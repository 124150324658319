import React, { useState, useEffect } from 'react';
import './css/index.scss';
import {
  Typography,
  Avatar,
  Comment,
  Card,
  Switch,
  Input,
  Form,
  Button,
  Result,
  Row,
  Col,
  Checkbox,
  Radio,
  Collapse,
  Select,
  Upload,
} from 'antd';
import {
  FormOutlined,
  SmileOutlined,
  CheckCircleTwoTone,
  ReloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './css/index.scss';
import { StaticImage } from '../../../utils/image';
const { Text, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const { Panel } = Collapse;
const Editor = ({ onChange, onSubmit, submitting, value, readOnly = false, }) => (
  <>
    <Form.Item align='start'>
      <TextArea
        readOnly={readOnly}
        rows={3}
        cols={12}
        onChange={onChange}
        value={value}
        placeholder={'เหตุผลเพิ่มเติม...'}
      />
    </Form.Item>
  </>
);

const ListDetails = ({
  survey,
  onCommentClick,
  onCommentOnChange,
  onEvent,
  beforeQuestion,
  surveyItem
}) => {
  const checkReadonly = () => {
    if ((surveyItem.isconclude == 'O' || !surveyItem.isconclude) && surveyItem.date_balance >= 0) {
      return false
    } else {
      return true;
    }
  }
  const history = useHistory();
  var rows = [];
  const normFile = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  survey.question_detail.map((item, index) => {
    rows.push(
      <div className="tab3-container" style={{ textAlign: 'left' }}>
        <div className="title-question">
          <h1 className='header'>{`${index + 1 + beforeQuestion
            }. ${item.title}`}
            {item.isRequired && <span className="text-danger">&nbsp;*</span>}
          </h1>
          {item.description && (
            <Collapse style={{ marginTop: 10 }} defaultActiveKey={['0']}>
              <Panel header='คำอธิบาย' key='1'>
                <p>{item.description}</p>
              </Panel>
            </Collapse>
          )}
        </div>
        {item.type == 'radiogroup' && (
          <div style={{ padding: '0px 10px' }}>
            <Radio.Group
              key={item.name}
              onChange={(e) =>
                onEvent(survey, item.name, item.type, e.target.value)
              }
              value={item.value}
            >
              {item.choices.map((choice, choiceIndex) => {
                return (
                  <div key={choice.value} style={{ paddingTop: '10px' }}>
                    <Radio
                      disabled={checkReadonly()}
                      className='switch-style'
                      onChange={(e) =>
                        onEvent(survey, item.name, item.type, choice.value)
                      }
                      value={choice.value}
                    />
                    <Text

                      onClick={() =>
                        onEvent(survey, item.name, item.type, choice.value)
                      }
                      className='switch-text'
                    >
                      {choice.text}
                    </Text>
                  </div>
                );
              })}
              {item.hasOther && (
                <div key={'other'} style={{ paddingTop: '10px' }}>
                  <Radio
                    disabled={checkReadonly()}
                    className='switch-style'
                    onChange={(e) =>
                      onEvent(survey, item.name, item.type, e.target.value)
                    }
                    value={'other'}
                  />
                  <Text
                    onClick={() =>
                      onEvent(survey, item.name, item.type, 'other')
                    }
                    className='switch-text'
                  >
                    {item.otherText}
                  </Text>
                  {item.value == 'other' && (
                    <Input
                      readOnly={checkReadonly()}
                      type={'text'}
                      placeholder={'ระบุ'}
                      value={item.otherValue}
                      onChange={(e) =>
                        onEvent(survey, item.name, 'other', e.target.value)
                      }
                    />
                  )}
                </div>
              )}
            </Radio.Group>

            {item.editor == true ? (
              <Editor
                value={item.note}
                readOnly={checkReadonly()}
                onChange={(e) =>
                  onCommentOnChange(survey, item, e.target.value)
                }
              />
            ) : (
              <div
                className='editor-pen'
                onClick={() => onCommentClick(survey, item)}
              >
                <img
                  src={StaticImage(
                    '/images/icon/pencil-alt-duotone-copy.png'
                  )}
                />
                <span>เขียนความคิดเห็น</span>
              </div>
            )}
          </div>
        )}
        {item.type == 'text' && (
          <div style={{ padding: '0px 10px' }}>
            <Input
              readOnly={checkReadonly()}
              key={item.name}
              type={item.inputType ? item.inputType : 'text'}
              placeholder={item.placeHolder}
              value={item.value}
              onChange={(e) =>
                onEvent(survey, item.name, item.type, e.target.value)
              }
            />
            {/* {item.editor == true ? (
                  <Editor
                    value={item.note}
                    onChange={(e) =>
                      onCommentOnChange(survey, item, e.target.value)
                    }
                  />
                ) : (
                  <div
                    className='editor-pen'
                    onClick={() => onCommentClick(survey, item)}
                  >
                    <img
                      src={StaticImage(
                        '/images/icon/pencil-alt-duotone-copy.png'
                      )}
                    />
                    <span>เขียนความคิดเห็น</span>
                  </div>
                )} */}
          </div>
        )}
        {item.type == 'checkbox' && (
          <div style={{ padding: '0px 10px' }}>
            <Checkbox.Group value={item.value}>
              {item.hasSelectAll && (
                <Row>
                  <Checkbox
                    disabled={checkReadonly()}
                    onChange={(e) => {
                      onEvent(survey, item.name, item.type, e.target.value);
                    }}
                    value={'selectAll'}
                  ></Checkbox>
                  <Text
                    disabled={checkReadonly()}
                    onClick={() =>
                      onEvent(survey, item.name, item.type, 'selectAll')
                    }
                    className='switch-text'
                  >
                    {item.selectAllText}
                  </Text>
                </Row>
              )}
              {item.choices.map((choice, choiceIndex) => {
                return (
                  <Row>
                    <Checkbox
                      disabled={checkReadonly()}
                      onChange={(e) => {
                        onEvent(
                          survey,
                          item.name,
                          item.type,
                          e.target.value
                        );
                      }}
                      value={choice.value}
                    ></Checkbox>
                    <Text
                      disabled={checkReadonly()}
                      onClick={() =>
                        onEvent(survey, item.name, item.type, choice.value)
                      }
                      className='switch-text'
                    >
                      {choice.text}
                    </Text>
                  </Row>
                );
              })}
              {item.hasOther && (
                <Row>
                  <Checkbox
                    disabled={checkReadonly()}
                    onChange={(e) => {
                      onEvent(survey, item.name, item.type, e.target.value);
                    }}
                    value={'other'}
                  ></Checkbox>
                  <Text
                    disabled={checkReadonly()}
                    onClick={() =>
                      onEvent(survey, item.name, item.type, 'other')
                    }
                    className='switch-text'
                  >
                    {item.otherText}
                  </Text>
                  {item.value &&
                    item.value.length > 0 &&
                    item.value.includes('other') && (
                      <Input
                        readOnly={checkReadonly()}
                        type={'text'}
                        placeholder={'ระบุ'}
                        value={item.otherValue}
                        onChange={(e) =>
                          onEvent(
                            survey,
                            item.name,
                            'other',
                            e.target.value
                          )
                        }
                      />
                    )}
                </Row>
              )}
              {item.hasNone && (
                <Row>
                  <Checkbox
                    disabled={checkReadonly()}
                    onChange={(e) => {
                      onEvent(survey, item.name, item.type, e.target.value);
                    }}
                    value={'none'}
                  ></Checkbox>
                  <Text
                    disabled={checkReadonly()}
                    onClick={() =>
                      onEvent(survey, item.name, item.type, 'none')
                    }
                    className='switch-text'
                  >
                    {item.noneText}
                  </Text>
                </Row>
              )}

            </Checkbox.Group>
            {item.editor == true ? (
              <Editor
                readOnly={checkReadonly()}
                value={item.note}
                onChange={(e) =>
                  onCommentOnChange(survey, item, e.target.value)
                }
              />
            ) : (
              <div
                className='editor-pen'
                onClick={() => onCommentClick(survey, item)}
              >
                <img
                  src={StaticImage(
                    '/images/icon/pencil-alt-duotone-copy.png'
                  )}
                />
                <span>เขียนความคิดเห็น</span>
              </div>
            )}
          </div>
        )}
        {item.type == 'dropdown' && (
          <div style={{ padding: '0px 10px' }}>
            <Select
              disabled={checkReadonly()}
              // defaultValue='lucy'
              style={{ width: '100%' }}
              onChange={(value) => {
                onEvent(survey, item.name, item.type, value);
              }}
              placeholder={item.optionsCaption}
            >
              {item.choices.map((choice, choiceIndex) => {
                return <Option value={choice.value}>{choice.text}</Option>;
              })}
            </Select>
            {item.editor == true ? (
              <Editor
                readOnly={checkReadonly()}
                value={item.note}
                onChange={(e) =>
                  onCommentOnChange(survey, item, e.target.value)
                }
              />
            ) : (
              <div
                className='editor-pen'
                onClick={() => onCommentClick(survey, item)}
              >
                <img
                  src={StaticImage(
                    '/images/icon/pencil-alt-duotone-copy.png'
                  )}
                />
                <span>เขียนความคิดเห็น</span>
              </div>
            )}
          </div>
        )}
        {item.type == 'file' && (
          <div style={{ padding: '0px 10px' }}>
            <Form.Item
              name='upload'
              label=''
              valuePropName='fileList'
              getValueFromEvent={normFile}
              isList={false}

            // extra='longgggggggggggggggggggggggggggggggggg'
            >
              <Upload
                disabled={checkReadonly()}
                name='logo'
                action='/upload.do'
                multiple={false}
                listType='picture'
                multiple={false}
                onChange={(e) => {
                  onEvent(survey, item.name, item.type, e.file);
                }}
              >
                <Button disabled={checkReadonly()} icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            {/* <Input

                  key={item.name}
                  style={{ zIndex: 1000 }}
                  type={item.inputType ? item.inputType : 'text'}
                  placeholder={item.placeHolder}
                  value={item.value}
                  onChange={(e) =>
                    onEvent(survey, item.name, item.type, e.target.value)
                  }
                /> */}
            {item.editor == true ? (
              <Editor
                readOnly={checkReadonly()}
                value={item.note}
                onChange={(e) =>
                  onCommentOnChange(survey, item, e.target.value)
                }
              />
            ) : (
              <div
                className='editor-pen'
                onClick={() => onCommentClick(survey, item)}
              >
                <img
                  src={StaticImage(
                    '/images/icon/pencil-alt-duotone-copy.png'
                  )}
                />
                <span>เขียนความคิดเห็น</span>
              </div>
            )}
          </div>
        )}

      </div>
    );
  });

  function onChange(checked) { }

  return (
    <div className='question-page'>
      <div>{rows}</div>
    </div>
  );
};

export default ListDetails;
