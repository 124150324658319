import React, { useState, useEffect } from 'react';
import {
  Typography,
  Space,
  Row,
  Col,
  Button,
  Image,
  Collapse,
  Switch,
} from 'antd';

import {
  StarOutlined,
  ShareAltOutlined,
  FormOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@material-ui/core';
import { useClipboard } from 'use-clipboard-copy';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import ApiSurvey from '../../api/survey';
import './css/index.scss';
import { StaticImage } from '../../utils/image';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { ConvertDateShort } from '../../utils/date';

const { Text, Link, Title } = Typography;
const { Panel } = Collapse;

const Summary = (props) => {
  const history = useHistory();
  const [survey, setsurvey] = useState([]);
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const [share, setshare] = useState({});
  const [showShare, setShowShare] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  useEffect(() => {
    getSurvey();
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiSurvey.getById({ survey_id });
      if (survey.status == 200) {
        Swal.close();
        const { data } = survey.data;
        if (!(data?.survey_id > 0)) {
          Swal.fire(
            'เกิดข้อผิดพลาด',
            'ไม่พบข้อมูลสรุปผลการรับฟังนี้',
            'warning'
          ).then((result) => {
            history.go(-1);
          });
          return false;
        }

        setsurvey(data);
        setshare({
          title: `ตามที่ท่านได้ร่วมแสดงความคิดเห็นต่อ ${data.survey_name} ภายใต้โครงการ ${data.project_name} ผ่านระบบกลางทางกฎหมาย นั้น ท่านสามารถติดตามความคืบหน้าของการรับฟังความคิดเห็นดังกล่าวได้ที่
          ${window.location.origin}/listeningDetail?survey_id=${survey_id} และเมื่อกระบวนการรับฟังความคิดเห็นได้ดำเนินการเสร็จสิ้นแล้ว หน่วยงานผู้รับผิดชอบมีหน้าที่เผยแพร่สรุปผลการรับฟังความคิดเห็นและรายงานการวิเคราะห์ผลกระทบของร่างกฎหมายหรือรายงานการประเมินผลสัมฤทธิ์ของกฎหมาย (แล้วแต่กรณี) ซึ่งท่านสามารถเข้าถึงได้ตาม Link ข้างต้น`,
          subject: `ตามที่ท่านได้ร่วมแสดงความคิดเห็นต่อ ${data.survey_name} ภายใต้โครงการ ${data.project_name} ผ่านระบบกลางทางกฎหมาย นั้น ท่านสามารถติดตามความคืบหน้าของการรับฟังความคิดเห็นดังกล่าวได้ที่
          ${window.location.origin}/listeningDetail?survey_id=${survey_id}`,
          shareUrl: `${window.location.origin}/listeningDetail?survey_id=${survey_id}`,
        });
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  return (
    <div>
      <section className='section-banner'>
        <div onClick={() => history.go(-1)} className='back-btn'>
          <ArrowLeftOutlined style={{ marginRight: 6 }} />
          <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
        </div>
        <section
          style={{
            backgroundColor: '#f1f3f7',
            paddingBottom: '10px',
          }}
        >
          <section style={{ padding: 10 }}>
            <span className='project-head'>{survey?.project_name}</span>
          </section>
        </section>
      </section>
      <section style={{ paddingTop: '12px' }}>
        <Container maxWidth='xl'>
          <Row>
            <Col span={12}>
              <Title level={5}>สรุปผลการรับฟัง</Title>
            </Col>
            <Col span={12} align='end'>
              {/* <Button icon={<StarOutlined />} /> */}
              <Button
                onClick={() => setShowShare(true)}
                icon={<ShareAltOutlined />}
                style={{ marginLeft: '8px', paddingTop: '1px' }}
              >
                แชร์
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ paddingBottom: 70 }}>
        {survey?.survey_id > 0 && (
          <Container
            maxWidth='xl'
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            <Title level={5} style={{ color: '#0c4da2' }}>
              รายงานสรุปผลการรับฟัง
            </Title>
            <div>
              <hr />
            </div>
            <div style={{ marginTop: 10 }}>
              <span style={{ color: '#0c4da2' }}>{survey.conclude_title}</span>
            </div>
            <div style={{ marginTop: 10 }}>
              <div
                className='ql-editor'
                dangerouslySetInnerHTML={{
                  __html: survey.conclude_desc,
                }}
              ></div>
              {survey?.content_files && survey?.content_files.length > 0 && (
                <div>
                  {survey.content_files.map((item, index) => {
                    if (item.type !== 'conclude') {
                      return <></>;
                    }
                    return (
                      <div className='d-flex'>
                        <label
                          style={{ fontSize: 14 }}
                          className='text-link cursor-pointer'
                          onClick={() =>
                            window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')
                          }
                        >
                          {item.file_name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Container>
        )}
      </section>

      {showShare && (
        <div className='share-container'>
          <div className='header'>
            <span>แชร์</span>
            <img
              onClick={() => setShowShare(false)}
              style={{ position: 'absolute', right: 25 }}
              src={StaticImage('/images/icon/drawer-header-close.png')}
            />
          </div>
          <div className='share-button'>
            <div>
              <FacebookShareButton
                url={share.shareUrl}
                quote={share.title}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <span>Facebook</span>
            </div>
            {/* <div>
              <LineShareButton url={share.shareUrl} title={share.subject}>
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
            <div>
              <TwitterShareButton
                url={' '}
                title={share.title}
                className='Demo__some-network__share-button'
              >
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <span>Twitter</span>
            </div>
            <div>
              <EmailShareButton
                url={''}
                subject={share.subject}
                body={share.title}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <span>E-mail</span>
            </div>
            <div
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/listeningDetail?survey_id=${survey_id}`
                )
              }
            >
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/link-regular.png'
                  )}
                />
              </div>
              <span>คัดลอกลิงค์</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Summary;
