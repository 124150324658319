const ModalFav = ({ show = false, onClose, law }) => {
    if (!show) return <></>
    return <div id="defaultModal" tabindex="-1" aria-hidden="true" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full">
        <div class="relative p-4 w-full max-w-2xl h-auto">
            <div class="relative bg-white rounded-lg shadow">
                <div class="p-6 space-y-2 flex items-center justify-center flex-column">
                    {law.is_like ? <div className="font-bold">คุณกด “ถูกใจ” </div> : <div className="font-bold">คุณยกเลิก “ถูกใจ”</div>}
                    <span className="text-blue-300 italic">{law.law_name_og}</span>
                    {law.is_like && <span className="font-bold">หลังจากนี้คุณสามารถติดตาม {law.law_name_og} ได้ที่เมนูด้านบนตามภาพ</span>}
                </div>
                <div class="border-t border-gray-200 flex justify-end">
                    <button onClick={onClose} className="border-l border-gray-200 text-lg text-blue-300 py-2 px-5">ปิด</button>
                </div>
            </div>
        </div>
    </div>
}

export default ModalFav;