import React from 'react';
import { Typography, Space, Row, Col, Button, Image } from 'antd';
import { StarOutlined, ShareAltOutlined } from '@ant-design/icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@material-ui/core';
import YoutuberPlayer from '../react-player/YoutuberPlayer';

const { Text, Link, Title } = Typography;
const Section2 = ({ survey }) => {
  return (
    <div>
      <section style={{ paddingTop: '22px' }}>
        <Container maxWidth='xl'>
          <Title level={5} style={{ color: '#0c4da2' }}>
            คำอธิบายหลักการหรือประเด็นสำคัญของร่างกฎหมายหรือกฎหมายที่นำมารับฟังความคิดเห็น
          </Title>
          <div style={{ paddingTop: '22px' }}>
            <hr />
          </div>
        </Container>
      </section>
      <section style={{ paddingTop: '22px' }}>
        <Container maxWidth='xl'>
          <div
            className='ql-editor'
            dangerouslySetInnerHTML={{ __html: survey.content_desc }}
          ></div>
          {survey.content_files && survey.content_files.length > 0 && (
            <div>
              {survey.content_files.map((item, index) => {
                if (item.type !== 'description') {
                  return <></>;
                }
                return (
                  <div className='d-flex'>
                    <label
                      style={{ fontSize: 14 }}
                      className='text-link cursor-pointer'
                      onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                    >
                      {item.file_name}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </Container>
      </section>{' '}
      {/* <section style={{ paddingTop: '22px', paddingBottom: '20px' }}>
        <Container maxWidth='xl'>
          <YoutuberPlayer />
        </Container>
      </section> */}
    </div>
  );
};

export default Section2;
