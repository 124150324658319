import { useHistory } from "react-router";
import { encodeBase64 } from "../../../utils/convertBase64";
import YoutuberPlayer from "../../react-player/YoutuberPlayer";
import './index.scss'
const Tab1Evaluate = ({ survey }) => {
    console.log('tab1::', survey)
    const history = useHistory();
    return (
        <>
            {survey.highlight && (
                <div className="tab1-container">
                    <YoutuberPlayer
                        mute={survey.ismute == '1' ? true : false}
                        autoplay={survey.isautoplay == '1' ? true : false}
                        url={survey.highlight}
                    />
                </div>
            )}
            <div className="tab1-container">
                <h1 className="header">
                    ชื่อการรับฟัง
                </h1>
                <div
                    className='ql-editor'
                >
                    {survey.survey_name}
                </div>
            </div>
            {survey.project_ref_id > 0 &&
                <div className="tab1-container">
                    <h1 className="header">
                        โครงการอ้างอิง
                    </h1>
                    <div className='file-container'>
                        <div className="d-flex">
                            <label
                                style={{ fontSize: 14 }}
                                className='text-link cursor-pointer'
                                onClick={() => {
                                    history.push(`/ProjectTimeline?projectId=${encodeBase64(survey.project_ref_id)}`);
                                }}
                            >
                                {survey.project_ref_name}
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className="tab1-container">
                <h1 className="header">
                    ผู้ได้รับผลกระทบ
                </h1>
                <div
                    className='ql-editor'
                >
                    {survey.affected_person?.length > 0 ? survey.affected_person : 'ไม่ได้ระบุ'}
                </div>
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    ข้อมูลเบื้องต้น
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_problem }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'problem') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() =>
                                            window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')
                                        }
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    สาระสำคัญของกฎหมาย/สภาพปัญหาและความจำเป็นของการมีกฎหมายฉบับนี้
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_necessity }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'nescessity') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    กฎหมาย กฎ มติ ครม ระเบียบ และคำสั่งที่เกี่ยวข้อง
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_affected }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'affected') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    ผู้เกี่ยวข้อง
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_related }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'related') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    ข้อมูลสถิติที่เกี่ยวกับการบังคับใช้กฎหมายฉบับนี้
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_stat }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'stat') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    การวิเคราะห์ความจำเป็นและผลกระทบของกฎหมายเบื้องต้น
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_analyze }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'analyze') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    ความเหมาะสมที่จะคงไว้ซึ่งระบบอนุญาต ระบบคณะกรรมการ การให้อำนาจดุลพินิจแก่เจ้าหน้าที่ หรือการกำหนดโทษอาญา (ถ้ามี)
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_decency }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'decency') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    อื่น ๆ
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_other }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'other') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>



        </>
    )
}

export default Tab1Evaluate;