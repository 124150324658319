import React from 'react'

const MapMargetDuoTone = ({className}) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ariaHidden="true"
        className={className}
        viewBox="0 0 384 512"
      >
        <g className="fa-group">
          <path
            fill="currentColor"
            d="M192 0C86 0 0 86 0 192c0 77.41 27 99 172.27 309.67a24 24 0 0 0 39.46 0C357 291 384 269.41 384 192 384 86 298 0 192 0zm0 288a96 96 0 1 1 96-96 96 96 0 0 1-96 96z"
            className="fa-secondary"
            opacity=".4"
          />
          <path
            fill="currentColor"
            d="M192 256a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"
            className="fa-primary"
          />
        </g>
      </svg>
    );
}

export default MapMargetDuoTone
