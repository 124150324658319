import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth } from '../utils';

class ApiNotification extends Component {
    static get = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `surveys/notification`,
            method: 'get',
        });
        return result;
    };
}

export default ApiNotification;
