import './index.scss'
import { Checkbox, Col, DatePicker, Input, Row, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useWindowSize } from '../../../utils/WindowSize';
import Search from 'antd/lib/transfer/search';
import Select from 'react-select'
import { useEffect, useState, useRef } from 'react';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import DetailLawPageTab1 from './Tab1';
import DetailLawPageTab2 from './Tab2';
import DetailLawPageTab3 from './Tab3';
import ModalFav from './ModalFav';
import ModalShare from './ModalShare';
import DetailLawPageTab4 from './Tab4';
import ApiLaw from '../../../api/law';
import Swal from 'sweetalert2';
import { ConvertDateShort } from '../../../utils/date';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import _ from 'lodash';
import { StaticImage } from '../../../utils/image';
import { checkComplete, checkLeftTime, checkLeftTimeIcon, checkLeftTimeStatus, numberToThai } from '../../../utils';
import { checkUser } from '../../../utils/checkUser';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import ProgressBar from '../progressBar';
import { encodeBase64 } from '../../../utils/convertBase64';
import { isMobile } from 'react-device-detect';
import Drawer from '../../../utils/Drawer';
import ReCAPTCHA from 'react-google-recaptcha';
import { WatDatePicker } from 'thaidatepicker-react';
import moment from 'moment';
const dateFormat = 'DD/MM/YYYY';
const filterOptions = [
    { value: 1, label: 'ชื่อ' },
    { value: 2, label: 'ชื่อและเนื้อหา' },
];
const typeOptions = [
    { value: null, label: 'ประเภท' },
    { value: 1, label: 'กฎกระทรวง' },
    { value: 2, label: 'ประกาศ' },
    { value: 3, label: 'ระเบียบ' },
    { value: 4, label: 'คำสั่ง' },
    { value: 5, label: 'พระราชบัญญัติ' },
];
const versionOptions = [
    { value: null, label: 'แสดงทุกฉบับ' },
    { value: 1, label: 'ฉบับตั้งต้น' },
];
const statusOptions = [
    { value: null, label: 'แสดงทุกสถานะ' },
    { value: 1, label: 'ประกาศใช้' },
    { value: 2, label: 'ยกเลิก' },
];
const DetailLawPage = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.user);
    const table_of_law_id = new URLSearchParams(props.location.search).get('table_of_law_id');
    const [searchState, setSearchState] = useState({
        isAdvance: false,
        isChar: false
    });
    const [masters, setMasters] = useState({
        agency: [],
        hirachy: [],
        category: []
    });
    const [lawGroup, setlawGroup] = useState([]);
    const [tabState, setTabState] = useState(1);
    const [tabStateLeft, setTabStateLeft] = useState(1);
    const [isFav, setisFav] = useState(false);
    const [isShare, setisShare] = useState(false);
    const [width, height] = useWindowSize();
    const history = useHistory();
    const [share, setshare] = useState({});
    const [laws, setLaws] = useState([]);
    const [virtues, setvirtues] = useState([]);
    const [lawActive, setlawActive] = useState({});
    const [lawActiveType, setlawActiveType] = useState('process');
    const [fileActive, setfileActive] = useState({
        pdf: "",
        word: "",
        en: "",
        relate_external: "",
        announce_url: "",
        infographic: []
    });

    const [searchAdvanceState, setsearchAdvanceState] = useState({
        searchText: "",
        searchTextType: "",
        hirachy: "",
        type: "",
        status: "",
        category: "",
        is_all_version: "",
        type_date: 1,
        start_date: "",
        end_date: "",
        address: ""
    });

    const [searchCharState, setsearchCharState] = useState({
        hirachy: [],
        char: [],
    });

    useEffect(() => {
        const promise1 = getMaster();
        const promise2 = getData();
        Swal.showLoading();
        Promise.all([promise1, promise2]).then(result => {
            Swal.close();
        })
    }, []);

    useEffect(() => {
        if (document.getElementById("tailwind-js")) {

        } else {
            const script = document.createElement("script");
            script.src = `${StaticImage("/tailwind.js")}`;
            script.id = "tailwind-js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    const getMaster = async () => {
        const result = await ApiLaw.getMaster();
        if (result.status == 200) {
            const { data } = result;
            data.agency.unshift({ value: null, label: 'หน่วยงาน' });
            data.hirachy.unshift({ value: null, label: 'ศักดิ์กฎหมาย' });
            data.category.unshift({ value: null, label: 'แสดงทุกหมวด' });
            setMasters(data);
        }
    }

    const getData = async () => {
        const result = await ApiLaw.getDetail(table_of_law_id);
        if (result.status == 200) {
            const { data } = result;
            setLaws(data.rows);
            setvirtues(data.virtues)
            setlawActive(data.rows[data.rows.length - 1]);
            setlawGroup(data.law_group)
            setshare({
                title: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
                subject: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
                shareUrl: `${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
                titleTW: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
                titleFB: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
            });
        }
    }

    const likeLaw = async (item) => {
        if (checkUser(userState)) {
            const model = {
                relate_id: item.law_id,
            };
            try {
                const result = await ApiLaw.like(model);
                if (result.status == 200) {
                    const likes = [...laws];
                    const index = likes.findIndex((x) => x.law_id == item.law_id);
                    likes[index].is_like = !likes[index].is_like;
                    setLaws(likes);
                    setisFav(true);
                }
            } catch (error) {
                console.log(error);
                Swal.close();
            }
        }
    };

    useEffect(() => {
        if (lawActive?.law_id > 0) {
            const file_docx = lawActive.content_files.find(x => x.type == "content" && x.file_name.includes('.doc'));
            const file_pdf = lawActive.content_files.find(x => x.type == "content" && x.file_name.includes('.pdf'));
            const files_en = lawActive.content_files.find(x => x.type == "files_en");
            const files_relate_external = lawActive.content_files.filter(x => x.type == "relate_external");
            const files_infographic = lawActive.content_files.filter(x => x.type == "infographic");
            setfileActive({
                word: file_docx,
                pdf: file_pdf,
                en: files_en,
                relate_external: files_relate_external,
                announce_url: lawActive.is_announce ? lawActive.announce_url : "",
                infographic: files_infographic
            })
        }
    }, [lawActive]);

    const handleRef = (id) => {
        let ref = id.replace("[", "");
        ref = ref.replace("]", "");
        const violation = document.getElementById(ref);
        let rect = violation.getBoundingClientRect();
        console.log(rect)
        window.scrollTo({
            top: rect.top < 0 ? rect.top + (window.scrollY - 170) : (rect.top + window.scrollY) - 170,
            behavior: "smooth"
        });
    }

    const onSearch = async (type, searchText) => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        history.push(`/SearchResult?format=search&type=${type}&searchText=${searchText}`)
    }

    const onSearchAdvance = async () => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        history.push(`/SearchResult?format=searchAdvance&searchText=${searchAdvanceState.searchText}`)
    }

    const onSearchChar = async () => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        history.push(`/SearchResult?format=searchChar`)
    }

    const clearSearchAdvance = async () => {
        setsearchAdvanceState({
            searchText: "",
            searchTextType: "",
            hirachy: null,
            type: null,
            status: null,
            category: null,
            is_all_version: null,
            type_date: 1,
            start_date: "",
            end_date: "",
            address: ""
        })

    }

    const clearSearchChar = async () => {
        setsearchCharState({
            hirachy: [],
            char: [],
        })
    }

    return <div id="detail-result" className='pt-5 sm:pt-0'>
        <div>
            <section className='section-banner mt-10 sm:mt-[65px]' style={{}}>
            </section>
        </div>

        <div className="d-flex-align-center px-5" style={{
            // marginTop: width >= 768 ? 50 : 50,
            zoom: width >= 768 ? '100%' : '80%'
        }}>
            <div className='w-full'>
                <section>
                    <SearchComponent
                        onSearch={onSearch}
                        onSearchAdvance={onSearchAdvance}
                        searchState={searchState}
                        setSearchState={setSearchState}
                        setsearchAdvanceState={setsearchAdvanceState}
                        searchAdvanceState={searchAdvanceState}
                    ></SearchComponent>
                </section>
                {searchState.isAdvance &&
                    <section>
                        <SearchAdvanceComponent
                            setsearchAdvanceState={setsearchAdvanceState}
                            searchAdvanceState={searchAdvanceState}
                            masters={masters}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            onSearchAdvance={onSearchAdvance}
                            clearSearchAdvance={clearSearchAdvance}
                        >

                        </SearchAdvanceComponent>
                    </section>
                }
                {
                    searchState.isChar &&
                    <section>
                        <SearchCharComponent
                            masters={masters}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            searchCharState={searchCharState}
                            setsearchCharState={setsearchCharState}
                            onSearchChar={onSearchChar}
                            clearSearchChar={clearSearchChar}
                        />
                    </section>
                }
            </div>
        </div>
        <div className='bg-white items-container mt-5 pt-5 pb-20 sm:pt-5 sm:pb-5'>
            <div className="d-flex-align-center px-5" style={{
                zoom: width >= 768 ? '100%' : '80%'
            }}>
                <Row className='hidden sm:flex w-full'>
                    <Col span="5" className='left-container'>
                        {lawActive?.law_id &&
                            <div className='left'>
                                <label className='text-blue-500 text-base font-bold'>หัวเรื่อง</label>
                                <ul className='sarabun'>
                                    <li>ชื่อกฎหมาย</li>
                                    {
                                        lawActiveType == "left" && lawActive.content_list.map((content, index) => {
                                            let title = `${content.content_type} ${numberToThai(content.content_number)}\t`;
                                            return <li onClick={(e) => handleRef(`#item-${index}`)}>{title}</li>
                                        })
                                    }
                                    {
                                        lawActiveType !== "left" && lawActive.content_list_process.map((content, index) => {
                                            let title = `${content.content_type} ${numberToThai(content.content_number)}\t`;
                                            return <li onClick={(e) => handleRef(`#item-${index}`)}>{title}</li>
                                        })
                                    }
                                    {/* <li>พระปรมาภิไธย</li>
                                    <li>คำปรารภ</li>
                                    <li>มาตรา 1</li>
                                    <li>มาตรา 2</li>
                                    <li>มาตรา 3</li>
                                    <li>ลักษณะ 1</li>
                                    <li className='level-2'>มาตรา 1</li>
                                    <li className='level-2'>มาตรา 2</li>
                                    <li>ส่วน 1</li> */}
                                </ul>
                            </div>
                        }

                        <div className='left-section-2'>
                            <div className='mt-5 flex gap-x-1'>
                                <button onClick={() => {
                                    setTabStateLeft(1)
                                }} className={`button flex items-center ${tabStateLeft == 1 && 'active'}`}>
                                    <img alt="history-icon" className='h-4 mr-2'
                                        src={StaticImage('/images/phase2icon/clock-duotone.svg')} />
                                    <span>
                                        ประวัติการแก้ไขกฎหมาย
                                    </span>
                                </button>
                                <button
                                    onClick={() => {
                                        setTabStateLeft(2)
                                    }}
                                    className={`button flex items-center ${tabStateLeft == 2 && 'active'}`}>
                                    <img alt="listening-icon" className='h-4 mr-2'
                                        src={StaticImage('/images/phase2icon/vote-yea-duotone-blue.svg')} />
                                    <span>
                                        การรับฟัง
                                    </span>
                                </button>
                            </div>
                            {tabStateLeft == 1 && <div className='mt-5 history-container'>
                                <div className='flex items-center border-bottom pb-2 justify-between'>
                                    <div className='flex items-center'>
                                        <img alt="garuda-icon" className='h-6 mr-2'
                                            src={StaticImage('/images/phase2icon/vector-smart-object.png')} />
                                        <label className='text-blue-300 text-sm font-bold'>{lawActive.tableoflaw_name}</label>
                                    </div>
                                    <img alt="file-icon" className='h-6 mr-2'
                                        src={StaticImage('/images/phase2icon/file-zipper-duotone.svg')} />
                                </div>
                                {_.orderBy(laws, 'effective_startdate', 'desc').map((item, index) => {
                                    var rivisionDate = "";
                                    // console.log("item.effective_type::", item.effective_type)
                                    if (item.effective_type == 1) {
                                        rivisionDate = `${ConvertDateShort(item.effective_startdate, 'DD MMM YY')} – ปัจจุบัน`
                                    }
                                    else if (item.effective_type == 3) {
                                        //console.log("item.effective_type::", item)
                                        rivisionDate = `${ConvertDateShort(item.effective_startdate, 'DD MMM YY')} – ${ConvertDateShort(moment(item.effective_enddate), 'DD MMM YY')}`
                                    }
                                    else if (item.effective_type == 2) {
                                        rivisionDate = `${ConvertDateShort(item.effective_startdate, 'DD MMM YY')} – ${ConvertDateShort(item.effective_enddate, 'DD MMM YY')}`
                                    }
                                    //var lawsOrderBy = _.orderBy(laws, 'effective_startdate', 'desc');
                                    //var beforeDate = index == 0 ? '' : lawsOrderBy[index - 1].effective_startdate;
                                    //var rivisionDate = index == 0 ? `${ConvertDateShort(item.effective_startdate, 'DD MMM YY')} – ปัจจุบัน` : `${ConvertDateShort(item.effective_startdate, 'DD MMM YY')} – ${ConvertDateShort(beforeDate, 'DD MMM YY')}`;

                                    return (
                                        <div key={"left-" + item.law_id} onClick={() => {
                                            console.log('item::', item)
                                            setlawActive(item);
                                            setlawActiveType('left')
                                        }
                                        }
                                            className={`flex law-content-container flex-column pt-2 border-bottom pb-2 cursor-pointer 
                                            ${item.law_id == lawActive.law_id && lawActiveType == 'left' ? "active" : ''} 
                                            ${item.law_id == lawActive.law_id && lawActiveType != 'left' ? "active-border" : ''}`
                                            }>
                                            <div>
                                                <span>
                                                    {item?.law_name_th}
                                                </span>
                                            </div>
                                            <div className='flex revision font-bold'>
                                                <small className='text-left'>ใช้บังคับตั้งแต่: </small>
                                                <small className='ml-2'>{`${rivisionDate}`} {item?.law_status_id == 2 && "(ยกเลิก)"}</small>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            }
                            {tabStateLeft == 2 && <div className='mt-5 survey-list-container'>
                                {lawActive?.surveys?.length > 0 &&
                                    lawActive?.surveys.map((item, index) => {
                                        return <SurveyComponent law_group={lawGroup} key={'survey-' + index} survey={item} />
                                    })
                                }
                            </div>
                            }

                        </div>
                        {
                            virtues.length > 0 && <>
                                <div className='flex items-center mt-5'>
                                    <img alt="law-main-icon" className='h-5 mr-2'
                                        src={StaticImage('/images/phase2icon/book-duotone.svg')} />
                                    <label className='font-bold text-blue-500 text-base '>กฎหมายแม่บท</label>
                                </div>

                                <div className='mt-5'>
                                    <LawMaster virtues={virtues} />
                                </div>
                            </>
                        }
                        {
                            lawActive?.law_relate?.length > 0 && <>
                                <div className='flex items-center mt-5'>
                                    <img alt="law-relate-icon" className='h-5 mr-2'
                                        src={StaticImage('/images/phase2icon/book-duotone.svg')} />
                                    <label className='font-bold text-blue-500 text-base '>กฎหมายที่เกี่ยวข้อง</label>
                                </div>
                                <div className='mt-5'>
                                    {lawActive?.law_relate?.length > 0 && <LawRelateInternal files={lawActive?.law_relate ?? []} />}
                                </div>
                            </>
                        }
                        {
                            fileActive?.relate_external?.length > 0 && <>
                                <div className='flex items-center mt-5'>
                                    <img alt="law-relate-external-icon" className='h-5 mr-2'
                                        src={StaticImage('/images/phase2icon/book-duotone.svg')} />
                                    <label className='font-bold text-blue-500 text-base '>กฎหมายที่เกี่ยวข้อง (External)</label>
                                </div>
                                <div className='mt-5'>
                                    {lawActive?.law_id && <LawRelate files={fileActive?.relate_external} />}

                                </div>
                            </>
                        }



                    </Col>
                    <Col span="1"></Col>
                    <Col span="18" className='right-container'>
                        <div className='flex flex-col flex-1 justify-betweeen gap-x-4 mb-5 flex-wrap gap-y-2'>
                            <div className='flex-1 flex gap-y-2 justify-between'>
                                <span>กฎหมายฉบับอัพเดท ณ วันที่</span>
                                <a style={{
                                    display: 'flex',
                                    alignSelf: 'center',
                                    textDecoration: 'underline',
                                    color: '#3562fd'
                                }}
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSfaqC_m-9p8baE9qFnm76h2OcNb2zSy5Rjdl6H9SstLr4XnhA/viewform" target='_blank'>
                                    แบบสำรวจการใช้งาน
                                </a>
                            </div>
                            <div className='flex flex-wrap gap-y-3 justify-between'>
                                <div className='timeline-container items-center flex-wrap gap-y-2 flex'>
                                    {
                                        laws.map((item, index) => {
                                            return (
                                                <>
                                                    <div onClick={() => {
                                                        setlawActiveType('process')
                                                        setlawActive(item)
                                                    }
                                                    } className={`
                                                        timeline-item
                                                        ${item.law_id == lawActive.law_id && lawActiveType == 'process' ? "active" : ''}
                                                        ${item.law_id == lawActive.law_id && lawActiveType != 'process' ? "active-border" : ''}
                                                    `}>
                                                        <span>{ConvertDateShort(item.effective_startdate, 'DD MMM YY')} {item.law_status_id == 2 && "(ยกเลิก)"}</span>
                                                    </div>
                                                    {(laws.length - 1) !== index && <div className='border'></div>}
                                                </>
                                            )
                                        })
                                    }
                                    {/* <div className='timeline-item'>
                                    <span>18 ต.ค. 50</span>
                                </div>
                                <div className='border'></div>
                                <div className='timeline-item'>
                                    <span>23 มิ.ย. 60</span>
                                </div>
                                <div className='border'></div>
                                <div className='timeline-item'>
                                    <span>22 พ.ย. 61</span>
                                </div>
                                <div className='border'></div>
                                <div className='timeline-item active'>
                                    <span>17 เม.ย 62</span>
                                </div> */}
                                </div>
                                <div className='tab-container flex '>
                                    <div class="group relative py-2 px-3">
                                        <button
                                            aria-label="announce_button"
                                            disabled={!fileActive.announce_url}
                                            onClick={() => {
                                                window.open(fileActive.announce_url);
                                            }}>
                                            <img alt="garuda-icon" className='h-6'
                                                src={StaticImage('/images/phase2icon/vector-smart-object.png')} /></button>
                                        <div class="hidden group-hover:block ...">ประกาศราชกิจจานุเบกษา</div>
                                    </div>

                                    <div class="group relative py-2 px-3">
                                        <button
                                            aria-label="pdf-button"
                                            disabled={
                                                !fileActive.pdf?.file_path
                                            } onClick={() => {
                                                window.open(fileActive.pdf?.file_path);
                                            }}>
                                            <img alt="pdf-icon" className='h-6'
                                                src={StaticImage('/images/phase2icon/pdf.svg')} />
                                        </button>
                                        <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.pdf)</div>
                                    </div>
                                    <div class="group relative py-2 px-3">
                                        <button
                                            aria-label="word-button"
                                            disabled={
                                                !fileActive.word?.file_path
                                            }
                                            onClick={() => {
                                                window.open(fileActive.word?.file_path);
                                            }}>
                                            <img alt="docx-icon" className='h-6'
                                                src={StaticImage('/images/phase2icon/word.svg')} />
                                        </button>
                                        <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.docx)</div>
                                    </div>
                                    <div class="group relative py-2 px-3">
                                        <button
                                            aria-label="fileen-button"
                                            className='text-xl'
                                            disabled={
                                                !fileActive.en?.file_path
                                            }
                                            onClick={() => {
                                                window.open(fileActive.en?.file_path);
                                            }}>
                                            EN
                                        </button>
                                        <div class="hidden group-hover:block ...">ภาษาอังกฤษ</div>
                                    </div>
                                    <div className={`group relative py-2 px-3 ${tabState == 3 ? "active" : ""}`}>
                                        <button aria-label="easy-button"
                                            className={tabState == 3 ? "active" : ""}
                                            onClick={() => {
                                                setTabState(tabState == 3 ? 1 : 3);
                                            }}
                                            disabled={
                                                !lawActive.simple_url_video && !lawActive.simple_description && (!fileActive.infographic || fileActive.infographic.length == 0)
                                            }
                                        >  <img alt="easy-icon" className='h-6'
                                            src={StaticImage('/images/phase2icon/image-duotone.svg')} />
                                        </button>
                                        <div class="hidden group-hover:block ...">ข้อมูลอย่างง่าย</div>
                                    </div>
                                    <div className={`group relative py-2 px-3 ${tabState == 4 ? "active" : ""}`}>
                                        <button aria-label="comment-button"
                                            className={tabState == 4 ? "active" : ""}
                                            onClick={() => {
                                                setTabState(tabState == 4 ? 1 : 4);
                                            }}><img alt="comment-icon" className='h-6'
                                                src={StaticImage('/images/phase2icon/megaphone-duotone.svg')} /></button>
                                        <div class="hidden group-hover:block ...">ข้อเสนอแนะ</div>
                                    </div>
                                    <div class="group relative py-2 px-3" style={{
                                        backgroundColor: lawActive.is_like ? '#3562fd' : "#fff"
                                    }}>
                                        <button
                                            aria-label="like-button"
                                            style={{
                                                opacity: lawActive.is_like ? 1 : 0.5,

                                            }}
                                            onClick={() => likeLaw(lawActive)}
                                        >
                                            {
                                                lawActive.is_like ? <img className='h-6'
                                                    alt="like-icon"
                                                    src={StaticImage('/images/phase2icon/heart-duotone-gray.svg')} />
                                                    : <img alt="like-active-icon" className='h-6'
                                                        src={StaticImage('/images/phase2icon/heart-duotone.svg')} />
                                            }
                                        </button>
                                        <div class="hidden group-hover:block ...">ถูกใจ</div>
                                    </div>
                                    <div class="group relative py-2 px-3">
                                        <button
                                            aria-label="share-button"
                                            className={isShare ? "active last" : "last"}
                                            onClick={() => setisShare(true)}
                                        ><img alt="share-icon" className='h-6'
                                            src={StaticImage('/images/phase2icon/share-duotone.svg')} /></button>
                                        <div class="hidden group-hover:block ...">แบ่งปัน</div>
                                    </div>

                                </div>
                            </div>



                        </div>
                        {
                            (tabState == 1) && lawActive?.law_id && <DetailLawPageTab1 law={lawActive} lawActiveType={lawActiveType} />
                        }
                        {
                            (tabState == 2) && lawActive?.law_id && <DetailLawPageTab2 law={lawActive} />
                        }
                        {
                            (tabState == 3) && lawActive?.law_id && <DetailLawPageTab3 law={lawActive} files={fileActive.infographic} />
                        }
                        {
                            (tabState == 4) && lawActive?.law_id && <DetailLawPageTab4
                                law={lawActive}
                                onCancel={() => setTabState(1)}
                                onSubmit={() => setTabState(1)}
                            />
                        }
                        {
                            (tabState == 5) && lawActive?.law_id
                            &&
                            <DetailLawPageTab1 law={lawActive} />
                        }

                    </Col>
                </Row>
                <div className='w-full flex-col right-container flex sm:hidden'>
                    <div className='flex flex-col justify-betweeen gap-x-4 mb-5 flex-wrap gap-y-4'>
                        <div className='flex flex-1 justify-between'>
                            <span>กฎหมายฉบับอัพเดท ณ วันที่</span>
                            <a style={{
                                display: 'flex',
                                alignSelf: 'center',
                                textDecoration: 'underline',
                                color: '#3562fd'
                            }}
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfaqC_m-9p8baE9qFnm76h2OcNb2zSy5Rjdl6H9SstLr4XnhA/viewform" target='_blank'>
                                แบบสำรวจการใช้งาน
                            </a>
                        </div>
                        <div className='flex-1 flex flex-column gap-y-2 max-w-full overflow-auto'>
                            <div className='timeline-container items-center flex-1 flex gap-x-2'>
                                {
                                    laws.map((item, index) => {
                                        return (
                                            <>
                                                <div onClick={() => {
                                                    setlawActiveType('process')
                                                    setlawActive(item)
                                                }
                                                } className={`
                                                        timeline-item
                                                        ${item.law_id == lawActive.law_id && lawActiveType == 'process' ? "active" : ''}
                                                        ${item.law_id == lawActive.law_id && lawActiveType != 'process' ? "active-border" : ''}
                                                    `}>
                                                    <span>{ConvertDateShort(item.effective_startdate, 'DD MMM YY')} {item.law_status_id == 2 && "(ยกเลิก)"}</span>
                                                </div>
                                                {(laws.length - 1) !== index && <div className='border'></div>}
                                            </>
                                        )
                                    })
                                }
                                {/* <div className='timeline-item'>
                                    <span>18 ต.ค. 50</span>
                                </div>
                                <div className='border'></div>
                                <div className='timeline-item'>
                                    <span>23 มิ.ย. 60</span>
                                </div>
                                <div className='border'></div>
                                <div className='timeline-item'>
                                    <span>22 พ.ย. 61</span>
                                </div>
                                <div className='border'></div>
                                <div className='timeline-item active'>
                                    <span>17 เม.ย 62</span>
                                </div> */}
                            </div>
                        </div>
                        <div className='flex items-center gap-x-3 justify-between'>
                            <div className={`group relative flex`}>
                                <button
                                    className={`py-2 px-3 rounded-full ${isOpen ? "bg-[#0e4ea3]" : ""}`}
                                    onClick={() => {
                                        setIsOpen(true)
                                    }}>

                                    <img className='h-6'
                                        src={StaticImage('/images/phase2icon/clock-duotone.svg')} />
                                </button>
                                {/* <div class="hidden group-hover:block ...">ประวัติการแก้ไขกฎหมาย</div> */}
                            </div>

                            <div className='tab-container flex'>
                                <div class="group relative py-2 px-3">
                                    <button
                                        disabled={!fileActive.announce_url}
                                        onClick={() => {
                                            window.open(fileActive.announce_url);
                                        }}>
                                        <img alt="garuda-icon" className='h-5 sm:h-6'
                                            src={StaticImage('/images/phase2icon/vector-smart-object.png')} /></button>
                                    <div class="hidden group-hover:block ...">ประกาศราชกิจจานุเบกษา</div>
                                </div>

                                <div class="group relative py-2 px-3">
                                    <button aria-label="button-pdf" disabled={
                                        !fileActive.pdf?.file_path
                                    } onClick={() => {
                                        window.open(fileActive.pdf?.file_path);
                                    }}>
                                        <img alt="pdf-icon" className='h-5 sm:h-6'
                                            src={StaticImage('/images/phase2icon/pdf.svg')} />
                                    </button>
                                    <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.pdf)</div>
                                </div>
                                <div class="group relative py-2 px-3">
                                    <button
                                        disabled={
                                            !fileActive.word?.file_path
                                        }
                                        onClick={() => {
                                            window.open(fileActive.word?.file_path);
                                        }}>
                                        <img className='h-5 sm:h-6'
                                            src={StaticImage('/images/phase2icon/word.svg')} />
                                    </button>
                                    <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.docx)</div>
                                </div>
                                <div class="group relative py-2 px-3">
                                    <button
                                        className='text-xl'
                                        disabled={
                                            !fileActive.en?.file_path
                                        }
                                        onClick={() => {
                                            window.open(fileActive.en?.file_path);
                                        }}>
                                        EN
                                    </button>
                                    <div class="hidden group-hover:block ...">ภาษาอังกฤษ</div>
                                </div>
                                <div className={`group relative py-2 px-3 ${tabState == 3 ? "active" : ""}`}>
                                    <button className={tabState == 3 ? "active" : ""}
                                        onClick={() => {
                                            setTabState(tabState == 3 ? 1 : 3);
                                        }}
                                    >  <img className='h-5 sm:h-6'
                                        src={StaticImage('/images/phase2icon/image-duotone.svg')} />
                                    </button>
                                    <div class="hidden group-hover:block ...">ข้อมูลอย่างง่าย</div>
                                </div>
                                <div className={`group relative py-2 px-3 ${tabState == 4 ? "active" : ""}`}>
                                    <button className={tabState == 4 ? "active" : ""}
                                        onClick={() => {
                                            setTabState(tabState == 4 ? 1 : 4);
                                        }}>
                                        <img className='h-5 sm:h-6'
                                            src={StaticImage('/images/phase2icon/megaphone-duotone.svg')} /></button>
                                    <div class="hidden group-hover:block ...">ข้อเสนอแนะ</div>
                                </div>
                                <div class="group relative py-2 px-3" style={{
                                    backgroundColor: lawActive.is_like ? '#3562fd' : "#fff"
                                }}>
                                    <button
                                        style={{
                                            opacity: lawActive.is_like ? 1 : 0.5,

                                        }}
                                        onClick={() => likeLaw(lawActive)}
                                    >
                                        {
                                            lawActive.is_like ? <img className='h-5 sm:h-6'
                                                src={StaticImage('/images/phase2icon/heart-duotone-gray.svg')} />
                                                : <img className='h-5 sm:h-6'
                                                    src={StaticImage('/images/phase2icon/heart-duotone.svg')} />
                                        }
                                    </button>
                                    <div class="hidden group-hover:block ...">ถูกใจ</div>
                                </div>
                                <div class="group relative py-2 px-3">
                                    <button className={isShare ? "active last" : "last"}
                                        onClick={() => setisShare(true)}
                                    ><img className='h-5 sm:h-6'
                                        src={StaticImage('/images/phase2icon/share-duotone.svg')} /></button>
                                    <div class="hidden group-hover:block ...">แบ่งปัน</div>
                                </div>

                            </div>
                        </div>

                    </div>
                    {
                        (tabState == 1) && lawActive?.law_id && <DetailLawPageTab1 law={lawActive} lawActiveType={lawActiveType} />
                    }
                    {/* {
                        (tabState == 2) && lawActive?.law_id && <DetailLawPageTab2 law={lawActive} />
                    } */}
                    {
                        (tabState == 3) && lawActive?.law_id && <DetailLawPageTab3 law={lawActive} files={fileActive.infographic} />
                    }
                    {
                        (tabState == 4) && lawActive?.law_id && <DetailLawPageTab4
                            law={lawActive}
                            onCancel={() => setTabState(1)}
                            onSubmit={() => setTabState(1)}
                        />
                    }
                    {
                        (tabState == 5) && lawActive?.law_id
                        &&
                        <DetailLawPageTab1 law={lawActive} />
                    }
                </div>
            </div>
        </div>
        <ModalFav law={lawActive} show={isFav} onClose={() => setisFav(false)} />
        <ModalShare share={share} show={isShare} onClose={() => setisShare(false)} />
        {
            isMobile &&
            <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className='left-container'>
                    <div className='left-section-2'>
                        <div className='mt-5 flex gap-x-1 flex-wrap gap-y-3'>
                            <button onClick={() => {
                                setTabStateLeft(1)
                            }} className={`button flex items-center ${tabStateLeft == 1 && 'active'}`}>
                                <img className='h-4 mr-2'
                                    src={StaticImage('/images/phase2icon/clock-duotone.svg')} />
                                <span>
                                    ประวัติการแก้ไขกฎหมาย
                                </span>
                            </button>
                            <button
                                onClick={() => {
                                    setTabStateLeft(2)
                                }}
                                className={`button flex items-center ${tabStateLeft == 2 && 'active'}`}>
                                <img className='h-4 mr-2'
                                    src={StaticImage('/images/phase2icon/vote-yea-duotone-blue.svg')} />
                                <span>
                                    การรับฟัง
                                </span>
                            </button>
                        </div>
                        {tabStateLeft == 1 && <div className='mt-5 history-container'>
                            <div className='flex items-center border-bottom pb-2 justify-between'>
                                <div className='flex items-center'>
                                    <img alt="garuda-icon" className='h-6 mr-2'
                                        src={StaticImage('/images/phase2icon/vector-smart-object.png')} />
                                    <label className='text-blue-300 text-sm font-bold'>{lawActive.tableoflaw_name}</label>
                                </div>
                                <img className='h-6 mr-2'
                                    src={StaticImage('/images/phase2icon/file-zipper-duotone.svg')} />
                            </div>
                            {_.orderBy(laws, 'effective_startdate', 'desc').map((item, index) => {
                                var lawsOrderBy = _.orderBy(laws, 'effective_startdate', 'desc');
                                var beforeDate = index == 0 ? '' : lawsOrderBy[index - 1].effective_startdate;
                                var rivisionDate = index == 0 ? `${ConvertDateShort(item.effective_startdate, 'DD MMM YY')} – ปัจจุบัน` : `${ConvertDateShort(item.effective_startdate, 'DD MMM YY')} – ${ConvertDateShort(beforeDate, 'DD MMM YY')}`;

                                return (
                                    <div onClick={() => {
                                        setlawActive(item);
                                        setlawActiveType('left')
                                    }
                                    }
                                        className={`flex law-content-container flex-column pt-2 border-bottom pb-2 cursor-pointer 
                                            ${item.law_id == lawActive.law_id && lawActiveType == 'left' ? "active" : ''} 
                                            ${item.law_id == lawActive.law_id && lawActiveType != 'left' ? "active-border" : ''}`
                                        }>
                                        <div>
                                            <span>
                                                {item?.law_name_th}
                                            </span>
                                        </div>
                                        <div className='flex revision font-bold'>
                                            <small className='text-left'>ใช้บังคับตั้งแต่: </small>
                                            <small className='ml-2'>{`${rivisionDate}`} {item?.law_status_id == 2 && "(ยกเลิก)"}</small>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        }
                        {tabStateLeft == 2 && <div className='mt-5 survey-list-container'>
                            {lawActive?.surveys?.length > 0 &&
                                lawActive?.surveys.map((item, index) => {
                                    return <SurveyComponent law_group={lawGroup} key={'survey-' + index} survey={item} />
                                })
                            }
                        </div>
                        }

                    </div>
                    {
                        virtues.length > 0 && <>
                            <div className='flex items-center mt-5'>
                                <img className='h-5 mr-2'
                                    src={StaticImage('/images/phase2icon/book-duotone.svg')} />
                                <label className='font-bold text-blue-500 text-base '>กฎหมายแม่บท</label>
                            </div>

                            <div className='mt-5'>
                                <LawMaster virtues={virtues} />
                            </div>
                        </>
                    }
                    {
                        lawActive?.law_relate?.length > 0 && <>
                            <div className='flex items-center mt-5'>
                                <img className='h-5 mr-2'
                                    src={StaticImage('/images/phase2icon/book-duotone.svg')} />
                                <label className='font-bold text-blue-500 text-base '>กฎหมายที่เกี่ยวข้อง</label>
                            </div>
                            <div className='mt-5'>
                                {lawActive?.law_relate?.length > 0 && <LawRelateInternal files={lawActive?.law_relate ?? []} />}
                            </div>
                        </>
                    }
                    {
                        fileActive?.relate_external?.length > 0 && <>
                            <div className='flex items-center mt-5'>
                                <img className='h-5 mr-2'
                                    src={StaticImage('/images/phase2icon/book-duotone.svg')} />
                                <label className='font-bold text-blue-500 text-base '>กฎหมายที่เกี่ยวข้อง (External)</label>
                            </div>
                            <div className='mt-5'>
                                {lawActive?.law_id && <LawRelate files={fileActive?.relate_external} />}

                            </div>
                        </>
                    }



                </div>
            </Drawer>
        }
    </div >
}

const LawMaster = ({ virtues = [] }) => {
    return <div className='law-master'>
        {
            virtues.map((item, index) => {
                if (item.table_of_law_id_relate_id) {
                    return <Link to={`/DetailLawPage?table_of_law_id=${item.table_of_law_id_relate_id}`}>
                        <div key={'virtue' + index} className='item flex flex-column gap-y-1 border-bottom'>
                            <div className='flex justify-between text-blue-500 gap-x-2'>
                                <span className='flex-1 whitespace-nowrap'>ศักดิ์ : {item.law_hierarchy_name}</span>
                                <div className='flex flex-1 flex-column items-end'>
                                    {
                                        item.section.split(',').map((section) => {
                                            return <span className='flex-1'>{section}</span>
                                        })
                                    }
                                </div>
                            </div>
                            <span className='font-sm'>{item.law_name}</span>
                        </div>
                    </Link>
                } else {
                    return <div key={'virtue' + index} className='item flex flex-column gap-y-1 border-bottom'>
                        <div className='flex justify-between text-blue-500 gap-x-2'>
                            <span className='flex-1 whitespace-nowrap'>ศักดิ์ : {item.law_hierarchy_name}</span>
                            <div className='flex flex-1 flex-column items-end'>
                                {
                                    item.section.split(',').map((section) => {
                                        return <span className='flex-1'>{section}</span>
                                    })
                                }
                            </div>
                        </div>
                        <span className='font-sm'>{item.law_name}</span>
                    </div>
                }
            })
        }
    </div>
}


const LawRelate = ({ files }) => {
    return <div className='law-relate'>
        {files.map((item, index) => {
            return <div className='item flex flex-column gap-y-1 border-bottom'>
                <div className='flex justify-between text-blue-500 gap-x-1'>
                    <span>{item.description ?? "-"}</span>
                    <button aria-label="button-pdf" onClick={() => {
                        window.open(item?.file_path);
                    }}>
                        <img alt="pdf-icon" className='h-4'
                            src={StaticImage(item.file_name.includes('.pdf') ?
                                '/images/phase2icon/pdf.svg'
                                : '/images/phase2icon/word.svg')} />
                    </button>
                </div>
            </div>
        })}
        {/* <div className='item flex flex-column gap-y-1 border-bottom'>
            <div className='flex justify-between text-blue-500 gap-x-1'>
                <span>{lawActive.tableoflaw_name}</span>
                <span></span>
            </div>
            <span className='font-sm text-gray'>ศาล และกระบวนการยุติธรรม</span>
        </div>
        <div className='item flex flex-column gap-y-1 border-bottom'>
            <div className='flex justify-between text-blue-500 gap-x-1'>
                <span>พระราชบัญญัติให้ใช้ประมวลกฎหมายยาเสพติด พ.ศ. 2564</span>
                <span></span>
            </div>
            <span className='font-sm text-gray'>ศาล และกระบวนการยุติธรรม</span>
        </div>
        <div className='item flex flex-column gap-y-1 border-bottom'>
            <div className='flex justify-between text-blue-500 gap-x-1'>
                <span>พระราชบัญญัติให้ใช้ประมวลกฎหมายยาเสพติด พ.ศ. 2564</span>
                <span></span>
            </div>
            <span className='font-sm text-gray'>ศาล และกระบวนการยุติธรรม</span>
        </div> */}
    </div>
}

const SurveyComponent = ({ survey, law_group = [] }) => {
    const renderLawGroupText = (item) => {
        let law = "ไม่ได้ระบุ";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
            law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
        }

        return (
            <span className='text-black'>{law}</span>
        );
    };

    const renderDate = () => {
        return (
            <div className="d-flex-align-center" style={{ marginRight: 20 }}>
                <img
                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={16}
                    alt="public-no"
                />
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span>
                        {`${ConvertDateShort(survey.start_date, "DD MMM YY")} - ${ConvertDateShort(survey.end_date, "DD MMM YY")}`}
                    </span>
                </div>
            </div>
        );
    };
    const renderStatus = () => {
        return (
            checkLeftTimeStatus(survey)
        );
    };

    return <Link to={`/listeningDetail?survey_id=${encodeBase64(survey.survey_id)}`}>
        <div className='survey-item-container mb-4'>
            <div className='title-container flex'>
                <label className='title-name text-base line-clamp-2'>{survey.short_project_name ?? survey.project_name}</label>
            </div>
            <div className='content-container gap-y-2 flex flex-column'>
                <span className='text-gray line-clamp-2'>{survey.short_survey_name ?? survey.survey_name}</span>
                <div className='flex items-center'>
                    <img className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/books-duotone-1.svg')} />
                    {renderLawGroupText(survey)}
                </div>
                <div className='flex items-center'>
                    <img className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/calendar-days-duotone.svg')} />
                    <span className='text-blank date-text'>
                        {`${ConvertDateShort(survey.start_date, "DD MMM YY")} - ${ConvertDateShort(survey.end_date, "DD MMM YY")}`}
                    </span>
                </div>
                <div className="progressbar-row">
                    <div className='flex items-center'>

                        {checkLeftTimeIcon(survey) ?
                            <>
                                <img
                                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                                    className="h-4 mr-2"
                                    alt="public-no"
                                />
                                <section
                                    className="progress-list-filter"
                                    style={{ width: "100%", maxWidth: 400 }}
                                >
                                    <ProgressBar
                                        bgcolor={survey.date_balance > 7 ? "#387436" : "#f96b57"}
                                        bgContainerColor={
                                            survey.date_balance > 7 ? "#717377" : "#717377"
                                        }
                                        leftTime={checkLeftTime(survey)}
                                        completed={checkComplete(survey)}
                                    />
                                </section>
                            </> :
                            <>
                                {renderStatus()}
                            </>
                        }
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex'>
                        <div className='flex items-center'>
                            <img className='h-4 mr-1'
                                src={StaticImage('/images/phase2icon/users-class-light.svg')} />
                            <span className='text-green mr-1'>ร่วมเสนอ</span>
                        </div>
                        <div className='bg-green text-white px-2 rounded'>{survey.answer_count ?? 0}</div>
                    </div>
                    <div className='flex'>

                        <div className='flex items-center'>
                            <img className='h-4 mr-1'
                                src={StaticImage('/images/phase2icon/pen-nib-duotone.svg')} />
                            <span className='mr-1 text-black'>ครั้งที่</span>
                        </div>
                        <div className='bg-blue text-white px-2 rounded'>{survey.publish_no}</div>
                    </div>
                </div>
            </div>
        </div>
    </Link>
}

const LawRelateInternal = ({ files }) => {
    return <div className='law-relate'>
        {files.map((item, index) => {
            return <Link to={`/DetailLawPage?table_of_law_id=${item.table_of_law_id}`}>
                <div className='item flex flex-column gap-y-1 border-bottom'>
                    <div className='flex justify-between text-blue-500 gap-x-1'>
                        <span>{item.law_name_th ?? "-"}</span>
                        {/* <button onClick={() => {
                        window.open(item?.file_path);
                    }}>
                        <img className='h-4'
                            src={StaticImage(item.file_name.includes('.pdf') ?
                                '/images/phase2icon/pdf.svg'
                                : '/images/phase2icon/word.svg')} />
                    </button> */}
                    </div>
                </div>
            </Link>
        })}
        {/* <div className='item flex flex-column gap-y-1 border-bottom'>
            <div className='flex justify-between text-blue-500 gap-x-1'>
                <span>{""}</span>
                <span></span>
            </div>
            <span className='font-sm text-gray'>ศาล และกระบวนการยุติธรรม</span>
        </div>
        <div className='item flex flex-column gap-y-1 border-bottom'>
            <div className='flex justify-between text-blue-500 gap-x-1'>
                <span>พระราชบัญญัติให้ใช้ประมวลกฎหมายยาเสพติด พ.ศ. 2564</span>
                <span></span>
            </div>
            <span className='font-sm text-gray'>ศาล และกระบวนการยุติธรรม</span>
        </div>
        <div className='item flex flex-column gap-y-1 border-bottom'>
            <div className='flex justify-between text-blue-500 gap-x-1'>
                <span>พระราชบัญญัติให้ใช้ประมวลกฎหมายยาเสพติด พ.ศ. 2564</span>
                <span></span>
            </div>
            <span className='font-sm text-gray'>ศาล และกระบวนการยุติธรรม</span>
        </div> */}
    </div>
}

const SearchComponent = ({ searchState, setSearchState, onSearch, setsearchAdvanceState, searchAdvanceState, onSearchAdvance }) => {
    const history = useHistory();
    const inputRef = useRef(null);
    const [search, setsearch] = useState({
        type: 1,
        searchText: "",
    });
    return <div className='flex flex-col'>
        <div className='d-flex search-component items-center flex-wrap gap-y-2'>
            <div className='d-flex type-search w-32 mr-2'>
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className='basic-single w-full flex-1'
                    classNamePrefix='select'
                    defaultValue={filterOptions[0]}
                    options={filterOptions}
                    value={filterOptions.find((x) => x.value == searchAdvanceState.type)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, type: item.value });
                    }}
                />
            </div>
            <div className='d-flex min-w-[200px] flex-1 flex relative'>
                <Input
                    value={searchAdvanceState.searchText}
                    onChange={(e) => {
                        setsearchAdvanceState({ ...searchAdvanceState, searchText: e.target.value });
                    }}
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            onSearchAdvance();
                        }
                    }}
                    className="search flex-1"
                    placeholder="พิมพ์คำค้นหากฎหมาย"
                    suffix={
                        <>
                            <div className='flex gap-x-2 items-center hidden sm:flex'>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "and") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", "").replace(".ยกเว้น.", "") + ".และ.", searchTextType: "and" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "and" && "active"}`
                                    }>และ</button>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "or") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".ยกเว้น.", "") + ".หรือ.", searchTextType: "or" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "or" && "active"}`}>หรือ</button>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "except") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".ยกเว้น.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".หรือ.", "") + ".ยกเว้น.", searchTextType: "except" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "except" && "active"}`}>ยกเว้น</button>
                                <button type="button" onClick={() => {
                                    onSearchAdvance();
                                }} className='search-button'>
                                    <SearchOutlined />
                                </button>
                            </div>
                            <div className='flex gap-x-2 items-center flex sm:hidden'>
                                <button type="button" onClick={() => {
                                    onSearchAdvance();
                                }} className='search-button'>
                                    <SearchOutlined />
                                </button>
                            </div>
                        </>
                    }
                    ref={inputRef}
                />
            </div>
            <div className='hidden sm:flex'>
                <div className='flex' style={{ marginLeft: 10 }}>
                    <button type="button" className={`button flex items-center ${searchState.isAdvance ? "active" : ""}`} onClick={() => {
                        setSearchState({ ...searchState, isAdvance: true, isChar: false })
                    }}>
                        <img alt="magnify-icon" className='w-4 mr-1'
                            src={StaticImage('/images/phase2icon/magnifying-glass-plus-duotone-1.svg')} />
                        ค้นหาแบบละเอียด
                    </button>
                </div>
                <div className='d-flex' style={{ marginLeft: 10 }}>
                    <button type="button" className={`button ${searchState.isChar ? "active" : ""}`} onClick={() => {
                        setSearchState({ ...searchState, isAdvance: false, isChar: true })
                    }}>
                        ก-ฮ
                    </button>
                </div>
            </div>
            <div className='flex flex-1 justify-between sm:hidden'>
                <div className='flex gap-x-2 items-center'>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText + ".และ.", searchTextType: "and" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "and" && "active"}`
                        }>และ</button>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText + ".หรือ.", searchTextType: "or" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "or" && "active"}`}>หรือ</button>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText + ".ยกเว้น.", searchTextType: "except" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "except" && "active"}`}>ยกเว้น</button>
                </div>
                <div className='flex gap-x-2'>
                    <div className='flex'>
                        <button type="button" className={`button flex items-center ${searchState.isAdvance ? "active" : ""}`} onClick={() => {
                            setSearchState({ ...searchState, isAdvance: true, isChar: false })
                        }}>
                            <img alt="magnify-icon" className='w-4 mr-1'
                                src={StaticImage('/images/phase2icon/magnifying-glass-plus-duotone-1.svg')} />
                            ค้นหาแบบละเอียด
                        </button>
                    </div>
                    <div className='d-flex' >
                        <button type="button" className={`button ${searchState.isChar ? "active" : ""}`} onClick={() => {
                            setSearchState({ ...searchState, isAdvance: false, isChar: true })
                        }}>
                            ก-ฮ
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </div >
}

const SearchAdvanceComponent = ({ searchState, setSearchState, masters, searchAdvanceState, setsearchAdvanceState, onSearchAdvance, clearSearchAdvance }) => {
    const inputRef = useRef(null);
    return <div className='search-advance-container flex flex-column flex-1 mt-3'>
        <div className='flex mt-5 items-center'>
            <img className='h-4 mr-2'
                src={StaticImage('/images/phase2icon/search-duotone.svg')} />
            <label className='text-blue-500 text-base font-bold'>ค้นหาใน</label>
        </div>
        <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
            <div className='flex-1 flex flex-column'>
                <span className='text-blue-100'>ศักดิ์ของกฎหมาย</span>
                <Select
                    className='basic-single mt-1 flex-1'
                    classNamePrefix='select'
                    defaultValue={masters.hirachy[0] ?? ""}
                    options={masters.hirachy}
                    value={masters.hirachy.find((x) => x.value == searchAdvanceState.hirachy)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, hirachy: item.value });
                    }}
                />
            </div>
            <div className='flex-1 flex flex-column'>
                <span className='text-blue-100'>สถานะ</span>
                <Select
                    className='basic-single mt-1 flex-1'
                    classNamePrefix='select'
                    defaultValue={statusOptions[0]}
                    options={statusOptions}
                    value={statusOptions.find((x) => x.value == searchAdvanceState.status)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, status: item.value });
                    }}
                />
            </div>
        </div>
        <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
            <div className='flex-1 flex gap-x-2 sm:items-center gap-y-2 flex-col sm:flex-row'>
                <div className='flex items-center'>
                    <img className='h-4 mr-1'
                        src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                    <span className='text-blue-500'>ตัวกรอง</span>
                </div>

                <Select
                    className='basic-single flex-1'
                    classNamePrefix='select'
                    defaultValue={masters.category[0] ?? ""}
                    options={masters.category}
                    value={masters.category.find((x) => x.value == searchAdvanceState.category)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, category: item.value });
                    }}
                />
            </div>
            <div className='flex-1 flex flex-column'>
                <Select
                    className='basic-single flex-1'
                    classNamePrefix='select'
                    defaultValue={versionOptions[0]}
                    options={versionOptions}
                    value={versionOptions.find((x) => x.value == searchAdvanceState.is_all_version)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, is_all_version: item.value });
                    }}
                />
            </div>

        </div>
        <div className='flex-col'>
            <div className='flex items-center mt-5'>
                <img className='h-4 mr-2'
                    src={StaticImage('/images/phase2icon/calendar-week-duotone.svg')} />
                <label className='text-blue-500 text-base font-bold'>สืบค้นจากช่วงเวลา</label>
            </div>
            <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
                <div className='d-flex type-search'>
                    <div onClick={() => setsearchAdvanceState({ ...searchAdvanceState, type_date: 1 })} className={`cursor-pointer ${searchAdvanceState.type_date == 1 && "active"}`}>วันที่ประกาศ</div>
                    <div onClick={() => setsearchAdvanceState({ ...searchAdvanceState, type_date: 2 })} className={`cursor-pointer ${searchAdvanceState.type_date == 2 && "active"}`}>วันที่บังคับใช้</div>
                </div>
                <div className='flex-1'>
                    <WatDatePicker
                        value={searchAdvanceState.start_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                        onChange={(christDate, buddhistDate) => {
                            setsearchAdvanceState({ ...searchAdvanceState, start_date: moment(christDate) });
                        }}
                        inputStyle={{
                            backgroundColor: '#FFFFFF',
                            fontSize: 14,

                        }}
                        placeholder="เริ่มต้น วว/ดด/ปป"
                        dateFormat={'yyyy-MM-dd'}
                        // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                        displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                        //inputStyle={{ color: 'red' }} // styles for input
                        clearable={false} // true | false
                        //minDate={'2020-12-26'} // supported date as string
                        //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                        disabled={false} // true | false
                        readOnly={false} // true | false
                        yearBoundary={99} // number of boundary ±X Year
                    />
                </div>
                <div className='flex-1'>
                    <WatDatePicker
                        value={searchAdvanceState.end_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                        onChange={(christDate, buddhistDate) => {
                            setsearchAdvanceState({ ...searchAdvanceState, end_date: moment(christDate) });
                        }}
                        inputStyle={{
                            backgroundColor: '#FFFFFF',
                            fontSize: 14,

                        }}
                        placeholder="สิ้นสุด วว/ดด/ปป"
                        dateFormat={'yyyy-MM-dd'}
                        // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                        displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                        //inputStyle={{ color: 'red' }} // styles for input
                        clearable={false} // true | false
                        //minDate={'2020-12-26'} // supported date as string
                        //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                        disabled={false} // true | false
                        readOnly={false} // true | false
                        yearBoundary={99} // number of boundary ±X Year
                    />
                </div>
            </div>

            <div className='flex items-center mt-5'>
                <img className='h-4 mr-2'
                    src={StaticImage('/images/phase2icon/map-marker-alt-duotone.svg')} />
                <label className='text-blue-500 text-base font-bold'>สืบค้นจากสถานที่</label>
            </div>
            <div className='flex mt-3 gap-x-4'>
                <div className='flex-1'>
                    <Input
                        value={searchAdvanceState.address}
                        type={'text'}
                        placeholder={'กรอกชื่อสถานที่'}
                        onChange={(e) => {
                            setsearchAdvanceState({ ...searchAdvanceState, address: e.target.value });
                        }}
                    />
                </div>
                <div className='flex gap-x-4 hidden sm:flex'>
                    <button type="button" className={`button`} onClick={() => {
                        clearSearchAdvance();
                    }}>
                        ล้างข้อมูล
                    </button>
                    <button type="button" className={`button active`} onClick={() => {
                        onSearchAdvance();
                    }}>
                        ค้นหา
                    </button>
                </div>

            </div>
        </div>
        <div className='flex justify-between mt-3 sm:hidden'>
            <button type="button" className={`button`} onClick={() => {
                clearSearchAdvance();
            }}>
                ล้างข้อมูล
            </button>
            <button type="button" className={`button active`} onClick={() => {
                onSearchAdvance();
            }}>
                ค้นหา
            </button>
        </div>
        <div className='flex justify-center mt-5'>
            <button type="button" className={`button-close`} onClick={() => {
                setSearchState({ ...searchState, isAdvance: false })
            }}>
                ปิดการค้นหาอย่างละเอียด
            </button>
        </div>
    </div >
}

const SearchCharComponent = ({ searchState, setSearchState, masters, searchCharState, setsearchCharState, onSearchChar, clearSearchChar }) => {
    const addHirachy = (value) => {
        setsearchCharState({ ...searchCharState, hirachy: value })
    }

    const addChar = (value) => {
        setsearchCharState({ ...searchCharState, char: [value] })
    }
    const data = [{
        icon: <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scale-balanced-duotone.svg')} />,
        label: "รัฐธรรมนูญ",
        value: 887,
        values: [887]
    },
    {
        icon: <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scroll-old-duotone.svg')} />,
        label: "พ.ร.บ./พระราชกำหนด",
        value: 1,
        values: [1, 883]
    },
    {
        icon: <img className='h-6' src={StaticImage('/images/phase2icon/book-duotone.svg')} />,
        label: "ประมวลกฎหมาย",
        value: 889,
        values: [889]
    }];
    const dataChar = ["ก", "ข", "ค", "ง", "จ", "ช", "ด", "ต", "ถ", "ท", "ธ", "น", "บ", "ป", "ผ", "ฝ", "พ", "ภ", "ม", "ย", "ร", "ล", "ว", "ศ", "ษ", "ส", "ห", "ฬ", "อ", "ฮ"]
    return <div className='search-char-container flex flex-column flex-1 mt-3 gap-y-4'>
        <div className='flex-wrap hidden sm:flex'>
            {data.map((item, index) => {
                if (index == 0) return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type left whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
                else if ((masters.hirachy.length - 1) == index) return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type right whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
                else return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
            })}
        </div>
        <div className='flex-wrap flex sm:hidden'>
            <div className='d-flex items-center flex-1'>
                <div className='flex items-center'>
                    <img alt="filter-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                    <label className='mr-2 text-base'>ประเภทกฎหมาย</label>
                </div>
                <div className='d-flex flex-1'>
                    <Select
                        aria-label="agency-select"
                        className='basic-single ml-2 flex-1'
                        classNamePrefix='select'
                        defaultValue={data[0] ?? ""}
                        options={data}
                        value={data.find((x) => searchCharState.hirachy.includes(x.value))}
                        onChange={(item) => {
                            addHirachy(item.values)
                        }}
                    />
                </div>
            </div>
        </div>
        <div className='grid grid-cols-7 sm:grid-cols-10 gap-3'>
            {dataChar.map((item, index) =>
                <div onClick={() => {
                    addChar(item)
                }} className={`d-flex-center py-2 char ${searchCharState.char.includes(item) ? "active" : ""}`}>{item}</div>
            )}
        </div>
        <div className='flex gap-x-4 mt-2 justify-end'>
            <button type="button" className={`button`} onClick={() => {
                clearSearchChar()
            }}>
                ล้างข้อมูล
            </button>
            <button type="button" className={`button active`} onClick={() => {
                onSearchChar();
            }}>
                ค้นหา
            </button>
        </div>
        <div className='flex justify-center mt-5'>
            <button type="button" className={`button-close`} onClick={() => {
                setSearchState({ ...searchState, isChar: false })
            }}>
                ปิดการค้นหาตามตัวอักษร
            </button>
        </div>
    </div>
}

export default DetailLawPage;