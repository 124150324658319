import React, { useEffect, useState } from 'react';
import '../../main/submenulist.scss';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../../utils/image';
import { checkLeftTime, checkComplete } from '../../../utils';
// import YoutuberPlayer from "./react-player/YoutuberPlayer";
import { fade, makeStyles } from '@material-ui/core';
import { Space, Skeleton } from 'antd';

import Avatar from 'antd/lib/avatar/avatar';
import TagDuoTone from '../../iconComponent/TagDuoTone';
import './index.scss';
import { ConvertDateShort } from '../../../utils/date';
import ProgressBar from './ProgressBar';
import {
  ClockCircleOutlined,
} from '@ant-design/icons';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { encodeBase64 } from '../../../utils/convertBase64';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
    '&:hover': {
      backgroundColor: '#e5e6ee',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontFamily: 'Kanit',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectOption: {
    zIndex: 10,
  },
  cardSeq: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 30,
    padding: 10,
    boxShadow: '3.9px 1px 6px 0 rgba(137, 148, 169, 0.4)',
  },
  cardSeqLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardSurveyTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    flex: 1,
  },
  cardSurveyDescription: {
    color: '#666666',
    fontSize: 18,
  },
  cardSurveyTypeImage: {
    width: 20,
    height: 20,
    marginRight: 5,
    objectFit: 'contain',
    color: '#0c4da2',
    paddingTop: 5,
  },
  cardSurveyTypeText: {
    fontSize: 14,
    color: '#0c4da2',
    fontWeight: 'bold',
  },
  cardSurveyLeftIcon: {
    width: 101,
    height: 101,
    padding: '18px 22px 20px 31px',
    borderRadius: 50,
    border: 'solid 5px #85b0e8',
    backgroundColor: '#0c4da2',
  },
  cardSurveyCommentIcon: {
    fontSize: 14,
    color: '#999',
  },
}));

const SurveyCardList = ({ law_group, res, history }) => {
  const classes = useStyles();
  const [isCollapse, setIsCollapse] = useState(false);

  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 75,
                sm: 75,
                md: 75,
                lg: 75,
                xl: 75,
                xxl: 75,
              }}
              style={{
                height: 75,
                width: 75,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={100} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };

  const renderAnswerCount = (survey) => {
    let answer_count = survey.answer_count ? survey.answer_count : 0;

    if (survey.survey_id == 280) {
      answer_count += 53;
    }
    if (survey.survey_id == 281) {
      answer_count += 76;
    }
    if (survey.survey_id == 283) {
      answer_count += 99;
    }
    if (survey.survey_id == 284) {
      answer_count += 32;
    }
    if (survey.survey_id == 285) {
      answer_count += 46;
    }
    if (survey.survey_id == 286) {
      answer_count += 22;
    }
    if (survey.survey_id == 287) {
      answer_count += 20601;
    }
    if (survey.survey_id == 288) {
      answer_count += 43;
    }
    if (survey.survey_id == 289) {
      answer_count += 295;
    }
    return (
      <div className='d-flex discuss-container' style={{ marginRight: 15 }}>
        <img
          src={StaticImage('/images/icon/comment-alt-edit-duotone-4.svg')}
          style={{ marginRight: 10 }}
        />
        <label style={{ fontSize: 14, color: '#333' }}>{answer_count}</label>
      </div>
    );
  };

  return (
    <section key={'card-' + res.survey_id} style={{ marginBottom: 20 }}>
      <div
        className='card-two'
        onClick={() => {
          {
            if (res.is_coming === '1') {
            } else {
              history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
            }
          }
        }}
      >
        <div
          className='card-list-shadow-web'
          style={{
            borderRadius: '10px',
            minHeight: 250,
          }}
        >
          <div
            className='project-timeline-body-top d-flex'
            style={{
              alignItems: 'center',
              padding: 10,
              borderBottom: '1px solid #eff1f5',
              backgroundColor: '#fff',
            }}
          >
            <ClockCircleOutlined
              style={{ marginRight: 10, color: '#387436', fontSize: '1rem' }}
            />
            <span
              style={{ fontSize: '1rem', color: '#387436', fontWeight: 'bold' }}
            >
              {ConvertDateShort(res.created_date, 'DD MMM YY')}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              height: '100%',
              padding: 24,
              paddingTop: 15,
            }}
          >
            <div
              style={{
                borderRight: '1px solid #e5e6ee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {renderImageLawGroup(res)}
              <div
                className='d-flex-center'
                style={{ marginTop: 20, minWidth: 170 }}
              >
                {res.agency_id > 0 && (
                  <div
                    className='organize-container'
                    style={{ margin: 0, maxWidth: 100 }}
                  >
                    <img
                      src={res.agency_image ?? '..'}
                      className='logo'
                      oncontextmenu='return false;'
                      onError={(i) => (i.target.style.display = 'none')}
                    />
                    <label style={{ paddingLeft: '2px' }}>
                      {res.agency_abbrv}
                    </label>
                  </div>
                )}
              </div>
              <div style={{ marginTop: 20 }}>
                <TagDuoTone className={classes.cardSurveyTypeImage} />
                <span className={classes.cardSurveyTypeText}>
                  {res.survey_type_name}
                </span>
              </div>
            </div>
            <div
              style={{
                marginLeft: 40,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <label className={classes.cardSurveyTitle}>
                    {res.short_project_name ?? res.project_name}
                  </label>
                </div>
                <label className={classes.cardSurveyDescription}>
                  {res.short_survey_name ?? res.survey_name}
                </label>
                <div
                  style={{
                    display: 'flex',
                    marginTop: 20,
                    alignItems: 'center',
                  }}
                >
                  <div
                    className='d-flex discuss-container'
                    style={{ marginRight: 15 }}
                  >
                    <img
                      src={StaticImage('/images/icon/users-class-light.svg')}
                      style={{ marginRight: 10 }}
                    />
                    <label style={{ color: '#488345', fontSize: 14 }}>
                      กำลังร่วมเสนอ
                    </label>
                  </div>
                  {renderAnswerCount(res)}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <span style={{ fontSize: 16 }}>
                    {ConvertDateShort(res.start_date, 'DD MMM YY')}
                  </span>
                  <section
                    className='progress-list-filter'
                    style={{ width: '100%', maxWidth: 400 }}
                  >
                    <ProgressBar
                      bgcolor={res.date_balance > 7 ? '#387436' : '#f96b57'}
                      bgContainerColor={
                        res.date_balance > 7 ? '#717377' : '#717377'
                      }
                      leftTime={checkLeftTime(res)}
                      completed={checkComplete(res)}
                    />
                  </section>
                  <span>
                    <span style={{ fontSize: 16 }}>
                      {ConvertDateShort(res.end_date, 'DD MMM YY')}
                    </span>
                  </span>
                </div>

                <Space>
                  <span
                    style={{
                      color: '#494949',
                      fontSize: 16,
                      marginRight: 5,
                      fontWeight: 'bold',
                    }}
                  >
                    ครั้งที่
                  </span>
                  <span
                    style={{
                      color: '#0c4da2',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }}
                  >
                    {res.publish_no
                      ? res.publish_no.toString().padStart('2', '0')
                      : '00'}
                  </span>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SurveyCardList;
