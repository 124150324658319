import React from "react";
import { StaticImage } from "../../utils/image";
import "./scss/index.scss";
import { Row, Col, Divider } from "antd";
import MapMargetDuoTone from "../iconComponent/MapMargetDuoTone";
import informationmodel from "./Information.model";
import EditDuoTone from "../iconComponent/EditDuoTone";

const Information = () => {
  return (
    <div>
      <section className="background-data">
        <div className="background-text-in-side">
          {`โครงการแก้ไข พ.ร.บ.รักษาความมั่นคงปลอดภัย... >> ร่างพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิว...`}
        </div>
      </section>
      <section>
        <img
          className="section-sub-branner-image-section"
          src={StaticImage("/images/branner/layer-2@3x.png")}
        />
      </section>
      <section>
        <div className="text-in-side-3">
          <p>สำนักงานคณะกรรมการกำกับหลักทรัพย์และ ตลาดหลักทรัพย์ ​​​ก.ล.ต..</p>
        </div>
      </section>
      <section>
        <div className="text-in-side-4">
          <p style={{ color: "#0c4da2" }}>ข้อมูลการติดต่อ</p>
        </div>
      </section>
      <section>
        <div>
          {informationmodel.map((res, key) => {
            return (
              <div key={key}>
                <img
                  className="Shape-1"
                  src={StaticImage("/images/Shape-1.png")}
                />
                <div className="text-in-side-5">
                  <Row wrap={false}>
                    <Col flex="none">
                      <div style={{ padding: "0 13px" }}>{res.icon}</div>
                    </Col>
                    <Col flex="auto" className="map-marker-alt-duotone-p">
                      <p>{res.content}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section>
        <div className="Rounded-Rectangle-4-copy">
          <button className="Rounded-Rectangle-8">
            <div className="flexs-container">
              <EditDuoTone className="edit-duotone" />
              <p className="p">เริ่มแสดงความคิดเห็น</p>
            </div>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Information;
