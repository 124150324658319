// export const WEB_API = 'http://159.65.3.230:3230/api/v1/';
export const WEB_SITE = 'https://www.law.go.th';

export const WEB_API = 'https://www.law.go.th:8443/';
//export const WEB_API = 'http://192.168.72.154:8000/';
//export const WEB_API = 'https://lawtest.egov.go.th/api/v1/';
//export const WEB_API = `http://localhost:4001/`;

//beta
//export const WEB_API = 'http://164.115.77.167:3230/api/v1/';
//export const WEB_SITE = 'https://lawtest.egov.go.th/';

// //beta domain
// export const WEB_API = 'https://lawtest.egov.go.th/api/v1/';

//export const WEB_API = 'https://lawtestbeta.egov.go.th/api/v1/';
