import React, { useState, useEffect } from 'react'
import './index.scss'
import {
    Input,
    Card,
    Avatar,
    Skeleton,
    Table,
    Tag,
    Space,
    Button
} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPollH,
    faTimesCircle,
    faArrowCircleDown,
    faSync,
    faSlidersH,
    faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import ApiMaster from '../../api/master';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { ConvertDateShort } from '../../utils/date';
import { useHistory } from 'react-router';
import { encodeBase64 } from '../../utils/convertBase64';
import { Link } from 'react-router-dom';
import {
    FileOutlined,
} from '@ant-design/icons';
import moment from 'moment';

// import TagDuoTone from "../../../iconComponent/TagDuoTone";
// import { StaticImage } from '../../../../utils/image';
// import { ConvertDateShort } from '../../../../utils/date';
// import { checkComplete, checkLeftTime, checkLeftTimeIcon, checkLeftTimeStatus } from '../../../../utils';
const { Search } = Input;

const yearList = [
    { value: null, label: "ทั้งหมด" },
    // { value: "2564", label: "2564" },
    // { value: "2563", label: "2563" },
];

const inSystemOption = [
    { value: null, label: "ทั้งหมด" },
    { value: 1, label: "ผ่านระบบ" },
    { value: 2, label: "ยังไม่ประเมิน" },
]







const EvaluateTable2 = ({ ministryOption, type = "desktop" }) => {

    const expandedRowRender = (recordMain, indexMain, indent, expanded) => {
        if (recordMain.law_evaluate.length == 0) {
            return <></>
        }
        const columns = [
            {
                key: 'table_of_law_id',
                title: 'ที่',
                defaultSortOrder: 'ascend',
                width: '1%',
                // sorter: (a, b) => a.number - b.number,
                render: (text, record, index) => {
                    return (
                        <span>{index + 1}</span>
                    );
                },
            },
            {
                title: 'กฎหมายที่ประเมินฯ / ผู้รักษาการ / หน่วยงานผู้รับผิดชอบ / ปีที่ประเมิน / ประเมินฯผ่านระบบกลางล่าสุด',
                dataIndex: 'law_name_th',
                width: '99%',
                // defaultSortOrder: 'descend',
                // sorter: (a, b) => a.law_name?.trim()?.localeCompare(b.law_name?.trim()),
                render: (text, record, index) => {

                    const renderYear = () => {
                        if (record.type == "external") {
                            return <span>{`ประเมินนอกระบบ ${ConvertDateShort(record.end_date, "DD MMM YY")}`}</span>
                        }
                        return <span>{ConvertDateShort(record.law_year_evaluate, "YYYY")}</span>
                    }

                    const renderEvaluate = () => {
                        if (record.type == "external") {
                            return <Button onClick={() => {
                                window.open(record.survey_path)
                            }} className="evaluate-button" color={'green'}>
                                ดาวน์โหลดไฟล์
                            </Button>
                        } else {
                            return <Link to={`/listeningDetail?survey_id=${encodeBase64(record.survey_id)}`}>
                                <Button className="evaluate-button" color="green">
                                    {ConvertDateShort(record.evaluate_date, "DD MMM YY")}
                                </Button>
                            </Link>
                        }
                    }

                    const renderEvaluateSummary = () => {
                        let law_evaluate = record;
                        if (!law_evaluate.summary_file) {
                            return <Tag className='w-fit' style={{ whiteSpace: 'break-spaces', fontSize: 12 }} color={'geekblue'}>
                                ยังไม่สรุปผล
                            </Tag>
                        } else {
                            return <Button onClick={() => {
                                window.open(law_evaluate.summary_file)
                            }} className="evaluate-button w-fit" color={'green'}>
                                {ConvertDateShort(law_evaluate.summary_updated_date, "DD MMM YY")}
                            </Button>
                        }
                    }

                    return (
                        <div>
                            <span><span className='font-bold'>ชื่อกฎหมาย : </span>{`${record?.law_name_th}`}</span>
                            <br />
                            <span><span className='font-bold'>ผู้รักษาการ : </span>{`${record?.acting}`}</span>
                            <br />
                            <span><span className='font-bold'>หน่วยงานผู้รับผิดชอบ : </span>{`${record?.agency}`}</span>
                            <br />
                            <span><span className='font-bold'>ปีที่ต้องประเมิน : </span>{``}</span> {renderYear()}
                            <br />
                            <span><span className='font-bold'>ข้อยกเว้น : </span>{`${record?.law_exception ?? "-"}`}</span>
                            <div className='mt-1 flex flex-col sm:flex-row gap-x-2 sm:items-center'>
                                <span className='font-bold'>ประเมินฯผ่านระบบกลางล่าสุด : </span>{renderEvaluate()}
                                <span className='font-bold'>สรุปผลผ่านระบบกลาง : </span>{renderEvaluateSummary()}
                            </div>
                        </div>
                    );
                },
            }
        ];
        return <Table columns={columns} dataSource={recordMain.law_evaluate} pagination={false} />;
    };

    const columns = [
        {
            key: 'table_of_law_id',
            title: 'ที่',
            defaultSortOrder: 'ascend',
            width: '1%',
            // sorter: (a, b) => a.number - b.number,
            render: (text, record, index) => {
                return (
                    <span>{(page - 1) * 10 + (index + 1)}</span>
                );
            },
        },
        {
            title: 'กฎหมายที่ประเมินฯ / ผู้รักษาการ / หน่วยงานผู้รับผิดชอบ / ปีที่ประเมิน / ประเมินฯผ่านระบบกลางล่าสุด',
            dataIndex: 'law_name',
            width: '99%',
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.law_name?.trim()?.localeCompare(b.law_name?.trim()),
            render: (text, record, index) => {
                const renderYear = () => {
                    console.log(record)
                    if (record.law_evaluate.length == 0) {
                        return <span>{ConvertDateShort(record?.law?.law_year_evaluate, "YYYY")}</span>
                    } else {
                        let law_evaluate = record.law_evaluate[0];
                        if (law_evaluate.type == "external") {
                            // return <span>{`ประเมินนอกระบบ ${ConvertDateShort(law_evaluate.start_date, "DD MMM YY")} - ${ConvertDateShort(law_evaluate.end_date, "DD MMM YY")}`}</span>
                            return <span>{`ประเมินนอกระบบ ${ConvertDateShort(law_evaluate.end_date, "DD MMM YY")}`}</span>
                        }
                        return <span>{ConvertDateShort(law_evaluate.law_year_evaluate, "YYYY")}</span>
                    }
                }

                const renderEvaluate = () => {
                    if (record.law_evaluate.length == 0) {
                        return <Tag className='w-fit' style={{ whiteSpace: 'break-spaces', fontSize: 12 }} color={'geekblue'}>
                            ยังไม่ประเมินผล
                        </Tag>
                    } else {
                        let law_evaluate = record.law_evaluate[0];
                        if (law_evaluate.type == "external") {
                            return <Button onClick={() => {
                                window.open(law_evaluate.survey_path)
                            }} className="evaluate-button w-fit" color={'green'}>
                                ดาวน์โหลดไฟล์
                            </Button>
                        } else {
                            return <Link className='w-fit' to={`/listeningDetail?survey_id=${encodeBase64(law_evaluate.survey_id)}`}>
                                <Button className="evaluate-button" color="green">
                                    {ConvertDateShort(law_evaluate.evaluate_date, "DD MMM YY")}
                                </Button>
                            </Link>
                        }
                    }
                }

                const renderEvaluateSummary = () => {
                    if (record.law_evaluate.length == 0) {
                        return <Tag className='w-fit' style={{ whiteSpace: 'break-spaces', fontSize: 12 }} color={'geekblue'}>
                            ยังไม่สรุปผล
                        </Tag>
                    } else {
                        let law_evaluate = record.law_evaluate[0];
                        if (!law_evaluate.summary_file) {
                            return <Tag className='w-fit' style={{ whiteSpace: 'break-spaces', fontSize: 12 }} color={'geekblue'}>
                                ยังไม่สรุปผล
                            </Tag>
                        } else {
                            return <Button onClick={() => {
                                window.open(law_evaluate.summary_file)
                            }} className="evaluate-button w-fit" color={'green'}>
                                {ConvertDateShort(law_evaluate.summary_updated_date, "DD MMM YY")}
                            </Button>
                        }
                    }
                }

                return (
                    <div>
                        <span><span className='font-bold'>ชื่อกฎหมาย : </span>{`${record?.law_name}`}</span>
                        <br />
                        <span><span className='font-bold'>ผู้รักษาการ : </span>{`${record?.law?.acting}`}</span>
                        <br />
                        <span><span className='font-bold'>หน่วยงานผู้รับผิดชอบ : </span>{`${record?.law?.agency}`}</span>
                        <br />
                        <span><span className='font-bold'>ปีที่ต้องประเมิน : </span>{``}</span> {renderYear()}
                        <br />
                        <span><span className='font-bold'>ข้อยกเว้น : </span>{`${record?.law_exception ?? "-"}`}</span>
                        <div className='mt-1 flex flex-col sm:flex-row gap-x-2 sm:items-center gap-y-1'>
                            <span className='font-bold'>ประเมินฯผ่านระบบกลางล่าสุด : </span>{renderEvaluate()}
                            <span className='font-bold'>สรุปผลผ่านระบบกลาง : </span>{renderEvaluateSummary()}
                        </div>
                    </div>
                );
            },
        },
    ];



    const gotoSurvey = (item) => {
        history.push(`/listeningDetail?survey_id=${encodeBase64(item.survey_id)}`);
    }
    const history = useHistory();
    const [agency, setagency] = useState(null);
    const [ministry, setministry] = useState(null);
    const [inSystemSelected, setInSystem] = useState(null);
    const [yearSelected, setYear] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");
    const [law_list, setlaw_list] = useState([]);
    const [law_list_filter, setlaw_listFilter] = useState([]);
    const [ministryOptionCustom, setministryOptionCustom] = useState(ministryOption)
    const [page, setPage] = React.useState(1);
    const [searchInputState, setsearchInputState] = useState('');
    const cleardata = () => {
        setsearchInputState('');
        setSearchFilter("");
        setYear(null);
        setagency(null);
        setministry(null);
        setInSystem(null);
    };

    useEffect(() => {
        let listData = [...law_list];

        if (yearSelected != null) {
            var evaluates = listData.filter(x => x.law_evaluate?.length > 0 || x.law.law_year_evaluate);
            var newListData = [];
            evaluates.map((item, index) => {

                if (item.law_evaluate?.length > 0) {
                    item.law_evaluate.map((evaluate) => {
                        if (evaluate.type == "external") {
                            let year = moment(evaluate.start_date).add(543, 'years').year();
                            if (year == yearSelected) {
                                newListData.push(item)
                            }
                        } else {
                            if (evaluate.law_year_evaluate) {
                                let year = moment(evaluate.law_year_evaluate).add(543, 'years').year();
                                if (year == yearSelected) {
                                    newListData.push(item)
                                }
                            }

                        }
                    })
                } else {
                    if (item?.law?.law_year_evaluate) {
                        console.log(item?.law?.law_year_evaluate)
                        let year = moment(item?.law?.law_year_evaluate).add(543, 'years').year();
                        if (year == yearSelected) {
                            newListData.push(item)
                        }
                    }
                }
            })
            listData = newListData;


        }
        if (ministry != null) {
            listData = listData.filter(x => x.law?.agency == ministry);
        }
        if (inSystemSelected != null) {
            if (inSystemSelected == 1) {
                // listData.map((x => {
                //     if (x.table_of_law_id == 8299 || x.table_of_law_id == 8296) {
                //         console.log(x)
                //     }
                // }))
                // console.log(listData);
                // if (item.law_evaluate?.length > 0) {
                // }
                listData = listData.filter(x => x?.law_evaluate?.length > 0);

            } else {
                // listData = listData.filter(x => !x?.law?.law_year_evaluate);
                listData = listData.filter(x => (x?.law_evaluate == null || x?.law_evaluate?.length == 0));
            }
        }
        if (searchFilter.length > 0) {
            listData = listData.filter(x => (x.law_name.includes(searchFilter)
                || x.agency_name?.includes(searchFilter)
                || x.agency_responsible?.includes(searchFilter)
            ));
        }
        setlaw_listFilter(listData);
        return () => { };
    }, [agency, ministry, inSystemSelected, yearSelected, searchFilter]);


    useEffect(() => {
        getLawList();
        return () => {

        }
    }, [])

    const getLawList = async () => {
        try {
            Swal.showLoading();
            const law_list = await ApiMaster.law_list();
            if (law_list.status == 200) {
                const { data, ministry_law_list } = law_list.data;
                //console.log("law_list::", data)
                let agencyOptions = data.filter(x => x.law?.agency).map(x => x.law?.agency);
                agencyOptions = _(agencyOptions).groupBy(x => x).map((value, key) => key).value();
                var evaluates = data.filter(x => x.law_evaluate?.length > 0);
                let year_group = [];
                evaluates.map((item, index) => {
                    item.law_evaluate.map((evaluate) => {
                        if (evaluate.type == "external") {
                            let year = moment(evaluate.start_date).add(543, 'years').year();
                            if (!year_group.includes(year)) {
                                year_group.push(year)
                            }
                        } else {
                            if (evaluate.law_year_evaluate) {
                                let year = moment(evaluate.law_year_evaluate).add(543, 'years').year();
                                if (!year_group.includes(year)) {
                                    year_group.push(year)
                                }
                            }

                        }
                    })
                })
                //let year_group = _(data).groupBy('law_year_evaluate').map((value, key) => key).value();
                let ministry_group = _(data).groupBy('ministry_id').map((value, key) => key).value();
                let items_ministry = [{ value: null, label: "ทั้งหมด" }];

                // ministry_group.map((group, index) => {
                //     const item = ministryOption.find(x => x.value == group);
                //     if (item) {
                //         items_ministry.push(item);
                //     }
                // });


                agencyOptions = _.orderBy(agencyOptions)
                agencyOptions.map((item, index) => {
                    items_ministry.push({ value: item, label: item });
                });



                setministryOptionCustom(items_ministry);

                year_group = year_group.sort().reverse();
                setlaw_list(data);
                setlaw_listFilter(data);
                year_group.filter(x => x !== "null").map((year) => {
                    yearList.push({
                        value: year,
                        label: year
                    })
                })
                Swal.close();
            }
        } catch (error) {
            console.log(error)
            Swal.close();
        }

    }

    return (
        <div id="survey-card-container-v2" className='flex flex-col sm:flex-row gap-y-4' style={{ paddingBottom: 100 }}>
            {
                <div className="filter-container sm:hidden !m-0 !w-full">
                    <Search className="search"
                        value={searchInputState}

                        placeholder="ค้นหากฎหมาย/หน่วยงาน"
                        onChange={(e) => {
                            setsearchInputState(e.target.value)
                        }}
                        onSearch={(text) => {
                            setSearchFilter(text);
                        }} />
                    <div className='mt-4'>
                        <Card
                            title={
                                <div className="text-filter">
                                    <FontAwesomeIcon color={"#6d7481"} icon={faSlidersH} />
                                    <label>ตัวกรอง</label>
                                </div>
                            }
                            extra={
                                <div className="clear-filter" onClick={() => cleardata()}>
                                    <FontAwesomeIcon color={"#0c4ca3"} icon={faSync} />
                                    <label>ล้าง</label>
                                </div>
                            }
                            style={{ width: "100%" }}
                            className="Rounded-Rectangle-4-web"
                        >
                            <p>ปีที่ต้องประเมิน</p>
                            <Select
                                className="basic-single "
                                classNamePrefix="select"
                                defaultValue={yearList[0]}
                                options={yearList}
                                value={yearList.find((x) => x.value == yearSelected)}
                                onChange={(item) => {
                                    setYear(item.value);
                                }}
                            />
                            <p style={{ paddingTop: "1pc" }}> กระทรวง / หน่วยงานผู้รับผิดชอบ</p>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={ministryOptionCustom[0]}
                                value={ministryOptionCustom.find((x) => x.value == ministry)}
                                options={ministryOptionCustom}
                                onChange={(item) => {
                                    setministry(item.value);
                                    setagency(null);
                                }}
                            />
                            <p style={{ paddingTop: "1pc" }}>
                                ประเมินผลสัมฤทธิ์ผ่านระบบกลาง
                            </p>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={inSystemOption[0]}
                                options={inSystemOption}
                                value={inSystemOption.find((x) => x.value == inSystemOption)}
                                onChange={(item) => {
                                    setInSystem(item.value);
                                }}
                            />
                        </Card>
                    </div>
                </div>
            }
            {
                (type == "mobile" && searchFilter) &&
                <div className="survey-card-container flex-1">
                    <Table
                        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                        pagination={{
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        stripe
                        bordered
                        columns={columns}
                        dataSource={law_list_filter}
                        showSorterTooltip=
                        {false} />
                </div>
            }

            {
                (type == "desktop") && <div className="survey-card-container flex-1">
                    <Table
                        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                        pagination={{
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        stripe
                        bordered
                        columns={columns}
                        dataSource={law_list_filter}
                        showSorterTooltip=
                        {false} />
                </div>
            }

            {
                <div className="filter-container hidden sm:block">
                    <Search className="search"
                        value={searchInputState}

                        placeholder="ค้นหากฎหมาย/หน่วยงาน"
                        onChange={(e) => {
                            setsearchInputState(e.target.value)
                        }}
                        onSearch={(text) => {
                            setSearchFilter(text);
                        }} />
                    <div className='mt-4'>
                        <Card
                            title={
                                <div className="text-filter">
                                    <FontAwesomeIcon color={"#6d7481"} icon={faSlidersH} />
                                    <label>ตัวกรอง</label>
                                </div>
                            }
                            extra={
                                <div className="clear-filter" onClick={() => cleardata()}>
                                    <FontAwesomeIcon color={"#0c4ca3"} icon={faSync} />
                                    <label>ล้าง</label>
                                </div>
                            }
                            style={{ width: "100%" }}
                            className="Rounded-Rectangle-4-web"
                        >
                            <p>ปีที่ต้องประเมิน</p>
                            <Select
                                className="basic-single "
                                classNamePrefix="select"
                                defaultValue={yearList[0]}
                                options={yearList}
                                value={yearList.find((x) => x.value == yearSelected)}
                                onChange={(item) => {
                                    setYear(item.value);
                                }}
                            />
                            <p style={{ paddingTop: "1pc" }}> กระทรวง / หน่วยงานผู้รับผิดชอบ</p>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={ministryOptionCustom[0]}
                                value={ministryOptionCustom.find((x) => x.value == ministry)}
                                options={ministryOptionCustom}
                                onChange={(item) => {
                                    setministry(item.value);
                                    setagency(null);
                                }}
                            />
                            <p style={{ paddingTop: "1pc" }}>
                                ประเมินผลสัมฤทธิ์ผ่านระบบกลาง
                            </p>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={inSystemOption[0]}
                                options={inSystemOption}
                                value={inSystemOption.find((x) => x.value == inSystemOption)}
                                onChange={(item) => {
                                    setInSystem(item.value);
                                }}
                            />
                        </Card>
                    </div>
                </div>
            }
        </div>
    )
}

export default EvaluateTable2;