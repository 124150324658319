import './index.scss'
import { Checkbox, Col, DatePicker, Input, Row, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../../utils/WindowSize';
import Select from 'react-select'
import { useEffect, useState, useRef } from 'react';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import { SearchOutlined } from '@ant-design/icons';
import ApiLaw from '../../../api/law';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { ConvertDateShort } from '../../../utils/date';
import { StaticImage } from '../../../utils/image';
import ReactPaginate from 'react-paginate';
import { WatDatePicker } from 'thaidatepicker-react';
import moment from 'moment';
import { numberToThai } from '../../../utils';
import AlertError from '../../../utils/AlertError';
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';
const filterOptions = [
    { value: 1, label: 'ชื่อ' },
    { value: 2, label: 'ชื่อและเนื้อหา' },
];
const typeOptions = [
    { value: null, label: 'ทั้งหมด' },
    { value: 1, label: 'กฎกระทรวง' },
    { value: 2, label: 'ประกาศ' },
    { value: 3, label: 'ระเบียบ' },
    { value: 4, label: 'คำสั่ง' },
    { value: 5, label: 'พระราชบัญญัติ' },
];
const versionOptions = [
    { value: null, label: 'แสดงทุกฉบับ' },
    { value: 1, label: 'ฉบับตั้งต้น' },
];
const statusOptions = [
    { value: null, label: 'แสดงทุกสถานะ' },
    { value: 1, label: 'ประกาศใช้' },
    { value: 2, label: 'ยกเลิก' },
];
const pageSizeOp = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
    { value: 50, label: '50' },
    { value: 9999, label: "ทั้งหมด" },
];
const SearchResultPage = (props) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const [totalPage, setTotalPage] = useState(1);
    const [totalItem, setTotalItem] = useState(9999);
    const [pageSize, setPageSize] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const searchAdvanceStore = useSelector((state) => state.searchAdvance);
    const searchCharStore = useSelector((state) => state.searchChar);
    let searchFormat = new URLSearchParams(props.location.search).get('format');
    let searchType = new URLSearchParams(props.location.search).get('type') ?? 1;
    let searchText = new URLSearchParams(props.location.search).get('searchText') ?? "";
    const [searchState, setSearchState] = useState({
        isAdvance: false,
        isChar: false,
        agency: []
    });
    const [laws, setLaws] = useState([]);
    const [surveys, setSurveys] = useState([
        {},
        {},
        {},
        {},
        {}
    ]);
    const [masters, setMasters] = useState({
        agency: [],
        hirachy: [],
        category: [],
    });
    const [width, height] = useWindowSize();
    const history = useHistory();

    const [filter, setfilter] = useState({
        type: null,
        agency: null,
        hirachy: null,
        searchType: searchType,
        searchText: searchText,
    });

    const [searchAdvanceState, setsearchAdvanceState] = useState({
        searchText: "",
        searchTextType: "",
        hirachy: "",
        type: "",
        status: "",
        category: "",
        is_all_version: "",
        type_date: 1,
        start_date: "",
        end_date: "",
        address: ""
    });

    const [searchCharState, setsearchCharState] = useState({
        hirachy: [],
        char: [],
    });



    useEffect(() => {
        const promise1 = getMaster();
        const promise2 = getData();
        Swal.showLoading();
        Promise.all([promise1, promise2]).then(result => {
            Swal.close();
        })
    }, []);

    const getData = async () => {
        setSearchState({
            ...searchState,
            isAdvance: false,
            isChar: false
        })
        try {
            if (searchFormat == "search") {
                const result = await ApiLaw.getSearchResult({
                    ...filter,
                    size: pageSize != 10 ? pageSize > totalItem ? totalItem : pageSize : pageSize,
                    page: pageNo
                });
                if (result.status == 200) {
                    const { data } = result;
                    setLaws(data.rows);
                    setTotalPage(data.lastPage);
                    setTotalItem(data.total);
                }
            } else if (searchFormat == "searchAdvance") {
                const result = await ApiLaw.getSearchResultAdvance({
                    ...searchAdvanceStore, agency: searchState.agency.map(x => x.value),
                    size: pageSize != 10 ? pageSize > totalItem ? totalItem : pageSize : pageSize,
                    page: pageNo
                });
                setsearchAdvanceState(searchAdvanceStore);
                if (result.status == 200) {
                    const { data } = result;
                    setLaws(data.rows);
                    setTotalPage(data.lastPage);
                    setTotalItem(data.total);
                }
            }
            else if (searchFormat == "searchChar") {
                const result = await ApiLaw.getSearchChar({
                    ...searchCharStore, agency: searchState.agency.map(x => x.value),
                    size: pageSize != 10 ? pageSize > totalItem ? totalItem : pageSize : pageSize,
                    page: pageNo
                });
                setsearchCharState(searchCharStore);
                if (result.status == 200) {
                    const { data } = result;
                    setLaws(data.rows);
                    setTotalPage(data.lastPage);
                    setTotalItem(data.total);
                }
            }
        }
        catch (error) {
            const { data } = error.response;
            AlertError('', data?.message);
            // Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
            //     // history.go(-1);
            // });
        }
    }

    const getDataRefresh = async (payload) => {
        Swal.showLoading();
        setSearchState({
            ...searchState,
            isAdvance: false,
            isChar: false
        })
        try {
            if (payload.searchFormatProps == "search") {
                const result = await ApiLaw.getSearchResult({
                    type: null,
                    agency: null,
                    hirachy: null,
                    searchType: payload.searchTypeProps,
                    searchText: payload.searchTextProps,
                    size: pageSize != 10 ? pageSize > totalItem ? totalItem : pageSize : pageSize,
                    page: pageNo
                });
                if (result.status == 200) {
                    const { data } = result;
                    setLaws(data.rows);
                    setTotalPage(data.lastPage);
                    setTotalItem(data.total);
                }
            } else if (payload.searchFormatProps == "searchAdvance") {
                const result = await ApiLaw.getSearchResultAdvance({
                    ...searchAdvanceStore,
                    hirachy: searchAdvanceStore.hirachy ?? "",
                    type: searchAdvanceStore.type ?? "",
                    status: searchAdvanceStore.status ?? "",
                    category: searchAdvanceStore.category ?? "",
                    is_all_version: searchAdvanceStore.is_all_version ?? "",
                    agency: searchState.agency.map(x => x.value),
                    size: pageSize != 10 ? pageSize > totalItem ? totalItem : pageSize : pageSize,
                    page: pageNo
                });
                if (result.status == 200) {
                    const { data } = result;
                    setLaws(data.rows);
                    setTotalPage(data.lastPage);
                    setTotalItem(data.total);
                }
            }
            else if (payload.searchFormatProps == "searchChar") {
                const result = await ApiLaw.getSearchChar({
                    ...searchCharStore, agency: searchState.agency.map(x => x.value),
                    size: pageSize != 10 ? pageSize > totalItem ? totalItem : pageSize : pageSize,
                    page: pageNo
                });
                if (result.status == 200) {
                    const { data } = result;
                    setLaws(data.rows);
                    setTotalPage(data.lastPage);
                    setTotalItem(data.total);
                }
            }
            Swal.close();
        } catch (error) {
            const { data } = error.response;
            AlertError('', data?.message);
            // Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
            //     // history.go(-1);
            // });
        }
    }

    useEffect(() => {
        let searchFormatProps = new URLSearchParams(location.search).get('format');
        let searchTypeProps = new URLSearchParams(location.search).get('type') ?? 1;
        let searchTextProps = new URLSearchParams(location.search).get('searchText');
        getDataRefresh({
            searchFormatProps,
            searchTypeProps,
            searchTextProps
        })
    }, [location.key])

    useEffect(() => {
        getData()
    }, [searchState.agency]);

    const getMaster = async () => {
        const result = await ApiLaw.getMaster();
        if (result.status == 200) {
            const { data } = result;
            // data.agency.unshift({ value: null, label: 'หน่วยงาน' });
            data.hirachy.unshift({ value: null, label: 'ศักดิ์กฎหมาย' });
            data.category.unshift({ value: null, label: 'แสดงทุกหมวด' });
            setMasters(data);
        }
    }

    const onSearch = async (type, searchText) => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        setPageNo(1);
        setPageSize(10);
        history.push(`/SearchResult?format=search&type=${type}&searchText=${searchText}`)
    }

    const onSearchAdvance = async () => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        setPageNo(1);
        setPageSize(10);
        history.push(`/SearchResult?format=searchAdvance&searchText=${searchAdvanceState.searchText}`)
    }

    const onSearchChar = async () => {
        setPageNo(1);
        setPageSize(10);
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        history.push(`/SearchResult?format=searchChar`)
    }

    useEffect(() => {
        let searchFormatProps = new URLSearchParams(location.search).get('format');
        let searchTypeProps = new URLSearchParams(location.search).get('type') ?? 1;
        let searchTextProps = new URLSearchParams(location.search).get('searchText');
        getDataRefresh({
            searchFormatProps,
            searchTypeProps,
            searchTextProps
        })
    }, [pageNo, pageSize]);

    const clearSearchAdvance = async () => {
        setsearchAdvanceState({
            searchText: "",
            searchTextType: "",
            hirachy: null,
            type: null,
            status: null,
            category: null,
            is_all_version: null,
            type_date: 1,
            start_date: "",
            end_date: "",
            address: ""
        })

    }

    const clearSearchChar = async () => {
        setsearchCharState({
            hirachy: [],
            char: [],
        })
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected + 1);
    };


    useEffect(() => {
        if (document.getElementById("tailwind-js")) {

        } else {
            const script = document.createElement("script");
            script.src = `${StaticImage("/tailwind.js")}`;
            script.id = "tailwind-js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    return <div id="search-result">
        <div>
            <section className='section-banner' style={{}}>
            </section>
        </div>
        <div className="d-flex-align-center px-5" style={{
            // marginTop: width >= 768 ? 50 : 50,
            zoom: width >= 768 ? '100%' : '80%'
        }}>
            <div className='w-full'>
                <div className='flex items-center flex-wrap gap-2'>
                    <div className='flex items-center'>
                        <img alt="box-icon" className='h-6 mr-2' src={StaticImage('/images/phase2icon/box-taped-duotone.svg')} />
                        <label className='title'>ศูนย์กลางข้อมูลกฎหมายของประเทศ</label>
                    </div>
                    <div onClick={() => {
                        history.push('/LawAbout')
                    }} className='flex cursor-pointer'>
                        <img alt="question-icon" className='w-4 mr-1' src={StaticImage('/images/phase2icon/circle-question-duotone.svg')} />
                        <span className='text-blue-100 italic underline'>มีกฎหมายอะไรบ้าง ?</span>
                    </div>
                </div>
                <section>
                    <SearchComponent
                        onSearch={onSearch}
                        onSearchAdvance={onSearchAdvance}
                        searchState={searchState}
                        setSearchState={setSearchState}
                        setsearchAdvanceState={setsearchAdvanceState}
                        searchAdvanceState={searchAdvanceState}
                    ></SearchComponent>
                </section>
                {searchState.isAdvance &&
                    <section>
                        <SearchAdvanceComponent
                            setsearchAdvanceState={setsearchAdvanceState}
                            searchAdvanceState={searchAdvanceState}
                            masters={masters}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            onSearchAdvance={onSearchAdvance}
                            clearSearchAdvance={clearSearchAdvance}
                        >

                        </SearchAdvanceComponent>
                    </section>
                }
                {
                    searchState.isChar &&
                    <section>
                        <SearchCharComponent
                            masters={masters}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            searchCharState={searchCharState}
                            setsearchCharState={setsearchCharState}
                            onSearchChar={onSearchChar}
                            clearSearchChar={clearSearchChar}
                        />
                    </section>
                }

            </div>
        </div>
        <div className='bg-white items-container mt-5 pt-5 pb-20 sm:pt-5 sm:pb-5'>
            <div className="d-flex-align-center px-5" style={{
                zoom: width >= 768 ? '100%' : '80%'
            }}>
                <div className='w-full'>
                    <Row className='hidden sm:flex'>
                        <Col span={15}>
                            <div className='flex justify-between items-center'>
                                <span className='text-gray '>ผลการค้นหา {`"${searchText}"`} {totalItem} รายการ</span>
                                <div className='w-24'>
                                    <Select
                                        className='basic-single mt-1 flex-1'
                                        classNamePrefix='select'
                                        defaultValue={pageSizeOp[0]}
                                        options={pageSizeOp}
                                        value={pageSizeOp.find((x) => x.value == pageSize)}
                                        onChange={(item) => {
                                            setPageSize(item.value)
                                            //setsearchAdvanceState({ ...searchAdvanceState, status: item.value });
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={8}>
                            <div className='flex items-center mt-5'>
                                <img alt="search-icon" className='h-4 mr-2'
                                    src={StaticImage('/images/phase2icon/file-search-duotone-blue.svg')} />
                                <label className='text-blue-500 text-bold'>คำค้นหา</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className='hidden sm:flex mt-5'>
                        <Col span={15}>
                            {laws.map((item, index) => {
                                return <ItemLawComponent searchType={searchAdvanceStore?.type} searchText={searchText} master={masters} item={item} key={index} />
                            })}
                            <div className='flex items-center justify-center'>
                                <ReactPaginate
                                    //nextLabel="next >
                                    className="pagination gap-x-2"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPage}
                                    // previousLabel="< previous"

                                    pageClassName="page-item"
                                    pageLinkClassName="page-link shadow"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link shadow"
                                    nextClassName="page-item"
                                    previousLabel="ก่อนหน้า"
                                    nextLabel="ถัดไป"
                                    nextLinkClassName="page-link shadow"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link shadow"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={8}>
                            <div className='filter-container flex flex-column gap-y-4'>
                                <Row className='flex items-center'>
                                    <Col span="6">
                                        <span>คำสืบค้น</span>
                                    </Col>
                                    <Col span="18">
                                        <Input aria-label='input-search' disabled value={searchAdvanceStore.searchText} />
                                    </Col>
                                </Row>
                                <Row className='flex items-center'>
                                    <Col span="6">
                                        <span>ประเภท</span>
                                    </Col>
                                    <Col span="18">
                                        <Input aria-label='input-type' disabled value={typeOptions.find((x) => x.value == searchAdvanceStore.type)?.label ?? typeOptions[0].label} />
                                    </Col>
                                </Row>
                                <Row className='flex items-center'>
                                    <Col span="6">
                                        <span>สถานะ</span>
                                    </Col>
                                    <Col span="18">
                                        <Input aria-label='input-status' disabled value={statusOptions.find((x) => x.value == searchAdvanceStore.status)?.label ?? statusOptions[0].label} />
                                    </Col>
                                </Row>
                                <Row className='flex items-center'>
                                    <Col span="6">
                                        <span>หมวด</span>
                                    </Col>
                                    <Col span="18">
                                        <Input disabled aria-label='input-category' value={masters.category.find((x) => x.value == searchAdvanceStore.category)?.label ?? masters.category[0]?.label} />
                                    </Col>
                                </Row>
                                <Row className='flex items-center'>
                                    <Col span="6">
                                        <span>ฉบับ</span>
                                    </Col>
                                    <Col span="18">
                                        <Input disabled aria-label='input-version' value={versionOptions.find((x) => x.value == searchAdvanceStore.is_all_version)?.label ?? versionOptions[0].label} />
                                    </Col>
                                </Row>
                                <Row className='flex items-center'>
                                    <Col span="6">
                                        <span>ช่วงเวลา</span>
                                    </Col>
                                    <Col span="18">
                                        <Input disabled aria-label='input-rangedate' value={searchAdvanceStore.start_date ? `${ConvertDateShort(searchAdvanceStore.start_date, 'DD MMM YY')} - ${ConvertDateShort(searchAdvanceStore.end_date, 'DD MMM YY')}` : "แสดงทุกช่วงเวลา"} />
                                    </Col>
                                </Row>
                            </div>
                            <div className='flex items-center mt-5'>
                                <img alt="agency-icon" className='h-4 mr-2'
                                    src={StaticImage('/images/phase2icon/building-columns-duotone-blue.svg')} />
                                <label className='text-blue-500 text-bold'>หน่วยงาน</label>
                            </div>
                            <div className='filter-container flex flex-column gap-y-4 mt-3'>
                                <Select
                                    aria-label='select-agency-search'
                                    isMulti
                                    className='basic-multi-select flex-1'
                                    classNamePrefix='select'
                                    //defaultValue={masters.agency[0] ?? ""}
                                    options={masters.agency}
                                    value={searchState.agency}
                                    onChange={(item) => {
                                        setSearchState({ ...searchState, agency: item })
                                    }}
                                />
                                {/* <Checkbox.Group defaultValue={[1]}>
                                    <div className='flex items-center gap-x-2'>
                                        <Checkbox
                                            value={1}
                                        ></Checkbox>
                                        <span
                                        >
                                            แสดงหน่วยงานทั้งหมด (000) รายการ
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-x-2'>
                                        <Checkbox
                                            value={2}
                                        ></Checkbox>
                                        <span
                                        >
                                            กรมเจ้าท่า (000) รายการ
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-x-2'>
                                        <Checkbox
                                            value={3}
                                        ></Checkbox>
                                        <span
                                        >
                                            กรมการขนส่งทางบก (000) รายการ
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-x-2'>
                                        <Checkbox
                                            value={4}
                                        ></Checkbox>
                                        <span
                                        >
                                            กรมส่งเสริมการปกครองท้องถิ่น (000) รายการ
                                        </span>
                                    </div>
                                </Checkbox.Group> */}
                            </div>
                        </Col>
                    </Row>
                    <div className='flex flex-col sm:hidden gap-y-4'>
                        <div className='flex justify-between items-center'>
                            <span className='text-gray '>ผลการค้นหา {`"${searchText}"`} {totalItem} รายการ</span>
                            <div className='w-24'>
                                <Select
                                    aria-label='select-pagesize'
                                    className='basic-single mt-1 flex-1'
                                    classNamePrefix='select'
                                    defaultValue={pageSizeOp[0]}
                                    options={pageSizeOp}
                                    value={pageSizeOp.find((x) => x.value == pageSize)}
                                    onChange={(item) => {
                                        setPageSize(item.value)
                                        //setsearchAdvanceState({ ...searchAdvanceState, status: item.value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            {laws.map((item, index) => {
                                return <ItemLawComponent searchType={searchType} searchText={searchText} master={masters} item={item} key={index} />
                            })}
                            <div className='flex items-center justify-center'>
                                <ReactPaginate
                                    //nextLabel="next >
                                    className="pagination gap-2 flex-wrap"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPage}
                                    // previousLabel="< previous"

                                    pageClassName="page-item"
                                    pageLinkClassName="page-link shadow"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link shadow"
                                    nextClassName="page-item"
                                    previousLabel="ก่อนหน้า"
                                    nextLabel="ถัดไป"
                                    nextLinkClassName="page-link shadow"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link shadow"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const ItemLawComponent = ({ item, master, searchText, searchType }) => {
    console.log(searchType)
    const renderRivision = (law) => {
        const effectiveStart = ConvertDateShort(item.effective_startdate, 'DD MMM YY');
        const file_docx = law.content_files.find(x => x.type == "content" && x.file_name.includes('.doc'));
        const file_pdf = law.content_files.find(x => x.type == "content" && x.file_name.includes('.pdf'));
        const announce_url = law.announce_url;
        return <div className='d-flex text-gray items-center gap-x-2'>
            {`Revision : ${effectiveStart} – ปัจจุบัน`}
            {
                announce_url && <div class="group relative py-2">
                    <button
                        name={`kritsadika-button-${item.law_name}`}
                        aria-label={`kritsadika-button-${item.law_name}`}
                        disabled={!announce_url}
                        onClick={() => {
                            window.open(announce_url);
                        }}>
                        <img className='h-4'
                            alt="kritsadika-icon"
                            src={StaticImage('/images/phase2icon/vector-smart-object.png')} /></button>
                    <div class="hidden group-hover:block ...">ประกาศราชกิจจานุเบกษา</div>
                </div>
            }
            {file_pdf && <div class="group relative py-2">
                <button
                    name={`pdf-button-${item.law_name}`}
                    aria-label={`pdf-button-${item.law_name}`}
                    disabled={
                        !file_pdf?.file_path
                    } onClick={() => {
                        window.open(file_pdf?.file_path);
                    }}>
                    <img className='h-4'
                        alt="pdf-icon"
                        src={StaticImage('/images/phase2icon/pdf.svg')} />
                </button>
                <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.pdf)</div>
            </div>}
            {
                file_docx && <div class="group relative py-2">
                    <button
                        aria-label={`docx-button-${item.law_name}`}
                        name={`docx-button-${item.law_name}`}
                        disabled={
                            !file_docx?.file_path
                        } onClick={() => {
                            window.open(file_docx?.file_path);
                        }}>
                        <img className='h-4'
                            alt="docx-icon"
                            src={StaticImage('/images/phase2icon/word.svg')} />
                    </button>
                    <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.docx)</div>
                </div>
            }

        </div>
    }

    const renderVirtue = (law) => {
        if (law.virtues?.length == 0) {
            return <></>
        }
        return <span className='text-blue underline'>
            {master.hirachy.find(x => x.value == law.virtues[0].hirachy_of_law_id)?.label}/{law.virtues[0].law_name}
        </span>
    }



    const history = useHistory();
    return <div className='law-container mb-5 d-flex'>
        <div></div>
        <div className='w-full flex flex-col gap-y-2'>
            <div className='flex flex-col sm:flex-row justify-between gap-y-2'>
                <div className='d-flex items-center justify-between'>
                    <div onClick={() => {
                        history.push(`/DetailLawPage?table_of_law_id=${item.table_of_law_id}`);
                    }} className='title-name cursor-pointer line-clamp-3' dangerouslySetInnerHTML={{
                        __html: searchText ?
                            numberToThai(item.law_name_th).replace(numberToThai(searchText), `<mark>${searchText}</mark>`)
                            : item.law_name_th
                    }}></div>
                </div>
                <div className='d-flex text-gray whitespace-nowrap items-center h-fit sm:mt-1'>
                    <img alt="clock-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/clock-duotone.svg')} />
                    <span>
                        Update : {ConvertDateShort(item.updatedate, 'DD MMM YY')}
                    </span>
                </div>
            </div>
            <div className='d-flex items-center justify-between'>

                {renderRivision(item)}
            </div>
            <div className='description line-clamp-5' dangerouslySetInnerHTML={{
                __html:
                    // searchType == "2" ?
                    //     numberToThai(item.content_all ?? "")?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '').replace(numberToThai(searchText), searchText ? `<mark>${searchText}</mark>` : "")
                    //     : numberToThai(item.content_all ?? "")?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '')
                    // item.content_all?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '').replace(searchText, searchText ? `<mark>${searchText}</mark>` : "")
                    searchType == "2" ? numberToThai(item.content_all ?? "")?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '').replace(numberToThai(searchText), searchText ? `<mark>${searchText}</mark>` : "") :
                        numberToThai(item.content_all ?? "")?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '')
            }}>

            </div>
            {
                renderVirtue(item)
            }

            <div className='flex flex-col sm:flex-row mt-2 flex-wrap gap-y-2'>
                <div className='d-flex mr-5'>
                    <div className='flex items-center'>
                        <img alt="category-icon" className='h-4 mr-2'
                            src={StaticImage('/images/phase2icon/books-duotone-1.svg')} />
                        <span>
                            {master.category.length > 0 ?
                                (master.category.filter(x => item.category_id.split(',').includes(x.value?.toString())).map(x => x.label) ?? "-") : "-"}
                        </span>
                    </div>
                </div>
                <div className='flex items-center'>
                    <img alt="agency-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/building-columns-duotone.svg')} />
                    <span>หน่วยงาน :&nbsp;
                        {master.agency.length > 0 &&
                            master.agency.filter(x => item.agency_response.split(',').includes(x.value?.toString())).map(x => x.label_short ?? x.label)?.join(',')}
                    </span>
                </div>
            </div>

        </div>
    </div >
}

const SurveyComponent = () => {
    return <div className='survey-item-container mb-4'>
        <div className='title-container flex'>
            <label className='title-name text-base'>รับฟัง ร่าง พ.ร.บ. ยกเลิก พ.ร.บ. ว่าด้วย
                ความผิดอันเกิดจากการใช้เช็ค</label>
        </div>
        <div className='content-container gap-y-2 flex flex-column'>
            <span className='text-gray'>การรับฟังความคิดเห็น เลขที่ อกม-3. 43/2563 เอ๊าะเอ็นทรานซ์ สตูดิโอ
                ซี้สต็อกคอรัปชั่นพูล ซีรีส์</span>
            <div className='flex'>
                <span>ศาล และกระบวนการยุติธรรม</span>
            </div>
            <div className='flex'>
                <span>7 ม.ค. 65 - 7 มี.ค. 65</span>
            </div>
            <div className='flex justify-between'>
                <div className='flex'>
                    <span className='text-green mr-1'>ร่วมเสนอ</span>
                    <div className='bg-green text-white px-2 rounded'>1</div>
                </div>
                <div className='flex'>
                    <span className='mr-1'>ครั้งที่</span>
                    <div className='bg-blue text-white px-2 rounded'>1</div>
                </div>
            </div>
        </div>
    </div>
}

const SearchComponent = ({ searchState, setSearchState, onSearch, setsearchAdvanceState, searchAdvanceState, onSearchAdvance }) => {
    const history = useHistory();
    const inputRef = useRef(null);
    const [search, setsearch] = useState({
        type: 1,
        searchText: "",
    });
    return <div className='flex flex-col'>
        <div className='d-flex search-component items-center flex-wrap gap-y-2'>
            <div className='d-flex type-search w-32 mr-2'>
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className='basic-single w-full flex-1'
                    classNamePrefix='select'
                    defaultValue={filterOptions[0]}
                    options={filterOptions}
                    value={filterOptions.find((x) => x.value == searchAdvanceState.type)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, type: item.value });
                    }}
                />
            </div>
            <div className='d-flex min-w-[200px] flex-1 flex relative'>
                <Input
                    value={searchAdvanceState.searchText}
                    onChange={(e) => {
                        setsearchAdvanceState({ ...searchAdvanceState, searchText: e.target.value });
                    }}
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            onSearchAdvance();
                        }
                    }}
                    className="search flex-1"
                    placeholder="พิมพ์คำค้นหากฎหมาย"
                    suffix={
                        <>
                            <div className='flex gap-x-2 items-center hidden sm:flex'>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "and") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", "").replace(".ยกเว้น.", "") + ".และ.", searchTextType: "and" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "and" && "active"}`
                                    }>และ</button>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "or") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".ยกเว้น.", "") + ".หรือ.", searchTextType: "or" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "or" && "active"}`}>หรือ</button>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "except") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".ยกเว้น.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".หรือ.", "") + ".ยกเว้น.", searchTextType: "except" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "except" && "active"}`}>ยกเว้น</button>
                                <button type="button" onClick={() => {
                                    onSearchAdvance();
                                }} className='search-button'>
                                    <SearchOutlined />
                                </button>
                            </div>
                            <div className='flex gap-x-2 items-center flex sm:hidden'>
                                <button type="button" onClick={() => {
                                    onSearchAdvance();
                                }} className='search-button'>
                                    <SearchOutlined />
                                </button>
                            </div>
                        </>
                    }
                    ref={inputRef}
                />
            </div>
            <div className='hidden sm:flex'>
                <div className='flex' style={{ marginLeft: 10 }}>
                    <button type="button" className={`button flex items-center ${searchState.isAdvance ? "active" : ""}`} onClick={() => {
                        setSearchState({ ...searchState, isAdvance: true, isChar: false })
                    }}>
                        <img alt="magnify-icon" className='w-4 mr-1'
                            src={StaticImage('/images/phase2icon/magnifying-glass-plus-duotone-1.svg')} />
                        ค้นหาแบบละเอียด
                    </button>
                </div>
                <div className='d-flex' style={{ marginLeft: 10 }}>
                    <button type="button" className={`button ${searchState.isChar ? "active" : ""}`} onClick={() => {
                        setSearchState({ ...searchState, isAdvance: false, isChar: true })
                    }}>
                        ก-ฮ
                    </button>
                </div>
            </div>
            <div className='flex flex-1 justify-between sm:hidden'>
                <div className='flex gap-x-2 items-center'>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchTextType == "and") {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", ""), searchTextType: "" });
                            }
                            else if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", "").replace(".ยกเว้น.", "") + ".และ.", searchTextType: "and" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "and" && "active"}`
                        }>และ</button>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchTextType == "or") {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", ""), searchTextType: "" });
                            }
                            else if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".ยกเว้น.", "") + ".หรือ.", searchTextType: "or" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "or" && "active"}`}>หรือ</button>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchTextType == "except") {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".ยกเว้น.", ""), searchTextType: "" });
                            }
                            else if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".หรือ.", "") + ".ยกเว้น.", searchTextType: "except" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "except" && "active"}`}>ยกเว้น</button>
                </div>
                <div className='flex gap-x-2'>
                    <div className='flex'>
                        <button type="button" className={`button flex items-center ${searchState.isAdvance ? "active" : ""}`} onClick={() => {
                            setSearchState({ ...searchState, isAdvance: true, isChar: false })
                        }}>
                            <img alt="magnify-icon" className='w-4 mr-1'
                                src={StaticImage('/images/phase2icon/magnifying-glass-plus-duotone-1.svg')} />
                            ค้นหาแบบละเอียด
                        </button>
                    </div>
                    <div className='d-flex' >
                        <button type="button" className={`button ${searchState.isChar ? "active" : ""}`} onClick={() => {
                            setSearchState({ ...searchState, isAdvance: false, isChar: true })
                        }}>
                            ก-ฮ
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </div >
}

const SearchAdvanceComponent = ({ searchState, setSearchState, masters, searchAdvanceState, setsearchAdvanceState, onSearchAdvance, clearSearchAdvance }) => {
    const inputRef = useRef(null);
    return <div className='search-advance-container flex flex-column flex-1 mt-3'>
        <div className='flex mt-5 items-center'>
            <img className='h-4 mr-2'
                src={StaticImage('/images/phase2icon/search-duotone.svg')} />
            <label className='text-blue-500 text-base font-bold'>ค้นหาใน</label>
        </div>
        <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
            <div className='flex-1 flex flex-column'>
                <span className='text-blue-100'>ศักดิ์ของกฎหมาย</span>
                <Select
                    className='basic-single mt-1 flex-1'
                    classNamePrefix='select'
                    defaultValue={masters.hirachy[0] ?? ""}
                    options={masters.hirachy}
                    value={masters.hirachy.find((x) => x.value == searchAdvanceState.hirachy)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, hirachy: item.value });
                    }}
                />
            </div>
            <div className='flex-1 flex flex-column'>
                <span className='text-blue-100'>สถานะ</span>
                <Select
                    className='basic-single mt-1 flex-1'
                    classNamePrefix='select'
                    defaultValue={statusOptions[0]}
                    options={statusOptions}
                    value={statusOptions.find((x) => x.value == searchAdvanceState.status)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, status: item.value });
                    }}
                />
            </div>
        </div>
        <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
            <div className='flex-1 flex gap-x-2 sm:items-center gap-y-2 flex-col sm:flex-row'>
                <div className='flex items-center'>
                    <img className='h-4 mr-1'
                        src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                    <span className='text-blue-500'>ตัวกรอง</span>
                </div>

                <Select
                    className='basic-single flex-1'
                    classNamePrefix='select'
                    defaultValue={masters.category[0] ?? ""}
                    options={masters.category}
                    value={masters.category.find((x) => x.value == searchAdvanceState.category)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, category: item.value });
                    }}
                />
            </div>
            <div className='flex-1 flex flex-column'>
                <Select
                    className='basic-single flex-1'
                    classNamePrefix='select'
                    defaultValue={versionOptions[0]}
                    options={versionOptions}
                    value={versionOptions.find((x) => x.value == searchAdvanceState.is_all_version)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, is_all_version: item.value });
                    }}
                />
            </div>

        </div>
        <div className='flex-col'>
            <div className='flex items-center mt-5'>
                <img className='h-4 mr-2'
                    src={StaticImage('/images/phase2icon/calendar-week-duotone.svg')} />
                <label className='text-blue-500 text-base font-bold'>สืบค้นจากช่วงเวลา</label>
            </div>
            <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
                <div className='d-flex type-search'>
                    <div onClick={() => setsearchAdvanceState({ ...searchAdvanceState, type_date: 1 })} className={`cursor-pointer ${searchAdvanceState.type_date == 1 && "active"}`}>วันที่ประกาศ</div>
                    <div onClick={() => setsearchAdvanceState({ ...searchAdvanceState, type_date: 2 })} className={`cursor-pointer ${searchAdvanceState.type_date == 2 && "active"}`}>วันที่บังคับใช้</div>
                </div>
                <div className='flex-1'>
                    <WatDatePicker
                        value={searchAdvanceState.start_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                        onChange={(christDate, buddhistDate) => {
                            setsearchAdvanceState({ ...searchAdvanceState, start_date: moment(christDate) });
                        }}
                        inputStyle={{
                            backgroundColor: '#FFFFFF',
                            fontSize: 14,

                        }}
                        placeholder="เริ่มต้น วว/ดด/ปป"
                        dateFormat={'yyyy-MM-dd'}
                        // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                        displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                        //inputStyle={{ color: 'red' }} // styles for input
                        clearable={false} // true | false
                        //minDate={'2020-12-26'} // supported date as string
                        //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                        disabled={false} // true | false
                        readOnly={false} // true | false
                        yearBoundary={99} // number of boundary ±X Year
                    />
                </div>
                <div className='flex-1'>
                    <WatDatePicker
                        value={searchAdvanceState.end_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                        onChange={(christDate, buddhistDate) => {
                            setsearchAdvanceState({ ...searchAdvanceState, end_date: moment(christDate) });
                        }}
                        inputStyle={{
                            backgroundColor: '#FFFFFF',
                            fontSize: 14,

                        }}
                        placeholder="สิ้นสุด วว/ดด/ปป"
                        dateFormat={'yyyy-MM-dd'}
                        // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                        displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                        //inputStyle={{ color: 'red' }} // styles for input
                        clearable={false} // true | false
                        //minDate={'2020-12-26'} // supported date as string
                        //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                        disabled={false} // true | false
                        readOnly={false} // true | false
                        yearBoundary={99} // number of boundary ±X Year
                    />
                </div>
            </div>

            <div className='flex items-center mt-5'>
                <img className='h-4 mr-2'
                    src={StaticImage('/images/phase2icon/map-marker-alt-duotone.svg')} />
                <label className='text-blue-500 text-base font-bold'>สืบค้นจากสถานที่</label>
            </div>
            <div className='flex mt-3 gap-x-4'>
                <div className='flex-1'>
                    <Input
                        value={searchAdvanceState.address}
                        type={'text'}
                        placeholder={'กรอกชื่อสถานที่'}
                        onChange={(e) => {
                            setsearchAdvanceState({ ...searchAdvanceState, address: e.target.value });
                        }}
                    />
                </div>
                <div className='flex gap-x-4 hidden sm:flex'>
                    <button type="button" className={`button`} onClick={() => {
                        clearSearchAdvance();
                    }}>
                        ล้างข้อมูล
                    </button>
                    <button type="button" className={`button active`} onClick={() => {
                        onSearchAdvance();
                    }}>
                        ค้นหา
                    </button>
                </div>

            </div>
        </div>
        <div className='flex justify-between mt-3 sm:hidden'>
            <button type="button" className={`button`} onClick={() => {
                clearSearchAdvance();
            }}>
                ล้างข้อมูล
            </button>
            <button type="button" className={`button active`} onClick={() => {
                onSearchAdvance();
            }}>
                ค้นหา
            </button>
        </div>
        <div className='flex justify-center mt-5'>
            <button type="button" className={`button-close`} onClick={() => {
                setSearchState({ ...searchState, isAdvance: false })
            }}>
                ปิดการค้นหาอย่างละเอียด
            </button>
        </div>
    </div >
}

const SearchCharComponent = ({ searchState, setSearchState, masters, searchCharState, setsearchCharState, onSearchChar, clearSearchChar }) => {
    const addHirachy = (value) => {
        // let hirachyList = [...searchCharState.hirachy];
        // if (hirachyList.includes(value)) {
        //     hirachyList = hirachyList.filter(x => x != value);
        // } else {
        //     hirachyList.push(value);
        // }
        setsearchCharState({ ...searchCharState, hirachy: value })
    }

    const addChar = (value) => {
        // let charList = [...searchCharState.char];
        // if (charList.includes(value)) {
        //     charList = charList.filter(x => x != value);
        // } else {
        //     charList.push(value);
        // }
        setsearchCharState({ ...searchCharState, char: [value] })
    }
    const data = [{
        icon: <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scale-balanced-duotone.svg')} />,
        label: "รัฐธรรมนูญ",
        value: 887,
        values: [887]
    },
    {
        icon: <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scroll-old-duotone.svg')} />,
        label: "พ.ร.บ./พระราชกำหนด",
        value: 1,
        values: [1, 883]
    },
    {
        icon: <img className='h-6' src={StaticImage('/images/phase2icon/book-duotone.svg')} />,
        label: "ประมวลกฎหมาย",
        value: 889,
        values: [889]
    }];
    const dataChar = ["ก", "ข", "ค", "ง", "จ", "ช", "ด", "ต", "ถ", "ท", "ธ", "น", "บ", "ป", "ผ", "ฝ", "พ", "ภ", "ม", "ย", "ร", "ล", "ว", "ศ", "ษ", "ส", "ห", "ฬ", "อ", "ฮ"]
    return <div className='search-char-container flex flex-column flex-1 mt-3 gap-y-4'>
        <div className='flex-wrap hidden sm:flex'>
            {data.map((item, index) => {
                if (index == 0) return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type left whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
                else if ((masters.hirachy.length - 1) == index) return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type right whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
                else return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
            })}
        </div>
        <div className='flex-wrap flex sm:hidden'>
            <div className='d-flex items-center flex-1'>
                <div className='flex items-center'>
                    <img alt="filter-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                    <label className='mr-2 text-base'>ประเภทกฎหมาย</label>
                </div>
                <div className='d-flex flex-1'>
                    <Select
                        aria-label="agency-select"
                        className='basic-single ml-2 flex-1'
                        classNamePrefix='select'
                        defaultValue={data[0] ?? ""}
                        options={data}
                        value={data.find((x) => searchCharState.hirachy.includes(x.value))}
                        onChange={(item) => {
                            addHirachy(item.values)
                        }}
                    />
                </div>
            </div>
        </div>
        <div className='grid grid-cols-7 sm:grid-cols-10 gap-3'>
            {dataChar.map((item, index) =>
                <div onClick={() => {
                    addChar(item)
                }} className={`d-flex-center py-2 char ${searchCharState.char.includes(item) ? "active" : ""}`}>{item}</div>
            )}
        </div>
        <div className='flex gap-x-4 mt-2 justify-end'>
            <button type="button" className={`button`} onClick={() => {
                clearSearchChar()
            }}>
                ล้างข้อมูล
            </button>
            <button type="button" className={`button active`} onClick={() => {
                onSearchChar();
            }}>
                ค้นหา
            </button>
        </div>
        <div className='flex justify-center mt-5'>
            <button type="button" className={`button-close`} onClick={() => {
                setSearchState({ ...searchState, isChar: false })
            }}>
                ปิดการค้นหาตามตัวอักษร
            </button>
        </div>
    </div>
}

export default SearchResultPage;