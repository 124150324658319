import React from 'react';
import ReactPlayer from 'react-player';
import './index.scss';
const YoutuberPlayer = ({
  url = '', mute = false, autoplay = true, styles = {}, className = 'react-player', classNamePlayer = 'player-wrapper' }) => {
  return (
    <div className={classNamePlayer} >
      <ReactPlayer
        className={className}
        url={url}
        controls
        style={styles}
        width='100%'
        height='100%'
        loop={false}
        muted={mute}
        playing={autoplay}
      //   playing={true}
      />
    </div >
  );
};

export default YoutuberPlayer;
