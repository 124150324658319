import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
} from 'react-router-dom';
import Detailsindex from '../components/details';
import Information from '../components/information';
import EvaluateDetail from '../components/evaluate';
import ListeningPageindex from '../components/listeningV2';
import ListeningDetail from '../components/listeningDetail/index';
import SurverDetail from '../components/surveyDetail'
import DraftLaw from '../components/draftLaw';
import Summary from '../components/summary';
import Conclusion from '../components/conclusion';
import PrincipleIndex from '../components/principle';
import UiStyleIndex from '../components/ui-styles';
import Contact from '../components/contact';
import MeanLaw from '../components/meanoflaw';
import WebView from '../components/webview';
import DetailinLinkindex from '../components/detailinlink';
import AnswerPublic from '../components/answerPublic';
import Post from '../components/post/post';
import Rule from '../components/post/rule';
import Comment from '../components/comment';
import Reply from '../components/reply';
import useGoogleAnalytics from './googleAnalytics';
import Analytics from 'react-router-ga';
import Main from '../components/main';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import LoginIndex from '../components/login';
import RegisterSuccess from '../components/registerSuccess';
import RegisterIndex from '../components/register';
import LoginChangeNameIndex from '../components/login/LoginChangeNameIndex';
import UserProfileIndex from '../components/userProfile/index';
import Success from '../components/result';
import TestPage from '../components/test';
import FavoriteHistory from '../components/favoriteV2';
import SurveyHistory from '../components/surveyHistory';
import NotificationHistory from '../components/notificationHistory/NotificationHistory'
import ProjectTimeLinePage from '../components/projectTimeline';
import VerifyEmail from '../components/verifyEmail';
import MeanOfLaw from '../components/meanoflaw/indexV2';
import DownloadDoc from '../components/webview/downloadDoc';
import ProjectTimelineV2 from '../components/projectTimelineV2';
import Phase2Index from '../components/phase2';
import SearchResultPage from '../components/phase2/search';
import DetailLawPage from '../components/phase2/detail';
import AboutPhase2Page from '../components/phase2/about';
import { v4 } from 'uuid';

const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const AnalyticRouter = () => {
  useGoogleAnalytics();
  return (
    <Switch>
      <Route exact path={'/'} component={ListeningPageindex}></Route>
      <Route path={'/listeningDetail'} component={SurverDetail}></Route>
      <Route path={'/DetailsList'} component={Detailsindex} />
      <Route path={'/PrincileDetail'} component={PrincipleIndex} />
      <Route path={'/AnswerPublic'} component={AnswerPublic} />
      <Route path={'/DraftLaw'} component={DraftLaw}></Route>
      <Route path={'/EvaluateDetail'} component={EvaluateDetail} />
      <Route path={'/Summary'} component={Summary} />
      <Route path={'/Success'} component={Success} />
      <Route path={'/Conclusion'} component={Conclusion} />
      <Route path={'/UiStyles'} component={UiStyleIndex} />
      <Route path={'/Contact'} component={Contact} />
      <Route path={'/MeanLaw'} component={MeanOfLaw} />
      <Route path={'/Information'} component={Information} />
      <Route path={'/WebView'} component={WebView} />
      <Route path={'/DownloadDoc'} component={DownloadDoc} />
      <Route path={'/DetailinLink'} component={DetailinLinkindex} />
      <Route path={'/ProjectTimeLine'} component={ProjectTimelineV2} />
      <Route path={'/Post'} component={Post} />
      <Route path={'/Rule'} component={Rule} />
      <Route path={'/Comment'} component={Comment} />
      <Route path={'/Reply'} component={Reply} />
      <Route path={'/Login'} component={LoginIndex} />
      <Route path={'/RegisterName'} component={LoginChangeNameIndex} />
      <Route path={'/RegisterSuccess'} component={RegisterSuccess} />
      <Route path={'/Register'} component={RegisterIndex} />
      <Route path={'/VerifyEmail'} component={VerifyEmail} />
      <Route path={'/UserProfile'} component={UserProfileIndex} />
      <Route path={'/Test'} component={TestPage} />
      {/* bottom navigation */}
      <Route path={'/FavoriteHistory'} component={FavoriteHistory} />
      <Route path={'/SurveyHistory'} component={SurveyHistory} />
      <Route path={'/NotificationHistory'} component={NotificationHistory} />
      <Route path={'/Laws'} component={Phase2Index} />
      <Route path={'/LawAbout'} component={AboutPhase2Page} />
      <Route path={'/SearchResult'} component={SearchResultPage} />
      <Route path={'/DetailLawPage'}
        render={(props) => <DetailLawPage {...props} key={v4()} />}
      />
      {/* <Route exact path={"/sc53"} component={ListeningPageindex}/> */}
    </Switch>
  );
};

// const history = createHistory();
// history.listen((location) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

const MainRouter = () => {
  return (
    <header>
      <Router history={history}>
        <AnalyticRouter />
        <Main />
      </Router>
    </header>
  );
};

export default MainRouter;
