import axios from 'axios';
import { getLocalStorage } from './localStorage';
import publicIp from 'public-ip';
import React, { useEffect, useState, useRef } from 'react';
import { StaticImage } from './image';
import DocIcon from '../icons/Doc';
// check if an element exists in array using a comparer function
// comparer : function(currentElement)

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

export const setHeaderAuth = async () => {
  const token = await getLocalStorage('token');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const setHeaderIPv4 = async () => {
  const ipv4 = await publicIp.v4();
  axios.defaults.headers.common['ipv4'] = ipv4;
};

export function checkFormatIdCard(p_iPID) {
  let isSuccess = true;
  var total = 0;
  var iPID;
  var chk;
  var Validchk;
  iPID = p_iPID.replace(/-/g, '');
  Validchk = iPID.substr(12, 1);
  var j = 0;
  var pidcut;
  for (var n = 0; n < 12; n++) {
    pidcut = parseInt(iPID.substr(j, 1));
    total = total + pidcut * (13 - n);
    j++;
  }

  chk = 11 - (total % 11);

  if (chk == 10) {
    chk = 0;
  } else if (chk == 11) {
    chk = 1;
  }
  if (chk == Validchk) {
    isSuccess = true;
  } else {
    isSuccess = false;
  }
  return isSuccess;
}

export function checkFormatUsername(username) {
  var numberPattern = /\d+/g;
  if (username.match(numberPattern)) {
    return true;
  } else {
    return false;
  }
}

export function checkFormatPassword(password) {
  var pattern =
    /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,32}$/gm;
  if (password.match(pattern)) {
    return true;
  } else {
    return false;
  }
}

export function autoTabRazor(obj) {
  /* กำหนดรูปแบบข้อความโดยให้ _ แทนค่าอะไรก็ได้ แล้วตามด้วยเครื่องหมาย 
  หรือสัญลักษณ์ที่ใช้แบ่ง เช่นกำหนดเป็น  รูปแบบเลขที่บัตรประชาชน 
  4-2215-54125-6-12 ก็สามารถกำหนดเป็น  _-____-_____-_-__ 
  รูปแบบเบอร์โทรศัพท์ 08-4521-6521 กำหนดเป็น __-____-____ 
  หรือกำหนดเวลาเช่น 12:45:30 กำหนดเป็น __:__:__ 
  ตัวอย่างข้างล่างเป็นการกำหนดรูปแบบเลขบัตรประชาชน 
  */
  var pattern = new String('___-_______-__'); // กำหนดรูปแบบในนี้
  var pattern_ex = new String('-'); // กำหนดสัญลักษณ์หรือเครื่องหมายที่ใช้แบ่งในนี้
  var returnText = new String('');
  var obj_l = obj.length;
  var obj_l2 = obj_l - 1;
  for (var i = 0; i < pattern.length; i++) {
    if (obj_l2 == i && pattern.charAt(i + 1) == pattern_ex) {
      returnText += obj + pattern_ex;
      obj = returnText;
    }
  }
  if (obj_l >= pattern.length) {
    obj = obj.substr(0, pattern.length);
  }
  console.log(obj);
  return obj;
}

export const checkComplete = (item) => {
  if (item.isconclude == 'O' || !item.isconclude) {
    if (item.date_balance > item.duration_day) {
      return 0;
    } else {
      return (
        ((item.duration_day - item.date_balance) / item.duration_day) * 100
      );
    }
  } else {
    return 100;
  }
};
export const checkLeftTime = (item) => {
  if (item.isconclude == 'O' || !item.isconclude) {
    if (item.date_balance < 0) {
      return 'ปิดการรับฟัง';
    } else {
      return `เหลือ ${item.date_balance + 1} วัน`;
    }
  } else if (item.isconclude == 'D') {
    return 'อยู่ระหว่างการสรุปผล';
  } else if (item.isconclude == 'C') {
    return 'ไม่มีการสรุปผล';
  } else if (item.isconclude == 'B') {
    return 'ปิดก่อนกำหนด';
  }
  else if (item.isconclude == 'P') {
    return 'เผยแพร่สรุปผลการรับฟัง';
  }
};

export const checkLeftTimeIcon = (item) => {
  if (item.isconclude == 'O' || !item.isconclude) {
    if (item.date_balance < 0) {
      return false;
    } else {
      return true;
    }
  } else if (item.isconclude == 'D') {
    return false;
  } else if (item.isconclude == 'C') {
    return false;
  } else if (item.isconclude == 'B') {
    return false;
  }
  else if (item.isconclude == 'P') {
    return false;
  }
};

export const checkLeftTimeStatus = (item) => {
  let color = "";
  let text = "";
  if (item.isconclude == 'O' || !item.isconclude) {
    if (item.date_balance < 0) {
      text = "ปิดการรับฟัง"
      color = "#6d7481"
    } else {
      text = `เหลือ ${item.date_balance + 1} วัน`
      color = "#6d7481"
    }
  } else if (item.isconclude == 'D') {
    text = 'อยู่ระหว่างการสรุปผล';
    color = "#ffbe06"
  } else if (item.isconclude == 'C') {
    text = 'ไม่มีการสรุปผล';
    color = "#6d7481"
  } else if (item.isconclude == 'B') {
    text = `ปิดก่อนกำหนด ${item.conclude_reason ? `(${item.conclude_reason})` : ""}`;
    color = "#6d7481"
  }
  else if (item.isconclude == 'P') {
    text = 'เผยแพร่สรุปผลการรับฟัง';
    color = "#387436"
  }
  return <div className="d-flex-align-center">
    {/* <img
      src={StaticImage('/images/icon/file-alt-duotone-3.svg')}
      style={{ marginRight: 5, color: color }}
      height={16}
      alt="public-no"
    /> */}
    <DocIcon style={{ marginRight: 5, color: color, height: 16, width: 16 }} />
    <div className="d-flex" style={{ alignItems: 'center' }}>
      <span className='status-text-progress'>
        {text}
      </span>
    </div>
  </div>
};



export const checkCardBackground = (item) => {
  if (item.isconclude == 'O' || !item.isconclude) {
    if (item.date_balance < 0) {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    } else {
      return {};
    }
  } else if (item.isconclude == 'D') {
    return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
  } else if (item.isconclude == 'C') {
    return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
  } else if (item.isconclude == 'B') {
    return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
  }
  else if (item.isconclude == 'P') {
    return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
  }
};

export const useWindowUnloadEffect = (handler, callOnCleanup) => {
  const cb = useRef();

  cb.current = handler;

  useEffect(() => {
    const handler = () => cb.current();

    window.addEventListener('beforeunload', handler);

    return () => {
      if (callOnCleanup) handler();

      window.removeEventListener('beforeunload', handler);
    };
  }, [cb]);
};

export const numberToThai = (num) => {
  // let newValue = "";
  // const arrayThai = ["๐", "๑", "๒", "๓", "๔", "๕", "๖", "๗", "๘", "๙"];
  // return value.replace("0", "๐")
  //   .replace("1", "๑")
  //   .replace("2", "๒")
  //   .replace("3", "๓")
  //   .replace("4", "๔")
  //   .replace("5", "๕")
  //   .replace("6", "๖")
  //   .replace("7", "๗")
  //   .replace("8", "๘")
  //   .replace("9", "๙")
  var array = { "1": "๑", "2": "๒", "3": "๓", "4": "๔", "5": "๕", "6": "๖", "7": "๗", "8": "๘", "9": "๙", "0": "๐" };
  var str = num.toString();
  for (var val in array) {
    str = str.split(val).join(array[val]);
  }
  return str;
}

export const numberToArabic = (num) => {
  // let newValue = "";
  // const arrayThai = ["๐", "๑", "๒", "๓", "๔", "๕", "๖", "๗", "๘", "๙"];
  // return value.replace("0", "๐")
  //   .replace("1", "๑")
  //   .replace("2", "๒")
  //   .replace("3", "๓")
  //   .replace("4", "๔")
  //   .replace("5", "๕")
  //   .replace("6", "๖")
  //   .replace("7", "๗")
  //   .replace("8", "๘")
  //   .replace("9", "๙")
  var array = { "๑": "1", "๒": "2", "๓": "3", "๔": "4", "๕": "5", "๖": "6", "๗": "7", "๘": "8", "๙": "9", "๐": "0" };
  var str = num.toString();
  for (var val in array) {
    str = str.split(val).join(array[val]);
  }
  return str;
}


export const checkContentLawType = (type = "") => {
  if (type == "หมวด" || type == "ส่วน" || type == "ตอน" || type == "บรรพ") {
    return "center"
  }
  else {
    return "left"
  }
};