import React from 'react'

const TagDuoTone = ({className}) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ariaHidden="true"
        className={className}
        viewBox="0 0 640 512"
      >
        <g className="fa-group">
          <path
            fill="currentColor"
            d="M497.94 225.94L286.06 14.06A48 48 0 0 0 252.12 0H48A48 48 0 0 0 0 48v204.12a48 48 0 0 0 14.06 33.94l211.88 211.88a48 48 0 0 0 67.88 0l204.12-204.12a48 48 0 0 0 0-67.88zM112 160a48 48 0 1 1 48-48 48 48 0 0 1-48 48z"
            className="fa-secondary"
            opacity=".4"
          />
          <path
            fill="currentColor"
            d="M625.94 293.82L421.82 497.94a48 48 0 0 1-67.88 0l-.36-.36 174.06-174.06a90 90 0 0 0 0-127.28L331.4 0h48.72a48 48 0 0 1 33.94 14.06l211.88 211.88a48 48 0 0 1 0 67.88z"
            className="fa-primary"
          />
        </g>
      </svg>
    );
}

export default TagDuoTone
