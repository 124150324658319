import React, { useEffect, useState } from 'react';
import YoutuberPlayer from '../react-player/YoutuberPlayer';
import { Avatar, Row, Col, Typography, Skeleton } from 'antd';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ListeningDetails from './ListeningDetail';
import './css/index.scss';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { StaticImage } from '../../utils/image';
import { useClipboard } from 'use-clipboard-copy';
import { ConvertDateShort } from '../../utils/date';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';
import { checkUserBool } from '../../utils/checkUser';
import { useSelector, useDispatch } from 'react-redux';
import TagDuoTone from '../iconComponent/TagDuoTone';
import { Helmet } from 'react-helmet';
import { encodeBase64 } from '../../utils/convertBase64';

const { Title, Text } = Typography;

const ListeningDetail = (props) => {
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const [isContinue, setIsContinue] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [survey, setsurvey] = useState({});
  const [share, setshare] = useState({});
  const [showShare, setShowShare] = useState(false);
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  const key = new URLSearchParams(props.location.search).get('key');
  const [law_group, setlaw_group] = useState([]);
  useEffect(() => {
    document.title = 'ข้อมูลการรับฟัง';
    getSurvey();
    if (checkUserBool(userState)) {
      checkAnswerSended();
      checkAnswer();
    }
    viewSurvey();
    if (key) {
      setinformerKey();
    }
    return () => { };
  }, []);

  const setinformerKey = async () => {
    await setLocalStorage('informer', key);
  };

  const checkAnswer = async () => {
    let surveyStorage = await ApiSurvey.getAnswerDraft({ id: survey_id });
    if (surveyStorage.status == 200) {
      try {
        const { data } = surveyStorage;
        surveyStorage = data;
        if (encodeBase64(surveyStorage.survey_id) == survey_id) {
          if (surveyStorage.answer.length > 0) {
            setIsContinue(true);
          }
        }
      } catch (error) { }
    }
  };

  const checkAnswerSended = async () => {
    let surveyStorage = await ApiSurvey.getAnswerHistory({ id: survey_id });
    if (surveyStorage.status == 200) {
      try {
        const { data } = surveyStorage;
        console.log(data);
        surveyStorage = data;
        if (encodeBase64(surveyStorage.survey_id) == survey_id) {
          setIsSended(true);
        }
      } catch (error) { }
    }
  };

  const viewSurvey = async () => {
    try {
      await ApiSurvey.viewSurvey({ id: survey_id });
    } catch (error) { }
  };

  const handleChild = (e, item) => {
    e.stopPropagation();
    history.push(`/ProjectTimeline?projectId=${encodeBase64(item.project_id)}`);
  };

  const getSurvey = async () => {
    Swal.showLoading();
    const survey = await ApiSurvey.getById({ survey_id });
    if (survey.status == 200) {
      Swal.close();
      const { data, law_group } = survey.data;

      if (!data?.survey_name) {
        Swal.fire('เกิดข้อผิดพลาด', 'ไม่พบข้อมูล Survey', 'warning').then(
          (result) => {
            history.go(-1);
          }
        );
        return false;
      }
      setshare({
        title: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${data.survey_name
          } ภายใต้โครงการ ${data.project_name} ได้ที่ ${window.location.origin
          }/listeningDetail?survey_id=${survey_id
          } ตั้งแต่วันที่ ${ConvertDateShort(
            data.start_date,
            'DD MMM YY'
          )} ถึงวันที่ ${ConvertDateShort(data.end_date, 'DD MMM YY')}

        โดยเมื่อกระบวนการรับฟังความคิดเห็นได้ดำเนินการเสร็จสิ้นแล้ว หน่วยงานผู้รับผิดชอบจะได้เผยแพร่สรุปผลการรับฟังความคิดเห็นและรายงานการวิเคราะห์ผลกระทบของร่างกฎหมายหรือรายงานการประเมินผลสัมฤทธิ์ของกฎหมาย (แล้วแต่กรณี) ซึ่งท่านสามารถเข้าถึงได้ตาม Link ข้างต้น

        มาร่วมเป็นส่วนหนึ่งในการสร้างกฎหมายของประชาชน`,
        subject: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${data.survey_name
          } ภายใต้โครงการ ${data.project_name} ได้ที่ ${window.location.origin
          }/listeningDetail?survey_id=${survey_id
          } ตั้งแต่วันที่ ${ConvertDateShort(
            data.start_date,
            'DD MMM YY'
          )} ถึงวันที่ ${ConvertDateShort(data.end_date, 'DD MMM YY')}`,
        shareUrl: `${window.location.origin}/listeningDetail?survey_id=${survey_id}`,
        titleTW: `LAW : ${data?.agency_abbrv
          } ได้เปิดรับฟังความคิดเห็น วันที่ ${ConvertDateShort(
            data.start_date,
            'DD/M/YY'
          )} - ${ConvertDateShort(data.end_date, 'DD/M/YY')} สำหรับ ${data.project_name
          }`,
        titleFB: `LAW : ${data?.agency_abbrv
          } ได้เปิดรับฟังความคิดเห็น \nวันที่ ${ConvertDateShort(
            data.start_date,
            'DD/M/YY'
          )} - ${ConvertDateShort(data.end_date, 'DD/M/YY')} \n
        สำหรับ ${data.project_name
          } \n \nมาร่วมแสดงความคิดเห็น หรือติดตามความคืนหน้าโครงการ อื่นๆ ได้ที่เว็บไซต์ Law.go.th \n\n
        ${window.location.origin}/listeningDetail?survey_id=${survey_id}`,
      });
      setsurvey(data);
      setlaw_group(law_group);
    }
  };

  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container-mobile d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 40,
                sm: 40,
                md: 40,
                lg: 40,
                xl: 40,
                xxl: 40,
              }}
              style={{
                height: 40,
                width: 40,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={40} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='smth '>
      {/* <Helmet>
        <meta charSet='utf-8' />
        <title>My Title</title>
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet> */}
      <div maxWidth='xl'>
        <section className='section-banner'>
          <div onClick={() => history.go(-1)} className='back-btn'>
            <ArrowLeftOutlined style={{ marginRight: 6 }} />
            <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
          </div>
          {survey.highlight && (
            <YoutuberPlayer
              mute={survey.ismute == '1' ? true : false}
              autoplay={survey.isautoplay == '1' ? true : false}
              url={survey.highlight}
            />
          )}
        </section>

        <section
          class='section-head'
          style={{ padding: '10px 10px 14px 10px' }}
        >
          <div
            className='d-flex w-100'
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <div className='flex-1 d-flex' style={{ alignItems: 'center' }}>
              {renderImageLawGroup(survey)}
              {survey.agency_id > 0 && (
                <div className='Rounded-Rectangle-6' style={{ marginLeft: 10 }}>
                  <img
                    src={survey.agency_image ?? '..'}
                    className='tag-layer'
                    oncontextmenu='return false;'
                    onError={(i) => (i.target.style.display = 'none')}
                  />
                  {survey.agency_abbrv}
                </div>
              )}
            </div>
            <div className='surveyType-container' style={{ marginLeft: 5 }}>
              <TagDuoTone />
              <span>{survey.survey_type_name}</span>
            </div>
          </div>
          <Row style={{ marginTop: 10 }} wrap={false}>
            <Col flex={'1'} className='d-flex'>
              <div
                onClick={(e) => handleChild(e, survey)}
                className='project-head cursor-pointer'
                style={{ flex: 1 }}
              >
                {survey.project_name}
              </div>
            </Col>
          </Row>
        </section>
        <section>
          {survey && (
            <ListeningDetails
              setShowShare={setShowShare}
              isContinue={isContinue}
              survey={survey}
              isSended={isSended}
            />
          )}
        </section>
      </div>
      {showShare && (
        <div className='share-container'>
          <div className='header'>
            <span>แชร์</span>
            <img
              onClick={() => setShowShare(false)}
              style={{ position: 'absolute', right: 25 }}
              src={StaticImage('/images/icon/drawer-header-close.png')}
            />
          </div>


          <div className='share-button'>
            <div>
              <FacebookShareButton
                url={share.shareUrl}
                quote={share.titleFB}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <span>Facebook</span>
            </div>
            {/* <div>
              <LineShareButton url={share.shareUrl} title={share.subject}>
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
            <div>
              <TwitterShareButton
                url={share.shareUrl}
                title={share.titleTW.substr(0, 170)}
                className='Demo__some-network__share-button'
              >
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <span>Twitter</span>
            </div>
            <div>
              <EmailShareButton
                url={''}
                subject={share.subject.substr(0, 120)}
                body={share.title}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <span>E-mail</span>
            </div>

            {/* <div
              onClick={() =>
                (window.location.href = 'https://line.me/ti/p/JMt9pd3ZxU')
              }
            >
              <LineShareButton
                url={share.shareUrl}
                title={share.title.substr(0, 170)}
                className='Demo__some-network__share-button'
              >
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
            <div>
              <iframe
                frameborder='0'
                data-size='large'
                src={
                  'https://timeline.line.me/social-plugin-widget/share?buttonType=share-d&amp;type=share&amp;lang=en&amp;id=1&amp;size=large&amp;origin=' +
                  share.shareUrl +
                  '&amp;title=' +
                  share.title.substr(0, 170) +
                  ''
                }
                style={{
                  width: '40px',
                  height: '40px',
                  // visibility: 'visible',
                  // position: 'static',
                  // alignItems: 'center',
                  // marginLeft: '40px',
                }}
              ></iframe>
              <span style={{ marginTop: 5 }}>LINE</span>
            </div>
            <div
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/listeningDetail?survey_id=${survey_id}`
                )
              }
            >
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/link-regular.png'
                  )}
                />
              </div>
              <span>
                {clipboard.copied ? 'คัดลอกลิงค์สำเร็จ' : 'คัดลอกลิงค์'}
              </span>
            </div>
            {/* <div
              onClick={() =>
                (window.location.href = 'https://line.me/ti/p/JMt9pd3ZxU')
              }
            >
              <div>
                <img
                  src={StaticImage('/images/buttons_en_v3/square-default.png')}
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
              <span>LINE</span>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ListeningDetail;
