import React from 'react';
import { Typography, Card } from 'antd';
import { Container } from '@material-ui/core';
import {
  faAngleDoubleRight,
  faCopy,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './css/index.css';
const { Text, Link, Title } = Typography;
const Section3 = ({ survey }) => {
  const rows = [];
  for (var i = 1; i < 3; i++) {
    rows.push(
      <Card className='principle-card'>
        <FontAwesomeIcon icon={faFileAlt} className='principle-icon-card ' />
        <Text className='principle-card-text'>
          เอกสารประกอบสภาพปัญหาโปสเตอร์บอยคอต เทรลเลอร์เทคอุปนายก...
        </Text>
      </Card>
    );
  }
  return (
    <div>
      <section style={{ paddingTop: '22px' }}>
        <Container maxWidth='xl'>
          <Title level={5} style={{ color: '#0c4da2' }}>
            ผู้เกี่ยวข้อง
          </Title>
          <div style={{ paddingTop: '22px' }}>
            <hr />
          </div>
        </Container>
      </section>
      <section style={{ paddingTop: '22px' }}>
        <Container maxWidth='xl'>
          <div
            className='ql-editor'
            dangerouslySetInnerHTML={{ __html: survey.content_affected }}
          ></div>
          {survey.content_files && survey.content_files.length > 0 && (
            <div>
              {survey.content_files.map((item, index) => {
                if (item.type !== 'affected') {
                  return <></>;
                }
                return (
                  <div className='d-flex'>
                    <label
                      style={{ fontSize: 14 }}
                      className='text-link cursor-pointer'
                      onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                    >
                      {item.file_name}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </Container>
      </section>
      <section
        style={{
          paddingTop: '15px',
          backgroundColor: '#f1f3f7',
          paddingBottom: '10px',
        }}
      ></section>
      <section style={{ paddingTop: '22px' }}>
        <Container maxWidth='xl'>
          <Title level={5} style={{ color: '#0c4da2' }}>
            เหตุผลความจำเป็นของการให้มีระบบอนุญาต
            ระบบคณะกรรมการหรือการกำหนดโทษอาญา
            รวมทั้งหลักเกณฑ์การใช้ดุลพินิจของเจ้าหน้าที่รัฐ
          </Title>
          <div style={{ paddingTop: '22px' }}>
            <hr />
          </div>
        </Container>
      </section>
      <section style={{ paddingTop: '22px' }}>
        <Container maxWidth='xl'>
          <div
            className='ql-editor'
            dangerouslySetInnerHTML={{ __html: survey.content_necessity }}
          ></div>
          {survey.content_files && survey.content_files.length > 0 && (
            <div>
              {survey.content_files.map((item, index) => {
                if (item.type !== 'nescessity') {
                  return <></>;
                }
                return (
                  <div className='d-flex'>
                    <label
                      style={{ fontSize: 14 }}
                      className='text-link cursor-pointer'
                      onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                    >
                      {item.file_name}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </Container>
      </section>
      {/* <section style={{ paddingTop: '22px' }}>
        <Container maxWidth='xl'>
          <Title level={5}>ข้อมูลประกอบ</Title>
          <section style={{ paddingTop: '18px' }}>{rows}</section>
        </Container>
      </section>
      <section style={{ paddingTop: '22px', paddingBottom: '20px' }}>
        <Container maxWidth='xl'></Container>
      </section> */}
    </div>
  );
};

export default Section3;
