import { Input } from 'antd';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import { useClipboard } from 'use-clipboard-copy';
import { StaticImage } from '../../../utils/image';

const ModalShare = ({ show = false, onClose, share = {} }) => {
    const clipboard = useClipboard({ copiedTimeout: 800 });
    if (!show) return <></>
    return <div id="defaultModal" tabindex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full modal-share">
        <div class="relative p-4 w-full max-w-2xl h-auto">
            <div class="relative bg-white rounded-lg shadow">
                <div className="border-b border-gray-200 py-2 px-5">
                    <span className="text-black-300 text-base font-bold">แบ่งปัน</span>
                </div>
                <div class="p-6 space-y-5 flex items-center justify-center flex-column">
                    <div className='flex w-100'>
                        <Input readOnly disabled value={share.shareUrl} />
                        <div
                            onClick={() =>
                                clipboard.copy(
                                    share.shareUrl
                                )
                            }
                            className="flex gap-x-2 copy-button"
                        >
                            <div className='circle'>
                                <img
                                    src={StaticImage(
                                        '/images/icon/listeningDetail/link-regular.png'
                                    )}
                                />
                            </div>
                            <span>
                                {clipboard.copied ? 'คัดลอกลิงค์สำเร็จ' : 'คัดลอกลิงค์'}
                            </span>
                        </div>
                    </div>
                    <div className='flex gap-x-10'>
                        <div className='flex-column flex justify-center items-center gap-y-2'>
                            <FacebookShareButton
                                url={share.shareUrl}
                                quote={share.title}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={40} />
                            </FacebookShareButton>
                            <span>Facebook</span>
                        </div>
                        <div className='flex-column flex justify-center items-center gap-y-2'>
                            <iframe
                                frameborder='0'
                                data-size='large'
                                src={
                                    'https://timeline.line.me/social-plugin-widget/share?buttonType=share-d&amp;type=share&amp;lang=en&amp;id=1&amp;size=large&amp;origin=' +
                                    share.shareUrl +
                                    '&amp;title=' +
                                    share.title.substr(0, 170) +
                                    ''
                                }
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    // visibility: 'visible',
                                    // position: 'static',
                                    // alignItems: 'center',
                                    // marginLeft: '40px',
                                }}
                            ></iframe>
                            <span>LINE</span>
                        </div>
                        <div className='flex-column flex justify-center items-center gap-y-2'>
                            <TwitterShareButton
                                url={share.shareUrl}
                                title={share.titleTW.substr(0, 170)}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={40} />
                            </TwitterShareButton>
                            <span>Twitter</span>
                        </div>
                    </div>
                </div>
                <div class="border-t border-gray-200 flex justify-end">
                    <button onClick={onClose} className="border-l border-gray-200 text-lg text-blue-300 py-2 px-5">ปิด</button>
                </div>
            </div>
        </div>
    </div>
}

export default ModalShare;