import { Container, Grid, Avatar, Paper } from '@material-ui/core';
import {
  Card,
  Skeleton,
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Radio,
  Modal,
  DatePicker,
  Space,
} from 'antd';
import Select from 'react-select';

import { makeStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { StaticImage } from '../../utils/image';
import { useSelector, useDispatch } from 'react-redux';
import { autoTabRazor, checkFormatIdCard } from '../../utils';
import ApiUser from '../../api/user';
import Swal from 'sweetalert2';
import ApiMaster from '../../api/master';
import AlterIndex from './alertIndex';
import RecordIndex from './recordIndex';
import SubscribeIndex from './subscribeIndex';
import { CheckFile, ConvertFileToBase64 } from '../../utils/uploadfile';
import AlertError from '../../utils/AlertError';
import AlertSuccess from '../../utils/AlertSuccess';
import NotificationSetting from './notificationSetting';
import moment from 'moment';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import { BrowserView, MobileView } from 'react-device-detect';
import UserProfileMobile from './mobile';
import UserProfileDesktop from './desktop';
import Divider from '@material-ui/core/Divider';
import ButtonComponent from '../../utils/ButtonComponent';
import { useWindowSize } from '../../utils/WindowSize';
const dateFormat = 'DD/MM/YYYY';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const UserProfileIndex = (props) => {
  const tab = new URLSearchParams(props.location.search).get(
    'tab'
  );
  const usertab = new URLSearchParams(props.location.search).get(
    'usertab'
  );
  const [width, height] = useWindowSize();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [idcard, setidcard] = useState('');
  const userState = useSelector((state) => state.user);
  const classes = useStyles();
  const history = useHistory();
  const [tab1, setTab1] = React.useState(true);
  const [tab2, setTab2] = React.useState(false);
  const [tab3, setTab3] = React.useState(false);
  const [menuFlag, setMenuFlag] = React.useState(0);
  const [componentSize, setComponentSize] = React.useState('default');
  const [nextFlag, setnextFlag] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const [prefix, setprefix] = useState(1);
  const [name_on_web, setname_on_web] = useState('');
  const [checked, setchecked] = useState('');
  const oldArr = [];
  const sexArr = [
    { label: 'ชาย', value: 0 },
    { label: 'หญิง', value: 1 },
  ];
  const prefixArr = [
    { label: 'นาย', value: 1 },
    { label: 'นาง', value: 2 },
    { label: 'นางสาว', value: 3 },
  ];

  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [dateOfBirth, setdateOfBirth] = useState(
    moment(
      moment()
        .add(543 - 7, 'y')
        .format(dateFormat),
      dateFormat
    )
  );
  const [laserCode1, setlaserCode1] = useState('');
  const [laserCode2, setlaserCode2] = useState('');
  const [laserCode3, setlaserCode3] = useState('');
  const [password, setpassword] = useState('');
  const [sexList, setsexList] = useState([]);
  const [ageList, setageList] = useState([]);
  const [educationList, seteducationList] = useState([]);
  const [provinceList, setprovinceList] = useState([]);
  const [careerList, setcareerList] = useState([]);

  const formOnWeb = React.useRef(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const masterLogin = async () => {
    const result = await ApiMaster.masterLogin();
    if (result.status) {
      const { data } = result;
      const { ageList, educationList, sexList, provinces, careerList } = data;
      setsexList(sexList);
      setageList(ageList);
      seteducationList(educationList);
      setprovinceList(provinces);
      setcareerList(careerList);
    }
  };

  const educationArr = [{ label: 'การศึกษา', value: 0 }];
  for (var i = 0; i < 100; i++) {
    var oldCount = i + 1;
    oldArr.push({ label: oldCount, value: oldCount });
  }

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const onFinish = async (values) => {
    const dateSplit = moment(dateOfBirth).format(dateFormat).split('/');
    const model = {
      citizenId: idcard,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: `${parseInt(dateSplit[2] - 543)}${dateSplit[1]}${dateSplit[0]
        }`,
      laserCode: laserCode1 + laserCode2 + laserCode3,
      password: password,
      prefix: prefix,
    };
    try {
      Swal.showLoading();
      const result = await ApiUser.lazorCode(model);
      if (result.status == 200) {
        const { data } = result;
        dispatch({ type: 'set_user', user: data });
      }
      Swal.fire('', 'ยืนยันตัวตนสำเร็จ', 'success').then((ok) => {
        handleCancel();
      });
    } catch (error) {
      const { data, status } = error.response;
      Swal.fire(data.message, '', 'error');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const menuArray = [
    {
      label: 'บัญชีผู้ใช้งาน',
      icon: '/images/login/userprofile/user-duotone-1.png',
      onPress: async () => {
        setMenuFlag(0);
      },
    },
    {
      label: 'ประวัติการตอบแบบสอบถาม',
      icon: '/images/login/userprofile/comment-alt-edit-duotone-4.png',
      onPress: async () => {
        setMenuFlag(1);
      },
    },
    {
      label: 'ประวัติการสนทนา',
      icon: '/images/login/userprofile/comments-alt-duotone-1.png',
      onPress: async () => {
        setMenuFlag(2);
      },
    },
    {
      label: 'จัดการการติดตาม',
      icon: '/images/login/userprofile/heart-circle-duotone-2.png',
      onPress: async () => {
        setMenuFlag(3);
      },
    },
    {
      label: 'ตั้งค่าการแจ้งเตือน',
      icon: '/images/login/bell-duotone-1.png',
      onPress: async () => {
        setMenuFlag(4);
      },
    },
    // {
    //   label: 'อื่นๆ',
    //   icon: '/images/login/userprofile/th-large-duotone.png',
    //   onPress: async () => {
    //     setMenuFlag(4);
    //   },
    // },
    {
      label: 'ออกจากระบบ',
      icon: '/images/login/userprofile/th-large-duotone.png',
      onPress: async () => {
        dispatch({ type: 'set_user', user: {}, token: '' });
        setTimeout(async () => {
          await localStorage.removeItem('token');
          history.push('/');
        }, 300);
      },
    },
  ];

  useEffect(() => {
    setidcard(userState.id_card);
    masterLogin();

    return () => { };
  }, []);

  const verifyIdCard = async () => {
    if (checkFormatIdCard(idcard)) {
      setnextFlag(true);
    } else {
      Swal.fire({
        text: 'ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง',
        icon: 'error',
        timer: 2000,
      });
    }
  };

  const renderTab2 = () => {
    if (userState.is_verify) {
      return (
        <Tab2Verify
          showModal={showModal}
          nextFlag={nextFlag}
          setidcard={setidcard}
          userState={userState}
        />
      );
    } else {
      return (
        <Tab2NotVerify
          showModal={showModal}
          nextFlag={nextFlag}
          setidcard={setidcard}
          userState={userState}
        />
      );
    }
  };

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];

      var name = file.name;

      const reader = new FileReader();
      Swal.showLoading();
      reader.onload = async (evt) => {
        CheckFile({
          file,
          size: 10,
          type: ['image/png', 'image/jpg', 'image/jpeg'],
        });
        const base64 = await ConvertFileToBase64(file);
        const model = {
          image: file.name,
          base64: base64,
          filename: file.name,
          type: file.type,
        };
        try {
          const update = await ApiUser.uploadImage(model);
          Swal.close();
          if (update.status == 200) {
            const { data } = update;
            dispatch({ type: 'set_user', user: data });
          }
        } catch (error) {
          AlertError('', error.response?.message);
        }
      };

      reader.readAsBinaryString(file);
    } catch (error) { }
  };

  const onEditName = async () => {
    const model = {
      name_on_web,
    };
    try {
      const result = await ApiUser.checknameV2(name_on_web);
      if (result.status == 200) {
        const update = await ApiUser.updateFirst(model);
        if (update.status == 200) {
          const user = update.data;
          dispatch({ type: 'set_user', user: user });
          AlertSuccess('', 'บันทึกสำเร็จ');
        }
      }
    } catch (error) {
      const { data, status } = error.response;
      AlertError('', data?.message);
    }
  };

  const updateNotification = async (value, key) => {
    const model = {};
    model[key] = value ? 1 : 0;
    try {
      const update = await ApiUser.updateFirst(model);
      if (update.status == 200) {
        const { data } = update;
        dispatch({ type: 'set_user', user: data });
      }
    } catch (error) { }
  };

  return (
    <div>
      {width >= 768 && <UserProfileDesktop tab={tab} usertab={usertab} />}
      {width < 768 && <UserProfileMobile tab={tab} usertab={usertab} />}
      <Modal
        // title='ยืนยันตัวบุคคลกับกรมการปกครอง'
        visible={isModalVisible}
        // onOk={handleOk}
        // onCancel={handleCancel}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        width={1024}
      >
        <Form
          name='finished'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout='vertical'
          className='form-verify-card'
        >
          <p
            className='title'
            style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}
          >
            ยืนยันตัวบุคคลกับกรมการปกครองผ่านระบบ Digital ID
          </p>
          <Row>
            <Col span={6}>
              <p>
                เลขที่บัตรประชาชน <span className='text-danger'>*</span>
              </p>
              <input value={idcard} required className='form-ant' readOnly />
            </Col>

            <Col span={9}>
              <p>
                ยืนยันรหัสผ่าน (Digital ID) <span className='text-danger'>*</span>
              </p>
              <input
                onChange={({ target }) => {
                  setpassword(target.value);
                }}
                style={{ width: 300 }}
                className='form-ant'
                type='password'
              />
            </Col>
          </Row>

          <Row
            style={{
              marginTop: 10,
            }}
          >
            {/* <Col span={6}>
              <p>
                คำนำหน้า <span className='text-danger'>*</span>
              </p>
              <div style={{ width: 200 }}>
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  defaultValue={oldArr[0].label}
                  options={prefixArr}
                  onChange={(e) => setprefix(e)}
                />
              </div>
            </Col> */}
            <Col span={9}>
              <p>
                ชื่อ <span className='text-danger'>*</span>
              </p>
              <input
                onChange={({ target }) => {
                  setfirstName(target.value);
                }}
                className='form-ant'
                name='firstName'
                required
                style={{ width: 300 }}
              />
            </Col>
            <Col span={9}>
              <p>
                นามสกุล <span className='text-danger'>*</span>
              </p>
              <input
                onChange={({ target }) => {
                  setlastName(target.value);
                }}
                className='form-ant'
                name='lastName'
                required
                style={{ width: 300 }}
              />
            </Col>
          </Row>

          <div className='hr' />
          <p className='title' style={{ textAlign: 'center' }}>
            ข้อมูลเพื่อยืนยันตัวบุคคล
          </p>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <Col span={11}>
              <div>
                <p>
                  วัน/เดือน/ปีเกิด
                  <span className='text-danger'>*</span>
                </p>

                <DatePicker
                  locale={locale}
                  format={dateFormat}
                  value={dateOfBirth}
                  onChange={(date) => {
                    if (date) {
                      setdateOfBirth(date);
                    }
                  }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <p>
                  รหัสเลเซอร์ <span className='text-danger'>*</span>
                </p>
                <div
                  className='d-flex'
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <input
                    name='laserCode1'
                    style={{ width: '25%' }}
                    className='form-ant'
                    value={laserCode1}
                    maxLength={3}
                    onChange={({ target }) => {
                      setlaserCode1(target.value);
                    }}
                    required
                  />
                  <div className='ml-2 mr-2'>-</div>
                  <input
                    name='laserCode2'
                    style={{ width: '30%' }}
                    className='form-ant'
                    value={laserCode2}
                    maxLength={7}
                    onChange={({ target }) => {
                      setlaserCode2(target.value);
                    }}
                    required
                  />
                  <div className='ml-2 mr-2'>-</div>
                  <input
                    name='laserCode1'
                    style={{ width: '15%' }}
                    className='form-ant'
                    value={laserCode3}
                    maxLength={2}
                    onChange={({ target }) => {
                      setlaserCode3(target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <Button
                htmlType='submit'
                className='bg-button'
                style={{ marginTop: 20 }}
              >
                ยืนยันตัวตน
              </Button>
            </Col>
            <Col span={1}></Col>
            <Col span={11}>
              <div>
                <img src={StaticImage('/images/login/id-card.png')} />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

const Tab2NotVerify = ({ showModal, nextFlag, setidcard, userState }) => {
  return (
    <section>
      <div className='w-100 d-flex' style={{ marginTop: 20 }}>
        {/* <img
          className='tag-openid'
          style={{ left: 10 }}
          src={StaticImage('/images/tag-openid.png')}
        /> */}
      </div>
      <label className='userprofile-lavelup-label'>
        ยกระดับความน่าเชื่อถือของผู้ใช้
      </label>
      <section>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <section>
            <img
              src={StaticImage('/images/login/badge-check-duotone-1.png')}
              className='badge-check-duotone-userprofile'
            />
            <img
              src={StaticImage('/images/login/user-circle-duotone-1.png')}
              className='user-circle-duotone-1-copy-userprofile'
            />
          </section>

          <img
            src={StaticImage('/images/login/chevron-double-right-duotone.png')}
            className='chevron-double-right-duotone-userprofile'
          />
          <section>
            <img
              src={StaticImage('/images/login/badge-check-duotone.png')}
              className='badge-check-duotone-userprofile'
            />
            <img
              src={StaticImage('/images/login/user-circle-duotone-1-copy.png')}
              className='user-circle-duotone-1-copy-userprofile'
            />
          </section>
        </div>
      </section>
      <section className='idcard-label-center-container'>
        <label className='userprofile-lavelup-label2'>
          กรอกเลขบัตรประจำตัวประชาชน
        </label>
      </section>
      <section>
        <label className='userprofile-lavelup-label3'>
          {' '}
          <img
            src={StaticImage('/images/login/info-circle-solid.png')}
            width={30}
            height={24}
            style={{ paddingRight: '0.5pc', marginLeft: 20 }}
          />
          เลขประจำตัวประชาชน 13 หลัก จะถูกใช้ในการยืนยันตัวตนกับกรมการปกครอง
        </label>
      </section>
      <section
        className={nextFlag === false ? 'userprofile-lavelup-input1' : ''}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            defaultValue={userState.id_card}
            onChange={({ target }) => setidcard(target.value)}
            size='large'
            maxLength={13}
            placeholder={
              nextFlag === false
                ? 'เลขที่บัตรประชาชน  13 หลัก'
                : 'ท่านต้องการยืนยันตัวบุคคลกับกรมการปกครอง หรือไม่?'
            }
            prefix={
              nextFlag === false && (
                <img
                  src={StaticImage('/images/login/id-card-duotone-1.png')}
                  style={{ paddingRight: '1pc' }}
                />
              )
            }
            className='userprofile-placeholder'
            disabled={nextFlag === false ? false : true}
          />
          {nextFlag === true && (
            <img
              src={StaticImage('/images/login/question-circle-duotone-3.png')}
              style={{ paddingLeft: '2pc' }}
            />
          )}
        </div>
      </section>
      <section>
        <Row>
          <Col span={13}>
            {' '}
            <section>
              <section>
                {nextFlag === true && (
                  <div>
                    {' '}
                    <div className='credit-card-duotone-userprofile'>
                      <img
                        src={StaticImage(
                          '/images/login/credit-card-duotone-big.png'
                        )}
                        className=''
                      />
                    </div>
                    <div>
                      <label className='userprofile-lavelup-label4'>
                        ยืนยันด้วยเลเซอร์โค้ด
                      </label>
                    </div>
                    <div>
                      <label className='userprofile-lavelup-label5'>
                        ยืนยันตัวบุคคลด้วยรหัสเลเซอร์โค้ด
                        ซึ่งอยู่ด้านหลังบัตรประชาชน
                      </label>
                    </div>
                    <section>
                      <button
                        onClick={showModal}
                        className='userprofile-lavelup-button'
                      >
                        กดเพื่อยืนยัน
                      </button>
                    </section>
                    {/*
                <label className="userprofile-lavelup-label5">
                  ยืนยันตัวบุคคลด้วยรหัสเลเซอร์โค้ด
                  ซึ่งอยู่ด้านหลังบัตรประชาชน
                </label>
                <section>
                  <button className="userprofile-lavelup-button">
                    กดเพื่อยืนยัน
                  </button>
                </section> */}
                  </div>
                )}
              </section>
            </section>
          </Col>
          <Col span={11}>
            {' '}
            {nextFlag === true && (
              <div>
                <img
                  src={StaticImage('/images/login/id-card.png')}
                  className='Group-7-id-card'
                />
              </div>
            )}
          </Col>
        </Row>
      </section>
    </section>
  );
};

const Tab2Verify = ({
  userState,
  sexList,
  ageList,
  educationList,
  provinceList,
}) => {
  return (
    <section className='verify-section'>
      <div className='w-100 d-flex' style={{ marginTop: 20 }}>
        {/* <img
          className='tag-openid'
          style={{ left: 10 }}
          src={StaticImage('/images/tag-openid.png')}
        /> */}
      </div>
      <label style={{ lineHeight: 3 }} className='userprofile-lavelup-label'>
        ความน่าเชื่อถือของผู้ใช้
      </label>
      <section>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <section>
            <img
              src={StaticImage('/images/login/badge-check-duotone.png')}
              className='badge-check-duotone-userprofile'
            />
            <img
              src={StaticImage('/images/login/user-circle-duotone-1-copy.png')}
              className='user-circle-duotone-1-copy-userprofile'
            />
          </section>
        </div>
      </section>
      <Space size={'middle'} direction={'vertical'} style={{ width: '100%' }}>
        <Row>
          <Col span={6}>
            <div className='d-flex'>
              <img
                style={{ marginRight: 10, height: 20, width: 20 }}
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
              <label>เลขที่บัตรประชาชน</label>
            </div>
            {/* <div style={{ width: 200 }}>
              <input
                defaultValue={userState.id_card}
                className='form-ant'
                required
                style={{ width: 200 }}
                readOnly
              />
            </div> */}
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className='d-flex'>
              <img
                style={{ marginRight: 10, height: 20, width: 20 }}
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
              <label>ชื่อ</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className='d-flex'>
              <img
                style={{ marginRight: 10, height: 20, width: 20 }}
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
              <label>นามสกุล</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className='d-flex'>
              <img
                style={{ marginRight: 10, height: 20, width: 20 }}
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
              <label>ชื่อกลาง</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className='d-flex'>
              <img
                style={{ marginRight: 10, height: 20, width: 20 }}
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
              <label>วัน เดือน ปีเกิด</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div className='d-flex'>
              <img
                style={{ marginRight: 10, height: 20, width: 20 }}
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
              <label>รหัสเลเซอร์</label>
            </div>
          </Col>
        </Row>
      </Space>
    </section>
  );
};

const Tab3 = ({
  userState,
  sexList,
  ageList,
  educationList,
  provinceList,
  careerList,
}) => {
  const [checked, setchecked] = useState('');
  const [name_on_web, setname_on_web] = useState('');
  const [is_show_name, setis_show_name] = useState(1);
  const [favorite_law_group, setfavorite_law_group] = useState([]);
  const [age_range, setage_range] = useState(0);
  const [sex, setsex] = useState(0);
  const [education, seteducation] = useState(0);
  const [old_address, setold_address] = useState(0);
  const [current_address, setcurrent_address] = useState(0);
  const [auto_answer, setauto_answer] = useState(1);
  const [position, setposition] = useState('');
  const [company, setcompany] = useState('');
  const dispatch = useDispatch();

  const onChange = (e) => {
    setis_show_name(e.target.value);
  };

  const onChangeAnswer = (e) => {
    setauto_answer(e.target.value);
  };

  useEffect(() => {
    setcompany(userState.company);
    setposition(userState.position);
    return () => { };
  }, []);

  const onSubmit = async () => {
    const update = await ApiUser.updateFirst({ position, company });
    if (update.status == 200) {
      const user = update.data;
      dispatch({ type: 'set_user', user: user });
      AlertSuccess('', 'บันทึกสำเร็จ');
    }
  };

  return (
    <section style={{ overflow: 'auto' }}>
      <div>
        <label className='tab3-label-01-userprofile'>
          ชื่อที่ใช้แสดงหน้าระบบ
        </label>
      </div>
      <div className='Rounded-Rectangle-6-copy-11-tab3-userprofile'>
        <Input
          size='large'
          disabled
          defaultValue={userState.name_on_web}
          className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
        />
      </div>
      {/* <div className='Possitive-Message-userprofile'>
      <img
        src={StaticImage(
          '/images/login/check-circle-duotone-1-copy-2.png'
        )}
        className='SC404'
        width='25'
        height='25'
      />
      <label>สามารถใช้ชื่อนี้ได้</label>
    </div> */}
      <div>
        <label className='tab3-label-02-userprofile'>
          ระบบแสดงชื่อผู้ตอบความคิดเห็นอัตโนมัติ
        </label>
      </div>
      <div>
        <div className='radio-userprofile'>
          {' '}
          <Radio.Group
            onChange={onChange}
            defaultValue={userState.is_show_name}
            value={is_show_name}
            disabled
          // value={value}
          >
            <Radio className='Inactive' value={1}>
              อนุญาต
            </Radio>
            <Radio className='Inactive' value={0}>
              ยังก่อน
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <Divider style={{ marginBottom: 30, marginTop: 30 }} />
      <div style={{ display: 'flex' }}>
        <section>
          <div>
            <label className='userprofile-old'>ช่วงอายุ</label>
          </div>
          <div className='userprofile-old-input'>
            {/* <Select
              className='basic-single'
              classNamePrefix='select'
              isDisabled={true}
              defaultValue={ageList
                .filter((x) => x.valuecode == userState.age_range)
                .map((item) => {
                  return {
                    label: item.valuetext,
                    value: item.valuecode,
                  };
                })}
              onChange={(e) => setage_range(e)}
              options={ageList.map((item) => {
                return {
                  label: item.valuetext,
                  value: item.valuecode,
                };
              })}
            /> */}
            <Input
              size='large'
              disabled
              defaultValue={
                ageList.find((x) => x.valuecode == userState.age_range)
                  ?.valuetext
              }
              className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
            />
          </div>
        </section>
        <section>
          <label className='userprofile-sex'>เพศ</label>
          <div className='userprofile-sex-input'>
            {/* <Select
              className='basic-single'
              classNamePrefix='select'
              isDisabled={true}
              onChange={(e) => setsex(e)}
              defaultValue={sexList
                .filter((x) => x.valuecode == userState.sex)
                .map((item) => {
                  return {
                    label: item.valuetext,
                    value: item.valuecode,
                  };
                })}
              options={sexList.map((item) => {
                return {
                  label: item.valuetext,
                  value: item.valuecode,
                };
              })}
            /> */}
            <Input
              size='large'
              disabled
              defaultValue={
                sexList.find((x) => x.valuecode == userState.sex)?.valuetext
              }
              className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
            />
          </div>
        </section>
      </div>
      <div>
        <section>
          <div>
            <label className='userprofile-education'>การศึกษา</label>
          </div>
        </section>
        <section className='userprofile-education-input'>
          {/* <Select
            className='basic-single'
            classNamePrefix='select'
            isDisabled={true}
            defaultValue={educationList
              .filter((x) => x.valuecode == userState.education)
              .map((item) => {
                return {
                  label: item.valuetext,
                  value: item.valuecode,
                };
              })}
            onChange={(e) => seteducation(e)}
            options={educationList.map((item) => {
              return {
                label: item.valuetext,
                value: item.valuecode,
              };
            })}
          /> */}
          <Input
            size='large'
            disabled
            defaultValue={
              educationList.find((x) => x.valuecode == userState.education)
                ?.valuetext
            }
            className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
          />
        </section>
      </div>
      <div>
        <section>
          <div>
            <label className='userprofile-error'>
              พื้นที่ของผู้ที่ได้รับผลกระทบ
            </label>
          </div>
        </section>
        <section>
          <div>
            <label className='userprofile-education'>ภูมิลำเนาเดิม</label>
          </div>
        </section>
        <section className='userprofile-education-input'>
          {/* <Select
            className='basic-single'
            classNamePrefix='select'
            isDisabled={true}
            defaultValue={provinceList
              .filter((x) => x.code == userState.old_address)
              .map((item) => {
                return {
                  label: item.name_th,
                  value: item.code,
                };
              })}
            onChange={(e) => setold_address(e)}
            options={provinceList.map((item) => {
              return {
                label: item.name_th,
                value: item.code,
              };
            })}
          /> */}
          <Input
            size='large'
            disabled
            defaultValue={
              provinceList.find((x) => x.code == userState.old_address)?.name_th
            }
            className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
          />
        </section>
        <section>
          <div>
            <label className='userprofile-education'>ที่อยู่ปัจจุบัน</label>
          </div>
        </section>
        <section className='userprofile-education-input'>
          {/* <Select
            className='basic-single'
            classNamePrefix='select'
            isDisabled={true}
            defaultValue={provinceList
              .filter((x) => x.code == userState.current_address)
              .map((item) => {
                return {
                  label: item.name_th,
                  value: item.code,
                };
              })}
            onChange={(e) => setcurrent_address(e)}
            options={provinceList.map((item) => {
              return {
                label: item.name_th,
                value: item.code,
              };
            })}
          /> */}
          <Input
            size='large'
            disabled
            defaultValue={
              provinceList.find((x) => x.code == userState.current_address)
                ?.name_th
            }
            className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
          />
        </section>
      </div>
      <div>
        <section>
          <div>
            <label className='userprofile-error'>
              ให้ระบบดึงข้อมูลไปใส่เมื่อถูกถามคำถามเดิมซ้ำๆ
            </label>
          </div>
        </section>
        <section>
          <div className='radio-loop'>
            <Radio.Group
              onChange={onChangeAnswer}
              defaultValue={userState.auto_answer}
              value={auto_answer}
              disabled={true}
            >
              <Radio className='Inactive' value={1}>
                อนุญาต
              </Radio>
              <Radio className='Inactive' value={0}>
                ยังก่อน
              </Radio>
            </Radio.Group>
          </div>
        </section>
      </div>

      <div>
        <label className='tab3-label-01-userprofile'>อาชีพ</label>
      </div>
      <div className='Rounded-Rectangle-6-copy-11-tab3-userprofile'>
        {/* <Select
          className='basic-single'
          classNamePrefix='select'
          isDisabled={true}
          defaultValue={careerList
            .filter((x) => x.valuecode == userState.career)
            .map((item) => {
              return {
                label: item.valuetext,
                value: item.valuecode,
              };
            })}
          options={sexList.map((item) => {
            return {
              label: item.valuetext,
              value: item.valuecode,
            };
          })}
        /> */}
        <Input
          size='large'
          disabled
          defaultValue={
            careerList.find((x) => x.valuecode == userState.career)?.valuetext
          }
          className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
        />
      </div>
      <Divider style={{ marginBottom: 20, marginTop: 40 }} />
      <div>
        <label className='tab3-label-01-userprofile'>ตำแหน่งงาน</label>
      </div>
      <div className='Rounded-Rectangle-6-copy-11-tab3-userprofile'>
        <Input
          size='large'
          placeholder='ตำแหน่งงาน'
          defaultValue={userState.position}
          onChange={(e) => setposition(e.target.value)}
          className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
        />
      </div>
      <div>
        <label className='tab3-label-01-userprofile'>หน่วยงานสังกัด</label>
      </div>
      <div className='Rounded-Rectangle-6-copy-11-tab3-userprofile'>
        <Input
          size='large'
          placeholder='หน่วยงานสังกัด'
          defaultValue={userState.company}
          onChange={(e) => setcompany(e.target.value)}
          className='Rounded-Rectangle-6-copy-11-tab3-userprofile-input input-theme'
        />
      </div>
      <div className='d-flex' style={{ marginBottom: 30 }}>
        <ButtonComponent
          text='บันทึกข้อมูล'
          onClick={() => {
            onSubmit();
          }}
          buttonStyle={{ marginTop: 30, marginLeft: 40 }}
          className={'button-theme-blue-45'}
        />
      </div>
    </section>
  );
};
export default UserProfileIndex;
