import React, { useState, useEffect, useLayoutEffect } from 'react';
import IndexCarousel from '../main/IndexCarousel';
import '../main/submenulist.scss';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../utils/image';
// import YoutuberPlayer from "./react-player/YoutuberPlayer";
import { fade, makeStyles } from '@material-ui/core';
// import SurveyCard from './newLayout/index';
import SurveyCardMobile from '../listening/newLayoutMobile';
import { checkUser } from '../../utils/checkUser';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import ApiMaster from '../../api/master';
import { useWindowSize } from '../../utils/WindowSize';
import SurveyCardV2 from './desktop/survey';
import ProjectCardV2 from './desktop/project';
import SurveyCardV2Mobile from './mobile/survey';
import ProjectCardV2Mobile from './mobile/project';
import EvaluateTable from './evaluateTable2';
import './index.scss';
import EvaluateTableMobile from './evaluateTableMobile';
import AlertInfo from '../../utils/AlertInfo';
import { Input } from 'antd';
import { SearchOutlined } from '@material-ui/icons';


const useStylesAvatar = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const scoreText = [
  { value: "last", label: "#ล่าสุด", color: "#0c4da2" },
  { value: "answer", label: "#ตอบมากที่สุด", color: "#a51830" },
  // { value: 'read', label: '#ความนิยม', color: '#387436' },
  { value: "progress", label: "#ความคืบหน้า", color: "#387436" },
  { value: 'evaluate', label: '#ประเมินผลสัมฤทธิ์', color: '#756039' },
];

const ListeningPageindex = () => {
  const [width, height] = useWindowSize();
  const [isMore, setisMore] = useState(true);
  const [surveys, setsurveys] = useState([]);
  const [surveyFilter, setSurveyFilter] = useState([]);
  const [card_type, setcard_type] = useState('last');
  const history = useHistory();
  const classes = useStyles();
  const classAvatar = useStylesAvatar();
  const userState = useSelector((state) => state.user);
  const [projects, setprojects] = useState([]);
  const [entitys, setEntitys] = useState([]);
  const [process, setprocess] = useState([]);
  const [law_group, setlawgroup] = useState([]);
  const [agencyOption, setAgencyOption] = useState([
    { value: 0, label: "ทั้งหมด" },
  ]);
  const [ministryOption, setMinistryOption] = useState([
    { value: 0, label: "ทั้งหมด" },
  ]);
  const [surveyTypeOption, setSurveyTypeOption] = useState([
    { value: 0, label: "ทั้งหมด" },
  ]);

  const [totalPage, setTotalPage] = useState(1);
  const [totalItem, setTotalItem] = useState(9999);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [search, setsearch] = useState({
    searchText: "",
    ministry_id: 0,
    agency_id: 0,
    statusSurvey: "",
    surveyType: ""
  });

  useEffect(() => {
    Swal.showLoading();
    const getSurveyResult = getSurvey();
    // const getProjectSurveyResult = getProjectSurvey();
    const getHomeFilter = getFilterOption();
    try {
      Promise.all([getSurveyResult, getHomeFilter])
        .then(data => {
          Swal.close();
          //AlertInfo(``, `เพื่อเพิ่มประสิทธิภาพในการใช้งาน ระบบกลางทางกฎหมาย ขอแจ้งปิดปรับปรุง ในวันที่ 24 ก.พ. เวลา 18.00 น. ถึงวันที่ 25 ก.พ. เวลา 04.00 น. โดยจะไม่สามารถเข้าใช้งานได้ในช่วงเวลาดังกล่าวขออภัยในความไม่สะดวกมา ณ โอกาสนี้`)
        })
    } catch (error) {
      Swal.close();
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (!(entitys.length == 0)) {
      getOrderSurvey({ orderFilter: card_type })
    }
    return () => {
    }
  }, [card_type])



  const getSurvey = async () => {
    // Swal.showLoading();
    try {
      const survey = await ApiSurvey.get({
        size: pageSize > totalItem ? totalItem : pageSize,
        page: pageNo,
        sort: card_type,
        ...search
      });
      if (survey.status == 200) {
        // Swal.close();
        const { data, law_group, entitys, lastPage, count } = survey.data;
        setsurveys(pageNo == 1 ? data : [...surveys, ...data]);
        setSurveyFilter(pageNo == 1 ? data : [...surveyFilter, ...data]);
        setlawgroup(law_group);
        setEntitys(entitys);
        setTotalPage(lastPage);
        setTotalItem(count);
      }
    } catch (error) {
      console.log(error);
    }

  };

  const getSurveyRefresh = async (pageNoParams = 1, searchParams = {}) => {
    console.log('getSurveyRefresh::')
    Swal.showLoading();
    try {
      const survey = await ApiSurvey.get({
        size: pageNoParams == 1 ? 10 : pageSize > totalItem ? totalItem : pageSize,
        page: pageNoParams == 1 ? 1 : pageNo,
        sort: card_type,
        ...searchParams
      });
      if (survey.status == 200) {
        Swal.close();
        const { data, law_group, entitys, lastPage, count } = survey.data;
        if (pageNoParams == 1) {
          setsurveys(data);
          setSurveyFilter(data);
        }
        else {
          setsurveys(pageNo == 1 ? data : [...surveys, ...data]);
          setSurveyFilter(pageNo == 1 ? data : [...surveyFilter, ...data]);
        }
        //setlawgroup(law_group);
        //setEntitys(entitys);
        setTotalPage(lastPage);
        setTotalItem(count);
      }
    } catch (error) {
      console.log(error);
    }

  };


  const getSurveySearch = async (searchParams = "") => {
    Swal.showLoading();
    try {
      const survey = await ApiSurvey.getSearch(searchParams == "" ? search : searchParams);
      Swal.close();
      if (survey.status == 200) {
        // Swal.close();
        const { data, law_group, entitys, lastPage, count } = survey.data;
        setsurveys(data);
        setSurveyFilter(data);

        // setlawgroup(law_group);
        // setEntitys(entitys);
        // setTotalPage(lastPage);
        // setTotalItem(count);
      }
    } catch (error) {
      console.log(error);
    }

  };

  const getProjectSurvey = async () => {
    // Swal.showLoading();
    const survey = await ApiSurvey.surveyGroupByProject();
    if (survey.status == 200) {
      // Swal.close();
      const { data, entitys } = survey.data;
      console.log("entitys::", entitys);
      setprojects(data);
      setEntitys(entitys);
      // setsurveys(data);
      // setSurveyFilter(data);
    }
  };

  const getFilterSurvey = async ({
    ministry,
    agency,
    surveyType,
    statusSurvey,
    searchFilter,
  }) => {
    let survey = [...surveys];

    // if (ministry > 0) {
    //   survey = survey.filter((x) => x.ministry_id == ministry);
    // }

    // if (agency > 0) {
    //   survey = survey.filter((x) => x.agency_id == agency);
    // }
    // if (surveyType > 0) {
    //   if (surveyType == 2) {
    //     survey = survey.filter(
    //       (x) => x.survey_type_id == 1 || x.survey_type_id == 2
    //     );
    //   } else {
    //     survey = survey.filter((x) => x.survey_type_id == surveyType);
    //   }
    // }
    // if(ministry == 0)
    if (!(ministry == null && agency == null && searchFilter == "" && statusSurvey == null && surveyType == null)) {
      setsearch({
        ...search,
        ministry_id: ministry,
        agency_id: agency,
        statusSurvey: statusSurvey,
        surveyType: surveyType
      })
      getSurveyRefresh(1, {
        searchText: search.searchText,
        ministry_id: ministry,
        agency_id: agency,
        statusSurvey: statusSurvey,
        surveyType: surveyType
      })
    }

    // if (statusSurvey == 'O') {
    //   survey = survey.filter(
    //     (x) => (x.isconclude == 'O' || !x.isconclude) && x.date_balance >= 0
    //   );
    // } else if (statusSurvey == 'I') {
    //   survey = survey.filter(
    //     (x) =>
    //       x.date_balance < 0 ||
    //       x.isconclude == 'D' ||
    //       x.isconclude == 'C' ||
    //       x.isconclude == 'B' ||
    //       x.isconclude == 'P'
    //   );
    // }
    // if (searchFilter) {
    //   if (card_type == "progress") {
    //     survey = survey.filter(item => {
    //       return (
    //         item.project.project_name.includes(searchFilter)
    //       )
    //     });
    //   } else {
    //     survey = survey.filter(item => {
    //       return (
    //         item.short_survey_name.includes(searchFilter) || item.survey_name.includes(searchFilter) ||
    //         item.short_project_name.includes(searchFilter) || item.project_name.includes(searchFilter)
    //       )
    //     });
    //   }

    // }

    // setSurveyFilter(survey);
  };

  const getOrderSurvey = async ({ orderFilter }) => {
    let survey = [...surveys];

    if (orderFilter == 'last') {
      setPageNo(1);
      setPageSize(10);
      getSurveyRefresh(1);
      setsearch({
        searchText: ""
      })
      setisMore(true)
      //survey = _.orderBy(surveyFilter, 'created_date', 'desc');
    } else if (orderFilter == 'answer') {
      setPageNo(1);
      setPageSize(10);
      getSurveyRefresh(1);
      setsearch({
        searchText: ""
      })
      setisMore(true)
      // survey = _.orderBy(
      //   surveyFilter,
      //   [
      //     (o) => {
      //       return o.answer_count || '';
      //     },
      //   ],
      //   ['desc']
      // );
    } else if (orderFilter == 'read') {
      setPageNo(1);
      setPageSize(10);
      getSurveyRefresh(1);
      setsearch({
        searchText: ""
      })
      setisMore(true)
      // survey = _.orderBy(
      //   surveyFilter,
      //   [
      //     (o) => {
      //       return o.view_count || '';
      //     },
      //   ],
      //   ['desc']
      // );
    } else if (orderFilter == 'popular') {
      // survey = _.orderBy(
      //   surveyFilter,
      //   [
      //     (o) => {
      //       return o.view_count || '';
      //     },
      //   ],
      //   ['desc']
      // );
    }
    //setSurveyFilter(survey);
  };

  const handleFavorite = async (e, item) => {
    e.stopPropagation();
    if (checkUser(userState)) {
      const model = {
        project_id: item.project_id,
      };
      try {
        const result = await ApiSurvey.favoriteProject(model);
        if (result.status == 200) {
          //filter
          const likesFilter = [...surveyFilter];
          const indexFilter = likesFilter.findIndex(
            (x) => parseInt(x.project_id) == item.project_id
          );
          likesFilter[indexFilter].is_like = !likesFilter[indexFilter].is_like;

          setSurveyFilter(likesFilter);
        }
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    }
  };

  const getFilterOption = async () => {
    const filter = await ApiMaster.homeFilter();
    if (filter.status == 200) {
      const { data } = filter.data;

      setAgencyOption([...agencyOption, ...data.agency]);
      setMinistryOption([...ministryOption, ...data.ministry]);
      setSurveyTypeOption([
        ...surveyTypeOption,
        ...data.survey_type.filter((x) => x.label != "หลักการ" && x.label != "ร่างกม.โดยผู้พิจารณา"),
      ]);
    }
  };


  useEffect(() => {
    if (pageNo > 1) {
      Swal.showLoading();
      const getSurveyResult = getSurvey({
        search
      });
      try {
        Promise.all([getSurveyResult])
          .then(data => {
            Swal.close();
          })
      } catch (error) {
        Swal.close();
      }
    }
  }, [pageNo]);

  return (
    // <Container maxWidth="xl">
    <div id="listening-v2" className='container-fluid bg-white'>
      <div>
        <section className='section-banner' style={{}}>
          <IndexCarousel history={history} />
        </section>
      </div>
      <div className="d-flex-align-center px-5 sm:px-[8%]" style={{
        marginTop: width >= 768 ? 0 : 50,
        zoom: width >= 768 ? '100%' : '80%'
      }}>
        <div className='flex flex-col flex-1'>
          {card_type != "evaluate" && <div className='d-flex flex-1 sm:flex hidden mb-5'>
            <Input value={search.searchText}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  //  onSearch(search.type, search.searchText);
                }
              }}
              onChange={(e) => {
                setsearch({ ...search, searchText: e.target.value })
              }}
              className="search flex-1"
              placeholder="พิมพ์คำค้นหากฎหมาย"
              suffix={
                <button type="button" aria-label='search-law' onClick={() => {
                  if (search.searchText) {
                    getSurveySearch();
                    setisMore(false)
                  } else {
                    getSurveyRefresh();
                    setisMore(true)
                  }

                }} className='search-button'>
                  <SearchOutlined />
                </button>
              }
            />
          </div>
          }
          <div className='flex'>
            {/* <span style={{ marginRight: 15, fontSize: 18, whiteSpace: 'nowrap' }} className="text-regular bg-[#0c4da2] h-fit !text-white rounded-[30px] !px-3 !py-1">มุมมอง</span> */}
            <div className="d-flex-align-center card-type-filter-container">
              {scoreText.map((item) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        card_type == item.value ? '#fff' : "#e5e6ee",
                    }}
                    className="card-type-filter"
                    onClick={() => setcard_type(item.value)}>
                    <span className="text-regular" style={{ color: item.color }}>{item.label}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>


      </div>
      {
        width >= 768 && (
          <section>
            {card_type == "progress" ? <ProjectCardV2
              agencyOption={agencyOption}
              ministryOption={ministryOption}
              surveyTypeOption={surveyTypeOption}
              getFilterSurvey={getFilterSurvey}
              handleFavorite={handleFavorite}
              law_group={law_group}
              surveys={surveyFilter}
              entitys={entitys} /> :
              card_type != "evaluate" ? <SurveyCardV2
                search={search}
                setsearch={setsearch}
                onSearch={(search) => {
                  if (search) {
                    getSurveySearch();
                    setisMore(false)
                  } else {
                    getSurveyRefresh();
                    setisMore(true)
                  }

                }}
                agencyOption={agencyOption}
                ministryOption={ministryOption}
                surveyTypeOption={surveyTypeOption}
                getFilterSurvey={getFilterSurvey}
                handleFavorite={handleFavorite}
                law_group={law_group}
                surveys={surveyFilter}
                entitys={entitys} /> : <EvaluateTable type="desktop" agencyOption={agencyOption}
                  ministryOption={ministryOption}
                  surveyTypeOption={surveyTypeOption}
                  getFilterSurvey={getFilterSurvey} />}

            {

              card_type != "evaluate" && pageNo !== totalPage && isMore && <div className='flex justify-center mb-4'>
                <button className='button-theme button-theme-blue-45 ' onClick={() => {
                  setPageNo(pageNo + 1)
                }}>ดูเพิ่มเติม</button>
              </div>
            }
          </section>
        )
      }
      {
        width < 768 && (
          <section>
            {card_type == "progress" ? <ProjectCardV2Mobile
              agencyOption={agencyOption}
              ministryOption={ministryOption}
              surveyTypeOption={surveyTypeOption}
              getFilterSurvey={getFilterSurvey}
              handleFavorite={handleFavorite}
              law_group={law_group}
              surveys={surveyFilter}
              entitys={entitys} /> :
              card_type != "evaluate" ?
                <SurveyCardV2Mobile
                  agencyOption={agencyOption}
                  ministryOption={ministryOption}
                  surveyTypeOption={surveyTypeOption}
                  getFilterSurvey={getFilterSurvey}
                  handleFavorite={handleFavorite}
                  law_group={law_group}
                  surveys={surveyFilter}
                  entitys={entitys} /> :
                <EvaluateTable
                  ministryOption={ministryOption}
                  type="mobile" />
            }
            {
              card_type != "evaluate" && pageNo !== totalPage && isMore && <div className='flex justify-center mb-4'>
                <button className='button-theme button-theme-blue-45 ' onClick={() => {
                  setPageNo(pageNo + 1)
                }}>ดูเพิ่มเติม</button>
              </div>
            }
          </section>
        )
      }
    </div >
  );
};

export default ListeningPageindex;
