import React, { useEffect, useState } from 'react';
import './css/index.scss';
import ListDetails from './ListDetails';
import {
  Typography,
  Space,
  Row,
  Col,
  Avatar,
  Button,
  Result,
  Input,
} from 'antd';
import {
  UserOutlined,
  ClockCircleOutlined,
  FormOutlined,
  CheckCircleTwoTone,
  ReloadOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { Container } from '@material-ui/core';
import IndexFooter from '../main/IndexFooter';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useClipboard } from 'use-clipboard-copy';
import moment from 'moment';

const { Text, Link, Title } = Typography;
const AnswerPublic = (props) => {
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const history = useHistory();
  const [result, setResult] = useState(false);
  const [survey, setsurvey] = useState([]);
  const [surveyName, setsurveyName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [date_balance, setDateBalance] = useState('');
  const [reRender, setRerender] = useState(false);
  const [totalQuestion, settotalQuestion] = useState(0);
  const [successQuestion, setSuccessQuestion] = useState(0);
  const answer_id = new URLSearchParams(props.location.search).get('answer_id');
  useEffect(() => {
    getSurvey();
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiSurvey.getAnswer({ answer_id });
      if (survey.status == 200) {
        Swal.close();
        const { data } = survey.data;
        if (data.length > 0) {
          setsurveyName(data[0].survey_name);
          setDateBalance(data[0].date_balance);
          setProjectName(data[0].project_name);
          let total = 0;
          data.map((item, index) => {
            total += item.question_detail.length;
          });
          settotalQuestion(total);
          if (data[0].question_id) {
            setsurvey(data);
          } else {
            throw 'ไม่พบข้อมูลแบบสอบถาม';
          }
        } else {
          throw 'ไม่พบข้อมูลแบบสอบถาม';
        }
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  useEffect(() => {
    let total = 0;
    survey.map((item, index) => {
      total += item.question_detail.filter((x) => x.value).length;
    });
    setSuccessQuestion(total);
    return () => { };
  }, [survey]);

  const onCommentClick = (page, item) => {
    let newSurvey = [...survey];
    let newPage = newSurvey.find((x) => x.question_id == page.question_id);
    let newQuestion = newPage.question_detail;
    let filter = newQuestion.findIndex((x) => x.name == item.name);
    newQuestion[filter].editor = true;
    setsurvey(newSurvey);
  };

  const onCommentOnChange = (page, item, label) => {
    let newSurvey = [...survey];
    let newPage = newSurvey.find((x) => x.question_id == page.question_id);
    let newQuestion = newPage.question_detail;
    let filter = newQuestion.findIndex((x) => x.name == item.name);
    newQuestion[filter].note = label;
    newQuestion[filter].datetime = moment().format('DD MMM YY HH:ss');
    setsurvey(newSurvey);
  };

  const onEvent = (surveyItem, question, type, value) => {
    if (type == 'radiogroup') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;
      setsurvey(newSurvey);
    } else if (type == 'text') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;
      setsurvey(newSurvey);
    } else if (type == 'dropdown') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;

      setsurvey(newSurvey);
    } else if (type == 'file') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value.name;
      setsurvey(newSurvey);
    }
    if (type == 'checkbox') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      if (newQuestion[filter].value) {
        if (newQuestion[filter].value.includes(value)) {
          const checkBoxIndex = newQuestion[filter].value.findIndex(
            (x) => x == value
          );
          newQuestion[filter].value.splice(checkBoxIndex, 1);
        } else {
          newQuestion[filter].value.push(value);
        }
      } else {
        newQuestion[filter].value = [];
        newQuestion[filter].value.push(value);
      }
      setsurvey(newSurvey);
    }
  };

  const surveyPageRender = () => {
    let components = [];
    survey.map((item, index) => {
      components.push(
        <div
          style={{
            background: '#fff',
            padding: '19px 13px 23px 16px',
            margin: '15px:0px',
          }}
        >
          <Container maxWidth='xl'>
            <span className='header-page'>{item.name}</span>
            <section style={{ marginTop: 20 }}>
              <ListDetails
                survey={item}
                onEvent={onEvent}
                onCommentClick={onCommentClick}
                onCommentOnChange={onCommentOnChange}
              />
            </section>
          </Container>
        </div>
      );
    });
    return <div style={{ paddingBottom: 120 }}>{components}</div>;
  };

  return (
    <div>
      <section style={{ marginBottom: 15 }}>
        <div
          className='Rounded-Rectangle-4'
          style={{
            padding: '20px 0px',
            paddingTop: '60px',
            backgroundColor: '#fff',
            objectFit: 'contain',
            boxShadow:
              '3.6px 10.4px 30px 0 rgba(137, 148, 169, 0.36), -10.6px -10.6px 30px 0 #ffffff, inset 9.2px 9.2px 26px 0 #ffffff, inset -9.2px -9.2px 26px 0 rgba(188, 194, 211, 0.25)',
          }}
        >
          <Container maxWidth='xl'>
            <Row style={{ marginBottom: 10 }} wrap={false}>
              <Col flex='1' className='d-flex'>
                {' '}
                <Text>
                  {' '}
                  <Avatar
                    shape='square'
                    src={
                      'https://' +
                      window.location.hostname +
                      ':' +
                      window.location.port +
                      '/images/layer-14.png'
                    }
                    icon={<UserOutlined />}
                  />
                  <Text className='section-head-details'> {surveyName}</Text>
                </Text>
              </Col>
              <Col className='section-head-timer-details'>
                <ClockCircleOutlined />
                <Text
                  style={{ paddingLeft: '2px' }}
                  className='section-head-timer-details'
                >
                  {' '}
                  ปิดรับฟังใน {parseInt(date_balance) + 1} วัน
                </Text>
              </Col>
            </Row>
            <span>{projectName}</span>
          </Container>
        </div>
      </section>
      {survey.length > 0 && result == false && surveyPageRender()}
      {survey.length > 0 && result == false && (
        <>
          <div className='bottom-button'>
            <Button
              type='primary'
              block
              icon={<HomeOutlined />}
              size='large'
              className='button-radius sent-question-button'
              style={{ backgroundColor: '#0c4da2' }}
              onClick={() => {
                history.push('/');
              }}
            >
              กลับสู่หน้าหลัก
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AnswerPublic;
