import React, { useState, useEffect } from 'react';
import './css/index.scss';
import {
  Typography,
  Avatar,
  Comment,
  Card,
  Switch,
  Input,
  Form,
  Button,
  Result,
  Row,
  Col,
  Checkbox,
  Radio,
  Collapse,
  Select,
  Upload,
} from 'antd';
import {
  FormOutlined,
  SmileOutlined,
  CheckCircleTwoTone,
  ReloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './css/index.scss';
import { StaticImage } from '../../utils/image';
const { Text, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const { Panel } = Collapse;
const Editor = ({
  onChange,
  onSubmit,
  submitting,
  value,
  readOnly = false,
}) => (
  <>
    <Form.Item align='start'>
      <TextArea
        readOnly={readOnly}
        rows={4}
        cols={12}
        onChange={onChange}
        value={value}
        style={{
          backgroundColor: '#f1f3f7',
          borderRadius: '10px',
          marginTop: 10,
        }}
        placeholder={'เขียนความคิดเห็น...'}
      />
    </Form.Item>
  </>
);

const ListDetails = ({
  survey,
  onCommentClick,
  onCommentOnChange,
  onEvent,
}) => {
  const history = useHistory();
  var rows = [];
  const normFile = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  survey.question_detail.map((item, index) => {
    rows.push(
      <Card
        style={{ marginBottom: '22px' }}
        className='card-layout p-0'
        actions={[
          <section style={{ textAlign: 'left' }}>
            {item.type == 'radiogroup' && (
              <div style={{ padding: '0px 10px' }}>
                <Radio.Group
                  key={item.name}
                  onChange={(e) =>
                    onEvent(survey, item.name, item.type, e.target.value)
                  }
                  value={item.value}
                >
                  {item.choices.map((choice, choiceIndex) => {
                    return (
                      <div key={choice.value} style={{ paddingTop: '10px' }}>
                        <Radio
                          disabled={true}
                          className='switch-style'
                          onChange={(e) =>
                            onEvent(survey, item.name, item.type, choice.value)
                          }
                          value={choice.value}
                        />
                        <Text
                          onClick={() =>
                            onEvent(survey, item.name, item.type, choice.value)
                          }
                          className='switch-text'
                        >
                          {choice.text}
                        </Text>
                      </div>
                    );
                  })}
                </Radio.Group>

                {item.note && (
                  <Editor
                    readOnly={true}
                    value={item.note}
                    onChange={(e) =>
                      onCommentOnChange(survey, item, e.target.value)
                    }
                  />
                )}
              </div>
            )}
            {item.type == 'text' && (
              <div style={{ padding: '0px 10px' }}>
                <Input
                  key={item.name}
                  readOnly={true}
                  style={{ background: 'rgb(241, 243, 247)' }}
                  type={item.inputType ? item.inputType : 'text'}
                  placeholder={item.placeHolder}
                  value={item.value}
                  onChange={(e) =>
                    onEvent(survey, item.name, item.type, e.target.value)
                  }
                />

                {item.note && (
                  <Editor
                    readOnly={true}
                    value={item.note}
                    onChange={(e) =>
                      onCommentOnChange(survey, item, e.target.value)
                    }
                  />
                )}
              </div>
            )}
            {item.type == 'checkbox' && (
              <div style={{ padding: '0px 10px' }}>
                <Checkbox.Group value={item.value}>
                  {item.choices.map((choice, choiceIndex) => {
                    return (
                      <Row>
                        <Checkbox
                          disabled={true}
                          onChange={(e) => {
                            onEvent(
                              survey,
                              item.name,
                              item.type,
                              e.target.value
                            );
                          }}
                          value={choice.value}
                        ></Checkbox>
                        <Text
                          // onClick={() =>
                          //   onEvent(survey, item.name, item.type, choice.value)
                          // }
                          className='switch-text'
                        >
                          {choice.text}
                        </Text>
                      </Row>
                    );
                  })}
                </Checkbox.Group>

                {item.note && (
                  <Editor
                    readOnly={true}
                    value={item.note}
                    onChange={(e) =>
                      onCommentOnChange(survey, item, e.target.value)
                    }
                  />
                )}
              </div>
            )}
            {item.type == 'dropdown' && (
              <div style={{ padding: '0px 10px' }}>
                <Select
                  disabled={true}
                  // defaultValue='lucy'
                  defaultValue={item.value}
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    onEvent(survey, item.name, item.type, value);
                  }}
                  placeholder={item.optionsCaption}
                >
                  {item.choices.map((choice, choiceIndex) => {
                    return <Option value={choice.value}>{choice.text}</Option>;
                  })}
                </Select>

                {item.note && (
                  <Editor
                    readOnly={true}
                    value={item.note}
                    onChange={(e) =>
                      onCommentOnChange(survey, item, e.target.value)
                    }
                  />
                )}
              </div>
            )}
            {item.type == 'file' && (
              <div style={{ padding: '0px 10px' }}>
                -
                {/* <Form.Item
                  name='upload'
                  label=''
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  isList={false}

                  // extra='longgggggggggggggggggggggggggggggggggg'
                >
                  <Upload
                    name='logo'
                    action='/upload.do'
                    multiple={false}
                    listType='picture'
                    onChange={(e) => {
                      onEvent(survey, item.name, item.type, e.file);
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                </Form.Item> */}
                {item.note && (
                  <Editor
                    readOnly={true}
                    value={item.note}
                    onChange={(e) =>
                      onCommentOnChange(survey, item, e.target.value)
                    }
                  />
                )}
              </div>
            )}
          </section>,
        ]}
      >
        <Comment
          content={
            <div>
              <label className='comment-text'>{`${index + 1}. ${
                item.title
              }`}</label>
              {item.description && (
                <Collapse style={{ marginTop: 10 }} defaultActiveKey={['0']}>
                  <Panel header='คำอธิบาย' key='1'>
                    <p>{item.description}</p>
                  </Panel>
                </Collapse>
              )}
            </div>
          }
        />
      </Card>
    );
  });
  function onChange(checked) {}

  return (
    <div className='question-page'>
      {/* {result === false ? (
        <div>{rows}</div>
      ) : (
        <div>
          <Result
            icon={<CheckCircleTwoTone twoToneColor='#0c4da2' />}
            title={
              <Title level={2} style={{ color: '#0c4da2' }}>
                ได้รับความคิดเห็นของคุณแล้ว
              </Title>
            }
            subTitle={
              <Title level={4}>
                คุณสามารถเปลี่ยนแปลงความคิดเห็นของคุณได้ จนกว่าจะปิดการรับฟัง
              </Title>
            }
            extra={
              <div>
                <Button
                  type='primary'
                  block
                  size='large'
                  className='button-radius'
                  style={{ backgroundColor: '#0c4da2', fontSize: 15 }}
                  onClick={() => {
                    history.push('/');
                    // setResult(false);
                  }}
                >
                  รับทราบและกลับสู่หน้าหลัก
                </Button>
                <div
                  style={{
                    paddingTop: '6px',
                    color: '#8e99ad',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <ReloadOutlined />
                  <Text style={{ color: '#8e99ad' }}> ทำแบบสำรวจอีกครั้ง</Text>
                </div>
                <div style={{ paddingTop: '10px', color: '#333333' }}>
                  <Text style={{ color: '#333333', fontSize: '20px' }}>
                    {' '}
                    หากต้องการติดตามผลการรับฟัง กรุณาให้อีเมลล์สำหรับติดตามความ
                    คืบหน้าของการรับฟังนี้
                  </Text>
                </div>
                <div style={{ paddingTop: '18px', color: '#333333' }}>
                  <Input placeholder='กรุณากรอกอีเมลล์' />
                </div>
                <div style={{ paddingTop: '18px', color: '#333333' }}>
                  <Button
                    block
                    size='large'
                    className='button-radius2'
                    style={{ backgroundColor: '#f0f2f6' }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            }
          />
        </div>
      )} */}
      <div>{rows}</div>
    </div>
  );
};

export default ListDetails;
