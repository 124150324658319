import { Container, Grid, Avatar } from '@material-ui/core';

import { Card, Input, Radio, Skeleton, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import './index.scss';
import { StaticImage } from '../../utils/image';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import ApiUser from '../../api/user';
import ApiMaster from '../../api/master';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import LoginChangeNameIndexMobile from './LoginChangeNameIndexMobile';
import ButtonComponent from '../../utils/ButtonComponent';
import { THEME_BLUE } from '../../style-guide-variable';
import Divider from '@material-ui/core/Divider';
const LoginChangeNameIndex = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tab1, setTab1] = React.useState(true);
  const [tab2, setTab2] = React.useState(false);
  const [tab3, setTab3] = React.useState(false);
  const [checked, setchecked] = useState('');
  const [name_on_web, setname_on_web] = useState('');
  const [company, setcompany] = useState('');
  const [position, setposition] = useState('');
  const [is_show_name, setis_show_name] = useState(1);
  const [favorite_law_group, setfavorite_law_group] = useState([]);
  const [age_range, setage_range] = useState(0);
  const [sex, setsex] = useState(0);
  const [education, seteducation] = useState(0);
  const [old_address, setold_address] = useState(0);
  const [current_address, setcurrent_address] = useState(0);
  const [career, setcareer] = useState(0);
  const [auto_answer, setauto_answer] = useState(1);
  const [law_group, setlaw_group] = useState([]);
  const [sexList, setsexList] = useState([]);
  const [ageList, setageList] = useState([]);
  const [educationList, seteducationList] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const [provinceList, setprovinceList] = useState([]);
  const yearOld = [];
  const sexs = [];
  const educations = [];
  const skeletonLoading = [];

  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    setis_show_name(e.target.value);
  };

  const onChangeAnswer = (e) => {
    setauto_answer(e.target.value);
  };

  const checkName = async () => {
    try {
      const result = await ApiUser.checkname(name_on_web);
      if (result.status == 200) {
        const { data } = result;
        if (!data) {
          setchecked(true);
        } else {
          setchecked(false);
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    lawGroup();
    masterLogin();
    return () => { };
  }, []);

  const lawGroup = async () => {
    const result = await ApiMaster.lawGroup();
    if (result.status) {
      const { data } = result;
      setlaw_group(data.data);
    }
  };

  const masterLogin = async () => {
    const result = await ApiMaster.masterLogin();
    if (result.status) {
      const { data } = result;
      const { ageList, educationList, sexList, provinces, careerList } = data;
      setsexList(sexList);
      setageList(ageList);
      seteducationList(educationList);
      setprovinceList(provinces);
      setCareerList(careerList);
    }
  };

  const handleFavorite = async (lawgroup_id) => {
    if (favorite_law_group.includes(lawgroup_id)) {
      const filter = favorite_law_group.filter((x) => x != lawgroup_id);
      console.log(filter);
      setfavorite_law_group(filter);
    } else {
      setfavorite_law_group([...favorite_law_group, lawgroup_id]);
    }
  };

  const onSubmit = async () => {
    const model = {
      is_first: 0,
      name_on_web,
      is_show_name,
      favorite_law_group: favorite_law_group.join(','),
      age_range: age_range?.value,
      sex: sex?.value,
      education: education?.value,
      old_address: old_address?.value,
      current_address: current_address?.value,
      auto_answer,
      career: career?.value,
      position,
      company,
    };
    try {
      const update = await ApiUser.updateFirst(model);
      if (update.status == 200) {
        const { data } = update;
        dispatch({ type: 'set_user', user: data });

        history.push('/');
      }
    } catch (error) { }
  };

  return (
    <div>
      <MobileView>
        <LoginChangeNameIndexMobile />
      </MobileView>
      <BrowserView>
        <section>
          <div className='login-name-container'>
            <div className='card-name' style={{ overflow: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginBottom: 20,
                  marginRight: 20,
                }}
              >
                {/* <ButtonComponent
                  icon={
                    <img
                      className='forward-duotone'
                      src={StaticImage('/images/login/forward-duotone.png')}
                    />
                  }
                  text='ข้าม'
                  onClick={() => {
                    history.push('/');
                  }}
                  buttonStyle={{ marginRight: 10 }}
                  className={'button-theme-brown-45'}
                /> */}
                <div
                  className='d-flex'
                  style={{ marginRight: 26 }}
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  <label className='text-link cursor-pointer'>ทำทีหลัง</label>
                </div>
                {!tab3 && (
                  // <button
                  //   onClick={() => {
                  //     if (tab1) {
                  //       setTab1(false);
                  //       setTab2(true);
                  //       setTab3(false);
                  //     }
                  //     if (tab2) {
                  //       setTab1(false);
                  //       setTab2(false);
                  //       setTab3(true);
                  //     }
                  //   }}
                  //   className='login-Rounded-Rectangle-8'
                  // >
                  //   ถัดไป
                  // </button>
                  <ButtonComponent
                    text='ถัดไป'
                    onClick={() => {
                      if (tab1) {
                        setTab1(false);
                        setTab2(false);
                        setTab3(true);
                      }
                    }}
                    className={'button-theme-blue-45'}
                  />
                )}
                {tab3 && (
                  <ButtonComponent
                    text='บันทึก'
                    onClick={() => {
                      onSubmit();
                    }}
                    className={'button-theme-blue-45'}
                  />
                )}
              </div>

              <div className='login-name-thriple-logo d-flex-center'>
                <div
                  className={
                    tab1 === false
                      ? 'regis-1 d-flex-center flex-column'
                      : 'regis-2 d-flex-center flex-column'
                  }
                  onClick={() => {
                    setTab1(!tab1);
                    setTab2(false);
                    setTab3(false);
                  }}
                >
                  <img
                    height={73}
                    width={91}
                    src={StaticImage(
                      '/images/login/userprofile/user-tag-duotone.svg'
                    )}
                  />
                  <p
                    style={{ marginTop: 10 }}
                    className='tabname-container-web02'
                  >
                    ชื่อที่จะแสดงในระบบ
                  </p>
                </div>
                {/* <div
                  className={tab2 === false ? 'regis-1' : 'regis-2'}
                  onClick={() => {
                    setTab2(true);
                    setTab1(false);
                    setTab3(false);
                  }}
                >
                  <center>
                    <img src={StaticImage('/images/login/pin.png')} />
                  </center>
                  <p className='tabname-container-web02'>
                    {tab2 === true
                      ? 'เลือกกลุ่มหมวดหมู่ ร่าง/ กฎหมายที่สนใจ '
                      : ''}{' '}
                  </p>
                </div> */}
                <div
                  className={
                    tab3 === false
                      ? 'regis-1 d-flex-center flex-column'
                      : 'regis-2 d-flex-center flex-column'
                  }
                  onClick={() => {
                    setTab3(true);
                    setTab1(false);
                    setTab2(false);
                  }}
                >
                  <img
                    height={70}
                    width={66}
                    src={StaticImage(
                      '/images/login/userprofile/file-user-duotone.svg'
                    )}
                  />
                  <p
                    style={{ marginTop: 10 }}
                    className='tabname-container-web02'
                  >
                    ข้อมูลส่วนตัว
                  </p>
                </div>
              </div>
              <div>
                {tab1 === true ? (
                  <section>
                    <section className='login-name-tab1-container'>
                      <div className='login-name-tab1-input-container'>
                        <label
                          className='Input-Label-copy'
                          style={{ color: THEME_BLUE }}
                        >
                          ชื่อที่จะแสดงในระบบ :
                        </label>
                        <section
                          style={{
                            width: '398px',
                          }}
                        >
                          <Input
                            size={'large'}
                            placeholder='กรอกชื่อที่จะแสดงในระบบ หรือระบบจะนำชื่อบัญชีผู้ใช้งานมาแทน'
                            value={name_on_web}
                            className='input-theme'
                            onChange={(e) => {
                              setchecked('');
                              setname_on_web(e.target.value);
                            }}
                          />

                          <div style={{ display: 'flex', marginTop: 20 }}>
                            {checked && (
                              <>
                                <img
                                  src={StaticImage(
                                    '/images/login/check-circle-duotone-1-copy-2.png'
                                  )}
                                  style={{ height: 24, width: 24 }}
                                />
                                <label style={{ paddingLeft: '0.7pc' }}>
                                  สามารถใช้ชื่อนี้ได้
                                </label>
                              </>
                            )}
                            {checked === false && (
                              <>
                                <img
                                  style={{ height: 24, width: 24 }}
                                  src={StaticImage(
                                    '/images/login/times-circle-duotone-copy-2.png'
                                  )}
                                />
                                <label style={{ paddingLeft: '0.7pc' }}>
                                  มีบัญชีนี้ในระบบแล้ว
                                </label>
                              </>
                            )}
                          </div>
                        </section>
                        <ButtonComponent
                          text='ตรวจสอบ'
                          onClick={checkName}
                          className={'button-theme-blue-45'}
                          buttonStyle={{ marginLeft: 20 }}
                        />
                      </div>
                    </section>
                  </section>
                ) : tab2 === true ? (
                  <section>
                    <div className='login-name-list-container'>
                      <div
                        className='flex-wrap login-name-list-scholler'
                        style={{ marginTop: -15 }}
                      >
                        {law_group.map((item) => {
                          return (
                            <div
                              style={{ marginTop: 10 }}
                              className='law-group-main'
                              onClick={() => handleFavorite(item.lawgroup_id)}
                            >
                              <center
                                style={{
                                  width: 150,
                                  opacity: favorite_law_group.includes(
                                    item.lawgroup_id
                                  )
                                    ? 1
                                    : 0.5,
                                }}
                                class='d-flex flex-column d-flex-center'
                              >
                                <div className='law-group-container d-flex-center'>
                                  <div className='d-flex-center'>
                                    {item.image ? (
                                      <Avatar
                                        className='avatar-object-contain'
                                        size={{
                                          xs: 75,
                                          sm: 75,
                                          md: 75,
                                          lg: 75,
                                          xl: 75,
                                          xxl: 75,
                                        }}
                                        style={{
                                          height: 75,
                                          width: 75,
                                        }}
                                        // shape={'circle'}
                                        src={item.image}
                                      />
                                    ) : (
                                      <Skeleton.Button
                                        style={{
                                          backgroundColor:
                                            favorite_law_group.includes(
                                              item.lawgroup_id
                                            )
                                              ? '#0c4da2'
                                              : '#efefef',
                                        }}
                                        active={true}
                                        size={100}
                                        shape={'circle'}
                                      />
                                    )}
                                  </div>
                                </div>
                                <span style={{ fontSize: 15 }}>
                                  {item.lawgroup_name}
                                </span>
                              </center>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {skeletonLoading}
                    </div> */}
                  </section>
                ) : (
                  <section style={{ paddingBottom: 20, paddingTop: 30 }}>
                    <div style={{ display: 'flex' }}>
                      <section>
                        <div className='form-container-year-label'>
                          <label>ช่วงอายุ</label>
                        </div>
                        <div className='form-containe-year-select'>
                          <Select
                            className='basic-single'
                            classNamePrefix='select'
                            defaultValue={''}
                            onChange={(e) => setage_range(e)}
                            options={ageList.map((item) => {
                              return {
                                label: item.valuetext,
                                value: item.valuecode,
                              };
                            })}
                          />
                        </div>
                      </section>
                      <section>
                        <div className='form-container-sex-label'>
                          <label>เพศ</label>
                        </div>
                        <div className='form-containe-sex-select'>
                          <Select
                            className='basic-single'
                            classNamePrefix='select'
                            defaultValue={''}
                            onChange={(e) => setsex(e)}
                            options={sexList.map((item) => {
                              return {
                                label: item.valuetext,
                                value: item.valuecode,
                              };
                            })}
                          />
                        </div>
                      </section>
                    </div>
                    <div>
                      <section>
                        <div>
                          <label className='form-container-education-label'>
                            การศึกษา
                          </label>
                        </div>
                        <div className='form-containe-education-select'>
                          <Select
                            className='basic-single'
                            classNamePrefix='select'
                            defaultValue={''}
                            onChange={(e) => seteducation(e)}
                            options={educationList.map((item) => {
                              return {
                                label: item.valuetext,
                                value: item.valuecode,
                              };
                            })}
                          />
                        </div>
                      </section>
                    </div>
                    <div>
                      <section>
                        <div>
                          <label className='form-container-education-label'>
                            พื้นที่ของผู้ที่ได้รับผลกระทบ
                          </label>
                        </div>
                      </section>
                      <section>
                        <div style={{ display: 'flex', marginLeft: '145px' }}>
                          <label className='Input-Label-copy'>
                            ภูมิลำเนาเดิม
                          </label>
                          <section
                            style={{ paddingLeft: '1.5pc', width: '63%' }}
                          >
                            <Select
                              className='basic-single'
                              classNamePrefix='select'
                              defaultValue={''}
                              onChange={(e) => setold_address(e)}
                              options={provinceList.map((item) => {
                                return {
                                  label: item.name_th,
                                  value: item.code,
                                };
                              })}
                            />
                          </section>
                        </div>
                      </section>
                      <section>
                        <div style={{ display: 'flex', marginLeft: '145px' }}>
                          <label
                            style={{
                              paddingTop: '1pc',
                            }}
                            className='Input-Label-copy'
                          >
                            ที่อยู่ปัจจุบัน
                          </label>
                          <section
                            style={{
                              paddingLeft: '2pc',
                              paddingTop: '1pc',
                              width: '64%',
                            }}
                          >
                            <Select
                              className='basic-single'
                              classNamePrefix='select'
                              defaultValue={''}
                              onChange={(e) => setcurrent_address(e)}
                              options={provinceList.map((item) => {
                                return {
                                  label: item.name_th,
                                  value: item.code,
                                };
                              })}
                            />
                          </section>
                        </div>
                      </section>

                      <section>
                        <div>
                          <label className='form-container-education-label'>
                            ให้ระบบดึงข้อมูลไปใส่เมื่อถูกถามคำถามเดิมซ้ำๆ
                          </label>
                        </div>
                      </section>
                      <section>
                        <div className='radio-container'>
                          <Radio.Group
                            onChange={onChangeAnswer}
                            value={auto_answer}
                          >
                            <Radio value={1}>อนุญาต</Radio>
                            <Radio value={0}>ยังก่อน</Radio>
                          </Radio.Group>
                        </div>
                      </section>
                      <section>
                        <div>
                          <label className='form-container-education-label'>
                            อาชีพ
                          </label>
                        </div>
                        <div className='form-containe-education-select'>
                          <Select
                            className='basic-single'
                            classNamePrefix='select'
                            defaultValue={''}
                            onChange={(e) => setcareer(e)}
                            options={careerList.map((item) => {
                              return {
                                label: item.valuetext,
                                value: item.valuecode,
                              };
                            })}
                          />
                        </div>
                      </section>

                      <Divider
                        style={{
                          marginBottom: 5,
                          marginTop: 40,
                          width: '90%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      />
                      <label style={{ marginTop: 20, marginLeft: 50, fontWeight: 'bold' }} className='title'>
                        ข้อมูลสำหรับหน่วยงานภาครัฐ
                      </label>
                      <div style={{ display: 'flex' }}>

                        <section>
                          <div className='form-container-year-label'>
                            <label>ตำแหน่งงาน</label>
                          </div>
                          <div className='form-containe-year-select'>
                            <Input
                              size={'large'}
                              placeholder='ตำแหน่งงาน'
                              value={position}
                              onChange={(e) => {
                                setposition(e.target.value);
                              }}
                            />
                          </div>
                        </section>
                        <section>
                          <div className='form-container-year-label'>
                            <label>หน่วยงานที่สังกัด</label>
                          </div>
                          <div className='form-containe-year-select'>
                            <Input
                              size={'large'}
                              placeholder='หน่วยงานที่สังกัด'
                              value={company}
                              onChange={(e) => {
                                setcompany(e.target.value);
                              }}
                            />
                          </div>
                        </section>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        </section>
      </BrowserView>
    </div>
  );
};

export default LoginChangeNameIndex;
