import React, { useState, useEffect } from 'react'
import './index.scss'
import {
    Input,
    Card,
    Avatar,
    Skeleton
} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPollH,
    faTimesCircle,
    faArrowCircleDown,
    faSync,
    faSlidersH,
    faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import TagDuoTone from "../../../iconComponent/TagDuoTone";
import { StaticImage } from '../../../../utils/image';
import { ConvertDateShort } from '../../../../utils/date';
import { checkComplete, checkLeftTime, checkLeftTimeIcon } from '../../../../utils';
import ProgressBar from "../../progressBar";
// import ProjectCardItem from './project';
import SurveyCardSubItem from './subCard';
import { useHistory } from 'react-router-dom';
import { encodeBase64 } from '../../../../utils/convertBase64';
const { Search } = Input;

const listTextOption = [
    { value: '', label: "ทั้งหมด" },
    { value: "O", label: "เปิดรับฟัง" },
    { value: "I", label: "ปิดรับฟัง" },
];

const sizes = {
    xs: 75,
    sm: 75,
    md: 75,
    lg: 75,
    xl: 75,
    xxl: 75,
}

const ProjectCardV2 = ({ surveys = [], law_group, entitys = [], handleFavorite, agencyOption, ministryOption, surveyTypeOption, getFilterSurvey }) => {
    const [agency, setagency] = useState(null);
    const [ministry, setministry] = useState(null);
    const [surveyType, setsurveyType] = useState(null);
    const [statusSurvey, setStatusSurvey] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");


    const cleardata = () => {
        setSearchFilter("");
        setStatusSurvey(null);
        setagency(null);
        setministry(null);
        setsurveyType(null);
    };

    useEffect(() => {
        getFilterSurvey({
            ministry,
            agency,
            surveyType,
            statusSurvey,
            searchFilter,
        });
        return () => { };
    }, [agency, ministry, surveyType, statusSurvey, searchFilter]);

    return (
        <div id="project-card-container-v2">
            <div className="survey-card-container">
                {surveys.map((item, index) =>
                    <ProjectCardItem
                        key={"project-" + item.project.project_id}
                        survey={item}
                        law_group={law_group}
                        entitys={
                            entitys.filter(x => x.project_type_id == item.project.project_type_id && x.order_id != 98 && x.order_id != 99)
                        }
                        handleFavorite={handleFavorite} />)}
            </div>
            <div className="filter-container">
                {/* <Search className="search" placeholder="ค้นหาจากโครงการ" onSearch={(text) => setSearchFilter(text)} /> */}
                <div>
                    <Card
                        title={
                            <div className="text-filter">
                                <FontAwesomeIcon color={"#6d7481"} icon={faSlidersH} />
                                <label>ตัวกรอง</label>
                            </div>
                        }
                        extra={
                            <div className="clear-filter" onClick={() => cleardata()}>
                                <FontAwesomeIcon color={"#0c4ca3"} icon={faSync} />
                                <label>ล้าง</label>
                            </div>
                        }
                        style={{ width: "100%" }}
                        className="Rounded-Rectangle-4-web"
                    >
                        <p> กระทรวง / หน่วยงานอิสระ</p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={ministryOption[0]}
                            value={ministryOption.find((x) => x.value == ministry)}
                            options={ministryOption}
                            onChange={(item) => {
                                setministry(item.value);
                                setagency(null);
                            }}
                        />
                        <p style={{ paddingTop: "1pc" }}>กรม</p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={agencyOption[0]}
                            options={
                                ministry > 0
                                    ? agencyOption.filter((x) => x.ministry_id == ministry)
                                    : agencyOption
                            }
                            value={agencyOption.find((x) => x.value == agency)}
                            onChange={(item) => {
                                setagency(item.value);
                            }}
                        />
                        <p style={{ paddingTop: "1pc" }}>
                            ร่างกฎหมาย / ประเมินผลสัมฤทธิ์
                        </p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={surveyTypeOption[0]}
                            options={surveyTypeOption}
                            value={surveyTypeOption.find((x) => x.value == surveyType)}
                            onChange={(item) => {
                                setsurveyType(item.value);
                            }}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
}

const ProjectCardItem = ({ survey, law_group, entitys, handleFavorite }) => {

    const project = survey.project;
    const entity_name = (project.entity_name) ?? entitys[0]?.entity_name;
    const history = useHistory();
    const [isCollapse, setIsCollapse] = useState(false);
    const [iscancel, setiscancel] = useState(false)
    const renderImageLawGroup = (item) => {
        if (entity_name == "ออกเป็นกฎหมาย") {
            return <>
                <div className="law-group-container outlaw d-flex-center">
                    <div className="d-flex-center">
                        <Avatar
                            alt="image of law group"
                            className="avatar-object-contain"
                            size={sizes}
                            style={{
                                height: 75,
                                width: 75,
                            }}
                            // shape={'circle'}
                            src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
                        />
                    </div>
                </div>
            </>;
        }
        let law = "";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.find((x) => x.lawgroup_id == split[0]);
        }

        return (
            <div className="law-group-container d-flex-center">
                <div className="d-flex-center">
                    {law && law.image ? (
                        <Avatar
                            alt="image of law group"
                            className="avatar-object-contain"
                            size={sizes}
                            style={{
                                height: 75,
                                width: 75,
                            }}
                            // shape={'circle'}
                            src={law.image}
                        />
                    ) : (
                        <Skeleton.Button active={true} size={100} shape={"circle"} />
                    )}
                </div>
            </div>
        );
    };
    const renderLawGroupText = (item) => {
        let law = "ไม่ได้ระบุ";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
            law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
        }

        return (
            <div
                className="d-flex discuss-container"
                style={{ marginRight: 15 }}
            >
                <img
                    src={StaticImage("/images/icon/books-duotone-1-copy.svg")}
                    style={{ marginRight: 5 }}
                    alt="webboard"
                    height={20}
                />
                <label className="lawgroup-text" style={{ color: "#333", fontSize: 16 }}>
                    {law}
                </label>
            </div>
        );
    };

    const renderFavorite = (survey) => {
        return (
            survey.is_like === true ? (
                <img
                    height={32}
                    width={32}
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    )}
                    alt="favorite"
                />
            ) : (
                <img
                    height={32}
                    width={32}
                    className="cursor-pointer"
                    onMouseOver={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    ))
                    }
                    onMouseOut={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    ))
                    }
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    )}
                    alt="favorite"
                />
            )
        );
    }


    const collapse = (e, item) => {
        e.stopPropagation();
        setIsCollapse(!isCollapse);
    };

    const navigate = (project_id) => {
        history.push(`/ProjectTimeline?projectId=${encodeBase64(project_id)}`);
    }

    useEffect(() => {
        if (project) {
            let order_id = project.process_order;
            if (order_id == 1) {
                order_id = entitys[0].order_id
            }
            const entityItem = entitys.find(
                (x) =>
                    x.order_id ==
                    (order_id ?? entitys[0].order_id)
            );
            if (entityItem?.entity_name == "ยกเลิก") {
                setiscancel(true)
            }
        }

        return () => {

        }
    }, [project])

    return (
        <section style={{ marginBottom: 20 }}>
            <div className={`survey-card ${(entity_name == "ไม่ออกเป็นกฎหมาย" || iscancel) ? " law-cancal" : ""}`}
                onClick={() => navigate(project.project_id)}>
                <div className="column1">
                    {renderImageLawGroup(survey)}
                    <div
                        className="d-flex-center"
                        style={{ marginTop: 20, minWidth: 170 }}
                    >
                        {survey.agency_id > 0 && (
                            <div
                                className="organize-container"
                                style={{ margin: 0, maxWidth: 100 }}
                            >
                                <img
                                    alt="agency_image"
                                    src={survey.agency_image_uploaded ?? survey.agency_image}
                                    className="logo"
                                    oncontextmenu="return false;"
                                    onError={(i) => (i.target.style.display = "none")}
                                />
                                <label style={{ paddingLeft: "2px" }}>
                                    {survey.agency_short ?? survey.agency_abbrv}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="project-type" style={{ marginTop: 20 }}>
                        <TagDuoTone />
                        <span>
                            {survey.survey_type_name}
                        </span>
                    </div>
                </div>
                <div className="column2">
                    {(entity_name == "ออกเป็นกฎหมาย" && project.law_title) &&
                        <div className='d-flex law_publish_title' style={{ alignItems: 'center' }}>
                            <Avatar
                                alt="image of law group"
                                className="avatar-object-contain"
                                size={sizes}
                                style={{
                                    height: 25,
                                    width: 25,
                                }}
                                // shape={'circle'}
                                src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
                            />
                            {project.law_title}
                        </div>
                    }
                    <div className="d-flex-align-center">


                        <div className="d-flex-align-center project-id-container">
                            <span className="text-regular">เลขที่ (ID) {project.project_id}</span>
                        </div>
                        <div className="d-flex-align-center">
                            <img
                                src={StaticImage("/images/icon/calendar-alt-duotone-2.svg")}
                                style={{ marginRight: 10 }}
                                alt="calendar"
                                height={20}
                            />
                            <span className="text-regular">
                                {ConvertDateShort(project.createdate, "DD MMM YY")}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">
                        <label className="title flex-1">{project.project_name}</label>

                        <div onClick={(e) => handleFavorite(e, survey)}>
                            <div class="group relative py-2">
                                {renderFavorite(survey)}
                                <div class="hidden group-hover:block ...">ถูกใจ</div>
                            </div>
                        </div>

                    </div>
                    <div className="webboard-row">
                        {renderLawGroupText(survey)}
                    </div>
                    <div className="d-flex-align-center process-container">
                        <FontAwesomeIcon icon={faBalanceScale} />
                        <span className="entity-text text-regular">
                            {project.entity_name ?? entitys[0]?.entity_name}
                        </span>
                        {project.logs_activity.filter((x) => x.type == 'upload_ria')
                            .length > 0 && (
                                <div className='d-flex-center' style={{ marginLeft: 10 }}>
                                    <img
                                        height={28}
                                        width={28}
                                        src={StaticImage('/images/icon/header/ria.svg')}
                                    />
                                    <span className="entity-text text-regular" style={{ marginLeft: 10 }}>
                                        {"สรุปผล"}
                                    </span>
                                </div>
                            )}

                        {project.logs_activity.filter((x) => x.type == 'upload_summary')
                            .length > 0 && (
                                <div className='d-flex-center' style={{ marginLeft: 10 }}>
                                    <img
                                        height={24}
                                        width={24}
                                        src={StaticImage('/images/icon/header/summary.svg')}
                                    />
                                    <span className="entity-text text-regular" style={{ marginLeft: 10 }}>
                                        {"RIA"}
                                    </span>
                                </div>
                            )}
                    </div>
                    <div className="d-flex-align-center flex-1" style={{ justifyContent: 'space-between', marginTop: 20, alignSelf: 'flex-end', width: '100%' }}>
                        {(entity_name == "ออกเป็นกฎหมาย" && project.law_url) &&
                            <label onClick={(e) => {
                                e.stopPropagation();
                                window.open(project.law_url, '_blank')
                            }} className='law_publish_link flex-1'>{project.law_url}</label>
                        }
                        {entity_name !== "ออกเป็นกฎหมาย" &&
                            <div className="project-timeline-body-bottom flex-1">
                                <div
                                    className="d-flex"
                                    style={{ flexWrap: "wrap", marginTop: -10 }}
                                >
                                    {entitys.map((item, index) => {
                                        let order_id = project.process_order;
                                        if (order_id == 1) {
                                            order_id = entitys[0].order_id
                                        }
                                        const findIndex = entitys.findIndex(
                                            (x) =>
                                                x.order_id ==
                                                (order_id ?? entitys[0].order_id)
                                        );
                                        let className = '';

                                        if (
                                            item.order_id ==
                                            (order_id ?? entitys[0].order_id)
                                        ) {
                                            className = 'active';
                                        }
                                        if (findIndex > index) {
                                            className += 'pass';
                                        }
                                        return (
                                            <div
                                                className={`process-step d-flex-center ${className} ${iscancel ? " iscancel" : ""}`}
                                            >
                                                {item.entity_abbrv}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }

                        <div className="d-flex expand cursor-pointer">
                            <div className="badge-public">{survey.surveys.length + 1}</div>
                            <div class="d-flex" style={{ alignItems: 'center' }} onClick={(e) => collapse(e, survey)}>
                                <span style={{ marginRight: 5 }}>{!isCollapse ? 'ขยาย' : 'ปิด'}</span>
                                <img
                                    src={StaticImage(!isCollapse ? "/images/icon/chevron-circle-down.svg" : "/images/icon/chevron-circle-up.png")}
                                    style={{ marginRight: 5 }}
                                    height={24}
                                    alt="public-no"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isCollapse && <SurveyCardSubItem survey={survey} />}
            {
                (isCollapse && survey.surveys.length > 0) && survey.surveys.map(sub => {
                    return <SurveyCardSubItem survey={sub} />
                })
            }
        </section >
    )
}



export default ProjectCardV2;