import React, { useState, useEffect } from 'react';
const ButtonComponent = ({
  icon,
  iconRight,
  text = '',
  onClick = () => { },
  className = 'button-theme-blue',
  buttonStyle = {},
  divClassName = "",
  disabled = false,
  type = 'button',
}) => {
  return (
    <button
      className={className}
      onClick={() => {
        onClick();
      }}
      type={type}
      disabled={disabled}
      style={{
        ...buttonStyle,
        ...{ opacity: disabled ? 0.2 : 1, cursor: 'pointer' },
      }}
    >
      <div style={{
        ...{ display: 'flex', justifyContent: 'center' },
      }} className={divClassName}>
        {icon && icon}
        {text}
        {iconRight && iconRight}
      </div>
    </button>
  );
};

export default ButtonComponent;
