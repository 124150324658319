import { checkContentLawType, numberToThai } from '../../../utils';
import { rawHTMLDiv } from './text';
import { renderToString } from 'react-dom/server'

const DetailLawPageTab2 = ({ law }) => {
    const handleRef = (id) => {
        let ref = id.replace("[", "");
        ref = ref.replace("]", "");
        const violation = document.getElementById(ref);
        let rect = violation.getBoundingClientRect();
        console.log(rect)
        window.scrollTo({
            top: rect.top < 0 ? rect.top + (window.scrollY - 150) : rect.top + window.scrollY,
            behavior: "smooth"
        });
    }
    let footnoteSeq = 0;
    const contentDisplay = () => {
        let content_notshow = ['หมายเหตุ', 'อื่นๆ', 'ผู้มีอำนาจลงนาม']
        let content_list = [];
        let footnoteList = []
        let forewardList = [];
        var elForward = document.createElement('div');
        law.foreword_process = numberToThai(law?.foreword_process)
        elForward.innerHTML = law?.foreword_process;
        let taghtmlCollectionForward = elForward.querySelectorAll('p,figure');
        let tagArrayForward = Array.prototype.slice.call(taghtmlCollectionForward);
        tagArrayForward.map((item, index) => {
            forewardList.push(
                <p style={{
                    textIndent: item.style?.textAlign.includes('center') ? '0pt' : '72pt',
                    marginLeft: item.style?.textAlign.includes('center') ? '0pt' : (item.outerHTML.includes('figure') ? '72pt' : '0pt')
                }} dangerouslySetInnerHTML={{ __html: item.outerHTML }} />
            )
        })
        if (law.content_list.length > 0) {
            law.content_list.map((item, index) => {
                const position = checkContentLawType(item.content_type);
                let styles = {};
                let containerStyles = {

                };
                let pStyles = {};
                if (position == "center") {
                    containerStyles = {
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textIndent: '72pt'
                    };
                    pStyles = {
                        width: '100%'
                    }
                } else if (position == "left") {
                    containerStyles = {
                        textIndent: '72pt',
                        position: 'relative'
                    };
                    // styles = {
                    //     textIndent: '72pt'
                    // };
                }
                let title = ""
                if (item.content_type !== "อื่นๆ" && item.content_type !== "หมายเหตุ" && item.content_type !== "ผู้มีอำนาจลงนาม" && item.content_number != 0) {
                    title = `${item.content_type} ${numberToThai(item.content_number)}\t`;
                }
                title += (item.content_footnote ? "\t" : "");
                let desc = item?.content_desc;
                desc = desc.replaceAll("<p", "<span");
                desc = desc.replaceAll("</p>", "</span>");
                var el = document.createElement('div');
                el.innerHTML = desc;


                let taghtmlCollection = el.querySelectorAll('span,figure');

                //let taghtmlCollection = el.getElementsByTagName('span,figure');

                let tagArray = Array.prototype.slice.call(taghtmlCollection);


                // if (tag.length > 0) {
                //     desc = tag[0].innerHTML;
                // }
                if (item.content_footnote) {
                    footnoteSeq += 1;
                    footnoteList.push(
                        <div>
                            <a id={`#foot-${footnoteSeq}`} className="mr-1 text-info" onClick={(e) => handleRef(`#ref-${e.target.textContent}`)}>
                                <sup>{`[${footnoteSeq}]`}
                                </sup>
                            </a>
                            <span>{item.content_footnote}</span>
                        </div>
                    )
                }
                content_list.push(
                    <div id={`#item-${index}`} style={{ marginTop: 20 }}>
                        <p style={containerStyles}>
                            {item.content_footnote &&
                                <a style={{ textIndent: `${title.length * 3.9}pt`, position: 'absolute', top: -2 }} id={`#ref-${footnoteSeq}`} onClick={(e) => handleRef(`#foot-${e.target.textContent}`)} className="text-info">
                                    <sup >{`[${footnoteSeq}]`}
                                    </sup>
                                </a>
                            }
                            {
                                tagArray.map((subitem, subindex) => {
                                    if (subitem.parentElement.tagName == "TD" || subitem.parentElement.tagName == "SPAN") {
                                    } else {
                                        let spanHtml = ""
                                        let spanRef = "";
                                        if (subindex == 0) {
                                            spanHtml = renderToString(<span className="mr-2" >{title}</span>)
                                        }
                                        return <>

                                            <p dangerouslySetInnerHTML={{ __html: spanHtml + subitem.outerHTML }} />
                                        </>
                                    }

                                })

                            }
                        </p>
                    </div>
                )
            })
        }
        return <div className='ck ck-content'>
            {forewardList}
            {content_list}
            {footnoteList}
        </div>
            ;
    }

    return <div className="tab1">
        <div className="title-container flex flex-column">
            <span className="text-black-300 font-bold">{law.law_name_th}</span>
            {/* <span className="italic">{law.simple_description?.replace(/(<([^>]+)>)/ig, '')}</span> */}
        </div>
        <div className="content-container">
            {/* <div dangerouslySetInnerHTML={{
                __html: law.content_list.map(x => x.content_desc)
            }} /> */}
            {contentDisplay()}
        </div>
    </div>
}

export default DetailLawPageTab2;