import React, { useEffect, useState } from 'react';

import {
  Typography,
  Space,
  Row,
  Col,
  Avatar,
  Button,
  Result,
  Input,
} from 'antd';
import {
  UserOutlined,
  ClockCircleOutlined,
  FormOutlined,
  CheckCircleTwoTone,
  ReloadOutlined,
} from '@ant-design/icons';
import { StaticImage } from '../../utils/image';
import { useClipboard } from 'use-clipboard-copy';
import { useHistory } from 'react-router-dom';
import { getLocalStorage } from '../../utils/localStorage';
import AlertError from '../../utils/AlertError';
import { ConvertDateShort } from '../../utils/date';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser, checkUserBool } from '../../utils/checkUser';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import { WEB_SITE } from '../../env';
import { encodeBase64 } from '../../utils/convertBase64';
const { Text, Link, Title } = Typography;
const ResultSurvey = (props) => {
  useEffect(() => {
    document.title = 'ทำแบบสอบถามสำเร็จ';
    getShareAnswer();
    return () => { };
  }, []);
  const userState = useSelector((state) => state.user);
  const [share_answer, setshare_answer] = useState([]);
  const [answer_date, setanswer_date] = useState(new Date());
  const [shareTextMessage, setshareTextMessage] = useState('');
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  const history = useHistory();
  const [surveyData, setsurveyData] = useState({});
  const [showShare, setshowShare] = useState(false);

  const getShareAnswer = async () => {
    let answer = await getLocalStorage('share-answer');
    if (answer) {
      try {
        answer = JSON.parse(answer);
        setanswer_date(answer.datetime);
        setshare_answer(answer.answer);
        setsurveyData(answer.survey);
      } catch (error) { }
    }
  };

  useEffect(() => {
    if (shareTextMessage.length > 0) {
      setshowShare(true);
    } else {
      setshowShare(false);
    }
    return () => { };
  }, [shareTextMessage]);

  const shareAnswer = async () => {
    if (checkUserBool(userState)) {
      if (userState.is_verify) {
        if (share_answer.length > 0) {
          const url = `${window.location.origin}/listeningDetail?survey_id=${encodeBase64(surveyData.survey_id)}`
          let shareText = '';
          shareText += `ชื่อการรับฟัง : ${surveyData.survey_name}
            \n`;
          shareText += `URL : ${url}
            \n`;;
          shareText += `วันที่เริ่มต้น : ${ConvertDateShort(
            surveyData.start_date,
            'DD MMM YY'
          )}\n`;
          shareText += `วันที่สิ้นสุด : ${ConvertDateShort(
            surveyData.end_date,
            'DD MMM YY'
          )}\n`;
          shareText += `ชื่อผู้ตอบแบบสำรวจ : ${checkUserBool(userState) ? userState.username : 'ไม่ได้ลงทะเบียน'
            }\n`;
          shareText += `วันที่ตอบแบบสำรวจ : ${ConvertDateShort(
            answer_date,
            'DD MMM YY'
          )}\n`;
          console.log(surveyData);

          share_answer.map((item) => {
            const questions = JSON.parse(item.question_detail);
            shareText += `หัวข้อ : ${item.name} \n`;
            questions.map((question, index) => {
              if (question.type == 'radiogroup' || question.type == 'dropdown') {
                if (question.value) {
                  const value = question.choices.find(
                    (x) => x.value == question.value
                  );
                  shareText += `คำถามข้อที่ ${index + 1} : ${question.title} \n`;
                  shareText += `ตอบ : ${value ? value.text : '-'}\n`;
                  shareText += question.note
                    ? `ความคิดเห็นเพิ่มเติม : ${question.note}\n`
                    : '';
                }
              } else if (question.type == 'checkbox') {
                if (question.value) {
                  const multiple = question.value;
                  const value = question.choices.filter((x) =>
                    question.value.includes(x.value)
                  );
                  shareText += `คำถามข้อที่ ${index + 1} : ${question.title} \n`;
                  shareText += `ตอบ : ${value.length > 0 ? value.map((x) => x.text).join(',') : '-'
                    }\n`;
                  shareText += question.note
                    ? `ความคิดเห็นเพิ่มเติม : ${question.note}\n`
                    : '';
                }
              } else if (question.type == 'text') {
                if (question.value) {
                  shareText += `คำถามข้อที่ ${index + 1} : ${question.title} \n`;
                  shareText += `ตอบ : ${question.value ? question.value : '-'}\n`;
                }
              }
            });
          });
          setshareTextMessage(shareText);
        } else {
          AlertError('', 'ไม่พบข้อมูล');
          return false;
        }
      } else {
        AlertError('', 'กรุณายืนยันตัวตนก่อนเข้าใช้งาน');
        return false;
      }
    } else {
      AlertError('', 'กรุณาเข้าสู่ระบบ/ยืนยันตัวตนก่อนเข้าใช้งาน');
      return false;
    }

  };

  const checkAccessRight = async () => {
    if (checkUserBool(userState)) {
      if (userState.is_verify) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }



  return (
    <div className='section-banner'>
      <Result
        style={{ padding: '20px 32px' }}
        icon={<CheckCircleTwoTone twoToneColor='#0c4da2' />}
        title={
          <Title level={2} style={{ color: '#0c4da2' }}>
            ได้รับความคิดเห็นของคุณแล้ว
          </Title>
        }
        extra={
          <div>
            <div style={{ margin: '14px 10px 14px 10px' }}>
              Copy Link ด้านล่าง เพื่อติดตามผลการรับฟัง
            </div>

            <Button
              type='primary'
              block
              size='large'
              className='button-radius'
              style={{ backgroundColor: '#0c4da2', fontSize: 15 }}
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/listeningDetail?survey_id=${survey_id}`
                )
              }
            >
              {clipboard.copied ? 'คัดลอกสำเร็จ' : 'คัดลอก URL'}
            </Button>


            <div
              className='feedback-button'
              style={{ marginTop: 20 }}
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/16oI_07hYLNoZQ2C3hG9bY3w15hHhNhpfaVwoNQUIOoU/edit?usp=sharing',
                  '_blank'
                );
                setTimeout(() => {
                  history.push('/');
                }, 1000);
              }}
            >
              <span>{'ส่ง Feedback'}</span>
            </div>

            <div
              onClick={() => shareAnswer()}
              className='feedback-button'
              style={{ marginTop: 20 }}
            >
              <span>{'แชร์ความคิดเห็นสาธารณะ'}</span>
            </div>

            {/* <Button
            // type='primary'
            block
            size='large'
            className='button-radius'
            style={{
              // backgroundColor: '#0c4da2',
              fontSize: 15,
              marginTop: 20,
            }}
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
          >
            กลับหน้าหลัก
          </Button> */}
            <div className='result-home-button'>
              <img
                onClick={() => {
                  history.push('/');
                  // setResult(false);
                }}
                src={StaticImage('/images/icon/home-lg-alt-duotone.png')}
              />
              <span
                onClick={() => {
                  history.push('/');
                  // setResult(false);
                }}
                style={{ color: '#8e99ad', fontSize: 11, marginTop: 10 }}
              >
                กลับหน้าหลัก
              </span>
            </div>
            {/* <Button
            type='primary'
            block
            size='large'
            className='button-radius'
            style={{
              backgroundColor: '#0c4da2',
              fontSize: 15,
              marginTop: 20,
            }}
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
          >
            {'กลับหน้าหลัก'}
          </Button> */}
            {/* <div
            className='feedback-button'
            style={{ marginTop: 20 }}
            onClick={() => setShowShare(true)}
          >
            <b>{'แชร์ความคิดเห็นของคุณให้เพื่อน'}</b>
          </div> */}
            {/* <div style={{ paddingTop: '10px', color: '#333333' }}>
            <Text style={{ color: '#333333', fontSize: '20px' }}>
              {' '}
              หากต้องการติดตามผลการรับฟัง กรุณาให้อีเมลล์สำหรับติดตามความ
              คืบหน้าของการรับฟังนี้
            </Text>
          </div>
          <div style={{ paddingTop: '18px', color: '#333333' }}>
            <Input placeholder='กรุณากรอกอีเมลล์' />
          </div>
          <div style={{ paddingTop: '18px', color: '#333333' }}>
            <Button
              block
              size='large'
              className='button-radius2'
              style={{ backgroundColor: '#f0f2f6' }}
            >
              ยืนยัน
            </Button>
          </div> */}
          </div>
        }
      />
      {showShare && (
        <div className='share-container'>
          <div className='header'>
            <span>แชร์</span>
            <img
              onClick={() => {
                setshareTextMessage('');
              }}
              style={{ position: 'absolute', right: 25 }}
              src={StaticImage('/images/icon/drawer-header-close.png')}
            />
          </div>
          <div className='share-button'>
            <div>
              <FacebookShareButton
                url={WEB_SITE}
                quote={shareTextMessage}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <span>Facebook</span>
            </div>
            <div>
              <TwitterShareButton
                url={WEB_SITE}
                title={shareTextMessage.substr(0, 170)}
                className='Demo__some-network__share-button'
              >
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <span>Twitter</span>
            </div>
            <div>
              <EmailShareButton
                url={WEB_SITE}
                subject={'แบบสำรวจความคิดเห็น'}
                body={shareTextMessage}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <span>E-mail</span>
            </div>

            <div onClick={() => clipboard.copy(shareTextMessage)}>
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/link-regular.png'
                  )}
                />
              </div>
              <span>
                {clipboard.copied ? 'คัดลอกข้อความสำเร็จ' : 'คัดลอกข้อความ'}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultSurvey;
