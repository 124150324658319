import React, { useEffect, useState } from 'react';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { StaticImage } from '../../utils/image';
import { List, Avatar } from 'antd';
import {
  StarOutlined,
  ShareAltOutlined,
  FormOutlined,
  Loading3QuartersOutlined,
  FileOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import PersonIcon from '@material-ui/icons/Person';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { encodeBase64 } from '../../utils/convertBase64';
const data = [
  {
    title: '-',
    icon: StaticImage('/images/icon/contact/map-marker-alt-duotone.png'),
  },
  {
    title: '-',
    icon: StaticImage('/images/icon/contact/phone-duotone.png'),
  },
  {
    title: '-',
    icon: StaticImage('/images/icon/contact/fax-duotone.png'),
  },
  {
    title: '-',
    icon: StaticImage('/images/icon/contact/mailbox-duotone.png'),
  },
  {
    title: '-',
    icon: StaticImage('/images/icon/contact/browser-duotone.png'),
  },
];

const Contact = (props) => {
  const history = useHistory();
  const [survey, setsurvey] = useState({});
  const [responsible_data, setresponsible_data] = useState([]);
  const [listItem, setListItem] = useState([
    // {
    //   type: 'person',
    //   title: '-',
    //   icon: <PersonIcon style={{ color: '#0c4da2', fontSize: 24 }} />,
    // },
    {
      title: '-',
      icon: StaticImage('/images/icon/contact/map-marker-alt-duotone.png'),
    },
    {
      title: '-',
      icon: StaticImage('/images/icon/contact/phone-duotone.png'),
    },
    {
      title: '-',
      icon: StaticImage('/images/icon/contact/fax-duotone.png'),
    },
    {
      title: '-',
      icon: StaticImage('/images/icon/contact/mailbox-duotone.png'),
    },
    {
      title: '-',
      icon: StaticImage('/images/icon/contact/browser-duotone.png'),
    },
  ]);
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  useEffect(() => {
    document.title = 'ข้อมูลหน่วยงาน';
    getSurvey();
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiSurvey.getContact({ survey_id });

      if (survey.status == 200) {
        Swal.close();
        const { data, responsible } = survey.data;
        if (data) {
          const newItem = [...listItem];
          newItem[0].title = <span>{data.location}</span> ?? <span>-</span>;
          newItem[1].title = (
            <a className='text-link' href={`tel:${data.phone}`}>
              {data.phone}
            </a>
          ) ?? <span>-</span>;
          newItem[2].title = <span>{data.fax}</span> ?? <span>-</span>;
          newItem[3].title = <span>{data.email}</span> ?? <span>-</span>;
          newItem[3].title = data.email ?? '-';
          newItem[4].title = (
            <a className='text-link' target='_blank' href={`${data.website}`}>
              {data.website}
            </a>
          ) ?? <span>-</span>;
          setListItem(newItem);
        }
        if (responsible) {
          setresponsible_data(responsible);
        }

        setsurvey(data);
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  return (
    <div
      style={{
        paddingBottom: '10px',
      }}
    >
      <section className='section-banner' style={{ padding: 10 }}>
        <div onClick={() => history.go(-1)} className='back-btn'>
          <ArrowLeftOutlined style={{ marginRight: 6 }} />
          <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
        </div>
        <span className='project-head'>{survey.project_name}</span>
      </section>
      {survey.banner && (
        <div className='d-flex-center'>
          <img
            style={{ marginTop: 15, width: '100%', maxWidth: 1000 }}
            src={survey.banner}
            oncontextmenu='return false;'
            onError={(i) => (i.target.style.display = 'none')}
          />
        </div>
      )}

      {/* <img
        style={{ width: '100%', marginTop: 15 }}
        src={StaticImage('/images/contact.png')}
      /> */}
      <section style={{ padding: 22, background: 'white', paddingBottom: 70 }}>
        <span className='contact-title'>{survey?.title}</span>
        <div>
          <span className='contact-description'>{survey?.description}</span>
        </div>
        <div className='contact' style={{ marginTop: 10 }}>
          <span className='contact-header'>ข้อมูลการติดต่อ</span>
          <List
            itemLayout='horizontal'
            dataSource={survey ? listItem : data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    item.type === 'person' ? item.icon : <img src={item.icon} />
                  }
                  title={item.title}
                />
              </List.Item>
            )}
          />
        </div>
        {responsible_data.length > 0 && (
          <div className='contact' style={{ marginTop: 10 }}>
            <span className='contact-header'>ผู้รับผิดชอบการรับฟัง</span>
            <List
              itemLayout='horizontal'
              dataSource={responsible_data}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <div className='d-flex flex-column'>
                        <div className='d-flex'>
                          <PersonIcon
                            style={{
                              color: '#0c4da2',
                              fontSize: 28,
                              marginRight: 16,
                              minWidth: 30,
                            }}
                          />
                          <span>{`${item.name}`}</span>
                        </div>
                        <div className='d-flex mt-3' style={{ marginTop: 15 }}>
                          <img
                            style={{
                              marginRight: 16,
                              minWidth: 30,
                              height: 20,
                            }}
                            src={StaticImage(
                              '/images/icon/contact/mailbox-duotone.png'
                            )}
                          />
                          <span>{`${item.email}`}</span>
                        </div>
                        <div className='d-flex mt-3' style={{ marginTop: 15 }}>
                          <img
                            style={{
                              marginRight: 16,
                              minWidth: 30,
                              height: 20,
                            }}
                            src={StaticImage(
                              '/images/icon/contact/phone-duotone.png'
                            )}
                          />
                          <a className='text-link' href={`tel:${item.phone}`}>
                            {item.phone}
                          </a>
                          {/* <a href={`tel:${item.phone}`}>{item.phone}</a> */}
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        )}
      </section>

      {(survey.isconclude == 'O' || !survey.isconclude) &&
        survey.date_balance >= 0 && (
          <div
            onClick={() => {
              history.push(`/DetailsList?survey_id=${encodeBase64(survey.survey_id)}`);
            }}
            className='bottom-button'
          >
            <div>
              <FormOutlined />
              <span>เริ่มแสดงความเห็น</span>
            </div>
          </div>
        )}
      {(survey.isconclude == 'O' || !survey.isconclude) &&
        survey.date_balance < 0 && (
          <div className='bottom-button'>
            <div className='disabled'>
              <Loading3QuartersOutlined />
              <span>อยู่ระหว่างสรุปผล</span>
            </div>
          </div>
        )}
      {survey.isconclude == 'D' && (
        <div className='bottom-button'>
          <div className='disabled'>
            <Loading3QuartersOutlined />
            <span>อยู่ระหว่างสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == 'C' && (
        <div className='bottom-button'>
          <div className='disabled'>
            <CloseCircleOutlined />
            <span>ไม่มีการสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == 'P' && (
        <div
          onClick={() => {
            history.push(`/Summary?survey_id=${encodeBase64(survey.survey_id)}`);
          }}
          className='bottom-button'
        >
          <div className='summary'>
            <FileOutlined />
            <span>อ่านสรุปผลการรับฟัง</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
