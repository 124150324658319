import React from 'react';
import { Card, Typography, Row, Col, Button, Space } from 'antd';
import {
  FormOutlined,
  Loading3QuartersOutlined,
  FileOutlined,
  CloseCircleOutlined,
  ShareAltOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './css/index.scss';
import ProgressBar from './Progressbar';
import { StaticImage } from '../../utils/image';
import { ConvertDateShort } from '../../utils/date';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import ApiSurvey from '../../api/survey';
import CommentAltEdit from '../iconComponent/CommentAltEdit';
import { encodeBase64 } from '../../utils/convertBase64';

const { Title, Text } = Typography;

const ListeningDetails = ({ survey, isContinue, setShowShare, isSended }) => {
  const history = useHistory();
  const userState = useSelector((state) => state.user);
  const testData = [{ bgcolor: '#387436', completed: 40 }];

  const checkComplete = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance > item.duration_day) {
        return 0;
      } else {
        return (
          ((item.duration_day - item.date_balance) / item.duration_day) * 100
        );
      }
    } else {
      return 100;
    }
  };
  const checkLeftTime = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance < 0) {
        return 'ปิดการรับฟัง';
      } else {
        return `เหลือ ${item.date_balance + 1} วัน`;
      }
    } else if (item.isconclude == 'D') {
      return 'อยู่ระหว่างการสรุปผล';
    } else if (item.isconclude == 'C') {
      return 'ไม่มีการสรุปผล';
    } else if (item.isconclude == 'P') {
      return 'เผยแพร่สรุปผลการรับฟัง';
    }
  };

  const checkButtonSubmit = () => {
    if (isSended) {
      return (
        <div className='btn-brown'>
          <ReloadOutlined />
          <span>ทำใหม่</span>
        </div>
      );
    }
    if (isContinue) {
      return (
        <div className='summary'>
          <ReloadOutlined />
          <span>ทำแบบสอบถามต่อ</span>
        </div>
      );
    } else {
      return (
        <div>
          <FormOutlined />
          <span>เริ่มแสดงความเห็น</span>
        </div>
      );
    }
  };

  const deleteOldAnswer = () => {
    Swal.fire({
      title: 'คุณแน่ใจแล้วใช่ไหม?',
      text: 'ต้องการยกเลิกความคิดเห็นเดิม เพื่อเริ่มทำใหม่',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true,
    }).then(async (ok) => {
      if (ok.isConfirmed) {
        const result = await ApiSurvey.cancelAnswer({ id: survey.survey_id });
        if (result.status === 200) {
          history.push(`/DetailsList?survey_id=${encodeBase64(survey.survey_id)}`);
        }
      }
    });
  };

  return (
    <div>
      <div className='survey-container'>
        <span className='survey-head'>{survey.survey_name}</span>
      </div>
      <Card type='inner' style={{ paddingBottom: 70 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div className='d-flex discuss-container' style={{ marginRight: 15 }}>
            <img
              src={StaticImage('/images/icon/users-class-light.svg')}
              style={{ marginRight: 10 }}
            />
            <label style={{ color: '#488345', fontSize: 14 }}>
              ร่วมเสนอ
            </label>
            <label style={{ color: '#494949', fontSize: 16 ,marginLeft: 40}}>
              {survey.answer_count ?? 0} คน
            </label>
          </div>
          <div className='d-flex'>
            <Space>
              <span
                style={{
                  color: '#494949',
                  fontSize: 16,
                  marginRight: 5,
                  fontWeight: 'bold',
                }}
              >
                ครั้งที่
              </span>
              <span
                style={{
                  color: '#0c4da2',
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                {survey.publish_no
                  ? survey.publish_no.toString().padStart('2', '0')
                  : '00'}
              </span>
            </Space>
          </div>
          <Button
            onClick={() => setShowShare(true)}
            icon={<ShareAltOutlined className='button-icon text-theme-blue' />}
            size={'small'}
            style={{
              marginRight: '3px',
              padding: '6px 10px 5px 9px;',
              border: 'solid 1px #0c4da2',
              backgroundColor: '#ffffff',
              borderRadius: 5,
            }}
          >
            <Text className='text-theme-blue' type={'secondary'}>
              แชร์
            </Text>
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
              {ConvertDateShort(survey.start_date, 'DD MMM YY')}
            </span>
            <section
              className='progress-list-filter'
              style={{ width: '100%', maxWidth: 400 }}
            >
              <ProgressBar
                bgcolor={survey.date_balance > 7 ? '#387436' : '#f96b57'}
                bgContainerColor={
                  survey.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'
                }
                leftTime={checkLeftTime(survey)}
                completed={checkComplete(survey)}
              />
            </section>
            <span>
              <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                {ConvertDateShort(survey.end_date, 'DD MMM YY')}
              </span>
            </span>
          </div>
        </div>

        <section className='menu-card-list'>
          {survey.conclusion_link && (
            <Card
              type='inner'
              className='out-card-list-layout'
              style={{
                marginBottom: '10px',
              }}
              onClick={() => {
                history.push(`/Conclusion?survey_id=${survey.survey_id}`);
              }}
            >
              <Text
                style={{
                  fontSize: '19px',
                  color: '#333333',
                }}
                strong
              >
                <Row wrap={false}>
                  <Col flex='none'>
                    <img
                      style={{ marginLeft: 10 }}
                      src={StaticImage('/images/icon/info-duotone.png')}
                    />
                    <Text
                      className='list-text-title'
                      style={{ paddingLeft: '27px' }}
                    >
                      {' '}
                      ข้อมูลสรุปจากหน่วยงาน
                    </Text>
                  </Col>
                  <Col flex='auto' align={'end'}>
                    {' '}
                    <Text type='secondary' style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Text>
                  </Col>
                </Row>
              </Text>
            </Card>
          )}
          {survey.survey_type_id === 3 && (
            <Card
              type='inner'
              className='out-card-list-layout'
              style={{
                marginBottom: '10px',
              }}
              onClick={() => {
                history.push(`/EvaluateDetail?survey_id=${encodeBase64(survey.survey_id)}`);
              }}
            >
              <Text
                style={{
                  fontSize: '19px',
                  color: '#333333',
                }}
                strong
              >
                <Row wrap={false}>
                  <Col flex='none'>
                    <img
                      src={StaticImage(
                        '/images/icon/pencil-alt-duotone-copy.png'
                      )}
                    />
                    <Text className='list-text-title'>ข้อมูลกฎหมาย</Text>
                  </Col>
                  <Col flex={'auto'} align={'end'}>
                    <Text type='secondary' style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Text>{' '}
                  </Col>
                </Row>
              </Text>
            </Card>
          )}
          <Card
            type='inner'
            className='out-card-list-layout'
            style={{
              marginBottom: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push(`/PrincileDetail?survey_id=${encodeBase64(survey.survey_id)}`);
            }}
          >
            <Text
              style={{
                fontSize: '19px',
                color: '#333333',
              }}
              strong
            >
              <Row wrap={false}>
                <Col flex='none'>
                  <img src={StaticImage('/images/icon/scroll-duotone.png')} />
                  <Text className='list-text-title'>
                    ข้อมูลประกอบการรับฟังความคิดเห็น
                  </Text>
                </Col>
                <Col flex='auto' align={'end'}>
                  <Text type='secondary'>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </Text>{' '}
                </Col>
              </Row>
            </Text>
          </Card>
          {survey.survey_type_id === 2 && (
            <Card
              type='inner'
              className='out-card-list-layout'
              style={{
                marginBottom: '10px',
              }}
              onClick={() => {
                history.push(`/DraftLaw?survey_id=${encodeBase64(survey.survey_id)}`);
              }}
            >
              <Text
                style={{
                  fontSize: '19px',
                  color: '#333333',
                }}
                strong
              >
                <Row wrap={false} style={{ cursor: 'pointer' }}>
                  <Col flex='none'>
                    <img
                      src={StaticImage(
                        '/images/icon/pencil-alt-duotone-copy.png'
                      )}
                    />
                    <Text className='list-text-title'> ร่างกฎหมาย</Text>
                  </Col>
                  <Col flex={'auto'} align={'end'}>
                    <Text type='secondary'>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Text>{' '}
                  </Col>
                </Row>
              </Text>
            </Card>
          )}

          <Card
            type='inner'
            className='out-card-list-layout'
            style={{
              marginBottom: '10px',
            }}
            onClick={() => {
              history.push(`/Contact?survey_id=${encodeBase64(survey.survey_id)}`);
            }}
          >
            <Text
              style={{
                fontSize: '19px',
                color: '#333333',
              }}
              strong
            >
              <Row wrap={false} style={{ cursor: 'pointer' }}>
                <Col flex='none'>
                  <img
                    src={StaticImage('/images/icon/landmark-duotone-copy.png')}
                  />
                  <Text className='list-text-title'> ข้อมูลติดต่อหน่วยงาน</Text>
                </Col>
                <Col flex='auto' align={'end'}>
                  <Text type='secondary'>
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </Text>{' '}
                </Col>
              </Row>
            </Text>
          </Card>
          {/* {survey.is_webboard == 1 && (
            <Card
              type='inner'
              className='out-card-list-layout'
              style={{
                marginBottom: '10px',
              }}
              onClick={() => {
                history.push(
                  `/Post?project_id=${survey.project_id}&survey_id=${survey.survey_id}`
                );
              }}
            >
              <Text
                style={{
                  fontSize: '19px',
                  color: '#333333',
                }}
                strong
              >
                <Row wrap={false}>
                  <Col flex='none'>
                    <CommentAltEdit className='comments-alt-duotone' />
                    <Text className='list-text-title'>ร่วมสนทนา</Text>
                  </Col>
                  <Col flex='auto' align={'end'}>
                    <Text type='secondary' style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Text>
                  </Col>
                </Row>
              </Text>
            </Card>
          )} */}

        </section>
      </Card>
      {(survey.isconclude == 'O' || !survey.isconclude) &&
        survey.date_balance >= 0 && (
          <div
            onClick={() => {
              isSended
                ? deleteOldAnswer()
                : history.push(`/DetailsList?survey_id=${encodeBase64(survey.survey_id)}`);
            }}
            className='bottom-button'
          >
            {checkButtonSubmit()}
          </div>
        )}
      {(survey.isconclude == 'O' || !survey.isconclude) &&
        survey.date_balance < 0 && (
          <div className='bottom-button'>
            <div className='disabled'>
              <Loading3QuartersOutlined />
              <span>อยู่ระหว่างสรุปผล</span>
            </div>
          </div>
        )}
      {survey.isconclude == 'D' && (
        <div className='bottom-button'>
          <div className='disabled'>
            <Loading3QuartersOutlined />
            <span>อยู่ระหว่างสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == 'C' && (
        <div className='bottom-button'>
          <div className='disabled'>
            <CloseCircleOutlined />
            <span>ไม่มีการสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == 'P' && (
        <div
          onClick={() => {
            history.push(`/Summary?survey_id=${encodeBase64(survey.survey_id)}`);
          }}
          className='bottom-button'
        >
          <div className='summary'>
            <FileOutlined />
            <span>อ่านสรุปผลการรับฟัง</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListeningDetails;
