import BrowserDuoTone from "../iconComponent/BrowserDuoTone";
import FaxDuoTone from "../iconComponent/FaxDuoTone";
import MailBoxDuoTone from "../iconComponent/MailBoxDuoTone";
import MapMargetDuoTone from "../iconComponent/MapMargetDuoTone";
import PhoneDuoTone from "../iconComponent/PhoneDuoTone";
import "./scss/index.scss";
const informationmodel = [
  {
    id: 1,
    icon: <MapMargetDuoTone className="map-marker-alt-duotone" />,
    content: `333/3 ถนนวิภาวดีรังสิต แขวงจอมพล เขต จตุจักร กรุงเทพมหานคร 10900`,
  },
  {
    id: 2,
    icon: <PhoneDuoTone className="map-marker-alt-duotone" />,
    content: "1207\n0-2033-9999",
  },
  {
    id: 3,
    icon: <FaxDuoTone className="map-marker-alt-duotone" />,
    content: `0-2033-9660`,
  },
  {
    id: 4,
    icon: <MailBoxDuoTone className="map-marker-alt-duotone" />,
    content: `info@​​sec.or.th`,
  },
  {
    id: 5,
    icon: <BrowserDuoTone className="map-marker-alt-duotone" />,
    content: `www.sec.or.th`,
  },
];

export default informationmodel;
