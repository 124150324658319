import React, { useState, useEffect } from 'react'
import './index.scss'
import {
    Input,
    Card,
    Avatar,
    Skeleton
} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPollH,
    faTimesCircle,
    faArrowCircleDown,
    faSync,
    faSlidersH,
    faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import TagDuoTone from "../../../iconComponent/TagDuoTone";
import { StaticImage } from '../../../../utils/image';
import { ConvertDateShort } from '../../../../utils/date';
import { checkComplete, checkLeftTime, checkLeftTimeIcon } from '../../../../utils';
import ProgressBar from "../../progressBar";
// import ProjectCardItem from './project';
import SurveyCardSubItem from './subCard';
import { useHistory } from 'react-router-dom';
import { encodeBase64 } from '../../../../utils/convertBase64';
const { Search } = Input;

const listTextOption = [
    { value: '', label: "ทั้งหมด" },
    { value: "O", label: "เปิดรับฟัง" },
    { value: "I", label: "ปิดรับฟัง" },
];

const ProjectCardV2 = ({ surveys = [], law_group, entitys = [], handleFavorite, agencyOption, ministryOption, surveyTypeOption, getFilterSurvey }) => {
    const [agency, setagency] = useState(null);
    const [ministry, setministry] = useState(null);
    const [surveyType, setsurveyType] = useState(null);
    const [statusSurvey, setStatusSurvey] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");


    const cleardata = () => {
        setSearchFilter("");
        setStatusSurvey(null);
        setagency(null);
        setministry(null);
        setsurveyType(null);
    };

    useEffect(() => {
        getFilterSurvey({
            ministry,
            agency,
            surveyType,
            statusSurvey,
            searchFilter,
        });
        return () => { };
    }, [agency, ministry, surveyType, statusSurvey, searchFilter]);

    return (
        <div id="project-card-container-v2">
            <div className="survey-card-container">
                {surveys.map((item, index) =>
                    <ProjectCardItem
                        key={"project-" + item.project.project_id}
                        survey={item}
                        law_group={law_group}
                        entitys={
                            item.survey_type_id !== 3 ?
                                entitys.filter(x => x.project_type_id == 1 && x.order_id != 98 && x.order_id != 99) :
                                entitys.filter(x => x.project_type_id == 2 && x.order_id != 98 && x.order_id != 99)
                        }
                        handleFavorite={handleFavorite} />)}
            </div>
            <div className="filter-container">
                <Search className="search" placeholder="ค้นหา" onSearch={(text) => setSearchFilter(text)} />
                <div>
                    <Card
                        title={
                            <div className="text-filter">
                                <FontAwesomeIcon color={"#6d7481"} icon={faSlidersH} />
                                <label>ตัวกรอง</label>
                            </div>
                        }
                        extra={
                            <div className="clear-filter" onClick={() => cleardata()}>
                                <FontAwesomeIcon color={"#0c4ca3"} icon={faSync} />
                                <label>ล้าง</label>
                            </div>
                        }
                        style={{ width: "100%" }}
                        className="Rounded-Rectangle-4-web"
                    >
                        <p>สถานะการรับฟัง</p>
                        <Select
                            className="basic-single "
                            classNamePrefix="select"
                            defaultValue={listTextOption[0]}
                            options={listTextOption}
                            value={listTextOption.find((x) => x.value == statusSurvey)}
                            onChange={(item) => {
                                setStatusSurvey(item.value);
                            }}
                        />
                        <p style={{ paddingTop: "1pc" }}> กระทรวง / หน่วยงานอิสระ</p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={ministryOption[0]}
                            value={ministryOption.find((x) => x.value == ministry)}
                            options={ministryOption}
                            onChange={(item) => {
                                setministry(item.value);
                                setagency(null);
                            }}
                        />
                        <p style={{ paddingTop: "1pc" }}>กรม</p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={agencyOption[0]}
                            options={
                                ministry > 0
                                    ? agencyOption.filter((x) => x.ministry_id == ministry)
                                    : agencyOption
                            }
                            value={agencyOption.find((x) => x.value == agency)}
                            onChange={(item) => {
                                setagency(item.value);
                            }}
                        />
                        <p style={{ paddingTop: "1pc" }}>
                            ร่างกฎหมาย / ประเมินผลสัมฤทธิ์
                        </p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={surveyTypeOption[0]}
                            options={surveyTypeOption}
                            value={surveyTypeOption.find((x) => x.value == surveyType)}
                            onChange={(item) => {
                                setsurveyType(item.value);
                            }}
                        />
                    </Card>
                </div>
            </div>
        </div>
    )
}

const ProjectCardItem = ({ survey, law_group, entitys, handleFavorite }) => {
    const project = survey.project;
    const history = useHistory();
    const [isCollapse, setIsCollapse] = useState(false);
    const renderImageLawGroup = (item) => {
        let law = "";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.find((x) => x.lawgroup_id == split[0]);
        }

        return (
            <div className="law-group-container d-flex-center">
                <div className="d-flex-center">
                    {law && law.image ? (
                        <Avatar
                            className="avatar-object-contain"
                            size={{
                                xs: 75,
                                sm: 75,
                                md: 75,
                                lg: 75,
                                xl: 75,
                                xxl: 75,
                            }}
                            style={{
                                height: 75,
                                width: 75,
                            }}
                            // shape={'circle'}
                            src={law.image}
                        />
                    ) : (
                        <Skeleton.Button active={true} size={100} shape={"circle"} />
                    )}
                </div>
            </div>
        );
    };
    const renderLawGroupText = (item) => {
        let law = "ไม่ได้ระบุ";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
            law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
        }

        return (
            <div
                className="d-flex discuss-container"
                style={{ marginRight: 15 }}
            >
                <img
                    src={StaticImage("/images/icon/books-duotone-1-copy.svg")}
                    style={{ marginRight: 5 }}
                    alt="webboard"
                    height={20}
                />
                <label className="lawgroup-text" style={{ color: "#333", fontSize: 16 }}>
                    {law}
                </label>
            </div>
        );
    };

    const renderFavorite = (survey) => {
        return (
            survey.is_like === true ? (
                <img
                    height={32}
                    width={32}
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    )}
                    alt="favorite"
                />
            ) : (
                <img
                    height={32}
                    width={32}
                    className="cursor-pointer"
                    onMouseOver={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    ))
                    }
                    onMouseOut={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    ))
                    }
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    )}
                    alt="favorite"
                />
            )
        );
    }


    const collapse = (e, item) => {
        e.stopPropagation();
        setIsCollapse(!isCollapse);
    };

    const navigate = (project_id) => {
        history.push(`/ProjectTimeline?projectId=${encodeBase64(project_id)}`);
    }

    return (
        <section style={{ marginBottom: 20 }}>
            <div className="survey-card" onClick={() => navigate(project.project_id)}>
                <div className="column1">
                    {renderImageLawGroup(survey)}
                    <div
                        className="d-flex-center"
                        style={{ marginTop: 20, minWidth: 170 }}
                    >
                        {survey.agency_id > 0 && (
                            <div
                                className="organize-container"
                                style={{ margin: 0, maxWidth: 100 }}
                            >
                                <img
                                    alt="agency_image"
                                    src={survey.agency_image_uploaded ?? survey.agency_image}
                                    className="logo"
                                    oncontextmenu="return false;"
                                    onError={(i) => (i.target.style.display = "none")}
                                />
                                <label style={{ paddingLeft: "2px" }}>
                                    {survey.agency_abbrv}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="project-type" style={{ marginTop: 20 }}>
                        <TagDuoTone />
                        <span>
                            {survey.survey_type_name}
                        </span>
                    </div>
                </div>
                <div className="column2">
                    <div className="d-flex-align-center">
                        <div className="d-flex-align-center project-id-container">
                            <span className="text-regular">เลขที่ (ID) {project.project_id}</span>
                        </div>
                        <div className="d-flex-align-center">
                            <img
                                src={StaticImage("/images/icon/calendar-alt-duotone-2.svg")}
                                style={{ marginRight: 10 }}
                                alt="calendar"
                                height={20}
                            />
                            <span className="text-regular">
                                {ConvertDateShort(project.createdate, "DD MMM YY")}
                            </span>
                        </div>
                    </div>

                    <div className="d-flex">
                        <label className="title flex-1">{project.project_name}</label>
                        <div onClick={(e) => handleFavorite(e, survey)}>
                            <div class="group relative py-2">
                                {renderFavorite(survey)}
                                <div class="hidden group-hover:block ...">ถูกใจ</div>
                            </div>
                        </div>
                    </div>
                    <div className="webboard-row">
                        {renderLawGroupText(survey)}
                    </div>
                    <div className="d-flex-align-center process-container">
                        <FontAwesomeIcon icon={faBalanceScale} />
                        <span className="entity-text text-regular">
                            {project.entity_name ?? entitys[0]?.entity_name}
                        </span>
                    </div>
                    <div className="d-flex-align-center flex-1" style={{ justifyContent: 'space-between', marginTop: 20, alignSelf: 'flex-end', width: '100%' }}>
                        <div className="project-timeline-body-bottom flex-1">
                            <div
                                className="d-flex"
                                style={{ flexWrap: "wrap", marginTop: -10 }}
                            >
                                {/* {entitys.map((item, index) => {
                                    const findIndex = entitys.findIndex(
                                        (x) =>
                                            x.activity_id ==
                                            (project.entity_activity_id ??
                                                entitys[0].activity_id)
                                    );
                                    let className = "";
                                    if (
                                        item.activity_id ==
                                        (project.entity_activity_id ??
                                            entitys[0].activity_id)
                                    ) {
                                        className = "active";
                                    }
                                    if (findIndex > index) {
                                        className += "pass";
                                    }
                                    return (
                                        <div
                                            className={`process-step d-flex-center ${className}`}
                                        >
                                            {item.activity_abbrv}
                                        </div>
                                    );
                                })} */}
                                {entitys.map((item, index) => {
                                    let order_id = project.process_order;
                                    if (survey.survey_type_id == 3) {
                                        if (order_id == 1) {
                                            order_id = entitys[0].order_id
                                        }
                                    }
                                    const findIndex = entitys.findIndex(
                                        (x) =>
                                            x.order_id ==
                                            (order_id ?? entitys[0].order_id)
                                    );
                                    let className = '';

                                    if (
                                        item.order_id ==
                                        (order_id ?? entitys[0].order_id)
                                    ) {
                                        className = 'active';
                                    }
                                    if (findIndex > index) {
                                        className += 'pass';
                                    }
                                    return (
                                        <div
                                            className={`process-step d-flex-center ${className}`}
                                        >
                                            {item.entity_abbrv}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="d-flex expand cursor-pointer">
                            <div className="badge-public">{survey.surveys.length + 1}</div>
                            <div class="d-flex" style={{ alignItems: 'center' }} onClick={(e) => collapse(e, survey)}>
                                <span style={{ marginRight: 5 }}>{!isCollapse ? 'ขยาย' : 'ปิด'}</span>
                                <img
                                    src={StaticImage(!isCollapse ? "/images/icon/chevron-circle-down.svg" : "/images/icon/chevron-circle-up.png")}
                                    style={{ marginRight: 5 }}
                                    height={24}
                                    alt="public-no"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isCollapse && <SurveyCardSubItem survey={survey} />}
            {(isCollapse && survey.surveys.length > 0) && survey.surveys.map(sub => {
                return <SurveyCardSubItem survey={sub} />
            })}
        </section>
    )
}



export default ProjectCardV2;
