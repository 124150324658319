import React, { useState, useEffect } from 'react';
import { Typography, Space, Row, Col, Button, Image } from 'antd';
import { StarOutlined, ShareAltOutlined } from '@ant-design/icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@material-ui/core';
import { useClipboard } from 'use-clipboard-copy';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import './css/index.scss';
import { StaticImage } from '../../utils/image';
import { ConvertDateShort } from '../../utils/date';

const { Text, Link, Title } = Typography;
const Section4 = ({ survey }) => {
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const [share, setshare] = useState({});
  const [showShare, setShowShare] = useState(false);
  useEffect(() => {
    setshare({
      title: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${
        survey.survey_name
      } ภายใต้โครงการ ${survey.project_name} ได้ที่ ${
        window.location.origin
      }/listeningDetail?survey_id=${
        survey.survey_id
      } ตั้งแต่วันที่ ${ConvertDateShort(
        survey.start_date,
        'DD MMM YY'
      )} ถึงวันที่ ${ConvertDateShort(survey.end_date, 'DD MMM YY')} 

      โดยเมื่อกระบวนการรับฟังความคิดเห็นได้ดำเนินการเสร็จสิ้นแล้ว หน่วยงานผู้รับผิดชอบจะได้เผยแพร่สรุปผลการรับฟังความคิดเห็นและรายงานการวิเคราะห์ผลกระทบของร่างกฎหมายหรือรายงานการประเมินผลสัมฤทธิ์ของกฎหมาย (แล้วแต่กรณี) ซึ่งท่านสามารถเข้าถึงได้ตาม Link ข้างต้น
      
      มาร่วมเป็นส่วนหนึ่งในการสร้างกฎหมายของประชาชน`,
      subject: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${
        survey.survey_name
      } ภายใต้โครงการ ${survey.project_name} ได้ที่ ${
        window.location.origin
      }/listeningDetail?survey_id=${
        survey.survey_id
      } ตั้งแต่วันที่ ${ConvertDateShort(
        survey.start_date,
        'DD MMM YY'
      )} ถึงวันที่ ${ConvertDateShort(survey.end_date, 'DD MMM YY')}`,
      shareUrl: `${window.location.origin}/listeningDetail?survey_id=${survey.survey_id}`,
    });
    return () => {};
  }, [survey]);
  return (
    <div>
      {' '}
      <section>
        <section
          style={{
            backgroundColor: '#f1f3f7',
            paddingBottom: '10px',
          }}
        >
          <section style={{ padding: 10 }}>
            <span className='project-head'>{survey?.project_name}</span>
          </section>
        </section>
        <section style={{ paddingTop: '12px' }}>
          <Container maxWidth='xl'>
            <Row>
              <Col span={12}>
                <Title level={5}>ข้อมูลกฎหมาย</Title>
              </Col>
              <Col span={12} align='end'>
                {/* <Button icon={<StarOutlined />} /> */}
                <Button
                  onClick={() => setShowShare(true)}
                  icon={<ShareAltOutlined />}
                  style={{ marginLeft: '8px', paddingTop: '1px' }}
                >
                  แชร์
                </Button>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <section style={{ paddingTop: '22px' }}>
            <Container maxWidth='xl'>
              <Title level={5} style={{ color: '#0c4da2' }}>
                เนื้อหากฎหมาย
              </Title>
              <div style={{ paddingTop: '22px' }}>
                <hr />
              </div>
            </Container>
          </section>
          <section>
            <Container maxWidth='xl' style={{ paddingTop: '22px' }}>
              <div
                className='ql-editor'
                dangerouslySetInnerHTML={{ __html: survey.content_desc }}
              ></div>
              {survey.content_files && survey.content_files.length > 0 && (
                <div>
                  {survey.content_files.map((item, index) => {
                    if (item.type !== 'contentlaw') {
                      return <></>;
                    }
                    return (
                      <div className='d-flex'>
                        <label
                          style={{ fontSize: 14 }}
                          className='text-link cursor-pointer'
                          onClick={() =>
                            window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')
                          }
                        >
                          {item.file_name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </Container>
          </section>

          <section>
            <Container maxWidth='xl' style={{ paddingTop: '22px' }}>
              {/* <hr style={{ backgroundColor: '#ced3da' }} /> */}
            </Container>
          </section>
        </section>
      </section>
      {showShare && (
        <div className='share-container'>
          <div className='header'>
            <span>แชร์</span>
            <img
              onClick={() => setShowShare(false)}
              style={{ position: 'absolute', right: 25 }}
              src={StaticImage('/images/icon/drawer-header-close.png')}
            />
          </div>
          <div className='share-button'>
            <div>
              <FacebookShareButton
                url={share.shareUrl}
                quote={share.title}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <span>Facebook</span>
            </div>
            {/* <div>
              <LineShareButton url={share.shareUrl} title={share.subject}>
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
            <div>
              <TwitterShareButton
                url={' '}
                title={share.title}
                className='Demo__some-network__share-button'
              >
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <span>Twitter</span>
            </div>
            <div>
              <EmailShareButton
                url={''}
                subject={share.subject}
                body={share.title}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <span>E-mail</span>
            </div>
            <div
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/listeningDetail?survey_id=${survey.survey_id}`
                )
              }
            >
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/link-regular.png'
                  )}
                />
              </div>
              <span>คัดลอกลิงค์</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Section4;
