import React from 'react';
import { Tabs } from 'antd';
import ListeningList from './ListeningList';
import { BarChartOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import ListeningList2 from './ListeningList2';

const { TabPane } = Tabs;

function callback(key) {}

const renderTabBar = (props, DefaultTabBar) => {
  return <DefaultTabBar {...props} className='site-custom-tab-bar' />;
};

const ListeningTab = ({ surveys }) => {
  return (
    <Row style={{ marginTop: 10 }}>
      <ListeningList surveys={surveys} />
      {/* <Col span={24}>
        <Tabs
          defaultActiveKey='1'
          renderTabBar={renderTabBar}
          render
          onChange={callback}
        >
          <TabPane
            tab={
              <div>
                <span>
                  <BarChartOutlined rotate={90} />
                  <label>แบบรับความคิดเห็น</label>
                </span>
              </div>
            }
            key='1'
          >
            <ListeningList
              surveys={surveys.filter(
                (x) => x.survey_type_name != 'ประเมินผลสัมฤทธิ์'
              )}
            />
          </TabPane>
          <TabPane
            tab={
              <div>
                <span>
                  <BarChartOutlined />
                  <label>ประเมินผลสัมฤทธิ์</label>
                </span>
              </div>
            }
            key='2'
          >
            <ListeningList
              surveys={surveys.filter(
                (x) => x.survey_type_name == 'ประเมินผลสัมฤทธิ์'
              )}
            />
          </TabPane>
        </Tabs>
      </Col>
    */}
    </Row>
  );
};

export default ListeningTab;
