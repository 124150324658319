import React, { useState, useEffect } from 'react'
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import './index.scss';
import Swal from 'sweetalert2';
import ApiContent from '../../api/content';

const Rule = (props) => {

    const history = useHistory();
    const [survey, setsurvey] = useState({});

    const type = new URLSearchParams(props.location.search).get('type');
    useEffect(() => {
        if (type == 'termcondition') {
            document.title = 'Term & Condition';
        }
        if (type == 'securitypolicy') {
            document.title = 'Security Policy';
        }
        if (type == 'privacypolicy') {
            document.title = 'Privacy Policy';
        }
        if (type == 'contactus') {
            document.title = 'ติดต่อเรา';
        }
        getContent();
        return () => { };
    }, [type]);

    const getContent = async () => {
        try {
            Swal.showLoading();
            const survey = await ApiContent.get({ type });
            if (survey.status == 200) {
                Swal.close();
                const { data } = survey.data;

                setsurvey(data);
            }
        } catch (error) {
            Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
                history.go(-1);
            });
        }
    };
    return (
        <div id='webview-page'>
            <div className='section-banner'></div>
            <div
                className='d-flex-center flex-column w-100'
                style={{
                    position: 'absolute',
                    marginTop: -50,
                    top: 0,
                    height: 300,
                    backgroundColor: '#0c4da2',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: -1,
                }}
            ></div>
            <div
                className='d-flex-center flex-column'
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Container>
                    <div
                        className='d-flex menu-top'
                        style={{ justifyContent: 'space-between' }}
                    >
                        <div
                            onClick={() => {
                                history.go(-1);
                            }}
                            className='cursor-pointer'
                        >
                            <span>{'ย้อนกลับ'}</span>
                        </div>
                    </div>
                    <div className='rule-container'>
                        <div className='d-flex'>
                            {/* <label className='title'>กฎ กติกา และมารยาท</label> */}
                        </div>
                        <div className='d-flex rule-content flex-column'>
                            {/* <label className='title-content'>
                                การร่วมกิจกรรมแสดงความเห็นในกระทู้
                                ผู้เข้าร่วมพึงต้องปฏิบัติดังต่อไปนี้
                            </label> */}
                            <div className='d-flex flex-column'>
                                <span
                                    className='contact-description ql-editor'
                                    dangerouslySetInnerHTML={{ __html: survey?.content }}
                                ></span>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Rule;
