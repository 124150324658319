import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth, setHeaderIPv4 } from '../utils';

class ApiProjectTimeline extends Component {
  static getById = async ({ project_id = 0 }) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/timeline/${project_id}`,
      method: 'get',
    });
    return result;
  };
}

export default ApiProjectTimeline;
