import React, { useState, useEffect } from 'react'
import './index.scss'
import {
    Input,
    Card,
    Avatar,
    Skeleton
} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPollH,
    faTimesCircle,
    faArrowCircleDown,
    faSync,
    faSlidersH,
    faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import TagDuoTone from "../../../iconComponent/TagDuoTone";
import { StaticImage } from '../../../../utils/image';
import { ConvertDateShort } from '../../../../utils/date';
import { checkComplete, checkLeftTime, checkLeftTimeIcon } from '../../../../utils';
import ProgressBar from "../../progressBar";
// import ProjectCardItem from './project';
import SurveyCardSubItem from './subCard';
import { useHistory } from 'react-router-dom';
import { encodeBase64 } from '../../../../utils/convertBase64';
const { Search } = Input;

const listTextOption = [
    { value: '', label: "ทั้งหมด" },
    { value: "O", label: "เปิดรับฟัง" },
    { value: "I", label: "ปิดรับฟัง" },
];

const ProjectCardV2 = ({ surveys = [], law_group, entitys = [], handleFavorite, agencyOption, ministryOption, surveyTypeOption, getFilterSurvey }) => {
    const [agency, setagency] = useState(null);
    const [ministry, setministry] = useState(null);
    const [surveyType, setsurveyType] = useState(null);
    const [statusSurvey, setStatusSurvey] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");


    const cleardata = () => {
        setSearchFilter("");
        setStatusSurvey(null);
        setagency(null);
        setministry(null);
        setsurveyType(null);
    };

    useEffect(() => {
        getFilterSurvey({
            ministry,
            agency,
            surveyType,
            statusSurvey,
            searchFilter,
        });
        return () => { };
    }, [agency, ministry, surveyType, statusSurvey, searchFilter]);

    return (
        <div id="project-card-container-v2-mobile">
            <div className="survey-card-container">
                {surveys.map((item, index) =>
                    <ProjectCardItem
                        key={"project-" + item.project.project_id}
                        survey={item}
                        law_group={law_group}
                        entitys={
                            entitys.filter(x => x.project_type_id == item.project.project_type_id && x.order_id != 98 && x.order_id != 99)
                        }
                        handleFavorite={handleFavorite} />)}
            </div>
        </div>
    )
}

const ProjectCardItem = ({ survey, law_group, entitys, handleFavorite }) => {
    const project = survey.project;
    const entity_name = (project.entity_name) ?? entitys[0]?.entity_name;
    const history = useHistory();
    const [isCollapse, setIsCollapse] = useState(false);
    const [iscancel, setiscancel] = useState(false)
    const renderImageLawGroup = (item) => {
        if (entity_name == "ออกเป็นกฎหมาย") {
            return <>
                <div className="law-group-container-mobile outlaw d-flex-center" style={{ marginRight: 15 }}>
                    <div className="d-flex-center">
                        <Avatar
                            alt="image of law group"
                            className="avatar-object-contain"
                            size={{
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 24,
                                xl: 24,
                                xxl: 24,
                            }}
                            style={{
                                height: 24,
                                width: 24,
                            }}
                            // shape={'circle'}
                            src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
                        />
                    </div>
                </div>
            </>;
        }
        let law = "";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.find((x) => x.lawgroup_id == split[0]);
        }

        return (
            <div className="law-group-container-mobile d-flex-center" style={{ marginRight: 15 }}>
                <div className="d-flex-center">
                    {law && law.image ? (
                        <Avatar
                            alt="image of law group"
                            className="avatar-object-contain"
                            size={{
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 24,
                                xl: 24,
                                xxl: 24,
                            }}
                            style={{
                                height: 24,
                                width: 24,
                            }}
                            // shape={'circle'}
                            src={law.image}
                        />
                    ) : (
                        <Skeleton.Button active={true} size={24} shape={"circle"} />
                    )}
                </div>
            </div>
        );
    };
    const renderLawGroupText = (item) => {
        let law = "ไม่ได้ระบุ";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
            law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
        }

        return (
            <div
                className="d-flex discuss-container"
                style={{ marginRight: 15 }}
            >
                <img
                    src={StaticImage("/images/icon/books-duotone-1-copy.svg")}
                    style={{ marginRight: 5 }}
                    alt="webboard"
                    height={18}
                />
                <label className="lawgroup-text" style={{ color: "#333" }}>
                    {law}
                </label>
            </div>
        );
    };

    const renderFavorite = (survey) => {
        return (
            survey.is_like === true ? (
                <img
                    height={32}
                    width={32}
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    )}
                    alt="favorite"
                />
            ) : (
                <img
                    height={32}
                    width={32}
                    className="cursor-pointer"
                    onMouseOver={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    ))
                    }
                    onMouseOut={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    ))
                    }
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    )}
                    alt="favorite"
                />
            )
        );
    }


    const collapse = (e, item) => {
        e.stopPropagation();
        setIsCollapse(!isCollapse);
    };

    const navigate = (project_id) => {
        history.push(`/ProjectTimeline?projectId=${encodeBase64(project_id)}`);
    }

    useEffect(() => {
        if (project) {
            let order_id = project.process_order;
            if (order_id == 1) {
                order_id = entitys[0].order_id
            }
            const entityItem = entitys.find(
                (x) =>
                    x.order_id ==
                    (order_id ?? entitys[0].order_id)
            );
            if (entityItem?.entity_name == "ยกเลิก") {
                setiscancel(true)
            }

        }

        return () => {

        }
    }, [project])

    return (
        <section style={{ marginBottom: 20 }}>
            <div className={`survey-card ${entity_name == "ไม่ออกเป็นกฎหมาย" || iscancel ? " law-cancal" : ""}`}
                onClick={() => navigate(project.project_id)}>
                {(entity_name == "ออกเป็นกฎหมาย" && project.law_title) &&
                    <div className='d-flex law_publish_title' style={{ alignItems: 'center' }}>
                        <Avatar
                            alt="image of law group"
                            className="avatar-object-contain"
                            style={{
                                height: 25,
                                width: 25,
                            }}
                            // shape={'circle'}
                            src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
                        />
                        {project.law_title}
                    </div>
                }
                <div className="d-flex row1">
                    <div className="d-flex-align-center flex-1">
                        {renderImageLawGroup(survey)}
                        {survey.agency_id > 0 && (
                            <div
                                className="organize-container"
                                style={{ margin: 0, marginRight: 15 }}
                            >
                                <img
                                    alt="agency_image"
                                    src={survey.agency_image_uploaded ?? survey.agency_image}
                                    className="logo"
                                    oncontextmenu="return false;"
                                    onError={(i) => (i.target.style.display = "none")}
                                />
                                <label className='line-clamp-2' style={{ paddingLeft: "2px" }}>
                                    {survey.agency_short ?? survey.agency_abbrv}
                                </label>
                            </div>
                        )}
                        <div className="project-type">
                            <TagDuoTone />
                            <span>
                                {survey.survey_type_name}
                            </span>
                        </div>

                    </div>
                    <div onClick={(e) => handleFavorite(e, survey)}>
                        <div class="group relative py-2">
                            {renderFavorite(survey)}
                            <div class="hidden group-hover:block ...">ถูกใจ</div>
                        </div>
                    </div>
                </div>
                <div className="column2">
                    {/* <div className="d-flex-align-center">
                        <div className="d-flex-align-center project-id-container" style={{ marginRight: 10 }}>
                            <span className="text-regular">เลขที่ (ID) {project.project_id}</span>
                        </div>
                        <div className="d-flex-align-center">
                            <img
                                src={StaticImage("/images/icon/calendar-alt-duotone-2.svg")}
                                style={{ marginRight: 10 }}
                                alt="calendar"
                                height={20}
                            />
                            <span className="text-regular">
                                {ConvertDateShort(project.createdate, "DD MMM YY")}
                            </span>
                        </div>
                    </div> */}

                    <div className="d-flex">
                        <label className="title flex-1">{project.project_name}</label>
                    </div>
                    <div className="webboard-row">
                        {renderLawGroupText(survey)}
                    </div>
                    <div className="d-flex-align-center process-container">
                        <FontAwesomeIcon icon={faBalanceScale} />
                        <span className="entity-text text-regular">
                            {project.entity_name ?? entitys[0]?.entity_name}
                        </span>
                    </div>
                    <div className="d-flex-align-center flex-1" style={{ justifyContent: 'space-between', alignSelf: 'flex-end', width: '100%' }}>
                        {(entity_name == "ออกเป็นกฎหมาย" && project.law_url) &&
                            <label onClick={(e) => {
                                e.stopPropagation();
                                window.open(project.law_url, '_blank')
                            }} className='law_publish_link flex-1'>{project.law_url}</label>
                        }
                        {entity_name !== "ออกเป็นกฎหมาย" &&
                            <div className="project-timeline-body-bottom flex-1">
                                <div
                                    className="d-flex"
                                    style={{ flexWrap: "wrap", marginTop: -10 }}
                                >
                                    {entitys.map((item, index) => {
                                        let order_id = project.process_order;
                                        if (order_id == 1) {
                                            order_id = entitys[0].order_id
                                        }
                                        const findIndex = entitys.findIndex(
                                            (x) =>
                                                x.order_id ==
                                                (order_id ?? entitys[0].order_id)
                                        );
                                        let className = '';

                                        if (
                                            item.order_id ==
                                            (order_id ?? entitys[0].order_id)
                                        ) {
                                            className = 'active';
                                        }
                                        if (findIndex > index) {
                                            className += 'pass';
                                        }
                                        return (
                                            <div
                                                className={`process-step d-flex-center ${className} ${iscancel ? " iscancel" : ""}`}
                                            >
                                                {item.entity_abbrv}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                        <div className="d-flex expand cursor-pointer">
                            <div className="badge-public">{survey.surveys.length + 1}</div>
                            <div class="d-flex" style={{ alignItems: 'center' }} onClick={(e) => collapse(e, survey)}>
                                <span style={{ marginRight: 5 }}>{!isCollapse ? 'ขยาย' : 'ปิด'}</span>
                                <img
                                    src={StaticImage(!isCollapse ? "/images/icon/chevron-circle-down.svg" : "/images/icon/chevron-circle-up.png")}
                                    style={{ marginRight: 5 }}
                                    height={24}
                                    alt="public-no"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {isCollapse && <SurveyCardSubItem survey={survey} />}
            {(isCollapse && survey.surveys.length > 0) && survey.surveys.map(sub => {
                return <SurveyCardSubItem survey={sub} />
            })}
        </section>
    )
}



export default ProjectCardV2;