import React, { useEffect, useState } from 'react';
import '../../../main/submenulist.scss';
import ApiSurvey from '../../../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../../../utils/image';
// import YoutuberPlayer from "./react-player/YoutuberPlayer";
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import Select from 'react-select';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { Container, fade, Grid, makeStyles } from '@material-ui/core';
import { Card, Space, Skeleton } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import PersonIcon from '@material-ui/icons/Person';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TagDuoTone from '../../../iconComponent/TagDuoTone';
import CommentAltEdit from '../../../iconComponent/CommentAltEdit';
import './index.scss';
import { ConvertDateShort } from '../../../../utils/date';
import ProgressBar from './ProgressBar';
import ApiMaster from '../../../../api/master';
import { FavoriteBorder, MoreHorizOutlined } from '@material-ui/icons';

import {
  HeartFilled,
  HeartOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { checkLeftTime, checkComplete } from '../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPollH,
  faTimesCircle,
  faArrowCircleDown,
  faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import { encodeBase64 } from '../../../../utils/convertBase64';
const scoreText = [
  { value: 'last', label: '#ล่าสุด', color: '#0c4da2' },
  { value: 'answer', label: '#ตอบมากที่สุด', color: '#a51830' },
  // { value: 'read', label: '#ความนิยม', color: '#387436' },
  { value: 'popular', label: '#ความนิยม', color: '#387436' },
  //{ value: 'project', label: '#เฉพาะโครงการ', color: '#387436' },
];

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontFamily: 'Kanit',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectOption: {
    zIndex: 10,
  },
  cardSeq: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 30,
    padding: 10,
    boxShadow: '3.9px 1px 6px 0 rgba(137, 148, 169, 0.4)',
  },
  cardSeqLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardSurveyTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#0c4da2',
    WebkitLineClamp: 1,
    maxHeight: 20,
    textOverflow: 'ellipsis',
  },
  cardSurveyDescription: {
    color: '#666666',
    fontSize: 15,
  },
  cardSurveyTypeImage: {
    width: 20,
    height: 20,
    marginRight: 5,
    objectFit: 'contain',
    color: '#0c4da2',
  },
  cardSurveyTypeText: {
    fontSize: 14,
    color: '#0c4da2',
    fontWeight: 'bold',
  },
  cardSurveyLeftIcon: {
    width: 101,
    height: 101,
    padding: '18px 22px 20px 31px',
    borderRadius: 50,
    border: 'solid 5px #85b0e8',
    backgroundColor: '#0c4da2',
  },
  cardSurveyCommentIcon: {
    fontSize: 14,
    color: '#999',
  },
}));

const SurveyCard = ({
  surveys = [],
  getFilterSurvey,
  getOrderSurvey,
  handleFavorite,
  entitys,
  law_group,
}) => {
  const classes = useStyles();
  const [agency, setagency] = useState(null);
  const [ministry, setministry] = useState(null);
  const [surveyType, setsurveyType] = useState(null);
  const [orderFilter, setorderFilter] = useState('project');
  const [searchFilter, setSearchFilter] = useState('');
  const [statusSurvey, setStatusSurvey] = useState(null);
  const [agencyOption, setAgencyOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);
  const [ministryOption, setMinistryOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);
  const [surveyTypeOption, setSurveyTypeOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);

  const history = useHistory();

  useEffect(() => {
    getFilterOption();
    return () => { };
  }, []);

  useEffect(() => {
    getFilterSurvey({
      ministry,
      agency,
      surveyType,
      statusSurvey,
      searchFilter,
    });
    return () => { };
  }, [agency, ministry, surveyType, statusSurvey, searchFilter]);

  useEffect(() => {
    getOrderSurvey({ orderFilter });
    return () => { };
  }, [orderFilter]);

  const getFilterOption = async () => {
    const filter = await ApiMaster.homeFilter();
    if (filter.status == 200) {
      const { data } = filter.data;
      console.log('filter', data);
      setAgencyOption([...agencyOption, ...data.agency]);
      setMinistryOption([...ministryOption, ...data.ministry]);
      setSurveyTypeOption([
        ...surveyTypeOption,
        ...data.survey_type.filter((x) => x.label != 'หลักการ'),
      ]);
    }
  };

  const cleardata = () => {
    setagency(null);
    setministry(null);
    setsurveyType(null);
  };

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
      <Grid
        container
        direction='row'
        justify='flex-start'
        className='flex-column'
      >
        {/* <div style={{ display: 'flex' }}>
          <Space style={{ marginLeft: 5 }}>
            {scoreText.map((res, i) => (
              <div
                key={res.value + i}
                onClick={() => setorderFilter(res.value)}
                className={classes.cardSeq}
                style={{
                  backgroundColor:
                    orderFilter == res.value ? res.color : '#fff',
                }}
              >
                <label
                  style={{
                    color: orderFilter == res.value ? '#fff' : res.color,
                  }}
                  className={classes.cardSeqLabel}
                >
                  {res.label}
                </label>
              </div>
            ))}
          </Space>
        </div> */}
        {surveys.length > 0 ? (
          <div className='card-survey-mobile' style={{ marginTop: 5 }}>
            {surveys.map((res, i) => {
              return orderFilter !== 'project' ? (
                <SurveyCardList
                  law_group={law_group}
                  handleFavorite={handleFavorite}
                  history={history}
                  classes={classes}
                  res={res}
                  entitys={entitys}
                />
              ) : (
                <div className='project-container-home-page'>
                  <ProjectCardV2
                    classes={classes}
                    handleFavorite={handleFavorite}
                    entitys={entitys}
                    history={history}
                    law_group={law_group}
                    res={res}
                    project={res.project}
                    renderType='main'
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className='card-survey-mobile'
            style={{ marginTop: 5, padding: 50 }}
          >
            <label>ไม่มีรายการ ระบบจะแสดงประวัติจากการกด หัวใจ ลองเลือกการติดตามได้จากหน้าหลัก</label>
          </div>
        )}
      </Grid>
    </div>
  );
};

const SurveyCardList = ({
  law_group,
  res,
  classes,
  handleFavorite,
  history,
  entitys,
}) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const handleChild = (e, item) => {
    e.stopPropagation();
    setIsCollapse(!isCollapse);
  };

  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container-mobile d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 40,
                sm: 40,
                md: 40,
                lg: 40,
                xl: 40,
                xxl: 40,
              }}
              style={{
                height: 40,
                width: 40,
              }}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={40} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        onClick={() => {
          {
            if (res.is_coming === '1') {
            } else {
              history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
            }
          }
        }}
        className='container-survey d-flex flex-column'
      >
        <div
          className='d-flex'
          style={{
            paddingBottom: 10,
            borderBottom: '1px solid #e5e6ee',
          }}
        >
          <div className='d-flex' style={{ flex: 1, alignItems: 'center' }}>
            {renderImageLawGroup(res)}
            {res.agency_id > 0 && (
              <div className='agency-container' style={{ maxWidth: 100 }}>
                <img
                  alt="agency_image"
                  src={res.agency_image_uploaded ?? res.agency_image}
                  className="logo"
                  oncontextmenu="return false;"
                  onError={(i) => (i.target.style.display = "none")}
                />
                <label style={{ paddingLeft: '2px' }}>
                  {res.agency_short ?? res.agency_abbrv}
                </label>
              </div>
            )}
            <div className='d-flex' style={{ marginLeft: 5 }}>
              <TagDuoTone className={classes.cardSurveyTypeImage} />
              <span className={classes.cardSurveyTypeText}>
                {res.survey_type_name}
              </span>
            </div>
          </div>
          <div
            onClick={(e) => handleFavorite(e, res)}
            className='d-flex-center'
          >
            {res.is_like === true ? (
              <img
                height={24}
                width={24}
                src={StaticImage(
                  '/images/icon/header/heart-circle-duotone-2-active.svg'
                )}
              />
            ) : (
              <img
                height={24}
                width={24}
                src={StaticImage(
                  '/images/icon/header/heart-circle-duotone-2.svg'
                )}
              />
            )}
          </div>
        </div>
        <div className='d-flex flex-column flex-1' style={{ marginTop: 10 }}>
          <label className={'project-name'}>
            {res.short_project_name ?? res.project_name}
          </label>
          <label className={'survey-name'}>
            {res.short_survey_name ?? res.survey_name}
          </label>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className='d-flex'>
              <div
                className='d-flex discuss-container'
                style={{ marginRight: 15 }}
              >
                <img
                  src={StaticImage('/images/icon/users-class-light.svg')}
                  style={{ marginRight: 10 }}
                />
                <label style={{ color: '#488345', fontSize: 14 }}>
                  กำลังร่วมเสนอ
                </label>
              </div>
              <div
                className='d-flex discuss-container'
                style={{ marginRight: 15 }}
              >
                <img
                  src={StaticImage(
                    '/images/icon/comment-alt-edit-duotone-4.svg'
                  )}
                  style={{ marginRight: 10 }}
                />
                <label style={{ fontSize: 14, color: '#333' }}>
                  {res.answer_count ?? 0}
                </label>
              </div>
            </div>
            <Space>
              <span
                style={{
                  color: '#494949',
                  fontSize: 12,
                  //   marginRight: 5,
                  fontWeight: 'bold',
                }}
              >
                ครั้งที่
              </span>
              <span
                style={{
                  color: '#0c4da2',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                {res.publish_no
                  ? res.publish_no.toString().padStart('2', '0')
                  : '00'}
              </span>
            </Space>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div style={{ display: 'flex', flex: 1 }}>
            <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
              {ConvertDateShort(res.start_date, 'DD MMM YY')}
            </span>
            <section
              className='progress-list-filter'
              style={{ width: '100%', maxWidth: 400 }}
            >
              <ProgressBar
                bgcolor={res.date_balance > 7 ? '#387436' : '#f96b57'}
                bgContainerColor={res.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'}
                leftTime={checkLeftTime(res)}
                completed={checkComplete(res)}
              />
            </section>
            <span>
              <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                {ConvertDateShort(res.end_date, 'DD MMM YY')}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className='d-flex-center' style={{ padding: 10 }}>
        <div onClick={(e) => handleChild(e, res)} className='cursor-pointer'>
          <MoreHorizOutlined />
        </div>
      </div>
      {isCollapse && (
        <div
          className='flex-column project-container-home-page'
          style={{ paddingLeft: 20, paddingRight: 20, zoom: '90%' }}
        >
          <ProjectCardV2
            classes={classes}
            entitys={entitys}
            history={history}
            law_group={law_group}
            res={res}
            project={res.project}
          />
          {res.surveys.map((childSurvey) => {
            return (
              <SurveyCardListChild
                handleFavorite={handleFavorite}
                history={history}
                classes={classes}
                res={childSurvey}
                entitys={entitys}
                law_group={law_group}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const ProjectCardV2 = ({
  law_group,
  res,
  classes,
  handleFavorite,
  history,
  entitys,
  project,
  renderType = 'child',
}) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const handleChild = (e, item) => {
    e.stopPropagation();
    setIsCollapse(!isCollapse);
  };
  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container-mobile d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 40,
                sm: 40,
                md: 40,
                lg: 40,
                xl: 40,
                xxl: 40,
              }}
              style={{
                height: 40,
                width: 40,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={40} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        className='d-flex-center'
        style={{ padding: 10, justifyContent: 'flex-end' }}
      >
        <div
          onClick={(e) => handleChild(e, res)}
          className='cursor-pointer expand-survey-icon'
        >
          {isCollapse ? (
            <>
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                color='#bababa'
                icon={faPollH}
              />
              <span>{`${res.surveys.length + 1 ?? 0} ย่อ`}</span>
              <FontAwesomeIcon color={'#0c4ca3'} icon={faTimesCircle} />
            </>
          ) : (
            <>
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                color='#bababa'
                icon={faPollH}
              />
              <span>{`${res.surveys.length + 1 ?? 0} ขยาย`}</span>
              <FontAwesomeIcon color={'#0c4ca3'} icon={faArrowCircleDown} />
            </>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          if (res.survey_type_id != 3) {
            history.push(`/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`);
          }
        }}
        className='project-card-mobile d-flex flex-column'
        style={{ marginTop: 0 }}
      >
        <div
          className='d-flex'
          style={{
            paddingBottom: 10,
            borderBottom: '1px solid #e5e6ee',
          }}
        >
          <div className='d-flex' style={{ flex: 1, alignItems: 'center' }}>
            {renderImageLawGroup(res)}
            {res.agency_id > 0 && (
              <div className='agency-container' style={{ maxWidth: 100 }}>
                <img
                  alt="agency_image"
                  src={res.agency_image_uploaded ?? res.agency_image}
                  className="logo"
                  oncontextmenu="return false;"
                  onError={(i) => (i.target.style.display = "none")}
                />
                <label style={{ paddingLeft: '2px' }}>
                  {res.agency_short ?? res.agency_abbrv}
                </label>
              </div>
            )}
            <div className='d-flex' style={{ marginLeft: 5 }}>
              <TagDuoTone className={classes.cardSurveyTypeImage} />
              <span className={classes.cardSurveyTypeText}>
                {res.survey_type_name}
              </span>
            </div>
          </div>
          {renderType == 'main' && (
            <div
              onClick={(e) => handleFavorite(e, res)}
              className='d-flex-center'
            >
              {res.is_like === true ? (
                <img
                  height={24}
                  width={24}
                  src={StaticImage(
                    '/images/icon/header/heart-circle-duotone-2-active.svg'
                  )}
                />
              ) : (
                <img
                  height={24}
                  width={24}
                  src={StaticImage(
                    '/images/icon/header/heart-circle-duotone-2.svg'
                  )}
                />
              )}
            </div>

          )}
        </div>
        <div className='d-flex flex-column flex-1' style={{ marginTop: 10 }}>
          <label className={'project-name'}>
            {res.short_project_name ?? res.project_name}
          </label>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div className='project-timeline-body-bottom d-flex'>
            <div>
              <div className='d-flex' style={{ marginBottom: 10 }}>
                <FontAwesomeIcon
                  style={{ marginRight: 10 }}
                  color={'#488345'}
                  icon={faBalanceScale}
                />
                <span className='entity-text'>
                  {project.entity_name ?? entitys[0]?.activity_abbrv}
                </span>
                <div className='d-flex' style={{ marginLeft: 10 }}>
                  <CalendarOutlined style={{ marginRight: 10 }} />
                  <span>
                    {ConvertDateShort(project.createdate, 'DD MMM YY')}
                  </span>
                </div>
              </div>
              {res.survey_type_id != 3 && (
                <div
                  className='d-flex'
                  style={{ flexWrap: 'wrap', marginTop: -10 }}
                >
                  {entitys.map((item, index) => {
                    const findIndex = entitys.findIndex(
                      (x) =>
                        x.activity_id ==
                        (project.entity_activity_id ?? entitys[0].activity_id)
                    );
                    let className = '';
                    if (
                      item.activity_id ==
                      (project.entity_activity_id ?? entitys[0].activity_id)
                    ) {
                      className = 'active';
                    }
                    if (findIndex > index) {
                      className += 'pass';
                    }
                    return (
                      <div
                        className={`process-step d-flex-center ${className}`}
                      >
                        {item.activity_abbrv}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isCollapse && renderType !== 'child' && (
        <div
          className='flex-column project-container-home-page'
          style={{ paddingLeft: 20, paddingRight: 20, zoom: '90%' }}
        >
          {res.surveys_all.map((childSurvey) => {
            return (
              <SurveyCardListChild
                handleFavorite={handleFavorite}
                history={history}
                classes={classes}
                res={childSurvey}
                entitys={entitys}
                law_group={law_group}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const SurveyCardListChild = ({
  law_group,
  res,
  classes,
  handleFavorite,
  history,
  entitys,
}) => {
  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container-mobile d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 40,
                sm: 40,
                md: 40,
                lg: 40,
                xl: 40,
                xxl: 40,
              }}
              style={{
                height: 40,
                width: 40,
              }}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={40} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        onClick={() => {
          {
            if (res.is_coming === '1') {
            } else {
              history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
            }
          }
        }}
        className='container-survey d-flex flex-column'
      >
        <div
          className='d-flex'
          style={{
            paddingBottom: 10,
            borderBottom: '1px solid #e5e6ee',
          }}
        >
          <div className='d-flex' style={{ flex: 1, alignItems: 'center' }}>
            {renderImageLawGroup(res)}
            {res.agency_id > 0 && (
              <div className='agency-container' style={{ maxWidth: 100 }}>
                <img
                  src={res.agency_image ?? '..'}
                  className='logo'
                  oncontextmenu='return false;'
                  onError={(i) => (i.target.style.display = 'none')}
                />
                <label style={{ paddingLeft: '2px' }}>{res.agency_abbrv}</label>
              </div>
            )}
            <div className='d-flex' style={{ marginLeft: 5 }}>
              <TagDuoTone className={classes.cardSurveyTypeImage} />
              <span className={classes.cardSurveyTypeText}>
                {res.survey_type_name}
              </span>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-1' style={{ marginTop: 10 }}>
          <label className={'project-name'}>
            {res.short_project_name ?? res.project_name}
          </label>
          <label className={'survey-name'}>
            {res.short_survey_name ?? res.survey_name}
          </label>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className='d-flex'>
              <div
                className='d-flex discuss-container'
                style={{ marginRight: 15 }}
              >
                <img
                  src={StaticImage('/images/icon/users-class-light.svg')}
                  style={{ marginRight: 10 }}
                />
                <label style={{ color: '#488345', fontSize: 14 }}>
                  กำลังร่วมเสนอ
                </label>
              </div>
              <div
                className='d-flex discuss-container'
                style={{ marginRight: 15 }}
              >
                <img
                  src={StaticImage(
                    '/images/icon/comment-alt-edit-duotone-4.svg'
                  )}
                  style={{ marginRight: 10 }}
                />
                <label style={{ fontSize: 14, color: '#333' }}>
                  {res.answer_count ?? 0}
                </label>
              </div>
            </div>
            <Space>
              <span
                style={{
                  color: '#494949',
                  fontSize: 12,
                  //   marginRight: 5,
                  fontWeight: 'bold',
                }}
              >
                ครั้งที่
              </span>
              <span
                style={{
                  color: '#0c4da2',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                {res.publish_no
                  ? res.publish_no.toString().padStart('2', '0')
                  : '00'}
              </span>
            </Space>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flex: 1 }}>
            <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
              {ConvertDateShort(res.start_date, 'DD MMM YY')}
            </span>
            <section
              className='progress-list-filter'
              style={{ width: '100%', maxWidth: 400 }}
            >
              <ProgressBar
                bgcolor={res.date_balance > 7 ? '#387436' : '#f96b57'}
                bgContainerColor={res.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'}
                leftTime={checkLeftTime(res)}
                completed={checkComplete(res)}
              />
            </section>
            <span>
              <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                {ConvertDateShort(res.end_date, 'DD MMM YY')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyCard;
