export const SmileIcon = ({ color = 'currentColor', style = {} }) => {
  return (
    <svg
      style={style}
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden='true'
      class='svg-inline--fa fa-comment-alt-smile fa-w-16'
      viewBox='0 0 512 512'
    >
      <g class='fa-group'>
        <path
          fill={color}
          d='M448 0H64A64.06 64.06 0 0 0 0 64v288a64.06 64.06 0 0 0 64 64h96v84a12 12 0 0 0 19.1 9.7L304 416h144a64.06 64.06 0 0 0 64-64V64a64.06 64.06 0 0 0-64-64zM320 133.2a26.8 26.8 0 1 1-26.8 26.8 26.8 26.8 0 0 1 26.8-26.8zm-128 0a26.8 26.8 0 1 1-26.8 26.8 26.8 26.8 0 0 1 26.8-26.8zm164.2 140.9a132.32 132.32 0 0 1-200.4 0 16 16 0 0 1 24.3-20.7 100.23 100.23 0 0 0 151.6-.1 16.07 16.07 0 0 1 24.5 20.8z'
          class='fa-secondary'
          opacity='.4'
        />
        <path
          fill={color}
          d='M320 186.8a26.8 26.8 0 1 0-26.8-26.8 26.8 26.8 0 0 0 26.8 26.8zm-128 0a26.8 26.8 0 1 0-26.8-26.8 26.8 26.8 0 0 0 26.8 26.8zm162.3 64.7a16.11 16.11 0 0 0-22.6 1.8 100.23 100.23 0 0 1-151.6.1 16 16 0 0 0-24.3 20.7 132.32 132.32 0 0 0 200.4 0 16 16 0 0 0-1.9-22.6z'
          class='fa-primary'
        />
      </g>
    </svg>
  );
};

export default SmileIcon;
