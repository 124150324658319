import { Container, Grid } from '@material-ui/core';
import './index.scss';
import { Form, Card, Input, Modal, Space, Button, Checkbox } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useEffect, useState, useRef } from 'react';
import { StaticImage } from '../../utils/image';
import { useHistory } from 'react-router-dom';
import ApiUser from '../../api/user';
import Swal from 'sweetalert2';
import AlertError from '../../utils/AlertError';
import { setLocalStorage } from '../../utils/localStorage';
import ReactCodeInput from 'react-code-input';
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import PhoneDuoTone from '../iconComponent/PhoneDuoTone';
import ButtonComponent from '../../utils/ButtonComponent';
import ApiContent from '../../api/content';
import { useInterval } from '../../utils';
import OtpInput from 'react-otp-input';

const RegisterIndex = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalConsent, setisModalConsent] = useState(false);
  const [modalConsentType, setmodalConsentType] = useState('');
  const formRegister = useRef(null);
  const formOtp = useRef(null);
  const [otp, setotp] = useState('');
  const history = useHistory();
  const [isConsent, setisConsent] = useState(false);
  const [registerData, setregisterData] = useState({});
  const [content1, setcontent1] = useState({});
  const [content2, setcontent2] = useState({});
  const [timeOTP, settimeOTP] = useState(0);
  let timerID = "";
  const onFinish = async (values) => {
    Swal.showLoading();
    try {
      const verify = await ApiUser.verifyEmail(values);
      if (verify.status == 200) {
        settimeOTP(29)
        setregisterData(values);
        showModal();
      }
      Swal.close();
    } catch (error) {
      Swal.close();
      const { data } = error.response;
      AlertError('', data?.message);
    }
  };

  useInterval(() => {
    settimeOTP(timeOTP - 1)
  }, timeOTP == 0 ? null : 1000);

  const otpAgain = async () => {
    if (timeOTP > 0) {
      return false;
    }
    Swal.showLoading();
    clear();
    setTimeout(async () => {
      try {
        const verify = await ApiUser.verifyEmail(registerData);
        settimeOTP(29);
        Swal.close();
      } catch (error) {
        Swal.close();
        const { data } = error.response;
        AlertError('', data?.message);
      }
    }, 1000);
  };

  const clear = () => {
    setotp('')
    // if (formOtp.current) {
    //   if (formOtp.current.textInput[0]) {
    //     formOtp.current.textInput[0].focus()
    //     formOtp.current.state.input[0] = ""
    //     formOtp.current.state.input[1] = ""
    //     formOtp.current.state.input[2] = ""
    //     formOtp.current.state.input[3] = ""
    //     formOtp.current.state.input[4] = ""
    //     formOtp.current.state.input[5] = ""
    //   }
    // }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    //  getContent();

    return () => { };
  }, []);

  const getContent = async () => {
    try {
      Swal.showLoading();
      const termcondition = await ApiContent.get({ type: 'termcondition' });
      const privacypolicy = await ApiContent.get({ type: 'privacynotice' });
      if (termcondition.status == 200) {
        Swal.close();
        const { data } = termcondition.data;
        setcontent1(data);
      }
      if (privacypolicy.status == 200) {
        Swal.close();
        const { data } = privacypolicy.data;
        setcontent2(data);
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const sendMail = async () => {
    // history.push('/Login');
    formRegister.current.submit();
  };

  const setModalConsent = async (type) => {
    setmodalConsentType(type);
    setisModalConsent(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    clear();
    setIsModalVisible(false);
    setisModalConsent(false);
  };

  const register = async () => {
    Swal.showLoading();
    registerData.otp = otp;
    try {
      const registerResult = await ApiUser.register(registerData);
      if (registerResult.status === 200) {
        history.push('RegisterSuccess');
      }
      Swal.close();
    } catch (error) {
      Swal.close();
      const { data } = error.response;
      AlertError(
        '',
        data?.message === 'ข้อมูลที่ได้รับมาไม่ครบ'
          ? 'รหัสไม่ถูกต้อง กรุณาตรวจสอบอีเมลอีกครั้ง หากมีปัญหาให้ติดต่อ Contact Center'
          : `${data?.message} หากมีปัญหาให้ติดต่อ Contact Center`
      );
    }
  };

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 17,
    },
  };
  const format = '`{|}~]{8,32}$';

  function onChange(e) {
    setisConsent(e.target.checked);
  }

  return (
    <div>
      <BrowserView>
        <div className="login-card-container"  >
          <div className="register-side-layer-component-1">
            <div className='register-side-layer-component-2'>
              <Card>
                <div className='register-logo-layer-component'>
                  <div className='d-flex-center flex-column'>
                    <img
                      height={150}
                      width={120}
                      src={StaticImage(
                        '/images/login/userprofile/digital-id-resize.svg'
                      )}
                    />
                    <label style={{ fontSize: 24, fontWeight: 'bold' }}>
                      ลงทะเบียนผู้ใช้ใหม่
                    </label>
                  </div>
                </div>
                <Form
                  style={{ marginTop: 20 }}
                  {...layout}
                  ref={formRegister}
                  name='basic'
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label={<label>ชื่อบัญชีผู้ใช้ (Username) </label>}
                    name='username'
                    rules={[
                      // {
                      //   required: true,
                      //   message: 'กรุณาระบุบัญชีผู้ใช้',
                      // },
                      {
                        required: true,
                        // pattern: /^[A-Za-z0-9]{4,50}/gm,
                        pattern: /^[A-Za-z0-9_.]{4,50}$/,
                        message: 'ต้องเป็นตัวอักษรภาษาอังกฤษ ขนาด 4-50 ตัวอักษร',
                      },
                    ]}
                  >
                    <Input
                      size={'large'}
                      placeholder='ระบุบัญชีผู้ใช้'
                      prefix={
                        <img src={StaticImage('/images/user-duotone-1.png')} />
                      }
                      className='input-theme'
                      required={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label={<label>รหัสผ่าน (Password) </label>}
                    name='password'
                    rules={[
                      {
                        required: true,
                        pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,32}$/gm,
                        message:
                          'รหัสผ่านต้องมี A-Z พิมพ์ใหญ่ พิมพ์เล็ก และ อักขระพิเศษ ไม่น้อยกว่า 8 อักษร',
                      },
                    ]}
                  >
                    <Input.Password
                      type='password'
                      size={'large'}
                      placeholder='Password ต้องมีอย่างน้อย 8 ตัวอักษร'
                      prefix={
                        <img src={StaticImage('/images/key-duotone.png')} />
                      }
                      className='input-theme'
                      required={true}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    // style={{ display: 'flex', alignItems: 'center' }}
                    label={<label>อีเมล (Email) </label>}
                    name='Email'
                    rules={[
                      {
                        required: true,
                        pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/gm,
                        message: 'รูปแบบของอีเมลไม่ถูกต้อง',
                      },
                    ]}
                  >
                    <div className='d-flex flex-column'>
                      <div
                        className='d-flex'
                        style={{ marginBottom: 10, alignItems: 'center' }}
                      >
                        <img
                          style={{ marginRight: 10 }}
                          width={24}
                          height={24}
                          src={StaticImage('/images/info-circle-solid.png')}
                        />
                        <label style={{ color: '#6d7481' }}>
                          อีเมลจะถูกใช้เมื่อลืมรหัสผ่าน เปลี่ยนรหัสผ่าน
                          และรับการแจ้งเตือน
                        </label>
                      </div>
                      <Input
                        type='email'
                        size={'large'}
                        placeholder='อีเมล (Email)'
                        prefix={
                          <img
                            src={StaticImage(
                              '/images/envelope-open-text-duotone-4.png'
                            )}
                          />
                        }
                        className='input-theme'
                        required={true}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/gm,
                        message: 'รูปแบบของเบอร์โทรศัพท์ไม่ถูกต้อง',
                      },
                    ]}
                    label={<label>เบอร์มือถือ</label>}
                    name='mobile'
                  >
                    <div>
                      <Input
                        type='mobile'
                        size={'large'}
                        placeholder='เบอร์มือถือ (ไม่บังคับ)'
                        prefix={<PhoneDuoTone />}
                        className='input-theme'
                      />
                    </div>
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label={<label></label>}
                    name='checkbox'
                  >
                    <div className='d-flex'>
                      <Checkbox onChange={onChange}></Checkbox>
                      <span style={{ marginLeft: 10 }}>
                        ได้อ่านและยอมรับ{' '}
                        <span
                          onClick={() => {
                            //setModalConsent('website');
                            window.open('/WebView?type=termcondition', '_blank');
                          }}
                          className='text-link'
                        >
                          นโยบายเว็บไซต์
                        </span>{' '}
                        และ{' '}
                        <span
                          onClick={() => {
                            // setModalConsent('privacy');
                            window.open('/WebView?type=privacynotice', '_blank');
                          }}
                          className='text-link'
                        >
                          คำประกาศเกี่ยวกับความเป็นส่วนตัว
                        </span>
                      </span>
                    </div>
                  </Form.Item>

                  {/* <div className='register-info-component'>
                      <label style={{ paddingLeft: '1pc', color: '#6d7481' }}>
                        หรือคุณอาจจะลืม ลอง รีเซ็ตพาสเวิร์ด
                      </label>
                    </div>
                    <div className='register-info-component'>
                      <label style={{ paddingLeft: '1pc', color: '#6d7481' }}>
                        หากไม่แน่ใจกรุณาเปลี่ยนหรือติดต่อ DGA Contact Center : 02
                        612 600
                      </label>
                    </div> */}
                </Form>
              </Card>
              <Card>
                <div
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                  <div className='Group-8'>
                    <center>
                      <p style={{ alignItems: 'center' }}>ตอบ</p>

                      <img
                        src={StaticImage(
                          '/images/register_icon/comment-alt-dots-duotone-1.png'
                        )}
                      />
                      <p>แบบสอบถามพิเศษ</p>
                    </center>
                  </div>
                  <div className='Group-8'>
                    <center>
                      <p>แก้ไข/แชร์</p>
                      <img
                        src={StaticImage(
                          '/images/register_icon/comment-alt-edit-duotone-5.png'
                        )}
                      />{' '}
                      <p>คำตอบหรือ สิ่งที่ทำไปแล้ว</p>
                    </center>
                  </div>
                  <div className='Group-8'>
                    <center>
                      <p>จัดการ</p>
                      <img
                        src={StaticImage(
                          '/images/register_icon/history-duotone.png'
                        )}
                      />
                      <p>ประวัติและ ข้อมูลการใช้งาน</p>
                    </center>
                  </div>
                  <div className='Group-8'>
                    <center>
                      <p>ติดตาม</p>
                      <div className='d-flex-center flex-column'>
                        <img
                          src={StaticImage(
                            '/images/register_icon/heart-circle-duotone-3.png'
                          )}
                        />
                        <span
                          style={{
                            whiteSpace: 'pre-line',
                            textAlign: 'center',
                          }}
                        >{`ความเคลื่อนไหวของ \n กระบวนทางกฎหมาย`}</span>
                      </div>
                    </center>
                  </div>
                </div>
              </Card>

              <Card>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingTop: '1pc',
                        alignItems: 'center',
                      }}
                    >
                      <p className='register-login-link01'>มีบัญชีแล้ว ?</p>
                      <p
                        style={{ paddingLeft: '1pc' }}
                        className='register-login-link'
                        onClick={() => {
                          history.push('/Login');
                        }}
                      >
                        เข้าสู่ระบบ
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <ButtonComponent
                        disabled={isConsent ? false : true}
                        onClick={sendMail}
                        className='button-theme button-theme-blue-45'
                        text='ลงทะเบียน'
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className='section-banner'
          style={{ padding: 20, paddingTop: 0, paddingBottom: 100 }}
        >
          <Space size={'middle'} direction='vertical' style={{ width: '100%' }}>
            <div className='d-flex-center flex-column'>
              <img
                height={116}
                width={88}
                src={StaticImage(
                  '/images/login/userprofile/digital-id-resize.svg'
                )}
              />
            </div>
            <div className='d-flex-center'>
              <label style={{ fontSize: 20, fontWeight: 'bold' }}>
                ลงทะเบียนผู้ใช้ใหม่
              </label>
            </div>

            <Form
              name='basic'
              initialValues={{
                remember: true,
              }}
              ref={formRegister}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name='username'
                rules={[
                  {
                    required: true,
                    //pattern: /^[A-Za-z0-9.@]{4,50}/gm,
                    pattern: /^[A-Za-z0-9_.]{4,50}$/,
                    message: 'ต้องเป็นตัวอักษรภาษาอังกฤษ ขนาด 4-50 ตัวอักษร',
                  },
                ]}
              >
                <Input
                  size={'large'}
                  placeholder='ระบุบัญชีผู้ใช้'
                  prefix={
                    <img src={StaticImage('/images/user-duotone-1.png')} />
                  }
                  style={{ borderRadius: '10px' }}
                  required={true}
                />
              </Form.Item>

              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]{8,32}$/gm,
                    message:
                      'รหัสผ่านต้องประกอบไปด้วย ตัวอักษรภาษาอังกฤษ ตัวพิมพ์ใหญ่ ตัวพิมพ์และ และอักขระพิเศษ ขนาด 8-32 ตัวอักษร',
                  },
                ]}
              >
                <Input
                  type='password'
                  size={'large'}
                  placeholder='Password ต้องมีอย่างน้อย 8 ตัวอักษร'
                  prefix={<img src={StaticImage('/images/key-duotone.png')} />}
                  style={{ borderRadius: '10px' }}
                  required={true}
                />
              </Form.Item>

              <Form.Item
                name='Email'
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/gm,
                    message: 'รูปแบบของอีเมลไม่ถูกต้อง',
                  },
                ]}
              >
                <Input
                  type='email'
                  size={'large'}
                  placeholder='อีเมล (Email)'
                  prefix={
                    <img
                      src={StaticImage(
                        '/images/envelope-open-text-duotone-4.png'
                      )}
                    />
                  }
                  style={{ borderRadius: '10px' }}
                  required={true}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    pattern: /^[0-9]{10}$/gm,
                    message: 'รูปแบบของเบอร์โทรศัพท์ไม่ถูกต้อง',
                  },
                ]}
                name='mobile'
              >
                <Input
                  type='mobile'
                  size={'large'}
                  placeholder='เบอร์มือถือ (ไม่บังคับ)'
                  style={{ borderRadius: '10px' }}
                />
              </Form.Item>
              <Form.Item name='checkbox'>
                <div className='d-flex'>
                  <Checkbox onChange={onChange}></Checkbox>
                  <span style={{ marginLeft: 10 }}>
                    ได้อ่านและยอมรับ{' '}
                    <span
                      onClick={() => {
                        setModalConsent('website');
                      }}
                      className='text-link'
                    >
                      นโยบายเว็บไซต์
                    </span>{' '}
                    และ{' '}
                    <span
                      onClick={() => {
                        setModalConsent('privacy');
                      }}
                      className='text-link'
                    >
                      นโยบายการคุ้มครองข้อมูลส่วนบุคคล
                    </span>
                  </span>
                </div>
              </Form.Item>
              <div
                className='mobile-icon-register'
                style={{ display: 'flex', justifyContent: 'space-evenly' }}
              >
                <div>
                  <center>
                    <p style={{ alignItems: 'center' }}>ตอบ</p>

                    <img
                      src={StaticImage(
                        '/images/register_icon/comment-alt-dots-duotone-1.png'
                      )}
                    />
                  </center>
                </div>
                <div>
                  <center>
                    <p>แก้ไข/แชร์</p>
                    <img
                      src={StaticImage(
                        '/images/register_icon/comment-alt-edit-duotone-5.png'
                      )}
                    />{' '}
                  </center>
                </div>
                <div>
                  <center>
                    <p>จัดการ</p>
                    <img
                      src={StaticImage(
                        '/images/register_icon/history-duotone.png'
                      )}
                    />
                  </center>
                </div>
                <div>
                  <center>
                    <p>ติดตาม</p>
                    <img
                      src={StaticImage(
                        '/images/register_icon/heart-circle-duotone-3.png'
                      )}
                    />
                  </center>
                </div>
              </div>

              <Form.Item style={{ width: '100%', marginTop: 30 }}>
                <ButtonComponent
                  buttonStyle={{ width: '100%' }}
                  disabled={isConsent ? false : true}
                  onClick={sendMail}
                  className='button-theme button-theme-blue-45'
                  text='ลงทะเบียน'
                />
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p style={{ fontSize: 14 }} className='register-login-link01'>
                  มีบัญชีแล้ว ?
                </p>
                <p
                  style={{ paddingLeft: '1pc', fontSize: 14 }}
                  className='register-login-link'
                  onClick={() => {
                    history.push('/Login');
                  }}
                >
                  เข้าสู่ระบบ
                </p>
              </div>
            </Form>
          </Space>
        </div>
        <div
          className='home-button-bottom'
          style={{ position: 'fixed', zIndex: 2, backgroundColor: '#fff' }}
        >
          <img
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            src={StaticImage('/images/icon/home-lg-alt-duotone.png')}
          />
          <span
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            style={{ color: '#8e99ad', fontSize: 11, marginTop: 10 }}
          >
            กลับหน้าหลัก
          </span>
        </div>
      </MobileView>
      <Modal
        // title='ยืนยันตัวบุคคลกับกรมการปกครอง'
        visible={isModalVisible}
        // onOk={handleOk}
        // onCancel={handleCancel}
        onCancel={handleCancel}
        footer={null}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      // width={500}
      >
        <p
          className='title'
          style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}
        >
          กรุณากรอกรหัสที่ได้รับจาก Email
        </p>
        <div
          className='d-flex'
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <OtpInput
            ref={formOtp}
            id="otpinput"
            className="mr-1 sm:mr-4"
            inputStyle={{
              width: 50, height: 60, border: '1px solid #ababab', marginRigth: 10, color: 'black',
              borderRadius: 10,
              fontSize: 20
            }}
            shouldAutoFocus={true}
            isInputNum
            value={otp}
            onChange={(text) => setotp(text)}
            numInputs={6}
          // separator={<span>-</span>}
          />
          {/* <ReactCodeInput
            inputStyle={{
              fontFamily: 'Kanit',
              borderColor: '#cfcfcf',
              MozAppearance: 'textfield',
              borderRadius: '6px',
              border: '1px solid',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
              margin: '4px',
              padding: 10,
              paddingRight: 0,
              paddingLeft: 0,
              maxWidth: isMobile ? 30 : 45,
              maxHeight: isMobile ? 40 : 50,
              textAlign: 'center',
              // width: '36px',
              // minHeight: '32px',
              fontSize: isMobile ? 18 : 24,
              boxSizing: 'border-box',
            }}
            ref={formOtp}
            type='text'
            fields={6}
          /> */}
          <ButtonComponent
            text='ยืนยัน'
            onClick={register}
            className={'button-theme-blue-45'}
            buttonStyle={{ marginTop: 10, minWidth: 200 }}
          />
          <p
            className='cursor-pointer'
            style={{
              marginTop: 10,
              textDecoration: 'underline',
              color: '#697288',
              opacity: timeOTP == 0 ? 1 : 0.2
            }}
            onClick={otpAgain}
          >
            {`${timeOTP == 0 ? `ขอรหัส OTP อีกครั้ง` : `ขอรหัส OTP อีกครั้ง (${timeOTP})`}`}
          </p>
        </div>
      </Modal>
      <Modal
        title={
          modalConsentType == 'website'
            ? 'นโยบายเว็บไซต์'
            : 'คำประกาศเกี่ยวกับความเป็นส่วนตัว'
        }
        cancelText={'ตกลง'}
        okButtonProps={{ hidden: true }}
        visible={isModalConsent}
        // onOk={handleOk}
        // onCancel={handleCancel}
        onCancel={handleCancel}
      >
        {modalConsentType == 'website' ? (
          <span
            className='contact-description ql-editor'
            dangerouslySetInnerHTML={{ __html: content1?.content }}
          ></span>
        ) : (
          <span
            className='contact-description ql-editor'
            dangerouslySetInnerHTML={{ __html: content2?.content }}
          ></span>
        )}
      </Modal>
    </div>
  );
};

export default RegisterIndex;
