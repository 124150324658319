import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './index.scss';
import ApiSurvey from '../../../api/survey';
import {
    Collapse,
} from 'antd';
const { Panel } = Collapse;

const Tab2 = ({ survey, survey_id }) => {
    useEffect(() => {
        getSurvey();
        return () => {
        }
    }, [])
    const [content_files, setcontent_files] = useState([]);
    const [surveyResponse, setsurveyResponse] = useState([]);
    const [activeKey, setActiveKey] = useState([]);
    const getSurvey = async () => {
        try {
            Swal.showLoading();
            const survey = await ApiSurvey.getDraftLaw({ survey_id });

            if (survey.status == 200) {
                Swal.close();
                const { data, content_files } = survey.data;
                setcontent_files(content_files);
                const groups = _.groupBy(data, 'id');
                if (data.length === 0) {
                    Swal.fire(
                        'เกิดข้อผิดพลาด',
                        'ไม่พบข้อมูลร่างกฎหมายนี้',
                        'warning'
                    ).then((result) => {
                    });
                    return false;
                }

                const itemSurvey = [];
                Object.keys(groups).map((key, index) => {
                    const itemGroup = groups[key];
                    console.log(itemGroup);
                    itemSurvey.push({
                        name: itemGroup[0].name,
                        description: itemGroup[0].description,
                        details: itemGroup,
                        ...itemGroup[0],
                    });
                });
                setsurveyResponse(itemSurvey);
            }
        } catch (error) {
            Swal.close();
        }
    };

    const callback = (key) => {
        setActiveKey(key);
    };

    const renderCollapse = () => {
        return (
            <Collapse
                style={{ marginTop: 20 }}
                defaultActiveKey={[]}
                activeKey={activeKey}
                onChange={callback}
            >
                {renderCollapseItem()}
            </Collapse>
        );
    };

    const renderCollapseItem = () => {
        let components = [];
        surveyResponse.map((item, index) => {
            components.push(
                <Panel header={item.name} key={index}>
                    <p
                        className='ql-editor'
                        dangerouslySetInnerHTML={{ __html: item.description }}
                    ></p>
                    {item.details.filter((x) => x.detail_name).length > 0 &&
                        renderContentItem(item.details)}
                </Panel>
            );
        });
        return components;
    };

    const renderContentItem = (items) => {
        let components = [];
        items.map((item, index) => {
            components.push(
                <div>
                    <label style={{ fontSize: 16 }}>{item.detail_name}</label>
                    <p
                        style={{ marginLeft: 10, fontSize: 14 }}
                        className='ql-editor'
                        dangerouslySetInnerHTML={{ __html: item.detail_description }}
                    ></p>
                </div>
            );
        });
        return components;
    };

    return <div className="tab2-container" >
        <h1 className="header">ร่างกฎหมาย</h1>
        {renderCollapse()}
        {content_files && content_files.length > 0 && (
            <div className="file-container" style={{ marginTop: 15 }}>
                {content_files.map((item, index) => {
                    if (item.type !== 'draftlaw') {
                        return <></>;
                    }
                    return (
                        <div className='d-flex'>
                            <label
                                style={{ fontSize: 14 }}
                                className='text-link cursor-pointer'
                                onClick={() =>
                                    window.open(`${item.file_path}`, '_blank')
                                }
                            >
                                {item.file_name}
                            </label>
                        </div>
                    );
                })}
            </div>
        )}
    </div >
}
export default Tab2;
