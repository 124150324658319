import React, { useState, useEffect } from 'react'
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import './index.scss';
import Swal from 'sweetalert2';
import ApiContent from '../../api/content';
import { Button } from 'antd'
import { DownloadOutlined, LockOutlined } from '@ant-design/icons';
import { WEB_SITE } from '../../env';
import { StaticImage } from '../../utils/image';
const FAQDOC = require('../../assets/file.pdf')

const Rule = (props) => {
  const history = useHistory();
  const [survey, setsurvey] = useState({});
  const [title, settitle] = useState('')

  const type = new URLSearchParams(props.location.search).get('type');
  useEffect(() => {
    if (type == 'faq') {
      document.title = 'คำถามที่พบบ่อย (FAQ)';
      settitle('คำถามที่พบบ่อย (FAQ)')
    }
    if (type == 'doc') {
      document.title = 'คู่มือการใช้งาน';
      settitle('คู่มือการใช้งาน')
    }
    getContent();

    return () => { };
  }, [type]);

  useEffect(() => {
    if (document.getElementById("tailwind-js")) {

    } else {
      const script = document.createElement("script");
      script.src = `${StaticImage("/tailwind.js")}`;
      script.id = "tailwind-js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const getContent = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiContent.get({ type });
      if (survey.status == 200) {
        Swal.close();
        const { data } = survey.data;
        setsurvey(data);
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const downloadFAQ = () => {
    if (type == "faq") {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      //link.setAttribute('href', `${WEB_SITE}/pdf/ระบบกลาง_[FAQ] สำหรับประชาชน_06.pdf`);
      console.log(survey.file_url);
      link.setAttribute('href', `${survey.file_url}`)
      link.setAttribute('download', `ระบบกลาง_[FAQ] สำหรับประชาชน_06.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      // link.setAttribute('href', `${WEB_SITE}/pdf/doc.pdf`);
      link.setAttribute('href', `${survey.file_url}`)
      link.setAttribute('download', `ระบบกลาง_[คู่มือการใช้งาน] สำหรับประชาชน.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
  return (
    <div id='webview-page' className='!pb-20 !sm:pb-5'>
      <div className='section-banner'></div>
      <div
        className='d-flex-center flex-column w-100'
        style={{
          position: 'absolute',
          marginTop: -50,
          top: 0,
          height: 300,
          backgroundColor: '#0c4da2',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: -1,
        }}
      ></div>
      <div
        className='d-flex-center flex-column'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container>
          <div
            className='d-flex menu-top'
            style={{ justifyContent: 'space-between' }}
          >
            <div
              onClick={() => {
                history.go(-1);
              }}
              className='cursor-pointer'
            >
              <span>{'ย้อนกลับ'}</span>
            </div>
          </div>
          <div className='rule-container'>
            <div className='d-flex'>
              {/* <label className='title'>กฎ กติกา และมารยาท</label> */}
            </div>
            <div className='d-flex rule-content flex-column'>

              {/* <label className='title-content'>
                                การร่วมกิจกรรมแสดงความเห็นในกระทู้
                                ผู้เข้าร่วมพึงต้องปฏิบัติดังต่อไปนี้
                            </label> */}
              {/* <div className='d-flex flex-column'>
                <label className="title">{title}</label>
                <Button
                  onClick={downloadFAQ}
                  style={{ fontSize: 16 }}
                  className="d-flex-center mt-3"
                  style={{ background: '#275394', display: 'flex', color: '#fff', padding: 20, borderRadius: 10, marginLeft: 20 }}
                  icon={<DownloadOutlined />}>
                  ดาวน์โหลดเอกสาร
                </Button>
              </div> */}
              <div className='d-flex flex-column'>
                <label className="title">{title}</label>
                <span
                  className='contact-description ql-editor'
                  dangerouslySetInnerHTML={{ __html: survey?.content }}
                ></span>
                <Button
                  onClick={downloadFAQ}
                  className="d-flex-center mt-3"
                  style={{ background: '#275394', fontSize: 16, display: 'flex', color: '#fff', padding: 20, borderRadius: 10, marginLeft: 20 }}
                  icon={<DownloadOutlined />}>
                  ดาวน์โหลดเอกสาร
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Rule;
