import axios from 'axios';
import { Component } from 'react';

class ApiMaster extends Component {
  static getEntity = async () => {
    const result = await axios({
      url: `master/entity`,
      method: 'get',
    });
    return result;
  };

  static lawGroup = async () => {
    const result = await axios({
      url: `master/law_group`,
      method: 'get',
    });
    return result;
  };

  static masterLogin = async () => {
    const result = await axios({
      url: `master/masterLogin`,
      method: 'get',
    });
    return result;
  };

  static homeFilter = async () => {
    const result = await axios({
      url: `master/homeFilter`,
      method: 'get',
    });
    return result;
  };

  static banner = async () => {
    const result = await axios({
      url: `master/banner`,
      method: 'get',
    });
    return result;
  };

  static law_list = async () => {
    const result = await axios({
      url: `master/law_list`,
      method: 'get',
    });
    return result;
  };
}

export default ApiMaster;
