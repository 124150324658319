import React, { useState, useEffect, useLayoutEffect } from 'react';
import IndexCarousel from '../main/IndexCarousel';
import '../main/submenulist.scss';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../utils/image';
// import YoutuberPlayer from "./react-player/YoutuberPlayer";
import { fade, makeStyles } from '@material-ui/core';
// import SurveyCard from './newLayout/index';
import SurveyCardMobile from '../listening/newLayoutMobile';
import { checkUser } from '../../utils/checkUser';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import ApiMaster from '../../api/master';
import { useWindowSize } from '../../utils/WindowSize';
import SurveyCardV2 from './desktop/survey';
import ProjectCardV2 from './desktop/project';
import SurveyCardV2Mobile from './mobile/survey';
import ProjectCardV2Mobile from './mobile/project';
import './index.scss';
 

const useStylesAvatar = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const scoreText = [
  { value: "last", label: "#ล่าสุด", color: "#0c4da2" },
  { value: "answer", label: "#ตอบมากที่สุด", color: "#a51830" },
  // { value: 'read', label: '#ความนิยม', color: '#387436' },
  { value: "progress", label: "#ความคืบหน้า", color: "#387436" },
  // { value: 'project', label: '#ประเมินผลสัมฤทธิ์', color: '#387436' },
];

const ListeningPageindex = () => {
  const [width, height] = useWindowSize();
  const [surveys, setsurveys] = useState([]);
  const [surveyFilter, setSurveyFilter] = useState([]);
  const [card_type, setcard_type] = useState('last');
  const history = useHistory();
  const classes = useStyles();
  const classAvatar = useStylesAvatar();
  const userState = useSelector((state) => state.user);
  const [projects, setprojects] = useState([]);
  const [entitys, setEntitys] = useState([]);
  const [process, setprocess] = useState([]);
  const [law_group, setlawgroup] = useState([]);
  const [agencyOption, setAgencyOption] = useState([
    { value: null, label: "ทั้งหมด" },
  ]);
  const [ministryOption, setMinistryOption] = useState([
    { value: null, label: "ทั้งหมด" },
  ]);
  const [surveyTypeOption, setSurveyTypeOption] = useState([
    { value: null, label: "ทั้งหมด" },
  ]);
  useEffect(() => {
    Swal.showLoading();
    const getSurveyResult = getSurvey();
    // const getProjectSurveyResult = getProjectSurvey();
    const getHomeFilter = getFilterOption();
    try {
      Promise.all([getSurveyResult, getHomeFilter])
        .then(data => { Swal.close() })
    } catch (error) {
      Swal.close();
    }
    return () => { };
  }, []);

  useEffect(() => {
    getOrderSurvey({ orderFilter: card_type })
    return () => {
    }
  }, [card_type])



  const getSurvey = async () => {
    // Swal.showLoading();
    try {
      const survey = await ApiSurvey.favoriteHistoryProject();
      if (survey.status == 200) {
        // Swal.close();
        const { data, law_group, entitys } = survey.data;
        setsurveys(data);
        setSurveyFilter(data);
        setlawgroup(law_group);
        setEntitys(entitys);
      }
    } catch (error) {
      console.log(error);
    }

  };

  const getProjectSurvey = async () => {
    // Swal.showLoading();
    const survey = await ApiSurvey.surveyGroupByProject();
    if (survey.status == 200) {
      // Swal.close();
      const { data, entitys } = survey.data;
      console.log("entitys::", entitys);
      setprojects(data);
      setEntitys(entitys);
      // setsurveys(data);
      // setSurveyFilter(data);
    }
  };

  const getFilterSurvey = async ({
    ministry,
    agency,
    surveyType,
    statusSurvey,
    searchFilter,
  }) => {
    let survey = [...surveys];
    if (ministry > 0) {
      survey = survey.filter((x) => x.ministry_id == ministry);
    }

    if (agency > 0) {
      survey = survey.filter((x) => x.agency_id == agency);
    }
    if (surveyType > 0) {
      if (surveyType == 2) {
        survey = survey.filter(
          (x) => x.survey_type_id == 1 || x.survey_type_id == 2
        );
      } else {
        survey = survey.filter((x) => x.survey_type_id == surveyType);
      }
    }
    if (statusSurvey == 'O') {
      survey = survey.filter(
        (x) => (x.isconclude == 'O' || !x.isconclude) && x.date_balance >= 0
      );
    } else if (statusSurvey == 'I') {
      survey = survey.filter(
        (x) =>
          x.date_balance < 0 ||
          x.isconclude == 'D' ||
          x.isconclude == 'C' ||
          x.isconclude == 'B' ||
          x.isconclude == 'P'
      );
    }
    if (searchFilter) {
      if (card_type == "progress") {
        survey = survey.filter(item => {
          return (
            item.project.project_name.includes(searchFilter)
          )
        });
      } else {
        survey = survey.filter(item => {
          return (
            item.short_survey_name.includes(searchFilter) || item.survey_name.includes(searchFilter) ||
            item.short_project_name.includes(searchFilter) || item.project_name.includes(searchFilter)
          )
        });
      }

    }

    setSurveyFilter(survey);
  };

  const getOrderSurvey = async ({ orderFilter }) => {
    let survey = [...surveys];

    if (orderFilter == 'last') {
      survey = _.orderBy(surveyFilter, 'created_date', 'desc');
    } else if (orderFilter == 'answer') {
      survey = _.orderBy(
        surveyFilter,
        [
          (o) => {
            return o.answer_count || '';
          },
        ],
        ['desc']
      );
    } else if (orderFilter == 'read') {
      survey = _.orderBy(
        surveyFilter,
        [
          (o) => {
            return o.view_count || '';
          },
        ],
        ['desc']
      );
    } else if (orderFilter == 'popular') {
      survey = _.orderBy(
        surveyFilter,
        [
          (o) => {
            return o.view_count || '';
          },
        ],
        ['desc']
      );
    }

    setSurveyFilter(survey);
  };

  const handleFavorite = async (e, item) => {
    e.stopPropagation();
    if (checkUser(userState)) {
      const model = {
        project_id: item.project_id,
      };
      try {
        const result = await ApiSurvey.favoriteProject(model);
        if (result.status == 200) {
          //filter
          const likesFilter = [...surveyFilter];
          const indexFilter = likesFilter.findIndex(
            (x) => parseInt(x.project_id) == item.project_id
          );
          likesFilter[indexFilter].is_like = !likesFilter[indexFilter].is_like;

          setSurveyFilter(likesFilter);
        }
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    }
  };

  const getFilterOption = async () => {
    const filter = await ApiMaster.homeFilter();
    if (filter.status == 200) {
      const { data } = filter.data;

      setAgencyOption([...agencyOption, ...data.agency]);
      setMinistryOption([...ministryOption, ...data.ministry]);
      setSurveyTypeOption([
        ...surveyTypeOption,
        ...data.survey_type.filter((x) => x.label != "หลักการ" && x.label != "ร่างกม.โดยผู้พิจารณา"),
      ]);
    }
  };

  return (
    // <Container maxWidth="xl">
    <div id="listening-v2" className='container-fluid bg-white'>
      <div>
        <section className='section-banner' style={{}}>
          <IndexCarousel history={history} />
        </section>
      </div>
      <div className="d-flex-align-center" style={{
        paddingLeft: '8%',
        paddingRight: '8%',
        marginTop: width >= 768 ? 0 : 50,
        zoom: width >= 768 ? '100%' : '80%'
      }}>
        <span style={{ marginRight: 15, fontSize: 18, whiteSpace: 'nowrap' }} className="text-primary text-regular">มุมมอง</span>
        {scoreText.map((item) => {
          return (
            <div
              style={{
                backgroundColor:
                  card_type == item.value ? '#fff' : "#e5e6ee",
              }}
              className="card-type-filter"
              onClick={() => setcard_type(item.value)}>
              <span className="text-regular" style={{ color: item.color }}>{item.label}</span>
            </div>
          )
        })}
      </div>
      {
        width >= 768 && (
          <section>
            {card_type == "progress" ? <ProjectCardV2
              agencyOption={agencyOption}
              ministryOption={ministryOption}
              surveyTypeOption={surveyTypeOption}
              getFilterSurvey={getFilterSurvey}
              handleFavorite={handleFavorite}
              law_group={law_group}
              surveys={surveyFilter.filter((x) => x.is_like)}
              entitys={entitys} /> :
              <SurveyCardV2
                agencyOption={agencyOption}
                ministryOption={ministryOption}
                surveyTypeOption={surveyTypeOption}
                getFilterSurvey={getFilterSurvey}
                handleFavorite={handleFavorite}
                law_group={law_group}
                surveys={surveyFilter.filter((x) => x.is_like)} Ï
                entitys={entitys} />}
          </section>
        )
      }
      {
        width < 768 && (
          // <section>
          //   <SurveyCardMobile
          //     surveys={surveyFilter}
          //     getFilterSurvey={getFilterSurvey}
          //     getOrderSurvey={getOrderSurvey}
          //     handleFavorite={handleFavorite}
          //     law_group={law_group}
          //     entitys={entitys}
          //   />
          // </section>
          <section>
            {card_type == "progress" ? <ProjectCardV2Mobile
              agencyOption={agencyOption}
              ministryOption={ministryOption}
              surveyTypeOption={surveyTypeOption}
              getFilterSurvey={getFilterSurvey}
              handleFavorite={handleFavorite}
              law_group={law_group}
              surveys={surveyFilter.filter((x) => x.is_like)}
              entitys={entitys} /> :
              <SurveyCardV2Mobile
                agencyOption={agencyOption}
                ministryOption={ministryOption}
                surveyTypeOption={surveyTypeOption}
                getFilterSurvey={getFilterSurvey}
                handleFavorite={handleFavorite}
                law_group={law_group}
                surveys={surveyFilter.filter((x) => x.is_like)}
                entitys={entitys} />}
          </section>

        )
      }
    </div >
  );
};

export default ListeningPageindex;
