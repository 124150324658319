import axios from 'axios';
import { Component } from 'react';

class ApiContent extends Component {
  static get = async ({ type }) => {
    const result = await axios({
      url: `surveys/content/${type}`,
      method: 'get',
    });
    return result;
  };
}

export default ApiContent;
