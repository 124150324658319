import React from 'react';

const CommentAltEdit = ({ className, style = {}, onMouseOver = () => { }, onMouseOut = () => { }, onClick = () => { } }) => {
  return (
    <svg
      style={style}
      onMouseOver={(e) => onMouseOver(e)}
      onMouseOut={(e) => onMouseOut(e)}
      onClick={() => onClick()}
      xmlns='http://www.w3.org/2000/svg'
      ariaHidden='true'
      viewBox='0 0 512 512'
      className={className}
    >
      <path
        fill='currentColor'
        d='M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288zM164.9 243.2l-4.8 42.8c-.6 5.7 4.2 10.6 10 10l42.8-4.8 85.5-85.5-48-48-85.5 85.5zm159.3-133.9c-7-7-18.4-7-25.4 0l-28.3 28.3 48 48 28.3-28.3c7-7 7-18.4 0-25.4l-22.6-22.6z'
      />
    </svg>
  );
};

export default CommentAltEdit;
