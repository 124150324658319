import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import './index.scss';
const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  step: {
    maxWidth: 400,
    flexGrow: 1,
  },
  stepContainer: {
    display: 'inline-flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10,
  },
  stepItemActive: {
    backgroundColor: '#4bb6d7',
    height: 10,
    flex: 1,
    marginRight: 2,
    marginLeft: 2,
    borderRadius: 5,
  },
  stepItem: {
    backgroundColor: '#efefef',
    height: 10,
    flex: 1,
    marginRight: 2,
    marginLeft: 2,
    borderRadius: 5,
  },
}));

const CustomizedSteppers = ({ success = 0, complete = 0, items = [] }) => {
  const classes = useStyles();
  return (
    <section className='stepper1-section'>
      <div style={{ paddingTop: '0.4pc' }}>
        <label className='status-label'>สถานะโครงการ</label>
      </div>
      <div className='step-container'>
        <div className='stepper1-container'>
          {items.map((item, index) => {
            return (
              <div style={{ flex: 1, textAlign: 'center' }}>
                <div
                  // style={{ width: `${80 / complete}%` }}
                  className={
                    success >= index ? classes.stepItemActive : classes.stepItem
                  }
                ></div>
                <label
                  style={{ fontSize: 11, textAlign: 'center' }}
                  className='content-label2'
                >
                  {item.entity_abbrv}
                </label>
              </div>
            );
          })}
          {/* <div
            className='Rounded-Rectangle-9-copy-4'
            style={{ backgroundColor: ' #4bb6d7' }}
          >
            <label className='content-label2'>กขค.</label>
          </div>
          <div className='Rounded-Rectangle-9-copy-4'>
            <label className='content-label2'>คกง.</label>
          </div>
          <div className='Rounded-Rectangle-9-copy-4'>
            <label className='content-label2'>สลค.</label>
          </div>
          <div className='Rounded-Rectangle-9-copy-4'>
            <label className='content-label2'>ครม.</label>
          </div>
          <div className='Rounded-Rectangle-9-copy-4'>
            <label className='content-label2'>สคก.</label>
          </div>
          <div className='Rounded-Rectangle-9-copy-4'>
            <label className='content-label2'>รัฐสภา</label>
          </div>
          <div className='Rounded-Rectangle-9-copy-4'>
            <label className='content-label2'>ประกาศ</label>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default CustomizedSteppers;
