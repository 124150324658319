import { Image } from 'antd';
import { rawHTMLENGDiv } from './text';
import { StaticImage } from '../../../utils/image';
import YoutuberPlayer from '../../react-player/YoutuberPlayer';
const DetailLawPageTab3 = ({ law, files = [] }) => {
    return <div className="tab3">
        <div className="title-container flex flex-column">
            <span className="text-black-300 font-bold">{law.law_name_og}</span>
            {/* <span className="italic">{law.simple_description?.replace(/(<([^>]+)>)/ig, '')}</span> */}
        </div>
        <div className="content-container flex flex-column gap-y-5">
            {law.simple_url_video &&
                <div className='flex justify-center'>
                    <YoutuberPlayer
                        autoplay={false}
                        classNamePlayer={'h-80 w-full sm:w-6/12'}
                        className={''}
                        // styles={{ maxHeight: 800, maxWidth: 800 }}
                        url={law.simple_url_video} />
                </div>
            }
            <div className='flex gap-x-5 justify-center flex-wrap'>
                {/* {lawActive.content_files.filter(x => x.type == "")} */}
                {
                    files.map((item, index) => {
                        return (
                            <Image
                                alt={item.file_name}
                                height={100}
                                src={item.file_path}
                            />
                        )
                    })
                }
            </div>
            <div className='flex description-container w-full sm:w-8/12 justify-center ml-auto mr-auto flex-column'>
                <span className='text-blue-300 text-lg'>คำอธิบาย</span>
                <div dangerouslySetInnerHTML={{ __html: law.simple_description }}></div>
            </div>
        </div>
    </div>
}

export default DetailLawPageTab3;