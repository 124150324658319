import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import ApiSurvey from "../../../api/survey";
import { StaticImage } from '../../../utils/image';
import YoutuberPlayer from "../../react-player/YoutuberPlayer";
import { List, Avatar, Image } from 'antd';
import PersonIcon from '@material-ui/icons/Person';
import './index.scss'
const data = [

    {
        title: '-',
        icon: StaticImage('/images/icon/contact/map-marker-alt-duotone.png'),
    },
    {
        title: '-',
        icon: StaticImage('/images/icon/contact/phone-duotone.png'),
    },
    {
        title: '-',
        icon: StaticImage('/images/icon/contact/fax-duotone.png'),
    },
    {
        title: '-',
        icon: StaticImage('/images/icon/contact/mailbox-duotone.png'),
    },
    {
        title: '-',
        icon: StaticImage('/images/icon/contact/browser-duotone.png'),
    },
];
const Tab4 = ({ survey, survey_id }) => {
    useEffect(() => {
        getContact();
        return () => {
        }
    }, [])
    const [surveyResponse, setsurveyResponse] = useState({})
    const [responsible_data, setresponsible_data] = useState([]);
    const [listItem, setListItem] = useState([
        {
            title: '-',
        },

        {
            title: '-',
            icon: StaticImage('/images/icon/contact/map-marker-alt-duotone.png'),
        },
        {
            title: '-',
            icon: StaticImage('/images/icon/contact/phone-duotone.png'),
        },
        {
            title: '-',
            icon: StaticImage('/images/icon/contact/fax-duotone.png'),
        },
        {
            title: '-',
            icon: StaticImage('/images/icon/contact/mailbox-duotone.png'),
        },
        {
            title: '-',
            icon: StaticImage('/images/icon/contact/browser-duotone.png'),
        },
        {
            title: '-',
        },
    ]);
    const getContact = async () => {
        try {
            Swal.showLoading();
            const surveyResponse = await ApiSurvey.getContact({ survey_id: survey_id });

            if (surveyResponse.status == 200) {
                Swal.close();
                const { data, responsible } = surveyResponse.data;
                if (data) {
                    const newItem = [...listItem];
                    newItem[0].title = <span>{data.title}</span> ?? <span>-</span>;
                    newItem[1].title = <span>{data.location}</span> ?? <span>-</span>;
                    newItem[2].title = (
                        <a className='text-link' href={`tel:${data.phone}`}>
                            {data.phone}
                        </a>
                    ) ?? <span>-</span>;
                    newItem[3].title = <span>{data.fax}</span> ?? <span>-</span>;
                    newItem[4].title = <span>{data.email}</span> ?? <span>-</span>;
                    newItem[4].title = data.email ?? '-';
                    newItem[5].title = (
                        <a className='text-link' target='_blank' href={`${data.website}`}>
                            {data.website}
                        </a>
                    ) ?? <span>-</span>;
                    newItem[6].title = <span>{data.description}</span> ?? <span>-</span>;
                    setListItem(newItem);
                }
                if (responsible) {
                    setresponsible_data(responsible);
                }

                setsurveyResponse(data);
            }
        } catch (error) {
            Swal.close()
            // Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
            //     history.go(-1);
            // });
        }
    };
    return <div className="tab4-container">
        {surveyResponse.banner && (
            <div className='d-flex-center' style={{ marginBottom: 20 }}>
                {/* <img
                    style={{ marginTop: 15, width: 'auto', maxWidth: 1000, objectFit: 'contain' }}
                    src={surveyResponse.banner}
                    oncontextmenu='return false;'
                    onError={(i) => (i.target.style.display = 'none')}
                /> */}
                <Image src={surveyResponse.banner} />
            </div>
        )}
        <h1 className="header">ข้อมูลการติดต่อ</h1>
        <List
            itemLayout='horizontal'
            dataSource={survey ? listItem : data}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta

                        avatar={
                            item.icon ? item.type === 'person' ? item.icon : <img src={item.icon} /> : ""
                        }
                        title={item.title}
                    />
                </List.Item>
            )}
        />
        {responsible_data.length > 0 && (
            <div style={{ marginTop: 10 }}>
                <h1 className='header'>ผู้รับผิดชอบการรับฟัง</h1>
                <List
                    itemLayout='horizontal'
                    dataSource={responsible_data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex'>
                                            <PersonIcon
                                                style={{
                                                    color: '#0c4da2',
                                                    fontSize: 28,
                                                    marginRight: 16,
                                                    minWidth: 30,
                                                }}
                                            />
                                            <span>{`${item.name}`}</span>
                                        </div>
                                        <div className='d-flex mt-3' style={{ marginTop: 15 }}>
                                            <img
                                                style={{
                                                    marginRight: 16,
                                                    minWidth: 30,
                                                    height: 20,
                                                }}
                                                src={StaticImage(
                                                    '/images/icon/contact/mailbox-duotone.png'
                                                )}
                                            />
                                            <span>{`${item.email}`}</span>
                                        </div>
                                        <div className='d-flex mt-3' style={{ marginTop: 15 }}>
                                            <img
                                                style={{
                                                    marginRight: 16,
                                                    minWidth: 30,
                                                    height: 20,
                                                }}
                                                src={StaticImage(
                                                    '/images/icon/contact/phone-duotone.png'
                                                )}
                                            />
                                            <a className='text-link' href={`tel:${item.phone}`}>
                                                {item.phone}
                                            </a>
                                            {/* <a href={`tel:${item.phone}`}>{item.phone}</a> */}
                                        </div>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </div>
        )}
    </div>
}

export default Tab4;