import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../index.scss';
import { StaticImage } from '../../../utils/image';
import { Avatar, Skeleton } from 'antd';
import { encodeBase64 } from '../../../utils/convertBase64';
import { ConvertDateShort } from '../../../utils/date';
import { checkComplete, checkLeftTime, checkLeftTimeIcon, checkLeftTimeStatus } from '../../../utils';
import ProgressBar from "../../listeningV2/progressBar";
import TagDuoTone from '../../iconComponent/TagDuoTone';
import { checkUser } from '../../../utils/checkUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBalanceScale,
    faPollH,
    faShareAlt,
} from '@fortawesome/free-solid-svg-icons';

const SurveyCardItem = ({ survey, law_group, handleFavorite, setShowShare }) => {

    const history = useHistory();
    const [isCollapse, setIsCollapse] = useState(false);
    const renderImageLawGroup = (item) => {
        let law = "";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.find((x) => x.lawgroup_id == split[0]);
        }

        return (
            <div className="law-group-container d-flex-center">
                <div className="d-flex-center">
                    {law && law.image ? (
                        <Avatar
                            className="avatar-object-contain"
                            size={{
                                xs: 75,
                                sm: 75,
                                md: 75,
                                lg: 75,
                                xl: 75,
                                xxl: 75,
                            }}
                            style={{
                                height: 75,
                                width: 75,
                            }}
                            // shape={'circle'}
                            src={law.image}
                        />
                    ) : (
                        <Skeleton.Button active={true} size={100} shape={"circle"} />
                    )}
                </div>
            </div>
        );
    };
    const renderLawGroupText = (item) => {
        let law = "ไม่ได้ระบุ";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
            law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
        }

        return (
            <div
                className="d-flex discuss-container"
                style={{ marginRight: 15, marginTop: 10 }}
            >
                <img
                    src={StaticImage("/images/icon/books-duotone-1-copy.svg")}
                    style={{ marginRight: 5 }}
                    alt="webboard"
                    height={24}
                />
                <label className="lawgroup-text" style={{ color: "#333", textOverflow: 'initial', maxWidth: 'none', whiteSpace: 'break-spaces' }}>
                    {law}
                </label>
            </div>
        );
    };
    const renderAnswerCount = (survey) => {
        let answer_count = survey.answer_count ?? 0;
        return (
            <div className="d-flex discuss-container" style={{ marginRight: 15 }}>
                <div className="badge-answer">{answer_count}</div>
            </div>
        );
    };
    const renderPublishNo = (survey) => {
        return (
            <div className="d-flex discuss-container">
                <img
                    src={StaticImage("/images/icon/pen-nib-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={24}
                    alt="public-no"
                />
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span>ครั้งที่</span>
                    <div className="badge-public">{survey.publish_no}</div>
                </div>
            </div>
        );
    };

    const collapse = (e, item) => {
        e.stopPropagation();
        setIsCollapse(!isCollapse);
    };

    const navigate = (survey_id) => {
        history.push(`/listeningDetail?survey_id=${encodeBase64(survey_id)}`);
    }

    const renderFavorite = (survey) => {
        return (
            survey.is_like === true ? (
                <img
                    height={32}
                    width={32}
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    )}
                    alt="favorite"
                />
            ) : (
                <img
                    height={32}
                    width={32}
                    className="cursor-pointer"
                    onMouseOver={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2-active.svg"
                    ))
                    }
                    onMouseOut={(e) =>
                    (e.currentTarget.src = StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    ))
                    }
                    src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                    )}
                    alt="favorite"
                />
            )
        );
    }

    const renderDate = () => {
        return (
            <div className="d-flex-align-center" style={{ marginRight: 20 }}>
                <img
                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={16}
                    alt="public-no"
                />
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span>
                        {`${ConvertDateShort(survey.start_date, "DD MMM YY")} - ${ConvertDateShort(survey.end_date, "DD MMM YY")}`}
                    </span>
                </div>
            </div>
        );
    };
    const renderStatus = () => {
        return (
            checkLeftTimeStatus(survey)
        );
    };



    return (
        <section>
            <div className="survey-card">
                <div className="column1">
                    {renderImageLawGroup(survey)}
                    <div
                        className="d-flex-center"
                        style={{ marginTop: 20, minWidth: 170 }}
                    >
                        {survey.agency_id > 0 && (
                            <div
                                className="organize-container"
                                style={{ margin: 0, maxWidth: 100 }}
                            >
                                <img
                                    alt="agency_image"
                                    src={survey.agency_image_uploaded ?? survey.agency_image}
                                    className="logo"
                                    oncontextmenu="return false;"
                                    onError={(i) => (i.target.style.display = "none")}
                                />
                                <label style={{ paddingLeft: "2px" }}>
                                    {survey.agency_short ?? survey.agency_abbrv}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="project-type" style={{ marginTop: 20 }}>
                        <TagDuoTone />
                        <span>
                            {survey.survey_type_name}
                        </span>
                    </div>
                </div>
                <div className="column2">
                    <div className="d-flex">
                        <label className="title flex-1">{survey.short_project_name ?? survey.project_name}</label>
                        <div className="d-flex">
                            <div
                                onClick={() => setShowShare(true)}
                                style={{ marginRight: 10, marginTop: 2 }}
                            >
                                <div class="group relative py-2">
                                    <FontAwesomeIcon
                                        className='share-icon cursor-pointer'
                                        style={{ width: 20, height: 28, marginRight: 10 }}
                                        color='#6d7481'
                                        icon={faShareAlt}
                                    />
                                    <div class="hidden group-hover:block ...">แบ่งปัน</div>
                                </div>
                            </div>

                            <div
                                onClick={(e) => handleFavorite(e, survey)}
                            >
                                <div class="group relative py-2">
                                    {renderFavorite(survey)}
                                    <div class="hidden group-hover:block ...">ถูกใจ</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label className="description">
                        {survey.short_survey_name ?? survey.survey_name}
                    </label>
                    <div className="webboard-row" style={{ flexWrap: 'wrap', marginTop: 5 }}>
                        {renderLawGroupText(survey)}
                        <div className="d-flex" style={{ marginTop: 10 }}>
                            <div className="d-flex discuss-container" >
                                <img
                                    src={StaticImage("/images/icon/users-class-light.svg")}
                                    style={{ marginRight: 10 }}
                                    alt="webboard"
                                />
                                <label style={{ color: "#488345" }}>
                                    ร่วมเสนอ
                                </label>
                            </div>
                            {renderAnswerCount(survey)}
                            {renderPublishNo(survey)}
                        </div>
                    </div>
                    <div className="progressbar-row">
                        <div style={{ display: "flex", flex: 1, paddingRight: 7.5 }}>
                            {checkLeftTimeIcon(survey) ? <>
                                <img
                                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                                    style={{ marginRight: 10 }}
                                    height={20}
                                    alt="public-no"
                                />
                                <span style={{ fontSize: 16 }}>
                                    {ConvertDateShort(survey.start_date, "DD MMM YY")}
                                </span>
                                <section
                                    className="progress-list-filter"
                                    style={{ width: "100%", maxWidth: 400 }}
                                >
                                    <ProgressBar
                                        bgcolor={survey.date_balance > 7 ? "#387436" : "#f96b57"}
                                        bgContainerColor={
                                            survey.date_balance > 7 ? "#e4e8f0" : "#e4e8f0"
                                        }
                                        leftTime={checkLeftTime(survey)}
                                        completed={checkComplete(survey)}
                                    />
                                </section>
                                <span>
                                    <span style={{ fontSize: 16 }}>
                                        {ConvertDateShort(survey.end_date, "DD MMM YY")}
                                    </span>
                                </span>
                            </> :
                                <>
                                    {renderDate()}
                                    {renderStatus()}
                                </>}
                        </div>


                    </div>

                </div>
            </div>

        </section >
    )
}
export default SurveyCardItem;