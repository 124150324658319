import AlertError from "../utils/AlertError";

export const CheckFile = ({ file, size, type = [] }) => {
  if (!type.includes(file.type)) {
    AlertError(null, `support ${type.join(",")}`);
    return false;
  }
  if (file.size / 1000 / 1000 > size) {
    AlertError(null, `Please upload a file smaller than ${size} MB`);
    return false;
  } else {
    return true;
  }
};

export const ConvertFileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
