import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { StaticImage } from '../../utils/image';
import SubMenuList from './SubMenuList';
import SearchIcon from '@material-ui/icons/Search';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BarDuoTone from '../iconComponent/BarDuoTone';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser, checkUserBool } from '../../utils/checkUser';
import Avatar from '@material-ui/core/Avatar';
import ButtonComponent from '../../utils/ButtonComponent';
import CommentAltEdit from '../iconComponent/CommentAltEdit';
const drawerWidth = 300;

const menuState = [
  {
    label: 'ลงทะเบียน/เข้าสู่ระบบ',
    icon: StaticImage('/images/icon/drawer/unlock-alt-duotone.png'),
    type: 'login',
  },
  {
    label: 'โครงการรับฟัง',
    icon: StaticImage('/images/icon/drawer/law-phase1.png'),
    type: 'home',
  },
  {
    label: 'ฐานข้อมูลกฎหมาย',
    icon: StaticImage('/images/icon/drawer/law-phase2.png'),
    type: 'phase2',
  },
  {
    label: 'โปรไฟล์',
    icon: StaticImage('/images/icon/drawer/user-shield-duotone.png'),
    type: 'profile',
  },
  {
    label: 'ออกจากระบบ',
    icon: StaticImage('/images/icon/drawer/unlock-alt-duotone.png'),
    type: 'logout',
  },
  {
    label: "ระบบกลางทางกฎหมาย",
    icon: StaticImage("/images/icon/drawer/info-duotone-copy.png"),
    type: "more",
  },
  {
    label: "เกี่ยวกับเรา",
    icon: StaticImage("/images/icon/drawer/landmark-duotone-2.png"),
    type: "aboutus",
  },
  {
    label: "ดูสถิติการรับฟัง",
    icon: StaticImage("/images/statistics.png"),
    type: "statistics",
  },
  {
    label: "ข้อมูลอื่นๆ",
    icon: StaticImage("/images/icon/drawer/circle-info-duotone@2x.png"),
    type: "acheive",
  },
  {
    label: "คู่มือการใช้งาน",
    icon: StaticImage("/images/icon/drawer/usermanual@2x.png"),
    type: "doc",
  },
  {
    label: "คำถามที่พบบ่อย (FAQ)",
    icon: StaticImage("/images/icon/drawer/faq@2x.png"),
    type: "faq",
  },
  {
    label: "สารบัญ Website",
    icon: StaticImage("/images/icon/drawer/list-of-content@2x.png"),
    type: "listwebsite",
  },
  {
    label: "ติดต่อเรา",
    icon: StaticImage("/images/icon/drawer/contactus@2x.png"),
    type: "contactus",
  },
  {
    label: "นโยบายเว็บไซต์",
    icon: StaticImage("/images/icon/drawer/website@2x.png"),
    type: "termcondition",
  },
  {
    label: "คำประกาศเกี่ยวกับความเป็นส่วนตัว",
    icon: StaticImage("/images/icon/drawer/policy@2x.png"),
    type: "privacynotice",
  },
  {
    label: "นโยบายคุ้กกี้",
    icon: StaticImage("/images/icon/drawer/cookie@2x.png"),
    type: "cookie",
  },
  {
    label: "นโยบายการรักษาความมั่นคงปลอดภัย",
    icon: StaticImage("/images/icon/drawer/policy@2x.png"),
    type: "securitypolicy",
  },
];

const useDrawerStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '30px',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    // display: 'flex',
    // alignItems: 'flex-end',
    // padding: theme.spacing(0, 1),
    // // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    // justifyContent: 'flex-start',
    // height: 158,
    // backgroundColor: '#0c4da2',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
    height: 158,
    backgroundColor: '#ebf0f8',
  },
  labelText: {
    fontSize: 18,
    color: '#fff',
    marginBottom: 10,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    borderRadius: '50px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderRadius: '50px',
  },
  logoBackground: {
    width: '71px',
    height: '71px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#6d94c7',
    position: 'absolute',
    left: 15,
    top: 10,
    borderRadius: 35,
  },
}));

const MobileDeviceIndex = (props) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const classes = useStyles();
  const classesDrawer = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  React.useEffect(() => {
    return () => { };
  }, []);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.drawerHeader}>
        <img
          onClick={handleDrawerClose}
          style={{ position: 'absolute', right: 25, top: 10 }}
          src={StaticImage('/images/icon/drawer-header-close.png')}
        />
        <img
          onClick={handleDrawerClose}
          height={70}
          // src={StaticImage('/images/logo/cld-logo.png')}
          src={StaticImage('/images/logo/law-beta_version-02_20210507.svg')}
        />

        {/* <div
          onClick={() =>
            userState.username ? history.push('/UserProfile') : {}
          }
          className={classes.logoBackground}
        >
          {userState.image_profile ? (
            <Avatar
              size={{
                xs: 71,
                sm: 71,
                md: 71,
                lg: 71,
                xl: 71,
                xxl: 71,
              }}
              style={{ height: 71, width: 71 }}
              // shape={'circle'}
              src={userState.image_profile}
            />
          ) : (
            <img src={StaticImage('/images/user-duotone.png')} />
          )}
        </div>
        <img
          onClick={handleDrawerClose}
          style={{ position: 'absolute', right: 15, top: 10 }}
          src={StaticImage('/images/icon/drawer-header-close.png')}
        />
        <div className={classes.labelText}>
          {checkUserBool(userState)
            ? userState.username
            : 'คุณยังไม่ได้เข้าสู่ระบบ'}
        </div> */}
      </div>
      <Divider />
      <List>
        {menuState.map((text, index) => {
          if (checkUserBool(userState)) {
            if (text.type == 'login') {
              return false;
            }
          } else {
            if (text.type == 'logout') {
              return false;
            }
            if (text.type == 'profile') {
              return false;
            }
          }
          return (
            <div>
              {text.type == 'more' && (
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
              )}
              <ListItem
                onClick={() => navigate(text.type)}
                button
                key={text.label}
              >
                <ListItemIcon>
                  <div
                    style={{
                      width: '46px',
                      height: '46px',
                      borderRadius: 23,
                      backgroundColor:
                        text.type == 'login' ? '#0c4da2' : '#b6bdca',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: 15,
                    }}
                  >
                    <img className="h-6 w-6 object-contain" src={text.icon} />
                  </div>
                </ListItemIcon>
                <ListItemText primary={text.label} />
                {
                  text.type == "phase2" && <img
                    className="h-5 w-8"
                    src={StaticImage("/images/icon_beta.png")}>
                  </img>
                }
              </ListItem>
              {/* {text.type == 'login' && (
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
              )} */}
            </div>
          );
        })}
      </List>

      <Divider />
    </div>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = (type) => {
    if (type == 'login') {
      history.push(`/Login`);
      return false;
    } else if (type == 'logout') {
      dispatch({ type: 'set_user', user: {}, token: '' });
      setTimeout(async () => {
        await localStorage.removeItem('token');
        history.push('/');
      }, 300);
      return false;
    } else if (type == 'profile') {
      history.push('/UserProfile');
      return false;
    }
    else if (type == 'home') {
      history.push('/');
      return false;
    }
    else if (type == 'phase2') {
      history.push('/Laws');
      return false;
    }

    if (type == "acheive") {
      window.open('https://archive.law.go.th', '_blank');
      return false;
    }
    if (type == "statistics") {
            window.open('https://lookerstudio.google.com/embed/u/0/reporting/fe8cc363-01e7-4721-a73d-cb6aff86f551/page/p_scj63dtygd', '_blank')

	    return false;
    }
    if (type == 'faq') {
      history.push(`/DownloadDoc?type=${type}`);
      return false;
    }
    if (type == 'doc') {
      history.push(`/DownloadDoc?type=${type}`);
      return false;
    }

    if (type == 'more') {
      history.push(`/MeanLaw?type=${type}`);
      return false;
    }
    if (type == 'rule') {
      history.push(`/Rule`);
      return false;
    }
    if (type == 'test') {
      history.push(`/Test?type=${type}`);
      return false;
    } else {
      history.push(`/WebView?type=${type}`);
      return false;
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        marginBottom: checkUserBool(userState) ? 60 : 0,
      }}
    >
      <div className={classes.root}>
        <CssBaseline />
        {['left'].map((anchor) => (
          <React.Fragment key={anchor}>
            <AppBar
              position='fixed'
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
              style={{
                backgroundColor: '#ffffff',
                // borderBottomLeftRadius: '30px',
                // borderBottomRightRadius: '30px',
              }}
            >
              <div className='flex py-3 px-2 items-center'>
                <section
                  className='toggle_icon_mobile'
                  onClick={toggleDrawer(anchor, true)}
                >
                  <BarDuoTone
                    className={'mr-2'}
                  />
                </section>
                <div className='flex items-center'>
                  <img
                    className='logo-topbar !h-12'
                    src={StaticImage(
                      isMobile
                        ? '/images/logo/law-beta_version-02_20210507.svg'
                        : '/images/logo/law-beta_version-02_20210507.svg'
                    )}
                    onClick={() => {
                      window.location.href = '/';
                    }}
                  />

                </div>
                <div className='flex flex-1 items-center justify-end gap-x-2'>
                  <div className='flex gap-x-1 items-center'>
                    <img
                      alt="favorite-icon"
                      onClick={() => {
                        if (checkUser(userState)) {
                          // setfavoriteHistory(false);
                          history.push("/");
                        }
                      }}
                      className="cursor-pointer h-6 w-6"
                      src={StaticImage(
                        "/images/icon/header/heart-circle-duotone-2.svg"
                      )}
                    />
                    <CommentAltEdit
                      style={{
                        color: '#6d7481'
                      }}
                      onClick={() => {
                        if (checkUser(userState)) {
                          //setfavoriteHistory(true);
                          //setsurverHistoryStyle({ ...surverHistoryStyle, color: '#0c4da2' })
                          history.push("/SurveyHistory");
                        }
                      }}
                      className='cursor-pointer h-5 w-6'
                    />
                    <img
                      src={StaticImage('/images/bottomnavigation/bell-duotone-gray.svg')}
                      alt='home'
                      className="cursor-pointer h-6 w-6"
                    />
                  </div>
                  {!(userState?.username) ? <div onClick={() => {
                    history.push(`/Login`)
                  }} className="group relative">

                    <img
                      alt="signin-icon"
                      src={StaticImage('/images/login/user-circle-duotone-1.png')}
                      className='user-circle-duotone-1-copy-userprofile logo-topbar h-6 w-6'
                    />
                    {/* <div className="hidden !left-[-100px] !bottom-[-40px] !text-xs group-hover:block ...">ลงทะเบียน / เข้าสู่ระบบ</div> */}
                  </div> : <button onClick={() => {
                    navigate('logout')
                  }} className='bg-blue p-2 rounded-lg text-xs'>ออกจากระบบ</button>
                  }

                  {/* <button onClick={() => {
                    history.push(`/Login`)
                  }} className='bg-blue p-2 rounded-lg text-xs'>ลงทะเบียน / เข้าสู่ระบบ</button> */}
                </div>
              </div>
            </AppBar>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MobileDeviceIndex;
