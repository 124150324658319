import React, { useState, useEffect, useLayoutEffect } from 'react';
import '../../main/submenulist.scss';
import ApiSurvey from '../../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../../utils/image';
// import YoutuberPlayer from "./react-player/YoutuberPlayer";
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import Select from 'react-select';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { Container, fade, Grid, makeStyles } from '@material-ui/core';
import { Card } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import PersonIcon from '@material-ui/icons/Person';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SurveyCard from './newLayout/index';
import SurveyCardMobile from './newLayoutMobile/index';
import { checkUser } from '../../../utils/checkUser';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowSize } from '../../../utils/WindowSize';
import ApiMaster from '../../../api/master';

const useStylesAvatar = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const ListeningPageindex = () => {
  const [width, height] = useWindowSize();
  const [surveys, setsurveys] = useState([]);
  const [surveyFilter, setSurveyFilter] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const classAvatar = useStylesAvatar();
  const userState = useSelector((state) => state.user);
  const [projects, setprojects] = useState([]);
  const [entitys, setEntitys] = useState([]);
  const [process, setprocess] = useState([]);
  const [law_group, setlawgroup] = useState([]);
  useEffect(() => {
    getSurvey();
    getProjectSurvey();
    return () => { };
  }, []);

  const getEntity = async () => {
    Swal.showLoading();
    const masterEntity = await ApiMaster.getEntity();

    if (masterEntity.status == 200) {
      Swal.close();
      const { data } = masterEntity.data;
      setprocess(data);
    }
  };

  const getSurvey = async () => {
    Swal.showLoading();
    const survey = await ApiSurvey.favoriteHistoryProject();
    if (survey.status == 200) {
      Swal.close();
      const { data, law_group } = survey.data;
      setsurveys(data);
      setSurveyFilter(data);
      setlawgroup(law_group);
    }
  };

  const getProjectSurvey = async () => {
    Swal.showLoading();
    const survey = await ApiSurvey.surveyGroupByProject();
    if (survey.status == 200) {
      Swal.close();
      const { data, entitys } = survey.data;
      console.log('project::', data);
      setprojects(data);
      setEntitys(entitys);
      // setsurveys(data);
      // setSurveyFilter(data);
    }
  };

  const getFilterSurvey = async ({
    ministry,
    agency,
    surveyType,
    statusSurvey,
    searchFilter,
  }) => {
    let survey = [...surveys];
    if (ministry > 0) {
      survey = survey.filter((x) => x.ministry_id == ministry);
    }

    if (agency > 0) {
      survey = survey.filter((x) => x.agency_id == agency);
    }
    if (surveyType > 0) {
      if (surveyType == 2) {
        survey = survey.filter(
          (x) => x.survey_type_id == 1 || x.survey_type_id == 2
        );
      } else {
        survey = survey.filter((x) => x.survey_type_id == surveyType);
      }
    }
    if (statusSurvey == 'O') {
      survey = survey.filter(
        (x) => (x.isconclude == 'O' || !x.isconclude) && x.date_balance >= 0
      );
    } else if (statusSurvey == 'I') {
      survey = survey.filter(
        (x) =>
          x.date_balance < 0 ||
          x.isconclude == 'D' ||
          x.isconclude == 'C' ||
          x.isconclude == 'B' ||
          x.isconclude == 'P'
      );
    }
    if (searchFilter) {
      survey = survey.filter(
        (x) =>
          x.survey_name.includes(searchFilter) ||
          x.project_name.includes(searchFilter)
      );
    }

    setSurveyFilter(survey);
  };

  const getOrderSurvey = async ({ orderFilter }) => {
    let survey = [...surveys];
    if (orderFilter == 'last') {
      survey = _.orderBy(surveyFilter, 'created_date', 'desc');
    } else if (orderFilter == 'answer') {
      survey = _.orderBy(
        surveyFilter,
        [
          (o) => {
            return o.answer_count || '';
          },
        ],
        ['desc']
      );
    } else if (orderFilter == 'read') {
      survey = _.orderBy(
        surveyFilter,
        [
          (o) => {
            return o.view_count || '';
          },
        ],
        ['desc']
      );
    } else if (orderFilter == 'popular') {
      survey = _.orderBy(
        surveyFilter,
        [
          (o) => {
            return o.view_count || '';
          },
        ],
        ['desc']
      );
    }

    setSurveyFilter(survey);
  };

  const handleFavorite = async (e, item) => {
    e.stopPropagation();
    if (checkUser(userState)) {
      Swal.fire({
        title: 'คุณต้องการยกเลิกติดตามแบบสอบถามนี้ใช่หรือไม่',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
      }).then(async (ok) => {
        if (ok.isConfirmed) {
          const model = {
            project_id: item.project_id,
          };
          try {
            const result = await ApiSurvey.favoriteProject(model);
            if (result.status == 200) {
              //filter
              let likesFilter = [...surveyFilter];
              const indexFilter = likesFilter.findIndex(
                (x) => parseInt(x.project_id) == item.project_id
              );
              likesFilter[indexFilter].is_like = !likesFilter[indexFilter]
                .is_like;
              likesFilter = likesFilter.filter((x) => x.is_like == true);
              setSurveyFilter(likesFilter);
            }
          } catch (error) {
            console.log(error);
            Swal.close();
          }
        }
      });
    }
  };

  return (
    // <Container maxWidth="xl">
    <div className='container-fluid bg-white'>
      <div>
        {width >= 768 && (
          <SurveyCard
            law_group={law_group}
            surveys={surveyFilter}
            entitys={entitys}
            getFilterSurvey={getFilterSurvey}
            getOrderSurvey={getOrderSurvey}
            handleFavorite={handleFavorite}
          />
        )}
        {width < 768 && (
          <SurveyCardMobile
            surveys={surveyFilter}
            getFilterSurvey={getFilterSurvey}
            getOrderSurvey={getOrderSurvey}
            handleFavorite={handleFavorite}
            law_group={law_group}
            entitys={entitys}
          />
        )}
      </div>
    </div>
  );
};

export default ListeningPageindex;
