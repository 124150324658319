import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  user: {},
  token: '',
  searchAdvance: {
    searchText: "",
    hirachy: null,
    type: null,
    status: null,
    category: null,
    is_all_version: null,
    type_date: 1,
    start_date: "",
    end_date: "",
    address: ""
  },
  searchChar: {
    hirachy: [],
    char: []
  }
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'set_user':
      return { ...state, ...rest };
    case 'set_token':
      return { ...state, ...rest };
    case 'clear_all':
      return initialState;
    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);

export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};

// const store = createStore(changeState);

// export default store;
