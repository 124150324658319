import React, { useEffect, useState } from 'react';

import Section4 from './Section4';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import {
  StarOutlined,
  ShareAltOutlined,
  FormOutlined,
  Loading3QuartersOutlined,
  FileOutlined,
  CloseCircleOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { encodeBase64 } from '../../utils/convertBase64';

const Evaluate = (props) => {
  const history = useHistory();
  const [survey, setsurvey] = useState({});
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  useEffect(() => {
    getSurvey();
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiSurvey.getById({ survey_id });

      if (survey.status == 200) {
        Swal.close();
        const { data } = survey.data;

        setsurvey(data);
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };
  return (
    <div>
      <section>
        <section className='section-banner'>
          <div onClick={() => history.go(-1)} className='back-btn'>
            <ArrowLeftOutlined style={{ marginRight: 6 }} />
            <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
          </div>
          <Section4 survey={survey} />
        </section>
      </section>
      {(survey.isconclude == 'O' || !survey.isconclude) &&
        survey.date_balance >= 0 && (
          <div
            onClick={() => {
              history.push(`/DetailsList?survey_id=${encodeBase64(survey.survey_id)}`);
            }}
            className='bottom-button'
          >
            <div>
              <FormOutlined />
              <span>เริ่มแสดงความเห็น</span>
            </div>
          </div>
        )}
      {(survey.isconclude == 'O' || !survey.isconclude) &&
        survey.date_balance < 0 && (
          <div className='bottom-button'>
            <div className='disabled'>
              <Loading3QuartersOutlined />
              <span>อยู่ระหว่างสรุปผล</span>
            </div>
          </div>
        )}
      {survey.isconclude == 'D' && (
        <div className='bottom-button'>
          <div className='disabled'>
            <Loading3QuartersOutlined />
            <span>อยู่ระหว่างสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == 'C' && (
        <div className='bottom-button'>
          <div className='disabled'>
            <CloseCircleOutlined />
            <span>ไม่มีการสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == 'P' && (
        <div
          onClick={() => {
            history.push(`/Summary?survey_id=${encodeBase64(survey.survey_id)}`);
          }}
          className='bottom-button'
        >
          <div className='summary'>
            <FileOutlined />
            <span>อ่านสรุปผลการรับฟัง</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Evaluate;
