import './index.scss'
import { Col, DatePicker, Input, Row, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useWindowSize } from '../../utils/WindowSize';
import IndexCarousel from "../main/IndexCarousel";
// import Search from 'antd/lib/transfer/search';
import Select from 'react-select'
import { useEffect, useRef, useState } from 'react';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import ApiLaw from '../../api/law';
import Swal from 'sweetalert2';
import { ConvertDateShort } from '../../utils/date';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { StaticImage } from '../../utils/image';
import { checkComplete, checkLeftTime, checkLeftTimeIcon, checkLeftTimeStatus, numberToThai } from '../../utils';
import ApiSurvey from '../../api/survey';
import ProgressBar from './progressBar';
import { encodeBase64 } from '../../utils/convertBase64';
import _ from 'lodash';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EvaluateTableMobile from '../listeningV2/evaluateTableMobile';
import ReactPaginate from 'react-paginate';
import { WatDatePicker } from "thaidatepicker-react";
import moment from 'moment';
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';
const typeOptions = [
    { value: null, label: 'ประเภท' },
    { value: 1, label: 'กฎกระทรวง' },
    { value: 2, label: 'ประกาศ' },
    { value: 3, label: 'ระเบียบ' },
    { value: 4, label: 'คำสั่ง' },
    { value: 5, label: 'พระราชบัญญัติ' },
];
const filterOptions = [
    { value: 1, label: 'ชื่อ' },
    { value: 2, label: 'ชื่อและเนื้อหา' },
];
const versionOptions = [
    { value: null, label: 'แสดงทุกฉบับ' },
    { value: 1, label: 'ฉบับตั้งต้น' },
];
const statusOptions = [
    { value: null, label: 'แสดงทุกสถานะ' },
    { value: 1, label: 'ประกาศใช้' },
    { value: 2, label: 'ยกเลิก' },
];
const pageSizeOp = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
    { value: 50, label: '50' },
    { value: 9999, label: "ทั้งหมด" },
];
const Phase2Index = () => {
    const dispatch = useDispatch();
    const [totalPage, setTotalPage] = useState(1);
    const [totalItem, setTotalItem] = useState(9999);
    const [pageSize, setPageSize] = useState(20);
    const [pageNo, setPageNo] = useState(1);

    const [totalPageSurvey, setTotalPageSurver] = useState(1);
    const [totalItemSurvey, setTotalItemSurvey] = useState(9999);
    const [pageSizesurvey, setPageSizeSurvey] = useState(15);
    const [pageNoSurvey, setPageNoSurvey] = useState(1);


    const [searchState, setSearchState] = useState({
        isAdvance: false,
        isChar: false
    });
    const [laws, setLaws] = useState([]);
    const [virtues, setvirtues] = useState([]);
    const [masters, setMasters] = useState({
        agency: [],
        hirachy: [],
        category: []
    });
    const [width, height] = useWindowSize();
    const history = useHistory();
    const [filter, setfilter] = useState({
        type: null,
        agency: null,
        hirachy: null
    });

    const [searchAdvanceState, setsearchAdvanceState] = useState({
        searchText: "",
        searchTextType: "",
        hirachy: "",
        type: "",
        status: "",
        category: "",
        is_all_version: "",
        type_date: 1,
        start_date: "",
        end_date: "",
        address: ""
    });

    const [searchCharState, setsearchCharState] = useState({
        hirachy: [],
        char: [],
    });

    const [surveys, setsurveys] = useState([]);
    const [surveyFilter, setSurveyFilter] = useState([]);
    const [law_group, setlawgroup] = useState([]);
    const [card_type, setcard_type] = useState('last');
    const [entitys, setEntitys] = useState([]);

    useEffect(() => {
        const promise1 = getMaster();
        const promise2 = getData();
        const getSurveyResult = getSurvey();
        Swal.showLoading();
        Promise.all([promise1, promise2, getSurveyResult]).then(result => {
            Swal.close();
        });

    }, []);

    useEffect(() => {
        if (document.getElementById("tailwind-js")) {

        } else {
            const script = document.createElement("script");
            script.src = `${StaticImage("/tailwind.js")}`;
            script.id = "tailwind-js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    useEffect(() => {
        getOrderSurvey({ orderFilter: card_type })
        return () => {
        }
    }, [card_type])

    const getOrderSurvey = async ({ orderFilter }) => {
        let survey = [...surveys];

        if (orderFilter == 'last') {
            survey = _.orderBy(surveyFilter, 'created_date', 'desc');
        } else if (orderFilter == 'answer') {
            survey = _.orderBy(
                surveyFilter,
                [
                    (o) => {
                        return o.answer_count || '';
                    },
                ],
                ['desc']
            );
        } else if (orderFilter == 'read') {
            survey = _.orderBy(
                surveyFilter,
                [
                    (o) => {
                        return o.view_count || '';
                    },
                ],
                ['desc']
            );
        } else if (orderFilter == 'popular') {
            survey = _.orderBy(
                surveyFilter,
                [
                    (o) => {
                        return o.view_count || '';
                    },
                ],
                ['desc']
            );
        }

        setSurveyFilter(survey);
    };

    const getData = async () => {
        const result = await ApiLaw.getUpdated({
            ...filter,
            size: pageSize > totalItem ? totalItem : pageSize,
            page: pageNo
        });
        if (result.status == 200) {
            const { data } = result;
            setLaws(data.rows);
            setTotalPage(data.lastPage);
            setTotalItem(data.count);

        }
    }

    useEffect(() => {
        getDataFilter();
    }, [pageNo, pageSize]);

    const getMaster = async () => {
        const result = await ApiLaw.getMaster();
        if (result.status == 200) {
            const { data } = result;
            data.agency.unshift({ value: null, label: 'หน่วยงานทั้งหมด' });
            data.hirachy.unshift({ value: null, label: 'ศักดิ์กฎหมายทั้งหมด' });
            data.category.unshift({ value: null, label: 'แสดงทุกหมวด' });
            setMasters(data);
        }
    }


    const getSurvey = async () => {
        // Swal.showLoading();
        try {
            const survey = await ApiSurvey.get({
                size: pageSizesurvey > totalItemSurvey ? totalItemSurvey : pageSizesurvey,
                page: pageNoSurvey,
                sort: "last"
            });
            if (survey.status == 200) {
                // Swal.close();
                const { data, law_group, entitys, lastPage, count } = survey.data;
                setsurveys(pageNoSurvey == 1 ? data : [...surveys, ...data]);
                setSurveyFilter(pageNoSurvey == 1 ? data : [...surveyFilter, ...data]);
                setlawgroup(law_group);
                setEntitys(entitys);
                setTotalPageSurver(lastPage);
                setTotalItemSurvey(count);
            }
        } catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        getDataFilter();
    }, [filter]);

    const getDataFilter = async () => {
        Swal.showLoading();
        const result = await ApiLaw.getUpdated({
            ...filter,
            size: pageSize > totalItem ? totalItem : pageSize,
            page: pageNo
        });
        Swal.close();
        if (result.status == 200) {
            const { data } = result;
            setLaws(data.rows);
            setTotalPage(data.lastPage);
            setTotalItem(data.count);
        }
    }

    const onSearch = async (type, searchText) => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        history.push(`/SearchResult?format=search&type=${type}&searchText=${searchText}`)
    }

    const onSearchAdvance = async () => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        history.push(`/SearchResult?format=searchAdvance&searchText=${searchAdvanceState.searchText}`)
    }

    const onSearchChar = async () => {
        dispatch({ type: 'set', searchChar: searchCharState, searchAdvance: searchAdvanceState });
        history.push(`/SearchResult?format=searchChar`)
    }

    const clearSearchAdvance = async () => {
        setsearchAdvanceState({
            searchText: "",
            searchTextType: "",
            hirachy: null,
            type: null,
            status: null,
            category: null,
            is_all_version: null,
            type_date: 1,
            start_date: "",
            end_date: "",
            address: ""
        })

    }

    const clearSearchChar = async () => {
        setsearchCharState({
            hirachy: [],
            char: [],
        })
    }

    const handlePageClick = (event) => {
        setPageNo(event.selected);
    };

    useEffect(() => {
        if (pageNoSurvey > 1) {
            Swal.showLoading();
            const getSurveyResult = getSurvey();
            try {
                Promise.all([getSurveyResult])
                    .then(data => {
                        Swal.close();
                    })
            } catch (error) {
                Swal.close();
            }
        }
    }, [pageNoSurvey]);

    return <div id="phase2-index" className=''>
        <div>
            <section className='section-banner' style={{}}>
                <IndexCarousel history={history} />
            </section>
        </div>
        <div className="d-flex-align-center px-5" style={{
            marginTop: width >= 768 ? 50 : 50,
            zoom: width >= 768 ? '100%' : '80%'
        }}>
            <div className='w-full'>
                <form onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <div className='flex items-center flex-wrap gap-2'>
                        <div className='flex items-center'>
                            <img
                                alt="box-icon"
                                // aria-label="box-icon"
                                // aria-labelledby='box-icon'
                                className='h-6 mr-2' src={StaticImage('/images/phase2icon/box-taped-duotone.svg')} />
                            <label className='title'>ศูนย์กลางข้อมูลกฎหมายของประเทศ</label>
                        </div>
                        <div onClick={() => {
                            history.push('/LawAbout')
                        }} className='flex cursor-pointer'>
                            <img alt="question-icon" className='w-4 mr-1' src={StaticImage('/images/phase2icon/circle-question-duotone.svg')} />
                            <span className='text-blue-100 italic underline'>มีกฎหมายอะไรบ้าง ?</span>
                        </div>
                    </div>
                    <section>
                        <SearchComponent
                            onSearch={onSearch}
                            onSearchAdvance={onSearchAdvance}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            setsearchAdvanceState={setsearchAdvanceState}
                            searchAdvanceState={searchAdvanceState}
                        ></SearchComponent>
                    </section>
                    {searchState.isAdvance &&
                        <section>
                            <SearchAdvanceComponent
                                setsearchAdvanceState={setsearchAdvanceState}
                                searchAdvanceState={searchAdvanceState}
                                masters={masters}
                                searchState={searchState}
                                setSearchState={setSearchState}
                                onSearchAdvance={onSearchAdvance}
                                clearSearchAdvance={clearSearchAdvance}
                            >

                            </SearchAdvanceComponent>
                        </section>
                    }
                    {
                        searchState.isChar &&
                        <section>
                            <SearchCharComponent
                                masters={masters}
                                searchState={searchState}
                                setSearchState={setSearchState}
                                searchCharState={searchCharState}
                                setsearchCharState={setsearchCharState}
                                onSearchChar={onSearchChar}
                                clearSearchChar={clearSearchChar}
                            />
                        </section>
                    }
                </form>
            </div>
        </div>
        <div className='bg-white items-container pt-5 pb-20 sm:pt-5 sm:pb-5 mt-5'>
            <div className="d-flex-align-center px-5" style={{
                zoom: width >= 768 ? '100%' : '80%'
            }}>
                <div className='w-full'>
                    <Row className='hidden sm:flex'>
                        <Col span={16}>
                            <div className='flex'>
                                <img alt="law-new-icon" className='w-4 mr-2'
                                    src={StaticImage('/images/phase2icon/file-medical-alt-duotone.svg')} />
                                <label className='title'>อัพเดทกฎหมายใหม่</label>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='flex'>
                                <img alt="listening-icon" className='w-5 mr-2'
                                    src={StaticImage('/images/phase2icon/message-pen-duotone.png')} />
                                <label className='title'>โครงการรับฟัง</label>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-5 hidden sm:flex'>
                        <Col className='hidden sm:block' span={15}>
                            <div className='d-flex items-center'>
                                <div className='flex items-center'>
                                    <img alt="filter-icon" className='h-4 mr-2'
                                        src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                                    <label className='mr-2 text-base'>ตัวกรอง</label>
                                </div>
                                <div className='d-flex flex-1'>
                                    {/* <Select
                                        className='basic-single ml-2 flex-1'
                                        classNamePrefix='select'
                                        defaultValue={typeOptions[0]}
                                        options={typeOptions}
                                        value={typeOptions.find((x) => x.value == filter.type)}
                                        onChange={(item) => {
                                            setfilter({ ...filter, type: item.value });
                                        }}
                                    /> */}
                                    <Select
                                        aria-label="agency-select"
                                        className='basic-single ml-2 flex-1'
                                        classNamePrefix='select'
                                        defaultValue={masters.agency[0] ?? ""}
                                        options={masters.agency}
                                        value={masters.agency.find((x) => x.value == filter.agency)}
                                        onChange={(item) => {
                                            setfilter({ ...filter, agency: item.value });
                                        }}
                                    />
                                    <Select
                                        aria-label="hirachy-select"
                                        className='basic-single ml-2 flex-1'
                                        classNamePrefix='select'
                                        defaultValue={masters.hirachy[0] ?? ""}
                                        options={masters.hirachy}
                                        value={masters.hirachy.find((x) => x.value == filter.hirachy)}
                                        onChange={(item) => {
                                            setfilter({ ...filter, hirachy: item.value });
                                        }}
                                    />
                                    <div className='w-24 ml-2'>
                                        <Select
                                            aria-label="pagesize-select"
                                            className='basic-single flex-1'
                                            classNamePrefix='select'
                                            defaultValue={pageSizeOp[0]}
                                            options={pageSizeOp}
                                            value={pageSizeOp.find((x) => x.value == pageSize)}
                                            onChange={(item) => {
                                                setPageSize(item.value)
                                                //setsearchAdvanceState({ ...searchAdvanceState, status: item.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='hidden sm:block' span={1}></Col>
                        <Col className='hidden sm:block' span={8}>
                            <div className='flex filter-survey gap-x-2'>
                                <button onClick={() => setcard_type("last")} className={`flex last items-center ${card_type == "last" && "active"}`}>
                                    <div className='survey-type-1 mr-2'>
                                        <img alt="last-icon" className='h-3'
                                            src={StaticImage('/images/phase2icon/bolt-solid.svg')} />
                                    </div>
                                    <span>ล่าสุด</span>
                                </button>
                                <button onClick={() => setcard_type("progress")} className={`flex update items-center ${card_type == "progress" && "active"}`}>
                                    <div className='survey-type-2 mr-2'>
                                        <img alt="update-icon" className='h-3'
                                            src={StaticImage('/images/phase2icon/history-solid-2.svg')} />
                                    </div>
                                    <span>ความคืบหน้า</span>
                                </button>
                                {/* <button onClick={() => setcard_type("evaluate")} className={`flex evaluate items-center ${card_type == "evaluate" && "active"}`}>
                                    <div className='survey-type-3 mr-2'>
                                        <img className='h-3'
                                            src={StaticImage('/images/phase2icon/file-signature-duotone.svg')} />
                                    </div>
                                    <span>ประเมินผลสัมฤทธิ์</span>
                                </button> */}
                            </div>
                            <div></div>
                        </Col>
                    </Row>
                    <Row >
                        <Row className='mt-5 hidden sm:flex sm:flex-1'>
                            <Col className='hidden sm:block' span={15}>
                                {laws.length > 0 && laws.map((item, index) => {
                                    return <ItemLawComponent master={masters} item={item} key={index} />
                                })
                                }
                                <div className='flex items-center justify-center'>
                                    <ReactPaginate
                                        //nextLabel="next >
                                        className="pagination gap-x-2"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPage}
                                        // previousLabel="< previous"

                                        pageClassName="page-item"
                                        pageLinkClassName="page-link shadow"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link shadow"
                                        nextClassName="page-item"
                                        previousLabel="ก่อนหน้า"
                                        nextLabel="ถัดไป"
                                        nextLinkClassName="page-link shadow"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link shadow"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </Col>
                            <Col className='hidden sm:block' span={1}></Col>
                            <Col span={8} className="right-component hidden sm:block">
                                {card_type == "last" &&
                                    surveyFilter.map((item, index) => {
                                        return <SurveyComponent law_group={law_group} key={'survey-' + item.survey_id} survey={item} />
                                    })
                                }
                                {card_type == "progress" &&
                                    surveyFilter.map((item, index) => {
                                        return <ProjectComponent
                                            entitys={entitys.filter(x => x.project_type_id == item.project.project_type_id && x.order_id != 98 && x.order_id != 99)}
                                            law_group={law_group} key={'project-' + item.survey_id} survey={item} />
                                    })
                                }
                                {
                                    card_type == "evaluate" &&
                                    <EvaluateTableMobile
                                        ministryOption={[]}
                                        type="mobile" />
                                }
                                {
                                    pageNoSurvey !== totalPageSurvey && <div className='flex justify-center '>
                                        <button className='button-theme button-theme-blue-45 ' onClick={() => {
                                            // setPageNoSurvey(pageNoSurvey + 1)
                                            history.push('/');
                                        }}>ดูเพิ่มเติม</button>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Row>
                    <div className='flex flex-col sm:hidden gap-y-4'>
                        <div className='flex'>
                            <img alt="law-new-icon" className='w-4 mr-2'
                                src={StaticImage('/images/phase2icon/file-medical-alt-duotone.svg')} />
                            <label className='title'>อัพเดทกฎหมายใหม่</label>
                        </div>
                        <div className='flex flex-col'>
                            {laws.length > 0 && laws.map((item, index) => {
                                return <ItemLawComponent master={masters} item={item} key={index} />
                            })
                            }
                            <div className='flex items-center justify-center'>
                                <ReactPaginate
                                    //nextLabel="next >
                                    className="pagination gap-2 flex-wrap"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={1}

                                    pageCount={totalPage}
                                    // previousLabel="< previous"

                                    pageClassName="page-item"
                                    pageLinkClassName="page-link shadow"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link shadow"
                                    nextClassName="page-item"
                                    previousLabel="ก่อนหน้า"
                                    nextLabel="ถัดไป"
                                    nextLinkClassName="page-link shadow"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link shadow"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const ItemLawComponent = ({ item, master }) => {
    const history = useHistory();

    const renderRivision = (law) => {
        const effectiveStart = ConvertDateShort(item.effective_startdate, 'DD MMM YY');
        const file_docx = law.content_files.find(x => x.type == "content" && x.file_name.includes('.doc'));
        const file_pdf = law.content_files.find(x => x.type == "content" && x.file_name.includes('.pdf'));
        const announce_url = law.announce_url;
        return <div className='d-flex text-gray items-center gap-x-2'>
            {`Revision : ${effectiveStart} – ปัจจุบัน`}
            {
                announce_url && <div class="group relative py-2">
                    <button
                        name={`kritsadika-button-${item.law_name}`}
                        aria-label={`kritsadika-button-${item.law_name}`}
                        disabled={!announce_url}
                        onClick={() => {
                            window.open(announce_url);
                        }}>
                        <img className='h-4'
                            alt="kritsadika-icon"
                            src={StaticImage('/images/phase2icon/vector-smart-object.png')} /></button>
                    <div class="hidden group-hover:block ...">ประกาศราชกิจจานุเบกษา</div>
                </div>
            }
            {file_pdf && <div class="group relative py-2">
                <button
                    name={`pdf-button-${item.law_name}`}
                    aria-label={`pdf-button-${item.law_name}`}
                    disabled={
                        !file_pdf?.file_path
                    } onClick={() => {
                        window.open(file_pdf?.file_path);
                    }}>
                    <img className='h-4'
                        alt="pdf-icon"
                        src={StaticImage('/images/phase2icon/pdf.svg')} />
                </button>
                <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.pdf)</div>
            </div>}
            {
                file_docx && <div class="group relative py-2">
                    <button
                        aria-label={`docx-button-${item.law_name}`}
                        name={`docx-button-${item.law_name}`}
                        disabled={
                            !file_docx?.file_path
                        } onClick={() => {
                            window.open(file_docx?.file_path);
                        }}>
                        <img className='h-4'
                            alt="docx-icon"
                            src={StaticImage('/images/phase2icon/word.svg')} />
                    </button>
                    <div class="hidden group-hover:block ...">เนื้อหากฎหมาย (.docx)</div>
                </div>
            }

        </div>
    }

    const renderVirtue = (law) => {
        if (law.virtues?.length == 0) {
            return <></>
        }
        return <span className='text-blue underline'>
            {master.hirachy.find(x => x.value == law.virtues[0].hirachy_of_law_id)?.label}/{law.virtues[0].law_name}
        </span>
    }

    return <div className='law-container mb-5 d-flex'>
        <div></div>
        <div className='w-full flex flex-col gap-y-2'>
            <div className='flex flex-col sm:flex-row justify-between gap-y-2'>
                <div className='d-flex items-center justify-between'>
                    <label onClick={() => {
                        history.push(`/DetailLawPage?table_of_law_id=${item.table_of_law_id}`);
                    }} className='title-name cursor-pointer text-lg sm:text-xl line-clamp-3'>{item.law_name_th}</label>
                </div>
                <div className='d-flex text-gray whitespace-nowrap items-center h-fit sm:mt-1'>
                    <img alt="updatedate-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/clock-duotone.svg')} />
                    <span>
                        Update : {ConvertDateShort(item.updatedate, 'DD MMM YY')}
                    </span>
                </div>
            </div>

            {/* <div className='description mt-2'>
                {item.simple_description?.replace(/(<([^>]+)>)/ig, '')}
            </div> */}
            <div className='d-flex items-center justify-between'>
                {renderRivision(item)}
            </div>
            <div className='description line-clamp-3'>
                {item.content_all?.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '')}
            </div>

            <div className='flex flex-col sm:flex-row mt-2 flex-wrap gap-y-2'>
                <div className='d-flex mr-5'>
                    <div className='flex items-center'>
                        <img alt="category-icon" className='h-4 mr-2'
                            src={StaticImage('/images/phase2icon/books-duotone-1.svg')} />
                        <span>
                            {master.category.length > 0 ?
                                (master.category.filter(x => item.category_id.split(',').includes(x.value?.toString())).map(x => x.label) ?? "-") : "-"}
                        </span>
                    </div>
                </div>
                <div className='flex items-center'>
                    <img alt="agency-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/building-columns-duotone.svg')} />
                    <span>หน่วยงาน :&nbsp;
                        {master.agency.length > 0 &&
                            master.agency.filter(x => item.agency_response.split(',').includes(x.value?.toString())).map(x => x.label_short ?? x.label)?.join(',')}
                    </span>
                </div>
            </div>
            {
                renderVirtue(item)
            }
        </div>
    </div>
}

const SurveyComponent = ({ survey, law_group = [] }) => {
    const renderLawGroupText = (item) => {
        let law = "ไม่ได้ระบุ";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
            law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
        }

        return (
            <span className='text-black'>{law}</span>
        );
    };

    const renderDate = () => {
        return (
            <div className="d-flex-align-center" style={{ marginRight: 20 }}>
                <img
                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={16}
                    alt="public-no"
                />
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span>
                        {`${ConvertDateShort(survey.start_date, "DD MMM YY")} - ${ConvertDateShort(survey.end_date, "DD MMM YY")}`}
                    </span>
                </div>
            </div>
        );
    };
    const renderStatus = () => {
        return (
            checkLeftTimeStatus(survey)
        );
    };

    return <Link to={`/listeningDetail?survey_id=${encodeBase64(survey.survey_id)}`}>
        <div className='survey-item-container mb-4'>
            <div className='title-container flex'>
                <label className='title-name text-base line-clamp-2'>{survey.short_project_name ?? survey.project_name}</label>
            </div>
            <div className='content-container gap-y-2 flex flex-column'>
                <span className='text-gray line-clamp-2'>{survey.short_survey_name ?? survey.survey_name}</span>
                <div className='flex items-center'>
                    <img alt="category-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/books-duotone-1.svg')} />
                    {renderLawGroupText(survey)}
                </div>
                <div className='flex items-center'>
                    <img alt="calendar-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/calendar-days-duotone.svg')} />
                    <span className='text-blank date-text'>
                        {`${ConvertDateShort(survey.start_date, "DD MMM YY")} - ${ConvertDateShort(survey.end_date, "DD MMM YY")}`}
                    </span>
                </div>
                <div className="progressbar-row">
                    <div className='flex items-center'>

                        {checkLeftTimeIcon(survey) ?
                            <>
                                <img
                                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                                    className="h-4 mr-2"
                                    alt="public-no"
                                />
                                <section
                                    className="progress-list-filter"
                                    style={{ width: "100%", maxWidth: 400 }}
                                >
                                    <ProgressBar
                                        bgcolor={survey.date_balance > 7 ? "#387436" : "#f96b57"}
                                        bgContainerColor={
                                            survey.date_balance > 7 ? "#717377" : "#717377"
                                        }
                                        leftTime={checkLeftTime(survey)}
                                        completed={checkComplete(survey)}
                                    />
                                </section>
                            </> :
                            <>
                                {renderStatus()}
                            </>
                        }
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex'>
                        <div className='flex items-center'>
                            <img className='h-4 mr-1'
                                alt="answer-icon"
                                src={StaticImage('/images/phase2icon/users-class-light.svg')} />
                            <span className='text-green mr-1'>ร่วมเสนอ</span>
                        </div>
                        <div className='bg-green text-white px-2 rounded'>{survey.answer_count ?? 0}</div>
                    </div>
                    <div className='flex'>

                        <div className='flex items-center'>
                            <img className='h-4 mr-1'
                                alt="count-icon"
                                src={StaticImage('/images/phase2icon/pen-nib-duotone.svg')} />
                            <span className='mr-1 text-black'>ครั้งที่</span>
                        </div>
                        <div className='bg-blue text-white px-2 rounded'>{survey.publish_no}</div>
                    </div>
                </div>
            </div>
        </div>
    </Link>
}

const ProjectComponent = ({ survey, law_group = [], entitys }) => {
    const project = survey.project;
    const entity_name = (project.entity_name) ?? entitys[0]?.entity_name;
    const renderLawGroupText = (item) => {
        let law = "ไม่ได้ระบุ";
        if (item.law_group_id) {
            const split = item.law_group_id.split(",");
            law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
            law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
        }

        return (
            <span className='text-black'>{law}</span>
        );
    };
    const [isCollapse, setIsCollapse] = useState(false);
    const [iscancel, setiscancel] = useState(false)

    const renderDate = () => {
        return (
            <div className="d-flex-align-center" style={{ marginRight: 20 }}>
                <img
                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={16}
                    alt="public-no"
                />
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span>
                        {`${ConvertDateShort(survey.start_date, "DD MMM YY")} - ${ConvertDateShort(survey.end_date, "DD MMM YY")}`}
                    </span>
                </div>
            </div>
        );
    };
    const renderStatus = () => {
        return (
            checkLeftTimeStatus(survey)
        );
    };

    return <Link to={`/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`}>
        <div className='survey-item-container mb-4'>
            <div className='title-container flex justify-between items-center'>
                <span className='text-black'>เลขที่ (ID) {project.project_id}</span>
                <div className='flex items-center'>
                    <img className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/calendar-days-duotone.svg')} />
                    <span className='text-blank date-text'>
                        {ConvertDateShort(project.createdate, "DD MMM YY")}
                    </span>
                </div>
            </div>
            <div className='title-container flex'>
                <label className='title-name text-base line-clamp-2'>{project.project_name}</label>
            </div>
            <div className='content-container gap-y-2 flex flex-column'>
                <div className='flex items-center'>
                    <img className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/books-duotone-1.svg')} />
                    {renderLawGroupText(survey)}
                </div>
                <div className="d-flex-align-center process-container">
                    <FontAwesomeIcon icon={faBalanceScale} />
                    <span className="entity-text text-regular">
                        {project.entity_name ?? entitys[0]?.entity_name}
                    </span>
                    {project.logs_activity.filter((x) => x.type == 'upload_ria')
                        .length > 0 && (
                            <div className='d-flex-center' style={{ marginLeft: 10 }}>
                                <img
                                    height={28}
                                    width={28}
                                    src={StaticImage('/images/icon/header/ria.svg')}
                                />
                                <span className="entity-text text-regular" style={{ marginLeft: 10 }}>
                                    {"สรุปผล"}
                                </span>
                            </div>
                        )}

                    {project.logs_activity.filter((x) => x.type == 'upload_summary')
                        .length > 0 && (
                            <div className='d-flex-center' style={{ marginLeft: 10 }}>
                                <img
                                    height={24}
                                    width={24}
                                    src={StaticImage('/images/icon/header/summary.svg')}
                                />
                                <span className="entity-text text-regular" style={{ marginLeft: 10 }}>
                                    {"RIA"}
                                </span>
                            </div>
                        )}
                </div>
                <div className="d-flex-align-center flex-1" style={{ justifyContent: 'space-between', alignSelf: 'flex-end', width: '100%' }}>
                    {(entity_name == "ออกเป็นกฎหมาย" && project.law_url) &&
                        <label onClick={(e) => {
                            e.stopPropagation();
                            window.open(project.law_url, '_blank')
                        }} className='law_publish_link flex-1'>{project.law_url}</label>
                    }
                    {entity_name !== "ออกเป็นกฎหมาย" &&
                        <div className="project-timeline-body-bottom flex-1">
                            <div
                                className="d-flex"
                                style={{ flexWrap: "wrap", marginTop: -10 }}
                            >
                                {entitys.map((item, index) => {
                                    let order_id = project.process_order;
                                    if (order_id == 1) {
                                        order_id = entitys[0].order_id
                                    }
                                    const findIndex = entitys.findIndex(
                                        (x) =>
                                            x.order_id ==
                                            (order_id ?? entitys[0].order_id)
                                    );
                                    let className = '';

                                    if (
                                        item.order_id ==
                                        (order_id ?? entitys[0].order_id)
                                    ) {
                                        className = 'active';
                                    }
                                    if (findIndex > index) {
                                        className += 'pass';
                                    }
                                    return (
                                        <div
                                            className={`process-step d-flex-center ${className} ${iscancel ? " iscancel" : ""}`}
                                        >
                                            {item.entity_abbrv}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }

                    {/* <div className="d-flex expand cursor-pointer">
                        <div className="badge-public">{survey.surveys.length + 1}</div>
                        <div class="d-flex" style={{ alignItems: 'center' }} onClick={(e) => collapse(e, survey)}>
                            <span style={{ marginRight: 5 }}>{!isCollapse ? 'ขยาย' : 'ปิด'}</span>
                            <img
                                src={StaticImage(!isCollapse ? "/images/icon/chevron-circle-down.svg" : "/images/icon/chevron-circle-up.png")}
                                style={{ marginRight: 5 }}
                                height={24}
                                alt="public-no"
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </Link>
}

const SearchComponent = ({ searchState, setSearchState, onSearch, setsearchAdvanceState, searchAdvanceState, onSearchAdvance }) => {
    const history = useHistory();
    const inputRef = useRef(null);
    const [search, setsearch] = useState({
        type: 1,
        searchText: "",
    });
    return <div className='flex flex-col'>
        <div className='d-flex search-component items-center flex-wrap gap-y-2'>
            <div className='d-flex type-search w-32 mr-2'>
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className='basic-single w-full flex-1'
                    classNamePrefix='select'
                    defaultValue={filterOptions[0]}
                    options={filterOptions}
                    value={filterOptions.find((x) => x.value == searchAdvanceState.type)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, type: item.value });
                    }}
                />
            </div>
            <div className='d-flex min-w-[200px] flex-1 flex relative'>
                <Input
                    value={searchAdvanceState.searchText}
                    onChange={(e) => {
                        setsearchAdvanceState({ ...searchAdvanceState, searchText: e.target.value });
                    }}
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            onSearchAdvance();
                        }
                    }}
                    className="search flex-1"
                    placeholder="พิมพ์คำค้นหากฎหมาย"
                    suffix={
                        <>
                            <div className='flex gap-x-2 items-center hidden sm:flex'>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "and") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", "").replace(".ยกเว้น.", "") + ".และ.", searchTextType: "and" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "and" && "active"}`
                                    }>และ</button>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "or") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".ยกเว้น.", "") + ".หรือ.", searchTextType: "or" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "or" && "active"}`}>หรือ</button>
                                <button type="button"
                                    onClick={() => {
                                        if (searchAdvanceState.searchTextType == "except") {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".ยกเว้น.", ""), searchTextType: "" });
                                        }
                                        else if (searchAdvanceState.searchText) {
                                            setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".หรือ.", "") + ".ยกเว้น.", searchTextType: "except" });
                                        }
                                        inputRef.current.focus();
                                    }}
                                    className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "except" && "active"}`}>ยกเว้น</button>
                                <button type="button" onClick={() => {
                                    onSearchAdvance();
                                }} className='search-button'>
                                    <SearchOutlined />
                                </button>
                            </div>
                            <div className='flex gap-x-2 items-center flex sm:hidden'>
                                <button type="button" onClick={() => {
                                    onSearchAdvance();
                                }} className='search-button'>
                                    <SearchOutlined />
                                </button>
                            </div>
                        </>
                    }
                    ref={inputRef}
                />
            </div>
            <div className='hidden sm:flex'>
                <div className='flex' style={{ marginLeft: 10 }}>
                    <button type="button" className={`button flex items-center ${searchState.isAdvance ? "active" : ""}`} onClick={() => {
                        setSearchState({ ...searchState, isAdvance: true, isChar: false })
                    }}>
                        <img alt="magnify-icon" className='w-4 mr-1'
                            src={StaticImage('/images/phase2icon/magnifying-glass-plus-duotone-1.svg')} />
                        ค้นหาแบบละเอียด
                    </button>
                </div>
                <div className='d-flex' style={{ marginLeft: 10 }}>
                    <button type="button" className={`button ${searchState.isChar ? "active" : ""}`} onClick={() => {
                        setSearchState({ ...searchState, isAdvance: false, isChar: true })
                    }}>
                        ก-ฮ
                    </button>
                </div>
            </div>
            <div className='flex flex-1 justify-between sm:hidden'>
                <div className='flex gap-x-2 items-center'>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchTextType == "and") {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", ""), searchTextType: "" });
                            }
                            else if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", "").replace(".ยกเว้น.", "") + ".และ.", searchTextType: "and" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "and" && "active"}`
                        }>และ</button>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchTextType == "or") {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".หรือ.", ""), searchTextType: "" });
                            }
                            else if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".ยกเว้น.", "") + ".หรือ.", searchTextType: "or" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "or" && "active"}`}>หรือ</button>
                    <button type="button"
                        onClick={() => {
                            if (searchAdvanceState.searchTextType == "except") {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".ยกเว้น.", ""), searchTextType: "" });
                            }
                            else if (searchAdvanceState.searchText) {
                                setsearchAdvanceState({ ...searchAdvanceState, searchText: searchAdvanceState.searchText?.replace(".และ.", "").replace(".หรือ.", "") + ".ยกเว้น.", searchTextType: "except" });
                            }
                            inputRef.current.focus();
                        }}
                        className={`cursor-pointer button-item ${searchAdvanceState.searchTextType == "except" && "active"}`}>ยกเว้น</button>
                </div>
                <div className='flex gap-x-2'>
                    <div className='flex'>
                        <button type="button" className={`button flex items-center ${searchState.isAdvance ? "active" : ""}`} onClick={() => {
                            setSearchState({ ...searchState, isAdvance: true, isChar: false })
                        }}>
                            <img alt="magnify-icon" className='w-4 mr-1'
                                src={StaticImage('/images/phase2icon/magnifying-glass-plus-duotone-1.svg')} />
                            ค้นหาแบบละเอียด
                        </button>
                    </div>
                    <div className='d-flex' >
                        <button type="button" className={`button ${searchState.isChar ? "active" : ""}`} onClick={() => {
                            setSearchState({ ...searchState, isAdvance: false, isChar: true })
                        }}>
                            ก-ฮ
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </div >
}

const SearchAdvanceComponent = ({ searchState, setSearchState, masters, searchAdvanceState, setsearchAdvanceState, onSearchAdvance, clearSearchAdvance }) => {
    const inputRef = useRef(null);
    return <div className='search-advance-container flex flex-column flex-1 mt-3'>
        <div className='flex mt-5 items-center'>
            <img className='h-4 mr-2'
                src={StaticImage('/images/phase2icon/search-duotone.svg')} />
            <label className='text-blue-500 text-base font-bold'>ค้นหาใน</label>
        </div>
        <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
            <div className='flex-1 flex flex-column'>
                <span className='text-blue-100'>ศักดิ์ของกฎหมาย</span>
                <Select
                    className='basic-single mt-1 flex-1'
                    classNamePrefix='select'
                    defaultValue={masters.hirachy[0] ?? ""}
                    options={masters.hirachy}
                    value={masters.hirachy.find((x) => x.value == searchAdvanceState.hirachy)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, hirachy: item.value });
                    }}
                />
            </div>
            <div className='flex-1 flex flex-column'>
                <span className='text-blue-100'>สถานะ</span>
                <Select
                    className='basic-single mt-1 flex-1'
                    classNamePrefix='select'
                    defaultValue={statusOptions[0]}
                    options={statusOptions}
                    value={statusOptions.find((x) => x.value == searchAdvanceState.status)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, status: item.value });
                    }}
                />
            </div>
        </div>
        <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
            <div className='flex-1 flex gap-x-2 sm:items-center gap-y-2 flex-col sm:flex-row'>
                <div className='flex items-center'>
                    <img className='h-4 mr-1'
                        src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                    <span className='text-blue-500'>ตัวกรอง</span>
                </div>

                <Select
                    className='basic-single flex-1'
                    classNamePrefix='select'
                    defaultValue={masters.category[0] ?? ""}
                    options={masters.category}
                    value={masters.category.find((x) => x.value == searchAdvanceState.category)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, category: item.value });
                    }}
                />
            </div>
            <div className='flex-1 flex flex-column'>
                <Select
                    className='basic-single flex-1'
                    classNamePrefix='select'
                    defaultValue={versionOptions[0]}
                    options={versionOptions}
                    value={versionOptions.find((x) => x.value == searchAdvanceState.is_all_version)}
                    onChange={(item) => {
                        setsearchAdvanceState({ ...searchAdvanceState, is_all_version: item.value });
                    }}
                />
            </div>

        </div>
        <div className='flex-col'>
            <div className='flex items-center mt-5'>
                <img className='h-4 mr-2'
                    src={StaticImage('/images/phase2icon/calendar-week-duotone.svg')} />
                <label className='text-blue-500 text-base font-bold'>สืบค้นจากช่วงเวลา</label>
            </div>
            <div className='flex mt-3 gap-x-4 gap-y-2 flex-col sm:flex-row'>
                <div className='d-flex type-search'>
                    <div onClick={() => setsearchAdvanceState({ ...searchAdvanceState, type_date: 1 })} className={`cursor-pointer ${searchAdvanceState.type_date == 1 && "active"}`}>วันที่ประกาศ</div>
                    <div onClick={() => setsearchAdvanceState({ ...searchAdvanceState, type_date: 2 })} className={`cursor-pointer ${searchAdvanceState.type_date == 2 && "active"}`}>วันที่บังคับใช้</div>
                </div>
                <div className='flex-1'>
                    <WatDatePicker
                        value={searchAdvanceState.start_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                        onChange={(christDate, buddhistDate) => {
                            setsearchAdvanceState({ ...searchAdvanceState, start_date: moment(christDate) });
                        }}
                        inputStyle={{
                            backgroundColor: '#FFFFFF',
                            fontSize: 14,

                        }}
                        placeholder="เริ่มต้น วว/ดด/ปป"
                        dateFormat={'yyyy-MM-dd'}
                        // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                        displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                        //inputStyle={{ color: 'red' }} // styles for input
                        clearable={false} // true | false
                        //minDate={'2020-12-26'} // supported date as string
                        //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                        disabled={false} // true | false
                        readOnly={false} // true | false
                        yearBoundary={99} // number of boundary ±X Year
                    />
                </div>
                <div className='flex-1'>
                    <WatDatePicker
                        value={searchAdvanceState.end_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                        onChange={(christDate, buddhistDate) => {
                            setsearchAdvanceState({ ...searchAdvanceState, end_date: moment(christDate) });
                        }}
                        inputStyle={{
                            backgroundColor: '#FFFFFF',
                            fontSize: 14,

                        }}
                        placeholder="สิ้นสุด วว/ดด/ปป"
                        dateFormat={'yyyy-MM-dd'}
                        // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                        displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                        //inputStyle={{ color: 'red' }} // styles for input
                        clearable={false} // true | false
                        //minDate={'2020-12-26'} // supported date as string
                        //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                        disabled={false} // true | false
                        readOnly={false} // true | false
                        yearBoundary={99} // number of boundary ±X Year
                    />
                </div>
            </div>

            <div className='flex items-center mt-5'>
                <img className='h-4 mr-2'
                    src={StaticImage('/images/phase2icon/map-marker-alt-duotone.svg')} />
                <label className='text-blue-500 text-base font-bold'>สืบค้นจากสถานที่</label>
            </div>
            <div className='flex mt-3 gap-x-4'>
                <div className='flex-1'>
                    <Input
                        value={searchAdvanceState.address}
                        type={'text'}
                        placeholder={'กรอกชื่อสถานที่'}
                        onChange={(e) => {
                            setsearchAdvanceState({ ...searchAdvanceState, address: e.target.value });
                        }}
                    />
                </div>
                <div className='flex gap-x-4 hidden sm:flex'>
                    <button type="button" className={`button`} onClick={() => {
                        clearSearchAdvance();
                    }}>
                        ล้างข้อมูล
                    </button>
                    <button type="button" className={`button active`} onClick={() => {
                        onSearchAdvance();
                    }}>
                        ค้นหา
                    </button>
                </div>

            </div>
        </div>
        <div className='flex justify-between mt-3 sm:hidden'>
            <button type="button" className={`button`} onClick={() => {
                clearSearchAdvance();
            }}>
                ล้างข้อมูล
            </button>
            <button type="button" className={`button active`} onClick={() => {
                onSearchAdvance();
            }}>
                ค้นหา
            </button>
        </div>
        <div className='flex justify-center mt-5'>
            <button type="button" className={`button-close`} onClick={() => {
                setSearchState({ ...searchState, isAdvance: false })
            }}>
                ปิดการค้นหาอย่างละเอียด
            </button>
        </div>
    </div >
}

const SearchCharComponent = ({ searchState, setSearchState, masters, searchCharState, setsearchCharState, onSearchChar, clearSearchChar }) => {
    const addHirachy = (value) => {
        // let hirachyList = [...searchCharState.hirachy];
        // if (hirachyList.includes(value)) {
        //     hirachyList = hirachyList.filter(x => x != value);
        // } else {
        //     hirachyList.push(value);
        // }
        setsearchCharState({ ...searchCharState, hirachy: value })
    }

    const addChar = (value) => {
        // let charList = [...searchCharState.char];
        // if (charList.includes(value)) {
        //     charList = charList.filter(x => x != value);
        // } else {
        //     charList.push(value);
        // }
        setsearchCharState({ ...searchCharState, char: [value] })
    }
    const data = [{
        icon: <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scale-balanced-duotone.svg')} />,
        label: "รัฐธรรมนูญ",
        value: 887,
        values: [887]
    },
    {
        icon: <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scroll-old-duotone.svg')} />,
        label: "พ.ร.บ./พระราชกำหนด",
        value: 1,
        values: [1, 883]
    },
    {
        icon: <img className='h-6' src={StaticImage('/images/phase2icon/book-duotone.svg')} />,
        label: "ประมวลกฎหมาย",
        value: 889,
        values: [889]
    }];
    const dataChar = ["ก", "ข", "ค", "ง", "จ", "ช", "ด", "ต", "ถ", "ท", "ธ", "น", "บ", "ป", "ผ", "ฝ", "พ", "ภ", "ม", "ย", "ร", "ล", "ว", "ศ", "ษ", "ส", "ห", "ฬ", "อ", "ฮ"]
    return <div className='search-char-container flex flex-column flex-1 mt-3 gap-y-4'>
        <div className='flex-wrap hidden sm:flex'>
            {data.map((item, index) => {
                if (index == 0) return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type left whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
                else if ((masters.hirachy.length - 1) == index) return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type right whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
                else return <div onClick={() => {
                    addHirachy(item.values)
                }} className={`type whitespace-nowrap ${searchCharState.hirachy.includes(item.value) ? "active" : ""}`}>
                    <div className='flex flex-column items-center gap-y-1'>
                        {item.icon}
                        <span>{item.label}</span>
                    </div>
                </div>
            })}
        </div>
        <div className='flex-wrap flex sm:hidden'>
            <div className='d-flex items-center flex-1'>
                <div className='flex items-center'>
                    <img alt="filter-icon" className='h-4 mr-2'
                        src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                    <label className='mr-2 text-base'>ประเภทกฎหมาย</label>
                </div>
                <div className='d-flex flex-1'>
                    <Select
                        aria-label="agency-select"
                        className='basic-single ml-2 flex-1'
                        classNamePrefix='select'
                        defaultValue={data[0] ?? ""}
                        options={data}
                        value={data.find((x) => searchCharState.hirachy.includes(x.value))}
                        onChange={(item) => {
                            addHirachy(item.values)
                        }}
                    />
                </div>
            </div>
        </div>
        <div className='grid grid-cols-7 sm:grid-cols-10 gap-3'>
            {dataChar.map((item, index) =>
                <div onClick={() => {
                    addChar(item)
                }} className={`char ${searchCharState.char.includes(item) ? "active" : ""} d-flex-center py-2`}>{item}</div>
            )}
        </div>
        <div className='flex gap-x-4 justify-end'>
            <button type="button" className={`button`} onClick={() => {
                clearSearchChar();
            }}>
                ล้างข้อมูล
            </button>
            <button type="button" className={`button active`} onClick={() => {
                onSearchChar();
            }}>
                ค้นหา
            </button>
        </div>
        <div className='flex justify-center mt-5'>
            <button type="button" className={`button-close`} onClick={() => {
                setSearchState({ ...searchState, isChar: false })
            }}>
                ปิดการค้นหาตามตัวอักษร
            </button>
        </div>
    </div>
}

export default Phase2Index;