import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import './rule.scss';

const Rule = () => {
  let rules = [
    `ห้ามเสนอข้อความหรือเนื้อหาอันเป็นการวิพากษ์วิจารณ์หรือพาดพิงสถาบันพระมหากษัตริย์และราชวงศ์เป็นอันขาด`,
    `ห้ามเสนอข้อความหรือเนื้อหาที่ส่อไปในทางหยาบคาย ก้าวร้าว เกินกว่าที่บรรทัดฐานของสังคมจะยอมรับได้`,
    `ห้ามเสนอข้อความหรือเนื้อหาที่ส่อไปในทาง ลามก อนาจาร รุนแรง`,
    `ห้ามเสนอข้อความอันมีเจตนาใส่ความบุคคลอื่นให้ได้รับการดูหมิ่นเกลียดชังจากบุคคลอื่น โดยไม่มีแหล่งที่มาของข้อมูลอ้างอิงที่ชัดเจน`,
    `ห้ามเสนอข้อความอันเป็นการท้าทาย ชักชวน โดยมีเจตนาก่อให้เกิดการทะเลาะวิวาท หรือก่อให้เกิดความวุ่นวายขึ้นโดยมีมูลเหตุแห่งความขัดแย้งดังกล่าวไม่ใช่การแสดงความคิดเห็นโดยเสรีเช่นวิญญูชน พึงกระทำ`,
    `ห้ามเสนอข้อความกล่าวโจมตี หรือวิพากษ์วิจารณ์ในทางเสียหายต่อศาสนา หรือคำสอนของศาสนาใดๆ ทุกศาสนา`,
    `ห้ามใช้นามแฝงอันเป็นชื่อจริงของผู้อื่นโดยมีเจตนาทำให้สาธารณชนเข้าใจผิดและเจ้าของชื่อผู้นั้นได้รับความเสียหาย หรือเสื่อมเสียชื่อเสียง`,
    `ห้ามเสนอข้อความอันอาจเป็นเหตุให้เกิดความขัดแย้งขึ้นในระหว่างสถาบันการศึกษา หรือระหว่างสังคมใดๆ`,
    `ห้ามเสนอข้อมูลส่วนตัวของผู้อื่น เช่น อีเมลหรือหมายเลขโทรศัพท์โดยมีเจตนากลั่นแกล้งให้ผู้อื่นได้รับความเดือดร้อนรำคาญโดยเฉพาะอย่าง ยิ่งกรณีเบอร์โทรศัพท์`,
    `ห้ามเสนอข้อความหรือเนื้อหาอันเป็นสิ่งที่เกี่ยวข้องกับสิ่งผิดกฎหมายหรือศีลธรรมอันดีของสังคม`,
    `ห้ามเสนอข้อความในการขายหรือโฆษณาสินค้าและบริการ`,
    // `เพื่อความสะดวกในการให้บริการ ปรับปรุงคุณภาพในการให้บริการสำรวจความนิยม และปฏิบัติตามกฎหมาย ระบบกลางฯจำเป็นต้องจัดเก็บข้อมูลของท่านบางประการ เช่น หมายเลขไอพี (IP Address) ชนิดของโปรแกรมค้น (Web Browser) บันทึกหน้าเว็บ (Web Page) เวลาเข้าเยี่ยมชม (Access Times) รวมถึงการใช้ฐานข้อมูลเดียวกับเว็บไซต์และแอบพลิเคชั่นในเครือ เช่น  Bloggang.com โดยทั้งนี้ข้อมูลที่ทาง Pantip.com จัดเก็บนั้นจะไม่ถูกนำไปเผยแพร่ต่อบุคคลที่สามในลักษณะที่จะสามารถระบุตัวบุคคลผู้เข้าใช้งานได้ แต่จัดเก็บเป็นข้อมูลผู้เข้าใช้งานโดยรวมไปเพื่อนำไปประกอบการวิเคราะห์สำรวจความนิยม เว้นแต่เป็นไปเพื่อการปฏิบัติตามกฎหมายเท่านั้น`,
  ];
  const history = useHistory();
  return (
    <div id='role-page'>
      <div className='section-banner'></div>
      <div
        className='d-flex-center flex-column w-100'
        style={{
          position: 'absolute',
          marginTop: -50,
          top: 0,
          height: 300,
          backgroundColor: '#0c4da2',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: -1,
        }}
      ></div>
      <div
        className='d-flex-center flex-column'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container>
          <div
            className='d-flex menu-top'
            style={{ justifyContent: 'space-between' }}
          >
            <div
              onClick={() => {
                history.go(-1);
              }}
              className='cursor-pointer'
            >
              <span>{'ย้อนกลับ'}</span>
            </div>
          </div>
          <div className='rule-container'>
            <div className='d-flex'>
              <label className='title'>กฎ กติกา และมารยาท</label>
            </div>
            <div className='d-flex rule-content flex-column'>
              <label className='title-content'>
                การร่วมกิจกรรมแสดงความเห็นในกระทู้
                ผู้เข้าร่วมพึงต้องปฏิบัติดังต่อไปนี้
              </label>
              <div className='d-flex flex-column'>
                {rules.map((item, index) => {
                  return (
                    <div className='d-flex' style={{ marginTop: 10 }}>
                      <div style={{ minWidth: 20 }}>{`${index + 1}`}</div>
                      <span>{`${item}`}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  
  );
};

export default Rule;
