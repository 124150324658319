import React, { useState, useEffect } from 'react';
import { Button, Select, Typography, Form, Input } from 'antd';
import { ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import { Container } from '@material-ui/core';
import './index.scss';
import CommnetDuoDot from '../iconComponent/CommnetDuoDot';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import ChaveronCircleDown from '../iconComponent/ChaveronCircleDown';
import PrincipleComment from './PrincipleComment';
import { useHistory } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import ApiSurvey from '../../api/survey';
import ApiWebBoard from '../../api/post';
import { useSelector, useDispatch } from 'react-redux';
const { TextArea } = Input;
const { Text, Link, Title } = Typography;
const SubComment = (props) => {
  const post_id = new URLSearchParams(props.location.search).get('post_id');
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const [comment, setcomment] = useState('');
  const [comments, setComments] = useState([]);
  const [posts, setPosts] = useState([]);
  const [surveyData, setSurveyData] = useState({});
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const [share, setshare] = useState({});
  const [showShare, setShowShare] = useState(false);
  useEffect(() => {
    getSurvey();
    return () => {};
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const result = await ApiWebBoard.getComment({
        post_id: post_id,
        username: userState.username,
      });
      if (result.status == 200) {
        Swal.close();
        const { comments } = result.data;
        setComments(comments);
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const handleSubmit = async () => {
    Swal.showLoading();
    try {
      const model = {
        message: comment,
        post_id: post_id,
      };
      const result = await ApiWebBoard.createComment(model);
      if (result.status == 200) {
        Swal.fire({
          title: 'สำเร็จ',
          text: 'ส่งความคิดเห็นสำเร็จ',
          icon: 'success',
          timer: 2000,
        }).then((ok) => {
          // getSurvey();
          const { data } = result.data;
          data.is_like = 0;
          data.count_like = 0;
          setComments([...comments, data]);
          setcomment('');
        });
      } else {
        throw Error('');
      }
    } catch (error) {
      Swal.close();
    }
  };

  const likePost = async (item) => {
    const model = {
      relate_id: item.id,
      table_type: 'comment',
    };
    try {
      const result = await ApiWebBoard.likePost(model);
      if (result.status == 200) {
        const likes = [...comments];
        const index = likes.findIndex((x) => x.id == item.id);
        likes[index].count_like = likes[index].is_like
          ? parseInt(likes[index].count_like) - 1
          : parseInt(likes[index].count_like) + 1;
        likes[index].is_like = !likes[index].is_like;
        setComments(likes);
        // likes[index].is_like =
      }
    } catch (error) {
      console.log(error);
      Swal.close();
    }
  };

  const commentPost = async (item) => {
    history.push(`/Reply?comment_id=${item.id}`);
  };

  return (
    <div>
      {/* <section
        style={{
          paddingTop: '60px',
          backgroundColor: '#f1f3f7',
          paddingBottom: '10px',
        }}
      >
        <Container maxWidth='xl'>
          <Text className='principle-header'>{`${surveyData?.survey_name}`}</Text>
        </Container>

      </section> */}
      <section className='section-banner'>
        <div onClick={() => history.go(-1)} className='back-btn'>
          <ArrowLeftOutlined style={{ marginRight: 6 }} />
          <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
        </div>
        <span className='project-head'>{surveyData?.project_name}</span>
      </section>

      <section className='section-detail-container'>
        <label style={{ color: '#333', fontSize: 16 }}>สนทนา</label>
      </section>
      <section className='section-detail-container'>
        <div className='icon-container-inside-2 count-comment-all'>
          <div style={{ paddingRight: '0.8pc' }}>
            <CommnetDuoDot className='comments-alt-duotone-principle' />
          </div>
          <label> {comments.filter((x) => x.id > 0).length} ความคิดเห็น</label>
        </div>
        <Select defaultValue=''>
          <option value=''>เรียงตามล่าสุด</option>
        </Select>
      </section>
      <section className='background-copy-4'></section>
      {comments.filter((x) => x.id > 0).length > 0 ? (
        <section style={{ paddingBottom: 30 }}>
          <PrincipleComment
            comments={comments.filter((x) => x.id > 0)}
            likePost={likePost}
            commentPost={commentPost}
          />
        </section>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 30,
          }}
        >
          ไม่มีการแสดงความคิดเห็น
        </div>
      )}

      <section>
        <div className='comment-bottom'>
          {/* <div>
            <FormOutlined />
            <span>เริ่มแสดงความเห็น</span>
          </div> */}
          <div style={{ display: 'flex' }}>
            <Form.Item style={{ marginBottom: 0, flex: 1 }} align='start'>
              <TextArea
                rows={2}
                cols={12}
                onChange={(e) => setcomment(e.target.value)}
                value={comment}
                style={{
                  backgroundColor: '#f1f3f7',
                  borderRadius: '10px',
                  marginTop: 10,
                }}
                placeholder={'เขียนความคิดเห็น...'}
              />
            </Form.Item>
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'flex-end',
                display: 'flex',
                marginLeft: 10,
              }}
            >
              <Button
                disabled={comment.length > 0 ? false : true}
                type={comment.length > 0 ? 'primary' : 'ghost'}
                onClick={() => handleSubmit()}
                icon={<SendOutlined />}
              ></Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubComment;
