import React, { useState, useEffect } from 'react'
import './index.scss'
import {
    Input,
    Card,
    Avatar,
    Skeleton,
    Table,
    Tag,
    Space,
    Button
} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPollH,
    faTimesCircle,
    faArrowCircleDown,
    faSync,
    faSlidersH,
    faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import ApiMaster from '../../api/master';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { ConvertDateShort } from '../../utils/date';
import { encodeBase64 } from '../../utils/convertBase64';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
    FileOutlined,
} from '@ant-design/icons';
// import TagDuoTone from "../../../iconComponent/TagDuoTone";
// import { StaticImage } from '../../../../utils/image';
// import { ConvertDateShort } from '../../../../utils/date';
// import { checkComplete, checkLeftTime, checkLeftTimeIcon, checkLeftTimeStatus } from '../../../../utils';
const { Search } = Input;

const yearList = [
    { value: null, label: "ทั้งหมด" },
    // { value: "2564", label: "2564" },
    // { value: "2563", label: "2563" },
];

const inSystemOption = [
    { value: null, label: "ทั้งหมด" },
    { value: 1, label: "ผ่านระบบ" },
    { value: 2, label: "ยังไม่ประเมิน" },
]







const EvaluateTableMobile = ({ ministryOption, type = "desktop" }) => {

    const columns = [
        // {
        //     title: 'ที่',
        //     defaultSortOrder: 'ascend',
        //     // sorter: (a, b) => a.number - b.number,
        //     render: (text, record, index) => {
        //         return (
        //             <span>{index + 1}</span>
        //         );
        //     },
        // },
        {
            title: 'กฎหมายที่ประเมินฯ',
            dataIndex: 'law_name',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.law_name?.trim()?.localeCompare(b.law_name?.trim()),
        },
        {
            title: 'ผู้รักษาการ',
            dataIndex: 'responsible',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.responsible?.trim()?.localeCompare(b.responsible?.trim()),
        },
        {
            title: 'ปีที่ต้องประเมินฯ',
            dataIndex: 'law_year_evaluate',
            sorter: (a, b) => a.law_year_evaluate - b.law_year_evaluate,
        },
        {
            title: 'ประเมินฯผ่านระบบกลางล่าสุด',
            dataIndex: 'created_date',
            // sorter: (a, b) => a.created_date - b.created_date,
            render: (text, record, index) => {
                let color = record.created_date ? 'green' : 'geekblue';
                let textCustom = record.created_date ? ConvertDateShort(record.created_date, "DD MMM YY") : 'ยังไม่ประเมินผล';
                let fileIcon = record.isconclude == "P" ? <FileOutlined /> : "";
                return (
                    <>
                        {textCustom == "ยังไม่ประเมินผล" ? <Tag style={{ whiteSpace: 'break-spaces', fontSize: 12 }} color={color}>
                            {textCustom.toUpperCase()}
                        </Tag>
                            :
                            <Link to={`/listeningDetail?survey_id=${encodeBase64(record.survey_id)}`}>
                                <Button style={{ paddingLeft: 10, paddingRight: 10, fontSize: 14 }} className="evaluate-button" color={color}>
                                    {fileIcon} {textCustom.toUpperCase()}
                                </Button>
                            </Link>
                        }
                    </>
                );
            }
        },
    ];
    const gotoSurvey = (item) => {
        history.push(`/listeningDetail?survey_id=${encodeBase64(item.survey_id)}`);
    }
    const history = useHistory();

    const [agency, setagency] = useState(null);
    const [ministry, setministry] = useState(null);
    const [inSystemSelected, setInSystem] = useState(null);
    const [yearSelected, setYear] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");
    const [law_list, setlaw_list] = useState([]);
    const [law_list_filter, setlaw_listFilter] = useState([]);
    const [ministryOptionCustom, setministryOptionCustom] = useState(ministryOption)
    const cleardata = () => {
        setSearchFilter("");
        setYear(null);
        setagency(null);
        setministry(null);
        setInSystem(null);
    };

    useEffect(() => {
        let listData = [...law_list];
        if (yearSelected != null) {
            listData = listData.filter(x => x.law_year_evaluate == yearSelected);
        }
        if (ministry != null) {
            listData = listData.filter(x => x.ministry_id == ministry);
        }
        if (inSystemSelected != null) {
            if (inSystemSelected == 1) {
                listData = listData.filter(x => x.created_date);
            } else {
                listData = listData.filter(x => !x.created_date);
            }
        }
        if (searchFilter.length > 0) {
            listData = listData.filter(x => (x.law_name.includes(searchFilter)
                || x.agency_name?.includes(searchFilter)
                || x.agency_responsible?.includes(searchFilter)
            ));
        }
        setlaw_listFilter(listData);
        return () => { };
    }, [agency, ministry, inSystemSelected, yearSelected, searchFilter]);


    useEffect(() => {
        getLawList();
        return () => {

        }
    }, [])

    const getLawList = async () => {
        try {
            Swal.showLoading();
            const law_list = await ApiMaster.law_list();
            if (law_list.status == 200) {
                const { data } = law_list.data;
                let year_group = _(data).groupBy('law_year_evaluate').map((value, key) => key).value();
                year_group = year_group.sort().reverse();
                let ministry_group = _(data).groupBy('ministry_id').map((value, key) => key).value();
                let items_ministry = [{ value: null, label: "ทั้งหมด" }];
                ministry_group.map((group, index) => {
                    const item = ministryOption.find(x => x.value == group);
                    if (item) {
                        items_ministry.push(item);
                    }
                });
                setministryOptionCustom(items_ministry)
                setlaw_list(data);
                setlaw_listFilter(data);
                year_group.filter(x => x !== "null").map((year) => {
                    yearList.push({
                        value: year,
                        label: year
                    })
                })
                Swal.close();
            }
        } catch (error) {
            Swal.close();
        }

    }

    return (
        <div id="survey-card-container-v2-mobile">
            <div className="survey-card-container">
                <Search className="search" style={{ marginBottom: 10 }} placeholder="ค้นหากฎหมาย/หน่วยงาน" onSearch={(text) => setSearchFilter(text)} />
                <Table tableLayout={'fixed'} stripe bordered columns={columns} dataSource={law_list_filter} showSorterTooltip={false} />
            </div>

            {type == "desktop" && <div className="filter-container">
                <div>
                    <Card
                        title={
                            <div className="text-filter">
                                <FontAwesomeIcon color={"#6d7481"} icon={faSlidersH} />
                                <label>ตัวกรอง</label>
                            </div>
                        }
                        extra={
                            <div className="clear-filter" onClick={() => cleardata()}>
                                <FontAwesomeIcon color={"#0c4ca3"} icon={faSync} />
                                <label>ล้าง</label>
                            </div>
                        }
                        style={{ width: "100%" }}
                        className="Rounded-Rectangle-4-web"
                    >
                        <p>ประเมินผลภายในปี</p>
                        <Select
                            className="basic-single "
                            classNamePrefix="select"
                            defaultValue={yearList[0]}
                            options={yearList}
                            value={yearList.find((x) => x.value == yearSelected)}
                            onChange={(item) => {
                                setYear(item.value);
                            }}
                        />
                        <p style={{ paddingTop: "1pc" }}> กระทรวง / หน่วยงานอิสระ</p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={ministryOptionCustom[0]}
                            value={ministryOptionCustom.find((x) => x.value == ministry)}
                            options={ministryOptionCustom}
                            onChange={(item) => {
                                setministry(item.value);
                                setagency(null);
                            }}
                        />
                        <p style={{ paddingTop: "1pc" }}>
                            ประเมินฯผ่านระบบกลาง
                        </p>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={inSystemOption[0]}
                            options={inSystemOption}
                            value={inSystemOption.find((x) => x.value == inSystemOption)}
                            onChange={(item) => {
                                setInSystem(item.value);
                            }}
                        />
                    </Card>
                </div>
            </div>
            }

        </div>
    )
}

export default EvaluateTableMobile;