import React from 'react';
import './progressBar.scss';
const ProgressBar = (props) => {
    const { bgcolor, bgContainerColor, completed, leftTime } = props;
    const containerStyles = {
        backgroundColor: bgContainerColor,
        borderRadius: 50,
    };

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        maxWidth: '100%',
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'left',
        paddingLeft: '5px',
        display: 'flex'
    };

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        alignSelf: 'center'
    };

    return (
        <div style={containerStyles} className='progress-containerStyles'>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${leftTime}`}</span>
            </div>
        </div>
    );
};

export default ProgressBar;
