import { ConvertDateShort } from '../../../../utils/date';
import { StaticImage } from '../../../../utils/image';
import './project.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import { encodeBase64 } from '../../../../utils/convertBase64';
import { useHistory } from "react-router-dom";
// const ProjectCardItem = ({ project, entitys = [], survey }) => {
//     const history = useHistory();
//     return <div className="project-container">
//         <div className="d-flex-align-center flex-1" style={{ justifyContent: 'space-between' }}>
//             <div className="d-flex-align-center">
//                 <div className="d-flex-align-center project-id-container">
//                     <span className="text-regular">เลขที่ (ID) {project.project_id}</span>
//                 </div>
//                 <div className="d-flex-align-center">
//                     <img
//                         src={StaticImage("/images/icon/calendar-alt-duotone-2.svg")}
//                         style={{ marginRight: 10 }}
//                         alt="calendar"
//                         height={20}
//                     />
//                     <span className="text-regular">
//                         {ConvertDateShort(project.createdate, "DD MMM YY")}
//                     </span>
//                 </div>
//             </div>
//             <div
//                 onClick={() => {
//                     history.push(`/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`);
//                 }}
//                 className="d-flex-align-center cursor-pointer">
//                 <img
//                     src={StaticImage("/images/login/eye-duotone-2.png")}
//                     style={{ marginRight: 10 }}
//                     alt="calendar"
//                     height={14}
//                     width={20}
//                 />
//                 <span className="text-regular text-primary" style={{ fontSize: 14 }}>ดูรายละเอียดโครงการ</span>
//             </div>
//         </div>
//         <div className="d-flex flex-1 flex-column" style={{ justifyContent: 'space-between', marginTop: 10 }}>
//             <div className="d-flex-align-center process-container">
//                 <FontAwesomeIcon icon={faBalanceScale} />
//                 <span className="entity-text text-regular">
//                     {project.entity_name ?? entitys[0]?.entity_name}
//                 </span>
//             </div>
//             <div className="project-timeline-body-bottom">
//                 <div
//                     className="d-flex"
//                     style={{ flexWrap: "wrap", marginTop: -10 }}
//                 >
//                     {entitys.map((item, index) => {
//                         let order_id = project.process_order;
//                         if (order_id == 1) {
//                             order_id = entitys[0].order_id
//                         }
//                         const findIndex = entitys.findIndex(
//                             (x) =>
//                                 x.order_id ==
//                                 (order_id ?? entitys[0].order_id)
//                         );
//                         let className = '';

//                         if (
//                             item.order_id ==
//                             (order_id ?? entitys[0].order_id)
//                         ) {
//                             className = 'active';
//                         }
//                         if (findIndex > index) {
//                             className += 'pass';
//                         }
//                         return (
//                             <div
//                                 className={`process-step d-flex-center ${className}`}
//                             >
//                                 {item.entity_abbrv}
//                             </div>
//                         );
//                     })}
//                 </div>
//             </div>
//         </div>

//     </div>
// }

const ProjectCardItem = ({ project, entitys = [], survey }) => {
    const history = useHistory();
    return <div className="p-2 !px-4 bg-white shadow-lg border-t-color[#e5e6ee] border-t">
        <div className="d-flex-align-center flex-1 items-center" style={{ justifyContent: 'space-between' }}>
            <div className='flex gap-x-1 text-[#387436] items-center'>
                <FontAwesomeIcon icon={faBalanceScale} />
                <span className="entity-text text-regular">
                    {project.entity_name ?? entitys[0]?.entity_name}
                </span>
            </div>
            <div
                onClick={() => {
                    history.push(`/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`);
                }}
                className='flex gap-x-1 text-[#387436] items-center'>
                <img
                    src={StaticImage("/images/login/eye-duotone-2.png")}
                    alt="calendar"
                    height={14}
                    width={20}
                />
                <span className="text-regular text-primary" style={{ fontSize: 14 }}>ดูรายละเอียด</span>
            </div>
        </div>
        <div className='flex'>
            <div className="project-timeline-body-bottom !mt-1">
                <div
                    className="d-flex gap-x-1"
                    style={{ flexWrap: "wrap", marginTop: -10 }}
                >
                    {entitys.map((item, index) => {
                        let order_id = project.process_order;
                        if (order_id == 1) {
                            order_id = entitys[0].order_id
                        }
                        const findIndex = entitys.findIndex(
                            (x) =>
                                x.order_id ==
                                (order_id ?? entitys[0].order_id)
                        );
                        let className = '';

                        if (
                            item.order_id ==
                            (order_id ?? entitys[0].order_id)
                        ) {
                            className = 'active';
                        }
                        if (findIndex > index) {
                            className += 'pass';
                        }
                        return (
                            <div
                                className={`process-step d-flex-center ${className}`}
                            >
                                {item.entity_abbrv}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    </div>
}

export default ProjectCardItem;