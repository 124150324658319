import React from 'react';
import { List, Space } from 'antd';
import { Card } from 'antd';
import { Progress } from 'antd';
import { Row, Col } from 'antd';
import {
  EyeOutlined,
  FormOutlined,
  TagsOutlined,
  ClockCircleOutlined,
  ClockCircleTwoTone,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './cardList.scss';
import './index.scss';
import CommentAltEdit from '../iconComponent/CommentAltEdit';
import StopWatch from '../iconComponent/StopWatch';
import TagDuoTone from '../iconComponent/TagDuoTone';
import ProgressBar from './ProgressBar';
import { ConvertDateShort } from '../../utils/date';
const defaultProps = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1,
  style: { width: '5rem', height: '5rem' },
};
const listData = [];
for (let i = 0; i < 7; i++) {
  listData.push({
    href:
      i + 18 !== 21
        ? '/images/main_images/layer-' + (i + 18) + '.png'
        : i + 18 > 21
        ? ''
        : '/images/main_images/layer-' + '21' + '.jpg',
    title: `ประเมินผลฯ พระราชบัญญัติสภาวิสาหกิจขนาดกลาง ขนาดย่อมและขนาดย่อย พ.ศ. 2563`,
    avatar: '',
    description: 'โครงการแก้ไข พ.ร.บ.รักษาความมั่นคงปลอดภัย ขจัด...',
    substyle: i + 18 > 21 ? { display: 'none' } : { display: '' },
    statusText: i + 18 > 21 ? `` : `อยู่ระหว่างการประเมิณ`,
    classChecking: i + 18 > 21 ? `` : `status-round-texts status-text`,
    strokeColor: {
      '0%': i + 18 > 21 ? '#f96b57' : '#0c4da2',
      '100%': i + 18 > 21 ? '#f96b57' : '#0c4da2',
    },
  });
}
const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const style = {
  background: '#0092ff',
  padding: '8px 0',
  fontSize: '16px',
  fontWeight: 'bold',
};

const ListeningList2 = ({ surveys }) => {
  const history = useHistory();
  return (
    <section>
      <List
        itemLayout='vertical'
        size='large'
        dataSource={surveys}
        locale={{ emptyText: 'ไม่พบข้อมูล' }}
        renderItem={(item, i) => (
          <div className='card-listening'>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <p className='text-title'>
                  <a
                    className='text-theme-blue'
                    onClick={() => {
                      history.push(
                        `/listeningDetail?survey_id=${item.survey_id}`
                      );
                    }}
                  >
                    {item.survey_name}
                  </a>
                </p>
                <p
                  dangerouslySetInnerHTML={{ __html: item.content_desc }}
                  className='text-description ql-editor'
                ></p>
              </div>
              {item.image && (
                <img
                  className='content'
                  alt='logo'
                  src={item.href}
                  // className="display-images-none"
                  onContextMenu={'return false;'}
                />
              )}
            </div>
            <div className='organize-div'>
              <div className='icon-tag-duo-tone-container'>
                <TagDuoTone className='icon-tag-duo-tone' />
                <span className='span-tags'>{item.survey_type_name}</span>
              </div>
              <div className='organize-container'>
                <img
                  src={'images/layer-18.png'}
                  className='logo'
                  oncontextmenu='return false;'
                />
                <label style={{ paddingLeft: '2px' }}>ก.ล.ต.</label>
              </div>
              <div>
                {/* <label className={item.classChecking}> {item.statusText}</label> */}
              </div>
            </div>
            <div className='progress-container'>
              <div style={{ display: 'flex', flex: 1 }}>
                <span>{ConvertDateShort(item.start_date, 'DD MMM YY')}</span>
                <section className='progress-list-filter'>
                  <ProgressBar
                    bgcolor={item.statusText !== '' ? '#387436' : '#f96b57'}
                    bgContainerColor={
                      item.statusText !== '' ? '#a1be9f' : '#fcd1cb'
                    }
                    leftTime={
                      item.date_balance < 0
                        ? 'ปิดการรับฟัง'
                        : `เหลือ ${item.date_balance + 1} วัน`
                    }
                    completed={
                      ((item.duration_day - item.date_balance) /
                        item.duration_day) *
                      100
                    }
                  />
                </section>

                {/* <Progress
                percent={50}
                showInfo={false}
                strokeColor={item.strokeColor}
                style={{
                  width: item.statusText !== "" ? 50 : 80,
                  marginTop: item.statusText !== "" ? "0px" : "3.5px",
                }}
              /> */}
                <span>
                  <span>{ConvertDateShort(item.end_date, 'DD MMM YY')}</span>
                </span>
              </div>
              <div className='icon-tag-duo-tone-container'>
                <CommentAltEdit className='icon-comment-alt-edit' />
                <label className='icon-comment-alt-label'>0</label>
              </div>
            </div>
          </div>

          // <div>
          //   <Card>
          //     {" "}
          //     <List.Item
          //       key={i}
          //       extra={<img width={272} alt="logo" src={item.href} />}
          //       actions={[
          //         <section>
          //           <span
          //             style={{
          //               color: "#0c4da2",
          //               fontWeight: "bold",
          //               fontSize: "16px",
          //             }}
          //           >
          //             <IconText
          //               icon={TagsOutlined}
          //               text="ร่างกฎหมาย"
          //               key="list-vertical-star-o"
          //             />
          //           </span>
          //         </section>,
          //         <div>
          //           <p
          //             style={{
          //               position: "relative",
          //               fontSize: "16px",
          //               fontWeight: "bold",
          //               width: "90px",
          //               height: "35px",
          //               padding: "2px 12px 3px 2px",
          //               objectFit: "contain",
          //               borderRadius: "17.5px",
          //               backgroundColor: "#f4f6f9",
          //             }}
          //           >
          //             <img
          //               src={"images/layer-18.png"}
          //               style={{ verticalAalign: "middle" }}
          //             />
          //             <label style={{ paddingLeft: "2px" }}>ก.ล.ต.</label>
          //           </p>
          //         </div>,

          //         // <IconText
          //         //   icon={""}
          //         //   text="156"
          //         //   key="list-vertical-like-o"
          //         // />,
          //       ]}
          //     >
          //       <List.Item.Meta
          //         title={
          //           <a style={{ fontSize: "24px" }} onClick={() => {

          //             history.push("/listeningDetail/" + i);
          //           }}>
          //             {item.title}
          //           </a>
          //         }
          //         description={item.description}
          //       />
          //     </List.Item>
          //   </Card>
          //   <Card
          //     style={{
          //       marginBottom: "30px",
          //       borderBottomRightRadius: "15px",
          //       borderBottomLeftRadius: "15px",
          //     }}
          //     className="shadow bg-white"
          //   >
          //     <Row>
          //       <Col span={12}>
          //         {" "}
          //         <div style={{ width: 170 }}>
          //           <Progress
          //             percent={50}
          //             showInfo={false}
          //             style={{ color: "#387436" }}
          //           />
          //           <Row>
          //             <Col span={12}>1 พย. 63</Col>
          //             <Col span={12} style={{ textAlign: "right" }}>
          //               30 พย. 63
          //             </Col>
          //           </Row>
          //         </div>
          //       </Col>
          //       <Col span={12} style={{ textAlign: "right" }}>
          //         <Row>
          //           <Col flex="auto" style={{ textAlign: "right" }}>
          //             <EyeOutlined /> 1,234
          //             <FormOutlined style={{ paddingLeft: "7px" }} /> 1,234
          //           </Col>
          //         </Row>
          //       </Col>
          //     </Row>
          //   </Card>
          // </div>
        )}
      />
    </section>
  );
};

export default ListeningList2;
