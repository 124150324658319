import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Skeleton,
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Radio,
  Modal,
  DatePicker,
  Avatar,
  Space,
  Switch,
  List,
} from 'antd';
import Select from 'react-select';
import { CheckFile, ConvertFileToBase64 } from '../../../utils/uploadfile';
import ApiUser from '../../../api/user';
import Swal from 'sweetalert2';
import { ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import AlertError from '../../../utils/AlertError';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { StaticImage } from '../../../utils/image';
import './index.scss';
import { useHistory } from 'react-router';
import ApiSurvey from '../../../api/survey';
import { ConvertDateShort } from '../../../utils/date';
import ProgressBar from '../ProgressBar';
import { checkComplete, checkLeftTime } from '../../../utils';
import ApiWebBoard from '../../../api/post';
import ButtonComponent from '../../../utils/ButtonComponent';
import AlertSuccess from '../../../utils/AlertSuccess';
import ApiMaster from '../../../api/master';
import { checkUser, checkUserBool } from '../../../utils/checkUser';
import FavoriteHistory from '../favoriteHistory';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import { encodeBase64 } from '../../../utils/convertBase64';
import ApiLaw from '../../../api/law';
const dateFormat = 'DD/MM/YYYY';

const UserProfileMobile = ({ tab, usertab }) => {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const hiddenFileInput = React.useRef(null);
  const [sexList, setsexList] = useState([]);
  const [ageList, setageList] = useState([]);
  const [educationList, seteducationList] = useState([]);
  const [provinceList, setprovinceList] = useState([]);
  const [careerList, setcareerList] = useState([]);
  const [law_group, setlaw_group] = useState([]);
  const [currentMenu, setcurrentMenu] = useState(tab == "notification" ? 5 : 1);

  useEffect(() => {
    masterLogin();
    lawGroup();
    return () => { };
  }, []);

  const formOnWeb = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];

      var name = file.name;

      const reader = new FileReader();
      Swal.showLoading();
      reader.onload = async (evt) => {
        CheckFile({
          file,
          size: 10,
          type: ['image/png', 'image/jpg', 'image/jpeg'],
        });
        const base64 = await ConvertFileToBase64(file);
        const model = {
          image: file.name,
          base64: base64,
          filename: file.name,
          type: file.type,
        };
        try {
          const update = await ApiUser.uploadImage(model);
          Swal.close();
          if (update.status == 200) {
            const { data } = update;
            dispatch({ type: 'set_user', user: data });
          }
        } catch (error) {
          AlertError('', error.response?.message);
        }
      };

      reader.readAsBinaryString(file);
    } catch (error) { }
  };

  const handleFavoriteLawGroup = async (item) => {
    let array = [];
    if (userState.favorite_law_group) {
      array = userState.favorite_law_group.split(',');
    }

    // array.push(item);
    if (array.includes(item.toString())) {
      array = array.filter((x) => x != item);
    } else {
      if (array[0] == '') {
        array[0] = item;
      } else {
        array.push(item);
      }
    }
    const model = {
      favorite_law_group: array.join(','),
    };
    try {
      const update = await ApiUser.updateFirst(model);
      if (update.status == 200) {
        const { data } = update;
        dispatch({ type: 'set_user', user: data });
      }
    } catch (error) { }
  };


  const updateNotification = async (value, key) => {
    const model = {};
    model[key] = value ? 1 : 0;
    try {
      const update = await ApiUser.updateFirst(model);
      if (update.status == 200) {
        const { data } = update;
        dispatch({ type: 'set_user', user: data });
      }
    } catch (error) { }
  };

  const masterLogin = async () => {
    const result = await ApiMaster.masterLogin();
    if (result.status) {
      const { data } = result;
      const { ageList, educationList, sexList, provinces, careerList } = data;
      setsexList(sexList);
      setageList(ageList);
      seteducationList(educationList);
      setprovinceList(provinces);
      setcareerList(careerList);
    }
  };

  const lawGroup = async () => {
    const result = await ApiMaster.lawGroup();
    if (result.status) {
      const { data } = result;
      setlaw_group(data.data);
    }
  };

  return (
    <div className='tab-menu-userprofile-page-container' style={{ paddingBottom: 30 }}>
      <input
        accept='.png,.jpg,.jpeg'
        ref={hiddenFileInput}
        onChange={handleFileChange}
        type='file'
        style={{ display: 'none' }}
      />
      <section
        className='section-banner d-flex'
        style={{ alignItems: 'center' }}
      >
        <div
          className='d-flex'
          style={{ flexDirection: 'column' }}
          onClick={() => handleClick()}
        >
          {userState.image_profile ? (
            <Avatar
              size={{
                xs: 72,
                sm: 72,
                md: 72,
                lg: 72,
                xl: 72,
                xxl: 72,
              }}
              style={{ height: 72, width: 72 }}
              // shape={'circle'}
              src={userState.image_profile}
            />
          ) : (
            // <Skeleton.Button active={true} size={72} shape={'circle'} />
            <Avatar
              size={{
                xs: 72,
                sm: 72,
                md: 72,
                lg: 72,
                xl: 72,
                xxl: 72,
              }}
              style={{ height: 72, width: 72 }}
              src={StaticImage('/images/login/user-circle-duotone-1-copy@3x.png')}
            />
          )}
          <div
            className='d-flex-center'
            style={{
              marginLeft: 50,
              marginTop: -30,
              zIndex: 2,
              height: 26,
              width: 26,
              borderRadius: 13,
              backgroundColor: '#1a57a7',
            }}
          >
            <CameraAltIcon style={{ fontSize: 16, color: 'white' }} />
          </div>
        </div>
        <div style={{ marginLeft: 20 }} className='d-flex flex-column'>
          <div className='d-flex'>
            <Space direction='horizontal'>
              <span>บัญชีผู้ใช้ :</span>
              <span>{userState.username}</span>
            </Space>
          </div>
          <div className='d-flex'>
            <Space direction='horizontal'>
              <span>ความน่าเชื่อถือ :</span>
              <div className='d-flex-center'>
                <span>
                  {userState.is_verify
                    ? 'ยืนยันตัวตนแล้ว'
                    : 'ยังไม่ได้ยืนยันตัวตน'}
                </span>
                {userState.is_verify ? (
                  <img
                    className='badge-check-duotone-01'
                    src={StaticImage('/images/login/badge-check-duotone.png')}
                  />
                ) : (
                  <img
                    className='badge-check-duotone-01'
                    src={StaticImage('/images/login/badge-check-duotone.png')}
                    style={{ opacity: 0.2 }}
                  />
                )}
              </div>
            </Space>
          </div>
        </div>
      </section>
      <div className='tab-menu-userprofile-page'>
        <div
          onClick={() => setcurrentMenu(1)}
          className={`d-flex-center flex-column ${currentMenu == 1 ? 'active' : ''
            }`}
        >
          <img
            src={StaticImage('/images/login/userprofile/user-duotone-1.png')}
          />
          <label>บัญชีผู้ใช้</label>
        </div>
        <div
          onClick={() => setcurrentMenu(2)}
          className={`d-flex-center flex-column ${currentMenu == 2 ? 'active' : ''
            }`}
        >
          <img
            src={StaticImage(
              '/images/login/userprofile/comment-alt-edit-duotone-4.png'
            )}
          />
          <label>แบบสอบถาม</label>
        </div>
        <div
          onClick={() => setcurrentMenu(3)}
          className={`d-flex-center flex-column ${currentMenu == 3 ? 'active' : ''
            }`}
        >
          <img
            src={StaticImage(
              '/images/login/userprofile/comments-alt-duotone-1.png'
            )}
          />
          <label>การสนทนา</label>
        </div>
        <div
          onClick={() => setcurrentMenu(4)}
          className={`d-flex-center flex-column ${currentMenu == 4 ? 'active' : ''
            }`}
        >
          <img
            src={StaticImage(
              '/images/login/userprofile/heart-circle-duotone-2.png'
            )}
          />
          <label>การติดตาม</label>
        </div>
        <div
          onClick={() => setcurrentMenu(5)}
          className={`d-flex-center flex-column ${currentMenu == 5 ? 'active' : ''
            }`}
        >
          <img src={StaticImage('/images/login/bell-duotone-1.png')} />
          <label>การแจ้งเตือน</label>
        </div>
      </div>
      {currentMenu == 1 && (
        <UserProfileTab
          usertab={usertab}
          user={userState}
          history={history}
          sexList={sexList}
          ageList={ageList}
          educationList={educationList}
          provinceList={provinceList}
          careerList={careerList}
        />
      )}
      {currentMenu == 2 && <SurveyHistory user={userState} history={history} />}
      {currentMenu == 3 && (
        <WebboardHistory user={userState} history={history} />
      )}
      {currentMenu == 4 && (
        <FavoriteTab
          user={userState}
          history={history}
          law_group={law_group}
          handleFavoriteLawGroup={handleFavoriteLawGroup}
        />
      )}
      {currentMenu == 5 && (
        <NotificationSetting
          updateNotification={updateNotification}
          user={userState}
        />
      )}
    </div>
  );
};

const UserProfileTab = ({
  user,
  history,
  sexList,
  ageList,
  educationList,
  provinceList,
  careerList,
  usertab = 1
}) => {
  const profileTabScroll = useRef(null);
  const executeScroll = () => profileTabScroll.current.scrollIntoView();
  const [currentTab, setcurrentTab] = useState(usertab ?? 1);
  const renderTab2 = () => {
    if (!user.is_verify) {
      return <UserProfileTab2Verify user={user} />;
    } else {
      return (
        <UserProfileTab2NotVerify user={user} executeScroll={executeScroll} />
      );
    }
  };
  return (
    <div className='userprofile-menu'>
      <div className='userprofile-tab' ref={profileTabScroll}>
        <div
          onClick={() => setcurrentTab(1)}
          className={`d-flex userprofile-tab-detail ${currentTab == 1 && 'active'
            }`}
        >
          <img
            src={StaticImage('/images/login/userprofile/user-duotone-1.png')}
          />
          <span>บัญชีผู้ใช้</span>
        </div>
        <div
          onClick={() => setcurrentTab(2)}
          className={`d-flex userprofile-tab-detail ${currentTab == 2 && 'active'
            }`}
        >
          <img
            src={StaticImage(
              '/images/login/userprofile/badge-check-duotone-copy.svg'
            )}
          />
          <span>ความน่าเชื่อถือ</span>
        </div>
        <div
          onClick={() => setcurrentTab(3)}
          className={`d-flex userprofile-tab-detail ${currentTab == 3 && 'active'
            }`}
        >
          <img
            src={StaticImage('/images/login/userprofile/file-user-duotone.svg')}
          />
          <span>ข้อมูลส่วนตัว</span>
        </div>
      </div>
      <div className='userprofile-body'>
        {currentTab == 1 && <UserProfileTab1 user={user} />}
        {currentTab == 2 && renderTab2()}
        {currentTab == 3 && (
          <UserProfileTab3
            user={user}
            sexList={sexList}
            ageList={ageList}
            educationList={educationList}
            provinceList={provinceList}
            careerList={careerList}
          />
        )}
      </div>
    </div>
  );
};

const FavoriteTab = ({ user, history, law_group, handleFavoriteLawGroup }) => {
  const [currentTab, setcurrentTab] = useState(1);

  return (
    <div className='userprofile-menu'>
      <div className='userprofile-tab'>
        <div
          onClick={() => setcurrentTab(1)}
          className={`d-flex userprofile-tab-detail ${currentTab == 1 && 'active'
            }`}
        >
          <img
            src={StaticImage(
              '/images/login/userprofile/landmark-duotone-4.svg'
            )}
          />
          <span>โครงการ</span>
        </div>
        <div
          onClick={() => setcurrentTab(2)}
          className={`d-flex userprofile-tab-detail ${currentTab == 2 && 'active'
            }`}
        >
          <img
            src={StaticImage(
              '/images/login/userprofile/books-duotone-1.svg'
            )}
          />
          <span>ฐานข้อมูลกฎหมาย</span>
        </div>
      </div>
      <div className='userprofile-body'>
        {currentTab == 1 && <FavoriteTab1 user={user} />}
        {/* {currentTab == 2 && (
          <FavoriteTab2
            user={user}
            law_group={law_group}
            handleFavoriteLawGroup={handleFavoriteLawGroup}
          />
        )} */}
        {currentTab == 2 && (
          <div className='p-2'>
            <FavoriteLaw user={user} />
          </div>
        )}
      </div>
    </div>
  );
};

const FavoriteLaw = ({ user }) => {
  const userState = useSelector((state) => state.user);
  const [laws, setLaws] = useState([]);
  const [masters, setMasters] = useState({
    agency: [],
    hirachy: [],
    category: [],
  });
  useEffect(() => {
    getData();
    getMaster();
  }, []);

  const getMaster = async () => {
    const result = await ApiLaw.getMaster();
    if (result.status == 200) {
      const { data } = result;
      // data.agency.unshift({ value: null, label: 'หน่วยงาน' });
      data.hirachy.unshift({ value: null, label: 'ศักดิ์กฎหมาย' });
      data.category.unshift({ value: null, label: 'แสดงทุกหมวด' });
      setMasters(data);
    }
  }

  const getData = async () => {
    const result = await ApiLaw.getLike();
    if (result.status == 200) {
      const { data } = result;
      console.log(data)
      setLaws(data.rows);
      // setvirtues(data.virtues)
      // setlawActive(data.rows[data.rows.length - 1]);
      // setlawGroup(data.law_group)
      // setshare({
      //   title: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
      //   subject: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
      //   shareUrl: `${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
      //   titleTW: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
      //   titleFB: `ดูกฎหมาย ได้ที่ ${window.location.origin}/DetailLawPage?table_of_law_id=${table_of_law_id}`,
      // });
    }
  }

  const likeLaw = async (item) => {
    if (checkUser(userState)) {
      const model = {
        relate_id: item.law_id,
      };
      try {
        const result = await ApiLaw.like(model);
        if (result.status == 200) {
          const likes = [...laws];
          const index = likes.findIndex((x) => x.law_id == item.law_id);
          likes[index].is_like = !likes[index].is_like;
          setLaws(likes);
        }
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    }
  };



  return <>
    {laws.map((item, index) => {
      return <ItemLawComponent likeLaw={likeLaw} master={masters} item={item} key={index} />
    })}
  </>
};

const ItemLawComponent = ({ item, master, likeLaw }) => {


  const history = useHistory();
  return <div className='law-container mb-5 d-flex'>
    <div></div>
    <div className='w-full flex flex-col gap-y-2'>
      <div className='flex justify-between gap-y-2 items-center'>
        <div className='d-flex items-center justify-between'>
          <div onClick={() => {
            history.push(`/DetailLawPage?table_of_law_id=${item.table_of_law_id}`);
          }} className='title-name cursor-pointer line-clamp-3 !text-sm' dangerouslySetInnerHTML={{ __html: item.law_name_th }}></div>
        </div>
        <div class="group relative px-2 py-2 rounded-full cursor-pointer" style={{
          backgroundColor: item.is_like ? '#3562fd' : "#fff"
        }}>
          <button
            aria-label="like-button"
            style={{
              opacity: item.is_like ? 1 : 0.5,

            }}
            onClick={() => likeLaw(item)}
          >
            {
              item.is_like ? <img className='h-4'
                alt="like-icon"
                src={StaticImage('/images/phase2icon/heart-duotone-gray.svg')} />
                : <img alt="like-active-icon" className='h-4'
                  src={StaticImage('/images/phase2icon/heart-duotone.svg')} />
            }
          </button>
          <div class="hidden group-hover:block ...">ถูกใจ</div>
        </div>
      </div>
      <div className='d-flex items-center justify-between'>
        <div className='d-flex text-gray whitespace-nowrap items-center h-fit sm:mt-1'>
          <img alt="clock-icon" className='h-4 mr-2'
            src={StaticImage('/images/phase2icon/clock-duotone.svg')} />
          <span>
            Update : {ConvertDateShort(item.updatedate, 'DD MMM YY')}
          </span>
        </div>

        {/* {renderRivision(item)} */}
      </div>
      <div className='description line-clamp-3 !text-sm' dangerouslySetInnerHTML={{ __html: item.content_all }}>

      </div>
      {/* {
        renderVirtue(item)
      } */}

      <div className='flex flex-col sm:flex-row mt-2 flex-wrap gap-y-2'>
        <div className='d-flex mr-5'>
          <div className='flex items-center'>
            <img alt="category-icon" className='h-4 mr-2'
              src={StaticImage('/images/phase2icon/books-duotone-1.svg')} />
            <span>
              {master.category.length > 0 ?
                (master.category.filter(x => item.category_id.split(',').includes(x.value?.toString())).map(x => x.label) ?? "-") : "-"}
            </span>
          </div>
        </div>
        <div className='flex items-center'>
          <img alt="agency-icon" className='h-4 mr-2'
            src={StaticImage('/images/phase2icon/building-columns-duotone.svg')} />
          <span>หน่วยงาน :&nbsp;
            {master.agency.length > 0 &&
              master.agency.filter(x => item.agency_response.split(',').includes(x.value?.toString())).map(x => x.label_short ?? x.label)?.join(',')}
          </span>
        </div>
      </div>

    </div>
  </div >
}

const UserProfileTab1 = ({ user, history }) => {
  const [name_on_web, setname_on_web] = useState('');
  const dispatch = useDispatch();
  const onEditName = async () => {
    const model = {
      name_on_web,
    };
    try {
      const result = await ApiUser.checknameV2(name_on_web);
      if (result.status == 200) {
        const update = await ApiUser.updateFirst(model);
        if (update.status == 200) {
          const user = update.data;
          dispatch({ type: 'set_user', user: user });
          AlertSuccess('', 'บันทึกสำเร็จ');
        }
      }
    } catch (error) {
      const { data, status } = error.response;
      AlertError('', data?.message);
    }
  };
  return (
    <div className='userprofile-body-1'>
      <div className="d-flex-center">
        <img
          height={120}
          width={70}
          src={StaticImage(
            '/images/login/userprofile/digital-id-resize.svg'
          )}
        />
      </div>
      <Form
        layout={'vertical'}
        name='form-name-on-web'
        initialValues={{ remember: true }}
        onFinish={() => onEditName()}
        // ref={formOnWeb}
        style={{ padding: 15 }}
      // onFinishFailed={onFinishFailed}
      >
        <Form.Item name='username'>
          <Input
            disabled
            size='large'
            prefix={
              <img
                src={StaticImage(
                  '/images/login/userprofile/user-duotone-grey.svg'
                )}
                style={{ paddingRight: '1pc' }}
              />
            }
            className='input-theme-small'
            defaultValue={user.username}
            placeholder={'Username'}
          />
        </Form.Item>

        <Form.Item name='password'>
          <Input
            disabled
            size='large'
            className='input-theme-small'
            type='password'
            prefix={
              <img
                src={StaticImage('/images/login/key-duotone.png')}
                style={{ paddingRight: '1pc' }}
              />
            }
            defaultValue={user.password}
            placeholder={'Password'}
          />
        </Form.Item>
        <Form.Item name='email'>
          <Input
            disabled
            size='large'
            className='input-theme-small'
            prefix={
              <img
                src={StaticImage(
                  '/images/login/envelope-open-text-duotone-3.png'
                )}
                style={{ paddingRight: '1pc' }}
              />
            }
            defaultValue={user.email}
            placeholder={'Email'}
          />
        </Form.Item>
        {/* <Form.Item
          className='flex-1'
          name='name_on_web'
          rules={[
            {
              required: true,
              message: 'กรอกชื่อที่ต้องการโชว์ในระบบ',
            },
          ]}
          initialValue={user.name_on_web}
        >
          <Input
            size='large'
            className='input-theme-small'
            prefix={
              <img
                src={StaticImage(
                  '/images/login/userprofile/user-duotone-grey.svg'
                )}
                style={{ paddingRight: '1pc' }}
              />
            }
            value={name_on_web}
            onChange={(e) => setname_on_web(e.target.value)}
            defaultValue={user.name_on_web}
            placeholder={'ชื่อที่ใช้แสดงในระบบ'}
          />
        </Form.Item> */}
        {/* <Row>
          <ButtonComponent
            type='submit'
            className='button-theme-blue-45'
            color={'info'}
            style={{ marginRight: 20, float: 'left' }}
            disabled={!name_on_web.length > 0}
            text='บันทึก'
          />
        </Row> */}
        <div
          onClick={() => {
            window.open('https://accounts.egov.go.th/Citizen/Account/Login', '_blank');
          }}
        >
          <label className='cursor-pointer'>
            คลิกเพื่อ แก้ไขรหัสผ่านหรืออีเมล สามารถจัดการได้ที่ Digital ID
          </label>
        </div>
      </Form>
    </div>
  );
};

const UserProfileTab2Verify = ({ user }) => {
  return (
    <>
      <div className='user-profile-body-2'>
        <Space direction='vertical' className='w-100'>
          <div className='d-flex-center'>
            <span className='title'>ความน่าเชื่อถือของผู้ใช้</span>
          </div>
          <div className='d-flex-center'>
            <section>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <section>
                  <img
                    src={StaticImage('/images/login/badge-check-duotone.png')}
                    className='badge-check-duotone-userprofile'
                    style={{ marginLeft: 75 }}
                  />
                  <img
                    height={70}
                    src={StaticImage(
                      '/images/login/user-circle-duotone-1-copy.png'
                    )}
                    className='icon-user'
                  />
                </section>
              </div>
            </section>
          </div>
          <div className='d-flex' style={{ marginTop: 20 }}>
            <img
              style={{ marginRight: 10, height: 20, width: 20 }}
              src={StaticImage('/images/login/badge-check-duotone.png')}
            />
            <label>เลขที่บัตรประชาชน</label>
          </div>
          <div className='d-flex'>
            <img
              style={{ marginRight: 10, height: 20, width: 20 }}
              src={StaticImage('/images/login/badge-check-duotone.png')}
            />
            <label>ชื่อ</label>
          </div>
          <div className='d-flex'>
            <img
              style={{ marginRight: 10, height: 20, width: 20 }}
              src={StaticImage('/images/login/badge-check-duotone.png')}
            />
            <label>นามสกุล</label>
          </div>
          <div className='d-flex'>
            <img
              style={{ marginRight: 10, height: 20, width: 20 }}
              src={StaticImage('/images/login/badge-check-duotone.png')}
            />
            <label>วัน เดือน ปีเกิด</label>
          </div>
          <div className='d-flex'>
            <img
              style={{ marginRight: 10, height: 20, width: 20 }}
              src={StaticImage('/images/login/badge-check-duotone.png')}
            />
            <label>รหัสเลเซอร์</label>
          </div>
        </Space>
      </div>
    </>
  );
};

const UserProfileTab2NotVerify = ({ user, executeScroll }) => {
  const dispatch = useDispatch();
  const [currentTab, setcurrentTab] = useState(1);
  const [idcard, setidcard] = useState('');
  const [password, setpassword] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [dateOfBirth, setdateOfBirth] = useState(
    moment(
      moment()
        .add(543 - 7, 'y')
        .format(dateFormat),
      dateFormat
    )
  );
  const [laserCode1, setlaserCode1] = useState('');
  const [laserCode2, setlaserCode2] = useState('');
  const [laserCode3, setlaserCode3] = useState('');
  const [prefix, setprefix] = useState(1);
  const onFinish = async (values) => {
    const dateSplit = moment(dateOfBirth).format(dateFormat).split('/');
    const model = {
      citizenId: idcard,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: `${parseInt(dateSplit[2] - 543)}${dateSplit[1]}${dateSplit[0]
        }`,
      laserCode: laserCode1 + laserCode2 + laserCode3,
      password: password,
      prefix: prefix,
    };
    try {
      Swal.showLoading();
      const result = await ApiUser.lazorCode(model);
      if (result.status == 200) {
        const { data } = result;
        dispatch({ type: 'set_user', user: data });
      }
      Swal.fire('', 'ยืนยันตัวตนสำเร็จ', 'success').then((ok) => { });
    } catch (error) {
      const { data, status } = error.response;
      Swal.fire(data.message, '', 'error');
    }
  };

  return (
    <>
      {currentTab == 1 && (
        <div className='user-profile-body-2'>
          <div className='d-flex-center' style={{ marginTop: 20 }}>

            <div className='container-title'>
              <img
                height={40}
                width={40}
                style={{ marginBottom: 10 }}
                src={StaticImage(
                  '/images/login/userprofile/sim-card-duotone.svg'
                )}
              />
              <span style={{ fontSize: 18 }} className='title'>ยกระดับความน่าเชื่อถือของผู้ใช้</span>
              <span className='title'>
                ด้วยการยืนยันตัวบุคคลกับกรมการปกครองผ่านระบบ Digital ID
              </span>
            </div>
          </div>
          <Form
            onFinish={onFinish}
            layout={'vertical'}
            name='form-name-on-web'
            initialValues={{ remember: true }}
            // ref={formOnWeb}
            style={{ marginTop: 20 }}
          // onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={
                <label>
                  เลขที่บัตรประชาชน <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                maxLength={13}
                label
                size='large'
                className='input-theme-small'
                onChange={({ target }) => setidcard(target.value)}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  ยืนยันรหัสผ่าน (Digital ID) <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                required
                label
                size='large'
                type='password'
                className='input-theme-small'
                onChange={({ target }) => {
                  setpassword(target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  ชื่อ <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                required
                label
                size='large'
                type='text'
                className='input-theme-small'
                onChange={({ target }) => {
                  setfirstName(target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  นามสกุล <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                required
                label
                size='large'
                type='text'
                className='input-theme-small'
                onChange={({ target }) => {
                  setlastName(target.value);
                }}
              />
            </Form.Item>

            {/* <label style={{ marginBottom: 15 }} className='title'>
            ข้อมูลเพื่อยืนยันตัวบุคคล
          </label> */}
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'วัน/เดือน/ปีเกิด',
                },
              ]}
              label={
                <label>
                  วัน/เดือน/ปีเกิด <span className='text-danger'>*</span>
                </label>
              }
            >
              <DatePicker
                className='input-theme-small w-100'
                locale={locale}
                format={dateFormat}
                value={dateOfBirth}
                onChange={(date) => {
                  if (date) {
                    setdateOfBirth(date);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  รหัสเลเซอร์ <span className='text-danger'>*</span>
                </label>
              }
            >
              <div className='d-flex'>
                <Input
                  style={{ marginRight: 10 }}
                  required
                  label
                  size='large'
                  type='text'
                  maxLength={3}
                  className='input-theme-small'
                  onChange={({ target }) => {
                    setlaserCode1(target.value);
                  }}
                />
                <Input
                  style={{ marginRight: 10 }}
                  required
                  label
                  size='large'
                  type='text'
                  maxLength={7}
                  className='input-theme-small'
                  onChange={({ target }) => {
                    setlaserCode2(target.value);
                  }}
                />
                <Input
                  required
                  label
                  size='large'
                  type='text'
                  maxLength={2}
                  className='input-theme-small'
                  onChange={({ target }) => {
                    setlaserCode3(target.value);
                  }}
                />
              </div>
            </Form.Item>
            <div style={{ marginTop: 20 }}>
              <img src={StaticImage('/images/login/id-card.png')} />
            </div>
            <div className='w-100' style={{ marginTop: 20 }}>
              <ButtonComponent
                type='submit'
                className='button-theme-blue-45 w-100'
                text='ยืนยันตัวตน'
                buttonStyle={{
                  fontSize: '12px !important',
                }}
              />
            </div>
          </Form>
        </div>
      )}
      {currentTab == 2 && (
        <div className='user-profile-body-2'>
          <div
            onClick={() => {
              executeScroll();
              setcurrentTab(1);
            }}
            className='d-flex'
            style={{ alignItems: 'center' }}
          >
            <ArrowLeftOutlined
              size={14}
              style={{ marginRight: 6, color: '#0c4ea4' }}
            />
            <label style={{ fontSize: 14 }}>ย้อนกลับ</label>
          </div>

          <div className='d-flex-center' style={{ marginTop: 20 }}>
            <img
              height={23}
              width={31}
              src={StaticImage(
                '/images/login/userprofile/sim-card-duotone.svg'
              )}
            />
            <div className='container-title'>
              <span className='title'>ยกระดับความน่าเชื่อถือของผู้ใช้</span>
              <span className='title'>
                ด้วยการยืนยันตัวบุคคลกับกรมการปกครองผ่านระบบ Digital ID
              </span>
            </div>
          </div>
          <Form
            onFinish={onFinish}
            layout={'vertical'}
            name='form-name-on-web'
            initialValues={{ remember: true }}
            // ref={formOnWeb}
            style={{ marginTop: 20 }}
          // onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={
                <label>
                  เลขที่บัตรประชาชน <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                maxLength={13}
                label
                size='large'
                className='input-theme-small'
                onChange={({ target }) => setidcard(target.value)}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  ยืนยันรหัสผ่าน (Digital ID) <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                required
                label
                size='large'
                type='password'
                className='input-theme-small'
                onChange={({ target }) => {
                  setpassword(target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  ชื่อ <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                required
                label
                size='large'
                type='text'
                className='input-theme-small'
                onChange={({ target }) => {
                  setfirstName(target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  นามสกุล <span className='text-danger'>*</span>
                </label>
              }
            >
              <Input
                required
                label
                size='large'
                type='text'
                className='input-theme-small'
                onChange={({ target }) => {
                  setlastName(target.value);
                }}
              />
            </Form.Item>

            {/* <label style={{ marginBottom: 15 }} className='title'>
              ข้อมูลเพื่อยืนยันตัวบุคคล
            </label> */}
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'วัน/เดือน/ปีเกิด',
                },
              ]}
              label={
                <label>
                  วัน/เดือน/ปีเกิด <span className='text-danger'>*</span>
                </label>
              }
            >
              <DatePicker
                className='input-theme-small w-100'
                locale={locale}
                format={dateFormat}
                value={dateOfBirth}
                onChange={(date) => {
                  if (date) {
                    setdateOfBirth(date);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <label>
                  รหัสเลเซอร์ <span className='text-danger'>*</span>
                </label>
              }
            >
              <div className='d-flex'>
                <Input
                  style={{ marginRight: 10 }}
                  required
                  label
                  size='large'
                  type='text'
                  maxLength={3}
                  className='input-theme-small'
                  onChange={({ target }) => {
                    setlaserCode1(target.value);
                  }}
                />
                <Input
                  style={{ marginRight: 10 }}
                  required
                  label
                  size='large'
                  type='text'
                  maxLength={7}
                  className='input-theme-small'
                  onChange={({ target }) => {
                    setlaserCode2(target.value);
                  }}
                />
                <Input
                  required
                  label
                  size='large'
                  type='text'
                  maxLength={2}
                  className='input-theme-small'
                  onChange={({ target }) => {
                    setlaserCode3(target.value);
                  }}
                />
              </div>
            </Form.Item>
            <div style={{ marginTop: 20 }}>
              <img src={StaticImage('/images/login/id-card.png')} />
            </div>
            <div className='w-100' style={{ marginTop: 20 }}>
              <ButtonComponent
                type='submit'
                className='button-theme-blue-45 w-100'
                text='ยืนยันตัวตน'
                buttonStyle={{
                  fontSize: '12px !important',
                }}
              />
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

const UserProfileTab3 = ({
  user,
  history,
  sexList,
  ageList,
  educationList,
  provinceList,
  careerList,
}) => {
  const dispatch = useDispatch();
  const [position, setposition] = useState('');
  const [company, setcompany] = useState('');
  const [name_on_web, setname_on_web] = useState('');
  const [is_show_name, setis_show_name] = useState(1);
  const [favorite_law_group, setfavorite_law_group] = useState([]);
  const [age_range, setage_range] = useState(0);
  const [sex, setsex] = useState(0);
  const [education, seteducation] = useState(0);
  const [old_address, setold_address] = useState(0);
  const [current_address, setcurrent_address] = useState(0);
  const [career, setcareer] = useState(0);
  const [auto_answer, setauto_answer] = useState(1);
  useEffect(() => {
    setcompany(user.company);
    setposition(user.position);
    return () => { };
  }, []);

  const onSubmit = async () => {
    const model = {
      name_on_web: name_on_web,
      is_first: 0,
      is_show_name,
      age_range: age_range?.value,
      sex: sex?.value,
      education: education?.value,
      old_address: old_address?.value,
      current_address: current_address?.value,
      auto_answer: auto_answer ?? user.auto_answer,
      career: career?.value,
      position,
      company,
    };
    try {
      if (name_on_web && user.name_on_web !== name_on_web) {
        const result = await ApiUser.checknameV2(name_on_web);
        if (result.status == 200) {
          const update = await ApiUser.updateFirst(model);
          if (update.status == 200) {
            const user = update.data;
            dispatch({ type: 'set_user', user: user });
            AlertSuccess('', 'บันทึกสำเร็จ');
          }
        }
      } else {
        delete model.name_on_web;
        const update = await ApiUser.updateFirst(model);
        if (update.status == 200) {
          const user = update.data;
          dispatch({ type: 'set_user', user: user });
          AlertSuccess('', 'บันทึกสำเร็จ');
        }
      }
    } catch (error) {
      const { data, status } = error.response;
      AlertError('', data?.message);
    }
  };

  const onChangeAnswer = (e) => {
    setauto_answer(e.target.value);
  };

  const onChangeShowName = (e) => {
    setis_show_name(e.target.value);
  };

  return (
    <div className='userprofile-body-1'>
      <Form
        layout={'vertical'}
        name='form-name-on-web'
        initialValues={{ remember: true }}
        // ref={formOnWeb}
        style={{ padding: 15 }}
      // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<label>ชื่อที่ใช้แสดงในระบบ</label>}
          className='flex-1'
          name='name_on_web'
          rules={[
            {
              required: true,
              message: 'กรอกชื่อที่ต้องการโชว์ในระบบ',
            },
          ]}
          initialValue={user.name_on_web}
        >
          <Input
            size='large'
            className='input-theme-small'
            prefix={
              <img
                src={StaticImage(
                  '/images/login/userprofile/user-duotone-grey.svg'
                )}
                style={{ paddingRight: '1pc' }}
              />
            }
            value={name_on_web}
            onChange={(e) => setname_on_web(e.target.value)}
            defaultValue={user.name_on_web}
            placeholder={'ตั้งชื่อที่ใช้แสดงความเห็นในเว็บบอร์ด'}
          />
        </Form.Item>
        <Form.Item
          label={<label>ตั้งค่าการแสดงชื่อผู้ตอบในกระดานสนทนา</label>}
        >
          <Radio.Group
            defaultValue={user.is_show_name}
            onChange={onChangeShowName}
          >
            <Radio value={1}>แสดงชื่อ</Radio>
            <Radio value={0}>ไม่แสดงชื่อ</Radio>
          </Radio.Group>
        </Form.Item>
        <Divider style={{ marginBottom: 30, marginTop: 30 }} />
        <Form.Item label={<label>ช่วงอายุ</label>}>
          <Select
            className='basic-single'
            classNamePrefix='select'
            size={'small'}
            defaultValue={{
              label: ageList.find((x) => x.valuecode == user.age_range)
                ?.valuetext,
              value: ageList.find((x) => x.valuecode == user.age_range)
                ?.valuecode,
            }}
            onChange={(e) => setage_range(e)}
            options={ageList.map((item) => {
              return {
                label: item.valuetext,
                value: item.valuecode,
              };
            })}
          />
        </Form.Item>
        <Form.Item label={<label>เพศ</label>}>
          <Select
            className='basic-single'
            classNamePrefix='select'
            defaultValue={{
              label: sexList.find((x) => x.valuecode == user.sex)?.valuetext,
              value: sexList.find((x) => x.valuecode == user.sex)?.valuecode,
            }}
            onChange={(e) => setsex(e)}
            options={sexList.map((item) => {
              return {
                label: item.valuetext,
                value: item.valuecode,
              };
            })}
          />
        </Form.Item>
        <Form.Item label={<label>การศึกษา</label>}>
          <Select
            className='basic-single'
            classNamePrefix='select'
            defaultValue={{
              label: educationList.find((x) => x.valuecode == user.education)
                ?.valuetext,
              value: educationList.find((x) => x.valuecode == user.education)
                ?.valuecode,
            }}
            onChange={(e) => seteducation(e)}
            options={educationList.map((item) => {
              return {
                label: item.valuetext,
                value: item.valuecode,
              };
            })}
          />
        </Form.Item>
        <label style={{ marginBottom: 15 }} className='title'>
          พื้นที่ของผู้ที่ได้รับผลกระทบ
        </label>
        <Form.Item label={<label>ภูมิลำเนาเดิม</label>}>
          <Select
            className='basic-single'
            classNamePrefix='select'
            defaultValue={{
              label: provinceList.find((x) => x.code == user.old_address)
                ?.name_th,
              value: provinceList.find((x) => x.code == user.old_address)?.code,
            }}
            onChange={(e) => setold_address(e)}
            options={provinceList.map((item) => {
              return {
                label: item.name_th,
                value: item.code,
              };
            })}
          />
        </Form.Item>
        <Form.Item label={<label>ที่อยู่ปัจจุบัน</label>}>
          <Select
            className='basic-single'
            classNamePrefix='select'
            defaultValue={{
              label: provinceList.find((x) => x.code == user.current_address)
                ?.name_th,
              value: provinceList.find((x) => x.code == user.current_address)
                ?.code,
            }}
            onChange={(e) => setcurrent_address(e)}
            options={provinceList.map((item) => {
              return {
                label: item.name_th,
                value: item.code,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          label={<label>ให้ระบบดึงข้อมูลไปใส่เมื่อถูกถามคำถามเดิมซ้ำๆ</label>}
        >
          <Radio.Group
            defaultValue={user.auto_answer}
            onChange={onChangeAnswer}
          >
            <Radio value={1}>อนุญาต</Radio>
            <Radio value={0}>ยังก่อน</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={<label>อาชีพ</label>}>
          <Select
            className='basic-single'
            classNamePrefix='select'
            defaultValue={{
              label: careerList.find((x) => x.valuecode == user.career)
                ?.valuetext,
              value: careerList.find((x) => x.valuecode == user.career)
                ?.valuecode,
            }}
            onChange={(e) => setcareer(e)}
            options={careerList.map((item) => {
              return {
                label: item.valuetext,
                value: item.valuecode,
              };
            })}
          />
        </Form.Item>
        <Divider style={{ marginBottom: 30, marginTop: 30 }} />
        <label style={{ marginBottom: 15 }} className='title'>
          ข้อมูลสำหรับหน่วยงานภาครัฐ
        </label>
        <Form.Item label={<label>ตำแหน่งงาน</label>}>
          <Input
            label
            size='large'
            className='input-theme-small'
            defaultValue={user.position}
            onChange={(e) => setposition(e.target.value)}
          />
        </Form.Item>
        <Form.Item label={<label>หน่วยงานสังกัด</label>}>
          <Input
            label
            size='large'
            className='input-theme-small'
            defaultValue={user.company}
            onChange={(e) => setcompany(e.target.value)}
          />
        </Form.Item>
        <div className='d-flex'>
          <ButtonComponent
            text='บันทึกข้อมูล'
            onClick={() => {
              onSubmit();
            }}
            buttonStyle={{ marginTop: 10, marginBottom: 20 }}
            className={'button-theme-blue-45'}
          />
        </div>
      </Form>
    </div>
  );
};

const FavoriteTab1 = ({ user }) => {
  return <FavoriteHistory />;
};

const FavoriteTab2 = ({ user, law_group, handleFavoriteLawGroup }) => {
  return (
    <div style={{ padding: 20 }}>
      <div
        className='flex-wrap login-name-list-scholler'
        style={{
          justifyContent: 'space-between',
          overflow: 'hidden',
          maxHeight: 'inherit',
        }}
      >
        {law_group.map((item) => {
          return (
            <div
              style={{ marginTop: 10 }}
              className='law-group-main'
              onClick={() => handleFavoriteLawGroup(item.lawgroup_id)}
            >
              <center
                style={{
                  width: 100,
                  opacity:
                    checkUserBool(user) &&
                      user.favorite_law_group
                        ?.split(',')
                        .includes(item.lawgroup_id.toString())
                      ? 1
                      : 0.5,
                }}
                class='d-flex flex-column d-flex-center'
              >
                <div className='law-group-container d-flex-center'>
                  <div className='d-flex-center'>
                    {item.image ? (
                      <Avatar
                        className='avatar-object-contain'
                        size={{
                          xs: 75,
                          sm: 75,
                          md: 75,
                          lg: 75,
                          xl: 75,
                          xxl: 75,
                        }}
                        style={{
                          height: 75,
                          width: 75,
                        }}
                        // shape={'circle'}
                        src={item.image}
                      />
                    ) : (
                      <Skeleton.Button
                        style={{
                          backgroundColor:
                            checkUserBool(user) &&
                              user.favorite_law_group
                                ?.split(',')
                                .includes(item.lawgroup_id.toString())
                              ? '#0c4da2'
                              : '#efefef',
                        }}
                        active={true}
                        size={100}
                        shape={'circle'}
                      />
                    )}
                  </div>
                </div>
                <span style={{ fontSize: 15 }}>{item.lawgroup_name}</span>
              </center>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const NotificationSetting = ({ updateNotification, user }) => {
  return (
    <div className='notification-menu'>
      <div className='d-flex notification-menu-header'>
        <Space direction='horizontal'>
          <img src={StaticImage('/images/login/bell-duotone-1.png')} />
          <label>ตั้งค่าการแจ้งเตือน</label>
        </Space>
      </div>
      <div className='notification-setting-card'>
        <span className='left'>
          โครงการ/การรับฟัง ที่เปิดให้แสดงความคิดเห็นในระบบ
        </span>
        <Switch
          size={'default'}
          title={'เปิดการแจ้งเตือน'}
          checked={user.is_notification_survey_open == 1 ? true : false}
          onChange={(value) =>
            updateNotification(value, 'is_notification_survey_open')
          }
        />
      </div>
      <div className='notification-setting-card'>
        <span className='left'>
          โครงการ/การรับฟัง ที่ปิดให้แสดงความคิดเห็นในระบบ
        </span>
        <Switch
          size={'default'}
          title={'เปิดการแจ้งเตือน'}
          checked={user.is_notification_survey_close == 1 ? true : false}
          onChange={(value) =>
            updateNotification(value, 'is_notification_survey_close')
          }
        />
      </div>

      <div className='notification-setting-card'>
        <span className='left'>โครงการ/การรับฟัง ที่มีการสรุปผล</span>
        <Switch
          size={'default'}
          title={'เปิดการแจ้งเตือน'}
          checked={user.is_notification_summary == 1 ? true : false}
          onChange={(value) =>
            updateNotification(value, 'is_notification_summary')
          }
        />
      </div>
      {/* <div className='notification-setting-card'>
        <span className='left'>กฎหมายที่ใกล้ประเมินผลสัมฤทธิ์</span>
        <Switch
          size={'default'}
          title={'เปิดการแจ้งเตือน'}
          checked={user.is_notification_evaluate == 1 ? true : false}
          onChange={(value) =>
            updateNotification(value, 'is_notification_evaluate')
          }
        />
      </div>
      <div className='notification-setting-card'>
        <span className='left'>โครงการ/การรับฟัง ที่มีการอัพเดทสถานะ</span>
        <Switch
          size={'default'}
          title={'เปิดการแจ้งเตือน'}
          checked={user.is_notification_project_status == 1 ? true : false}
          onChange={(value) =>
            updateNotification(value, 'is_notification_project_status')
          }
        />
      </div>
      <div className='notification-setting-card'>
        <span className='left'>Webboard</span>
        <Switch
          size={'default'}
          title={'เปิดการแจ้งเตือน'}
          checked={user.is_notification_webboard == 1 ? true : false}
          onChange={(value) =>
            updateNotification(value, 'is_notification_webboard')
          }
        />
      </div>
    */}
    </div>
  );
};

const SurveyHistory = ({ user, history }) => {
  const lateArray = [
    { label: 'ล่าสุด', value: 'last' },
    { label: 'ส่งแล้ว', value: 'sended' },
    { label: 'ยังไม่ส่ง', value: 'unsend' },
  ];
  const [surveyHistory, setsurveyHistory] = useState([]);
  const [surveyHistoryFilter, setsurveyHistoryFilter] = useState([]);
  useEffect(() => {
    getHistory();
    return () => { };
  }, []);

  const getHistory = async () => {
    Swal.showLoading();
    try {
      const result = await ApiSurvey.history();
      if (result.status == 200) {
        const { data } = result.data;
        setsurveyHistory(data);
        setsurveyHistoryFilter(data);
      }
    } catch (error) { }
    Swal.close();
  };

  const renderStatus = (item) => {
    let component = '';
    if (item.answer_type == 'answer_draft') {
      component = (
        <div className='status-card'>
          <img
            src={StaticImage('/images/login/vote-nay-duotone.png')}
            className='alter-vote-duotone'
          />
          <div style={{ color: '#f96b57' }} className='alter-send-status'>
            {'ยังไม่ได้ส่ง'}
          </div>
        </div>
      );
    } else {
      component = (
        <div className='status-card'>
          <img
            src={StaticImage('/images/login/vote-yea-duotone.png')}
            className='alter-vote-duotone'
          />
          <div style={{ color: '#0c4da2' }} className='alter-send-status'>
            {'ส่งแล้ว'}
          </div>
        </div>
      );
    }
    return component;
  };

  const handleFilter = (value) => {
    let filters = [...surveyHistory];
    if (value == 'unsend') {
      filters = filters.filter((x) => x.answer_type == 'answer_draft');
    } else if (value == 'sended') {
      filters = filters.filter((x) => x.answer_type != 'answer_draft');
    }
    setsurveyHistoryFilter(filters);
  };

  return (
    <div className='survey-history-menu'>
      <div className='d-flex survey-history-menu-header'>
        <div
          className='d-flex'
          style={{ justifyContent: 'space-between', width: '100%' }}
        >
          <Space className='flex-1' direction='horizontal'>
            <img
              src={StaticImage(
                '/images/login/userprofile/comment-alt-edit-duotone-4.png'
              )}
            />
            <label>ประวัติการตอบแบบสอบถาม</label>
          </Space>
          <div style={{ width: 200 }}>
            <Select
              className='basic-single'
              classNamePrefix='select'
              defaultValue={lateArray[0].value}
              options={lateArray}
              onChange={(item) => handleFilter(item.value)}
            />
          </div>
        </div>
      </div>

      <List
        itemLayout='vertical'
        size='large'
        style={{ padding: 20 }}
        pagination={{
          onChange: (page) => { },
          pageSize: 5,
        }}
        dataSource={surveyHistoryFilter}
        renderItem={(res, i) => (
          <div className='survey-card'>
            <div className='d-flex survey-card-header'>
              <div className='d-flex-center'>
                <img src={StaticImage('/images/login/clock-solid.png')} />
                <span>{ConvertDateShort(res.answer_date, 'DD MMM YY')}</span>
              </div>
              <div
                onClick={() => {
                  history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
                }}
                className='d-flex-center'
              >
                <img
                  className='img-eye'
                  src={StaticImage('/images/login/eye-duotone-2.png')}
                />
                <label style={{ fontSize: 14, fontWeight: 'bold' }}>
                  ดูรายละเอียด
                </label>
              </div>
            </div>
            <div className='survey-card-body'>
              <div className='d-flex flex-column'>
                <Space direction='vertical'>
                  <label className='project-name'> {res.project_name} </label>
                  <label className='survey-name'>{res.survey_name}</label>
                </Space>
                <div
                  style={{
                    height: 3,
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: '#eff1f5',
                  }}
                ></div>
                <div
                  className='d-flex agency-card'
                  style={{ justifyContent: 'space-between' }}
                >
                  {res.agency_id > 0 ? (
                    <div
                      className='organize-container'
                      style={{ margin: 0, maxWidth: 100 }}
                    >
                      <img
                        alt="agency_image"
                        src={res.agency_image_uploaded ?? res.agency_image}
                        className="logo"
                        oncontextmenu="return false;"
                        onError={(i) => (i.target.style.display = "none")}
                      />
                      <label style={{ paddingLeft: '2px' }}>
                        {res.agency_short ?? res.agency_abbrv}
                      </label>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {renderStatus(res)}
                </div>
                <div className='d-flex' style={{ marginTop: 10 }}>
                  <div className='alter-start-date'>
                    {ConvertDateShort(res.start_date, 'DD MMM YY')}
                  </div>
                  <div style={{ flex: 1 }}>
                    <ProgressBar
                      bgcolor={res.date_balance > 7 ? '#387436' : '#f96b57'}
                      bgContainerColor={
                        res.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'
                      }
                      leftTime={checkLeftTime(res)}
                      completed={checkComplete(res)}
                    />
                  </div>
                  <div className='alter-end-date'>
                    {ConvertDateShort(res.end_date, 'DD MMM YY')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

const WebboardHistory = ({ user, history }) => {
  const [surveyHistory, setsurveyHistory] = useState([]);
  const [surveyHistoryFilter, setsurveyHistoryFilter] = useState([]);
  useEffect(() => {
    getHistory();
    return () => { };
  }, []);

  const getHistory = async () => {
    Swal.showLoading();
    try {
      const result = await ApiWebBoard.history();
      if (result.status == 200) {
        const { data } = result.data;
        setsurveyHistory(data);
        setsurveyHistoryFilter(data);
      }
    } catch (error) { }
    Swal.close();
  };
  const lateArray = [
    { label: 'ล่าสุด', value: 0 },
    { label: 'หน่วยงานตอบกลับ', value: 1 },
  ];
  const deletePost = async (item) => {
    Swal.fire({
      title: 'คุณต้องการลบรายการสนทนานี้ใช่หรือไม่',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true,
    }).then(async (ok) => {
      if (ok.isConfirmed) {
        let results = await ApiWebBoard.deletePost(item.id);
        setsurveyHistory([...surveyHistory.filter((x) => x.id != item.id)]);
        setsurveyHistoryFilter([
          ...surveyHistoryFilter.filter((x) => x.id != item.id),
        ]);
        if (results.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          });
        }
      }
    });
  };

  const handleFilter = (value) => {
    let filters = [...surveyHistory];
    if (value == 1) {
      filters = filters.filter((x) => x.agency_reply.length > 0);
    }
    setsurveyHistoryFilter(filters);
  };

  const editMessage = (item) => {
    Swal.fire({
      title: 'ข้อความของคุณ',
      text: 'แก้ไขข้อความของคุณ',
      input: 'text',
      showCancelButton: true,
      inputValue: item.message,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
    }).then(async (inputResult) => {
      if (inputResult.value) {
        Swal.showLoading();
        try {
          const model = {
            message: inputResult.value,
            id: item.id,
            type: 'A',
          };
          const result = await ApiWebBoard.createPost(model);
          if (result.status == 200) {
            Swal.fire({
              title: 'สำเร็จ',
              text: 'แก้ไขข้อความสำเร็จ',
              icon: 'success',
              timer: 2000,
            }).then((ok) => {
              // getSurvey();
              getHistory();
            });
          } else {
            throw Error('');
          }
        } catch (error) {
          Swal.close();
        }
      }
    });
  };

  return (
    <div className='webboard-history-menu'>
      <div className='d-flex webboard-history-menu-header'>
        <div
          className='d-flex'
          style={{ justifyContent: 'space-between', width: '100%' }}
        >
          <Space className='flex-1' direction='horizontal'>
            <img
              src={StaticImage(
                '/images/login/userprofile/comments-alt-duotone-1.png'
              )}
            />
            <label>ประวัติการสนทนา</label>
          </Space>
          <div style={{ width: 200 }}>
            <Select
              className='basic-single'
              classNamePrefix='select'
              defaultValue={lateArray[0].value}
              options={lateArray}
              onChange={(item) => handleFilter(item.value)}
            />
          </div>
        </div>
      </div>
      <List
        itemLayout='vertical'
        size='large'
        style={{ padding: 20 }}
        pagination={{
          onChange: (page) => { },
          pageSize: 5,
        }}
        dataSource={surveyHistoryFilter}
        renderItem={(res, i) => (
          <div className='webboard-card'>
            <div className='d-flex webboard-card-header'>
              <div className='d-flex-center'>
                <img src={StaticImage('/images/login/clock-solid.png')} />
                <span>{ConvertDateShort(res.created_date, 'DD MMM YY')}</span>
              </div>
              <div
                onClick={() =>
                  history.push(`Post?project_id=${res.project_id}`)
                }
                className='d-flex-center'
              >
                <img
                  className='img-eye'
                  src={StaticImage('/images/login/eye-duotone-2.png')}
                />
                <label style={{ fontSize: 14, fontWeight: 'bold' }}>
                  ดูรายละเอียด
                </label>
              </div>
            </div>
            <div className='webboard-card-body'>
              <div className='d-flex flex-column'>
                <Space direction='vertical'>
                  <label className='project-name'> {res.project_name} </label>
                  <div className='record-body-inside-border-container'>
                    <div style={{ textAlign: 'left' }}>{res.message}</div>
                    {res.agency_reply &&
                      res.agency_reply.length > 0 &&
                      res.agency_reply.map((reply) => {
                        return (
                          <div className='agency-reply d-flex text-danger'>
                            <span>หน่วยงานตอบกลับ :</span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: reply.message,
                              }}
                              style={{ marginLeft: 10 }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </Space>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default UserProfileMobile;
