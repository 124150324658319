import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ApiNotification from "../../api/notification";
import { checkUserBool } from "../../utils/checkUser";
import { encodeBase64 } from "../../utils/convertBase64";
import { ConvertDateShort } from "../../utils/date";

import { StaticImage } from "../../utils/image";

import "./notification.scss";

const data = [
  {
    id: 1,
    status_read: false,
    symbol: "primary",
    icon_header: StaticImage(
      "/images/icon/header/notification/pencil-alt-duotone-7.svg"
    ),
    header: "สิ่งที่ต้องทำ",
    time: "15 นาทีที่แล้ว",
    picture: null,
    picture_name: "",
    text_header: "คุณมีแบบรับฟังความเห็นที่ต้องทำต่อ",
    text: "การรับฟังความเห็น เลขที่ อกม-3. 43/2563...",
  },
  {
    id: 2,
    status_read: false,
    symbol: "warning",
    icon_header: StaticImage(
      "/images/icon/header/notification/vote-yea-duotone.svg"
    ),
    header: "ใกล้ปิดรับฟัง",
    time: "45 นาทีที่แล้ว",
    picture: StaticImage("/images/icon/header/notification/025-warehouse.svg"),
    picture_name: "warehouse",
    text_header: "",
    text: "การรับฟังความเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอนทรานซ์รัปชั่นพลู...",
  },
  {
    id: 3,
    status_read: true,
    symbol: "danger",
    icon_header: StaticImage(
      "/images/icon/header/notification/vote-nay-duotone-copy-2.svg"
    ),
    header: "ปิดรับฟัง",
    time: "3 ชั่วโมงที่แล้ว",
    picture: StaticImage("/images/icon/header/notification/025-warehouse.svg"),
    picture_name: "warehouse",
    text_header: "",
    text: "การรับฟังความเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอนทรานซ์รัปชั่นพลู...",
  },
  {
    id: 4,
    status_read: true,
    symbol: "success",
    icon_header: StaticImage(
      "/images/icon/header/notification/vote-yea-duotone-green.svg"
    ),
    header: "การรับฟังใหม่",
    time: "1 วันที่แล้ว",
    picture: StaticImage("/images/icon/header/notification/025-warehouse.svg"),
    picture_name: "warehouse",
    text_header: "",
    text: "การรับฟังความเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอนทรานซ์รัปชั่นพลู...",
  },
  {
    id: 5,
    status_read: true,
    symbol: "primary",
    icon_header: StaticImage(
      "/images/icon/header/notification/sync-duotone-1.svg"
    ),
    header: "อัพเดทไทม์ไลน์",
    time: "20 ก.ค. 64",
    picture: StaticImage("/images/icon/header/notification/020-shipping.svg"),
    picture_name: "shipping",
    text_header: "",
    text: "เลขที่ (ID) 43 โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาด...",
  },
];


const iconSurveyOpen = {
  image: StaticImage(
    "/images/icon/header/notification/vote-yea-duotone-green.svg"
  ),
  symbol: "success"
};
const iconSurveyClose = {
  image: StaticImage(
    "/images/icon/header/notification/vote-nay-duotone-copy-2.svg"
  ),
  symbol: "danger"
}
const iconProjectProgress = {
  image: StaticImage("/images/icon/header/notification/sync-duotone-1.svg"),
  symbol: "primary"
}



const NotificationHistory = (props) => {
  const userState = useSelector((state) => state.user);
  React.useEffect(() => {
    getNotification();
    return () => { };
  }, []);

  const [notificationList, setnotification] = useState([]);

  const getNotification = async () => {
    try {
      if (checkUserBool(userState)) {
        const notificationData = await ApiNotification.get();
        if (notificationData.status == 200) {
          setnotification(notificationData.data);
        }
      }
    } catch (error) {

    }
  }
  return (
    <div id="notification">
      <div className="container">
        <div className="header-flex">
          <div className="header-font">การแจ้งเตือน</div>
          <div className="setting-font cursor-pointer" onClick={() => {
            window.location.href = "/UserProfile?tab=notification"
          }}>
            <img
              className="icon-setting"
              src={StaticImage(
                "/images/icon/header/notification/cog-duotone-1.svg"
              )}
            />
            ตั้งค่าการแจ้งเตือน
          </div>
        </div>
        <div>
          {notificationList.map((d, index) => {
            let icon = iconSurveyOpen;
            let url = "";
            if (d.notification_type == "N01-1") { icon = iconSurveyOpen; url = `/listeningDetail?survey_id=${encodeBase64(d.link_id)}` }
            else if (d.notification_type == "N04") { icon = iconSurveyClose; url = `/listeningDetail?survey_id=${encodeBase64(d.link_id)}` }
            else if (d.notification_type == "N03") { icon = iconSurveyClose; url = `/listeningDetail?survey_id=${encodeBase64(d.link_id)}` }
            else if (d.notification_type == "N05") { icon = iconProjectProgress; url = `/ProjectTimeline?projectId=${encodeBase64(d.link_id)}` }
            else if (d.notification_type == "N07") { icon = iconProjectProgress; url = `/listeningDetail?survey_id=${encodeBase64(d.link_id)}` }
            const symbol = icon.symbol;
            return d.picture == null ? (
              <div
                className={`${!d.status_read ? "card-mobile" : "card-mobile-open"
                  }`}
              >
                <div className="card-space-mobile cursor-pointer" onClick={() => {
                  window.location.href = url;
                }}>
                  <div className="flex header-card-first">
                    <div className="d-flex-center card-header-font-mobile">
                      <div
                        className={`d-flex-center ${symbol === "danger"
                          ? "icon-frame-danger"
                          : `${symbol === "warning"
                            ? "icon-frame-warning"
                            : `${symbol === "success"
                              ? "icon-frame-success"
                              : "icon-frame-primary"
                            }`
                          }`
                          }`}
                      >
                        <img
                          className="icon-header-frame-size"
                          src={icon.image}
                        />
                      </div>
                      {d.title}
                    </div>
                    <div className="setting-font">{ConvertDateShort(d.created_date, 'DD MMM YY')}</div>
                  </div>
                  <div className="card-body">
                    <div className="card-header-font card-body-space">
                      {/* {d.text_header} */}
                    </div>
                    <div className="card-header-font-mobile card-body-space underline">
                      {d.description}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`${!d.status_read ? "card-mobile" : "card-mobile-open"
                  }`}
              >
                <div className="card-space-mobile">
                  <div className="flex header-card-first">
                    <div className="d-flex-center card-header-font-mobile">
                      <div
                        className={`d-flex-center ${d.symbol === "danger"
                          ? "icon-frame-danger"
                          : `${d.symbol === "warning"
                            ? "icon-frame-warning"
                            : `${d.symbol === "success"
                              ? "icon-frame-success"
                              : "icon-frame-primary"
                            }`
                          }`
                          }`}
                      >
                        <img
                          className="icon-header-frame-size"
                          src={d.icon_header}
                        />
                      </div>
                      {d.header}
                    </div>
                    <div className="setting-font">{d.time}</div>
                  </div>
                  <div className="flex card-body">
                    <div
                      className={`d-flex-center ${d.picture_name === "warehouse"
                        ? "icon-rounded-primary"
                        : `${d.picture_name === "shipping"
                          ? "icon-rounded-success"
                          : "icon-rounded"
                        }`
                        }`}
                    >
                      <img className="icon-body-frame-size" src={d.picture} />
                    </div>
                    <div>
                      <div className="card-header-font-mobile card-body-space underline margin-left">
                        {d.text}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NotificationHistory;
