import React from 'react'

const CommnetDuoDot = ({className}) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ariaHidden="true"
        className={className}
        viewBox="0 0 576 512"
        style={{ color: "#0c4da2" }}
      >
        <g class="fa-group">
          <path
            fill="currentColor"
            d="M352 287.9H162.3L79.5 350a9.7 9.7 0 0 1-15.5-7.8V288a64.06 64.06 0 0 1-64-64V64A64.06 64.06 0 0 1 64 0h288a64.06 64.06 0 0 1 64 64v160a63.91 63.91 0 0 1-64 63.9z"
            class="fa-secondary"
            opacity=".4"
          />
          <path
            fill="currentColor"
            d="M576 224v160a64.06 64.06 0 0 1-64 64h-32v54.3a9.7 9.7 0 0 1-15.5 7.8L381.7 448H256a64.06 64.06 0 0 1-64-64v-64h160a96.15 96.15 0 0 0 96-96v-64h64a64.06 64.06 0 0 1 64 64z"
            class="fa-primary"
          />
        </g>
      </svg>
    );
}

export default CommnetDuoDot
