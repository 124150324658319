import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  step: {
    maxWidth: 400,
    flexGrow: 1,
  },
  stepContainer: {
    display: 'inline-flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10,
  },
  stepItemActive: {
    backgroundColor: '#0c4da2',
    height: 10,
    flex: 1,
    marginRight: 2,
    marginLeft: 2,
    borderRadius: 5,
  },
  stepItem: {
    backgroundColor: '#efefef',
    height: 10,
    flex: 1,
    marginRight: 2,
    marginLeft: 2,
    borderRadius: 5,
  },
}));

const IndexFooter = ({ success = 0, complete = 0 }) => {
  const classes = useStyles();
  const items = [];
  for (var i = 0; i < complete; i++) {
    items.push(i);
  }
  return (
    <div className='step-footer'>
      <React.Fragment>
        {/* <AppBar
          position='fixed'
          color='primary'
          className={classes.appBar}
          style={{
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            backgroundColor: '#ffffff',
          }}
        > */}
        {/* <Toolbar>
            <ScrollIndecator />
          </Toolbar> */}
        {/* <MobileStepper
            variant='progress'
            steps={complete + 1}
            position='static'
            activeStep={success}
            className={classes.step}
          /> */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginBottom: 50,
          }}
        >
          <div className={classes.stepContainer}>
            {items.map((item) => {
              return (
                <div
                  // style={{ width: `${80 / complete}%` }}
                  className={
                    success > item ? classes.stepItemActive : classes.stepItem
                  }
                />
              );
            })}
          </div>
          <div className='success-question-text'>{`${success}/${complete} คำถาม`}</div>
        </div>
        {/* </AppBar> */}
      </React.Fragment>
    </div>
  );
};

export default IndexFooter;
