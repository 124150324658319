import React from 'react';
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import { Form, Input, Button, Space, Card } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { StaticImage } from '../../utils/image';
import { useHistory } from 'react-router-dom';
import './index.scss';
import ApiUser from '../../api/user';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { setLocalStorage } from '../../utils/localStorage';

const LoginIndex = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const onFinish = async (values) => {
    const { userName, password } = values;
    const model = {
      userName,
      password,
    };
    try {
      Swal.showLoading();
      const result = await ApiUser.login(model);
      if (result.status === 200) {
        const { data } = result;
        const { user, token, personalToken } = data;
        if (user.is_verify_email == 1) {
          dispatch({ type: 'set_user', user: user });
          dispatch({ type: 'set_token', token: token });
          setLocalStorage('token', token);
          if (user.is_first === 1) {
            history.push('/RegisterName');
          } else {
            history.push('/');
          }
        } else {
          history.push(`/VerifyEmail?personalToken=${personalToken}`);
        }
      }
      Swal.close();
    } catch (error) {
      const { data, status } = error.response;
      Swal.fire(data.message, '', 'error');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const tailLayout = {
    wrapperCol: {
      offset: 4,
      span: 16,
    },
  };
  return (
    <div>
      <BrowserView>
        <div className='login-card-container'>
          <div class='card'>
            <div className='login-card-logo' style={{ marginTop: 20 }}>
              <div className='d-flex-center flex-column'>
                <img
                  height={150}
                  width={120}
                  src={StaticImage(
                    '/images/login/userprofile/digital-id-resize.svg'
                  )}
                />
                <label style={{ fontSize: 24, fontWeight: 'bold' }}>
                  ระบุบัญชีผู้ใช้งานและรหัสผ่าน Digital ID
                </label>
              </div>
            </div>
            <Form
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{ marginTop: 20 }}
            >
              <div
                onClick={() => {
                  window.location.href =
                    'https://connect.egov.go.th/Account/Reset';
                }}
              >
                <label className='label-small-underlne label-small-underlne-position cursor-pointer'>
                  ลืมรหัสผ่าน ?
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <Form.Item
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกข้อมูล Username',
                    },
                  ]}
                >
                  <div style={{ width: '420px' }}>
                    <Input
                      size={'large'}
                      placeholder='ระบุบัญชีผู้ใช้งาน Digital ID/Email'
                      prefix={
                        <img src={StaticImage('/images/user-duotone-1.png')} />
                      }
                      className='input-theme'
                    />
                  </div>
                </Form.Item>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกรหัสผ่าน',
                    },
                  ]}
                >
                  <div
                    style={{
                      width: '420px',
                    }}
                  >
                    <Input.Password
                      size={'large'}
                      type='password'
                      placeholder='รหัสผ่าน'
                      prefix={
                        <img src={StaticImage('/images/key-duotone.png')} />
                      }
                      className='input-theme'
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </div>
                </Form.Item>
              </div>

              <Form.Item {...tailLayout}>
                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  htmlType='submit'
                  className='button-theme-blue'
                >
                  <span>เข้าสู่ระบบ</span>
                </Button>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <div className='d-flex'>
                  <span
                    onClick={() => {
                      history.push('/Register');
                    }}
                    className='register-text'
                  >
                    {' '}
                    ลงทะเบียนผู้ใช้ใหม่
                  </span>
                </div>
              </Form.Item>
            </Form>
            <Card>
              <div
                className="suggest"
                style={{ display: 'flex', justifyContent: 'space-evenly' }}
              >
                <div className='Group-8'>
                  <center>
                    <p style={{ alignItems: 'center' }}>ตอบ</p>

                    <img
                      src={StaticImage(
                        '/images/register_icon/comment-alt-dots-duotone-1.png'
                      )}
                    />
                    <p>แบบสอบถามพิเศษ</p>
                  </center>
                </div>
                <div className='Group-8'>
                  <center>
                    <p>แก้ไข/แชร์</p>
                    <img
                      src={StaticImage(
                        '/images/register_icon/comment-alt-edit-duotone-5.png'
                      )}
                    />{' '}
                    <p>คำตอบหรือ สิ่งที่ทำไปแล้ว</p>
                  </center>
                </div>
                <div className='Group-8'>
                  <center>
                    <p>จัดการ</p>
                    <img
                      src={StaticImage(
                        '/images/register_icon/history-duotone.png'
                      )}
                    />
                    <p>ประวัติและ ข้อมูลการใช้งาน</p>
                  </center>
                </div>
                <div className='Group-8'>
                  <center>
                    <p>ติดตาม</p>
                    <div className='d-flex-center flex-column'>
                      <img
                        src={StaticImage(
                          '/images/register_icon/heart-circle-duotone-3.png'
                        )}
                      />
                      <span
                        style={{
                          whiteSpace: 'pre-line',
                          textAlign: 'center',
                        }}
                      >{`ความเคลื่อนไหวของ \n กระบวนทางกฎหมาย`}</span>
                    </div>
                  </center>
                </div>
              </div>
            </Card>
          </div>

        </div>

        <div className='login-card-bottom-container'>
          <div className='card-buttom'>
            <div style={{ display: 'flex', marginTop: '18px' }}>
              <img
                src={StaticImage('/images/login/arrow-left-regular.png')}
                className='arrow-left-regular'
              />
              <p
                className='login-back-to-register'
                onClick={() => {
                  history.push('/');
                }}
              >
                กลับหน้าหลัก
              </p>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className='section-banner'
          style={{ padding: 20, paddingTop: 0, paddingBottom: 0 }}
        >
          <Space size={'middle'} direction='vertical' style={{ width: '100%' }}>
            <div className='d-flex-center flex-column'>
              <img
                height={116}
                width={88}
                src={StaticImage(
                  '/images/login/userprofile/digital-id-resize.svg'
                )}
              />
              <label
                style={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                ระบุบัญชีผู้ใช้งานและรหัสผ่าน Digital ID
              </label>
            </div>

            <div className='d-flex-justify-end'>
              <label
                onClick={() => {
                  window.location.href =
                    'https://connect.egov.go.th/Account/Reset';
                }}
                className='cursor-pointer'
                style={{
                  fontSize: 16,
                  color: '#8e99ad',
                  textDecoration: 'underline',
                }}
              >
                ลืมรหัสผ่าน ?
              </label>
            </div>
            <Form
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <Form.Item
                  style={{ width: '100%' }}
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกข้อมูล Username',
                    },
                  ]}
                >
                  {/* <div style={{ width: '401px' }}> */}
                  <Input
                    size={'large'}
                    placeholder='ระบุบัญชีผู้ใช้งาน Digital ID/Email'
                    prefix={
                      <img src={StaticImage('/images/user-duotone-1.png')} />
                    }
                    className='input-theme'
                  />
                  {/* </div> */}
                </Form.Item>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <Form.Item
                  name='password'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกรหัสผ่าน',
                    },
                  ]}
                >
                  <Input.Password
                    size={'large'}
                    type='password'
                    placeholder='รหัสผ่าน'
                    prefix={
                      <img src={StaticImage('/images/key-duotone.png')} />
                    }
                    className='input-theme'
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item style={{ width: '100%' }}>
                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  htmlType='submit'
                  className='button-theme-blue'
                >
                  <span>เข้าสู่ระบบ</span>
                </Button>
              </Form.Item>
              <Form.Item style={{ width: '100%' }}>
                <div className='d-flex'>
                  <span
                    onClick={() => {
                      history.push('/Register');
                    }}
                    className='register-text'
                  >
                    {' '}
                    ลงทะเบียนผู้ใช้ใหม่
                  </span>
                </div>
              </Form.Item>
            </Form>
          </Space>
        </div>
        <div
          className='mobile-icon-register'
          style={{ display: 'flex', justifyContent: 'space-evenly', paddingBottom: 100 }}
        >
          <div>
            <center>
              <p style={{ alignItems: 'center' }}>ตอบ</p>

              <img
                src={StaticImage(
                  '/images/register_icon/comment-alt-dots-duotone-1.png'
                )}
              />
            </center>
          </div>
          <div>
            <center>
              <p>แก้ไข/แชร์</p>
              <img
                src={StaticImage(
                  '/images/register_icon/comment-alt-edit-duotone-5.png'
                )}
              />{' '}
            </center>
          </div>
          <div>
            <center>
              <p>จัดการ</p>
              <img
                src={StaticImage(
                  '/images/register_icon/history-duotone.png'
                )}
              />
            </center>
          </div>
          <div>
            <center>
              <p>ติดตาม</p>
              <img
                src={StaticImage(
                  '/images/register_icon/heart-circle-duotone-3.png'
                )}
              />
            </center>
          </div>
        </div>
        <div
          className='home-button-bottom'
          style={{ position: 'fixed', zIndex: 2, backgroundColor: '#fff' }}
        >
          <img
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            src={StaticImage('/images/icon/home-lg-alt-duotone.png')}
          />
          <span
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            style={{ color: '#8e99ad', fontSize: 11, marginTop: 10 }}
          >
            กลับหน้าหลัก
          </span>
        </div>
      </MobileView>
    </div>
  );
};

export default LoginIndex;
