import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';
const AlertInfo = (title = 'ผิดพลาด', text) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: 'info',
    confirmButtonText: 'ตกลง',
  });
};

export default AlertInfo
