import React from 'react'

const EditDuoTone = ({className}) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ariaHidden="true"
        className={className}
        viewBox="0 0 576 512"
      >
        <g className="fa-group">
          <path
            fill="currentColor"
            d="M564.6 60.2l-48.8-48.8a39.11 39.11 0 0 0-55.2 0l-35.4 35.4a9.78 9.78 0 0 0 0 13.8l90.2 90.2a9.78 9.78 0 0 0 13.8 0l35.4-35.4a39.11 39.11 0 0 0 0-55.2zM427.5 297.6l-40 40a12.3 12.3 0 0 0-3.5 8.5v101.8H64v-320h229.8a12.3 12.3 0 0 0 8.5-3.5l40-40a12 12 0 0 0-8.5-20.5H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V306.1a12 12 0 0 0-20.5-8.5z"
            className="fa-secondary"
            opacity=".4"
          />
          <path
            fill="currentColor"
            d="M492.8 173.3a9.78 9.78 0 0 1 0 13.8L274.4 405.5l-92.8 10.3a19.45 19.45 0 0 1-21.5-21.5l10.3-92.8L388.8 83.1a9.78 9.78 0 0 1 13.8 0z"
            className="fa-primary"
          />
        </g>
      </svg>
    );
}

export default EditDuoTone
