import React, { useEffect, useState, useRef } from 'react';
import './css/index.scss';
import ListDetails from './ListDetails';
import {
  Typography,
  Space,
  Row,
  Col,
  Avatar,
  Button,
  Result,
  Input,
} from 'antd';
import {
  UserOutlined,
  ClockCircleOutlined,
  FormOutlined,
  CheckCircleTwoTone,
  ReloadOutlined,
} from '@ant-design/icons';
import { Container } from '@material-ui/core';
import IndexFooter from '../main/IndexFooter';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useClipboard } from 'use-clipboard-copy';
import moment from 'moment';
import { StaticImage } from '../../utils/image';
import { useSelector, useDispatch } from 'react-redux';
import { checkUserBool } from '../../utils/checkUser';
import AlertError from '../../utils/AlertError';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from '../../utils/localStorage';
import ReCAPTCHA from 'react-recaptcha';
import { v4 } from 'uuid';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useWindowUnloadEffect } from '../../utils';
import { encodeBase64 } from '../../utils/convertBase64';
import { ConvertFileToBase64 } from '../../utils/uploadfile';
const { Text, Link, Title } = Typography;
const Detailsindex = (props) => {
  const userState = useSelector((state) => state.user);
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const history = useHistory();
  const [verify, setverify] = useState(true);
  const [result, setResult] = useState(false);
  const [survey, setsurvey] = useState([]);
  const [share, setshare] = useState({});
  const [project_id, setproject_id] = useState(0);
  const [showShare, setShowShare] = useState(false);
  const [surveyName, setsurveyName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [date_balance, setDateBalance] = useState('');
  const [reRender, setRerender] = useState(false);
  const [totalQuestion, settotalQuestion] = useState(0);
  const [successQuestion, setSuccessQuestion] = useState(0);
  const [answerSuccess, setAnswerSuccess] = useState('');
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');

  useWindowUnloadEffect(() => destroy(), true);

  useEffect(() => {
    document.title = 'แสดงความคิดเห็น';
    // getSurvey();
    if (checkUserBool(userState)) {
      checkAnswer();
      // getSurvey();
    } else {
      getSurvey();
    }
    return () => {
      destroy();
    };
  }, []);

  const destroy = async () => {
    let surveyStorage = await getLocalStorage('answer');
    if (surveyStorage) {
      // getSurvey();
      try {
        surveyStorage = JSON.parse(surveyStorage);
        const result = await ApiSurvey.answerDraft(surveyStorage);
        if (result.status == 200) {
          removeLocalStorage('answer');
        }
      } catch (error) { }
    }
  };

  const checkAnswer = async () => {
    // let surveyStorage = await getLocalStorage('answer');
    let surveyStorage = await ApiSurvey.getAnswerDraft({ id: survey_id });
    if (surveyStorage.status == 200) {
      // getSurvey();
      try {
        const { data } = surveyStorage;
        surveyStorage = data;
        if (encodeBase64(surveyStorage.survey_id) == survey_id) {
          if (surveyStorage.answer.length > 0) {
            setsurveyName(surveyStorage.answer[0].survey_name);
            setDateBalance(surveyStorage.answer[0].date_balance);
            setProjectName(surveyStorage.answer[0].project_name);
            setsurvey(surveyStorage.answer);
            let total = 0;
            surveyStorage.answer.map((item, index) => {
              total += item.question_detail.length;
            });
            settotalQuestion(total);
          } else {
            getSurvey();
          }
        } else {
          getSurvey();
        }
      } catch (error) {
        getSurvey();
      }
    } else {
      getSurvey();
    }
  };

  const getSurvey = async () => {
    try {
      removeLocalStorage('answer');
      Swal.showLoading();
      const survey = await ApiSurvey.getQuestion({ survey_id });
      if (survey.status == 200) {
        Swal.close();
        const { data } = survey.data;

        if (data.length > 0) {
          if (!data[0].question_detail) {
            throw 'ไม่พบข้อมูลแบบสอบถาม';
          }
          setsurveyName(data[0].survey_name);
          setDateBalance(data[0].date_balance);
          setProjectName(data[0].project_name);
          setproject_id(data[0].project_id);
          let total = 0;
          data.map((item, index) => {
            total += item.question_detail.length;
          });
          settotalQuestion(total);
          if (data[0].question_id) {
            setsurvey(data);
          } else {
            throw 'ไม่พบข้อมูลแบบสอบถาม';
          }
        } else {
          throw 'ไม่พบข้อมูลแบบสอบถาม';
        }
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  useEffect(() => {
    let total = 0;
    survey.map((item, index) => {
      total += item.question_detail.filter((x) => x.value).length;
    });
    setSuccessQuestion(total);
    return () => { };
  }, [survey]);

  const onCommentClick = (page, item) => {
    let newSurvey = [...survey];
    let newPage = newSurvey.find((x) => x.question_id == page.question_id);
    let newQuestion = newPage.question_detail;
    let filter = newQuestion.findIndex((x) => x.name == item.name);
    newQuestion[filter].editor = true;
    setLocalStorage(
      'answer',
      JSON.stringify({
        survey_id: survey_id,
        answer: newSurvey,
      })
    );
    setsurvey(newSurvey);
  };

  const onCommentOnChange = (page, item, label) => {
    let newSurvey = [...survey];
    let newPage = newSurvey.find((x) => x.question_id == page.question_id);
    let newQuestion = newPage.question_detail;
    let filter = newQuestion.findIndex((x) => x.name == item.name);
    newQuestion[filter].note = label;
    newQuestion[filter].datetime = moment().format('DD MMM YY HH:ss');
    setsurvey(newSurvey);
    setLocalStorage(
      'answer',
      JSON.stringify({
        survey_id: survey_id,
        answer: newSurvey,
      })
    );
  };

  const onEvent = async (surveyItem, question, type, value) => {
    if (type == 'radiogroup') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;
      setsurvey(newSurvey);
      setLocalStorage(
        'answer',
        JSON.stringify({
          survey_id: survey_id,
          answer: newSurvey,
        })
      );
    } else if (type == 'text') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;
      setsurvey(newSurvey);
      setLocalStorage(
        'answer',
        JSON.stringify({
          survey_id: survey_id,
          answer: newSurvey,
        })
      );
    } else if (type == 'dropdown') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = value;

      setsurvey(newSurvey);
      setLocalStorage(
        'answer',
        JSON.stringify({
          survey_id: survey_id,
          answer: newSurvey,
        })
      );
    } else if (type == 'file') {
      const base64File = await ConvertFileToBase64(value.originFileObj);

      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].value = base64File;
      setsurvey(newSurvey);
      setLocalStorage(
        'answer',
        JSON.stringify({
          survey_id: survey_id,
          answer: newSurvey,
        })
      );
    } else if (type == 'other') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      newQuestion[filter].otherValue = value;
      setsurvey(newSurvey);
      setLocalStorage(
        'answer',
        JSON.stringify({
          survey_id: survey_id,
          answer: newSurvey,
        })
      );
    }
    if (type == 'checkbox') {
      let newSurvey = [...survey];
      let newPage = newSurvey.find(
        (x) => x.question_id == surveyItem.question_id
      );
      let newQuestion = newPage.question_detail;
      let filter = newQuestion.findIndex((x) => x.name == question);
      if (newQuestion[filter].value) {
        if (newQuestion[filter].value.includes(value)) {
          const checkBoxIndex = newQuestion[filter].value.findIndex(
            (x) => x == value
          );
          newQuestion[filter].value.splice(checkBoxIndex, 1);
        } else {
          newQuestion[filter].value.push(value);
        }
      } else {
        newQuestion[filter].value = [];
        newQuestion[filter].value.push(value);
      }
      setsurvey(newSurvey);
      setLocalStorage(
        'answer',
        JSON.stringify({
          survey_id: survey_id,
          answer: newSurvey,
        })
      );
    }
  };

  const surveyPageRender = () => {
    let components = [];
    let beforeQuestion = 0;
    survey.map((item, index) => {
      components.push(
        <div
          style={{
            background: '#fff',
            padding: '20px 20px',
            // marginTop: 20,
            // margin: '15px;0px',
            // margin: '15px 0px',
            borderTop: index == 0 ? 'none' : '1px solid #0c4da2',
          }}
        >
          <Container maxWidth='xl'>
            <span className='header-page'>{item.name}</span>
            <div>
              {item?.question_title && (
                <p className='section-head-text-details'>{`${item.question_title}`}</p>
              )}
              {item?.question_desc && (
                <p className='section-head-description-details'>{`${item.question_desc}`}</p>
              )}
            </div>
            <section style={{ marginTop: 20 }}>
              <ListDetails
                beforeQuestion={beforeQuestion}
                survey={item}
                onEvent={onEvent}
                onCommentClick={onCommentClick}
                onCommentOnChange={onCommentOnChange}
              />
            </section>
          </Container>
        </div>
      );
      beforeQuestion += item.question_detail.length;
    });
    return (
      <div style={{ paddingBottom: 120 }}>
        {components}
        {/* <ReCAPTCHA
          className='d-flex-center'
          style={{ marginTop: 20 }}
          sitekey='6Lfxs6kaAAAAALjK038cIdMbaiqxaH4lC8M5l8rn'
          render='explicit'
          verifyCallback={verifyCallback}
          onloadCallback={callback}
        /> */}
      </div>
    );
  };

  const copyURL = (url) => {
    clipboard.copy(url);
  };

  const sendAnswer = async () => {
    const submit_id = v4();
    const stackHolder = await getLocalStorage('informer');
    await removeLocalStorage('answer');
    await removeLocalStorage('informer');
    const uuid = await getLocalStorage('uuid');
    Swal.showLoading();
    try {
      const answer = [];
      var hasValueLessThanTen = false;
      var messegeError = `ท่านต้องตอบคำถามที่มี <span class="text-danger">*</span> สีแดงให้ครบทุกข้อก่อนจึงจะส่งคำตอบได้`;
      survey.map((item) => {
        const value = item.question_detail.filter(x => x.isRequired && !x.value);
        if (value.length > 0) {
          messegeError += "\n โดย รายการคำถามที่จำเป็นต้องตอบแต่ท่านยังไม่ได้ตอบคือ";
          value.map((subitem, subindex) => {
            messegeError += `\n ข้อ ${subitem.title}`
          })
          hasValueLessThanTen = true;
        }
        const model = {
          project_id: project_id,
          question_id: item.question_id,
          survey_id: item.survey_id,
          question_title: item.question_title,
          question_desc: item.question_desc,
          record_status: 'A',
          question_detail: JSON.stringify(item.question_detail),
          name: item.name,
          uuid: uuid,
          submit_id: submit_id,
          is_stackholder: stackHolder ? 1 : 0,
          stackholder_key: stackHolder ?? '',
        };
        answer.push(model);
      });
      if (hasValueLessThanTen) {
        Swal.fire({
          title: '',
          html: `<pre>${messegeError}</pre>`,
          icon: 'error'
        });
        return false;
      }
      const result = await ApiSurvey.answer(answer);
      if (result.status == 200) {
        const { data } = result.data;
        setshare({
          title: `${window.location.origin}/AnswerPublic?answer_id=${data}`,
          subject: `${window.location.origin}/AnswerPublic?answer_id=${data}`,
          shareUrl: `${window.location.origin}/AnswerPublic?answer_id=${data}`,
        });
        setAnswerSuccess(data);
        await setLocalStorage(
          'share-answer',
          JSON.stringify({
            datetime: new Date(),
            answer: answer,
          })
        );
        // setResult(true);
        history.push(`/Success?survey_id=${survey_id}`);
      }
      Swal.close();
    } catch (error) {
      Swal.close();
    }


    // setResult(true);
  };

  function captchaOnChange(value) {
    console.log('Captcha value:', value);
  }

  var callback = function () {
    console.log('Done!!!!');
  };

  // specifying verify callback function
  var verifyCallback = function (response) {
    if (response) {
      setverify(true);
    }
  };

  return (
    <div>
      <section style={{ marginBottom: 15 }}>
        <div className='section-banner'>
          <div onClick={() => history.go(-1)} className='back-btn'>
            <ArrowLeftOutlined style={{ marginRight: 6 }} />
            <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
          </div>
          <Container maxWidth='xl'>
            <Row style={{ marginBottom: 10 }} wrap={false}>
              <Col flex='1' className='d-flex'>
                <label className='project-head'>{projectName}</label>
              </Col>
            </Row>
            <Row>
              <Col className='section-head-timer-details'>
                <ClockCircleOutlined />
                <Text
                  style={{ paddingLeft: '2px' }}
                  className='section-head-timer-details'
                >
                  {' '}
                  ปิดรับฟังใน {parseInt(date_balance) + 1} วัน
                </Text>
              </Col>
            </Row>
            {/* <span>{projectName}</span> */}
          </Container>
        </div>
        {/* <ReCAPTCHA
          // ref={recaptchaRef}
          sitekey='6Le3YqUaAAAAAP6tRMy5HCN3VI-Ownxlgxe8vwbP'
          onChange={captchaOnChange}
        /> */}
      </section>
      {survey.length > 0 && result == false && surveyPageRender()}

      {result == true && (
        <div>
          <Result
            icon={<CheckCircleTwoTone twoToneColor='#0c4da2' />}
            title={
              <Title level={2} style={{ color: '#0c4da2' }}>
                ได้รับความคิดเห็นของคุณแล้ว
              </Title>
            }
            // subTitle={
            //   <Title level={4}>
            //     คุณสามารถเปลี่ยนแปลงความคิดเห็นของคุณได้ จนกว่าจะปิดการรับฟัง
            //   </Title>
            // }
            extra={
              <div>
                <div style={{ margin: '14px 10px 14px 10px' }}>
                  Copy Link ด้านล่าง เพื่อติดตามผลการรับฟัง
                </div>

                <Button
                  type='primary'
                  block
                  size='large'
                  className='button-radius'
                  style={{ backgroundColor: '#0c4da2', fontSize: 15 }}
                  onClick={() =>
                    clipboard.copy(
                      `${window.location.origin}/listeningDetail?survey_id=${survey_id}`
                    )
                  }
                >
                  {clipboard.copied ? 'คัดลอกสำเร็จ' : 'คัดลอก URL'}
                </Button>

                <div style={{ marginTop: 20 }}>
                  <Title level={3}>ขอบคุณที่เข้าร่วมทดสอบครั้งนี้</Title>
                  <span style={{ fontSize: 16 }}>
                    ขอเวลาสักหน่อย... ตอบคำถามสั้นๆ เพื่อเป็น Feedback
                    ให้ทีมพัฒนาต่อไป
                  </span>
                </div>
                <div
                  className='feedback-button'
                  style={{ marginTop: 20 }}
                  onClick={() => {
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLScMGg-Hrot96h1ABIF0YwybqLTM6Nxh8Zcujw3HGnRjo_2kRA/viewform',
                      '_blank'
                    );
                    setTimeout(() => {
                      history.push('/');
                    }, 1000);
                  }}
                >
                  <span>{'ส่ง Feedback'}</span>
                </div>
                {/* <Button
                  // type='primary'
                  block
                  size='large'
                  className='button-radius'
                  style={{
                    // backgroundColor: '#0c4da2',
                    fontSize: 15,
                    marginTop: 20,
                  }}
                  onClick={() => {
                    history.push('/');
                    // setResult(false);
                  }}
                >
                  กลับหน้าหลัก
                </Button> */}
                <div className='result-home-button'>
                  <img
                    onClick={() => {
                      history.push('/');
                      // setResult(false);
                    }}
                    src={StaticImage('/images/icon/home-lg-alt-duotone.png')}
                  />
                  <span
                    onClick={() => {
                      history.push('/');
                      // setResult(false);
                    }}
                    style={{ color: '#8e99ad', fontSize: 11, marginTop: 10 }}
                  >
                    กลับหน้าหลัก
                  </span>
                </div>
                {/* <Button
                  type='primary'
                  block
                  size='large'
                  className='button-radius'
                  style={{
                    backgroundColor: '#0c4da2',
                    fontSize: 15,
                    marginTop: 20,
                  }}
                  onClick={() => {
                    history.push('/');
                    // setResult(false);
                  }}
                >
                  {'กลับหน้าหลัก'}
                </Button> */}
                {/* <div
                  className='feedback-button'
                  style={{ marginTop: 20 }}
                  onClick={() => setShowShare(true)}
                >
                  <b>{'แชร์ความคิดเห็นของคุณให้เพื่อน'}</b>
                </div> */}
                {/* <div style={{ paddingTop: '10px', color: '#333333' }}>
                  <Text style={{ color: '#333333', fontSize: '20px' }}>
                    {' '}
                    หากต้องการติดตามผลการรับฟัง กรุณาให้อีเมลล์สำหรับติดตามความ
                    คืบหน้าของการรับฟังนี้
                  </Text>
                </div>
                <div style={{ paddingTop: '18px', color: '#333333' }}>
                  <Input placeholder='กรุณากรอกอีเมลล์' />
                </div>
                <div style={{ paddingTop: '18px', color: '#333333' }}>
                  <Button
                    block
                    size='large'
                    className='button-radius2'
                    style={{ backgroundColor: '#f0f2f6' }}
                  >
                    ยืนยัน
                  </Button>
                </div> */}
              </div>
            }
          />
        </div>
      )}
      {survey.length > 0 && result == false && (
        <>
          <div className='bottom-send-button-container' style={{ bottom: 0 }}>
            <IndexFooter success={successQuestion} complete={totalQuestion} />
            <Button
              type='primary'
              block
              disabled={successQuestion > 0 && verify == true ? false : true}
              icon={<FormOutlined />}
              size='large'
              className='button-radius sent-question-button'
              style={{
                backgroundColor:
                  successQuestion > 0 && verify == true ? '#0c4da2' : '#ebebeb',
              }}
              onClick={() => {
                sendAnswer();
              }}
            >
              ส่งความคิดเห็น
            </Button>
          </div>
        </>
      
      )}
      {showShare && result && answerSuccess.length > 0 && (
        <div className='share-container'>
          <div className='header'>
            <span>แชร์</span>
            <img
              onClick={() => setShowShare(false)}
              style={{ position: 'absolute', right: 25 }}
              src={StaticImage('/images/icon/drawer-header-close.png')}
            />
          </div>
          <div className='share-button'>
            <div>
              <FacebookShareButton
                url={share.shareUrl}
                quote={share.title}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <span>Facebook</span>
            </div>
            {/* <div>
              <LineShareButton url={share.shareUrl} title={share.subject}>
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
            <div>
              <TwitterShareButton
                url={' '}
                title={share.title}
                className='Demo__some-network__share-button'
              >
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <span>Twitter</span>
            </div>
            <div>
              <EmailShareButton
                url={''}
                subject={share.subject}
                body={share.title}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <span>E-mail</span>
            </div>
            <div onClick={() => clipboard.copy(`${share.shareUrl}`)}>
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/link-regular.png'
                  )}
                />
              </div>
              <span>คัดลอกลิงค์</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Detailsindex;
