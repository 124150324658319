import React from 'react';

//@ antd

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Main from './components/main';
import RouterMain from './router/index'
import BarDuoTone from './components/iconComponent/BarDuoTone';
function App() {
  return (
    <div>
      {/* <Main /> */}
      <RouterMain />
    </div>
  );
}

export default App;
