import { Container } from '@material-ui/core';
import {
  Card,
  Form,
  Input,
  Button,
  Checkbox,
  Result,
  Typography,
  Space,
} from 'antd';
import React from 'react';
import { StaticImage } from '../../utils/image';
import { useHistory } from 'react-router-dom';
import './index.scss';
import ApiUser from '../../api/user';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { setLocalStorage } from '../../utils/localStorage';
import { CheckCircleTwoTone } from '@ant-design/icons';
import ButtonComponent from '../../utils/ButtonComponent';
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
const { Text, Link, Title } = Typography;
const LoginIndex = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const onFinish = async (values) => {
    const { userName, password } = values;
    const model = {
      userName,
      password,
    };
    try {
      Swal.showLoading();
      const result = await ApiUser.login(model);
      if (result.status === 200) {
        const { data } = result;
        const { user, token } = data;
        dispatch({ type: 'set_user', user: user });
        dispatch({ type: 'set_token', token: token });
        setLocalStorage('token', token);
        if (user.is_first === 1) {
          history.push('/RegisterName');
        } else {
          history.push('/');
        }
      }
      Swal.close();
    } catch (error) {
      const { data, status } = error.response;
      Swal.fire(data.message, '', 'error');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const tailLayout = {
    wrapperCol: {
      offset: 4,
      span: 16,
    },
  };
  return (
    <div>
      <BrowserView>
        <div className='login-card-container'>
          <div class='card'>
            <div className='d-flex-center' style={{ marginTop: 20 }}>
              <img
                height={150}
                width={120}
                src={StaticImage(
                  '/images/login/userprofile/digital-id-resize.svg'
                )}
              />
              <CheckCircleTwoTone
                style={{ fontSize: 60, marginBottom: 20, marginLeft: 20 }}
                twoToneColor='#387436'
              />
            </div>
            <div
              className='d-flex-center flex-column'
              style={{ marginTop: 10 }}
            >
              <Title level={2} style={{ color: '#0c4da2' }}>
                ผลการลงทะเบียนสำเร็จ
              </Title>
              <label>ระบบได้ลงทะเบียนข้อมูลของท่านเรียบร้อยแล้ว</label>
            </div>
            <div className='login-card-logo' style={{ marginTop: 10 }}></div>
            <Form
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{ marginTop: 20 }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  paddingBottom: '1pc',
                }}
              >
                <Form.Item
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกข้อมูล Username',
                    },
                  ]}
                >
                  <div style={{ width: '401px' }}>
                    <Input
                      className='input-theme'
                      size={'large'}
                      placeholder='ระบุบัญชีผู้ใช้งาน Digital ID/Email'
                      prefix={
                        <img src={StaticImage('/images/user-duotone-1.png')} />
                      }
                      style={{ borderRadius: '10px' }}
                      required={true}
                    />
                  </div>
                </Form.Item>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกรหัสผ่าน',
                    },
                  ]}
                >
                  <div
                    style={{
                      width: '401px',
                    }}
                  >
                    <Input
                      className='input-theme'
                      size={'large'}
                      type='password'
                      placeholder='รหัสผ่าน'
                      prefix={
                        <img src={StaticImage('/images/key-duotone.png')} />
                      }
                      style={{ borderRadius: '10px' }}
                      required={true}
                    />
                  </div>
                </Form.Item>
              </div>
              <Form.Item
                style={{ paddingLeft: 10, paddingRight: 10 }}
                {...tailLayout}
              >
                {/* <Button
                  type='primary'
                  htmlType='submit'
                  className='Rounded-Rectangle-8-login'
                >
                  <span className='login-label'>เข้าสู่ระบบ</span>
                </Button>
             */}
                <ButtonComponent
                  text='เข้าสู่ระบบ'
                  type='submit'
                  className={'button-theme-blue w-100'}
                />
              </Form.Item>
            </Form>
            {/* <h2>First card</h2>
          <p>Some text</p> */}
          </div>
        </div>
        <div className='login-card-bottom-container'>
          <div className='card-buttom'>
            <div style={{ display: 'flex', marginTop: '18px' }}>
              <img
                src={StaticImage('/images/login/arrow-left-regular.png')}
                className='arrow-left-regular'
              />
              <p
                className='login-back-to-register'
                onClick={() => {
                  history.push('/');
                }}
              >
                กลับหน้าหลัก
              </p>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className='section-banner'
          style={{ padding: 20, paddingTop: 0, paddingBottom: 50 }}
        >
          <div className='d-flex-center' style={{ marginTop: 20 }}>
            <img
              height={116}
              width={88}
              src={StaticImage(
                '/images/login/userprofile/digital-id-resize.svg'
              )}
            />
            <CheckCircleTwoTone
              style={{ fontSize: 50, marginBottom: 20, marginLeft: 20 }}
              twoToneColor='#387436'
            />
          </div>
          <div className='d-flex-center flex-column' style={{ marginTop: 10 }}>
            <Title level={2} style={{ color: '#0c4da2' }}>
              ผลการลงทะเบียนสำเร็จ
            </Title>
            <label style={{ textAlign: 'center' }}>
              ระบบได้ลงทะเบียนข้อมูลของท่านเรียบร้อยแล้ว
            </label>
          </div>

          <Space
            size={'middle'}
            direction='vertical'
            style={{ width: '100%', marginTop: 20 }}
          >
            <Form
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <Form.Item
                  style={{ width: '100%' }}
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกข้อมูล Username',
                    },
                  ]}
                >
                  {/* <div style={{ width: '401px' }}> */}
                  <Input
                    size={'large'}
                    placeholder='ระบุบัญชีผู้ใช้งาน Digital ID/Email'
                    prefix={
                      <img src={StaticImage('/images/user-duotone-1.png')} />
                    }
                    className='input-theme-small'
                    style={{ borderRadius: '10px' }}
                    required={true}
                  />
                  {/* </div> */}
                </Form.Item>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <Form.Item
                  name='password'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'กรุณากรอกรหัสผ่าน',
                    },
                  ]}
                >
                  <Input
                    size={'large'}
                    type='password'
                    className='input-theme-small'
                    placeholder='รหัสผ่าน'
                    prefix={
                      <img src={StaticImage('/images/key-duotone.png')} />
                    }
                    style={{ borderRadius: '10px' }}
                    required={true}
                  />
                </Form.Item>
              </div>
              <Form.Item style={{ width: '100%' }}>
                <ButtonComponent
                  text='เข้าสู่ระบบ'
                  type='submit'
                  className={'button-theme-blue-45 w-100'}
                />
              </Form.Item>
            </Form>
          </Space>
        </div>
        <div
          className='home-button-bottom'
          style={{ position: 'fixed', zIndex: 2, backgroundColor: '#fff' }}
        >
          <img
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            src={StaticImage('/images/icon/home-lg-alt-duotone.png')}
          />
          <span
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            style={{ color: '#8e99ad', fontSize: 11, marginTop: 10 }}
          >
            กลับหน้าหลัก
          </span>
        </div>
      </MobileView>
    </div>
  );
};

export default LoginIndex;
