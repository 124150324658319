import React from "react";
import { Typography, Space, Button, Row, Col, Card } from "antd";
import { Container } from "@material-ui/core";
import "./css/index.css";
const { Title, Text, Link, Paragraph } = Typography;
const UiStyleIndex = () => {
  return (
    <div>
      <section style={{ paddingTop: "100px" }}>
        <Container maxWidth="xl">
          <div className="space-align-block">
            <Space align="baseline ">
              <Title className="ui-header">UI Style Guide</Title>
              <Title level={3} className="ui-header">
                ระบบกลางทางกฎหมาย
              </Title>
            </Space>
          </div>
        </Container>
      </section>
      <section>
        <Container maxWidth="xl" className="div-ui-padding">
          <Text style={{ color: "#6d7481", fontSize: "15" }}>01 </Text>
          <Text style={{ color: "#0c4da2", fontSize: "20px" }} strong>
            Colors
          </Text>
          <hr className="hr-style" />
        </Container>
      </section>
      <section>
        <Container maxWidth="xl" className="div-ui-padding">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <Card bordered={false} className="Color-1"></Card>
                </Col>
                <Col span={12}>
                  <Card bordered={false} className="Color-1-copy-5"></Card>
                </Col>
              </Row>
              <Card bordered={false}>
                <Text strong>True Blue ครามฝรั่ง </Text> <br />
                <Text>#0c4ca3, #7d9fcb</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Row gutter={16} align="center">
                <Col span={8}>
                  <Card bordered={false} className="Color-1-copy-2"></Card>
                </Col>
                <Col span={8}>
                  <Card bordered={false} className="Color-1-copy-3"></Card>
                </Col>
              </Row>
              <Row gutter={16} align="center">
                <Col span={8}>
                  <Card bordered={false}>
                    <Text strong>Online Lime </Text> <br />
                    <Text>#387436</Text>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card bordered={false}>
                    <Text strong>Greenery </Text> <br />
                    <Text>#89b246</Text>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container maxWidth="xl" className="div-ui-padding">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <Card bordered={false} className="Color-1-copy-6 "></Card>
                </Col>
                <Col span={12}>
                  <Card bordered={false} className="Color-1-copy-9"></Card>
                </Col>
              </Row>
              <Card bordered={false}>
                <Text strong>Gray ดอกเลา </Text> <br />
                <Text>#6d7481, #8b999a</Text>
              </Card>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <Card bordered={false} className="Color-1-copy-8 "></Card>
                </Col>
                <Col span={12}>
                  <Card bordered={false} className="Color-1-copy-88"></Card>
                </Col>
              </Row>
              <Card bordered={false}>
                <Text strong>Brown มุกสุข สีนวล </Text> <br />
                <Text>#c8ae7b, #dbcc91</Text>
              </Card>
            </Col>
            <Col span={12} style={{ marginTop: "22px" }}>
              <Row>
                <Col span={12}>
                  <Card bordered={false} className="Color-1-copy-10 "></Card>
                </Col>
                <Col span={12}>
                  <Card bordered={false} className="Color-1-copy-100"></Card>
                </Col>
              </Row>
              <Card bordered={false}>
                <Text strong>Midnight</Text> <br />
                <Text>#333333,#6666666</Text>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container maxWidth="xl" className="div-ui-padding">
          <Text style={{ color: "#6d7481", fontSize: "15" }}>02 </Text>
          <Text style={{ color: "#0c4da2", fontSize: "20px" }} strong>
            Typography
          </Text>
          <hr className="hr-style" />
        </Container>
      </section>
      <section>
        <Container maxWidth="xl" className="div-ui-padding">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Title>h1. Ant Design</Title>
              <Title level={2}>h2. Ant Design</Title>
              <Title level={3}>h3. Ant Design</Title>
              <Title level={4}>h4. Ant Design</Title>
              <Title level={5}>h5. Ant Design</Title>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <Text style={{ fontWeight: "bold" }}>
                  Font weight 500, Line Height 50
                </Text>
                <Text strong style={{ fontWeight: "500" }}>
                  Font weight 500, Line Height 50
                </Text>
                <Text strong style={{ fontWeight: "500" }}>
                  Font weight 500, Line Height 46
                </Text>
                <Text strong style={{ fontWeight: "500 !important" }}>
                  Font weight 500, Line Height 40
                </Text>
                <Text strong style={{ fontWeight: "500 !important" }}>
                  Font weight 500, Line Height 34
                </Text>
                <Text style={{ fontWeight: "900" }}>
                  Font weight 600, Line Height 34
                </Text>
              </Space>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container maxWidth="xl" className="div-ui-padding">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Space direction="vertical">
                <Text>Body and Text</Text>
                <Text type="secondary">
                  Kanit regular, 20px / Font weight 400 / Line height 34
                </Text>
                <Paragraph>
                  โพลารอยด์ผลักดันคาร์โก้แฟรี่ บ็อกซ์โต๊ะจีนโรแมนติก สจ๊วตยอม
                  รับนาฏยฮ็อตฟีเวอร์ มาร์ตโฮปแบต ฟีดเพรสตนเองมอลล์โอเปร่า
                  ชนะเลิศอึ้มราเมนบีรา โอเปร่าฟลุค ล้มเหลวสหัชญาณแคมปัส
                  แบล็คอพาร์ตเมนท์ฮิต
                </Paragraph>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <Text strong>Link, Hyperlink</Text>
                <Text type="secondary">Kanit regular, 18px</Text>
                <Text>โพลารอยด์ผลักดันคาร์โก้แฟรี่</Text>
                <Text underline>โพลารอยด์ผลักดันคาร์โก้แฟรี่</Text>
                <Text>
                  1. โพลารอยด์ผลักดันคาร์โก้แฟรี่ • โพลารอยด์ผลักดันคาร์โก้แฟรี่
                </Text>
              </Space>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default UiStyleIndex;
