import React, { useState, useEffect } from 'react';
import {
  Typography,
  Space,
  Row,
  Col,
  Button,
  Image,
  Collapse,
  Switch,
} from 'antd';

import {
  StarOutlined,
  ShareAltOutlined,
  FormOutlined,
  Loading3QuartersOutlined,
  FileOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@material-ui/core';
import { useClipboard } from 'use-clipboard-copy';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import ApiSurvey from '../../api/survey';
import './css/index.scss';
import { StaticImage } from '../../utils/image';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { ConvertDateShort } from '../../utils/date';
import _ from 'lodash';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { encodeBase64 } from '../../utils/convertBase64';

const { Text, Link, Title } = Typography;
const { Panel } = Collapse;

const DraftLaw = (props) => {
  const history = useHistory();
  const [survey, setsurvey] = useState([]);
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const [share, setshare] = useState({});
  const [showShare, setShowShare] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const [content_files, setcontent_files] = useState([]);
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  useEffect(() => {
    document.title = 'ร่างกฎหมาย';
    getSurvey();
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiSurvey.getDraftLaw({ survey_id });

      if (survey.status == 200) {
        Swal.close();
        const { data, content_files } = survey.data;
        setcontent_files(content_files);
        const groups = _.groupBy(data, 'id');
        if (data.length === 0) {
          Swal.fire(
            'เกิดข้อผิดพลาด',
            'ไม่พบข้อมูลร่างกฎหมายนี้',
            'warning'
          ).then((result) => {
            history.go(-1);
          });
          return false;
        }

        const itemSurvey = [];
        Object.keys(groups).map((key, index) => {
          const itemGroup = groups[key];
          itemSurvey.push({
            name: itemGroup[0].name,
            description: itemGroup[0].description,
            details: itemGroup,
            ...itemGroup[0],
          });
        });
        setsurvey(itemSurvey);

        setshare({
          title: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${data[0].survey_name
            } ภายใต้โครงการ ${data[0].project_name} ได้ที่ ${window.location.origin
            }/listeningDetail?survey_id=${data[0].survey_id
            } ตั้งแต่วันที่ ${ConvertDateShort(
              data[0].start_date,
              'DD MMM YY'
            )} ถึงวันที่ ${ConvertDateShort(data[0].end_date, 'DD MMM YY')} 

          โดยเมื่อกระบวนการรับฟังความคิดเห็นได้ดำเนินการเสร็จสิ้นแล้ว หน่วยงานผู้รับผิดชอบจะได้เผยแพร่สรุปผลการรับฟังความคิดเห็นและรายงานการวิเคราะห์ผลกระทบของร่างกฎหมายหรือรายงานการประเมินผลสัมฤทธิ์ของกฎหมาย (แล้วแต่กรณี) ซึ่งท่านสามารถเข้าถึงได้ตาม Link ข้างต้น
          
          มาร่วมเป็นส่วนหนึ่งในการสร้างกฎหมายของประชาชน`,
          subject: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${data[0].survey_name
            } ภายใต้โครงการ ${data[0].project_name} ได้ที่ ${window.location.origin
            }/listeningDetail?survey_id=${data[0].survey_id
            } ตั้งแต่วันที่ ${ConvertDateShort(
              data[0].start_date,
              'DD MMM YY'
            )} ถึงวันที่ ${ConvertDateShort(data[0].end_date, 'DD MMM YY')}`,
          shareUrl: `${window.location.origin}/listeningDetail?survey_id=${data[0].survey_id}`,
        });
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const callback = (key) => {
    setActiveKey(key);
  };

  function onChange(checked) {
    if (checked == true) {
      const activeKey = [];
      survey.map((item, index) => {
        activeKey.push(index);
      });
      setActiveKey(activeKey);
    } else {
      setActiveKey([]);
    }
  }

  const renderCollapseItem = () => {
    let components = [];
    survey.map((item, index) => {
      components.push(
        <Panel header={item.name} key={index}>
          <p
            className='ql-editor'
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></p>
          {item.details.filter((x) => x.detail_name).length > 0 &&
            renderContentItem(item.details)}
        </Panel>
      );
    });
    return components;
  };

  const renderContentItem = (items) => {
    let components = [];
    items.map((item, index) => {
      components.push(
        <div>
          <label style={{ fontSize: 16 }}>{item.detail_name}</label>
          <p
            style={{ marginLeft: 10, fontSize: 14 }}
            className='ql-editor'
            dangerouslySetInnerHTML={{ __html: item.detail_description }}
          ></p>
        </div>
      );
    });
    return components;
  };

  const renderCollapse = () => {
    return (
      <Collapse
        style={{ marginTop: 20 }}
        defaultActiveKey={[]}
        activeKey={activeKey}
        onChange={callback}
      >
        {renderCollapseItem()}
      </Collapse>
    );
  };

  return (
    <div>
      <section
        className='section-banner'
        style={{
          backgroundColor: '#f1f3f7',
        }}
      >
        <div onClick={() => history.go(-1)} className='back-btn'>
          <ArrowLeftOutlined style={{ marginRight: 6 }} />
          <label style={{ color: '#08c', fontSize: 16 }}>ย้อนกลับ</label>
        </div>
        {/* <Container maxWidth='xl'>
          <Text className='principle-header'>{`${survey[0]?.survey_name}`}</Text>
        </Container> */}
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <span className='project-head' style={{ padding: 10 }}>
            {survey[0]?.project_name}
          </span>
        </div>
      </section>
      <section style={{ paddingTop: '12px', paddingBottom: '12px' }}>
        <Container maxWidth='xl'>
          <Row>
            <Col span={12}>
              <Title level={5}>ร่างกฎหมาย</Title>
            </Col>
            <Col span={12} align='end'>
              {/* <Button icon={<StarOutlined />} /> */}
              <Button
                onClick={() => setShowShare(true)}
                icon={<ShareAltOutlined />}
                style={{ marginLeft: '8px', paddingTop: '1px' }}
              >
                แชร์
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ paddingBottom: 70 }}>
        <section
          style={{
            paddingTop: '12px',
            paddingBottom: '12px',
            backgroundColor: '#f1f3f7',
          }}
        ></section>
        {survey.length > 0 && (
          <Container maxWidth='xl' style={{ paddingTop: 20 }}>
            {/* <Row>
              <Col span={12}>
                <Switch
                  title={'แสดงเนื้อหาทั้งหมด'}
                  defaultChecked={false}
                  onChange={onChange}
                />
                <label style={{ marginLeft: 8 }}>แสดงเนื้อหาทั้งหมด</label>
              </Col>
            </Row> */}
            {renderCollapse()}
            {content_files && content_files.length > 0 && (
              <div style={{ marginTop: 15 }}>
                {content_files.map((item, index) => {
                  if (item.type !== 'draftlaw') {
                    return <></>;
                  }
                  return (
                    <div className='d-flex'>
                      <label
                        style={{ fontSize: 14 }}
                        className='text-link cursor-pointer'
                        onClick={() =>
                          window.open(`${item.file_path}`, '_blank')
                        }
                      >
                        {item.file_name}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </Container>
        )}
      </section>

      {showShare && (
        <div className='share-container'>
          <div className='header'>
            <span>แชร์</span>
            <img
              onClick={() => setShowShare(false)}
              style={{ position: 'absolute', right: 25 }}
              src={StaticImage('/images/icon/drawer-header-close.png')}
            />
          </div>
          {/* <div className='copy-button'>
            <div>
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/comment-alt-lines-light.png'
                  )}
                />
              </div>
              <span>ส่งข้อความ</span>
            </div>
            <div>
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/copy-light.png'
                  )}
                />
              </div>
              <span>คัดลอกข้อมูล</span>
            </div>
            <div
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/listeningDetail?survey_id=${survey.survey_id}`
                )
              }
            >
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/link-regular.png'
                  )}
                />
              </div>
              <span>คัดลอกลิงค์</span>
            </div>
          </div> */}

          <div className='share-button'>
            <div>
              <FacebookShareButton
                url={share.shareUrl}
                quote={share.title}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <span>Facebook</span>
            </div>
            {/* <div>
              <LineShareButton url={share.shareUrl} title={share.subject}>
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
            <div>
              <TwitterShareButton
                url={' '}
                title={share.title}
                className='Demo__some-network__share-button'
              >
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <span>Twitter</span>
            </div>
            <div>
              <EmailShareButton
                url={''}
                subject={share.subject}
                body={share.title}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <span>E-mail</span>
            </div>
            <div
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/listeningDetail?survey_id=${survey[0].survey_id}`
                )
              }
            >
              <div className='circle'>
                <img
                  src={StaticImage(
                    '/images/icon/listeningDetail/link-regular.png'
                  )}
                />
              </div>
              <span>คัดลอกลิงค์</span>
            </div>
          </div>
        </div>
      )}
      {survey.length > 0 && (
        <>
          {(survey[0].isconclude == 'O' || !survey[0].isconclude) &&
            survey[0].date_balance >= 0 && (
              <div
                onClick={() => {
                  history.push(`/DetailsList?survey_id=${encodeBase64(survey[0].survey_id)}`);

                }}
                className='bottom-button'
              >
                <div>
                  <FormOutlined />
                  <span>เริ่มแสดงความเห็น</span>
                </div>
              </div>
            )}
          {(survey[0].isconclude == 'O' || !survey[0].isconclude) &&
            survey[0].date_balance < 0 && (
              <div className='bottom-button'>
                <div className='disabled'>
                  <Loading3QuartersOutlined />
                  <span>อยู่ระหว่างสรุปผล</span>
                </div>
              </div>
            )}

          {survey[0].isconclude == 'D' && (
            <div className='bottom-button'>
              <div className='disabled'>
                <Loading3QuartersOutlined />
                <span>อยู่ระหว่างสรุปผล</span>
              </div>
            </div>
          )}
          {survey[0].isconclude == 'C' && (
            <div className='bottom-button'>
              <div className='disabled'>
                <CloseCircleOutlined />
                <span>ไม่มีการสรุปผล</span>
              </div>
            </div>
          )}
          {survey[0].isconclude == 'P' && (
            <div
              onClick={() => {
                history.push(`/Summary?survey_id=${encodeBase64(survey[0].survey_id)}`);
              }}
              className='bottom-button'
            >
              <div className='summary'>
                <FileOutlined />
                <span>อ่านสรุปผลการรับฟัง</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DraftLaw;
