import React, { useState, useEffect } from 'react';
import {
  Typography,
  Space,
  Row,
  Col,
  Button,
  Image,
  Collapse,
  Switch,
} from 'antd';

import {
  StarOutlined,
  ShareAltOutlined,
  FormOutlined,
  Loading3QuartersOutlined,
  FileOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@material-ui/core';
import { useClipboard } from 'use-clipboard-copy';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import ApiSurvey from '../../api/survey';
import './css/index.scss';
import { StaticImage } from '../../utils/image';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { ConvertDateShort } from '../../utils/date';
import YoutuberPlayer from '../react-player/YoutuberPlayer';
import { encodeBase64 } from '../../utils/convertBase64';

const { Text, Link, Title } = Typography;
const Conclusion = (props) => {
  const history = useHistory();
  const [survey, setsurvey] = useState([]);
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const [share, setshare] = useState({});
  const [showShare, setShowShare] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  useEffect(() => {
    getSurvey();
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiSurvey.getById({ survey_id });
      if (survey.status == 200) {
        Swal.close();
        const { data } = survey.data;
        setsurvey(data);
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };
  useEffect(() => {
    setshare({
      title: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${survey.survey_name
        } ภายใต้โครงการ ${survey.project_name} ได้ที่ ${window.location.origin
        }/listeningDetail?survey_id=${survey.survey_id
        } ตั้งแต่วันที่ ${ConvertDateShort(
          survey.start_date,
          'DD MMM YY'
        )} ถึงวันที่ ${ConvertDateShort(survey.end_date, 'DD MMM YY')}

      โดยเมื่อกระบวนการรับฟังความคิดเห็นได้ดำเนินการเสร็จสิ้นแล้ว หน่วยงานผู้รับผิดชอบจะได้เผยแพร่สรุปผลการรับฟังความคิดเห็นและรายงานการวิเคราะห์ผลกระทบของร่างกฎหมายหรือรายงานการประเมินผลสัมฤทธิ์ของกฎหมาย (แล้วแต่กรณี) ซึ่งท่านสามารถเข้าถึงได้ตาม Link ข้างต้น

      มาร่วมเป็นส่วนหนึ่งในการสร้างกฎหมายของประชาชน`,
      subject: `ขอเชิญชวนท่านร่วมแสดงความคิดเห็นต่อ ${survey.survey_name
        } ภายใต้โครงการ ${survey.project_name} ได้ที่ ${window.location.origin
        }/listeningDetail?survey_id=${survey.survey_id
        } ตั้งแต่วันที่ ${ConvertDateShort(
          survey.start_date,
          'DD MMM YY'
        )} ถึงวันที่ ${ConvertDateShort(survey.end_date, 'DD MMM YY')}`,
      shareUrl: `${window.location.origin}/listeningDetail?survey_id=${survey.survey_id}`,
    });
    return () => { };
  }, [survey]);
  return (
    <div>
      <section
        style={{
          paddingTop: "60px",
          backgroundColor: "#f1f3f7",
          paddingBottom: "10px",
        }}
      >
        <section style={{ padding: 10 }}>
          <span className="project-head">{survey?.project_name}</span>
        </section>
      </section>
      <section style={{ paddingTop: "12px", paddingBottom: "12px" }}>
        <Container maxWidth="xl">
          <Row>
            <Col span={16}>
              <Title level={5}>ข้อมูลสรุปจากหน่วยงาน</Title>
            </Col>
            <Col span={8} align="end">
              {/* <Button icon={<StarOutlined />} /> */}
              <Button
                onClick={() => setShowShare(true)}
                icon={<ShareAltOutlined />}
              >
                แชร์
              </Button>
            </Col>
          </Row>
          {survey.conclusion_link && (
            <div style={{ marginTop: 20 }}>
              <YoutuberPlayer url={survey.conclusion_link} />
            </div>
          )}
          <section style={{ marginTop: 20 }}>
            <div>
              <Title level={5} style={{ color: "#0c4da2" }}>
                รายละเอียด
              </Title>
              <div style={{ paddingTop: "5px" }}>
                <hr />
              </div>
            </div>
          </section>
          <section style={{ marginTop: 12 }}>
            <div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: survey.conclusion }}
              ></div>
            </div>
          </section>{" "}
        </Container>
      </section>

      {showShare && (
        <div className="share-container" style={{ overflowY: "scroll" }}>
          <div className="header">
            <span>แชร์</span>
            <img
              onClick={() => setShowShare(false)}
              style={{ position: "absolute", right: 25 }}
              src={StaticImage("/images/icon/drawer-header-close.png")}
            />
          </div>
          <div className="share-button">
            <div>
              <FacebookShareButton
                url={share.shareUrl}
                quote={share.title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={40} />
              </FacebookShareButton>
              <span>Facebook</span>
            </div>
            <div
              className="line-it-button"
              data-lang="en"
              data-type="share-b"
              data-ver="3"
              data-url="https://social-plugins.line.me/en/how_to_install"
              data-color="default"
              data-size="small"
              data-count="true"

            ></div>
            {/* <div>
              <LineShareButton url={share.shareUrl} title={share.subject}>
                <LineIcon size={40} />
              </LineShareButton>
              <span>LINE</span>
            </div> */}
            <div>
              <TwitterShareButton
                url={" "}
                title={share.title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={40} />
              </TwitterShareButton>
              <span>Twitter</span>
            </div>
            <div>
              <EmailShareButton
                url={""}
                subject={share.subject}
                body={share.title}
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={40} />
              </EmailShareButton>
              <span>E-mail</span>
            </div>

            <div
              onClick={() =>
                clipboard.copy(
                  `${window.location.origin}/listeningDetail?survey_id=${survey.survey_id}`
                )
              }
            >
              <div className="circle">
                <img
                  src={StaticImage(
                    "/images/icon/listeningDetail/link-regular.png"
                  )}
                />
              </div>
              <span>คัดลอกลิงค์</span>
            </div>
          </div>
        </div>
      )}
      {(survey.isconclude == "O" || !survey.isconclude) &&
        survey.date_balance >= 0 && (
          <div
            onClick={() => {
              history.push(`/DetailsList?survey_id=${encodeBase64(survey.survey_id)}`);
            }}
            className="bottom-button"
          >
            <div>
              <FormOutlined />
              <span>เริ่มแสดงความเห็น</span>
            </div>
          </div>
        )}
      {(survey.isconclude == "O" || !survey.isconclude) &&
        survey.date_balance < 0 && (
          <div className="bottom-button">
            <div className="disabled">
              <Loading3QuartersOutlined />
              <span>อยู่ระหว่างสรุปผล</span>
            </div>
          </div>
        )}
      {survey.isconclude == "D" && (
        <div className="bottom-button">
          <div className="disabled">
            <Loading3QuartersOutlined />
            <span>อยู่ระหว่างสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == "C" && (
        <div className="bottom-button">
          <div className="disabled">
            <CloseCircleOutlined />
            <span>ไม่มีการสรุปผล</span>
          </div>
        </div>
      )}
      {survey.isconclude == "P" && (
        <div
          onClick={() => {
            history.push(`/Summary?survey_id=${encodeBase64(survey.survey_id)}`);
          }}
          className="bottom-button"
        >
          <div className="summary">
            <FileOutlined />
            <span>อ่านสรุปผลการรับฟัง</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Conclusion;
