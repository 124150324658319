import { Container, Grid, Avatar } from '@material-ui/core';

import {
  Card,
  Input,
  Radio,
  Skeleton,
  Button,
  Space,
  Steps,
  Badge,
  Form,
  Select,
} from 'antd';
import React, { useState, useEffect } from 'react';
import './index.scss';
import { StaticImage } from '../../utils/image';
import { useHistory } from 'react-router-dom';
import ApiUser from '../../api/user';
import ApiMaster from '../../api/master';
import { useSelector, useDispatch } from 'react-redux';
import AlertError from '../../utils/AlertError';
import Divider from '@material-ui/core/Divider';
const LoginChangeNameIndexMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tab1, setTab1] = React.useState(true);
  const [tab2, setTab2] = React.useState(false);
  const [tab3, setTab3] = React.useState(false);
  const [checked, setchecked] = useState('');
  const [name_on_web, setname_on_web] = useState('');
  const [company, setcompany] = useState('');
  const [position, setposition] = useState('');
  const [is_show_name, setis_show_name] = useState(1);
  const [favorite_law_group, setfavorite_law_group] = useState([]);
  const [age_range, setage_range] = useState(0);
  const [sex, setsex] = useState(0);
  const [education, seteducation] = useState(0);
  const [old_address, setold_address] = useState(0);
  const [current_address, setcurrent_address] = useState(0);
  const [career, setcareer] = useState(0);
  const [auto_answer, setauto_answer] = useState(1);
  const [law_group, setlaw_group] = useState([]);
  const [sexList, setsexList] = useState([]);

  const [ageList, setageList] = useState([]);
  const [educationList, seteducationList] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const [provinceList, setprovinceList] = useState([]);
  const yearOld = [];
  const sexs = [];
  const educations = [];
  const skeletonLoading = [];

  const [current, setCurrent] = React.useState(1);

  const next = () => {
    if (current == 1) {
      if (!name_on_web || !checked) {
        AlertError('กรุณากรอกชื่อที่แสดงในระบบ');
        return false;
      }
    }

    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const [value, setValue] = React.useState(1);
  const onChange = (e) => {
    setis_show_name(e.target.value);
  };

  const onChangeAnswer = (e) => {
    setauto_answer(e.target.value);
  };

  const checkName = async () => {
    try {
      const result = await ApiUser.checkname(name_on_web);
      if (result.status == 200) {
        const { data } = result;
        if (!data) {
          setchecked(true);
        } else {
          setchecked(false);
        }
      }
    } catch (error) { }
  };

  const onFinish = async (values) => {
    // Swal.showLoading();
    // try {
    //   const verify = await ApiUser.verifyEmail(values);
    //   if (verify.status == 200) {
    //     setregisterData(values);
    //     showModal();
    //   }
    //   Swal.close();
    // } catch (error) {
    //   Swal.close();
    //   const { data } = error.response;
    //   AlertError('', data?.message);
    // }
  };

  useEffect(() => {
    lawGroup();
    masterLogin();
    return () => { };
  }, []);

  const lawGroup = async () => {
    const result = await ApiMaster.lawGroup();
    if (result.status) {
      const { data } = result;
      setlaw_group(data.data);
    }
  };

  const masterLogin = async () => {
    const result = await ApiMaster.masterLogin();
    if (result.status) {
      const { data } = result;
      const { ageList, educationList, sexList, provinces, careerList } = data;
      setsexList(sexList);
      setageList(ageList);
      seteducationList(educationList);
      setprovinceList(provinces);
      setCareerList(careerList);
    }
  };

  const handleFavorite = async (lawgroup_id) => {
    if (favorite_law_group.includes(lawgroup_id)) {
      const filter = favorite_law_group.filter((x) => x != lawgroup_id);
      console.log(filter);
      setfavorite_law_group(filter);
    } else {
      setfavorite_law_group([...favorite_law_group, lawgroup_id]);
    }
  };

  const onSubmit = async () => {
    const model = {
      is_first: 0,
      name_on_web,
      is_show_name,
      favorite_law_group: favorite_law_group.join(','),
      age_range: age_range,
      sex: sex,
      education: education,
      old_address: old_address,
      current_address: current_address,
      auto_answer,
      career: career,
      position,
      company,
    };
    try {
      const update = await ApiUser.updateFirst(model);
      if (update.status == 200) {
        const { data } = update;
        dispatch({ type: 'set_user', user: data });

        history.push('/');
      }
    } catch (error) { }
  };

  return (
    <div className='section-banner' style={{ padding: 20, paddingTop: 0 }}>
      <Space size={'middle'} direction='vertical' style={{ width: '100%' }}>
        <div
          className='d-flex'
          style={{ justifyContent: 'flex-end', marginBottom: 20 }}
        >
          {/* <button
            className='login-Rectangle-8-copy'
            onClick={() => {
              history.push('/');
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                className='forward-duotone'
                src={StaticImage('/images/login/forward-duotone.png')}
              />
              ข้าม
            </div>
          </button> */}
          <div
            className='d-flex'
            onClick={() => {
              history.push('/');
            }}
          >
            <label className='text-link cursor-pointer'>ทำทีหลัง</label>
          </div>
        </div>
        <div className='d-flex-center'>
          {current == 1 ? (
            <img
              height={73}
              width={91}
              src={StaticImage(
                '/images/login/userprofile/user-tag-duotone.svg'
              )}
            />
          ) : (
            <img
              height={88}
              width={66}
              src={StaticImage(
                '/images/login/userprofile/file-user-duotone.svg'
              )}
            />
          )}
        </div>
        <Space
          className='d-flex-center'
          size={'middle'}
          style={{ width: '100%', marginTop: 10 }}
        >
          <Badge
            dot
            style={{
              height: 16,
              width: 16,
              backgroundColor: current == 1 ? '#108ee9' : '#efefef',
            }}
          />
          <Badge
            dot
            style={{
              height: 16,
              width: 16,
              backgroundColor: current == 2 ? '#108ee9' : '#efefef',
            }}
          />
          {/* <Badge
            dot
            style={{
              height: 16,
              width: 16,
              backgroundColor: current == 3 ? '#108ee9' : '#efefef',
            }}
          /> */}
        </Space>
        {current == 1 && (
          <>
            <div className='d-flex'>
              <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                ชื่อที่จะแสดงในระบบ
              </label>
            </div>
            <Form name='basic' onFinish={onFinish}>
              <Form.Item name='name_on_web'>
                <div className='d-flex'>
                  <Input
                    className='flex-1'
                    size={'large'}
                    placeholder='กรอกชื่อที่จะแสดงในระบบ'
                    value={name_on_web}
                    onChange={(e) => {
                      setchecked('');
                      setname_on_web(e.target.value);
                    }}
                  />
                  <Button
                    style={{ height: 40 }}
                    onClick={checkName}
                    color={'success'}
                  >
                    ตรวจสอบ
                  </Button>
                </div>
              </Form.Item>

              {checked && (
                <div style={{ display: 'flex' }}>
                  <img
                    src={StaticImage(
                      '/images/login/check-circle-duotone-1-copy-2.png'
                    )}
                  />
                  <label style={{ paddingLeft: '0.7pc' }}>
                    สามารถใช้ชื่อนี้ได้
                  </label>
                </div>
              )}
              {checked === false && (
                <div style={{ display: 'flex' }}>
                  <img
                    src={StaticImage(
                      '/images/login/times-circle-duotone-copy-2.png'
                    )}
                  />
                  <label style={{ paddingLeft: '0.7pc' }}>
                    มีบัญชีนี้ในระบบแล้ว
                  </label>
                </div>
              )}
              {/* <div style={{ display: 'flex', marginTop: 20 }}>
                <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                  ระบบแสดงชื่อผู้ตอบความคิดเห็นอัตโนมัติ
                </label>
              </div>
              <div style={{ marginTop: 10 }}>
                <Radio.Group onChange={onChange} value={is_show_name}>
                  <Radio value={1}>อนุญาต</Radio>
                  <Radio value={0}>ยังก่อน</Radio>
                </Radio.Group>
              </div> */}
            </Form>
          </>
        )}
        {/* {current == 2 && (
          <>
            <div className='d-flex'>
              <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                เลือกกลุ่มหมวดหมู่ ร่าง/ กฎหมายที่สนใจ
              </label>
            </div>
            <div
              className='flex-wrap login-name-list-scholler'
              style={{
                justifyContent: 'space-between',
                overflow: 'hidden',
                maxHeight: 'inherit',
              }}
            >
              {law_group.map((item) => {
                return (
                  <div
                    style={{ marginTop: 10 }}
                    className='law-group-main'
                    onClick={() => handleFavorite(item.lawgroup_id)}
                  >
                    <center
                      style={{
                        width: 100,
                        opacity: favorite_law_group.includes(item.lawgroup_id)
                          ? 1
                          : 0.5,
                      }}
                      class='d-flex flex-column d-flex-center'
                    >
                      <div className='law-group-container d-flex-center'>
                        <div className='d-flex-center'>
                          {item.image ? (
                            <Avatar
                              className='avatar-object-contain'
                              size={{
                                xs: 75,
                                sm: 75,
                                md: 75,
                                lg: 75,
                                xl: 75,
                                xxl: 75,
                              }}
                              style={{
                                height: 75,
                                width: 75,
                              }}
                              // shape={'circle'}
                              src={item.image}
                            />
                          ) : (
                            <Skeleton.Button
                              style={{
                                backgroundColor: favorite_law_group.includes(
                                  item.lawgroup_id
                                )
                                  ? '#0c4da2'
                                  : '#efefef',
                              }}
                              active={true}
                              size={100}
                              shape={'circle'}
                            />
                          )}
                        </div>
                      </div>
                      <span style={{ fontSize: 15 }}>{item.lawgroup_name}</span>
                    </center>
                  </div>
                );
              })}
            </div>
          </>
        )} */}
        {current == 2 && (
          <>
            <div className='d-flex-center'>
              <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                ข้อมูลส่วนตัว
              </label>
            </div>

            <Form
              name='basic'
              style={{ paddingBottom: 100 }}
              onFinish={onFinish}
            >
              <Space
                size={'middle'}
                direction='vertical'
                style={{ width: '100%' }}
              >
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      ช่วงอายุ
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Select
                      style={{ width: '100%' }}
                      className='basic-single'
                      classNamePrefix='select'
                      placeholder='ช่วงอายุ'
                      onChange={(e) => setage_range(e)}
                      options={ageList.map((item) => {
                        return {
                          label: item.valuetext,
                          value: item.valuecode,
                        };
                      })}
                    />
                  </div>
                </>
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      เพศ
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      style={{ width: '100%' }}
                      placeholder='เพศ'
                      onChange={(e) => setsex(e)}
                      options={sexList.map((item) => {
                        return {
                          label: item.valuetext,
                          value: item.valuecode,
                        };
                      })}
                    />
                  </div>
                </>
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      การศึกษา
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      placeholder='การศึกษา'
                      style={{ width: '100%' }}
                      onChange={(e) => seteducation(e)}
                      options={educationList.map((item) => {
                        return {
                          label: item.valuetext,
                          value: item.valuecode,
                        };
                      })}
                    />
                  </div>
                </>

                <div className='d-flex-center' style={{ marginTop: 20 }}>
                  <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                    พื้นที่ของผู้ที่ได้รับผลกระทบ
                  </label>
                </div>
                <hr />
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      ภูมิลำเนาเดิม
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      placeholder='ภูมิลำเนาเดิม'
                      style={{ width: '100%' }}
                      onChange={(e) => setold_address(e)}
                      options={provinceList.map((item) => {
                        return {
                          label: item.name_th,
                          value: item.code,
                        };
                      })}
                    />
                  </div>
                </>
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      ที่อยู่ปัจจุบัน
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      placeholder='ที่อยู่ปัจจุบัน'
                      style={{ width: '100%' }}
                      onChange={(e) => setcurrent_address(e)}
                      options={provinceList.map((item) => {
                        return {
                          label: item.name_th,
                          value: item.code,
                        };
                      })}
                    />
                  </div>
                </>
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      ให้ระบบดึงข้อมูลไปใส่เมื่อถูกถามคำถามเดิมซ้ำๆ
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Radio.Group onChange={onChangeAnswer} value={auto_answer}>
                      <Radio value={1}>อนุญาต</Radio>
                      <Radio value={0}>ยังก่อน</Radio>
                    </Radio.Group>
                  </div>
                </>
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      อาชีพ
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      placeholder='อาชีพ'
                      onChange={(e) => setcareer(e)}
                      style={{ width: '100%' }}
                      options={careerList.map((item) => {
                        return {
                          label: item.valuetext,
                          value: item.valuecode,
                        };
                      })}
                    />
                  </div>
                </>
                <div className='d-flex-center'>
                  <label style={{ fontSize: 18, fontWeight: 'bold', marginTop: 15 }}>
                    ข้อมูลสำหรับหน่วยงานภาครัฐ
                  </label>
                </div>
                <Divider
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                  }}
                />
                <>


                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      ตำแหน่งงาน
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Input
                      size={'large'}
                      placeholder='ตำแหน่งงาน'
                      value={position}
                      onChange={(e) => {
                        setposition(e.target.value);
                      }}
                    />
                  </div>
                </>
                <>
                  <div>
                    <label style={{ fontSize: 18, fontWeight: 'bold' }}>
                      หน่วยงานที่สังกัด
                    </label>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Input
                      size={'large'}
                      placeholder='หน่วยงานที่สังกัด'
                      value={company}
                      onChange={(e) => {
                        setcompany(e.target.value);
                      }}
                    />
                  </div>
                </>
              </Space>
            </Form>
          </>
        )}
      </Space>

      <div
        className='home-button-bottom d-flex'
        style={{
          position: 'fixed',
          zIndex: 2,
          backgroundColor: '#fff',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 0,
          height: 66,
          bottom: 0,
        }}
      >
        <div className='flex-1 d-flex-center flex-column'>
          <img
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            style={{ marginBottom: 5 }}
            src={StaticImage('/images/icon/home-lg-alt-duotone.png')}
          />
          <span
            onClick={() => {
              history.push('/');
              // setResult(false);
            }}
            style={{ color: '#8e99ad', fontSize: 16 }}
          >
            กลับหน้าหลัก
          </span>
        </div>
        <div className='flex-1 d-flex-center flex-column'>
          <img
            onClick={() => {
              if (current == 2) {
                onSubmit();
              } else {
                next();
              }
              // setResult(false);
            }}
            style={{ marginBottom: 5 }}
            src={StaticImage('/images/icon/arrow-right-duotone.png')}
          />
          <span
            onClick={() => {
              if (current == 2) {
                onSubmit();
              } else {
                next();
              }
            }}
            style={{ color: '#8e99ad', fontSize: 16 }}
          >
            {current == 2 ? 'บันทึก' : 'ถัดไป'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginChangeNameIndexMobile;
