import React, { useEffect, useState } from 'react';
import '../../main/submenulist.scss';
import ApiSurvey from '../../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../../utils/image';
// import YoutuberPlayer from "./react-player/YoutuberPlayer";
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import Select from 'react-select';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { Container, fade, Grid, makeStyles } from '@material-ui/core';
import { Card, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import PersonIcon from '@material-ui/icons/Person';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TagDuoTone from '../../iconComponent/TagDuoTone';
import CommentAltEdit from '../../iconComponent/CommentAltEdit';
import './index.scss';
import { ConvertDateShort } from '../../../utils/date';
import ProgressBar from './ProgressBar';
import ApiMaster from '../../../api/master';
import { FavoriteBorder } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { encodeBase64 } from '../../../utils/convertBase64';
const defaultProps = {
  bgcolor: '#ffffff',
  style: { width: '97px', height: '97px' },
};
const defaultPropsRight = {
  bgcolor: '#0c4da2',
  style: { width: '97px', height: '97px', cursor: 'pointer' },
};

const scoreText = [
  { value: 'last', label: '#ล่าสุด', color: '#0c4da2' },
  { value: 'answer', label: '#ตอบมากที่สุด', color: '#a51830' },
  { value: 'read', label: '#อ่านมากที่สุด', color: '#387436' },
  { value: 'popular', label: '#ความนิยม', color: '#0c4da2' },
];

const listTextOption = [
  { value: 'ทั้งหมด', label: 'ทั้งหมด' },
  { value: 'เปิดรับฟัง', label: 'เปิดรับฟัง' },
  { value: 'ปิดรับฟัง', label: 'ปิดรับฟัง' },
];

const cardListOption = [
  {
    name: 'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ ...',
    detail:
      'การรับฟังความคิดเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอ็นทรานซ์ สตูดิโอซี้สต็อกคอรัปชั่นพูล ซีรีส์พาร์ตเนอร์แบนเนอร์สะเด่ารัมแอสเตอร์ สี่แยกซีอีโอบู๊ โรลออนตื้บป๋อหลอ โปรดักชั่น ไฮบริด ...',
  },
  {
    name: 'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ ...',
    detail:
      'การรับฟังความคิดเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอ็นทรานซ์ สตูดิโอซี้สต็อกคอรัปชั่นพูล ซีรีส์พาร์ตเนอร์แบนเนอร์สะเด่ารัมแอสเตอร์ สี่แยกซีอีโอบู๊ โรลออนตื้บป๋อหลอ โปรดักชั่น ไฮบริด ...',
  },
  {
    name: 'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ ...',
    detail:
      'การรับฟังความคิดเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอ็นทรานซ์ สตูดิโอซี้สต็อกคอรัปชั่นพูล ซีรีส์พาร์ตเนอร์แบนเนอร์สะเด่ารัมแอสเตอร์ สี่แยกซีอีโอบู๊ โรลออนตื้บป๋อหลอ โปรดักชั่น ไฮบริด ...',
  },
  {
    name: 'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ ...',
    detail:
      'การรับฟังความคิดเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอ็นทรานซ์ สตูดิโอซี้สต็อกคอรัปชั่นพูล ซีรีส์พาร์ตเนอร์แบนเนอร์สะเด่ารัมแอสเตอร์ สี่แยกซีอีโอบู๊ โรลออนตื้บป๋อหลอ โปรดักชั่น ไฮบริด ...',
  },
  {
    name: 'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ ...',
    detail:
      'การรับฟังความคิดเห็น เลขที่ อกม-3. 43/2563 เอ๊าะ เอ็นทรานซ์ สตูดิโอซี้สต็อกคอรัปชั่นพูล ซีรีส์พาร์ตเนอร์แบนเนอร์สะเด่ารัมแอสเตอร์ สี่แยกซีอีโอบู๊ โรลออนตื้บป๋อหลอ โปรดักชั่น ไฮบริด ...',
  },
];

const cardDetailOption = [
  {
    name:
      'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ พ.ศ. ...',
    logoImages: '/images/layer-18.png',
    likeNumber: '1.45 M',
    iconOne: '',
    iconTwo: '',
    guestName: 'Guest',
    detail: (
      <>
        {' '}
        โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์ <br />
        และตลาดหลักทรัพย์ พ.ศ. 2535 เกี่ยวกับการ...
      </>
    ),
    subDetail: (
      <>
        แกสโซฮอล์ทัวร์นาเมนท์แบตไลฟ์มอยส์เจอไรเซอร์ ใช้งานฟลุตมิลค์คอมเมนต์ร็อค
        สโรชาไลน์ซูโม่คอปเตอร์ เซฟตี้วีไอพีสเปกแพนด้าซิ่ง แพนด้า โปรเจกต์ซีน ...
      </>
    ),
  },
  {
    name:
      'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ พ.ศ. ...',
    logoImages: '/images/layer-18.png',
    likeNumber: '0.97 M',
    iconOne: (
      <Avatar
        style={{ backgroundColor: '#387436', marginBottom: '1pc' }}
      ></Avatar>
    ),
    iconTwo: '',
    guestName: 'Aumpawa',
    detail: (
      <>
        {' '}
        <div style={{ fontSize: '15px' }}>
          {' '}
          โครงการแก้ไขเพิ่มเติมพระราชบัญญัติ
          <br />
          หลักทรัพย์ และตลาดหลักทรัพย์ พ.ศ. 2535 <br />
          เกี่ยวกับการ...
        </div>
      </>
    ),
    subDetail: (
      <>
        แกสโซฮอล์ทัวร์นาเมนท์แบตไลฟ์มอยส์เจอไรเซอร์ ใช้งานฟลุตมิลค์คอมเมนต์ร็อค
        สโรชาไลน์ซูโม่คอปเตอร์ เซฟตี้วีไอพีสเปกแพนด้าซิ่ง แพนด้า โปรเจกต์ซีน ...
      </>
    ),
  },
  {
    name:
      'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ พ.ศ. ...',
    logoImages: '/images/layer-18.png',
    likeNumber: '0.97 M',
    iconOne: <Avatar style={{ backgroundColor: '#387436' }}></Avatar>,
    iconTwo: '',
    guestName: 'Lapis',
    detail: (
      <>
        {' '}
        <div style={{ fontSize: '15px' }}>
          {' '}
          โครงการแก้ไขเพิ่มเติมพระราชบัญญัติ
          <br />
          หลักทรัพย์ และตลาดหลักทรัพย์ พ.ศ. 2535 <br />
          เกี่ยวกับการ...
        </div>
      </>
    ),
    subDetail: (
      <>
        แกสโซฮอล์ทัวร์นาเมนท์แบตไลฟ์มอยส์เจอไรเซอร์ ใช้งานฟลุตมิลค์คอมเมนต์ร็อค
        สโรชาไลน์ซูโม่คอปเตอร์ เซฟตี้วีไอพีสเปกแพนด้าซิ่ง แพนด้า โปรเจกต์ซีน ...
      </>
    ),
  },
  {
    name:
      'โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์และตลาดหลักทรัพย์ พ.ศ. ...',
    logoImages: '/images/layer-18.png',
    likeNumber: '0.97 M',
    iconOne: '',
    iconTwo: '',
    guestName: 'จริมา แสงทอง',
    detail: (
      <>
        {' '}
        โครงการแก้ไขเพิ่มเติมพระราชบัญญัติหลักทรัพย์ <br />
        และตลาดหลักทรัพย์ พ.ศ. 2535 เกี่ยวกับการ...
      </>
    ),
    subDetail: (
      <>
        แกสโซฮอล์ทัวร์นาเมนท์แบตไลฟ์มอยส์เจอไรเซอร์ ใช้งานฟลุตมิลค์คอมเมนต์ร็อค
        สโรชาไลน์ซูโม่คอปเตอร์ เซฟตี้วีไอพีสเปกแพนด้าซิ่ง แพนด้า โปรเจกต์ซีน ...
      </>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectOption: {
    zIndex: 10,
  },
  cardSeq: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 30,
    padding: 10,
    boxShadow: '3.9px 1px 6px 0 rgba(137, 148, 169, 0.4)',
  },
  cardSeqLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardSurveyTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    flex: 1,
  },
  cardSurveyDescription: {
    color: '#666666',
    fontSize: 15,
  },
  cardSurveyTypeImage: {
    width: 20,
    height: 20,
    marginRight: 5,
    objectFit: 'contain',
    color: '#0c4da2',
    paddingTop: 5,
  },
  cardSurveyTypeText: {
    fontSize: 14,
    color: '#0c4da2',
    fontWeight: 'bold',
  },
  cardSurveyLeftIcon: {
    width: 101,
    height: 101,
    padding: '18px 22px 20px 31px',
    borderRadius: 50,
    border: 'solid 5px #85b0e8',
    backgroundColor: '#0c4da2',
  },
  cardSurveyCommentIcon: {
    fontSize: 14,
    color: '#999',
  },
}));

const deMoOption = [{ value: 'ทั้งหมด', label: 'ทั้งหมด' }];

const SurveyCard = ({
  surveys = [],
  getFilterSurvey,
  getOrderSurvey,
  handleFavorite,
}) => {
  const classes = useStyles();
  const [agency, setagency] = useState(null);
  const [ministry, setministry] = useState(null);
  const [surveyType, setsurveyType] = useState(null);
  const [orderFilter, setorderFilter] = useState(null);
  const [agencyOption, setAgencyOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);
  const [ministryOption, setMinistryOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);
  const [surveyTypeOption, setSurveyTypeOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);

  const history = useHistory();

  const checkComplete = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance > item.duration_day) {
        return 0;
      } else {
        return (
          ((item.duration_day - item.date_balance) / item.duration_day) * 100
        );
      }
    } else {
      return 100;
    }
  };
  const checkLeftTime = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance < 0) {
        return 'ปิดการรับฟัง';
      } else {
        return `เหลือ ${item.date_balance + 1} วัน`;
      }
    } else if (item.isconclude == 'D') {
      return 'อยู่ระหว่างการสรุปผล';
    } else if (item.isconclude == 'C') {
      return 'ไม่มีการสรุปผล';
    } else if (item.isconclude == 'B') {
      return 'ปิดก่อนกำหนด';
    } else if (item.isconclude == 'P') {
      return 'เผยแพร่สรุปผลการรับฟัง';
    }
  };

  const checkCardBackground = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance < 0) {
        return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
      } else {
        return {};
      }
    } else if (item.isconclude == 'D') {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    } else if (item.isconclude == 'C') {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    } else if (item.isconclude == 'B') {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    } else if (item.isconclude == 'P') {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <div>
        {surveys.map((res, i) => {
          return (
            <section
              onClick={() => {
                {
                  if (res.is_coming === '1') {
                  } else {
                    history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
                  }
                }
              }}
              key={'card-' + res.survey_id}
              style={{ marginBottom: 20, zoom: '90%' }}
            >
              <div className='card-two'>
                <div
                  className='card-list-shadow-web'
                  style={{
                    borderRadius: '10px',
                    height: 250,
                    padding: 24,
                  }}
                >
                  <div style={{ display: 'flex', height: '100%' }}>
                    <div
                      style={{
                        borderRight: '1px solid #e5e6ee',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div className={classes.cardSurveyLeftIcon}></div>
                      <div
                        className='d-flex-center'
                        style={{ marginTop: 20, minWidth: 170 }}
                      >
                        {res.agency_id > 0 && (
                          <div
                            className='organize-container'
                            style={{ margin: 0, maxWidth: 100 }}
                          >
                            <img
                              src={res.agency_image ?? '..'}
                              className='logo'
                              oncontextmenu='return false;'
                              onError={(i) => (i.target.style.display = 'none')}
                            />
                            <label style={{ paddingLeft: '2px' }}>
                              {res.agency_abbrv}
                            </label>
                          </div>
                        )}
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <TagDuoTone className={classes.cardSurveyTypeImage} />
                        <span className={classes.cardSurveyTypeText}>
                          {res.survey_type_name}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: 40,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <label className={classes.cardSurveyTitle}>
                            {res.project_name}
                          </label>
                          <div
                            onClick={(e) => handleFavorite(e, res)}
                            className='d-flex-center'
                            style={{
                              width: 50,
                              height: 50,
                              backgroundColor: 'red',
                              borderRadius: 25,
                              boxShadow:
                                '2.8px 2.8px 5px 0 rgba(137, 148, 169, 0.2), -2.1px -2.1px 8px 0 rgba(137, 148, 169, 0.15), inset 3.5px 3.5px 10px 0 #ffffff, inset -3.5px -3.5px 10px 0 rgba(188, 194, 211, 0.25)',
                              backgroundColor: '#ffffff',
                            }}
                          >
                            <FavoriteBorder
                              style={{
                                color:
                                  res.is_like == true ? '#0c4da2' : '#afafaf',
                              }}
                            />
                          </div>
                        </div>
                        <label className={classes.cardSurveyDescription}>
                          {res.survey_name}
                        </label>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 20,
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ marginRight: 15 }}>
                            <label style={{ color: '#488345', fontSize: 14 }}>
                              กำลังร่วมเสนอ
                            </label>
                          </div>
                          <div className='icon-tag-duo-tone-container'>
                            <CommentAltEdit className='icon-comment-alt-edit' />
                            <label
                              className='icon-comment-alt-label'
                              style={{ fontSize: 14 }}
                            >
                              {res.answer_count ?? 0}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', flex: 1 }}>
                          <span style={{ fontSize: 16 }}>
                            {ConvertDateShort(res.start_date, 'DD MMM YY')}
                          </span>
                          <section
                            className='progress-list-filter'
                            style={{ width: '100%', maxWidth: 400 }}
                          >
                            <ProgressBar
                              bgcolor={
                                res.date_balance > 7 ? '#387436' : '#f96b57'
                              }
                              bgContainerColor={
                                res.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'
                              }
                              leftTime={checkLeftTime(res)}
                              completed={checkComplete(res)}
                            />
                          </section>
                          <span>
                            <span style={{ fontSize: 16 }}>
                              {ConvertDateShort(res.end_date, 'DD MMM YY')}
                            </span>
                          </span>
                        </div>
                        {/* <div className='icon-tag-duo-tone-container'>
                                <CommentAltEdit className='icon-comment-alt-edit' />
                                <label className='icon-comment-alt-label'>
                                  {res.answer_count ?? 0}
                                </label>
                              </div> */}
                        <Space>
                          <span
                            style={{
                              color: '#494949',
                              fontSize: 16,
                              marginRight: 5,
                              fontWeight: 'bold',
                            }}
                          >
                            ครั้งที่
                          </span>
                          <span
                            style={{
                              color: '#0c4da2',
                              fontSize: 16,
                              fontWeight: 'bold',
                            }}
                          >
                            00
                          </span>
                        </Space>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='card-three'>
                      {' '}
                      <Card
                        className='card-list-shadow-web'
                        style={{ width: '97%', borderRadius: '10px' }}
                      ></Card>
                    </div>
                    <div className='card-four'>
                      {' '}
                      <Card
                        className='card-list-shadow-web'
                        style={{ width: '95%', borderRadius: '10px' }}
                      ></Card>
                    </div>
                  */}
            </section>
          );
        })}
      </div>
    </div>
  );
};

export default SurveyCard;
