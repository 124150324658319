import { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { ConvertDateShort } from '../../utils/date';
import { CalendarOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../utils/image';
import './post.scss';
import SmileIcon from '../../icons/Smile';
import { Avatar, Input, List } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ApiWebBoard from '../../api/post';
import Swal from 'sweetalert2';
import { checkUser, checkUserBool } from '../../utils/checkUser';
import GrinDuoTone from '../iconComponent/GrinDuoTone';
import CommentAltEdit from '../iconComponent/CommentAltEdit';
import GrinIcon from '../iconComponent/GrinIcon';
import { Close, MoreHorizOutlined } from '@material-ui/icons';
import PencilIcon from '../iconComponent/PencilIcon';
import TrashIcon from '../iconComponent/TrashIcon';
import WarningIcon from '../iconComponent/WarningIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Actionsheet from './../../utils/ActionSheet';
import Select from 'react-select';
import AlertError from '../../utils/AlertError';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  faArrowAltCircleUp,
  faArrowCircleDown,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
let active_postid = 0;
const Posts = (props) => {
  const project_id = new URLSearchParams(props.location.search).get(
    'project_id'
  );
  const survey_id = new URLSearchParams(props.location.search).get('survey_id');
  const userState = useSelector((state) => state.user);
  const history = useHistory();
  const [spamMaster, setspamMaster] = useState([]);
  const [project, setprojectData] = useState({});
  const [posts, setPosts] = useState([]);
  const [posts_filter, setposts_filter] = useState([]);
  const [post_agency, set_post_agency] = useState([]);
  const [actionSheet, setactionSheet] = useState(false);
  const [actionSheetMessage, setactionSheetMessage] = useState(false);
  var clientHeight = document.getElementById('content_agency')?.clientHeight;
  useEffect(() => {
    getSurvey();
    if (checkUserBool(userState)) {
      getPostAgency();
    }
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const result = await ApiWebBoard.getPost({
        project_id,
        username: userState.username,
      });
      if (result.status == 200) {
        Swal.close();
        const { posts, spam, project } = result.data;
        // if (project.is_webboard !== 1) {
        //   throw 'ห้องสนทนาถูกปิดโดยหน่วยงาน';
        // }
        setprojectData(project);
        setPosts(posts);
        setposts_filter(posts);
        console.log('posts', posts);
        setspamMaster(
          spam.map((x) => {
            return {
              content: x.valuetext,
              id: x.mastervalue_id,
              onClick: (item) => actionSheetClick(item),
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const getPostAgency = async () => {
    try {
      const result = await ApiWebBoard.getPostAgency({
        project_id,
        username: userState.username,
      });
      if (result.status == 200) {
        const { posts } = result.data;
        console.log('postsagency::', posts);
        set_post_agency(posts);
      }
    } catch (error) {
      console.log(error);
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const actionSheetShow = (id) => {
    setactionSheet(!actionSheet);
  };

  const actionSheetShowActive = (item) => {
    // setactive_postid(item.id);
    if (checkUser(userState)) {
      active_postid = item.id;
      setactionSheet(true);
    }
  };

  const actionSheetClick = async (item) => {
    if (checkUser(userState)) {
      const model = {
        relate_id: active_postid,
        spam_id: item.id,
        table_type: 'post',
      };
      try {
        const result = await ApiWebBoard.spamPost(model);
        if (result.status == 200) {
          Swal.fire({
            title: 'สำเร็จ',
            text: 'ส่งรายงานความเห็นนี้',
            icon: 'success',
            timer: 2000,
          }).then((ok) => { });
        }
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    }
  };
  const likePost = async (item) => {
    if (checkUser(userState)) {
      const model = {
        relate_id: item.id,
        table_type: 'post',
      };
      try {
        const result = await ApiWebBoard.likePost(model);
        if (result.status == 200) {
          const likes = [...posts];
          const index = likes.findIndex((x) => x.id == item.id);
          likes[index].count_like = likes[index].is_like
            ? parseInt(likes[index].count_like) - 1
            : parseInt(likes[index].count_like) + 1;
          likes[index].is_like = !likes[index].is_like;
          setPosts(likes);
          // likes[index].is_like =
        }
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    }
  };

  const likeComment = async (item) => {
    if (checkUser(userState)) {
      const model = {
        relate_id: item.id,
        table_type: 'comment',
      };
      try {
        const result = await ApiWebBoard.likePost(model);
        if (result.status == 200) {
          const likes = [...posts];
          const index = likes.findIndex((x) => x.id == item.post_id);
          const comments = [...likes[index].comments];

          const indexComment = comments.findIndex((x) => x.id == item.id);
          comments[indexComment].count_like = comments[indexComment].is_like
            ? parseInt(comments[indexComment].count_like) - 1
            : parseInt(comments[indexComment].count_like) + 1;
          comments[indexComment].is_like = !comments[indexComment].is_like;
          likes[index].comments = comments;
          setPosts(likes);
        }
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    }
  };

  const handleSubmitPublicPost = async (message) => {
    if (!message) {
      AlertError('', 'กรุณาพิมพ์ข้อความ');
      return false;
    }
    Swal.showLoading();
    try {
      const model = {
        message: message,
        project_id: project_id,
        send_to: 'P',
      };
      const result = await ApiWebBoard.createPost(model);
      if (result.status == 200) {
        Swal.fire({
          title: 'สำเร็จ',
          text: 'ส่งความคิดเห็นสำเร็จ',
          icon: 'success',
          timer: 2000,
        }).then((ok) => {
          const { data } = result.data;
          data.is_verify = userState.is_verify;
          data.image_profile = userState.image_profile;
          data.name_on_web = userState.name_on_web;
          data.is_like = 0;
          data.count_like = 0;
          data.comments = [];
          setPosts([...posts, data]);
          setposts_filter([data, ...posts_filter]);
        });
      } else {
        throw Error('');
      }
    } catch (error) {
      Swal.close();
    }
  };

  const handleSubmitPublicComment = async (message, post_id) => {
    if (!message) {
      AlertError('', 'กรุณาพิมพ์ข้อความ');
      return false;
    }
    Swal.showLoading();
    try {
      const model = {
        message: message,
        post_id: post_id,
      };
      const result = await ApiWebBoard.createComment(model);
      if (result.status == 200) {
        Swal.fire({
          title: 'สำเร็จ',
          text: 'ส่งความคิดเห็นสำเร็จ',
          icon: 'success',
          timer: 2000,
        }).then((ok) => {
          // getSurvey();
          const { data } = result.data;
          data.is_verify = userState.is_verify;
          data.image_profile = userState.image_profile;
          data.name_on_web = userState.name_on_web;
          data.is_like = 0;
          data.count_like = 0;
          const likes = [...posts_filter];
          const index = likes.findIndex((x) => x.id == post_id);
          const comments = [...likes[index].comments, data];
          likes[index].comments = comments;
          setPosts([...likes]);
          setposts_filter([...likes]);
        });
      } else {
        throw Error('');
      }
    } catch (error) {
      Swal.close();
    }
  };

  const handleSubmitPublicPostEdit = async (message, post_id) => {
    if (!message) {
      AlertError('', 'กรุณาพิมพ์ข้อความ');
      return false;
    }
    Swal.showLoading();
    try {
      const model = {
        message: message,
        id: post_id,
      };
      const result = await ApiWebBoard.createPost(model);
      if (result.status == 200) {
        Swal.fire({
          title: 'สำเร็จ',
          text: 'ส่งความคิดเห็นสำเร็จ',
          icon: 'success',
          timer: 2000,
        }).then((ok) => {
          // getSurvey();
          const likes = [...posts_filter];
          const index = likes.findIndex((x) => x.id == post_id);
          likes[index].message = message;
          likes[index].update_by = result.data.data.updated_user;
          setPosts([...likes]);
          setposts_filter([...likes]);
        });
      } else {
        throw Error('');
      }
    } catch (error) {
      Swal.close();
    }
  };

  const handleSubmitAgencyPost = async (message) => {
    if (!message) {
      AlertError('', 'กรุณาพิมพ์ข้อความ');
      return false;
    }
    Swal.showLoading();
    try {
      const model = {
        message: message,
        project_id: project_id,
        send_to: 'A',
      };
      const result = await ApiWebBoard.createPost(model);
      if (result.status == 200) {
        Swal.fire({
          title: 'สำเร็จ',
          text: 'ส่งความคิดเห็นสำเร็จ',
          icon: 'success',
          timer: 2000,
        }).then((ok) => {
          getPostAgency();
        });
      } else {
        throw Error('');
      }
    } catch (error) {
      Swal.close();
    }
  };

  const deletePostPublic = async (item) => {
    Swal.fire({
      title: 'คุณต้องการลบรายการสนทนานี้ใช่หรือไม่',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true,
    }).then(async (ok) => {
      if (ok.isConfirmed) {
        let results = await ApiWebBoard.deletePost(item.id);
        const likes = [...posts_filter];
        const index = likes.findIndex((x) => x.id == item.id);
        likes[index].record_status = 'I';
        setPosts([...likes]);
        setposts_filter([...likes]);
        if (results.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          });
        }
      }
    });
  };

  const optionArr = [
    { label: 'ใหม่-เก่า', value: 'desc' },
    { label: 'เก่า-ใหม่', value: 'asc' },
  ];

  return (
    <div id='post-page' >
      <div className='section-banner' ></div>
      <div
        className='d-flex-center flex-column w-100'
        style={{
          position: 'absolute',
          marginTop: -50,
          top: 0,
          height: 300,
          backgroundColor: '#0c4da2',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: -1,
        }}
      ></div>
      <div
        className='d-flex-center flex-column'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container>
          <div
            className='d-flex menu-top'
            style={{ justifyContent: 'space-between' }}
          >
            <div
              className='cursor-pointer'
              onClick={() => {
                if (survey_id) {
                  history.push(`listeningDetail?survey_id=${survey_id}`);
                } else {
                  history.push(`ProjectTimeline?projectId=${project_id}`);
                }
              }}
            >
              <span> {survey_id ? 'ย้อนกลับ' : 'ย้อนกลับไปที่โครงการ'}</span>
            </div>
            <div
              className='cursor-pointer'
              onClick={() => {
                history.push(`Rule`);
              }}
            >
              <span>กฏ กติกาและมารยาท</span>
            </div>
          </div>
          <div className='post-project-card'>
            <div className='d-flex'>
              <div className='d-flex-column'>
                <div
                  className='d-flex project-id'
                  style={{ marginBottom: 10, alignItems: 'center' }}
                >
                  <img
                    src={StaticImage('/images/smile.svg')}
                    height={30}
                    width={30}
                    style={{ marginRight: 10 }}
                  />
                  <label style={{ marginRight: 10 }}>
                    เลขที่ (ID) {project_id}
                  </label>
                  <div className='d-flex'>
                    <CalendarOutlined />
                    <label style={{ marginLeft: 5 }}>
                      {ConvertDateShort(
                        project.created_date,
                        'DD MMM YYYY เวลา HH:mm น.'
                      )}
                    </label>
                  </div>
                </div>
                <span className='project-head'>{project.project_name}</span>
              </div>
            </div>
            {project.webboard_message && <Announement clientHeight={clientHeight ?? 0} project={project} />}
          </div>


          <ChatAgency
            userState={userState}
            handleSubmitAgencyPost={handleSubmitAgencyPost}
          />
          <div id="scrollToTopContent"></div>

          {post_agency.length > 0 && (
            <List
              itemLayout='vertical'
              size='large'
              style={{ padding: 20 }}
              pagination={{
                onChange: (page) => {
                  const section = document.getElementById('scrollToTopContent');
                  section.scrollIntoView({ behavior: 'smooth', block: 'end' });
                },
                pageSize: 5,
              }}
              dataSource={post_agency}
              renderItem={(res, i) => (
                <PostAgency
                  key={'post-id-' + res.id}
                  item={res}
                  userState={userState}
                />
              )}
            />
          )}

          <ChatPublic
            userState={userState}
            handleSubmitPublicPost={handleSubmitPublicPost}
          />
          <div
            className='d-flex'
            style={{ flex: 1, marginLeft: 10, marginTop: 20 }}
          >
            <div className='flex-1'></div>
            <div style={{ width: 150 }}>
              <label style={{ fontSize: 14, marginBottom: 10 }}>
                เรียงลำดับตาม
              </label>
              <Select
                className='basic-single w-100'
                classNamePrefix='select'
                defaultValue={optionArr[0]}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                options={optionArr}
                onChange={(option) => {
                  let filters = [...posts];
                  filters = _.orderBy(filters, 'created_date', option.value);
                  setposts_filter(filters);
                }}
              />
            </div>
          </div>

          {posts_filter.length > 0 && (
            <List
              itemLayout='vertical'
              size='large'
              style={{ padding: 20 }}
              pagination={{
                onChange: (page) => {
                  const section = document.getElementById('scrollToTopContent');
                  section.scrollIntoView({ behavior: 'smooth', block: 'end' });
                },
                pageSize: 5,
              }}
              dataSource={posts_filter}
              renderItem={(res, i) => (
                <PostPublic
                  key={'post-id-' + res.id}
                  likeComment={likeComment}
                  likePost={likePost}
                  item={res}
                  userState={userState}
                  actionSheetShowActive={actionSheetShowActive}
                  handleSubmitPublicComment={handleSubmitPublicComment}
                  handleSubmitPublicPostEdit={handleSubmitPublicPostEdit}
                  deletePostPublic={deletePostPublic}
                />
              )}
            />
          )}
        </Container>
      </div>
      <Actionsheet
        style={{}}
        show={actionSheet}
        menus={spamMaster}
        cancelText={'ยกเลิก'}
        onRequestClose={() => actionSheetShow()}
        onCancel={() => actionSheetShow()}
      />
    </div>
  );
};

const Announement = ({ project, clientHeight }) => {
  const [isReadMore, setIsReadMore] = useState(false)
  return (
    <div className='announcement-card'>
      <div className='d-flex' style={{ alignItems: 'center' }}>
        <img
          src={StaticImage('/images/megaphone.svg')}
          height={20}
          width={20}
          style={{ marginRight: 10 }}
        />
        <span className='title'>ประกาศจากหน่วยงาน</span>
      </div>
      <div className='content' style={{ marginTop: 10 }}>
        <p
          id="content_agency"
          className={`ql-editor ${clientHeight > 280 && isReadMore ? "readmore" : ""}`}
          dangerouslySetInnerHTML={{
            __html: project.webboard_message ?? '-',
          }}
        />
        <div className='d-flex-center'>
          {(clientHeight > 280 && !isReadMore) && <div onClick={() => setIsReadMore(true)} className='cursor-pointer text-link'>ดูเพิ่มเติม</div>}
          {(clientHeight > 280 && isReadMore) && <div onClick={() => setIsReadMore(false)} className='cursor-pointer text-link'>ย่อ</div>}
        </div>
      </div>
    </div>
  );
};

const ChatAgency = ({ userState, handleSubmitAgencyPost }) => {
  const [post_message, setpost_message] = useState('');
  return (
    <div className='agency-card'>
      <div className='d-flex card-title'>
        <SmileIcon
          color='#c8ae7b'
          style={{ marginRight: 10, width: 30, height: 30 }}
        />
        <span>สนทนากับหน่วยงาน</span>
      </div>
      <div className='d-flex card-post'>
        <div className='d-flex w-100' style={{ alignItems: 'center' }}>
          <div
            className='d-flex-center'
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <Avatar
              shape='circle'
              className='avatar-object-contain'
              src={
                userState.image_profile
                  ? userState.image_profile
                  : StaticImage('/images/login/user-circle-duotone-1-copy.png')
              }
              style={{
                // border: '2px solid #387436',
                width: '40px',
                height: '40px',
              }}
            />
            {userState.is_verify ? (
              <img
                className='badge-check-duotone'
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
            ) : (
              <img
                className='badge-check-duotone'
                src={StaticImage('/images/login/badge-check-duotone.png')}
                style={{ opacity: 0.2 }}
              />
            )}
          </div>
          <div className='flex-1' style={{ marginLeft: 10, marginRight: 10 }}>
            <TextArea
              className='w-100 textarea-theme-small'
              type={'text'}
              placeholder={'เขียนแสดงความเห็น'}
              rows={1}
              value={post_message}
              onChange={(e) => setpost_message(e.target.value)}
            />
          </div>
          <FontAwesomeIcon
            onClick={() => {
              setpost_message('');
              handleSubmitAgencyPost(post_message);
            }}
            style={{ fontSize: 18 }}
            color={'#0c4ca3'}
            icon={faPaperPlane}
          />
        </div>
      </div>
    </div>
  );
};

const ChatPublic = ({ userState, handleSubmitPublicPost }) => {
  const [post_message, setpost_message] = useState('');

  return (
    <div className='public-card'>
      <div className='d-flex card-title'>
        <SmileIcon
          color='currentColor'
          style={{ marginRight: 10, width: 30, height: 30 }}
        />
        <span>สาธารณะ</span>
      </div>
      <div className='d-flex card-post'>
        <div className='d-flex w-100' style={{ alignItems: 'center' }}>
          <div
            className='d-flex-center'
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <Avatar
              shape='circle'
              className='avatar-object-contain'
              src={
                userState.image_profile
                  ? userState.image_profile
                  : StaticImage('/images/login/user-circle-duotone-1-copy.png')
              }
              style={{
                // border: '2px solid #387436',
                width: '40px',
                height: '40px',
              }}
            />
            {userState.is_verify ? (
              <img
                className='badge-check-duotone'
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
            ) : (
              <img
                className='badge-check-duotone'
                src={StaticImage('/images/login/badge-check-duotone.png')}
                style={{ opacity: 0.2 }}
              />
            )}
          </div>
          <div className='flex-1' style={{ marginLeft: 10, marginRight: 10 }}>
            <TextArea
              className='w-100 textarea-theme-small'
              type={'text'}
              placeholder={'เขียนแสดงความเห็น'}
              rows={1}
              value={post_message}
              onChange={(e) => setpost_message(e.target.value)}
            />
          </div>
          <FontAwesomeIcon
            onClick={() => {
              setpost_message('');
              handleSubmitPublicPost(post_message);
            }}
            style={{ fontSize: 18 }}
            color={'#0c4ca3'}
            icon={faPaperPlane}
          />
        </div>
      </div>
    </div>
  );
};

const PostAgency = ({ item, userState }) => {
  return (
    <div className='post-agency-card'>
      <div className='d-flex post-user-name'>
        <div className='d-flex' style={{ display: 'flex', cursor: 'pointer' }}>
          <Avatar
            shape='circle'
            className='avatar-object-contain'
            src={
              item.image_profile
                ? item.image_profile
                : StaticImage('/images/login/user-circle-duotone-1-copy.png')
            }
            style={{
              width: '40px',
              height: '40px',
            }}
          />
          {item.is_verify ? (
            <img
              className='badge-check-duotone'
              src={StaticImage('/images/login/badge-check-duotone.png')}
            />
          ) : null}
        </div>
        <div>
          <label>
            {item.name_on_web && item.name_on_web != ''
              ? item.name_on_web
              : item.created_user}
          </label>
          <span>
            {ConvertDateShort(item.created_date, 'DD MMM YYYY เวลา HH:mm น.')}
          </span>
        </div>
      </div>
      <div className='d-flex post-content'>
        <span>{item.message}</span>
      </div>
      <CommentAgency comments={item.agency_reply} />
    </div>
  );
};

const PostPublic = ({
  item,
  userState,
  likePost,
  likeComment,
  handleSubmitPublicComment,
  handleSubmitPublicPostEdit,
  deletePostPublic,
  actionSheetShowActive,
}) => {
  const [moreMenu, setmoreMenu] = useState(0);
  const [isCollapse, setIsCollapse] = useState([]);
  const [post_message, setpost_message] = useState('');
  const [edit_message, setedit_message] = useState('');
  const handleMoremenu = (id) => {
    setmoreMenu(id);
  };

  const handleCollapse = (id) => {
    if (isCollapse.includes(id)) {
      setIsCollapse(isCollapse.filter((x) => x != id));
    } else {
      setIsCollapse([...isCollapse, id]);
    }
  };

  return (
    <div
      className='post-public-card'
    // onClick={() => (moreMenu == 0 ? {} : handleMoremenu(0))}
    >
      <div className='d-flex post-user-name'>
        <div className='d-flex' style={{ display: 'flex', cursor: 'pointer' }}>
          <Avatar
            shape='circle'
            className='avatar-object-contain'
            src={
              item.image_profile
                ? item.image_profile
                : StaticImage('/images/login/user-circle-duotone-1-copy.png')
            }
            style={{
              // border: '2px solid #387436',
              width: '40px',
              height: '40px',
            }}
          />
          {item.is_verify ? (
            <img
              className='badge-check-duotone'
              src={StaticImage('/images/login/badge-check-duotone.png')}
            />
          ) : null}
        </div>
        <div>
          <label>
            {item.name_on_web && item.name_on_web != ''
              ? item.name_on_web
              : item.created_user}
          </label>
          <span>
            {ConvertDateShort(item.created_date, 'DD MMM YYYY เวลา HH:mm น.')}
          </span>
        </div>
      </div>
      {item.record_status == 'A' ? (
        <div className='d-flex post-content'>
          {edit_message ? (
            <div className='d-flex flex-1'>
              <textarea
                onChange={(e) => setedit_message(e.target.value)}
                value={edit_message}
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      'background-color',
                      '#f0f2f6',
                      'important'
                    );
                  }
                }}
                className='w-100 textarea-theme-small flex-1'
                style={{ padding: 10 }}
                type={'text'}
                placeholder={'เขียนแสดงความเห็น'}
                rows={1}
              />
              <div className='flex-column' style={{ marginLeft: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className='cursor-pointer'
                  onClick={() => {
                    handleSubmitPublicPostEdit(edit_message, item.id);
                    setTimeout(() => {
                      setedit_message('');
                    }, 200);
                  }}
                >
                  <PencilIcon style={{ marginRight: 5, minWidth: 30 }} />
                  แก้ไข
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className='cursor-pointer'
                  onClick={() => setedit_message('')}
                >
                  <Close
                    style={{ marginRight: 5, fontSize: 20, minWidth: 30 }}
                  />
                  ยกเลิก
                </div>
              </div>
            </div>
          ) : (
            <span>{`${item.message} ${item.updated_date && item.update_by
              ? `(ถูกแก้ไขโดยเจ้าของความเห็น ${ConvertDateShort(
                item.updated_date ?? new Date(),
                'DD/MM/YYYY HH:mm'
              )})`
              : ''
              }`}</span>
          )}
        </div>
      ) : (
        <div className='d-flex post-content-close'>
          <span>{`ถูกลบโดยเจ้าของความเห็น ${ConvertDateShort(
            item.updated_date ?? new Date(),
            'DD/MM/YYYY HH:mm'
          )}`}</span>
        </div>
      )}

      <div className='post-bottom'>
        <div className='d-flex' style={{ alignItems: 'center' }}>
          <GrinDuoTone
            style={{ color: '#0c4da2' }}
            className='comment-alt-edit-duotone-2'
          />
          <span>
            {item.is_like
              ? <>
                {item.count_like == 1 ? `${item.count_like} เห็นด้วย` :
                  `คุณและคนอื่นๆอีก ${item.count_like - 1} เห็นด้วย`}
              </>
              : `${item.count_like} เห็นด้วย`}
          </span>
        </div>
        <div className='d-flex' style={{ alignItems: 'center' }}>
          {/* <CommentAltEdit
            style={{ color: '#0c4da2' }}
            className='comment-alt-edit-duotone-2'
          /> */}
          <span>{`${item.comments.length ?? 0} ตอบกลับ`}</span>
        </div>
      </div>
      <div className='post-more-menu'>
        <div
          className='d-flex'
          style={{ alignItems: 'center', whiteSpace: 'nowrap' }}
          onClick={() => likePost(item)}
        >
          {item.is_like ? (
            <GrinDuoTone
              style={{
                color: '#0c4da2',
                marginRight: 10,
                height: 20,
                width: 20,
              }}
              className='comment-alt-edit-duotone-2'
            />
          ) : (
            <GrinIcon
              style={{
                color: '#ababab',
                marginRight: 10,
                height: 20,
                width: 20,
              }}
              className='comment-alt-edit-duotone-2'
            />
          )}
          <span>{'เห็นด้วย'}</span>
        </div>

        {item.record_status == 'A' && (
          <OutsideClickHandler
            onOutsideClick={() => {
              handleMoremenu(0);
            }}
          >
            <div
              className='d-flex flex-column'
              style={{ alignItems: 'center', position: 'relative' }}
            >
              <MoreHorizOutlined
                onClick={() => handleMoremenu(item.id)}
                color={'#0e4ea3'}
              />
              <div
                className={`d-flex flex-column more-menu ${moreMenu == item.id ? 'active' : ''
                  }`}
              >
                {userState.username == item.created_user && (
                  <div
                    className='cursor-pointer'
                    onClick={() => {
                      setedit_message(item.message);
                      handleMoremenu(0);
                    }}
                  >
                    <PencilIcon />
                    แก้ไข
                  </div>
                )}
                {userState.username == item.created_user && (
                  <div
                    className='cursor-pointer'
                    onClick={() => {
                      deletePostPublic(item);
                      handleMoremenu(0);
                    }}
                  >
                    <TrashIcon />
                    ลบ
                  </div>
                )}
                {userState.username !== item.created_user && (
                  <div
                    className='cursor-pointer'
                    onClick={() => {
                      actionSheetShowActive(item);
                      handleMoremenu(0);
                    }}
                  >
                    <WarningIcon />
                    รายงาน
                  </div>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
      {item.record_status == 'A' && (
        <div className='d-flex card-post' style={{ marginTop: 10 }}>
          <div className='d-flex w-100' style={{ alignItems: 'center' }}>
            <div
              className='d-flex-center'
              style={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <Avatar
                shape='circle'
                className='avatar-object-contain'
                src={
                  userState.image_profile
                    ? userState.image_profile
                    : StaticImage(
                      '/images/login/user-circle-duotone-1-copy.png'
                    )
                }
                style={{
                  // border: '2px solid #387436',
                  width: '40px',
                  height: '40px',
                }}
              />
              {userState.is_verify ? (
                <img
                  className='badge-check-duotone'
                  src={StaticImage('/images/login/badge-check-duotone.png')}
                />
              ) : (
                <img
                  className='badge-check-duotone'
                  src={StaticImage('/images/login/badge-check-duotone.png')}
                  style={{ opacity: 0.2 }}
                />
              )}
            </div>
            <div className='flex-1' style={{ marginLeft: 10 }}>
              <textarea
                onChange={(e) => setpost_message(e.target.value)}
                value={post_message}
                ref={(el) => {
                  if (el) {
                    el.style.setProperty(
                      'background-color',
                      '#f0f2f6',
                      'important'
                    );
                  }
                }}
                className='w-100 textarea-theme-small'
                style={{ padding: 10 }}
                type={'text'}
                placeholder={'เขียนแสดงความเห็น'}
                rows={1}
              />
            </div>
            <FontAwesomeIcon
              onClick={() => {
                setpost_message('');
                handleSubmitPublicComment(post_message, item.id);
              }}
              style={{ fontSize: 18, marginLeft: 10 }}
              color={'#0c4ca3'}
              icon={faPaperPlane}
            />
          </div>
        </div>
      )}

      {item.comments?.length > 0 && (
        <>
          {isCollapse.includes(item.id) ? (
            <div
              className='d-flex'
              onClick={() => handleCollapse(item.id)}
              style={{ alignItems: 'center', marginTop: 10 }}
            >
              <span style={{ marginRight: 10 }}>{`ดูน้อยลง`}</span>
              <FontAwesomeIcon color={'#0c4ca3'} icon={faArrowAltCircleUp} />
            </div>
          ) : (
            <div
              className='d-flex'
              onClick={() => handleCollapse(item.id)}
              style={{ alignItems: 'center', marginTop: 10 }}
            >
              <span style={{ marginRight: 10 }}>{`ดูความเห็นเพิ่มเติม`}</span>
              <FontAwesomeIcon color={'#0c4ca3'} icon={faArrowCircleDown} />
            </div>
          )}
        </>
      )}
      {isCollapse.includes(item.id) && item.comments?.length > 0 && (
        <CommentPublic likeComment={likeComment} comments={item.comments} />
      )}
    </div>
  );
};

const CommentPublic = ({ comments, userState, likeComment }) => {
  let components = [];
  const [moreMenu, setmoreMenu] = useState(0);
  const handleMoremenu = (id) => {
    setmoreMenu(id);
  };
  comments.map((item, index) => {
    components.push(
      <div
        key={'comment-id-' + item.id}
        style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
        onClick={() => (moreMenu == 0 ? {} : handleMoremenu(0))}
      >
        <div className='d-flex post-user-name'>
          <div
            className='d-flex'
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <Avatar
              shape='circle'
              className='avatar-object-contain'
              src={
                item.image_profile
                  ? item.image_profile
                  : StaticImage('/images/login/user-circle-duotone-1-copy.png')
              }
              style={{
                // border: '2px solid #387436',
                width: '40px',
                height: '40px',
              }}
            />
            {item.is_verify ? (
              <img
                className='badge-check-duotone'
                src={StaticImage('/images/login/badge-check-duotone.png')}
              />
            ) : null}
          </div>
          <div className='d-flex flex-column flex-1'>
            <div className='d-flex flex-column post-comment-content flex-1'>
              <label style={{ marginBottom: 10 }}>
                {item.name_on_web && item.name_on_web != ''
                  ? item.name_on_web
                  : item.created_user}
              </label>
              <span>{item.message}</span>
            </div>
            <div className='d-flex'>
              <span>
                {ConvertDateShort(
                  item.created_date,
                  'DD MMM YYYY เวลา HH:mm น.'
                )}
              </span>
              <div
                className='d-flex'
                style={{ marginLeft: 10, alignItems: 'center' }}
                onClick={() => likeComment(item)}
              >
                {item.is_like ? (
                  <GrinDuoTone
                    style={{
                      color: '#0c4da2',
                      marginRight: 10,
                      height: 20,
                      width: 20,
                    }}
                    className='comment-alt-edit-duotone-2'
                  />
                ) : (
                  <GrinIcon
                    style={{
                      color: '#ababab',
                      marginRight: 10,
                      height: 20,
                      width: 20,
                    }}
                    className='comment-alt-edit-duotone-2'
                  />
                )}
                <span>{item.count_like}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return <div className='post-comment-public-card'>{components}</div>;
};

const CommentAgency = ({ comments }) => {
  let components = [];
  comments.map((item, index) => {
    components.push(
      <div
        key={'comment-id-' + item.id}
        style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
      >
        <div
          className='d-flex post-user-name'
          style={{ alignItems: 'initial' }}
        >
          <div
            className='d-flex'
            style={{ display: 'flex', cursor: 'pointer', marginTop: 10 }}
          >
            <Avatar
              shape='circle'
              className='avatar-object-contain'
              src={
                item.image_profile
                  ? item.image_profile
                  : StaticImage('/images/login/user-circle-duotone-1-copy.png')
              }
              style={{
                // border: '2px solid #387436',
                width: '40px',
                height: '40px',
              }}
            />
          </div>
          <div className='d-flex flex-column flex-1'>
            <div className='d-flex flex-column post-comment-content flex-1'>
              <label style={{ marginBottom: 10 }}>
                {item.agency_name ?? item.created_user}
              </label>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.message,
                }}
              />
            </div>
            <div className='d-flex'>
              <span>
                {ConvertDateShort(
                  item.created_date,
                  'DD MMM YYYY เวลา HH:mm น.'
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return <div className='post-comment-public-card'>{components}</div>;
};
export default Posts;
