import { useHistory } from "react-router";
import { encodeBase64 } from "../../../utils/convertBase64";
import YoutuberPlayer from "../../react-player/YoutuberPlayer";
import { Image } from 'antd';
import './index.scss'
const Tab1 = ({ survey }) => {

    const history = useHistory();
    return (
        <>

            {survey.highlight && (
                <div className="tab1-container">
                    <YoutuberPlayer
                        mute={survey.ismute == '1' ? true : false}
                        autoplay={survey.isautoplay == '1' ? true : false}
                        url={survey.highlight}
                    />
                </div>
            )}
            {survey.content_files && survey.content_files.filter(x => x.type == "image_relate").length > 0 && (
                <>
                    <div className="tab1-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'image_relate') {
                                return <></>;
                            }
                            return (
                                <Image style={{ maxWidth: 300 }} src={item.file_path} />
                            )
                        })
                        }
                    </div>
                </>
            )}
            <div className="tab1-container">
                <h1 className="header">
                    ชื่อการรับฟัง
                </h1>
                <div
                    className='ql-editor'
                >
                    {survey.survey_name}
                </div>
            </div>
            {survey.project_ref_id > 0 &&
                <div className="tab1-container">
                    <h1 className="header">
                        โครงการอ้างอิง
                    </h1>
                    <div className='file-container'>
                        <div className="d-flex">
                            <label
                                style={{ fontSize: 14 }}
                                className='text-link cursor-pointer'
                                onClick={() => {
                                    history.push(`/ProjectTimeline?projectId=${encodeBase64(survey.project_ref_id)}`);
                                }}
                            >
                                {survey.project_ref_name}
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className="tab1-container">
                <h1 className="header">
                    ผู้ได้รับผลกระทบ
                </h1>
                <div
                    className='ql-editor'
                >
                    {survey.affected_person?.length > 0 ? survey.affected_person : 'ไม่ได้ระบุ'}
                </div>
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    ความเป็นมา (สภาพปัญหาและเป้าหมาย)
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_problem }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'problem') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() =>
                                            window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')
                                        }
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    คำอธิบายหลักการหรือประเด็นสำคัญของร่างกฎหมายหรือกฎหมายที่นำมารับฟังความคิดเห็น
                </h1>
	    	<div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_desc }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'description') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    ผู้เกี่ยวข้อง
                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_affected }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'affected') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="tab1-container">
                <h1 className="header">
                    เหตุผลความจำเป็นของการให้มีระบบอนุญาต ระบบคณะกรรมการหรือการกำหนดโทษอาญา รวมทั้งหลักเกณฑ์การใช้ดุลพินิจของเจ้าหน้าที่รัฐ

                </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_necessity }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'nescessity') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() => window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')}
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>


        </>
    )
}

export default Tab1;
