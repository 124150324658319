import React, { useEffect } from 'react';
import { StaticImage } from '../../utils/image';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import './scss/index.scss';
const Test = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = 'เกี่ยวกับการทดสอบระบบ';
    return () => {};
  }, []);

  return (
    <div
      // className='section-banner'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: isMobile ? 60 : 60,
      }}
    >
      {/* <img
        className='section-sub-branner-image-section2'
        src={StaticImage('/images/branner/rectangle-1@3x.png')}
      /> */}
      <img
        style={{ width: '100%', objectFit: 'contain' }}
        src={
          isMobile
            ? StaticImage('/images/testpage/mobile.png')
            : StaticImage('/images/testpage/web.png')
        }
      />
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          marginTop: 20,
          marginBottom: 30,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          onClick={() => {
            history.push('/');
          }}
          className='cursor-pointer btn-backhome'
        >
          กลับหน้าแรก
        </div>
        <div
          onClick={() => {
            history.push('/MeanLaw');
          }}
          className='cursor-pointer btn-meanlaw'
          style={{
            fontSize: 20,
            textDecoration: 'underline',
            marginTop: isMobile ? 20 : 0,
            marginLeft: isMobile ? 0 : 20,
            marginBottom: isMobile ? 30 : 0,
          }}
        >
          ระบบกลาง คืออะไร ?
        </div>
      </div>
    </div>
  );
};

export default Test;
