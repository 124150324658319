import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth, setHeaderIPv4 } from '../utils';

class ApiSurvey extends Component {
  static get = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: 'surveys/',
      method: 'post',
      data: data
    });

    return result;
  };
  static getSearch = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: 'surveys/search',
      method: 'post',
      data: data
    });

    return result;
  };
  static surveyGroupByProject = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: 'surveys/group',
      method: 'get',
    });
    return result;
  };
  static history = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: 'surveys/history',
      method: 'get',
    });
    return result;
  };
  static historyV2 = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: 'surveys/historyV2',
      method: 'get',
    });
    return result;
  };
  static favoriteHistory = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: 'surveys/favorite',
      method: 'get',
    });
    return result;
  };

  static favoriteHistoryProject = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: 'surveys/favoriteProject',
      method: 'get',
    });
    return result;
  };

  static getById = async ({ survey_id }) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/${survey_id}`,
      method: 'get',
    });
    return result;
  };

  static getByProjectId = async ({ project_id }) => {
    const result = await axios({
      url: `surveys/project/${project_id}`,
      method: 'get',
    });
    return result;
  };

  static getContact = async ({ survey_id }) => {
    const result = await axios({
      url: `surveys/contact/${survey_id}`,
      method: 'get',
    });
    return result;
  };

  static getDraftLaw = async ({ survey_id }) => {
    const result = await axios({
      url: `surveys/draftLaw/${survey_id}`,
      method: 'get',
    });
    return result;
  };

  static getSummary = async ({ survey_id }) => {
    const result = await axios({
      url: `surveys/summary/${survey_id}`,
      method: 'get',
    });
    return result;
  };

  static getQuestion = async ({ survey_id }) => {
    const result = await axios({
      url: `surveys/question/${survey_id}`,
      method: 'get',
    });
    return result;
  };

  static getAnswer = async ({ answer_id }) => {
    const result = await axios({
      url: `surveys/answer/${answer_id}`,
      method: 'get',
    });
    return result;
  };

  static answer = async (data) => {
    await setHeaderIPv4();
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/answer`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static cancelAnswer = async ({ id = 0 }) => {
    await setHeaderIPv4();
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/answer/${id}`,
      method: 'delete',
    });
    return result;
  };

  static answerDraft = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/answerDraft`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static getAnswerDraft = async ({ id = 0 }) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/answerDraft/${id}`,
      method: 'get',
    });
    return result;
  };

  static getAnswerHistory = async ({ id = 0 }) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/answerHistory/${id}`,
      method: 'get',
    });
    return result;
  };

  static viewSurvey = async ({ id = 0 }) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/view/${id}`,
      method: 'get',
    });
    return result;
  };


  static favorite = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/favorite`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static favoriteProject = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `surveys/favoriteProject`,
      method: 'post',
      data: data,
    });
    return result;
  };
}

export default ApiSurvey;
