import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import ApiSurvey from "../../../api/survey";
import { useWindowUnloadEffect } from '../../../utils';
import { checkUserBool } from '../../../utils/checkUser';
import { encodeBase64 } from '../../../utils/convertBase64';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../utils/localStorage";
import { ConvertFileToBase64 } from '../../../utils/uploadfile';
import ListDetails from './ListDetails';
import './index.scss';
import IndexFooter from '../../main/IndexFooter';
import { Button } from 'antd';
import {

    FormOutlined,
    Loading3QuartersOutlined,
    FileOutlined,
    CloseCircleOutlined,
    ShareAltOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router';
import { v4 } from 'uuid';
import AlertError from '../../../utils/AlertError';
import ReCAPTCHA from 'react-recaptcha';

const Tab3 = ({ survey, survey_id, surveyStorage, isContinue, isSended, deleteOldAnswer }) => {
    const history = useHistory();
    const [question, setquestion] = useState([]);
    const [totalQuestion, settotalQuestion] = useState(0);
    const [successQuestion, setSuccessQuestion] = useState(0);
    const [answerSuccess, setAnswerSuccess] = useState('');
    const userState = useSelector((state) => state.user);
    const [share, setshare] = useState({});
    const [verify, setverify] = useState(false)
    useWindowUnloadEffect(() => destroy(), true);

    useEffect(() => {
        document.title = 'แสดงความคิดเห็น';
        // getQuestion();
        if (checkUserBool(userState)) {
            checkAnswer();
        } else {
            const promise1 = getQuestion();
        }
        return () => {
            destroy();
        };
    }, []);


    useEffect(() => {
        if (totalQuestion > 0) {
            Swal.fire({
                title: '',
                html: `ระบบกลางไม่มีนโยบายให้หน่วยงานที่มารับฟังความคิดเห็นขอข้อมูลส่วนบุคคลจากผู้ตอบแบบสอบถาม หากหน่วยงานถามคำถามเพื่อขอข้อมูลส่วนบุคคล ท่านมีสิทธิที่จะไม่ตอบคำถามนั้น`,
                icon: 'warning',
                //showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                reverseButtons: true,
            }).then(async (ok) => {
                if (ok.isConfirmed) {

                }
            });
        }
    }, [totalQuestion]);

    const destroy = async () => {
        let surveyStorage = await getLocalStorage('answer');
        if (surveyStorage) {
            // getSurvey();
            try {
                surveyStorage = JSON.parse(surveyStorage);
                const result = await ApiSurvey.answerDraft(surveyStorage);
                if (result.status == 200) {
                    removeLocalStorage('answer');
                }
            } catch (error) { }
        }
    };

    useEffect(() => {
        let total = 0;
        question.map((item, index) => {
            total += item.question_detail.filter((x) => x.value).length;
        });
        setSuccessQuestion(total);
        return () => { };
    }, [question]);

    const getQuestion = async () => {
        try {
            removeLocalStorage('answer');
            Swal.showLoading();
            const survey = await ApiSurvey.getQuestion({ survey_id });
            Swal.close()
            const { data } = survey.data;
            if (data.length > 0) {
                if (!data[0].question_detail) {
                    throw 'ไม่พบข้อมูลแบบสอบถาม';
                }
                let total = 0;
                data.map((item, index) => {
                    total += item.question_detail.length;
                });
                settotalQuestion(total);
                if (data[0].question_id) {
                    setquestion(data);
                } else {
                    throw 'ไม่พบข้อมูลแบบสอบถาม';
                }
            }
            else {
                throw 'ไม่พบข้อมูลแบบสอบถาม';
            }
        } catch (error) {
            Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {

            });
        }
    }

    const checkButtonSubmit = () => {
        if (isSended) {
            return (
                <div className='btn-brown'>
                    <ReloadOutlined />
                    <span>ทำใหม่</span>
                </div>
            );
        }
        if (isContinue) {
            return (
                <div className='summary'>
                    <ReloadOutlined />
                    <span>ทำแบบสอบถามต่อ</span>
                </div>
            );
        } else {
            return (
                <div>
                    <FormOutlined />
                    <span>ส่งความคิดเห็น</span>
                </div>
            );
        }
    };

    const checkAnswer = async () => {
        if (encodeBase64(surveyStorage.survey_id) == survey_id) {
            if (surveyStorage.answer.length > 0) {
                setquestion(surveyStorage.answer);
                let total = 0;
                surveyStorage.answer.map((item, index) => {
                    total += item.question_detail.length;
                });
                settotalQuestion(total);
            } else {
                getQuestion();
            }
        } else {
            getQuestion();
        }
    };


    const surveyPageRender = () => {
        let components = [];
        let beforeQuestion = 0;
        question.map((item, index) => {
            components.push(
                <div>
                    {item.question_title &&
                        <div className="tab3-container d-flex flex-column">
                            <div className="title-question">
                                <h1 className="header">{item.question_title}</h1>
                            </div>
                            <div style={{ padding: '0px 10px' }}>
                                <span>{item.question_desc}</span>
                            </div>

                        </div>
                    }

                    <ListDetails
                        surveyItem={survey}
                        beforeQuestion={beforeQuestion}
                        survey={item}
                        onEvent={onEvent}
                        onCommentClick={onCommentClick}
                        onCommentOnChange={onCommentOnChange}
                    />
                </div>
            );
            beforeQuestion += item.question_detail.length;
        });
        return (
            <div>
                {components}
            </div>
        );
    };

    const onEvent = async (surveyItem, questionParam, type, value) => {
        if ((survey.isconclude == 'O' || !survey.isconclude) && survey.date_balance >= 0) {

        } else {
            return false;
        }
        if (type == 'radiogroup') {
            let newSurvey = [...question];
            let newPage = newSurvey.find(
                (x) => x.question_id == surveyItem.question_id
            );
            let newQuestion = newPage.question_detail;
            let filter = newQuestion.findIndex((x) => x.name == questionParam);
            newQuestion[filter].value = value;
            setquestion(newSurvey);
            setLocalStorage(
                'answer',
                JSON.stringify({
                    survey_id: survey_id,
                    answer: newSurvey,
                })
            );
        } else if (type == 'text') {
            let newSurvey = [...question];
            let newPage = newSurvey.find(
                (x) => x.question_id == surveyItem.question_id
            );
            let newQuestion = newPage.question_detail;
            let filter = newQuestion.findIndex((x) => x.name == questionParam);
            newQuestion[filter].value = value;
            setquestion(newSurvey);
            setLocalStorage(
                'answer',
                JSON.stringify({
                    survey_id: survey_id,
                    answer: newSurvey,
                })
            );
        } else if (type == 'dropdown') {
            let newSurvey = [...question];
            let newPage = newSurvey.find(
                (x) => x.question_id == surveyItem.question_id
            );
            let newQuestion = newPage.question_detail;
            let filter = newQuestion.findIndex((x) => x.name == questionParam);
            newQuestion[filter].value = value;

            setquestion(newSurvey);
            setLocalStorage(
                'answer',
                JSON.stringify({
                    survey_id: survey_id,
                    answer: newSurvey,
                })
            );
        } else if (type == 'file') {
            const base64File = await ConvertFileToBase64(value.originFileObj);

            let newSurvey = [...question];
            let newPage = newSurvey.find(
                (x) => x.question_id == surveyItem.question_id
            );
            let newQuestion = newPage.question_detail;
            let filter = newQuestion.findIndex((x) => x.name == questionParam);
            newQuestion[filter].value = base64File;
            setquestion(newSurvey);
            setLocalStorage(
                'answer',
                JSON.stringify({
                    survey_id: survey_id,
                    answer: newSurvey,
                })
            );
        } else if (type == 'other') {
            let newSurvey = [...question];
            let newPage = newSurvey.find(
                (x) => x.question_id == surveyItem.question_id
            );
            let newQuestion = newPage.question_detail;
            let filter = newQuestion.findIndex((x) => x.name == questionParam);
            newQuestion[filter].otherValue = value;
            setquestion(newSurvey);
            setLocalStorage(
                'answer',
                JSON.stringify({
                    survey_id: survey_id,
                    answer: newSurvey,
                })
            );
        }
        if (type == 'checkbox') {
            let newSurvey = [...question];
            let newPage = newSurvey.find(
                (x) => x.question_id == surveyItem.question_id
            );
            let newQuestion = newPage.question_detail;
            let filter = newQuestion.findIndex((x) => x.name == questionParam);
            if (value == "none") {
                newQuestion[filter].value = [];
                newQuestion[filter].value.push(value);
            } else if (value == "selectAll") {
                if (newQuestion[filter].value?.includes('selectAll')) {
                    newQuestion[filter].value = [];
                } else {
                    newQuestion[filter].value = [];
                    newQuestion[filter].value.push(value);
                    const result = newQuestion[filter].choices.map(x => x.value);
                    newQuestion[filter].value = newQuestion[filter].value.concat(result);
                }
            } else {
                if (newQuestion[filter].value) {
                    if (newQuestion[filter].value.includes('none')) {
                        const checkBoxIndex = newQuestion[filter].value.findIndex(
                            (x) => x == value
                        );
                        newQuestion[filter].value.splice(checkBoxIndex, 1);
                    }
                    if (newQuestion[filter].value.includes(value)) {
                        const checkBoxIndex = newQuestion[filter].value.findIndex(
                            (x) => x == value
                        );
                        newQuestion[filter].value.splice(checkBoxIndex, 1);
                    } else {
                        newQuestion[filter].value.push(value);
                    }
                } else {
                    newQuestion[filter].value = [];
                    newQuestion[filter].value.push(value);
                }
            }

            setquestion(newSurvey);
            setLocalStorage(
                'answer',
                JSON.stringify({
                    survey_id: survey_id,
                    answer: newSurvey,
                })
            );
        }
    };

    const onCommentClick = (page, item) => {
        if ((survey.isconclude == 'O' || !survey.isconclude) && survey.date_balance >= 0) {

        } else {
            return false;
        }
        let newSurvey = [...question];
        let newPage = newSurvey.find((x) => x.question_id == page.question_id);
        let newQuestion = newPage.question_detail;
        let filter = newQuestion.findIndex((x) => x.name == item.name);
        newQuestion[filter].editor = true;
        setLocalStorage(
            'answer',
            JSON.stringify({
                survey_id: survey_id,
                answer: newSurvey,
            })
        );
        setquestion(newSurvey);
    };

    const onCommentOnChange = (page, item, label) => {
        let newSurvey = [...question];
        let newPage = newSurvey.find((x) => x.question_id == page.question_id);
        let newQuestion = newPage.question_detail;
        let filter = newQuestion.findIndex((x) => x.name == item.name);
        newQuestion[filter].note = label;
        newQuestion[filter].datetime = moment().format('DD MMM YY HH:ss');
        setquestion(newSurvey);
        setLocalStorage(
            'answer',
            JSON.stringify({
                survey_id: survey_id,
                answer: newSurvey,
            })
        );
    };

    const sendAnswer = async () => {
        const submit_id = v4();
        const stackHolder = await getLocalStorage('informer');
        await removeLocalStorage('answer');
        await removeLocalStorage('informer');
        const uuid = await getLocalStorage('uuid');
        Swal.showLoading();
        try {
            const answer = [];
            var hasValueLessThanTen = false;
            var messegeError = `กรุณาตอบคำถามข้อ <span class="text-danger">*</span> สีแดงให้ครบทุกข้อก่อนจึงจะส่งคำตอบได้`;
            let questionIndex = 0;
            question.map((item) => {
                const value = item.question_detail;

                value.map((subitem, subindex) => {
                    questionIndex += 1;
                    if (subitem.isRequired && !subitem.value) {
                        if (questionIndex == 1) {
                            messegeError += "\n โดย รายการคำถามที่จำเป็นต้องตอบแต่ท่านยังไม่ได้ตอบคือ";
                            messegeError += `\n ข้อ ${questionIndex}`;
                        } else {
                            messegeError += `,${questionIndex}`;
                        }
                        // messegeError += `\n ข้อ ${subitem.title}`;

                        hasValueLessThanTen = true;
                    }
                })


                const model = {
                    project_id: survey.project_id,
                    question_id: item.question_id,
                    survey_id: item.survey_id,
                    question_title: item.question_title,
                    question_desc: item.question_desc,
                    record_status: 'A',
                    question_detail: JSON.stringify(item.question_detail),
                    name: item.name,
                    uuid: uuid,
                    submit_id: submit_id,
                    is_stackholder: stackHolder ? 1 : 0,
                    stackholder_key: stackHolder ?? '',
                };
                answer.push(model);
            });
            if (hasValueLessThanTen) {
                Swal.fire({
                    title: '',
                    html: `<pre>${messegeError}</pre>`,
                    icon: 'error'
                });
                return false;
            }

            const result = await ApiSurvey.answer(answer);
            if (result.status == 200) {
                const { data } = result.data;
                setshare({
                    title: `${window.location.origin}/AnswerPublic?answer_id=${data}`,
                    subject: `${window.location.origin}/AnswerPublic?answer_id=${data}`,
                    shareUrl: `${window.location.origin}/AnswerPublic?answer_id=${data}`,
                });
                setAnswerSuccess(data);
                await setLocalStorage(
                    'share-answer',
                    JSON.stringify({
                        datetime: new Date(),
                        answer: answer,
                        survey: survey
                    })
                );
                // setResult(true);
                history.push(`/Success?survey_id=${survey_id}`);
            }
            Swal.close();
        } catch (error) {
            const { data } = error.response;
            AlertError('', data?.message);
        }


        // setResult(true);
    };

    var verifyCallback = function (response) {
        if (response) {
            setverify(true);
        }
    };

    var callback = function () {
        console.log('Done!!!!');
    };

    const checkReadonly = () => {
        if ((survey.isconclude == 'O' || !survey.isconclude) && survey.date_balance >= 0) {
            return false
        } else {
            return true;
        }
    }

    const checkAccessRight = () => {
        if (survey.access_right == 3) {
            if (checkUserBool(userState)) {
                return false;
            } else {
                return true;
            }
        } else if (survey.access_right == 2) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    return true;
                }

            } else {
                return true;
            }
        }
        else if (survey.access_right == 4) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    return true;
                }

            } else {
                return true;
            }
        }
        else {
            return false;
        }

    }

    const checkAccessRightText = () => {
        let text = ``;
        if (survey.access_right == 3) {
            if (checkUserBool(userState)) {
                return false;
            } else {
                text = `ท่านจำเป็นต้องทำการ <a href="/Login">เข้าสู่ระบบ</a> ก่อนที่จะทำแบบรับฟังความคิดเห็นนี้`
            }
        }
        else if (survey.access_right == 2) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    text = `ท่านจำเป็นต้องทำการ <a href="/UserProfile?usertab=2">ยืนยันตัวตน</a> ก่อนที่จะทำแบบรับฟังความคิดเห็นนี้`
                }

            } else {
                text = `ท่านจำเป็นต้องทำการ <a href="/Login">เข้าสู่ระบบ/ยืนยันตัวตน</a> ก่อนที่จะทำแบบรับฟังความคิดเห็นนี้`
            }
        }
        else if (survey.access_right == 4) {
            if (checkUserBool(userState)) {
                if (userState.is_verify) {
                    return false;
                } else {
                    text = `ท่านจำเป็นต้องทำการ <a href="/UserProfile?usertab=2">ยืนยันตัวตน</a> ก่อนที่จะทำแบบรับฟังความคิดเห็นนี้`
                }

            } else {
                text = `ท่านจำเป็นต้องทำการ <a href="/Login">เข้าสู่ระบบ</a> ก่อนที่จะทำแบบรับฟังความคิดเห็นนี้`
            }
        }
        else {
            return false;
        }
        return <div className="alert-warning"
            style={{ marginTop: 20 }}
            dangerouslySetInnerHTML={{ __html: checkReadonly() ? "แบบสำรวจนี้ได้รับการแสดงความคิดเห็นเฉพาะ ผู้ที่ลงทะเบียนหรือยืนยันตัวตนแล้ว" : text }}
        />
    }

    return <div>
        {checkAccessRight() && checkAccessRightText()}
        <fieldset style={checkAccessRight() ? { pointerEvents: 'none', opacity: 0.6 } : {}} disabled={checkAccessRight()}>
            {question.length > 0 && surveyPageRender()}
        </fieldset>
        <div style={{ paddingBottom: 100 }}>
            <div className='tab3-container d-flex flex-column'>
                <ReCAPTCHA
                    className='d-flex-center'
                    style={{ marginTop: 20 }}
                    sitekey='6Lfxs6kaAAAAALjK038cIdMbaiqxaH4lC8M5l8rn'
                    render='explicit'
                    verifyCallback={verifyCallback}
                    onloadCallback={callback}
                />
            </div>
        </div>


        {!checkAccessRight() && <>
            <div className='bottom-send-button-container' style={{ bottom: 0 }}>
                <IndexFooter success={successQuestion} complete={totalQuestion} />
                <>
                    {(survey.isconclude == 'O' || !survey.isconclude) &&
                        survey.date_balance >= 0 && (
                            <div
                                onClick={() => {
                                    isSended
                                        ? (verify && deleteOldAnswer())
                                        : (verify && sendAnswer())
                                }}
                                disabled={!verify}
                                style={{ opacity: !(verify) ? 0.2 : 1 }}
                                className='bottom-button'
                            >
                                {checkButtonSubmit()}
                            </div>
                        )}
                    {(survey.isconclude == 'O' || !survey.isconclude) &&
                        survey.date_balance < 0 && (
                            <div className='bottom-button'>
                                <div className='disabled'>
                                    <Loading3QuartersOutlined />
                                    <span>อยู่ระหว่างสรุปผล</span>
                                </div>
                            </div>
                        )}
                    {survey.isconclude == 'D' && (
                        <div className='bottom-button'>
                            <div className='disabled'>
                                <Loading3QuartersOutlined />
                                <span>อยู่ระหว่างสรุปผล</span>
                            </div>
                        </div>
                    )}
                    {survey.isconclude == 'C' && (
                        <div className='bottom-button'>
                            <div className='disabled'>
                                <CloseCircleOutlined />
                                <span>ไม่มีการสรุปผล</span>
                            </div>
                        </div>
                    )}
                    {survey.isconclude == 'B' && (
                        <div className='bottom-button'>
                            <div className='disabled'>
                                <CloseCircleOutlined />
                                <span>ปิดก่อนกำหนด</span>
                            </div>
                        </div>
                    )}
                    {survey.isconclude == 'P' && (
                        <div
                            onClick={() => {
                                history.push(`/Summary?survey_id=${encodeBase64(survey.survey_id)}`);
                            }}
                            className='bottom-button'
                        >
                            <div className='summary'>
                                <FileOutlined />
                                <span>อ่านสรุปผลการรับฟัง</span>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </>}

    </div>
}

export default Tab3;