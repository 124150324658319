import React from 'react';
import { List, Space, Typography } from 'antd';
import { Card } from 'antd';
import { Progress } from 'antd';
import { Row, Col } from 'antd';
import {
  EyeOutlined,
  FormOutlined,
  TagsOutlined,
  ClockCircleOutlined,
  ClockCircleTwoTone,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './cardList.scss';
import './index.scss';
import CommentAltEdit from '../iconComponent/CommentAltEdit';
import StopWatch from '../iconComponent/StopWatch';
import TagDuoTone from '../iconComponent/TagDuoTone';
import ProgressBar from './ProgressBar';
import moment from 'moment';
import { ConvertDateShort } from '../../utils/date';
import { StaticImage } from '../../utils/image';
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import { encodeBase64 } from '../../utils/convertBase64';
const { Title } = Typography;

const defaultProps = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1,
  style: { width: '5rem', height: '5rem' },
};
const listData = [];
for (let i = 0; i < 7; i++) {
  listData.push({
    href:
      i + 18 !== 21
        ? '/images/main_images/layer-' + (i + 18) + '.png'
        : i + 18 > 21
          ? ''
          : '/images/main_images/layer-' + '21' + '.jpg',
    title: `ประเมินผลฯ พระราชบัญญัติสภาวิสาหกิจขนาดกลาง ขนาดย่อมและขนาดย่อย พ.ศ. 2563`,
    avatar: '',
    description: 'โครงการแก้ไข พ.ร.บ.รักษาความมั่นคงปลอดภัย ขจัด...',
    substyle: i + 18 > 21 ? { display: 'none' } : { display: '' },
    statusText: i + 18 > 21 ? `` : `อยู่ระหว่างการประเมิณ`,
    classChecking: i + 18 > 21 ? `` : `status-round-texts status-text`,
    strokeColor: {
      '0%': i + 18 > 21 ? '#f96b57' : '#0c4da2',
      '100%': i + 18 > 21 ? '#f96b57' : '#0c4da2',
    },
  });
}
const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);
const style = {
  background: '#0092ff',
  padding: '8px 0',
  fontSize: '16px',
  fontWeight: 'bold',
};

const ListeningList = ({ surveys }) => {
  const history = useHistory();
  const handleChild = (e, item) => {
    e.stopPropagation();
    history.push(`/ProjectTimeline?projectId=${encodeBase64(item.project_id)}`);
  };

  const checkComplete = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance > item.duration_day) {
        return 0;
      } else {
        return (
          ((item.duration_day - item.date_balance) / item.duration_day) * 100
        );
      }
    } else {
      return 100;
    }
  };
  const checkLeftTime = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance < 0) {
        return 'ปิดการรับฟัง';
      } else {
        return `เหลือ ${item.date_balance + 1} วัน`;
      }
    } else if (item.isconclude == 'D') {
      return 'อยู่ระหว่างการสรุปผล';
    } else if (item.isconclude == 'C') {
      return 'ไม่มีการสรุปผล';
    } else if (item.isconclude == 'P') {
      return 'เผยแพร่สรุปผลการรับฟัง';
    }
  };

  const checkCardBackground = (item) => {
    if (item.isconclude == 'O' || !item.isconclude) {
      if (item.date_balance < 0) {
        return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
      } else {
        return {};
      }
    } else if (item.isconclude == 'D') {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    } else if (item.isconclude == 'C') {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    } else if (item.isconclude == 'P') {
      return { background: 'rgb(228, 232, 240)', boxShadow: 'none' };
    }
  };

  return (
    <section style={{ width: '101%' }}>
      <List
        itemLayout='vertical'
        size='large'
        dataSource={surveys}
        renderItem={(item, i) => (
          <div
            style={checkCardBackground(item)}
            className='card-listening'
            onClick={() => {
              {
                if (item.is_coming === '1') {
                } else {
                  history.push(`/listeningDetail?survey_id=${item.survey_id}`);
                }
              }
            }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {item.is_coming === '1' ? (
                  <div>
                    <span style={{ marginRight: 5, color: item.coming_color }}>
                      {item.coming_desc}
                    </span>
                    <Title level={5}>{item.project_name}</Title>
                  </div>
                ) : (
                  <p className='text-title'>
                    <Title level={5}>{item.project_name}</Title>
                    {/* <a
                      className='text-theme-blue'
                      onClick={(e) => handleChild(e, item)}
                    >
                      {item.project_name}
                    </a> */}
                  </p>
                )}
                <p className='text-description'>{item.survey_name}</p>
              </div>
            </div>
            <div className='organize-div'>
              <div className='icon-tag-duo-tone-container'>
                <TagDuoTone className='icon-tag-duo-tone' />
                <span className='span-tags'>{item.survey_type_name}</span>
              </div>
              {item.agency_id > 0 && (
                <div className='organize-container'>
                  <img
                    alt="agency_image"
                    src={item.agency_image ?? '..'}
                    className='logo'
                    oncontextmenu='return false;'
                    onError={(i) => (i.target.style.display = 'none')}
                  />
                  <label style={{ paddingLeft: '2px' }}>
                    {item.agency_abbrv}
                    {/* {item.agency_name} */}
                  </label>
                </div>
              )}
            </div>
            <div className='progress-container'>
              <div style={{ display: 'flex', flex: 1 }}>
                <span>{ConvertDateShort(item.start_date, 'DD MMM YY')}</span>
                <section className='progress-list-filter'>
                  <ProgressBar
                    bgcolor={item.date_balance > 7 ? '#387436' : '#f96b57'}
                    bgContainerColor={
                      item.date_balance > 7 ? '#717377' : '#717377'
                    }
                    leftTime={checkLeftTime(item)}
                    completed={checkComplete(item)}
                  />
                </section>

                {/* <Progress
                  percent={50}
                  showInfo={false}
                  strokeColor={item.strokeColor}
                  style={{
                    width: item.statusText !== "" ? 50 : 80,
                    marginTop: item.statusText !== "" ? "0px" : "3.5px",
                  }}
                /> */}
                <span>
                  <span>{ConvertDateShort(item.end_date, 'DD MMM YY')}</span>
                </span>
              </div>
              <div className='icon-tag-duo-tone-container'>
                <CommentAltEdit className='icon-comment-alt-edit' />
                <label className='icon-comment-alt-label'>
                  {item.answer_count ?? 0}
                </label>
              </div>
            </div>
          </div>

          // <div>
          //   <Card>
          //     {" "}
          //     <List.Item
          //       key={i}
          //       extra={<img width={272} alt="logo" src={item.href} />}
          //       actions={[
          //         <section>
          //           <span
          //             style={{
          //               color: "#0c4da2",
          //               fontWeight: "bold",
          //               fontSize: "16px",
          //             }}
          //           >
          //             <IconText
          //               icon={TagsOutlined}
          //               text="ร่างกฎหมาย"
          //               key="list-vertical-star-o"
          //             />
          //           </span>
          //         </section>,
          //         <div>
          //           <p
          //             style={{
          //               position: "relative",
          //               fontSize: "16px",
          //               fontWeight: "bold",
          //               width: "90px",
          //               height: "35px",
          //               padding: "2px 12px 3px 2px",
          //               objectFit: "contain",
          //               borderRadius: "17.5px",
          //               backgroundColor: "#f4f6f9",
          //             }}
          //           >
          //             <img
          //               src={"images/layer-18.png"}
          //               style={{ verticalAalign: "middle" }}
          //             />
          //             <label style={{ paddingLeft: "2px" }}>ก.ล.ต.</label>
          //           </p>
          //         </div>,

          //         // <IconText
          //         //   icon={""}
          //         //   text="156"
          //         //   key="list-vertical-like-o"
          //         // />,
          //       ]}
          //     >
          //       <List.Item.Meta
          //         title={
          //           <a style={{ fontSize: "24px" }} onClick={() => {

          //             history.push("/listeningDetail/" + i);
          //           }}>
          //             {item.title}
          //           </a>
          //         }
          //         description={item.description}
          //       />
          //     </List.Item>
          //   </Card>
          //   <Card
          //     style={{
          //       marginBottom: "30px",
          //       borderBottomRightRadius: "15px",
          //       borderBottomLeftRadius: "15px",
          //     }}
          //     className="shadow bg-white"
          //   >
          //     <Row>
          //       <Col span={12}>
          //         {" "}
          //         <div style={{ width: 170 }}>
          //           <Progress
          //             percent={50}
          //             showInfo={false}
          //             style={{ color: "#387436" }}
          //           />
          //           <Row>
          //             <Col span={12}>1 พย. 63</Col>
          //             <Col span={12} style={{ textAlign: "right" }}>
          //               30 พย. 63
          //             </Col>
          //           </Row>
          //         </div>
          //       </Col>
          //       <Col span={12} style={{ textAlign: "right" }}>
          //         <Row>
          //           <Col flex="auto" style={{ textAlign: "right" }}>
          //             <EyeOutlined /> 1,234
          //             <FormOutlined style={{ paddingLeft: "7px" }} /> 1,234
          //           </Col>
          //         </Row>
          //       </Col>
          //     </Row>
          //   </Card>
          // </div>
        )}
      />
    </section>
  );
};

export default ListeningList;
