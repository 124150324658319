import { Container } from '@material-ui/core';
import { Row, Col, Image, Space } from 'antd';
import { StaticImage } from '../../utils/image';
import './scss/mean.scss';

const arrayGift = [
  {
    title: `ความคิดเห็นจะถูกส่งตรงไปยังหน่วยงานที่รับผิดชอบ`,
    icon: StaticImage('/images/meanlaw/mean1.svg'),
  },
  {
    title: `สามารถติดตามสถานะการจัดทำร่างกฎหมายได้ยังหน่วยงานที่รับผิดชอบ`,
    icon: StaticImage('/images/meanlaw/mean2.svg'),
  },
  {
    title: `ข้อมูลส่วนบุคคลได้รับ ความคุ้มครองตามกฎหมาย มีความน่าเชื่อถือ และปลอดภัย`,
    icon: StaticImage('/images/meanlaw/mean3.svg'),
  },
  {
    title: `สามารถนำข้อมูลกฎหมาย
    ไปใช้ประโยชน์ต่อได้`,
    icon: StaticImage('/images/meanlaw/mean4.svg'),
  },
  {
    title: `ใช้อ้างอิงกฎหมายได้`,
    icon: StaticImage('/images/meanlaw/mean5.svg'),
  },
  {
    title: `มีการจัดเก็บข้อมูลพร้อมนำไป
    วิเคราะห์ต่อยอดได้ในอนาคต`,
    icon: StaticImage('/images/meanlaw/mean6.svg'),
  },
];

const arrayAgency = [
  {
    title: `กรมกิจการสตรีและสถาบันครอบครัว`,
    icon: StaticImage('/images/meanlaw/agency1.png'),
  },
  {
    title: `กรมทรัพย์สินทางปัญญา`,
    icon: StaticImage('/images/meanlaw/agency2.png'),
  },
  {
    title: `กรมสวัสดิการและคุ้มครองแรงงาน`,
    icon: StaticImage('/images/meanlaw/agency3.png'),
  },
  {
    title: `สำนักงานคณะกรรมการกำกับหลักทรัพย์
    และตลาดหลักทรัพย์`,
    icon: StaticImage('/images/meanlaw/agency4.png'),
  },
  {
    title: `สำนักงานคณะกรรมการพัฒนาระบบราชการ`,
    icon: StaticImage('/images/meanlaw/agency5.png'),
  },
  {
    title: `สำนักงานคณะกรรมการอาหารและยา`,
    icon: StaticImage('/images/meanlaw/agency6.png'),
  },
  {
    title: `สำนักเลขาธิการคณะรัฐมนตรี`,
    icon: StaticImage('/images/meanlaw/agency7.png'),
  },
  {
    title: `สำนักงานเลขาธิการสภาการศึกษา`,
    icon: StaticImage('/images/meanlaw/agency8.png'),
  },
  {
    title: `ธนาคารแห่งประเทศไทย`,
    icon: StaticImage('/images/meanlaw/agency9.png'),
  },
];

const MeanOfLaw = () => {
  return (
    <div id='mean-page'>
      <div className='section-banner'></div>
      {/* <div
        className='d-flex-center flex-column w-100'
        style={{
          position: 'absolute',
          marginTop: -50,
          top: 0,
          height: 300,
          backgroundColor: '#0c4da2',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: -1,
        }}
      ></div> */}

      <div
        className='d-flex-center flex-column'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container>
          <Image
            preview={false}
            width={'100%'}
            src={StaticImage('/images/meanlaw/artboard.jpg')}
          />
          <div className='d-flex flex-wrap' style={{ marginTop: 50 }}>
            <div className='flex-1 main-text-container'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-wrap' style={{ alignItems: 'center' }}>
                  <span className='title'>ระบบกลางทางกฎหมาย</span>
                  <span className='what'>คืออะไร ?</span>
                </div>
                <div className='d-flex'>
                  <span>
                    คือ เว็บไซต์ที่พัฒนาตามพรบ.
                    หลักเกณฑ์การจัดทำร่างกฎหมายและการประเมินผลสัมฤทธิ์ ของกฎหมาย
                    พ.ศ. 2562 เพื่อเป็นช่องทางที่ให้
                  </span>
                </div>
                <div className='d-flex flex-column list-container'>
                  <span>■ หน่วยงานของรัฐเปิดรับฟังความคิดเห็น</span>
                  <span>■ ประชาชนได้มีส่วนร่วมในการ แสดงความคิดเห็น</span>
                  <span>■ เป็นศูนย์กลางข้อมูลทางกฎหมายของประเทศ</span>
                </div>
                <div className='d-flex'>
                  <span>
                    โดยสำนักงานคณะกรรมการกฤษฎีกา (สคก.) และสำนักงาน
                    พัฒนารัฐบาลดิจิทัล (องค์การมหาชน) สพร. หรือ DGA ได้
                    ร่วมกันพัฒนาเว็บไซต์ https://law.go.th ระบบกลางทางกฎหมาย
                    โดยมีระยะการดำเนินงาน ดังนี้
                  </span>
                </div>
              </div>
            </div>
            <div className='flex-1'>
              <div className='d-flex-center'>
                <Image preview={false} width={300} src={StaticImage('/images/meanlaw.png')} />
              </div>
            </div>
          </div>
          <div className='d-flex flex-wrap'>
            <div
              className='flex-1 d-flex'
              style={{ marginRight: 10, minWidth: 300 }}
            >
              <div className='progress-container flex-1'>
                <div>
                  <Image
                    preview={false}
                    height={30}
                    src={StaticImage('/images/meanlaw/process1.svg')}
                  />
                </div>
                <h4>ระยะที่ 1</h4>
                <label>
                  จัดทำระบบแสดงความคิดเห็นประกอบการจัดทำ
                  ร่างกฎหมายและประเมินผลสัมฤทธิ์กฎหมายของหน่วยงานรัฐ
                </label>
              </div>
            </div>
            <div
              className='flex-1 d-flex'
              style={{ marginLeft: 10, minWidth: 300 }}
            >
              <div className='progress-container flex-1'>
                <div>
                  <Image
                    preview={false}
                    height={30}
                    src={StaticImage('/images/meanlaw/process2.svg')}
                  />
                </div>
                <h4>ระยะที่ 2</h4>
                <label>จัดทำฐานข้อมูลกฎหมายของประเทศ</label>
              </div>
            </div>
          </div>
          <div className='d-flex-center flex-column text-container'>
            <h1>ประชาชนได้อะไร?</h1>
            <h3>จากการมี ระบบกลางทางกฎหมาย</h3>
            <label>
              ‘ระบบกลาง’ เป็นระบบสำหรับเปิดรับฟัง
              และแสดงความคิดเห็นด้านกฎหมายที่
            </label>
          </div>
          <div
            className='d-flex flex-wrap'
            style={{ justifyContent: 'center', marginTop: 30 }}
          >
            {arrayGift.map((item) => (
              <div
                className='give-container d-flex flex-column'
                style={{ alignItems: 'center' }}
              >
                <div>
                  <Image preview={false} width={75} src={item.icon} />
                </div>
                <span className='text-center'>{item.title}</span>
              </div>
            ))}
          </div>
          <div className='d-flex-center flex-column text-container'>
            <h1>หน่วยงานนำร่อง โครงการพัฒนาระบบกลางฯ</h1>
          </div>
          <div
            className='d-flex flex-wrap'
            style={{ justifyContent: 'center', marginTop: 30 }}
          >
            {arrayAgency.map((item) => (
              <div
                className='agency-container d-flex flex-column'
                style={{ alignItems: 'center' }}
              >
                <div>
                  <Image width={125} src={item.icon} />
                </div>
                <span className='text-center'>{item.title}</span>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MeanOfLaw;
