import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth, setHeaderIPv4 } from '../utils';

class ApiLaw extends Component {
    static getUpdated = async (payload) => {
        await setHeaderAuth();
        const result = await axios({
            url: 'phase2/law',
            method: 'post',
            data: payload
        });
        return result;
    };

    static getSearchResult = async (payload) => {
        await setHeaderAuth();
        const result = await axios({
            url: 'phase2/law/searchResult',
            method: 'post',
            data: payload
        });
        return result;
    };

    static getSearchResultAdvance = async (payload) => {
        await setHeaderAuth();
        const result = await axios({
            url: 'phase2/law/searchResultAdvance',
            method: 'post',
            data: payload
        });
        return result;
    };

    static getSearchChar = async (payload) => {
        await setHeaderAuth();
        const result = await axios({
            url: 'phase2/law/searchChar',
            method: 'post',
            data: payload
        });
        return result;
    };

    static getDetail = async (id) => {
        await setHeaderAuth();
        const result = await axios({
            url: `phase2/law/detail/${id}`,
            method: 'get',
        });
        return result;
    };

    static like = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `phase2/law/like`,
            method: 'post',
            data: data,
        });
        return result;
    };

    static getLike = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: `phase2/law/like`,
            method: 'get',
        });
        return result;
    };

    static createComment = async (data) => {
        await setHeaderAuth();
        const result = await axios({
            url: `phase2/law/comment`,
            method: 'post',
            data: data,
        });
        return result;
    };

    static getMaster = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: 'phase2/law/master',
            method: 'get',
        });
        return result;
    };
    static getLawDashboard = async () => {
        await setHeaderAuth();
        const result = await axios({
            url: 'phase2/law/dashboard',
            method: 'get',
        });
        return result;
    };
}

export default ApiLaw;
