import './index.scss'
import { Checkbox, Col, DatePicker, Input, List, Row, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../../utils/WindowSize';
import Select from 'react-select'
import { useEffect, useState } from 'react';
import 'moment/locale/th';
import locale from 'antd/es/date-picker/locale/th_TH';
import { SearchOutlined } from '@ant-design/icons';
import ApiLaw from '../../../api/law';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { ConvertDateShort } from '../../../utils/date';
import { StaticImage } from '../../../utils/image';
import _ from 'lodash';
import { WatDatePicker } from 'thaidatepicker-react';
import moment from 'moment';

const AboutPhase2Page = (props) => {
    const [width, height] = useWindowSize();
    const [masters, setMasters] = useState({
        agency: [],
        agency_filter: [],
        hirachy: [],
        category: [],
    });
    const [topDashboard, settopDashboard] = useState({
        top1: 0,
        top2: 0,
        top3: 0,
        top1Date: "",
        top2Date: "",
        top3Date: "",
    });

    const [searchState, setSearchState] = useState({
        agency: [],
        start_date: "",
        end_date: "",
    });
    useEffect(() => {
        // const promise1 = getMaster();
        const promise2 = getLawDashboard();
        Swal.showLoading();
        Promise.all([promise2]).then(result => {
            Swal.close();
        })
    }, []);

    useEffect(() => {
        let filterData = [];
        let searchFilter = searchState.agency.map((x => x.label));
        if (searchFilter.length == 0) {
            filterData = masters.agency;
        } else {
            const filter = masters.agency.filter(x => searchFilter.includes(x.label))
            filterData = filter
        }
        if (filterData.length > 0) {
            console.log(filterData)
            if (searchState.start_date) {
                const filter = filterData.filter(x => x.lawData.length > 0 && moment(_.orderBy(x.lawData, ['max'], ['desc'])[0].max) >= moment(searchState.start_date));
                filterData = filter;
            }
            if (searchState.end_date) {
                const filter = filterData.filter(x => x.lawData.length > 0 && moment(_.orderBy(x.lawData, ['max'], ['desc'])[0].max).add(-1, 'day') <= moment(searchState.end_date));
                filterData = filter;
            }
        }
        setMasters({ ...masters, agency_filter: filterData })


    }, [searchState.agency, searchState.start_date, searchState.end_date]);





    const getMaster = async () => {
        const result = await ApiLaw.getMaster();
        if (result.status == 200) {
            const { data } = result;
            // data.agency.unshift({ value: null, label: 'หน่วยงาน' });
            data.hirachy.unshift({ value: null, label: 'ศักดิ์กฎหมาย' });
            data.category.unshift({ value: null, label: 'แสดงทุกหมวด' });
            setMasters(data);
        }
    }

    const getLawDashboard = async () => {
        const result = await ApiLaw.getLawDashboard();
        if (result.status == 200) {
            const { data } = result;
            settopDashboard({
                top1: data.topDashboard.find(x => x.hirachy_of_law_id == 887)?.count ?? 0,
                top2: data.topDashboard.find(x => x.hirachy_of_law_id == 1)?.count ?? 0,
                top3: data.topDashboard.find(x => x.hirachy_of_law_id == 893)?.count ?? 0,
                top1Date: data.topDashboard.find(x => x.hirachy_of_law_id == 887)?.max ?? "",
                top2Date: data.topDashboard.find(x => x.hirachy_of_law_id == 1)?.max ?? "",
                top3Date: data.topDashboard.find(x => x.hirachy_of_law_id == 893)?.max ?? ""
            })
            // data.agency.unshift({ value: null, label: 'หน่วยงาน' });
            data.hirachy.unshift({ value: null, label: 'ศักดิ์กฎหมาย' });
            data.category.unshift({ value: null, label: 'แสดงทุกหมวด' });
            setMasters({ ...data, agency_filter: data.agency });

        }
    }

    return <div id="about-phase2">
        <img className='w-full h-32 sm:h-auto'
            src={StaticImage('/images/phase2icon/top_2022-08-31/top@3x.png')}
        />

        {/* <div className="d-flex-align-center" style={{
            paddingLeft: '8%',
            paddingRight: '8%',
            // marginTop: width >= 768 ? 50 : 50,
            zoom: width >= 768 ? '100%' : '80%'
        }}>
            <div className='flex-1'>
                <div className='flex items-center'>
                    <div className='flex items-center'>
                        <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/box-taped-duotone.svg')} />
                        <label className='title'>ศูนย์กลางข้อมูลกฎหมายของประเทศ</label>
                    </div>
                    <div className='flex ml-2'>
                        <img className='w-4 mr-1' src={StaticImage('/images/phase2icon/circle-question-duotone.svg')} />
                        <span className='text-blue-100 italic underline'>เว็บไซต์นี้เกี่ยวกับอะไร?</span>
                    </div>
                </div>
                <section>
                    <SearchComponent onSearch={onSearch} defaultProps={props} searchState={searchState} setSearchState={setSearchState}></SearchComponent>
                </section>
                {searchState.isAdvance &&
                    <section>
                        <SearchAdvanceComponent
                            setsearchAdvanceState={setsearchAdvanceState}
                            searchAdvanceState={searchAdvanceState}
                            masters={masters}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            onSearchAdvance={onSearchAdvance}
                        >

                        </SearchAdvanceComponent>
                    </section>
                }
                {
                    searchState.isChar &&
                    <section>
                        <SearchCharComponent
                            masters={masters}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            searchCharState={searchCharState}
                            setsearchCharState={setsearchCharState}
                            onSearchChar={onSearchChar}
                        />
                    </section>
                }

            </div>
        </div> */}
        <div className='bg-white items-container pb-10'>
            <div className="d-flex-align-center px-5" style={{
                zoom: width >= 768 ? '100%' : '80%'
            }}>
                <div className='w-full'>
                    <div className='flex gap-x-2 mt-5 justify-between flex-wrap items-center gap-y-2'>
                        <div className='top-container flex-1 flex flex-column'>
                            <div className='flex items-center'>
                                <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scale-balanced-duotone-blue.svg')} />
                                <label className='text-base'>รัฐธรรมนูญ</label>
                            </div>
                            <div className='flex flex-col gap-y-2 sm:flex-row sm:items-center sm:justify-between mt-3'>
                                <span className='text-sm'>อัพเดทล่าสุด : {ConvertDateShort(topDashboard.top1Date, 'DD MM YY')}</span>
                                <span className='text-sm count w-fit'>{topDashboard.top1}</span>
                            </div>
                        </div>
                        <div className='top-container flex-1 flex flex-column'>
                            <div className='flex items-center'>
                                <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scroll-old-duotone-blue.svg')} />
                                <label className='text-base'>พ.ร.บ.ประกอบรัฐธรรมนูญ</label>
                            </div>
                            <div className='flex flex-col gap-y-2 sm:flex-row sm:items-center sm:justify-between mt-3'>
                                <span className='text-sm'>อัพเดทล่าสุด : {ConvertDateShort(topDashboard.top2Date, 'DD MMM YY')}</span>
                                <span className='text-sm count w-fit'>{topDashboard.top2}</span>
                            </div>
                        </div>
                        <div className='top-container flex-1 flex flex-column'>
                            <div className='flex items-center'>
                                <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/scroll-old-duotone-blue.svg')} />
                                <label className='text-base'>ประมวลกฎหมาย</label>
                            </div>
                            <div className='flex flex-col gap-y-2 sm:flex-row sm:items-center sm:justify-between mt-3'>
                                <span className='text-sm'>อัพเดทล่าสุด : {ConvertDateShort(topDashboard.top3Date, 'DD MMM YY')}</span>
                                <span className='text-sm count w-fit'>{topDashboard.top3}</span>
                            </div>
                        </div>
                        {/* <div className='top-container flex flex-column'>
                            <div className='flex items-center'>
                                <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/user-crown-duotone-blue.svg')} />
                                <label className='text-base'>พระบรมราชโองการ</label>
                            </div>
                            <div className='flex flex-col gap-y-2 sm:flex-row sm:items-center sm:justify-between mt-3'>
                                <span className='text-sm'>อัพเดทล่าสุด : 12 ก.ค. 2564</span>
                                <span className='text-sm count w-fit'>0,000</span>
                            </div>
                        </div> */}
                    </div>
                    <div className='mt-5 justify-between hidden sm:flex flex-col gap-y-4'>
                        <div className='d-flex items-center'>
                            <div className='flex items-center'>
                                <img alt="filter-icon" className='h-4 mr-2'
                                    src={StaticImage('/images/phase2icon/filters-duotone.svg')} />
                                <label className='mr-2 text-base'>ตัวกรอง</label>
                            </div>
                            <div className='flex-1'>
                                <Select
                                    aria-label='select-agency-search'
                                    isMulti
                                    className='basic-multi-select flex-1'
                                    classNamePrefix='select'
                                    //defaultValue={masters.agency[0] ?? ""}
                                    options={masters.agency}
                                    value={searchState.agency}
                                    onChange={(item) => {
                                        setSearchState({ ...searchState, agency: item })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex mt-3 gap-x-4'>
                            <div className='d-flex type-search'>
                                <div className='flex items-center'>
                                    <img className='h-4 mr-2'
                                        src={StaticImage('/images/phase2icon/calendar-week-duotone.svg')} />
                                    <label className='mr-2 text-base'>สืบค้นจากช่วงเวลา</label>
                                </div>
                            </div>
                            <div className='flex-1'>
                                <WatDatePicker
                                    clearable={true}
                                    value={searchState.start_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                                    onChange={(christDate, buddhistDate) => {
                                        setSearchState({ ...searchState, start_date: christDate ? moment(christDate) : "" });
                                    }}
                                    inputStyle={{
                                        backgroundColor: '#FFFFFF',
                                        fontSize: 14,

                                    }}
                                    placeholder="เริ่มต้น วว/ดด/ปป"
                                    dateFormat={'yyyy-MM-dd'}
                                    // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                                    displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                                    //inputStyle={{ color: 'red' }} // styles for input
                                    //minDate={'2020-12-26'} // supported date as string
                                    //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                                    disabled={false} // true | false
                                    readOnly={false} // true | false
                                    yearBoundary={99} // number of boundary ±X Year
                                />
                            </div>
                            <div className='flex-1'>
                                <WatDatePicker
                                    clearable={true}
                                    value={searchState.end_date} // Can be replace with string or dayjs object (e.g. "2020-12-31" or `dayjs()`)
                                    onChange={(christDate, buddhistDate) => {
                                        setSearchState({ ...searchState, end_date: christDate ? moment(christDate) : "" });
                                    }}
                                    inputStyle={{
                                        backgroundColor: '#FFFFFF',
                                        fontSize: 14,

                                    }}
                                    placeholder="สิ้นสุด วว/ดด/ปป"
                                    dateFormat={'yyyy-MM-dd'}
                                    // dateFormat={'yyyy-MM-dd'} // for set data purpose [using date-fns format](https://date-fns.org/v2.12.0/docs/format)
                                    displayFormat={'DD/MM/YYYY'} // for display purpose (using dayjs format)[https://day.js.org/docs/en/display/format]
                                    //inputStyle={{ color: 'red' }} // styles for input
                                    //minDate={'2020-12-26'} // supported date as string
                                    //maxDate={dayjs().add(20, 'day')} // also supported dayjs or moment
                                    disabled={false} // true | false
                                    readOnly={false} // true | false
                                    yearBoundary={99} // number of boundary ±X Year
                                />
                            </div>
                        </div>
                        <table class="table-auto">
                            <thead>
                                <tr>
                                    <th>
                                        <div className='flex flex-column items-center gap-y-1'>
                                            <img className='h-6 mr-2' src={StaticImage('/images/phase2icon/building-columns-duotone-blue.svg')} />
                                            <span>หน่วยงาน</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='flex flex-column items-center gap-y-1'>
                                            <img className='h-6' src={StaticImage('/images/phase2icon/scroll-old-duotone-blue.svg')} />
                                            <span>พระราชบัญญัติ</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='flex flex-column items-center gap-y-1'>
                                            <img className='h-6' src={StaticImage('/images/phase2icon/scroll-old-duotone-blue.svg')} />
                                            <span>พระราชกำหนด</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='flex flex-column items-center gap-y-1'>
                                            <img className='h-6' src={StaticImage('/images/phase2icon/book-duotone-blue.svg')} />
                                            <span>กฎกระทรวง</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='flex flex-column items-center gap-y-1'>
                                            <img className='h-6' src={StaticImage('/images/phase2icon/scroll-old-duotone-blue.svg')} />
                                            <span>ปว./ปร./คปค./คสช.</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='flex flex-column items-center gap-y-1'>
                                            <img className='h-6' src={StaticImage('/images/phase2icon/memo-pad-duotone-blue.svg')} />
                                            <span>ประกาศ</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className='flex flex-column items-center gap-y-1'>
                                            <img className='h-6' src={StaticImage('/images/phase2icon/clock-duotone-blue.svg')} />
                                            <span>วันที่อัพเดทล่าสุด</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {masters.agency_filter.map((item, index) => {
                                    let dashboard1 = item.lawData.find(x => x.hirachy_of_law_id == 1)?.count ?? 0
                                    let dashboard2 = item.lawData.find(x => x.hirachy_of_law_id == 883)?.count ?? 0
                                    let dashboard3 = item.lawData.find(x => x.hirachy_of_law_id == 888)?.count ?? 0
                                    let dashboard4 = item.lawData.find(x => [916, 917, 918, 919].includes(x.hirachy_of_law_id))?.count ?? 0
                                    let dashboard5 = item.lawData.find(x => x.hirachy_of_law_id == 2)?.count ?? 0;
                                    let maxDate = _.orderBy(item.lawData, ['max'], ['desc']);
                                    return <tr key={"agency" + index}>
                                        <td>{item.label}</td>
                                        <td className='text-center'><span className='text-sm count-blue'>{dashboard1 == 0 ? "-" : dashboard1}</span></td>
                                        <td className='text-center'><span className='text-sm count-blue'>{dashboard2 == 0 ? "-" : dashboard2}</span></td>
                                        <td className='text-center'><span className='text-sm count-blue'>{dashboard3 == 0 ? "-" : dashboard3}</span></td>
                                        <td className='text-center'><span className='text-sm count-blue'>{dashboard4 == 0 ? "-" : dashboard4}</span></td>
                                        <td className='text-center'><span className='text-sm count-blue'>{dashboard5 == 0 ? "-" : dashboard5}</span></td>
                                        <td className='text-center'><span className='text-sm'>{maxDate.length > 0 ? ConvertDateShort(maxDate[0].max, 'DD MMM YY') : "-"}</span></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


export default AboutPhase2Page;