import React from "react";

const BarDuoTone = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      dataPrefix="fas"
      dataIcon="bars"
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      style={{ color: "#0c4da2", width: "34px", height: "30px" }}
    >
      <path
        fill="currentColor"
       
        d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
      ></path>
    </svg>
  );
};

export default BarDuoTone;
