import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const AlertSuccess = (title = "สำเร็จ", text) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: "success",
    confirmButtonText: "ตกลง",
    timer: 1000,
  });
};

export default AlertSuccess;
