import axios from 'axios';
import { WEB_API } from '../env';
import { useSelector, useDispatch } from 'react-redux';
import configureStore from './../store';
const { store, persistor } = configureStore();
axios.defaults.baseURL = `${WEB_API}`;
//axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['x-api-key'] = "4nEZYvTwRFlUVn7aK85cZ2xSU83dOFai";
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
//axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      store.dispatch({
        type: 'clear_all',
      });
      window.location.href = '/';
    }
    throw error;
  }
);
