import React, { useState, useEffect } from 'react';
import { Space, Timeline, Skeleton, Badge } from 'antd';
import {
  TagsOutlined,
  ClockCircleOutlined,
  FileOutlined,
  DownloadOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import './index.scss';
import ApiMaster from '../../api/master';
import ApiProjectTimeline from '../../api/timeline';
import Avatar from 'antd/lib/avatar/avatar';
import { ConvertDateShort } from '../../utils/date';
import { useWindowSize } from '../../utils/WindowSize';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import {
  faBalanceScale,
  faPollH,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import SurveyCardList from './newLayout/survey/index';
import SurveyCardItemMobile from './newLayoutMobile/survey/index'
import SurveyCardListMobile, { ProjectCardV2Mobile } from './newLayoutMobile';
import { StaticImage } from '../../utils/image';
import { Container, fade, Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser } from '../../utils/checkUser';
import ApiSurvey from '../../api/survey';
import Select from 'react-select';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import _ from 'lodash';
import { useClipboard } from 'use-clipboard-copy';
import TagDuoTone from '../iconComponent/TagDuoTone';
import OutsideClickHandler from 'react-outside-click-handler';
import { encodeBase64 } from '../../utils/convertBase64';
import {
  isMobile,
} from 'react-device-detect';
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontFamily: 'Kanit',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectOption: {
    zIndex: 10,
  },
  cardSeq: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 30,
    padding: 10,
    boxShadow: '3.9px 1px 6px 0 rgba(137, 148, 169, 0.4)',
  },
  cardSeqLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardProjectNumber: {
    fontSize: 18,
    color: '#333',
  },
  cardSurveyTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#0c4da2',
    marginTop: 10,
    marginBottom: 10,
  },
  cardSurveyDescription: {
    color: '#666666',
    fontSize: 15,
  },
  cardSurveyTypeImage: {
    width: 20,
    height: 20,
    marginRight: 5,
    objectFit: 'contain',
    color: '#0c4da2',
  },
  cardSurveyTypeText: {
    fontSize: 14,
    color: '#0c4da2',
    fontWeight: 'bold',
  },
  cardSurveyLeftIcon: {
    width: 101,
    height: 101,
    padding: '18px 22px 20px 31px',
    borderRadius: 50,
    border: 'solid 5px #85b0e8',
    backgroundColor: '#0c4da2',
  },
  cardSurveyCommentIcon: {
    fontSize: 14,
    color: '#999',
  },
}));

const optionArr = [
  { label: 'ใหม่-เก่า', value: 'desc' },
  { label: 'เก่า-ใหม่', value: 'asc' },
];

const optionProgress = [
  { label: 'ทั้งหมด', value: '' },
  { label: 'ความคืบหน้า', value: 'process' },
  { label: 'แบบสำรวจการรับฟัง', value: 'survey' },
];

const ProjectTimeLinePage = (props) => {
  const clipboard = useClipboard({ copiedTimeout: 800 });
  const [showShare, setShowShare] = useState(false);
  const userState = useSelector((state) => state.user);
  const [width, height] = useWindowSize();
  const history = useHistory();
  const classes = useStyles();
  const [surveys, setsurveys] = useState([]);
  const [entitys, setEntitys] = useState([]);
  const [process, setprocess] = useState([]);
  const [project, setproject] = useState({});
  const [law_group, setlaw_group] = useState([]);
  const [survey_list, setsurvey_list] = useState([]);
  const [logs_activity, set_logs_activity] = useState([]);
  const [logs_activity_data, set_logs_activity_data] = useState([]);
  const [share, setshare] = useState({});
  const projectId = new URLSearchParams(props.location.search).get('projectId');
  useEffect(() => {
    getEntity();
    getSurvey();
    return () => { };
  }, []);

  const getSurvey = async () => {
    try {
      Swal.showLoading();
      const survey = await ApiProjectTimeline.getById({ project_id: projectId });

      if (survey.status == 200) {
        Swal.close();
        const {
          project,
          surveys,
          entitys,
          logs_activity,
          survey_list,
          law_group,
        } = survey.data;
        if (surveys.length == 0) {
          throw "ไม่พบแบบสำรวจที่เผยแพร่";
        }
        setsurveys(surveys);
        setproject(project);
        setEntitys(entitys);
        set_logs_activity(logs_activity);
        set_logs_activity_data(logs_activity);
        setsurvey_list(survey_list);
        setlaw_group(law_group);
        setshare({
          title: `เชิญเข้าร่วมติดตามความคืบหน้าของโครงการ ${project.project_name} ของ ${project.project_agency_name} ได้ที่นี่ ${window.location.origin}/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`,
          subject: `[LAW] ติดตามความคืบหน้าโครงการ ${project.project_name}`,
          shareUrl: `${window.location.origin}/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`,
        });
        // setsurveys();
      }
    } catch (error) {
      Swal.fire('เกิดข้อผิดพลาด', error, 'warning').then((result) => {
        history.go(-1);
      });
    }
  };

  const getEntity = async () => {
    const masterEntity = await ApiMaster.getEntity();

    if (masterEntity.status == 200) {
      const { data } = masterEntity.data;
      setprocess(data);
    }
  };

  const handleFavorite = async (e, item) => {
    e.stopPropagation();
    if (checkUser(userState)) {
      const model = {
        project_id: item.project_id,
      };

      try {
        const result = await ApiSurvey.favoriteProject(model);
        if (result.status == 200) {
          //filter
          const newProject = { ...project };

          newProject.is_like = !newProject.is_like;

          setproject(newProject);
        }
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    }
  };

  return (
    <div id="project-timeline-v2">
      <div className='section-banner'></div>
      <div
        className='d-flex-center flex-column w-100'
        style={{
          position: 'absolute',
          marginTop: -50,
          top: 0,
          height: 300,
          backgroundColor: '#0c4da2',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
      <div
        className='d-flex menu-top'
        style={{
          justifyContent: 'space-between',
          width: width > 768 ? '80%' : '100%',
          marginLeft: width > 768 ? 'auto' : 10,
          marginRight: 'auto',
        }}
      >

        <div className="d-flex" style={{ alignItems: 'center' }}>
          <div
            className='cursor-pointer back-button'
            style={{ zIndex: 100 }}
            onClick={() => {
              history.go(-1);
            }}
          >
            <span>{'ย้อนกลับ'}</span>
          </div>
        </div>
      </div >
      <div
        className='d-flex-center flex-column'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >

        {surveys.length > 0 && (
          <div
            className='project-container-home-page'
            style={{
              zIndex: 0,
              width: width > 768 ? '80%' : '100%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {width > 768 ? (
              <ProjectCardV2
                survey_list={survey_list}
                setShowShare={setShowShare}
                handleFavorite={handleFavorite}
                classes={classes}
                entitys={entitys}
                process={process}
                history={history}
                law_group={law_group}
                res={surveys[0]}
                project={project}
                logs_activity={logs_activity}
                renderType='main'
              />
            ) : (
              <div id="project-card-container-v2-mobile-timeline">
                <div className="survey-card-container">
                  <ProjectCardV2Mobile
                    survey_list={survey_list}
                    logs_activity={logs_activity}
                    setShowShare={setShowShare}
                    handleFavorite={handleFavorite}
                    classes={classes}
                    entitys={entitys}
                    history={history}
                    process={process}
                    law_group={law_group}
                    survey={surveys[0]}
                    project={project}
                    renderType='main'
                  />
                </div>
              </div>
            )}
            <div className='card-filter'>
              <div style={{ flex: 1, marginRight: 10 }}>
                <label style={{ marginBottom: 10, fontSize: 14 }}>
                  การรับฟัง/ปรับปรุงสถานะ
                </label>
                <Select
                  className='basic-single w-100'
                  classNamePrefix='select'
                  defaultValue={optionProgress[0]}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  isSearchable={!isMobile}
                  options={optionProgress}
                  onChange={(option) => {
                    if (option.value == 'survey') {
                      let filters = [
                        ...logs_activity_data.filter((x) => x.type == 'survey'),
                      ];
                      set_logs_activity(filters);
                    } else if (option.value == 'process') {
                      let filters = [
                        ...logs_activity_data.filter(
                          (x) => x.type == 'update_process'
                        ),
                      ];
                      set_logs_activity(filters);
                    } else {
                      set_logs_activity([...logs_activity_data]);
                    }
                    // filters = filters.filter(x=>x.)
                  }}
                />
              </div>

              <div style={{ flex: 1, marginLeft: 10 }}>
                <label style={{ fontSize: 14, marginBottom: 10 }}>
                  เรียงลำดับตาม
                </label>
                <Select
                  className='basic-single w-100'
                  classNamePrefix='select'
                  defaultValue={optionArr[0]}
                  isSearchable={!isMobile}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  options={optionArr}
                  onChange={(option) => {
                    let filters = [...logs_activity];
                    filters = _.orderBy(filters, 'created_date', option.value);
                    set_logs_activity(filters);
                  }}
                />
              </div>
            </div>
            {logs_activity.length > 0 ? (
              <Timeline style={{ marginTop: 20, marginLeft: 10 }}>
                <RecordItem
                  project={project}
                  width={width}
                  data={logs_activity}
                  entitys={process}
                  history={history}
                  law_group={law_group}
                />
              </Timeline>
            ) : (
              <div style={{ marginTop: 10 }} className='d-flex-center flex-1'>
                <span>ไม่มีรายการความคืบหน้า</span>
              </div>
            )}
          </div>
        )}
      </div>
      {
        showShare && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowShare(false);
            }}
          >
            <div className='share-container' style={{ overflowY: 'scroll' }}>
              <div className='header'>
                <span>แชร์</span>
                <img
                  onClick={() => setShowShare(false)}
                  style={{ position: 'absolute', right: 25 }}
                  src={StaticImage('/images/icon/drawer-header-close.png')}
                />
              </div>
              <div className='share-button'>
                <div>
                  <FacebookShareButton
                    url={share.shareUrl}
                    quote={share.title}
                    className='Demo__some-network__share-button'
                  >
                    <FacebookIcon size={40} />
                  </FacebookShareButton>
                  <span>Facebook</span>
                </div>

                <div>
                  <TwitterShareButton
                    url={' '}
                    title={share.title}
                    className='Demo__some-network__share-button'
                  >
                    <TwitterIcon size={40} />
                  </TwitterShareButton>
                  <span>Twitter</span>
                </div>
                <div>
                  <EmailShareButton
                    url={''}
                    subject={share.subject}
                    body={share.title}
                    className='Demo__some-network__share-button'
                  >
                    <EmailIcon size={40} />
                  </EmailShareButton>
                  <span>E-mail</span>
                </div>
                <div>
                  <iframe
                    frameborder='0'
                    data-size='large'
                    src={
                      'https://timeline.line.me/social-plugin-widget/share?buttonType=share-d&amp;type=share&amp;lang=en&amp;id=1&amp;size=large&amp;origin=' +
                      share.shareUrl +
                      '&amp;title=' +
                      share.title.substr(0, 170) +
                      ''
                    }
                    style={{
                      width: '40px',
                      height: '40px',
                      // visibility: 'visible',
                      // position: 'static',
                      // alignItems: 'center',
                      // marginLeft: '40px',
                    }}
                  ></iframe>
                  <span style={{ marginTop: 5 }}>LINE</span>
                </div>

                <div
                  onClick={() =>
                    clipboard.copy(
                      `${window.location.origin}/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`
                    )
                  }
                >
                  <div className='circle'>
                    <img
                      src={StaticImage(
                        '/images/icon/listeningDetail/link-regular.png'
                      )}
                    />
                  </div>
                  <span>คัดลอกลิงค์</span>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        )
      }
    </div >
  );
};

const RecordItem = ({ project = {}, data = [], entitys = [], width, history, law_group }) => {
  let components = [];
  data.map((item, index) => {
    const status = item.record_status == 'A' ? '' : 'disabled';
    if (item.type == 'survey') {
      {
        width >= 768 &&
          components.push(
            <Timeline.Item>
              <section id="project-timeline-v2-survey-card-container-v2">
                <div className="survey-card-container">
                  <SurveyCardList
                    survey={item.survey}
                    law_group={law_group}
                    key={'survey_id::' + index}
                  />
                </div>
              </section>
            </Timeline.Item>
          );
      }
      {
        width < 768 &&
          components.push(
            <Timeline.Item>
              <section id="project-timeline-v2-survey-card-container-v2-mobile">
                <div className="survey-card-container">
                  <SurveyCardItemMobile
                    survey={item.survey}
                    history={history}
                    law_group={law_group}
                    key={'survey_id::' + index}
                  />
                </div>
              </section>
            </Timeline.Item>
          );
      }
    } else if (item.type == 'update_process') {
      const json = JSON.parse(item.jsonobject);
      let old_process = entitys.find((x) => x.entity_id == json.old_process && x.project_type_id == project.project_type_id);
      const new_process = entitys.find((x) => x.entity_id == json.new_process && x.project_type_id == project.project_type_id);
      if (!old_process) {
        // old_process = entitys.filter((x) => x.project_type_id == project.project_type_id);
        // old_process = old_process[0];
        old_process = { entity_abbrv: "หน่วยงานจัดทำ" };
      }
      const files = json.process_file ? json.process_file.split(',') : [];
      const filename = item.filename;
      // let action = 'อัพเดทสถานะ';
      let old_process_name = old_process?.entity_abbrv;
      let new_process_name = new_process?.entity_abbrv;
      components.push(
        <Timeline.Item>
          <div className={`project-timeline-detail ${status}`}>
            <div
              className='project-timeline-body-top d-flex'
              style={{ alignItems: 'center' }}
            >
              <ClockCircleOutlined
                className='project-name d-flex'
                style={{ marginRight: 10 }}
              />
              <span className='project-name'>
                {ConvertDateShort(json.date, 'DD MMM YY')}
              </span>
              {status == 'disabled' && (
                <span style={{ marginLeft: 10 }} className='text-danger'>
                  (ยกเลิก)
                </span>
              )}
            </div>
            <div className='project-timeline-body-bottom d-flex flex-column'>
              <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                <Space style={{ flexWrap: 'wrap' }}>
                  <div className='d-flex' style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                    <div>
                      <span style={{ marginRight: 8 }}>หน่วยงานได้ทำการปรับปรุงสถานะจาก </span>
                    </div>
                    <div className='d-flex'>
                      <div className='process-update' style={{ marginRight: 8 }}>
                        <span>{old_process_name}</span>
                      </div>
                      <span style={{ marginRight: 8 }}>เป็น</span>
                      <div className='process-update'>
                        <span>{new_process_name}</span>
                      </div>
                    </div>
                  </div>
                </Space>
              </div>
              <div className='d-flex'>
                <Space>
                  <span>{'วันที่ปรับปรุงข้อมูล'}</span>
                  <span>
                    {ConvertDateShort(item.created_date, 'DD MMM YY HH:mm น.')}
                  </span>
                </Space>
              </div>
              {json.process_description && (
                <div className='description' style={{ marginTop: '10px' }}>
                  <p>{json.process_description}</p>
                </div>
              )}

              {files.map((file, fileIndex) => {
                return (
                  <div
                    className='file-container d-flex'
                    style={{ marginTop: '10px' }}
                  >
                    <div
                      className='d-flex filename'
                      style={{ alignItems: 'center' }}
                    >
                      <FileOutlined />
                      <span className='filename-text'>
                        {filename[fileIndex]}
                      </span>
                    </div>
                    <div
                      className='d-flex download'
                      style={{ alignItems: 'center' }}
                      onClick={() => {
                        window.open(file.replace('http://', 'https://'));
                      }}
                    >
                      <DownloadOutlined className='d-flex' />
                      <span>ดาวน์โหลดเอกสาร</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Timeline.Item>
      );
    } else if (item.type == 'update_checklist') {
      const json = JSON.parse(item.jsonobject);
      components.push(
        <Timeline.Item>
          <div className={`project-timeline-detail ${status}`}>
            <div
              className='project-timeline-body-top d-flex'
              style={{ alignItems: 'center' }}
            >
              <ClockCircleOutlined
                className='project-name d-flex'
                style={{ marginRight: 10 }}
              />
              <span className='project-name'>
                {ConvertDateShort(json.date, 'DD MMM YY')}
              </span>
              {status == 'disabled' && (
                <span style={{ marginLeft: 10 }} className='text-danger'>
                  (ยกเลิก)
                </span>
              )}
            </div>
            <div className='project-timeline-body-bottom d-flex flex-column'>
              <div className='d-flex'>
                <Space>
                  {/* <span>{item.fullname ?? item.created_user}</span> */}
                  <span>{'หน่วยงาน สลค. ได้ตรวจเอกสารโครงการนี้เรียบร้อยแล้ว'}</span>
                </Space>
              </div>
              <div className='d-flex'>
                <Space>
                  <span>{'วันที่ปรับปรุงข้อมูล'}</span>
                  <span>
                    {ConvertDateShort(item.created_date, 'DD MMM YY HH:mm น.')}
                  </span>
                </Space>
              </div>
              <div className='check-container' style={{ marginTop: '10px' }}>
                <div className='flex-1'>
                  <div className='flex-1'>
                    <Checkbox
                      checked={
                        json.is_check_document_draftlaw == 1 ? true : false
                      }
                      disabled
                    >
                      ร่างกฎหมาย
                    </Checkbox>
                  </div>
                  <div className='flex-1'>
                    <Checkbox
                      checked={json.is_check_document_ria == 1 ? true : false}
                      disabled
                    >
                      RIA
                    </Checkbox>
                  </div>
                </div>
                <div className='flex-1'>
                  <div className='flex-1'>
                    <Checkbox
                      checked={
                        json.is_check_document_summary == 1 ? true : false
                      }
                      disabled
                    >
                      สรุปผลการรับฟัง
                    </Checkbox>
                  </div>
                  <div className='flex-1'>
                    <Checkbox
                      checked={
                        json.is_check_document_reserve == 1 ? true : false
                      }
                      disabled
                    >
                      แผนกฎหมายลำดับรอง
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Timeline.Item>
      );
    } else {
      const json = JSON.parse(item.jsonobject);
      const files = json.file ? json.file.split(',') : [];
      const filename = item.filename;
      let action = '';
      if (item.type == 'upload_ria') {
        action = 'หน่วยงานได้จัดทำรายงานการประเมินผลกระทบของการออกกฎหมาย (RIA) เรียบร้อยแล้ว';
      }
      // if (item.type == 'upload_summary') {
      //   action = 'หน่วยงานได้จัดทำสรุปผลการรับฟังฯ เพื่อเตรียมนำเข้าเสนอ ครม. เรียบร้อยแล้ว';
      // }


      if (item.type == 'upload_summary') {
        if (project.project_type_id == 1) {
          action = 'หน่วยงานได้จัดทำสรุปผลการรับฟังฯ เพื่อเตรียมนำเข้าเสนอ ครม. เรียบร้อยแล้ว';
        }
        else {
          action = 'หน่วยงานได้จัดทำสรุปผลการรับฟังฯ ของโครงการเรียบร้อยแล้ว';
        }
      }

      if (item.type == 'upload_reserve') {
        action = 'หน่วยงานได้จัดทำแผนกฎหมายลำดับรอง เรียบร้อยแล้ว';
      }
      components.push(
        <Timeline.Item>
          <div className={`project-timeline-detail ${status}`}>
            <div
              className='project-timeline-body-top d-flex'
              style={{ alignItems: 'center' }}
            >
              <ClockCircleOutlined
                className='project-name d-flex'
                style={{ marginRight: 10 }}
              />
              <span className='project-name'>
                {ConvertDateShort(json.date, 'DD MMM YY')}
              </span>
              {status == 'disabled' && (
                <span style={{ marginLeft: 10 }} className='text-danger'>
                  (ยกเลิก)
                </span>
              )}
            </div>
            <div className='project-timeline-body-bottom d-flex flex-column'>
              <div className='d-flex'>
                <Space>
                  {/* <span>{item.fullname ?? item.created_user}</span> */}
                  <span>{action}</span>
                </Space>
              </div>
              <div className='d-flex'>
                <Space>
                  <span>{'วันที่ปรับปรุงข้อมูล'}</span>
                  <span>
                    {ConvertDateShort(item.created_date, 'DD MMM YY HH:mm น.')}
                  </span>
                </Space>
              </div>
              {json.description && (
                <div className='description' style={{ marginTop: '10px' }}>
                  <p>{json.description}</p>
                </div>
              )}

              {files.map((file, fileIndex) => {
                return (
                  <div
                    className='file-container d-flex'
                    style={{ marginTop: '10px' }}
                  >
                    <div
                      className='d-flex filename'
                      style={{ alignItems: 'center' }}
                    >
                      <FileOutlined />
                      <span className='filename-text'>
                        {filename[fileIndex]}
                      </span>
                    </div>
                    <div
                      className='d-flex download'
                      style={{ alignItems: 'center' }}
                      onClick={() => {
                        window.open(file.replace('http://', 'https://'));
                      }}
                    >
                      <DownloadOutlined className='d-flex' />
                      <span>ดาวน์โหลดเอกสาร</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Timeline.Item>
      );
    }
  });
  return components;
};
const sizes = {
  xs: 75,
  sm: 75,
  md: 75,
  lg: 75,
  xl: 75,
  xxl: 75,
}
const ProjectCardV2 = ({
  setShowShare,
  survey_list = [],
  logs_activity = [],
  res,
  project,
  classes,
  handleFavorite,
  history,
  entitys,
  process,
  law_group,
  renderType = 'child',
}) => {
  const entity_name = (project.entity_name) ?? entitys[0]?.entity_name;
  const renderImageLawGroup = (item) => {
    if (entity_name == "ออกเป็นกฎหมาย") {
      return <>
        <div className="law-group-container outlaw d-flex-center">
          <div className="d-flex-center">
            <Avatar
              alt="image of law group"
              className="avatar-object-contain"
              size={sizes}
              style={{
                height: 75,
                width: 75,
              }}
              // shape={'circle'}
              src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
            />
          </div>
        </div>
      </>;
    }
    let law = '';
    if (item.lawgroup_id_multiple) {
      const split = item.lawgroup_id_multiple.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 75,
                sm: 75,
                md: 75,
                lg: 75,
                xl: 75,
                xxl: 75,
              }}
              style={{
                height: 75,
                width: 75,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={100} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };

  const renderLawGroupText = (item) => {
    console.log(item);
    let law = "ไม่ได้ระบุ";
    if (item.law_group_id) {
      const split = item.law_group_id.split(",");
      law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
      law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
    }
    return (
      <div
        className="d-flex discuss-container"
        style={{ marginRight: 15, marginTop: 5, marginBottom: 10 }}
      >
        <img
          src={StaticImage("/images/icon/books-duotone-1-copy.svg")}
          style={{ marginRight: 5 }}
          alt="webboard"
          height={20}
        />
        <label className="lawgroup-text" style={{ color: "#333", fontSize: 16 }}>
          {law}
        </label>
      </div>
    );
  };

  const handleChat = (e) => {
    e.stopPropagation();
    history.push(`/Post?project_id=${project.project_id}`);
  };

  return (
    <section style={{ marginBottom: 20 }}>
      <div
        className={`project-card-desktop ${entity_name == "ไม่ออกเป็นกฎหมาย" ? " law-cancal" : ""}`}
        onClick={() => {
          if (res.survey_type_id != 3) {
            history.push(`/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`);
          }
        }}
      >
        <div className=''>
          <div style={{ display: 'flex', height: '100%' }}>
            <div
              style={{
                borderRight: '1px solid #e5e6ee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {renderImageLawGroup(project)}
              <div
                className='d-flex-center'
                style={{ marginTop: 20, minWidth: 170 }}
              >
                {res.agency_id > 0 && (
                  <div
                    className='organize-container'
                    style={{ margin: 0, maxWidth: 100 }}
                  >
                    <img
                      alt="agency_image"
                      src={res.agency_image_uploaded ?? res.agency_image}
                      className="logo"
                      oncontextmenu="return false;"
                      onError={(i) => (i.target.style.display = "none")}
                    />
                    <label style={{ paddingLeft: "2px" }}>
                      {res.agency_short ?? res.agency_abbrv}
                    </label>
                  </div>
                )}
              </div>
              <div className='d-flex tag' style={{ marginTop: 20 }}>
                <TagDuoTone className={classes.cardSurveyTypeImage} />
                <span style={{ color: '#0c4da2' }}>
                  {res.survey_type_id == 1 || res.survey_type_id == 2
                    ? 'ร่างกฎหมาย'
                    : res.survey_type_name}
                </span>
              </div>
            </div>
            <div
              style={{
                marginLeft: 40,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                {(entity_name == "ออกเป็นกฎหมาย" && project.law_title) &&
                  <div className='d-flex law_publish_title' style={{ alignItems: 'center' }}>
                    <Avatar
                      alt="image of law group"
                      className="avatar-object-contain"
                      size={sizes}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                      // shape={'circle'}
                      src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
                    />
                    {project.law_title}
                  </div>
                }
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="d-flex-align-center">
                    <div className="d-flex-align-center project-id-container">
                      <span className="text-regular">เลขที่ (ID) {project.project_id}</span>
                    </div>
                    <div className="d-flex-align-center" style={{ marginLeft: 15 }}>
                      <img
                        src={StaticImage("/images/icon/calendar-alt-duotone-2.svg")}
                        style={{ marginRight: 10 }}
                        alt="calendar"
                        height={20}
                      />
                      <span className="text-regular">
                        {ConvertDateShort(project.createdate, "DD MMM YY")}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex-center'>
                    <div
                      className='d-flex-center'
                      onClick={() => setShowShare(true)}
                      style={{ marginRight: 10 }}
                    >
                      <div class="group relative py-2">
                        <FontAwesomeIcon
                          className='share-icon cursor-pointer'
                          style={{ width: 24, height: 24, marginRight: 4 }}
                          color='#6d7481'
                          icon={faShareAlt}
                        />
                        <div class="hidden group-hover:block ...">แบ่งปัน</div>
                      </div>
                    </div>
                    <div
                      className='d-flex-center'
                      onClick={(e) => handleFavorite(e, res)}
                    >
                      <div class="group relative py-2">
                        {project.is_like === true ? (
                          <img
                            height={32}
                            width={32}
                            src={StaticImage(
                              '/images/icon/header/heart-circle-duotone-2-active.svg'
                            )}
                          />
                        ) : (
                          <img
                            className='cursor-pointer'
                            height={32}
                            width={32}
                            onMouseOver={(e) =>
                            (e.currentTarget.src = StaticImage(
                              '/images/icon/header/heart-circle-duotone-2-active.svg'
                            ))
                            }
                            onMouseOut={(e) =>
                            (e.currentTarget.src = StaticImage(
                              '/images/icon/header/heart-circle-duotone-2.svg'
                            ))
                            }
                            src={StaticImage(
                              '/images/icon/header/heart-circle-duotone-2.svg'
                            )}
                          />
                        )}
                        <div class="hidden group-hover:block ...">ถูกใจ</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <label className={classes.cardSurveyTitle}>
                    {res.project_name ?? res.project_name}
                  </label>
                  <div className='d-flex-center'>
                    {/* <div
                      className='d-flex-center'
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <FontAwesomeIcon
                        className='text-icon'
                        style={{ marginRight: 10, fontSize: 32 }}
                        color='#bababa'
                        icon={faPollH}
                      />
                      <span style={{ marginRight: 10 }}>แบบสำรวจการรับฟัง</span>
                      <Badge
                        count={survey_list.length}
                        style={{ backgroundColor: '#387436' }}
                        className='site-badge-count-4'
                      />
                    </div> */}

                    {logs_activity.filter((x) => x.type == 'upload_ria')
                      .length > 0 && (
                        <div className='d-flex-center' style={{ marginLeft: 10 }}>
                          <img
                            height={28}
                            width={28}
                            src={StaticImage('/images/icon/header/ria.svg')}
                          />
                        </div>
                      )}

                    {logs_activity.filter((x) => x.type == 'upload_summary')
                      .length > 0 && (
                        <div className='d-flex-center' style={{ marginLeft: 10 }}>
                          <img
                            height={24}
                            width={24}
                            src={StaticImage('/images/icon/header/summary.svg')}
                          />
                        </div>
                      )}
                  </div>
                </div>
                <div className="webboard-row">
                  {renderLawGroupText(res)}
                </div>
              </div>
              <div className='d-flex flex-1 project-card-body'>
                <div className='d-flex process-now'>
                  <FontAwesomeIcon icon={faBalanceScale} />
                  <span className='entity-text'>
                    {project.entity_name ?? entitys[0]?.activity_abbrv}
                  </span>
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div className='project-timeline-body-bottom d-flex w-100'>
                  <div className="d-flex-align-center flex-1" style={{ justifyContent: 'space-between', marginTop: 20, alignSelf: 'flex-end', width: '100%' }}>
                    {(entity_name == "ออกเป็นกฎหมาย" && project.law_url) &&
                      <div className='law_publish_link'>
                        <label onClick={(e) => {
                          e.stopPropagation();
                          window.open(project.law_url, '_blank')
                        }} className='flex-1'>{project.law_url}</label>
                      </div>
                    }
                    {entity_name !== "ออกเป็นกฎหมาย" &&
                      <div
                        className='d-flex flex-1'
                        style={{ flexWrap: 'wrap', marginTop: -10, zoom: '80%' }}
                      >
                        {entitys.map((item, index) => {
                          let order_id = project.process_order;
                          if (order_id == 1) {
                            order_id = entitys[0].order_id
                          }
                          const findIndex = entitys.findIndex(
                            (x) =>
                              x.order_id ==
                              (order_id ?? entitys[0].order_id)
                          );
                          let className = '';

                          if (
                            item.order_id ==
                            (order_id ?? entitys[0].order_id)
                          ) {
                            className = 'active';
                          }
                          if (findIndex > index) {
                            className += 'pass';
                          }
                          return (
                            <div
                              className={`process-step d-flex-center ${className}`}
                            >
                              {item.entity_abbrv}
                            </div>
                          );
                        })}
                      </div>
                    }
                    <div className="d-flex expand cursor-pointer">
                      <div className="badge-public">{survey_list.length}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectTimeLinePage;
