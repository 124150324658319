import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth, setHeaderIPv4 } from '../utils';

class ApiWebBoard extends Component {
  static getPost = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `webboard/find`,
      method: 'post',
      data: data
    });
    return result;
  };
  static getPostAgency = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `webboard/findPostAgency`,
      method: 'post',
      data: data,
    });
    return result;
  };
  static history = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: `webboard/history`,
      method: 'get',
    });
    return result;
  };

  static deletePost = async (id) => {
    await setHeaderAuth();
    const result = await axios({
      url: `webboard/delete/${id}`,
      method: 'delete',
    });
    return result;
  };

  static createPost = async (data) => {
    await setHeaderIPv4();
    const result = await axios({
      url: `webboard`,
      method: data.id > 0 ? 'put' : 'post',
      data: data,
    });
    return result;
  };
  static likePost = async (data) => {
    const result = await axios({
      url: `webboard/like`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static spamPost = async (data) => {
    const result = await axios({
      url: `webboard/report`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static getComment = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `webboard/comment/find`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static createComment = async (data) => {
    await setHeaderIPv4();
    const result = await axios({
      url: `webboard/comment`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static getReply = async (data) => {
    await setHeaderAuth();
    const result = await axios({
      url: `webboard/reply/find`,
      method: 'post',
      data: data,
    });
    return result;
  };

  static createReply = async (data) => {
    await setHeaderIPv4();
    const result = await axios({
      url: `webboard/reply`,
      method: 'post',
      data: data,
    });
    return result;
  };
}

export default ApiWebBoard;
