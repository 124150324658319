import AlertInfo from './AlertInfo';
export const checkUser = (user) => {
  if (user.username) {
    return true;
  } else {
    AlertInfo('', 'กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน');
    return false;
  }
};

export const checkUserBool = (user) => {
  if (user.username) {
    return true;
  } else {
    return false;
  }
};
