import React, { useState, useEffect } from 'react';
import { StaticImage } from '../../utils/image';
import CommentAltEdit from '../iconComponent/CommentAltEdit';
import { Button, Radio } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import ApiSurvey from '../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import './index.scss';
import CustomizedSteppers from './CustomizedSteppers';
import ListeningTab from '../listening/ListeningTab';
import ScrollIndecator from '../main/ScrollIndecator';
import ApiMaster from '../../api/master';
const DetailinLinkindex = (props) => {
  const [surveys, setsurveys] = useState([]);
  const [entitys, setEntitys] = useState([]);
  const projectId = new URLSearchParams(props.location.search).get('projectId');
  useEffect(() => {
    getSurvey();
    getEntity();
    return () => {};
  }, []);

  const getSurvey = async () => {
    Swal.showLoading();
    const survey = await ApiSurvey.getByProjectId({ project_id: projectId });
    if (survey.status == 200) {
      Swal.close();
      const { data } = survey.data;

      setsurveys(data);
    }
  };

  const getEntity = async () => {
    Swal.showLoading();
    const masterEntity = await ApiMaster.getEntity();

    if (masterEntity.status == 200) {
      Swal.close();
      const { data } = masterEntity.data;
      setEntitys(data);
    }
  };

  return (
    <div>
      {/* <section className='section'>
        <div className='container' style={{ paddingTop: 0 }}>
          <img height={50} src={StaticImage('/images/layer-14.png')} />
          <div className='section-label'>
            <label>{surveys[0]?.project_name}</label>
          </div>
        </div>
      </section> */}
      <section className='section-banner'>
        
        <div
          style={{
            paddingTop: 0,
            display: 'flex',
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <img height={50} src={StaticImage('/images/layer-14.png')} />
          <div className='section-label'>
            <label>{surveys[0]?.project_name}</label>
          </div>
        </div>
      </section>
      <section style={{ marginBottom: 10 }}>
        <div className='section1-container'>
          <div style={{ paddingRight: 10 }}>
            <label>{surveys[0]?.survey_name}</label>
          </div>
          <div>
            {/* <div className='Rectangle-3-copy-5 container'>
              <CommentAltEdit className='comment-alt-edit-duotone-2' />
              <label>เปิดรับฟัง ครั้งที่ 2</label>
            </div> */}
            {/* <div className='Rounded-Rectangle-6 container'>
              <img
                height={50}
                className='Layer-18'
                src={StaticImage('/images/icon/layer-18@3x.png')}
              />
              <label>ก.ล.ต.</label>
            </div> */}
            {/* <div className='container2'>
              <Button icon={<StarOutlined />} />
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className='section2-container'> */}
      {/* <img src={StaticImage("/images/shape-1@3x.png")} /> */}
      {/* </section> */}
      {entitys.length > 0 && (
        <section>
          <CustomizedSteppers
            success={entitys.findIndex(
              (x) => x.entity_id == surveys[0]?.entity_id
            )}
            items={entitys}
          />
        </section>
      )}

      <section className='background-copy-3'></section>

      <section>
        <ListeningTab surveys={surveys} />
      </section>
    </div>
  );
};

export default DetailinLinkindex;
