import React from 'react'

const FaxDuoTone = ({className}) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ariaHidden="true"
        className={className}
        viewBox="0 0 512 512"
      >
        <g className="fa-group">
          <path
            fill="currentColor"
            d="M480 96v96H128V32a32 32 0 0 1 32-32h224v80a16 16 0 0 0 16 16z"
            class="fa-secondary"
            opacity=".4"
          />
          <path
            fill="currentColor"
            d="M64 128H32a32 32 0 0 0-32 32v320a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32zm416 64H128v288a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V224a32 32 0 0 0-32-32zM288 432a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zm0-128a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zm128 128a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zm0-128a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16zM400 96h80v-4.58a17.92 17.92 0 0 0-5.25-12.67l-73.43-73.5A18 18 0 0 0 388.57 0H384v80a16 16 0 0 0 16 16z"
            className="fa-primary"
          />
        </g>
      </svg>
    );
}

export default FaxDuoTone
