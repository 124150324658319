import { Avatar, Radio } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';
import { StaticImage } from "../../../utils/image";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import AlertError from "../../../utils/AlertError";
import ApiLaw from "../../../api/law";

const DetailLawPageTab4 = ({ onCancel, onSubmit, law = {} }) => {
    const userState = useSelector((state) => state.user);
    const [verify, setverify] = useState(false)
    var verifyCallback = function (response) {
        if (response) {
            setverify(true);
        }
    };

    const [answer, set_answer] = useState(0);
    const [post_message, setpost_message] = useState('');

    var callback = function () {
        console.log('Done!!!!');
    };


    const handleSubmitPublicComment = async () => {
        Swal.showLoading();
        try {
            const model = {
                answer: answer,
                message: post_message,
                law_id: law.law_id
            };
            const result = await ApiLaw.createComment(model);
            if (result.status == 200) {
                Swal.fire({
                    title: 'สำเร็จ',
                    text: 'ส่งข้อเสนอแนะเรียบร้อยแล้ว',
                    icon: 'success',
                }).then((ok) => {
                    setverify(false);
                    setpost_message('');
                    set_answer(0);
                    onSubmit();
                });
            } else {
                throw Error('');
            }
        } catch (error) {
            Swal.close();
        }
    };


    return <div className="tab4">
        <div className="title-container flex flex-column">
            <span className="text-black-300 font-bold">{law.law_name_og}</span>
            {/* <span className="italic">{law.simple_description?.replace(/(<([^>]+)>)/ig, '')}</span> */}
        </div>
        <div className="content-container flex items-center flex-column gap-y-3">
            <div className="w-full sm:w-10/12 border border-gray-200 rounded">
                <div className="py-3 px-5 border-b border-gray-200">ข้อเสนอแนะ</div>
                <div className="py-3 px-5 border-b border-gray-200">
                    <Radio.Group
                        style={{ display: 'flex' }}
                        className="flex flex-column gap-y-2"
                        onChange={(e) => set_answer(e.target.value)}
                        value={answer}
                    >
                        <Radio value={1}>เห็นด้วย</Radio>
                        <Radio value={2}>ควรปรับปรุง</Radio>
                        <Radio value={3}>ควรยกเลิก</Radio>
                    </Radio.Group>
                </div>
                <div className="flex py-3 px-5 items-center gap-x-2">
                    <div
                        className='d-flex-center'
                        style={{ display: 'flex', cursor: 'pointer' }}
                    >
                        <Avatar
                            shape='circle'
                            className='avatar-object-contain'
                            src={
                                userState.image_profile
                                    ? userState.image_profile
                                    : StaticImage('/images/login/user-circle-duotone-1-copy.png')
                            }
                            style={{
                                // border: '2px solid #387436',
                                width: '30px',
                                height: '30px',
                            }}
                        />
                        {userState.is_verify ? (
                            <img
                                className='badge-check-duotone'
                                src={StaticImage('/images/login/badge-check-duotone.png')}
                            />
                        ) : (
                            <img
                                className='badge-check-duotone'
                                src={StaticImage('/images/login/badge-check-duotone.png')}
                                style={{ opacity: 0.2 }}
                            />
                        )}
                    </div>
                    <div className="flex-1 flex flex-column gap-y-1">
                        <TextArea
                            className='w-100 textarea-theme-small'
                            type={'text'}
                            maxLength={1000}
                            placeholder={'เขียนแสดงความเห็น'}
                            rows={3}
                            disabled={answer == 0}
                            value={post_message}
                            onChange={(e) => setpost_message(e.target.value)}
                        />
                        <span className="self-end">{`${post_message.length}/1000`}</span>
                    </div>
                </div>
            </div>
            <div id="recaptcha" className="w-full" style={{ zoom: '120%' }}>
                <ReCAPTCHA
                    className='d-flex-center'
                    style={{ marginTop: 20 }}
                    sitekey='6Lfxs6kaAAAAALjK038cIdMbaiqxaH4lC8M5l8rn'
                    onChange={verifyCallback}
                    size="normal"
                // onloadCallback={callback}
                />
            </div>

            <div className="w-full sm:w-10/12 flex justify-between">
                <button onClick={onCancel} className="bg-red-500 text-white py-1 px-4 rounded">ยกเลิก</button>
                <button
                    disabled={(verify && answer > 0) ? false : true}
                    onClick={() => {
                        handleSubmitPublicComment();

                    }} className="bg-blue text-white py-1 px-4 rounded">ยืนยัน</button>
            </div>
        </div>

    </div>
}

export default DetailLawPageTab4;