let rawHTML = `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1254"><p style="text-align:center;"><span>พระราชบัญญัติ</span></p><p style="text-align:center;"><span>วิธีพิจารณาคดียาเสพติด</span></p><p style="text-align:center;"><span>พ.ศ. ๒๕๕๐</span></p></div>`;
rawHTML += `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1255"><p style="text-align:center;"><span>ภูมิพลอดุลยเดช ป.ร.</span></p><p style="text-align:center;"><span>ให้ไว้ ณ วันที่ ๓๑ ธันวาคม พ.ศ. ๒๕๕๐</span></p><p style="text-align:center;"><span>เป็นปีที่ ๖๒ ในรัชกาลปัจจุบัน</span></p></div>`
rawHTML += `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1256"><p style="text-indent:72.0pt;"><span>พระบาทสมเด็จพระปรมินทรมหาภูมิพลอดุลยเดช มีพระบรมราชโองการโปรดเกล้าฯ ให้ประกาศว่า</span></p><p style="text-indent:72.0pt;"><span>โดยที่เป็นการสมควรมีกฎหมายว่าด้วยวิธีพิจารณาคดียาเสพติด</span></p><p style="text-indent:72.0pt;"><span>พระราชบัญญัตินี้มีบทบัญญัติบางประการเกี่ยวกับการจำกัดสิทธิและเสรีภาพของบุคคลซึ่งมาตรา ๒๙ ประกอบกับมาตรา ๓๓ มาตรา ๓๖ และมาตรา ๔๑ ของรัฐธรรมนูญแห่งราชอาณาจักรไทยบัญญัติให้กระทำได้โดยอาศัยอำนาจตามบทบัญญัติแห่งกฎหมาย</span></p><p style="text-indent:72.0pt;"><span>จึงทรงพระกรุณาโปรดเกล้าฯ ให้ตราพระราชบัญญัติขึ้นไว้โดยคำแนะนำและยินยอมของสภานิติบัญญัติแห่งชาติ ดังต่อไปนี้</span></p></div>`
rawHTML += `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1257"><p style="text-indent:72.0pt;"><span id="S1"></span><span>มาตรา ๑</span><span>  พระราชบัญญัตินี้เรียกว่า </span><span>“</span><span>พระราชบัญญัติวิธีพิจารณาคดียาเสพติด พ.ศ. ๒๕๕๐</span><span>”</span></p></div>`

let rawHTMLENG = `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1254"><p style="text-align:center;"><span>EXCISE</span></p><p style="text-align:center;"><span>ACT</span></p><p style="text-align:center;"><span>B.E 2550 (2017)</span></p></div>`;
rawHTMLENG += `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1255"><p style="text-align:center;"><span>ภูมิพลอดุลยเดช ป.ร.</span></p><p style="text-align:center;"><span>ให้ไว้ ณ วันที่ ๓๑ ธันวาคม พ.ศ. ๒๕๕๐</span></p><p style="text-align:center;"><span>เป็นปีที่ ๖๒ ในรัชกาลปัจจุบัน</span></p></div>`
rawHTMLENG += `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1256"><p style="text-indent:72.0pt;"><span>พระบาทสมเด็จพระปรมินทรมหาภูมิพลอดุลยเดช มีพระบรมราชโองการโปรดเกล้าฯ ให้ประกาศว่า</span></p><p style="text-indent:72.0pt;"><span>โดยที่เป็นการสมควรมีกฎหมายว่าด้วยวิธีพิจารณาคดียาเสพติด</span></p><p style="text-indent:72.0pt;"><span>พระราชบัญญัตินี้มีบทบัญญัติบางประการเกี่ยวกับการจำกัดสิทธิและเสรีภาพของบุคคลซึ่งมาตรา ๒๙ ประกอบกับมาตรา ๓๓ มาตรา ๓๖ และมาตรา ๔๑ ของรัฐธรรมนูญแห่งราชอาณาจักรไทยบัญญัติให้กระทำได้โดยอาศัยอำนาจตามบทบัญญัติแห่งกฎหมาย</span></p><p style="text-indent:72.0pt;"><span>จึงทรงพระกรุณาโปรดเกล้าฯ ให้ตราพระราชบัญญัติขึ้นไว้โดยคำแนะนำและยินยอมของสภานิติบัญญัติแห่งชาติ ดังต่อไปนี้</span></p></div>`
rawHTMLENG += `<div _ngcontent-yge-c152="" class="pt-2 preview-html ng-star-inserted" id="sec-content-1257"><p style="text-indent:72.0pt;"><span id="S1"></span><span>มาตรา ๑</span><span>  พระราชบัญญัตินี้เรียกว่า </span><span>“</span><span>พระราชบัญญัติวิธีพิจารณาคดียาเสพติด พ.ศ. ๒๕๕๐</span><span>”</span></p></div>`
export const rawHTMLDiv = rawHTML;
export const rawHTMLENGDiv = rawHTMLENG;