import { checkContentLawType, numberToThai } from '../../../utils';
import { rawHTMLDiv } from './text';
import { renderToString } from 'react-dom/server'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom';

const DetailLawPageTab1 = ({ law, lawActiveType }) => {
    const history = useHistory();
    const handleRef = (id) => {
        let ref = id.replace("[", "");
        ref = ref.replace("]", "");
        const violation = document.getElementById(ref);
        let rect = violation.getBoundingClientRect();
        window.scrollTo({
            top: rect.top < 0 ? rect.top + (window.scrollY - 150) : rect.top + window.scrollY,
            behavior: "smooth"
        });
    }
    let footnoteSeq = 0;
    let footnoteDesc = 0;
    const contentDisplay = () => {
        let item_list = [];
        if (lawActiveType == "process") {
            item_list = law.content_list_process;
        } else {
            item_list = law.content_list;
        }
        if (!item_list.length > 0) {
            return <div>-</div>
        }
        let content_notshow = ['หมายเหตุ', 'อื่นๆ', 'ผู้มีอำนาจลงนาม']
        let content_list = [];
        let footnoteList = []
        let forewardList = [];
        var elForward = document.createElement('div');
        if (lawActiveType == "process") {
            law.foreword_process = numberToThai(law?.foreword_process)
            elForward.innerHTML = law?.foreword_process;
        } else {
            law.foreword = numberToThai(law?.foreword)
            elForward.innerHTML = law?.foreword;
        }

        let taghtmlCollectionForward = elForward.querySelectorAll('p,figure');

        let tagArrayForward = Array.prototype.slice.call(taghtmlCollectionForward);
        // forewardList.push(
        //     <p style={{
        //         textIndent: '72pt'
        //     }} dangerouslySetInnerHTML={{ __html: state.foreword }} />
        // )
        tagArrayForward.map((item, index) => {
            forewardList.push(
                <p style={{
                    textIndent: item.style?.textAlign.includes('center') ? '0pt' : '72pt',
                    marginLeft: item.style?.textAlign.includes('center') ? '0pt' : (item.outerHTML.includes('figure') ? '72pt' : '0pt')
                }} dangerouslySetInnerHTML={{ __html: item.outerHTML }} />
            )
        })
        if (item_list.length > 0) {
            item_list.map((item, index) => {
                item.content_desc = numberToThai(item.content_desc);
                let footnote_list_filter = item.footnote_list.filter(x => x.content_number == item.content_number && x.content_type == item.content_type);
                console.log(footnote_list_filter)
                let footnote_title = footnote_list_filter.find(x => x.type == "content");
                let footnoteComponent = "";

                const position = checkContentLawType(item.content_type);
                let styles = {};
                let containerStyles = {

                };
                let pStyles = {};
                let footnotetitleStyle = {};
                if (position == "center") {
                    containerStyles = {
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textIndent: '72pt',
                        position: 'relative'
                    };
                    footnotetitleStyle = {
                        left: '49%'
                    }
                    pStyles = {
                        width: '100%'
                    }
                } else if (position == "left") {
                    containerStyles = {
                        textIndent: '72pt',
                        position: 'relative'
                    };
                    // styles = {
                    //     textIndent: '72pt'
                    // };
                }
                let title = ""
                if (item.content_type !== "อื่นๆ" && item.content_type !== "ผู้มีอำนาจลงนาม" && item.content_number != 0) {
                    title = `${item.content_type} ${numberToThai(item.content_number)}\t`;
                }
                // title += (item.content_footnote ? "\t" : "");
                let desc = item?.content_desc;
                desc = desc.replaceAll("<p", "<span");
                desc = desc.replaceAll("</p>", "</span>");
                var el = document.createElement('div');
                el.innerHTML = desc;
                if (footnote_title) {
                    footnoteDesc += 1;
                    footnoteComponent = <a style={{ textIndent: `${title.length * 4.7}pt`, position: 'absolute', top: -2, ...footnotetitleStyle }} id={`#ref-${footnoteDesc}`}
                        onClick={(e) => handleRef(`#foot-${e.target.textContent}`)} className="text-info">
                        <sup >{`[${footnoteDesc}]`}
                        </sup>
                    </a>
                }
                if (footnote_list_filter.length > 0) {
                    footnote_list_filter.filter(x => x.type == "description").map((subitem, index) => {
                        console.log("subitem::", subitem)
                        footnoteDesc += 1;
                        el.style.position = "relative";
                        el.innerHTML = el.innerHTML.replace(`${numberToThai(subitem.content_ref)}`,
                            `${numberToThai(subitem.content_ref)} <sup class="text-blue cursor-pointer sup-content" id="#ref-${footnoteDesc}">[${footnoteDesc}]</sup>`
                        );
                    });
                }



                let taghtmlCollection = el.querySelectorAll('span,figure');

                //let taghtmlCollection = el.getElementsByTagName('span,figure');

                let tagArray = Array.prototype.slice.call(taghtmlCollection);


                // if (tag.length > 0) {
                //     desc = tag[0].innerHTML;
                // }
                if (item.footnote_list.length > 0) {
                    item.footnote_list.map((footnote, footnoteIndex) => {
                        footnoteSeq += 1;
                        footnoteList.push(
                            <div>
                                <a id={`#foot-${footnoteSeq}`} className="mr-1 text-info" onClick={(e) => handleRef(`#ref-${e.target.textContent}`)}>
                                    <sup>{`[${footnoteSeq}]`}
                                    </sup>
                                </a>
                                <span>{footnote.description}</span>
                            </div>
                        )
                    })
                }
                content_list.push(
                    <div id={`#item-${index}`} style={{ marginTop: 20 }}>
                        <p style={containerStyles}>
                            {
                                footnoteComponent
                            }
                            {
                                tagArray.map((subitem, subindex) => {
                                    if (subitem.parentElement.tagName == "TD" || subitem.parentElement.tagName == "SPAN") {
                                    } else {
                                        let spanHtml = ""
                                        let spanRef = "";
                                        if (subindex == 0) {
                                            spanHtml = renderToString(<span className="mr-1" >{title}</span>)
                                        }
                                        return <>
                                            <p dangerouslySetInnerHTML={{ __html: spanHtml + subitem.outerHTML }} />
                                        </>
                                    }

                                })

                            }
                        </p>
                    </div>
                )

            })
        }

        return <div className='ql-editor ck ck-content'>
            {/* <p style={{ textAlign: 'center' }}>{state.law_name_og}</p>
            <p style={{ textAlign: 'center' }}>{numberToThai(state.law_year)}</p> */}
            {forewardList}
            {content_list}
            {footnoteList}
        </div>;
    }

    const fileDisplay = () => {
        const file_docx = law.content_files.find(x => x.type == "law_content_file");
        return <div className='flex gap-x-2'>
            <span>กฎหมายฉบับนี้ มีการแก้ไขกฎหมายเพิ่มเติมโดยกรณีอื่น</span>
            {
                file_docx ? <span onClick={() => {
                    window.open(file_docx.file_path);
                }} className='text-blue cursor-pointer underline'>{
                        file_docx.file_name
                    }</span> :
                    <span>ไม่มีไฟล์แนบ</span>
            }
        </div>
    }

    const lawOtherDisplay = () => {
        return <div className='flex gap-x-2'>
            <span>กฎหมายฉบับนี้ มีการแก้ไขกฎหมายเพิ่มเติมโดยกรณีอื่น</span>
            <Link to={`/DetailLawPage?table_of_law_id=${law.law_content_tableoflaw_id}`}>
                <span className='text-blue cursor-pointer underline'>{
                    law.law_content_name
                }</span>
            </Link>
        </div>
    }

    useEffect(() => {
        setTimeout(() => {
            const allWithClass = Array.from(
                document.getElementsByClassName('sup-content')
            );
            allWithClass.forEach(element => {
                element.onclick = function (e) {
                    handleRef(`#foot-${e.target.textContent}`)
                };
            });
        }, 2000);
    }, [law, lawActiveType]);

    return <div className="tab1">
        <div className="title-container flex flex-column">
            <span className="text-black-300 font-bold">{law.law_name_th}</span>
            {/* <span className="italic">{law.simple_description?.replace(/(<([^>]+)>)/ig, '')}</span> */}
        </div>
        <div className="content-container">
            {
                lawActiveType == "left" ? <>
                    {
                        !(law.law_content_type > 0) && contentDisplay()
                    }
                    {
                        (law.law_content_type == 3) && fileDisplay()

                    }
                    {
                        (law.law_content_type == 2) && fileDisplay()
                    }
                    {
                        (law.law_content_type == 1) && lawOtherDisplay()
                    }

                </> : contentDisplay()
            }

        </div>
    </div>
}

export default DetailLawPageTab1;