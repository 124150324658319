import React from 'react';

const GrinDuoTone = ({ className, style = {} }) => {
  return (
    <svg
      style={style}
      xmlns='http://www.w3.org/2000/svg'
      ariaHidden='true'
      className={className}
      viewBox='0 0 496 512'
    >
      <g className='fa-group'>
        <path
          fill='currentColor'
          d='M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm80 168a32 32 0 1 1-32 32 32 32 0 0 1 32-32zm-160 0a32 32 0 1 1-32 32 32 32 0 0 1 32-32zm223.8 162.7c-9.3 55-83.2 93.3-143.8 93.3s-134.5-38.3-143.8-93.3a16 16 0 0 1 20.7-17.9C155.1 330.5 200 336 248 336s92.9-5.5 123.1-15.2c11.3-3.7 22.6 6.1 20.7 17.9z'
          className='fa-secondary'
          opacity='.4'
        />
        <path
          fill='currentColor'
          d='M168 176a32 32 0 1 0 32 32 32 32 0 0 0-32-32zm160 0a32 32 0 1 0 32 32 32 32 0 0 0-32-32z'
          className='fa-primary'
        />
      </g>
    </svg>
  );
};

export default GrinDuoTone;
