import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import ApiSurvey from '../../api/survey';
import { isMobile } from 'react-device-detect';
import { encodeBase64 } from '../../utils/convertBase64';
import ApiMaster from '../../api/master';

const IndexCarousel = ({ history }) => {
  const [mouseMoved, setMouseMoved] = useState(false);
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    getBanner();
    return () => { };
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    rtl: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getBanner = async () => {
    try {
      const result = await ApiMaster.banner();
      if (result.status == 200) {
        const { data } = result.data;

        setBanners(data);
      }
    } catch (error) {

    }
  };

  const handleClick = (url) => {
    if (!mouseMoved) {
      history.push(url);
    }
  };

  const handleOpen = (url) => {
    if (!mouseMoved) {
      window.open(url, '_blank');
    }
  };

  const renderBanner = () => {
    let components = [];
    banners.map((item, index) => {
      components.push(
        <div key={index}>
          <div
            onMouseMove={() => setMouseMoved(true)}
            onMouseDown={() => setMouseMoved(false)}
            onMouseUp={() =>
              item.survey_id > 0
                ? handleClick(`/listeningDetail?survey_id=${encodeBase64(item.survey_id)}`)
                : handleOpen(item.link_url)
            }
            style={{
              backgroundImage: `url(${item.image})`,
              cursor: 'pointer',
              height: isMobile ? 150 : 150,
            }}
            className='shadow p-3 mb-5 bg-white card-carousel'
          ></div>
        </div>
      );
    });
    return components;
  };

  return (
    <div className='row'>
      {banners.length > 0 && <Slider {...settings}>{renderBanner()}</Slider>}
    </div>
  );
};

export default IndexCarousel;
