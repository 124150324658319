import React, { useEffect, useState } from 'react';
import '../../main/submenulist.scss';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../../utils/image';
import { Container, fade, Grid, makeStyles } from '@material-ui/core';
import { Card, Space, Skeleton, Badge } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';

import TagDuoTone from '../../iconComponent/TagDuoTone';
import './index.scss';
import { ConvertDateShort } from '../../../utils/date';
import ProgressBar from './ProgressBar';

import {
  HeartFilled,
  HeartOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { checkLeftTime, checkComplete } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPollH,
  faTimesCircle,
  faArrowCircleDown,
  faBalanceScale,
  faShareAlt
} from '@fortawesome/free-solid-svg-icons';
import { encodeBase64 } from '../../../utils/convertBase64';

const scoreText = [
  { value: 'last', label: '#ล่าสุด', color: '#0c4da2' },
  { value: 'answer', label: '#ตอบมากที่สุด', color: '#a51830' },
  // { value: 'read', label: '#ความนิยม', color: '#387436' },
  { value: 'popular', label: '#ความนิยม', color: '#387436' },
  //{ value: 'project', label: '#เฉพาะโครงการ', color: '#387436' },
];

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontFamily: 'Kanit',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectOption: {
    zIndex: 10,
  },
  cardSeq: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 30,
    padding: 10,
    boxShadow: '3.9px 1px 6px 0 rgba(137, 148, 169, 0.4)',
  },
  cardSeqLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardSurveyTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#0c4da2',
    WebkitLineClamp: 1,
    maxHeight: 20,
    textOverflow: 'ellipsis',
  },
  cardSurveyDescription: {
    color: '#666666',
    fontSize: 15,
  },
  cardSurveyTypeImage: {
    width: 20,
    height: 20,
    marginRight: 5,
    objectFit: 'contain',
    color: '#0c4da2',
  },
  cardSurveyTypeText: {
    fontSize: 14,
    color: '#0c4da2',
    fontWeight: 'bold',
  },
  cardSurveyLeftIcon: {
    width: 101,
    height: 101,
    padding: '18px 22px 20px 31px',
    borderRadius: 50,
    border: 'solid 5px #85b0e8',
    backgroundColor: '#0c4da2',
  },
  cardSurveyCommentIcon: {
    fontSize: 14,
    color: '#999',
  },
}));

const SurveyCardListMobile = ({ law_group, res, history }) => {
  const classes = useStyles();
  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
    }

    return (
      <div className='law-group-container-mobile d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 40,
                sm: 40,
                md: 40,
                lg: 40,
                xl: 40,
                xxl: 40,
              }}
              style={{
                height: 40,
                width: 40,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={40} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };

  const renderAnswerCount = (survey) => {
    let answer_count = survey.answer_count ? survey.answer_count : 0;

    if (survey.survey_id == 280) {
      answer_count += 53;
    }
    if (survey.survey_id == 281) {
      answer_count += 76;
    }
    if (survey.survey_id == 283) {
      answer_count += 99;
    }
    if (survey.survey_id == 284) {
      answer_count += 32;
    }
    if (survey.survey_id == 285) {
      answer_count += 46;
    }
    if (survey.survey_id == 286) {
      answer_count += 22;
    }
    if (survey.survey_id == 287) {
      answer_count += 20601;
    }
    if (survey.survey_id == 288) {
      answer_count += 43;
    }
    if (survey.survey_id == 289) {
      answer_count += 295;
    }
    return (
      <div className='d-flex discuss-container' style={{ marginRight: 8 }}>
        <img
          src={StaticImage('/images/icon/comment-alt-edit-duotone-4.svg')}
          style={{ marginLeft: 2, marginRight: 8 }}
        />
        <label style={{ fontSize: 14, color: '#333' }}>{answer_count}</label>
      </div>
    );
  };
  return (
    <>
      <div
        onClick={() => {
          {
            if (res.is_coming === '1') {
            } else {
              history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
            }
          }
        }}
        className='container-survey d-flex flex-column'
        style={{ marginTop: 0, marginLeft: 0, padding: 0 }}
      >
        <div
          className='project-timeline-body-top d-flex'
          style={{
            alignItems: 'center',
            padding: 10,
            borderBottom: '1px solid #eff1f5',
            backgroundColor: '#fff',
          }}
        >
          <ClockCircleOutlined
            style={{ marginRight: 10, color: '#387436', fontSize: '1rem' }}
          />
          <span
            style={{ fontSize: '1rem', color: '#387436', fontWeight: 'bold' }}
          >
            {ConvertDateShort(res.created_date, 'DD MMM YY')}
          </span>
        </div>
        <div style={{ flex: 1, padding: '14px 10px 14px 10px' }}>
          <div
            className='d-flex'
            style={{
              paddingBottom: 10,
              borderBottom: '1px solid #e5e6ee',
            }}
          >
            <div className='d-flex' style={{ flex: 1, alignItems: 'center' }}>
              {renderImageLawGroup(res)}
              {res.agency_id > 0 && (
                <div className='agency-container' style={{ maxWidth: 120 }}>
                  <img
                    alt="agency_image"
                    src={res.agency_image_uploaded ?? res.agency_image}
                    className="logo"
                    oncontextmenu="return false;"
                    onError={(i) => (i.target.style.display = "none")}
                  />
                  <label style={{ paddingLeft: "2px" }}>
                    {res.agency_short ?? res.agency_abbrv}
                  </label>
                </div>
              )}
              <div className='d-flex' style={{ marginLeft: 5 }}>
                <TagDuoTone className={classes.cardSurveyTypeImage} />
                <span className={classes.cardSurveyTypeText}>
                  {res.survey_type_name}
                </span>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column flex-1' style={{ marginTop: 10 }}>
            <label className={'project-name'}>
              {res.short_project_name ?? res.project_name}
            </label>

            <label className={'survey-name'}>
              {res.short_survey_name ?? res.survey_name}
            </label>
            <div
              style={{
                display: 'flex',
                marginTop: 10,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className='d-flex'>
                <div
                  className='d-flex discuss-container'
                  style={{ marginRight: 0 }}
                >
                  <img
                    src={StaticImage('/images/icon/users-class-light.svg')}
                    //style={{ marginRight: 10 }}
                    className='user-status'
                  />
                  <label className='text-status'>กำลังร่วมเสนอ</label>
                </div>
                {renderAnswerCount(res)}
              </div>
              <Space>
                <span className='text-number'>ครั้งที่</span>
                <span
                  style={{
                    color: '#0c4da2',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  {res.publish_no
                    ? res.publish_no.toString().padStart('2', '0')
                    : '00'}
                </span>
              </Space>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                {ConvertDateShort(res.start_date, 'DD MMM YY')}
              </span>
              <section
                className='progress-list-filter'
                style={{ width: '100%', maxWidth: 400 }}
              >
                <ProgressBar
                  bgcolor={res.date_balance > 7 ? '#387436' : '#f96b57'}
                  bgContainerColor={
                    res.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'
                  }
                  leftTime={checkLeftTime(res)}
                  completed={checkComplete(res)}
                />
              </section>
              <span>
                <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                  {ConvertDateShort(res.end_date, 'DD MMM YY')}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProjectCardV2Mobile = ({
  setShowShare,
  law_group,
  survey_list = [],
  logs_activity = [],
  survey,
  classes,
  process,
  handleFavorite,
  history,
  entitys,
  project,
  renderType = 'child',
}) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const entity_name = (project.entity_name) ?? entitys[0]?.entity_name;
  const handleChat = (e) => {
    e.stopPropagation();
    history.push(`/Post?project_id=${project.project_id}`);
  };
  const navigate = (project_id) => {
    history.push(`/ProjectTimeline?projectId=${encodeBase64(project_id)}`);
  }
  const renderImageLawGroup = (item) => {
    if (entity_name == "ออกเป็นกฎหมาย") {
      return <>
        <div className="law-group-container-mobile outlaw d-flex-center" style={{ marginRight: 15 }}>
          <div className="d-flex-center">
            <Avatar
              alt="image of law group"
              className="avatar-object-contain"
              size={{
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 24,
                xxl: 24,
              }}
              style={{
                height: 24,
                width: 24,
              }}
              // shape={'circle'}
              src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
            />
          </div>
        </div>
      </>;
    }
    let law = "";
    if (item.law_group_id) {
      const split = item.law_group_id.split(",");
      law = law_group.find((x) => x.lawgroup_id == split[0]);
    }

    return (
      <div className="law-group-container-mobile d-flex-center" style={{ marginRight: 15 }}>
        <div className="d-flex-center">
          {law && law.image ? (
            <Avatar
              alt="image of law group"
              className="avatar-object-contain"
              size={{
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24,
                xl: 24,
                xxl: 24,
              }}
              style={{
                height: 24,
                width: 24,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={24} shape={"circle"} />
          )}
        </div>
      </div>
    );
  };
  const renderLawGroupText = (item) => {
    let law = "ไม่ได้ระบุ";
    if (item.law_group_id) {
      const split = item.law_group_id.split(",");
      law = law_group.filter((x) => split.includes(x.lawgroup_id.toString()));
      law = law.length > 0 ? law.map(x => x.lawgroup_name).join(',') : "-";
    }

    return (
      <div
        className="d-flex discuss-container"
        style={{ marginRight: 15 }}
      >
        <img
          src={StaticImage("/images/icon/books-duotone-1-copy.svg")}
          style={{ marginRight: 5 }}
          alt="webboard"
          height={18}
        />
        <label className="lawgroup-text" style={{ color: "#333" }}>
          {law}
        </label>
      </div>
    );
  };

  const renderFavorite = (survey) => {
    return (
      survey.is_like === true ? (
        <img
          height={32}
          width={32}
          src={StaticImage(
            "/images/icon/header/heart-circle-duotone-2-active.svg"
          )}
          alt="favorite"
        />
      ) : (
        <img
          height={32}
          width={32}
          className="cursor-pointer"
          onMouseOver={(e) =>
          (e.currentTarget.src = StaticImage(
            "/images/icon/header/heart-circle-duotone-2-active.svg"
          ))
          }
          onMouseOut={(e) =>
          (e.currentTarget.src = StaticImage(
            "/images/icon/header/heart-circle-duotone-2.svg"
          ))
          }
          src={StaticImage(
            "/images/icon/header/heart-circle-duotone-2.svg"
          )}
          alt="favorite"
        />
      )
    );
  }
  return (
    <>
      <section style={{ marginBottom: 20 }}>
        <div className={`survey-card ${entity_name == "ไม่ออกเป็นกฎหมาย" ? " law-cancal" : ""}`}
          onClick={() => navigate(project.project_id)}>
          {(entity_name == "ออกเป็นกฎหมาย" && project.law_title) &&
            <div className='d-flex law_publish_title' style={{ alignItems: 'center' }}>
              <Avatar
                alt="image of law group"
                className="avatar-object-contain"
                style={{
                  height: 25,
                  width: 25,
                }}
                // shape={'circle'}
                src={StaticImage("/images/logo/garuda-emblem-of-thailand-monochrome-2.svg")}
              />
              {project.law_title}
            </div>
          }
          <div className="d-flex row1">
            <div className="d-flex-align-center flex-1">
              {renderImageLawGroup(survey)}
              {survey.agency_id > 0 && (
                <div
                  className="organize-container"
                  style={{ margin: 0, marginRight: 15 }}
                >
                  <img
                    alt="agency_image"
                    src={survey.agency_image_uploaded ?? survey.agency_image}
                    className="logo"
                    oncontextmenu="return false;"
                    onError={(i) => (i.target.style.display = "none")}
                  />
                  <label style={{ paddingLeft: "2px" }}>
                    {survey.agency_short ?? survey.agency_abbrv}
                  </label>
                </div>
              )}
              <div className="project-type">
                <TagDuoTone />
                <span>
                  {survey.survey_type_name}
                </span>
              </div>

            </div>
            <div
              onClick={() => setShowShare(true)}
              style={{ marginRight: 10, marginTop: 2 }}
            >

              <div class="group relative py-2">
                <FontAwesomeIcon
                  className='share-icon cursor-pointer'
                  style={{ width: 20, height: 28, marginRight: 10 }}
                  color='#6d7481'
                  icon={faShareAlt}
                />
                <div class="hidden group-hover:block ...">แบ่งปัน</div>
              </div>
            </div>
            <div onClick={(e) => handleFavorite(e, survey)}>
              <div class="group relative py-2">
                {renderFavorite(survey)}
                <div class="hidden group-hover:block ...">ถูกใจ</div>
              </div>
            </div>
          </div>
          <div className="column2">
            <div className="d-flex-align-center">
              <div className="d-flex-align-center project-id-container" style={{ marginRight: 10 }}>
                <span className="text-regular">เลขที่ (ID) {project.project_id}</span>
              </div>
              <div className="d-flex-align-center">
                <img
                  src={StaticImage("/images/icon/calendar-alt-duotone-2.svg")}
                  style={{ marginRight: 10 }}
                  alt="calendar"
                  height={20}
                />
                <span className="text-regular">
                  {ConvertDateShort(project.createdate, "DD MMM YY")}
                </span>
              </div>
            </div>

            <div className="d-flex">
              <label className="title flex-1">{project.project_name}</label>
            </div>
            <div className="webboard-row">
              {renderLawGroupText(survey)}
            </div>
            <div className="d-flex-align-center process-container">
              <FontAwesomeIcon icon={faBalanceScale} />
              <span className="entity-text text-regular">
                {project.entity_name ?? entitys[0]?.entity_name}
              </span>
            </div>
            <div className="d-flex-align-center flex-1" style={{ justifyContent: 'space-between', alignSelf: 'flex-end', width: '100%' }}>
              {(entity_name == "ออกเป็นกฎหมาย" && project.law_url) &&
                <label onClick={(e) => {
                  e.stopPropagation();
                  window.open(project.law_url, '_blank')
                }} className='law_publish_link flex-1'>{project.law_url}</label>
              }
              {entity_name !== "ออกเป็นกฎหมาย" &&
                <div className="project-timeline-body-bottom flex-1">
                  <div
                    className="d-flex"
                    style={{ flexWrap: "wrap", marginTop: -10 }}
                  >
                    {entitys.map((item, index) => {
                      let order_id = project.process_order;
                      if (order_id == 1) {
                        order_id = entitys[0].order_id
                      }
                      const findIndex = entitys.findIndex(
                        (x) =>
                          x.order_id ==
                          (order_id ?? entitys[0].order_id)
                      );
                      let className = '';

                      if (
                        item.order_id ==
                        (order_id ?? entitys[0].order_id)
                      ) {
                        className = 'active';
                      }
                      if (findIndex > index) {
                        className += 'pass';
                      }
                      return (
                        <div
                          className={`process-step d-flex-center ${className}`}
                        >
                          {item.entity_abbrv}
                        </div>
                      );
                    })}
                  </div>
                </div>
              }
              <div className="d-flex expand cursor-pointer">
                <div className="badge-public">{survey_list.length}</div>
              </div>
            </div>
          </div>

        </div>

      </section>
    </>
  );
};

export default SurveyCardListMobile;
