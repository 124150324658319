import { StaticImage } from '../../../../utils/image';
import './subCard.scss';
import TagDuoTone from "../../../iconComponent/TagDuoTone";
import { checkComplete, checkLeftTime, checkLeftTimeIcon, checkLeftTimeStatus } from '../../../../utils';
import { ConvertDateShort } from '../../../../utils/date';
import { useHistory } from 'react-router-dom';
import { encodeBase64 } from '../../../../utils/convertBase64';
import ProgressBar from './../../progressBar';
const SurveyCardSubItem = ({ survey }) => {
    const history = useHistory();
    const renderPublishNo = () => {
        return (
            <div className="d-flex-align-center" style={{ marginRight: 20 }}>
                <img
                    src={StaticImage("/images/icon/pen-nib-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={16}
                    alt="public-no"
                />
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span>ครั้งที่</span>
                    <div className="badge-public">{survey.publish_no}</div>
                </div>
            </div>
        );
    };
    const renderDate = () => {
        return (
            <div className="d-flex-align-center" style={{ marginRight: 20 }}>
                <img
                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={16}
                    alt="public-no"
                />
                <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span>
                        {`${ConvertDateShort(survey.start_date, "DD MMM YY")} - ${ConvertDateShort(survey.end_date, "DD MMM YY")}`}
                    </span>
                </div>
            </div>
        );
    };
    const renderStatus = () => {
        return (
            checkLeftTimeStatus(survey)
        );
    };

    const navigate = (survey_id) => {
        history.push(`/listeningDetail?survey_id=${encodeBase64(survey_id)}`);
    }

    return <div className="survey-sub-card-container" onClick={() => navigate(survey.survey_id)}>
        <div className="d-flex-align-center flex-1">
            <span className="title text-primary text-regular">{survey.short_project_name ?? survey.project_name}</span>
        </div>
        <div className="d-flex-align-center flex-1 bottom-position" >
            <div className="d-flex-align-center survey-type-container">
                <TagDuoTone />
                <span>
                    {survey.survey_type_name}
                </span>
            </div>
            {renderPublishNo()}

        </div>
        <div className="d-flex-align-center flex-1 bottom-position">
            {checkLeftTimeIcon(survey) ? <>
                <img
                    src={StaticImage(checkLeftTimeIcon(survey) ? "/images/icon/vote-yea-duotone.svg" : "/images/icon/vote-nay-duotone.svg")}
                    style={{ marginRight: 5 }}
                    height={14}
                    alt="public-no"
                />
                <span style={{ fontSize: 12 }}>
                    {ConvertDateShort(survey.start_date, "DD MMM YY")}
                </span>
                <section
                    className="progress-list-filter"
                    style={{ width: "100%", maxWidth: 200 }}
                >
                    <ProgressBar
                        bgcolor={survey.date_balance > 7 ? "#387436" : "#f96b57"}
                        bgContainerColor={
                            survey.date_balance > 7 ? "#e4e8f0" : "#e4e8f0"
                        }
                        leftTime={checkLeftTime(survey)}
                        completed={checkComplete(survey)}
                    />
                </section>
                <span>
                    <span style={{ fontSize: 12 }}>
                        {ConvertDateShort(survey.end_date, "DD MMM YY")}
                    </span>
                </span>
            </>
                :
                <>
                    {renderDate()}
                    {renderStatus()}
                </>
            }
        </div>
    </div>
}

export default SurveyCardSubItem;