import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
const AlertError = (title = "ผิดพลาด", text) => {
    return Swal.fire({
        title: title,
        text: text,
        icon: "error",
        confirmButtonText: "ตกลง",
    });
}

export default AlertError