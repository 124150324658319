import React, { useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { StaticImage } from "../../utils/image";
import { Box, Grid, Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import BarDuoTone from "../iconComponent/BarDuoTone";
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from "react-device-detect";
import "./mainIndex.scss";
import CommentAltEdit from "../iconComponent/CommentAltEdit";
import NotificationsTwoToneIcon from "@material-ui/icons/NotificationsTwoTone";
import { Avatar } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { checkUser, checkUserBool } from "../../utils/checkUser";
import { HeartFilled } from "@ant-design/icons";
import FavoriteIcon from "../iconComponent/bottomNavigator/Favorite";
import ButtonComponent from "../../utils/ButtonComponent";
import { useWindowSize } from "../../utils/WindowSize";
import Notification from "../notificationHistory/Notification"
import ApiNotification from "../../api/notification";

const drawerWidth = 300;

const menuState = [
  {
    label: "ระบบกลางทางกฎหมาย",
    icon: StaticImage("/images/icon/drawer/info-duotone-copy.png"),
    type: "more",
  },
  {
    label: 'โครงการรับฟัง',
    icon: StaticImage('/images/icon/drawer/law-phase1.png'),
    type: 'home',
  },
  {
    label: 'ฐานข้อมูลกฎหมาย',
    icon: StaticImage('/images/icon/drawer/law-phase2.png'),
    type: 'phase2',
  },
  {
    label: "เกี่ยวกับเรา",
    icon: StaticImage("/images/icon/drawer/landmark-duotone-2.png"),
    type: "aboutus",
  },
  {
    label: "ดูสถิติการรับฟัง",
    icon: StaticImage("/images/statistics.png"),
    type: "statistics",
  },
  {
    label: "ข้อมูลอื่นๆ",
    icon: StaticImage("/images/icon/drawer/circle-info-duotone@2x.png"),
    type: "acheive",
  },
  {
    label: "คู่มือการใช้งาน",
    icon: StaticImage("/images/icon/drawer/usermanual@2x.png"),
    type: "doc",
  },
  {
    label: "คำถามที่พบบ่อย (FAQ)",
    icon: StaticImage("/images/icon/drawer/faq@2x.png"),
    type: "faq",
  },
  {
    label: "สารบัญ Website",
    icon: StaticImage("/images/icon/drawer/list-of-content@2x.png"),
    type: "listwebsite",
  },
  {
    label: "ติดต่อเรา",
    icon: StaticImage("/images/icon/drawer/contactus@2x.png"),
    type: "contactus",
  },
  {
    label: "นโยบายเว็บไซต์",
    icon: StaticImage("/images/icon/drawer/website@2x.png"),
    type: "termcondition",
  },
  {
    label: "คำประกาศเกี่ยวกับความเป็นส่วนตัว",
    icon: StaticImage("/images/icon/drawer/policy@2x.png"),
    type: "privacynotice",
  },
  {
    label: "นโยบายคุ้กกี้",
    icon: StaticImage("/images/icon/drawer/cookie@2x.png"),
    type: "cookie",
  },
  {
    label: "นโยบายการรักษาความมั่นคงปลอดภัย",
    icon: StaticImage("/images/icon/drawer/policy@2x.png"),
    type: "securitypolicy",
  },
];

const avataruseStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: "30px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    height: 158,
    backgroundColor: "#ebf0f8",
    // padding: '19px 17px 25px 24px',
  },
  labelText: {
    fontSize: 18,
    color: "#fff",
    marginBottom: 10,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    borderRadius: "50px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderRadius: "50px",
  },
  logoBackground: {
    width: "71px",
    height: "71px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#6d94c7",
    position: "absolute",
    left: 15,
    top: 10,
    borderRadius: 35,
  },
}));
const BrowserDeviceIndex = (props) => {
  const [width, height] = useWindowSize();
  const pathname = window.location.pathname;
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const userMenu = useRef(null);
  const [notificationList, setnotification] = useState([]);
  const classesDrawer = useStyles();
  const classesAvatar = avataruseStyles();
  const [iconUserFlag, setIconUserFlag] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menuUser, setmenuUser] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [surverHistoryStyle, setsurverHistoryStyle] = useState({ color: pathname.includes("SurveyHistory") ? '#0c4da2' : '#6d7481' })

  React.useEffect(() => {
    getNotification();
    return () => { };
  }, []);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const getNotification = async () => {
    try {
      if (checkUserBool(userState)) {
        const notificationData = await ApiNotification.get();
        if (notificationData.status == 200) {
          setnotification(notificationData.data);
        }
      }
    } catch (error) {

    }
  }

  const [favoriteHistory, setfavoriteHistory] = useState(
    pathname.includes("FavoriteHistory") ? true : false
  );

  const [surveyHistory, setSurveyHistory] = useState(
    pathname.includes("SurveyHistory") ? true : false
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <div className={classes.drawerHeader}>
        <div
          onClick={() =>
            userState.username ? history.push('/UserProfile') : {}
          }
          className={classes.logoBackground}
        >
          {userState.image_profile ? (
            <Avatar
              size={{
                xs: 71,
                sm: 71,
                md: 71,
                lg: 71,
                xl: 71,
                xxl: 71,
              }}
              style={{ height: 71, width: 71 }}
              // shape={'circle'}
              src={userState.image_profile}
            />
          ) : (
            <img src={StaticImage('/images/user-duotone.png')} />
          )}
        </div>
        <img
          onClick={handleDrawerClose}
          style={{ position: 'absolute', right: 15, top: 10 }}
          src={StaticImage('/images/icon/drawer-header-close.png')}
        />
        <div className={classes.labelText}>
          {checkUserBool(userState)
            ? userState.username
            : 'คุณยังไม่ได้เข้าสู่ระบบ'}
        </div>
      </div> */}
      <div className={classes.drawerHeader}>
        <img
          onClick={handleDrawerClose}
          style={{ position: "absolute", right: 25, top: 10 }}
          src={StaticImage("/images/icon/drawer-header-close.png")}
        />
        <img
          onClick={handleDrawerClose}
          height={100}
          // src={StaticImage('/images/logo/cld-logo.png')}
          src={StaticImage("/images/logo/law-beta_version-02_20210507.svg")}
        />
        {/* <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton> */}
      </div>
      <Divider />
      <List>
        {menuState.map((text, index) => {
          if (checkUserBool(userState)) {
            if (text.type == "login") {
              return false;
            }
          } else {
            if (text.type == "logout") {
              return false;
            }
          }
          return (
            <div>
              {text.type == "logout" && (
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
              )}
              <ListItem
                onClick={() => navigate(text.type)}
                button
                key={text.label}
              >
                <ListItemIcon>
                  <div
                    style={{
                      width: "46px",
                      height: "46px",
                      borderRadius: 23,
                      backgroundColor:
                        text.type == "login" ? "#0c4da2" : "#b6bdca",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 15,
                    }}
                  >
                    <img className="h-6 w-6 object-contain" src={text.icon} />
                  </div>
                </ListItemIcon>
                <ListItemText primary={text.label} />
              </ListItem>
              {text.type == "login" && (
                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
              )}
            </div>
          );
        })}
      </List>
    </div>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = (type) => {
    if (type == "login") {
      history.push(`/Login`);
      return false;
    } else if (type == "logout") {
      dispatch({ type: "set_user", user: {}, token: "" });
      setTimeout(async () => {
        await localStorage.removeItem("token");
        history.push("/");
      }, 300);
      return false;
    } else if (type == 'home') {
      history.push('/');
      return false;
    }
    else if (type == 'phase2') {
      history.push('/Laws');
      return false;
    }

    if (type == "acheive") {
      window.open('https://archive.law.go.th', '_blank');
      return false;
    }
    if (type == "statistics") {
            window.open('https://lookerstudio.google.com/embed/u/0/reporting/fe8cc363-01e7-4721-a73d-cb6aff86f551/page/p_scj63dtygd', '_blank')

	    return false;
    }
    if (type == "faq") {
      history.push(`/DownloadDoc?type=${type}`);
      return false;
    }
    if (type == "doc") {
      history.push(`/DownloadDoc?type=${type}`);
      return false;
    }

    if (type == "more") {
      history.push(`/MeanLaw?type=${type}`);
      return false;
    }
    if (type == "rule") {
      history.push(`/Rule`);
      return false;
    }
    if (type == "test") {
      history.push(`/Test?type=${type}`);
      return false;
    } else {
      history.push(`/WebView?type=${type}`);
      return false;
    }
  };

  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <div className={classes.root}>
        <CssBaseline />
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
              style={{
                backgroundColor: "#ffffff",
                borderBottomLeftRadius: "30px",
                borderBottomRightRadius: "30px",
              }}
            >
              <Toolbar>
                <Grid
                  container
                  direction="row"
                  justify="left"
                  alignItems="left"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Grid>
                      <Typography variant="h6" noWrap>
                        <img
                          className="logo-topbar"
                          src={StaticImage(
                            isMobile
                              ? "/images/logo/law-beta_version-02_20210507.svg"
                              : "/images/logo/law-beta_version-02_20210507.svg"
                          )}
                          alt="logo-law"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.location.href = "/";
                          }}
                        />
                      </Typography>
                    </Grid>
                    <section
                      className="toggle_icon_web"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <BarDuoTone
                        style={{ cursor: "pointer" }}
                        className={clsx(
                          classes.menuButton,
                          open && classes.hide
                        )}
                      />
                    </section>
                    <ButtonComponent
                      onClick={() => {
                        history.push("/");
                      }}
                      className="button-theme button-theme-green-45"
                      text="โครงการรับฟัง"
                    />
                    <ButtonComponent
                      onClick={() => {
                        history.push("/Laws");
                      }}
                      iconRight={<img
                        className="h-6 w-10"
                        src={StaticImage("/images/icon_beta.png")}>
                      </img>}
                      className="button-theme button-theme-blue-45 ml-2"
                      divClassName={"flex items-center gap-x-2"}
                      text="ฐานข้อมูลกฎหมาย"
                    />
                    {/* <section className='main-label-menu-web'>
                      <label
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          history.push('/');
                        }}
                      >
                        หน้าหลัก
                      </label>
                    </section> */}
                  </div>
                </Grid>

                <IconButton aria-label="delete">
                  {/* <SearchIcon fontSize='large' /> */}
                </IconButton>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid>
                    <div className="d-flex header-right-menu">
                      <div class="group relative py-2">
                        <img
                          alt="favorite-icon"
                          className="h-12"
                          src={StaticImage(
                            "/images/EN@2x.png"
                          )}
                        />
                        <div class="hidden group-hover:block ...">กดคลิกขวา ใช้แปลภาษาผ่านเบราว์เซอร์ได้</div>
                      </div>

                    </div>
                  </Grid>
                  <Grid>
                    <div className="d-flex header-right-menu">
                      <div class="group relative py-2">
                        {pathname.includes("FavoriteHistory") ? (
                          <img
                            alt="favorite-icon"
                            onClick={() => {
                              if (checkUser(userState)) {
                                setfavoriteHistory(false);
                                history.push("/");
                              }
                            }}
                            className="cursor-pointer"
                            src={StaticImage(
                              "/images/icon/header/heart-circle-duotone-2-active.svg"
                            )}
                          />
                        ) : (
                          <img
                            alt="favorite-icon"
                            onClick={() => {
                              if (checkUser(userState)) {
                                setfavoriteHistory(true);
                                history.push("/FavoriteHistory");
                                //
                              }
                            }}
                            className="cursor-pointer"
                            onMouseOver={(e) =>
                            (e.currentTarget.src = StaticImage(
                              "/images/icon/header/heart-circle-duotone-2-active.svg"
                            ))
                            }
                            onMouseOut={(e) =>
                            (e.currentTarget.src = StaticImage(
                              "/images/icon/header/heart-circle-duotone-2.svg"
                            ))
                            }
                            style={{ color: "#A4C8F0" }}
                            src={StaticImage(
                              "/images/icon/header/heart-circle-duotone-2.svg"
                            )}
                          />
                        )}
                        <div class="hidden group-hover:block ...">ถูกใจ</div>
                      </div>

                    </div>
                  </Grid>
                  <Grid>
                    <div className="d-flex header-right-menu">
                      <div class="group relative py-2">
                        {pathname.includes("SurveyHistory") ? (
                          <CommentAltEdit
                            onClick={() => {
                              if (checkUser(userState)) {
                                setSurveyHistory(false);
                                setsurverHistoryStyle({ ...surverHistoryStyle, color: '#6d7481' })
                                history.push("/");
                              }
                            }}
                            className='comment-alt-edit-duotone-2 cursor-pointer'
                            style={surverHistoryStyle}
                          />
                        ) : (
                          <CommentAltEdit
                            onClick={() => {
                              if (checkUser(userState)) {
                                setfavoriteHistory(true);
                                setsurverHistoryStyle({ ...surverHistoryStyle, color: '#0c4da2' })
                                history.push("/SurveyHistory");
                              }
                            }}
                            className='comment-alt-edit-duotone-2 cursor-pointer'
                            style={surverHistoryStyle}
                            onMouseOver={(e) =>
                              setsurverHistoryStyle({ ...surverHistoryStyle, color: '#0c4da2' })
                            }
                            onMouseOut={(e) =>
                              setsurverHistoryStyle({ ...surverHistoryStyle, color: '#6d7481' })
                            }
                          />
                        )}
                        <div class="hidden group-hover:block ...">ประวัติการแสดงความเห็น</div>
                      </div>
                    </div>
                  </Grid>

                  <Grid>
                    <div className="d-flex header-right-menu" >
                      <Notification data={notificationList} />
                    </div>
                  </Grid>

                  <Grid>
                    {userState?.username ? (
                      <section style={{ paddingLeft: "1pc" }}>
                        <div
                          className="d-flex-center"
                          style={{ display: "flex", cursor: "pointer" }}
                          onClick={() => {
                            setmenuUser(!menuUser);
                          }}
                        >
                          <Avatar
                            shape="circle"
                            className="avatar-object-cover"
                            src={
                              userState.image_profile
                                ? userState.image_profile
                                : StaticImage(
                                  "/images/login/user-circle-duotone-1-copy.png"
                                )
                            }
                            style={{
                              // border: '2px solid #387436',
                              width: "40px",
                              height: "40px",
                            }}
                          />
                          {userState.is_verify ? (
                            <img
                              className="badge-check-duotone"
                              src={StaticImage(
                                "/images/login/badge-check-duotone.png"
                              )}
                            />
                          ) : (
                            <img
                              className="badge-check-duotone"
                              src={StaticImage(
                                "/images/login/badge-check-duotone.png"
                              )}
                              style={{ opacity: 0.2 }}
                            />
                          )}
                          <label
                            style={{
                              color: "#275394",
                              paddingTop: "0.8pc",
                              fontSize: 20,
                            }}
                          >
                            {userState.username}
                          </label>
                        </div>

                        <div
                          ref={userMenu}
                          onBlur={() => setmenuUser(false)}
                          style={{
                            position: "absolute",
                            display: menuUser == false ? "none" : "initial",
                            backgroundColor: "#fff",
                          }}
                        >
                          <ListItem
                            onClick={() => {
                              setmenuUser(false);
                              history.push("/UserProfile");
                            }}
                            button
                            key={"โปรไฟล์"}
                          >
                            <label style={{ color: "#0c4ea4", fontSize: 18 }}>
                              โปรไฟล์
                            </label>
                          </ListItem>
                          <ListItem
                            onClick={() => navigate("logout")}
                            button
                            key={"ออกจากระบบ"}
                          >
                            <label style={{ color: "#0c4ea4", fontSize: 18 }}>
                              ออกจากระบบ
                            </label>
                          </ListItem>
                        </div>
                      </section>
                    ) : (
                      // <section>
                      //   <ButtonComponent
                      //     onClick={() => {
                      //       history.push("/Login");
                      //       setIconUserFlag(true);
                      //     }}
                      //     className="button-theme button-theme-blue-45"
                      //     text="ลงทะเบียน / เข้าสู่ระบบ"
                      //   />
                      // </section>
                      <div className="d-flex cursor-pointer header-right-menu">
                        <div onClick={() => {
                          history.push(`/Login`)
                        }} class="group relative py-2">
                          <img
                            alt="signin-icon"
                            src={StaticImage('/images/login/user-circle-duotone-1.png')}
                            className='user-circle-duotone-1-copy-userprofile logo-topbar h-6 w-6'
                          />
                          <div class="hidden !left-[-50px] group-hover:block ...">ลงทะเบียน / เข้าสู่ระบบ</div>
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                {/* <div className="menu-main-icon-container">

                </div> */}
              </Toolbar>
            </AppBar>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BrowserDeviceIndex;
