import React, { useEffect, useState } from 'react';
import '../../../main/submenulist.scss';
import ApiSurvey from '../../../../api/survey';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useHistory } from 'react-router-dom';
import { StaticImage } from '../../../../utils/image';
import { checkLeftTime, checkComplete } from '../../../../utils';
// import YoutuberPlayer from "./react-player/YoutuberPlayer";
import {
  isMobile,
  BrowserView,
  MobileView,
  isBrowser,
} from 'react-device-detect';
import Select from 'react-select';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { Container, fade, Grid, makeStyles } from '@material-ui/core';
import { Card, Space, Collapse, Skeleton } from 'antd';

import Avatar from 'antd/lib/avatar/avatar';
import PersonIcon from '@material-ui/icons/Person';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TagDuoTone from '../../../iconComponent/TagDuoTone';
import CommentAltEdit from '../../../iconComponent/CommentAltEdit';
import './index.scss';
import { ConvertDateShort } from '../../../../utils/date';
import ProgressBar from './ProgressBar';
import ApiMaster from '../../../../api/master';
import { FavoriteBorder, MoreHorizOutlined } from '@material-ui/icons';
import {
  HeartFilled,
  HeartOutlined,
  CalendarOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPollH,
  faTimesCircle,
  faArrowCircleDown,
  faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { encodeBase64 } from '../../../../utils/convertBase64';
const { Panel } = Collapse;

const scoreText = [
  { value: 'last', label: '#ล่าสุด', color: '#0c4da2' },
  { value: 'answer', label: '#ตอบมากที่สุด', color: '#a51830' },
  // { value: 'read', label: '#ความนิยม', color: '#387436' },
  { value: 'popular', label: '#เข้าชมมากสุด', color: '#387436' },
  //{ value: 'project', label: '#เฉพาะโครงการ', color: '#387436' },
];

const listTextOption = [
  { value: '', label: 'ทั้งหมด' },
  { value: 'O', label: 'เปิดรับฟัง' },
  { value: 'I', label: 'ปิดรับฟัง' },
];

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    borderRadius: '100px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    backgroundColor: '#e5e6ee',
    '&:hover': {
      backgroundColor: '#e5e6ee',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontFamily: 'Kanit',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selectOption: {
    zIndex: 10,
  },
  cardSeq: {
    borderRadius: 10,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 30,
    padding: 10,
    boxShadow: '3.9px 1px 6px 0 rgba(137, 148, 169, 0.4)',
  },
  cardSeqLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardSurveyTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    flex: 1,
  },
  cardSurveyDescription: {
    color: '#666666',
    fontSize: 18,
  },
  cardSurveyTypeImage: {
    width: 20,
    height: 20,
    marginRight: 5,
    objectFit: 'contain',
    color: '#0c4da2',
    paddingTop: 5,
  },
  cardSurveyTypeText: {
    fontSize: 14,
    color: '#0c4da2',
    fontWeight: 'bold',
  },
  cardSurveyLeftIcon: {
    width: 101,
    height: 101,
    padding: '18px 22px 20px 31px',
    borderRadius: 50,
    border: 'solid 5px #85b0e8',
    backgroundColor: '#0c4da2',
  },
  cardSurveyCommentIcon: {
    fontSize: 14,
    color: '#999',
  },
}));

const SurveyCard = ({
  surveys = [],
  getFilterSurvey,
  getOrderSurvey,
  handleFavorite,
  entitys,
  law_group,
}) => {
  const classes = useStyles();
  const [agency, setagency] = useState(null);
  const [ministry, setministry] = useState(null);
  const [surveyType, setsurveyType] = useState(null);
  const [orderFilter, setorderFilter] = useState('project');
  const [searchFilter, setSearchFilter] = useState('');
  const [statusSurvey, setStatusSurvey] = useState(null);
  const [agencyOption, setAgencyOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);
  const [ministryOption, setMinistryOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);
  const [surveyTypeOption, setSurveyTypeOption] = useState([
    { value: null, label: 'ทั้งหมด' },
  ]);

  const history = useHistory();

  useEffect(() => {
    getFilterOption();
    return () => { };
  }, []);

  useEffect(() => {
    getFilterSurvey({
      ministry,
      agency,
      surveyType,
      statusSurvey,
      searchFilter,
    });
    return () => { };
  }, [agency, ministry, surveyType, statusSurvey, searchFilter]);

  useEffect(() => {
    if (orderFilter !== 'project') {
      getOrderSurvey({ orderFilter });
    }
    return () => { };
  }, [orderFilter]);

  const getFilterOption = async () => {
    const filter = await ApiMaster.homeFilter();
    if (filter.status == 200) {
      const { data } = filter.data;
      console.log('filter', data);
      setAgencyOption([...agencyOption, ...data.agency]);
      setMinistryOption([...ministryOption, ...data.ministry]);
      setSurveyTypeOption([
        ...surveyTypeOption,
        ...data.survey_type.filter((x) => x.label != 'หลักการ'),
      ]);
    }
  };

  const cleardata = () => {
    setagency(null);
    setministry(null);
    setsurveyType(null);
  };

  return (
    <div
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 0, zoom: '70%' }}
    >
      <Grid container direction='row' justify='flex-start'>
        <Grid item xs={12}>
          <Space
            size={'large'}
            direction={'vertical'}
            style={{ width: '100%' }}
          >
            <div style={{ marginTop: 16 }}>
              {surveys.map((res, i) => {
                return orderFilter !== 'project' ? (
                  <SurveyCardList
                    law_group={law_group}
                    handleFavorite={handleFavorite}
                    history={history}
                    classes={classes}
                    res={res}
                    entitys={entitys}
                  />
                ) : (
                  <div className='project-container-home-page'>
                    <ProjectCardV2
                      classes={classes}
                      entitys={entitys}
                      history={history}
                      law_group={law_group}
                      res={res}
                      project={res.project}
                      handleFavorite={handleFavorite}
                      renderType='main'
                    />
                  </div>
                );
              })}
              {surveys.length == 0 && (
                <div
                  className='card-survey-mobile'
                  style={{ marginTop: 5, padding: 50 }}
                >
                  <label>ไม่มีรายการ ระบบจะแสดงประวัติจากการกด หัวใจ ลองเลือกการติดตามได้จากหน้าหลัก</label>
                </div>
              )}
            </div>
          </Space>
        </Grid>
        {/* <Grid item sm={1}></Grid> */}
      </Grid>
    </div>
  );
};

const SurveyCardList = ({
  law_group,
  res,
  classes,
  handleFavorite,
  history,
  entitys,
}) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const handleChild = (e, item) => {
    e.stopPropagation();
    setIsCollapse(!isCollapse);
  };

  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 75,
                sm: 75,
                md: 75,
                lg: 75,
                xl: 75,
                xxl: 75,
              }}
              style={{
                height: 75,
                width: 75,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={100} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };

  return (
    <section key={'card-' + res.survey_id} style={{ marginBottom: 20 }}>
      <div
        className='card-two'
        onClick={() => {
          {
            if (res.is_coming === '1') {
            } else {
              history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
            }
          }
        }}
      >
        <div
          className='card-list-shadow-web'
          style={{
            borderRadius: '10px',
            height: 250,
            padding: 24,
          }}
        >
          <div style={{ display: 'flex', height: '100%' }}>
            <div
              style={{
                borderRight: '1px solid #e5e6ee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {renderImageLawGroup(res)}
              <div
                className='d-flex-center'
                style={{ marginTop: 20, minWidth: 170 }}
              >
                {res.agency_id > 0 && (
                  <div
                    className='organize-container'
                    style={{ margin: 0, maxWidth: 100 }}
                  >
                    <img
                      alt="agency_image"
                      src={res.agency_image_uploaded ?? res.agency_image}
                      className="logo"
                      oncontextmenu="return false;"
                      onError={(i) => (i.target.style.display = "none")}
                    />
                    <label style={{ paddingLeft: "2px" }}>
                      {res.agency_short ?? res.agency_abbrv}
                    </label>
                  </div>
                )}
              </div>
              <div style={{ marginTop: 20 }}>
                <TagDuoTone className={classes.cardSurveyTypeImage} />
                <span className={classes.cardSurveyTypeText}>
                  {res.survey_type_name}
                </span>
              </div>
            </div>
            <div
              style={{
                marginLeft: 40,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <label className={classes.cardSurveyTitle}>
                    {res.short_project_name ?? res.project_name}
                  </label>
                  <div
                    onClick={(e) => handleFavorite(e, res)}
                    className='d-flex-center'
                  >
                    {res.is_like === true ? (
                      <img
                        height={32}
                        width={32}
                        src={StaticImage(
                          '/images/icon/header/heart-circle-duotone-2-active.svg'
                        )}
                      />
                    ) : (
                      <img
                        height={32}
                        width={32}
                        src={StaticImage(
                          '/images/icon/header/heart-circle-duotone-2.svg'
                        )}
                      />
                    )}
                  </div>
                </div>
                <label className={classes.cardSurveyDescription}>
                  {res.short_survey_name ?? res.survey_name}
                </label>
                <div
                  style={{
                    display: 'flex',
                    marginTop: 20,
                    alignItems: 'center',
                  }}
                >
                  <div
                    className='d-flex discuss-container'
                    style={{ marginRight: 15 }}
                  >
                    <img
                      src={StaticImage('/images/icon/users-class-light.svg')}
                      style={{ marginRight: 10 }}
                    />
                    <label style={{ color: '#488345', fontSize: 14 }}>
                      กำลังร่วมเสนอ
                    </label>
                  </div>
                  <div
                    className='d-flex discuss-container'
                    style={{ marginRight: 15 }}
                  >
                    <img
                      src={StaticImage(
                        '/images/icon/comment-alt-edit-duotone-4.svg'
                      )}
                      style={{ marginRight: 10 }}
                    />
                    <label style={{ fontSize: 14, color: '#333' }}>
                      {res.answer_count ?? 0}
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <span style={{ fontSize: 16 }}>
                    {ConvertDateShort(res.start_date, 'DD MMM YY')}
                  </span>
                  <section
                    className='progress-list-filter'
                    style={{ width: '100%', maxWidth: 400 }}
                  >
                    <ProgressBar
                      bgcolor={res.date_balance > 7 ? '#387436' : '#f96b57'}
                      bgContainerColor={
                        res.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'
                      }
                      leftTime={checkLeftTime(res)}
                      completed={checkComplete(res)}
                    />
                  </section>
                  <span>
                    <span style={{ fontSize: 16 }}>
                      {ConvertDateShort(res.end_date, 'DD MMM YY')}
                    </span>
                  </span>
                </div>

                <Space>
                  <span
                    style={{
                      color: '#494949',
                      fontSize: 16,
                      marginRight: 5,
                      fontWeight: 'bold',
                    }}
                  >
                    ครั้งที่
                  </span>
                  <span
                    style={{
                      color: '#0c4da2',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }}
                  >
                    {res.publish_no
                      ? res.publish_no.toString().padStart('2', '0')
                      : '00'}
                  </span>
                </Space>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex-center' style={{ padding: 10 }}>
          <div onClick={(e) => handleChild(e, res)} className='cursor-pointer'>
            <MoreHorizOutlined />
          </div>
        </div>
      </div>
      {isCollapse && (
        <div
          className='flex-column project-container-home-page'
          style={{ paddingLeft: 20, paddingRight: 20, zoom: '90%' }}
        >
          <ProjectCardV2
            res={res}
            project={res.project}
            classes={classes}
            history={history}
            entitys={entitys}
            law_group={law_group}
          />
          {res.surveys.map((childSurvey) => {
            return (
              <SurveyCardListChild
                handleFavorite={handleFavorite}
                history={history}
                classes={classes}
                res={childSurvey}
                entitys={entitys}
                law_group={law_group}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};

const SurveyCardListChild = ({
  res,
  classes,
  handleFavorite,
  history,
  entitys,
  law_group,
}) => {
  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.law_group_id) {
      const split = item.law_group_id.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 75,
                sm: 75,
                md: 75,
                lg: 75,
                xl: 75,
                xxl: 75,
              }}
              style={{
                height: 75,
                width: 75,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={100} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };
  return (
    <section style={{ marginBottom: 20 }}>
      <div
        className='card-two'
        onClick={() => {
          {
            history.push(`/listeningDetail?survey_id=${encodeBase64(res.survey_id)}`);
          }
        }}
      >
        <div
          className='card-list-shadow-web'
          style={{
            borderRadius: '10px',
            height: 250,
            padding: 24,
          }}
        >
          <div style={{ display: 'flex', height: '100%' }}>
            <div
              style={{
                borderRight: '1px solid #e5e6ee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {renderImageLawGroup(res)}
              <div
                className='d-flex-center'
                style={{ marginTop: 20, minWidth: 170 }}
              >
                {res.agency_id > 0 && (
                  <div
                    className='organize-container'
                    style={{ margin: 0, maxWidth: 100 }}
                  >
                    <img
                      alt="agency_image"
                      src={res.agency_image_uploaded ?? res.agency_image}
                      className="logo"
                      oncontextmenu="return false;"
                      onError={(i) => (i.target.style.display = "none")}
                    />
                    <label style={{ paddingLeft: "2px" }}>
                      {res.agency_short ?? res.agency_abbrv}
                    </label>
                  </div>
                )}
              </div>
              <div style={{ marginTop: 20 }}>
                <TagDuoTone className={classes.cardSurveyTypeImage} />
                <span className={classes.cardSurveyTypeText}>
                  {res.survey_type_name}
                </span>
              </div>
            </div>
            <div
              style={{
                marginLeft: 40,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <label className={classes.cardSurveyTitle}>
                    {res.short_project_name ?? res.project_name}
                  </label>
                  {/* <div
                    onClick={(e) => handleFavorite(e, res)}
                    className='d-flex-center'
                    style={{
                      width: 50,
                      height: 50,
                      backgroundColor: 'red',
                      borderRadius: 25,
                      boxShadow:
                        '2.8px 2.8px 5px 0 rgba(137, 148, 169, 0.2), -2.1px -2.1px 8px 0 rgba(137, 148, 169, 0.15), inset 3.5px 3.5px 10px 0 #ffffff, inset -3.5px -3.5px 10px 0 rgba(188, 194, 211, 0.25)',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    {res.is_like === true ? (
                      <HeartFilled style={{ fontSize: 24, color: '#0c4da2' }} />
                    ) : (
                      <HeartOutlined
                        style={{ fontSize: 24, color: '#afafaf' }}
                      />
                    )}
                  </div> */}
                </div>
                <label className={classes.cardSurveyDescription}>
                  {res.short_survey_name ?? res.survey_name}
                </label>
                <div
                  style={{
                    display: 'flex',
                    marginTop: 20,
                    alignItems: 'center',
                  }}
                >
                  <div
                    className='d-flex discuss-container'
                    style={{ marginRight: 15 }}
                  >
                    <img
                      src={StaticImage('/images/icon/users-class-light.svg')}
                      style={{ marginRight: 10 }}
                    />
                    <label style={{ color: '#488345', fontSize: 14 }}>
                      กำลังร่วมเสนอ
                    </label>
                  </div>
                  <div
                    className='d-flex discuss-container'
                    style={{ marginRight: 15 }}
                  >
                    <img
                      src={StaticImage(
                        '/images/icon/comment-alt-edit-duotone-4.svg'
                      )}
                      style={{ marginRight: 10 }}
                    />
                    <label style={{ fontSize: 14, color: '#333' }}>
                      {res.answer_count ?? 0}
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <span style={{ fontSize: 16 }}>
                    {ConvertDateShort(res.start_date, 'DD MMM YY')}
                  </span>
                  <section
                    className='progress-list-filter'
                    style={{ width: '100%', maxWidth: 400 }}
                  >
                    <ProgressBar
                      bgcolor={res.date_balance > 7 ? '#387436' : '#f96b57'}
                      bgContainerColor={
                        res.date_balance > 7 ? '#e4e8f0' : '#e4e8f0'
                      }
                      leftTime={checkLeftTime(res)}
                      completed={checkComplete(res)}
                    />
                  </section>
                  <span>
                    <span style={{ fontSize: 16 }}>
                      {ConvertDateShort(res.end_date, 'DD MMM YY')}
                    </span>
                  </span>
                </div>

                <Space>
                  <span
                    style={{
                      color: '#494949',
                      fontSize: 16,
                      marginRight: 5,
                      fontWeight: 'bold',
                    }}
                  >
                    ครั้งที่
                  </span>
                  <span
                    style={{
                      color: '#0c4da2',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }}
                  >
                    {res.publish_no
                      ? res.publish_no.toString().padStart('2', '0')
                      : '00'}
                  </span>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectCardV2 = ({
  res,
  project,
  classes,
  handleFavorite,
  history,
  entitys,
  law_group,
  renderType = 'child',
}) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const handleChild = (e, item) => {
    e.stopPropagation();
    setIsCollapse(!isCollapse);
  };
  const renderImageLawGroup = (item) => {
    let law = '';
    if (item.lawgroup_id_multiple) {
      const split = item.lawgroup_id_multiple.split(',');
      law = law_group.find((x) => x.lawgroup_id == split[0]);
      // const lawgroup = law_group.find(x=>x.)
    }

    return (
      <div className='law-group-container d-flex-center'>
        <div className='d-flex-center'>
          {law && law.image ? (
            <Avatar
              className='avatar-object-contain'
              size={{
                xs: 75,
                sm: 75,
                md: 75,
                lg: 75,
                xl: 75,
                xxl: 75,
              }}
              style={{
                height: 75,
                width: 75,
              }}
              // shape={'circle'}
              src={law.image}
            />
          ) : (
            <Skeleton.Button active={true} size={100} shape={'circle'} />
          )}
        </div>
      </div>
    );
  };
  return (
    <section style={{ marginBottom: 20 }}>
      <div
        className='d-flex-center'
        style={{ padding: 10, justifyContent: 'flex-end' }}
      >
        <div
          onClick={(e) => handleChild(e, res)}
          className='cursor-pointer expand-survey-icon'
        >
          {isCollapse ? (
            <>
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                color='#bababa'
                icon={faPollH}
              />
              <span>{`${res.surveys.length + 1 ?? 0} ย่อ`}</span>
              <FontAwesomeIcon color={'#0c4ca3'} icon={faTimesCircle} />
            </>
          ) : (
            <>
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                color='#bababa'
                icon={faPollH}
              />
              <span>{`${res.surveys.length + 1 ?? 0} ขยาย`}</span>
              <FontAwesomeIcon color={'#0c4ca3'} icon={faArrowCircleDown} />
            </>
          )}
        </div>
      </div>
      <div
        className='project-card-desktop'
        onClick={() => {
          if (res.survey_type_id != 3) {
            history.push(`/ProjectTimeline?projectId=${encodeBase64(project.project_id)}`);
          }
        }}
      >
        <div className=''>
          <div style={{ display: 'flex', height: '100%' }}>
            <div
              style={{
                borderRight: '1px solid #e5e6ee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {renderImageLawGroup(project)}
              <div
                className='d-flex-center'
                style={{ marginTop: 20, minWidth: 170 }}
              >
                {res.agency_id > 0 && (
                  <div
                    className='organize-container'
                    style={{ margin: 0, maxWidth: 100 }}
                  >
                    <img
                      alt="agency_image"
                      src={res.agency_image_uploaded ?? res.agency_image}
                      className="logo"
                      oncontextmenu="return false;"
                      onError={(i) => (i.target.style.display = "none")}
                    />
                    <label style={{ paddingLeft: "2px" }}>
                      {res.agency_short ?? res.agency_abbrv}
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                marginLeft: 40,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <label className={classes.cardSurveyTitle}>
                    {res.short_project_name ?? res.project_name}
                  </label>
                  <div
                    onClick={(e) => handleFavorite(e, res)}
                    className='d-flex-center'
                  >
                    {res.is_like === true ? (
                      <img
                        height={32}
                        width={32}
                        src={StaticImage(
                          '/images/icon/header/heart-circle-duotone-2-active.svg'
                        )}
                      />
                    ) : (
                      <img
                        height={32}
                        width={32}
                        src={StaticImage(
                          '/images/icon/header/heart-circle-duotone-2.svg'
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex flex-1 project-card-body'>
                <div className='d-flex tag'>
                  <TagDuoTone />
                  <span>{res.survey_type_name}</span>
                </div>
                <div className='d-flex process-now'>
                  <FontAwesomeIcon icon={faBalanceScale} />
                  <span className='entity-text'>
                    {project.entity_name ?? entitys[0]?.activity_abbrv}
                  </span>
                </div>

                <div className='d-flex calendar'>
                  <CalendarOutlined />
                  <span>
                    {ConvertDateShort(project.createdate, 'DD MMM YY')}
                  </span>
                </div>
              </div>
              {res.survey_type_id != 3 && (
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className='project-timeline-body-bottom d-flex'>
                    <div>
                      <div
                        className='d-flex'
                        style={{ alignItems: 'center', marginBottom: 10 }}
                      ></div>

                      <div
                        className='d-flex'
                        style={{ flexWrap: 'wrap', marginTop: -10 }}
                      >
                        {entitys.map((item, index) => {
                          const findIndex = entitys.findIndex(
                            (x) =>
                              x.activity_id ==
                              (project.entity_activity_id ??
                                entitys[0].activity_id)
                          );
                          let className = '';
                          if (
                            item.activity_id ==
                            (project.entity_activity_id ??
                              entitys[0].activity_id)
                          ) {
                            className = 'active';
                          }
                          if (findIndex > index) {
                            className += 'pass';
                          }
                          return (
                            <div
                              className={`process-step d-flex-center ${className}`}
                            >
                              {item.activity_abbrv}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isCollapse && renderType !== 'child' && (
        <div
          className='flex-column project-container-home-page'
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            zoom: '90%',
            marginTop: 20,
          }}
        >
          {res.surveys_all.map((childSurvey) => {
            return (
              <SurveyCardListChild
                handleFavorite={handleFavorite}
                history={history}
                classes={classes}
                res={childSurvey}
                entitys={entitys}
                law_group={law_group}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};

export default SurveyCard;
