import './index.scss'
const Tab6 = ({ survey }) => {

    return (
        <>
            <div className="tab6-container">
                <h1 className="header">
                    เนื้อหากฎหมาย
               </h1>
                <div
                    className='ql-editor'
                    dangerouslySetInnerHTML={{ __html: survey.content_desc }}
                ></div>
                {survey.content_files && survey.content_files.length > 0 && (
                    <div className="file-container">
                        {survey.content_files.map((item, index) => {
                            if (item.type !== 'contentlaw') {
                                return <></>;
                            }
                            return (
                                <div className='d-flex'>
                                    <label
                                        style={{ fontSize: 14 }}
                                        className='text-link cursor-pointer'
                                        onClick={() =>
                                            window.open(`${item.file_path.replace('http://', 'https://')}`, '_blank')
                                        }
                                    >
                                        {item.file_name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    )
}

export default Tab6;
